<template>
  <ReportWrap
    name="Methodology"
    v-bind="$props"
  >
    <div class="report-insight">
      <div class="report-insight__body">
        <TitleInput
          appearance="report-title clear"
          :value="section.name"
          :can-edit="canEdit"
          placeholder="Methodology"
          @input="updateName($event)"
        />
        <ReportSummary v-bind="$props" />
      </div>
      <div class="report-insight__aside report-insight__aside--bottom">
        <img
          class="report-insight__image"
          width="341"
          height="394.19"
          src="/static/img/methods/art_stakeholder-interview.svg"
        >
      </div>
    </div>
  </ReportWrap>
</template>

<script>
import ReportWrap from './report-wrap';
import ReportSummary from './summary';

export default {
  name: 'ProjectMethodCustom',
  components: {
    ReportWrap,
    ReportSummary,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  methods: {
    updateName(name) {
      if (this.section) {
        this.$store.dispatch('methodSectionUpdate', {
          id: this.section.id,
          data: {
            name,
          },
        })
      }
    },
  },
};
</script>