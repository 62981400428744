import * as ApiService from 'services/api.service';

const MODEL = {
  id: '__temp',
  name: '',
  itemsCount: 0,
};
const parse = (raw) => ({
  ...MODEL,
  ...raw,
});

class TagsService {
  fetch(workspace, projectId) {
    return ApiService.get(this.getUrl(workspace, projectId)).then((tags) => tags && tags.map && tags.map(parse) || []);
  }

  fetchSentences(workspace, projectId) {
    return ApiService.get(`api/workspaces/${workspace}/projects/${projectId}/sentences?page=1&per=999999`);
  }

  add(workspace, projectId, { name, color, id, createTester }) {
    return ApiService.post(this.getUrl(workspace, projectId), {
      name,
      color,
      id,
      createTester,
    }).then(parse);
  }

  update(workspace, projectId, id, data) {
    return ApiService.put(`${this.getUrl(workspace, projectId)}/${id}`, data).then(parse);
  }

  remove(workspace, projectId, id) {
    return ApiService.del(`${this.getUrl(workspace, projectId)}/${id}`);
  }

  updateSurveyTag({ workspace, projectId, tagId, email, designMethodId }) {
    return ApiService.put(`${this.getUrl(workspace, projectId)}/${tagId}`, {
      email,
      designMethodId,
      questionnaire: true,
    });
  }

  getUrl(workspace, projectId) {
    return `api/workspaces/${workspace}/tags`;
  }

  get model() {
    return MODEL;
  }
}

export default new TagsService();
