import { pluralify } from 'utils/text';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const timeSince = (timestamp) => {
  const now = Date.now();
  const diff = Math.round((now - timestamp) / 1000);

  const days = Math.floor(diff / DAY);
  const hours = Math.floor((diff - days * DAY) / HOUR);
  const minutes = Math.floor((diff - days * DAY - hours * HOUR) / MINUTE);
  const seconds = diff - days * DAY - hours * HOUR - minutes * MINUTE;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const timeSinceParts = (date) => {
  const timestamp = date.getTime();
  const data = timeSince(timestamp);

  let result;
  if (data.days < 0 || data.days <= 0 && data.hours <= 0 && data.minutes <= 0 && data.seconds < 5) {
    result = 'just now';
  } else if (data.days === 0 && data.hours === 0 && data.minutes === 0) {
    result = `${data.seconds} ${pluralify('second', data.seconds)}`;
  } else if (data.days === 0 && data.hours === 0) {
    result = `${data.minutes} ${pluralify('minute', data.minutes)}`;
  } else if (data.days === 0) {
    result = `${data.hours} ${pluralify('hour', data.hours)}`;
  } else if (data.days < 7) {
    result = `${data.days} ${pluralify('day', data.days)}`;
  } else if (data.days < 30) {
    const weeks = Math.round(data.days / 7);
    result = `${weeks} ${pluralify('week', weeks)}`;
  } else {
    const months = Math.round(data.days / 30);
    result = `${months} ${pluralify('month', months)}`;
  }

  return result;
};

export const timeSinceText = (date) => {
  date = new Date(date);
  const text = timeSinceParts(date);
  if (text === 'just now') return text;
  return `${text} ago`;
};

export const timeMonthText = (time) => {
  const date = new Date(time);
  const now = new Date();
  const dateMonth = date.getMonth();
  const nowMonth = now.getMonth();
  const lastMonth = nowMonth - 1 < 0 ? nowMonth + 11 : nowMonth - 1;

  if (dateMonth === nowMonth) {
    return 'This month';
  }

  if (dateMonth === lastMonth) {
    return 'Last month';
  }

  return MONTHS[dateMonth];
};

export const timeText = (time) => {
  const date = new Date(time);
  const dateMonth = date.getMonth();

  return `${MONTHS[dateMonth]} ${date.getDate()}, ${date.getFullYear()}`;
};

const toFixed = (num) => {
  const str = num.toString();

  if (str.length <= 1) {
    return '0' + str;
  }

  return str;
}

export const videoSecondTime = (time) => {
  const hours = Math.floor(time / 3600);
  const mins = Math.floor((time - hours * 3600) / 60);
  const secs = Math.round(time - hours * 3600 - mins * 60);

  return `${mins + hours * 60}:${toFixed(secs)}`;
}