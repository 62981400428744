import * as EVENTS from 'constants/events';
import { ESC, SPACE } from 'constants/keys';
import { _ } from 'core-js';
import mitt from 'mitt';

const Events = mitt();

class EventsService {
  constructor() {
    this.initGlobalEvents();
  }

  initGlobalEvents() {
    window.addEventListener(
      'beforeunload',
      () => this.emit(EVENTS.UNLOAD),
      false
    );

    window.addEventListener(
      'scroll',
      (e) => this.emit(EVENTS.SCROLL, e),
      false
    );

    window.addEventListener(
      'resize',
      (e) => this.emit(EVENTS.RESIZE, e),
      false
    );

    document.addEventListener(
      'keydown',
      (e) => {
        this.emit(EVENTS.KEY_DOWN, e);
        if (e.keyCode === ESC) {
          this.emit(EVENTS.KEY_ESC, e);
          return;
        }

        if (e.keyCode === SPACE) {
          this.emit(EVENTS.KEY_SPACE, e);
        }
      },
      false
    );
  }

  on(event, handler) {
    Events.on(event, handler);
  }

  once(event, handler) {
    Events.on(event, handler);
  }

  off(event, handler) {
    Events.off(event, handler);
  }

  emit(event, ...args) {
    Events.emit(event, ...args);
  }
}

export { EVENTS };
export default new EventsService();
