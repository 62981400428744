<template>
  <div
    class="wysiwyg"
    @click="editorClick"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
  >
    <div
      class="wysiwyg__editor"
      placeholder="Type here..."
      ref="editor"
      v-if="canEdit"
    />
    <div class="wysiwyg__content" v-html="value" v-else />
    <div
      v-if="showTags"
      ref="tags"
      class="wysiwyg__tags"
    >
      <div
        class="wysiwyg__tags-group"
      >
        <div
          v-for="tag of tags"
          :key="tag.id"
          class="wysiwyg__tags-item"
          :data-tag-id="tag.id"
          :data-y="tag.y"
          @mouseenter="onTagMouseEnter(tag)"
          @mouseleave="onTagMouseLeave(tag)"
          @click="tagClicked(tag)"
        >
          <span
            class="wysiwyg__tags-item-ico"
            :style="{ background: tag.color }"
          />
          {{ tag.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { hasParent } from 'helpers/ui';

import Quill from 'quill';
import 'quill-paste-smart';
import chroma from 'chroma-js';
import EventsService from 'services/events.service';
import { getCaretPos, getTextOffset, setCaretPos } from 'helpers/ui';
import generateId from 'utils/id';
import TagBlot from './blots/tag';
import TimestampBlot from './blots/timestamp';

function getParentTag(element) {
  if (element.tagName === 'TAG') {
    return element;
  }
  return element.parentNode && getParentTag(element.parentNode);
}

const Inline = Quill.import('blots/inline');

Inline.order.push('tspm');
Inline.order.push('tag');

const Link = Quill.import('formats/link');
Link.sanitize = (url) =>
  (url.trim().match(/^https?/) ? url : `http://${ url.trim() }`);

Quill.register(TagBlot);
Quill.register(TimestampBlot);

const Clipboard = Quill.import('modules/clipboard')

class PlainClipboard extends Clipboard {
  onPaste (e) {
    let data = '';
    let stop = false;

    try {
      data = (e.clipboardData || window.clipboardData).getData('text/_talebook-blocks');
    } catch(e) {
      // eslint-disable-next-line
      console.error(e);
    }

    if (data) {
      const sections = JSON.parse(data);

      if (sections && sections.length) {
        stop = true;
      }
    }

    if (!stop) {
      super.onPaste(e);
    }
  }
}

Quill.register('modules/clipboard', PlainClipboard, true);

function getParentByClass(element, classNames) {
  for (const className of classNames) {
    if (element.classList.contains(className)) {
      return element;
    }
  }
  
  return element.parentNode && element.parentNode.classList && getParentByClass(element.parentNode, classNames);
}

let refreshTagsPositionsTimeout = null;

export function refreshTagsPositionsDebounce() {
  if (refreshTagsPositionsTimeout) {
    window.clearTimeout(refreshTagsPositionsTimeout);
  }

  refreshTagsPositionsTimeout = window.setTimeout(refreshTagsPositions, 60);
}

function refreshTagsPositions() {
  if (document.querySelector('.sortable-clone')) {
    return;
  }

  let tags = Array.from(document.querySelectorAll('.wysiwyg__tags-item'));
  let left = 0;
  let top = 0;
  let lastHeight = 0;
  let windowWidth = window.innerWidth;

  tags.forEach((tag, i) => {
    let width = tag.offsetWidth;
    let height = tag.offsetHeight;
    let parent = getParentByClass(tag, ['wysiwyg', 'addon-video__captions']);
    let parentWidth = parent.offsetWidth;
    let parentOffset = parent.querySelector('.wysiwyg__tags').getBoundingClientRect();

    parentOffset = {
      left: parentOffset.left + window.pageXOffset,
      top: parentOffset.top + window.pageYOffset,
    }

    let y = parseInt(tag.dataset.y) + parentOffset.top;

    let leftTarget = left;
    let topTarget = top;

    if (y >= top + lastHeight) {
      topTarget = y;
      leftTarget = 0;
    } else {
      topTarget = top;
    }

    if (leftTarget + parentOffset.left > windowWidth - width) {
      leftTarget = 0;
      topTarget = top + lastHeight + 3;
    }

    if (topTarget < y) {
      topTarget = y;
    }

    tag.style.top = (topTarget - parentOffset.top) + 'px';
    tag.style.left = leftTarget + 'px';

    window.setTimeout(() => {
      tag.classList.add('wysiwyg__tags-item--mounted');
    }, 32);

    left = leftTarget + width + 2;
    top = topTarget;
    lastHeight = height;
  });
}

window.addEventListener('resize', refreshTagsPositionsDebounce);

function placeCaretAt(el, atStart = false) { 
    el.focus();
    if (typeof window.getSelection != 'undefined'
            && typeof document.createRange != 'undefined') {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(atStart);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != 'undefined') {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(atStart);
        textRange.select();
    }
}

function createRange(node, chars, range) {
    if (!range) {
        range = document.createRange()
        range.selectNode(node);
        range.setStart(node, 0);
    }

    if (chars.count === 0) {
        range.setEnd(node, chars.count);
    } else if (node && chars.count >0) {
        if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
                chars.count -= node.textContent.length;
            } else {
                 range.setEnd(node, chars.count);
                 chars.count = 0;
            }
        } else {
            for (var lp = 0; lp < node.childNodes.length; lp++) {
                range = createRange(node.childNodes[lp], chars, range);

                if (chars.count === 0) {
                   break;
                }
            }
        }
   }

   return range;
};

function setCurrentCursorPosition(node, chars) {
    if (chars >= 0) {
        const selection = window.getSelection();

        const range = createRange(node, { count: chars });

        if (range) {
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
};

function includesAll(arr, list){
  for (let obj of list){
    if(!arr.includes(obj)) return false;
  }
  return true;
}

const BLOCK_TAGS = [
  'p',
  'div',
  'ul',
  'ol',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

function isBlockElement(el) {
  return BLOCK_TAGS.includes(el.tagName && el.tagName.toLowerCase());
}

function flattenParagraphs(el, selection, level = 0, tagTree = []) {
  const paragraphs = [];
  let isPrevBlock = true;

  if (!selection) {
    selection = window.getSelection();
  }

  const focusNode = selection.focusNode;
  const focusOffset = selection.focusOffset;

  el.childNodes.forEach((node, index) => {
    const hasFocus = focusOffset && ((el === focusNode && index === focusOffset) || !!hasParent(focusNode, [node]));

    if (isBlockElement(node)) {
      const flatten = flattenParagraphs(node, selection, level + 1, [...tagTree, node.tagName]);

      if (flatten.length) {
        paragraphs.push(...flatten);
      } else {
        paragraphs.push({
          nodes: [],
          focus: hasFocus,
          tagTree,
        });
      }
      
      isPrevBlock = true;
    } else if (node.tagName === 'BR') {
      if (isPrevBlock) {
        paragraphs.push({
          nodes: [],
          focus: hasFocus,
          tagTree,
        });
      }

      isPrevBlock = true;
    } else if (isPrevBlock) {
      paragraphs.push({
        nodes: [node],
        focus: hasFocus,
        tagTree,
      });

      isPrevBlock = false;
    } else {
      paragraphs.at(-1).nodes.push(node);

      if (hasFocus) {
        paragraphs.at(-1).focus = true;
      }

      isPrevBlock = false;
    }
  });

  return paragraphs;
}

export function getSentencesFromEditor(quill, projectTags) {
  const editor = quill.container;

  const tagsEl = Array.prototype.slice.call(editor.querySelectorAll('tag'));
  const tagSentences = {};
  let sentences = [];
  const tags = [];

  tagsEl.forEach((tagEl, i) => {
    const tagsList = tagEl.dataset.tags.split(' ').filter((id) => projectTags.find((t) => t.id === id));
    tagsList.forEach((tag, i) => {
      if (!tags.find((t) => t === tag)) {
        tags.push(tag);
        tagSentences[tag] = [];
      }
    });
    if (!tagsList.length) {
      let blot = Quill.find(tagEl);
      blot.format('tag', {tag: false});
    }
  });

  tags.forEach((tag, i) => {
    let tagEls = tagsEl.filter((el) => el.dataset.tags.split(' ').includes(tag));

    tagEls.forEach((tagEl, i) => {
      let lastSentence = tagSentences[tag][tagSentences[tag].length - 1];
      let blot = Quill.find(tagEl);

      if (!blot) return;

      let offsetLeft = blot.offset();
      let length = blot.length();
      let offsetRight = length + offsetLeft;

      if (!lastSentence || lastSentence.endAt < offsetLeft) {
        tagSentences[tag].push({
          startAt: offsetLeft,
          endAt: offsetRight,
          content: quill.getText(offsetLeft, length),
          text: quill.getText(offsetLeft, length),
          tagList: [tag],
          editorId: `${tag}-${i}__${offsetLeft}--${offsetRight}`,
        });
      } else {
        Object.assign(lastSentence, {
          endAt: offsetRight,
          editorId: `${tag}-${i}__${lastSentence.startAt}--${offsetRight}`,
          content: quill.getText(lastSentence.startAt, offsetRight - lastSentence.startAt),
          text: quill.getText(lastSentence.startAt, offsetRight - lastSentence.startAt),
        });
      }
    });
  });

  for (let id in tagSentences) {
    sentences = [ ...sentences, ...tagSentences[id] ];
  }

  const output = [];

  sentences.forEach((sentence, i) => {
    if (sentence.used) return;

    const same = sentences.filter((s) => {
        return s.startAt === sentence.startAt &&
          s.endAt === sentence.endAt &&
          !sentence.used;
      });

    same.forEach((s, i) => {
      s.used = true;
    });

    const tagList = same.reduce((arr, sen) => {
      return [ ...arr, sen.tagList[0] ];
    }, []);

    output.push({
      ...sentence,
      tagList,
    });

    sentence.used = true;
  });

  return output;
}

export default {
  name: 'Editor',
  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    showTags: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'default',
    },
    tagsList: Array,
    tagsEnabled: {
      type: Boolean,
      default: true,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tags: [],
    }
  },
  mounted() {
    const { editor } = this.$refs;

    if (this.canEdit) {
      this.createScroll();
    }

    const tagsStyles = document.createElement('style');
    tagsStyles.type = 'text/css';
    document.head.appendChild(tagsStyles);
    this.tagsStyles = tagsStyles;

    this.refreshTagsDebounce();

    this.$el.__vue__ = this;
    
    if (this.canEdit) {
      this.checkEmptyState();
    }
  },
  watch: {
    value(value) {
      if (!this.canEdit) {
        return;
      }

      if (!this.hasFocus) {
        const delta = this.editor.clipboard.convert(value)
        this.editor.setContents(delta, 'silent');
        this.refreshTagsDebounce();
        this.checkEmptyState();
      }
    },
  },
  beforeUnmount() {
    if (!this.canEdit) {
      return;
    }

    document.removeEventListener('selectionchange', this.selectionChange);
    document.head.removeChild(this.tagsStyles);
  },
  updated() {
    refreshTagsPositionsDebounce();
  },
  methods: {
    setValue(value) {
      const delta = this.editor.clipboard.convert(value)
      this.editor.setContents(delta, 'silent');
      this.refreshTagsDebounce();
      this.checkEmptyState();
    },
    createScroll(value = this.value) {
      const { editor } = this.$refs;

      editor.innerHTML = this.value;

      const extraFormats = this.multiline ? ['list'] : [];

      const scroll = new Quill(editor, {
        //debug: 'info',
        formats: ['bold', 'italic', 'underline', 'link', 'tag', 'tspm', ...extraFormats ],
        modules: {
          clipboard: {
            allowed: {
                tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span', 'tspm'],
                attributes: ['href', 'rel', 'target', 'class'],
            },
            substituteBlockElements: true,
            magicPasteLinks: true,
          },
          history: false,
          keyboard: {
            bindings: {
              'exit list with enter': {
                key: 'Enter',
                empty: true,
                handler: (range, context) => {
                  if (!['default', ''].includes(this.type)) {
                    this.$emit('setType', '');
                    return false;
                  }
                  return true;
                },
              },
              'disable undo': {
                key: 'Z',
                shortKey: true,
                shiftKey: null,
                handler: (range, context) => {
                  return false;
                },
              },
            },
          },
        },
      });

      this.editor = scroll;
      editor.editor = scroll;
      editor.editorComponent = this;

      scroll.on('text-change', () => {
        this.onInput();
      })

      scroll.on('selection-change', (range) => {
        EventsService.emit('editor:selection-change', range);

        if (!range) {
          this.hasFocus = false;
          this.$emit('blur')
        } else {
          this.hasFocus = true;
          this.$emit('focus')
        }
      });

      if (!this.multiline) {
        scroll.keyboard.addBinding({
          key: ' ',
          shiftKey: null,
          prefix: /^\s*?(\d+\.|\[ ?\]|\[x\])$/,
        }, (range, context) => {
          scroll.deleteText(0, 2);
          this.$emit('setType', 'number-list');
        });

        scroll.keyboard.addBinding({
          key: ' ',
          shiftKey: null,
          prefix: /^\s*?(-|\*|\[ ?\]|\[x\])$/,
        }, (range, context) => {
          scroll.deleteText(0, 2);
          this.$emit('setType', 'bullet-list');
        });
      }
    },
    setType(type) {
      this.$emit('setType', type);
    },
    checkEmptyState() {
      if (this.editor.root.innerText.replace(/[\s\n]/gim, '').length) {
        this.$el.classList.remove('wysiwyg--empty');
      } else {
        this.$el.classList.add('wysiwyg--empty');
      }
    },
    onInput() {
      const { root } = this.editor;
      const before = [];
      const after = [];
      let newValue = undefined;
    
      const paragraphs = flattenParagraphs(root);

      let value = root.innerHTML;

      if (!this.multiline) {
        if (paragraphs.length > 1) {
          newValue = '';
          const selection = this.editor.getSelection() || { index: 0 };
          let focus = false;
          let currentIndex = 0;
          let focusIndex = 0;
          
          paragraphs.forEach((p, index) => {
            const temp = document.createElement('p');

            p.nodes.forEach((n) => {
              temp.appendChild(n.cloneNode(true));
            })

            temp.querySelectorAll('.ql-cursor').forEach((e) => e.remove());

            let content = temp.innerHTML;
            let text = temp.innerText;

            if (!text.replace(/\s\n/gim, '').length) {
              content = '<p></p>';
              text = '';
            }

            if ((selection.index >= currentIndex && selection.index < currentIndex + text.length) || index === paragraphs.length - 1) {
              focus = true;
              focusIndex = selection.index - currentIndex;
              newValue = content;
              value = content;
            } else {
              if (focus) {
                after.push({
                  ...p,
                  content,
                });
              } else {
                before.push({
                  ...p,
                  content,
                });
              }
            }

            currentIndex += text.length;
          })

          requestAnimationFrame(() => {
            this.editor.setSelection(focusIndex);
          })
          
          if (before.length || after.length) {
            this.$emit('insert-siblings', { before, after });
          }
        }
      }

      // Save empty value instead of empty html tags
      if (!root.innerText.replace(/[\s\n]/gim, '').length) {
        value = '';
      }

      this.$emit('change', { value, before, after });

      if (newValue !== undefined) {
        const delta = this.editor.clipboard.convert(`<p>${newValue}</p>`)
        this.editor.setContents(delta, 'silent');
      }

      this.refreshTagsDebounce();
      this.refreshSentences();

      this.checkEmptyState();
    },
    /**
     *
     */
     refreshTagsDebounce() {
      if (this.refreshTagsTimeout) {
        window.clearTimeout(this.refreshTagsTimeout);
      }

      this.refreshTagsTimeout = window.setTimeout(() => {
        this.refreshTags();
      }, 300);
    },

    /**
     *
     */
     refreshTags() {
      if (!this.showTags) return;
      const { tagsList } = this;
      const editor = this.$el.querySelector('.wysiwyg__editor') || this.$el.querySelector('.wysiwyg__content');
      if (!editor) return;
      const tagsEl = Array.from(editor.querySelectorAll('tag'));
      const tagsBox = this.$refs.tags;
      const editorRect = editor.getBoundingClientRect();
      if (!editorRect) return;
      const offsetY = editorRect.top;
      const tags = [];
      const contentTags = [];
      const classes = [];

      let prevY = null;
      let prevEndRect = null;
      let prevTags = null;
      let prevOffsetLeft = null;
      let prevOffsetRight = null;

  //    tagsBox.style.width = window.innerWidth - tagsBox.getBoundingClientRect().left + 'px';

      /**
       * Process each element
       */
      tagsEl.forEach((tagEl, i) => {
        const tagsList = (tagEl.dataset.tags || '').split(' ');
        const rects = tagEl.getClientRects();
        const endRect = rects[rects.length - 1];
        const startRect = rects[0];
        const y = rects[0].top - offsetY;

        const textOffset = getTextOffset(tagEl, editor);
        const offsetLeft = textOffset.left;
        const offsetRight = textOffset.right;

        let newAdded = 0;

        /**
         * Add new segment if previous tags list are different than current one or if previous
         * element ended on different position that current one. That means that new tag segment is
         * created everytime if we determine that current element is not tag continuation
         */
        if (
          !(
            prevTags &&
            prevTags.length === tagsList.length &&
            includesAll(prevTags, tagsList) &&
            prevOffsetRight &&
            prevOffsetRight >= offsetLeft -1
          )
        ) {

          /**
           * Here we're creating new tag segment
           */
          const newTag = {
            id: generateId(),
            tags: tagsList, // Initial segment tags list
            startRect: startRect, // Initial segment start position
            endRect: endRect, // Initial segment end position
            offsetLeft: offsetLeft,
            offsetRight: offsetRight,
            elements: [tagEl], // Initial segment elements list
          };

          contentTags.push(newTag);
          newAdded = 1;

          /**
           * We need to collect all previous segments that tags will match new segment.
           * "Match" means that previous segment end position is the same as new segment start
           * position and contains each new segment tag (overlap). We're searching for the longest
           * possible range
           */
          for (let i = 0, j = contentTags.length - 1; i < j; i++) {
            let entry = contentTags[i];
            if (
              includesAll(entry.tags, tagsList) && // Check tags
              entry.offsetRight >= newTag.offsetLeft - 1
            ) {
              newTag.startRect = entry.startRect;
              newTag.offsetLeft = entry.offsetLeft;
              newTag.elements.unshift(entry.elements[entry.elements.length - 1]);
            }
          }
        }

        /**
         * We need to append element to previous segments that matches current element tags and
         * position
         */
        for (let i = contentTags.length - 1 - newAdded; i >= 0; i--) {
          let entry = contentTags[i];
          if (
            includesAll(tagsList, entry.tags) &&
            entry.offsetRight >= offsetLeft - 1
          ) {
            entry.endRect = endRect;
            entry.offsetRight = offsetRight;
            entry.elements.push(tagEl);
          }
        }

        /**
         * Create simple tags list used in paragraph with tags Y positions
         */
        tagsList.forEach((id, i) => {
          const tagData = this.tagsList.find((t) => t.id === id);

          if (tagData) {
            if (!tags.find((i) => i.id === id)) {
              if (y === prevY) {
                tags.push({ ...tagData });
              } else {
                tags.push({
                  ...tagData,
                  y,
                })
              }
              prevY = y;
            }
          }
        });

        // Save all previous states for next tag calculations
        prevEndRect = endRect;
        prevTags = tagsList;
        prevOffsetLeft = offsetLeft;
        prevOffsetRight = offsetRight;
      });

      contentTags.forEach((contentTag) => {
        const colors = contentTag.tags
          .map((tag) => (tagsList.find((t) => t.id === tag) || {}).color)
          .filter((c) => !!c);
        const color = colors.length ? chroma.average(colors, 'lch') : false;

        if (color) {
          contentTag.elements.forEach((el, i) => {
            const sentenceId = el.dataset.sentenceId;
            classes.push(
              `.highlight-sentence-${ contentTag.id } tag[data-sentence-id="${ sentenceId }"]{
                background-color: ${ color.alpha(0.5) };
                border-color: ${ color.alpha(0.8) };
              }`
            );
          });
        }
      });

      tagsEl.forEach((tagEl) => {
        if (!tagEl.dataset.tags) {
          return;
        }

        let tags = tagEl.dataset.tags.split(' ');
        let sentenceId = tagEl.dataset.sentenceId;
        let colors = tags.map((tag) => {
          return (tagsList.find((t) => t.id === tag)||{}).color;
        }).filter((c) => !!c);
        let color = colors.length ? chroma.average(colors, 'lch') : chroma('rgba(0,0,0,0)');

        if (colors.length) {
          classes.push(
            `tag[data-sentence-id="${ sentenceId }"]{
              background-color: ${ color.alpha(0.05) };
              border-color: ${ color.alpha(0.3) };
            }`
          );
        } else {
          classes.push(
            `tag[data-sentence-id="${ sentenceId }"]{
              background-color: transparent;
              border: 0;
              padding: 0;
              cursor: inherit;
            }`,
            `tag[data-sentence-id="${ sentenceId }"]:hover{
              background-color: transparent;
              border: 0;
              padding: 0;
              cursor: inherit;
            }`
          );
        }
        

        tags.forEach((tag, i) => {
          let tagColor = (tagsList.find((t) => t.id === tag) || {}).color;
          let color = tagColor && chroma(tagColor);

          if (color) {
            classes.push(
              `.highlight-tag-${ tag } tag[data-sentence-id="${ sentenceId }"]{
                background-color: ${ color.alpha(0.5) };
                border-color: ${ color.alpha(0.8) };
              }`
            );
          } else {
            classes.push(
              `.highlight-tag-${ tag } tag[data-sentence-id="${ sentenceId }"]{
                background-color: transparent;
                border-color: transparent;
              }`
            );
          }
        });
      });

      this.tags = tags;
      this.contentTags = contentTags;
      this.tagsStyles.innerHTML = classes.join('');
    },
    refreshSentences() {
      const quill = this.editor;
      const sentences = getSentencesFromEditor(quill, this.$store.getters.allTags);
      if (JSON.stringify(this.sentences) !== JSON.stringify(sentences)) {
        this.sentences = sentences;
        this.$emit('content-tags-change', this.sentences);
      }
    },
    getTagSiblings(target) {
      if (!this.showTags) return {};
      const list = target.dataset.tags && target.dataset.tags.split(' ');

      if (!list) return { elements: null, tags: null };

      const entry = (this.contentTags || []).find((entry) => {
        return (
          entry.elements.includes(target) &&
          entry.tags.length === list.length &&
          includesAll(entry.tags, list)
        );
      });

      if (!entry) return { elements: null, tags: null };

      return { elements: entry.elements, tags: list, entry };
    },
    tagClicked(tag) {
      if (!this.canEdit) {
        return;
      }

      this.$store.dispatch('modalShow', {
        name: 'tag',
        data: {
          id: tag.id,
          filterProjectId: this.$store.getters.projectCurrentId,
        },
      });
    },
    editorClick(e) {
      let quill = this.editor;
      let selection = quill.getSelection();

      if (!quill || (selection && selection.length)) return;

      const { target } = e;

      // Select [] checklist
      if (target.tagName === 'LI' && target.dataset && target.dataset.checked) {
        target.setAttribute('data-checked', target.dataset.checked === 'true' ? 'false' : 'true');
      }

      let link = target;

      while(link.parentNode && link.tagName !== 'A') {
        link = link.parentNode;
      }

      if (link.tagName === 'A') {
        let actualRange = [0,0];
        const blot = Quill.find(link);
        if (!blot) return;

        actualRange = [blot.offset(quill.scroll), blot.length()];
        quill.setSelection(actualRange[0], actualRange[1]);

        EventsService.emit('editor:edit-link');

        e.preventDefault();

        return;
      }

      const parentTag = getParentTag(target);

      if (!parentTag) return;

      let { elements, tags } = this.getTagSiblings(parentTag);

      tags = tags.filter((id) => this.tags.find((t) => t.id === id));

      if (!elements || !tags || !tags.length) return;

      const blotStart = Quill.find(elements[0]);
      const blotEnd = Quill.find(elements[elements.length - 1]);
      if (!blotStart || !blotEnd) return;
      const blotStartOffset = blotStart.offset(quill.scroll);
      const blotEndOffset = blotEnd.offset(quill.scroll);
      const actualRange = [blotStartOffset, blotEndOffset + blotEnd.length() - blotStartOffset];
      quill.setSelection(actualRange[0], actualRange[1]);
      EventsService.emit('editor:open-tags');

      e.stopPropagation();
    },
    onMouseOver(e) {
      const { target } = e;
      const parentTag = getParentTag(target);

      if (!parentTag) return;

      const { entry } = this.getTagSiblings(parentTag);

      if (!entry) return;

      const className = `highlight-sentence-${ entry.id }`;

      if (this.sentenceHighlightClassname) {
        this.$el.classList.remove(this.sentenceHighlightClassname);
      }

      this.$el.classList.add(className);
      this.sentenceHighlightClassname = className;
    },
    onMouseOut(e) {
      if (this.sentenceHighlightClassname) {
        this.$el.classList.remove(this.sentenceHighlightClassname);
        this.sentenceHighlightClassname = undefined;
      }
    },
    onTagMouseEnter(tag) {
      this.$el.classList.add(`highlight-tag-${ tag.id }`);
    },
    onTagMouseLeave(tag) {
      this.$el.classList.remove(`highlight-tag-${ tag.id }`);
    },
    blur() {
      const editable = this.$el.querySelector('.ql-editor');
      editable.blur();
    },
    addTag(tag) {
      const quill = this.editor;
      quill.setSelection(0, quill.getLength());
      quill.format('tag', { tag: tag.id, selected: true }, 'user');
    },
    removeTag(tag) {
      const quill = this.editor;
      quill.setSelection(0, quill.getLength());
      quill.format('tag', { tag: tag.id, selected: false }, 'user');
    },
    focus(type = 'end') {
      const editable = this.$el.querySelector('.ql-editor');

      if (!editable || editable.classList.contains('editor-content')) return;

      editable.focus();

      if (parseInt(type) || parseInt(type) === 0) {
        setCurrentCursorPosition(editable, type);
        return;
      }

      if (type === 'start') {
        placeCaretAt(editable, true);
      } else {
        placeCaretAt(editable, false);
      }
    },
    getEditor() {
      return this.editor;
    },
    getValue() {
      return this.editor.root.innerHTML;
    },
  },
}
</script>