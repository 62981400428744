<template>
  <span
    class="scale"
    :class="[`scale--${ appearance }`]"
  >
    <span
      v-if="shouldShowPercent"
      class="scale__percent"
      :style="{ color }"
    >{{ percentText }}</span>
    <span
      class="scale__bar"
      :style="{ background: color, [sizeProp]: (max - min) === 0 ? 0 : (value - min) / (max - min) * 100 + '%' }"
    ><span
      v-if="shouldShowPercent"
      class="scale__percent scale__percent--over"
    >{{ percentText }}</span></span>
  </span>
</template>
<script>
export default {
  name: 'Scale',
  props: {
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    appearance: {
      type: String,
      required: false,
      default: 'default',
    },
    showPercent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    classes() {
      return {
      };
    },
    sizeProp() {
      return this.appearance.match(/vertical/) ? 'height' : 'width';
    },
    percentText() {
      return (((this.value - this.min) / (this.max - this.min) * 100) || 0).toFixed(2) + '%' + ` (${this.value})`
    },
    shouldShowPercent() {
      return this.showPercent && this.value > 0;
    },
  },
};
</script>
