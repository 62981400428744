<template>
  <BaseTooltip
    class="date-picker-dropdown"
    behaviour="click"
    appearance="light-box"
    @toggle="(state) => open = state"
  >
    <slot />
    <template v-slot:content>
      <div class="tooltip-title">
        Time range
      </div>
      <div class="date-picker">
        <ul class="date-picker__presets multiselect-list">
          <li>
            <a
              :class="presetActiveClass('lastWeek')"
              @click="setShortcut('lastWeek')"
            >Last week</a>
          </li>
          <li>
            <a
              :class="presetActiveClass('thisMonth')"
              @click="setShortcut('thisMonth')"
            >This month</a>
          </li>
          <li>
            <a
              :class="presetActiveClass('lastMonth')"
              @click="setShortcut('lastMonth')"
            >Last month</a>
          </li>
          <li>
            <a
              :class="presetActiveClass('thisYear')"
              @click="setShortcut('thisYear')"
            >This year</a>
          </li>
          <li>
            <a
              :class="presetActiveClass('lastYear')"
              @click="setShortcut('lastYear')"
            >Last year</a>
          </li>
          <li>
            <a
              :class="presetActiveClass('all')"
              @click="setShortcut('all')"
            >All time</a>
          </li>
        </ul>
        <div class="date-picker__picker">
          <Datepicker
            ref="picker"
            format="yyyy-mm-dd"
            :range="true"
            formatted="ll"
            locale="en"
            :no-button="true"
            :only-date="true"
            :no-header="true"
            :no-shortcuts="true"
            :clearable="false"
            :enable-time-picker="false"
            :value="value"
            :auto-apply="true"
            color="#007b83"
            :inline="true"
            @update:modelValue="onChange"
          />
        </div>
      </div>
    </template>
  </BaseTooltip>
</template>

<script>
import moment from 'moment';
import Datepicker from '@vuepic/vue-datepicker';

const PRESETS = {
  'lastWeek': 'Last week',
  'thisMonth': 'This month',
  'lastMonth': 'Last month',
  'thisYear': 'This year',
  'lastYear': 'Last year',
  'all': 'All',
};

const FORMAT = 'YYYY-MM-DD';

export default {
  name: 'DatePicker',
  components: {
    Datepicker,
  },
  props: {
    value: {
      type: [String, Object],
      required: true,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      shortcut: '',
    };
  },
  computed: {
  },
  methods: {
    isPresetActive(preset, value) {
      const range = value && {
        start: moment(value.start).format(FORMAT),
        end: moment(value.end).format(FORMAT),
      };
      const time = this.getShortcut(preset);

      return (
        time.start === range.start &&
        time.end === range.end
      );
    },
    presetActiveClass(preset) {
      const { value } = this;
      return { 'is-active': this.isPresetActive(preset, value) };
    },
    emitValue(value) {
      let { start, end } = value;
      const format = 'YYYY-MM-DD';

      start = start && moment(start).format(format);
      end = end && moment(end).format(format);
      
      let text = '';

      Object.keys(PRESETS).forEach((preset) => {
        if (this.isPresetActive(preset, value)) {
          text = PRESETS[preset];
        }
      });

      if (!text) {
        text = `${ start ? start : '' } - ${ end ? end : '' }`
      }

      this.$emit('change', (start || end) ? { start, end, text } : '');
    },
    setShortcut(value) {
      this.emitValue(this.getShortcut(value));
    },
    getShortcut(value) {
      switch (value) {
        case 'lastWeek':
          return {
            start: moment().subtract(1, 'weeks').startOf('week').format(FORMAT),
            end: moment().subtract(1, 'weeks').endOf('week').format(FORMAT),
            value,
          };
        case 'thisMonth':
          return {
            start: moment().startOf('month').format(FORMAT),
            end: moment().endOf('month').format(FORMAT),
            value,
          };
        case 'lastMonth':
          return {
            start: moment().subtract(1, 'months').startOf('month').format(FORMAT),
            end: moment().subtract(1, 'months').endOf('month').format(FORMAT),
            value,
          };
        case 'thisYear':
          return {
            start: moment().startOf('year').format(FORMAT),
            end: moment().endOf('year').format(FORMAT),
            value,
          };
        case 'lastYear':
          return {
            start: moment().subtract(1, 'years').startOf('year').format(FORMAT),
            end: moment().subtract(1, 'years').endOf('year').format(FORMAT),
            value,
          };
        case 'all':
          return '';
      }
    },
    onChange([start, end]) {
      this.emitValue({ start, end });
    },
  },
};
</script>
