<template>
  <BaseModal
    custom
    :name="name"
  >
    <div
      v-if="answer"
      :key="answer.id"
      class="modal-container answer-modal"
      :class="classes"
    >
      <main class="answer-modal__content">
        <TextInput
          v-if="canEdit"
          type="text"
          class="methods__list-answer-input answer-modal__header answer-modal__header--editable"
          :text="title"
          lazy
          tabindex="2"
          @focus="onFocus"
          @blur="onBlur"
          @change="titleChange"
        />
        <p
          v-else
          class="methods__list-answer-input answer-modal__header"
        >
          {{ title }}
        </p>
        <div class="method-edit">
          <Editor
            class="editor"
            :can-edit="canEdit"
            :value="text"
            :project-id="$route.params.project_id"
            :workspace-id="$store.getters.workspaceCurrentId"
            :container-class="'.modal-container'"
            drop-image=".answer-modal"
            @save="saveContent"
          />
        </div>
      </main>
      <section class="answer-modal__tags">
        <button
          type="button"
          class="modal__close-x answer-modal__exit"
          @click="hide"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
          >
            <path d="M13 0L1 12M1 0l12 12" />
          </svg>
        </button>
        <SidebarDetails
          v-if="answer.createdAt"
          class="answer-modal__ul"
          :data="answer"
        />
        <SidebarTags
          v-if="!answer.disableTags"
          v-model="answerTags"
          mode="tags"
          class="answer-modal__ul"
        />
        <SidebarTags
          v-if="!answer.disableRespondents"
          mode="responders"
          :value="answerResponders"
          class="answer-modal__ul"
          @input="onRespondersChange"
          @itemAdd="onResponderAdd"
          @itemRemove="onResponderRemove"
        />
      </section>
    </div>
  </BaseModal>
</template>

<script>
import Editor from 'components/common/editor';
import BaseModal from 'modals/base';
import { ANSWER_MODAL } from 'constants/modals';
import SidebarDetails from 'views/page/common/sidebar-details';
import SidebarTags from 'views/page/common/sidebar-tags';
import { mapGetters } from 'vuex';

export default {
  name: 'AnswerModal',
  components: {
    SidebarTags,
    SidebarDetails,
    Editor,
    BaseModal,
  },
  props: {
    isModalVisable: Boolean,
    testers: Array,
  },
  computed: {
    ...mapGetters(['userCanEditProjects', 'projectCurrent']),
    ...mapGetters({ answer: 'modalAnswer' }),
    name() {
      return ANSWER_MODAL;
    },
    text() {
      return this.answer[this.answer.pageParam];
    },
    answerTags: {
      get() {
        return this.answer.tags;
      },
      async set(value) {
        await this.$store.dispatch('cardModalUpdate', {
          id: this.answer.id,
          projectId: this.projectCurrent.id,
          transport: this.answer.transport,
          data: {
            tagList: value,
            tags: value,
          },
        });
      },
    },
    answerResponders() {
      return this.answer.responders;
    },
    title() {
      return this.answer[this.answer.valueParam];
    },
    canEdit() {
      return this.userCanEditProjects;
    },
    classes() {
      return {
        'answerModal-notActive': !this.isModalVisable,
        'answerModal-active': this.isModalVisable,
      };
    },
  },
  methods: {
    async onRespondersChange(responders) {
      await this.$store.dispatch('cardModalUpdate', {
        id: this.answer.id,
        projectId: this.projectCurrent.id,
        transport: 'none',
        data: {
          responders,
        },
      });
    },
    async saveContent({ content }) {
      this.$store.dispatch('cardModalUpdate', {
        id: this.answer.id,
        projectId: this.projectCurrent.id,
        transport: this.answer.transport,
        data: {
          [this.answer.pageParam]: content,
        },
      });
    },
    titleChange(e) {
      let value = e.target.value;

      if (!value) {
        value = this.initialValue;
        e.target.value = value;
      }

      this.$store.dispatch('cardModalUpdate', {
        id: this.answer.id,
        projectId: this.projectCurrent.id,
        transport: this.answer.transport,
        data: {
          [this.answer.valueParam]: value,
        },
      });
    },
    onFocus(e) {
      e.target.select();
      this.initialValue = this.answer[this.answer.valueParam];
    },
    onBlur(e) {
      this.titleChange(e);
    },
    hide() {
      this.$store.dispatch('modalHide');
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    onResponderAdd(responder) {
      this.$store.dispatch('builderAddResponder', {
        item: this.answer,
        responder,
      });
    },
    onResponderRemove(responder) {
      this.$store.dispatch('builderRemoveResponder', {
        item: this.answer,
        responder,
      });
    },
  },
};
</script>
<style lang="scss">
.answer-modal-modal-wrapper {
  max-width: 1150px;
  background-color: #fff;
  position: relative;
  border-radius: 3px;
}
</style>
<style lang="scss" scoped>

.answer-modal {
  display: flex;
  min-height: 670px;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    textarea {
      min-height: 31px;
    }
  }
  &__tags {
    position: relative;
    width: 320px;
    flex-shrink: 0;
    background-color: #F8F9FA;
    border-radius: 0 3px 3px 0;
  }
  &__header {
    flex-shrink: 0;
    line-height: 30px;
    max-width: 557px;
    font-size: 24px;
    color: #000000;
    margin-left: 112px;
    margin-top: 50px;
    padding-bottom: 8px;
    border-bottom: 1px dashed #ffffff;
    transition: border-bottom 0.25s;
    &--editable {
      &:hover {
        border-bottom: 1px dashed #8e8e8e;
      }
      &:active,
      &:focus {
        border-bottom: 1px dashed #1d1d1d;
      }
    }
  }
  &__description {
    font-size: 16px;
    line-height: 1.5;
    color: #3b3b3b;
    margin-left: 112px;
    margin-top: 42px;
  }
  &__exit {
    right: 13px;
    top: 13px;
  }
  &__tags-header {
    margin-top: 46px;
    margin-left: 33px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.39;
    color: #252729;
  }
  &__ul {
    list-style: none;
    padding: 0;
    margin: 20px 33px;
    li {
      list-style: none !important;
      margin-bottom: 30px;
      padding-left: 15px;
    }
  }
  &__li {
    color: #646464;
    font-family: Lato;
    font-size: 14px;
  }
  &__span {
    margin-left: 45px;
  }
}
.method-edit {
  flex-grow: 1;
  margin: 42px 112px;
  .editor {
    li {
      margin-left: 1000px;
    }
  }
}
.tags-in-modals {
  top: 106px;
  right: 255px;
}
.methods__list {
  ul {
    list-style-type: disc;
    li {
      list-style-type: disc;
    }
  }
  // li {
  //   margin-bottom: 0px !important;
  // }
}
@media only screen and (max-width: 767px) {
  .methods__list-answer-input {
    line-height: 2.5rem;
  }
  .modal__close-x {
    display: block;
    transform: translate(-1px, 40px);
    svg {
      width: 16px;
    }
  }
  .answer-modal {
    flex-direction: column;

    &__header {
      max-width: inherit;
    }

    &__content {
      min-height: 464px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      .answer-modal__header {
        margin-top: 33px;
        margin-left: 0px;
      }
      .method-edit {
        width: 100%;
        margin: 0px;
        margin-top: 25px;
      }
    }
    &__tags {
      position: static;
      min-height: 200px;
      width: 100%;
      &-header {
        margin-top: 30px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .answer-modal {
    &__content {
      padding-left: 33px;
    }
    &__header {
      width: 95%;
      margin-left: 0px;
      margin-top: 32px;
      max-width: none;
    }
    &__tags {
      min-width: 258px;
    }
    .method-edit {
      margin-left: 0;
      margin-top: 28px;
      .editor {
        width: 95%;
      }
    }
  }
}
</style>
