<template>
  <div>
    <Sortable
      handler-class="builder-draggable-field__drag"
      event-handler-class="section-wrap"
      group="pages"
      :data="fields"
      @dragend="onDragEnd"
      @dragin="onDragIn"
    >
      <TableItem
        v-for="field of fields"
        :key="$store.getters.idJoin(field.id)"
        :data-id="field.id"
      >
        <div class="builder-draggable-field">
          <div
            v-if="canEdit"
            class="builder-draggable-field__drag"
          >
            <Icon name="drag" />
          </div>
          <SideDropdownMenu
            :menu="canEdit && [
              {
                text: 'Delete',
                action: () => $store.dispatch('builderRemoveSectionItem', { sectionId: section.id, id: field.id }),
              }
            ]"
            appearance="outside"
            @toggle="open = $event"
          >
            <div class="builder-page">
              <PageAvatarPicker
                v-if="field.page"
                class="builder-page__avatar"
                :page="field.page"
                @color-change="changeColor(field, $event)"
                @icon-change="changeIcon(field, $event)"
                @removeImage="removeImage(field, $event)"
                @addImage="changeImage(field, $event)"
              />
              <PageAvatar
                v-else
                class="builder-page__avatar"
              />
              <router-link
                :to="navigateTo(field)"
                class="builder-page-link"
              >
                <div class="builder-page__body">
                  <span class="builder-page__name">{{ field.page && field.page.name }}</span>
                  <span class="builder-page__desc">Open to add details</span>
                </div>
              </router-link>
            </div>
          </SideDropdownMenu>
        </div>
      </TableItem>
    </Sortable>
    <TableItem key="new">
      <div
        ref="newItem"
        class="builder-page builder-page--new"
        @click="focusNew"
      >
        <PageAvatar class="builder-page__avatar" />
        <div class="builder-page__body">
          <BaseInput
            ref="newInput"
            :value="newName"
            @input="newName = $event"
            class="builder-page__input"
            placeholder="Add..."
            @keyup.enter="onSubmit"
          />
        </div>
        <div
          v-if="newName"
          class="enter-input-icon"
        />
      </div>
    </TableItem>
  </div>
</template>

<script>
import { PROJECT_METHOD, PROJECT_METHODS } from 'constants/routes';
import { byPosition } from 'helpers/sort';
import { shake } from 'helpers/ui';

export default {
  name: 'ProjectMethodCustom',
  components: {
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      newName: '',
    };
  },
  computed: {
    fields() {
      return byPosition(this.section.customItemFields);
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    onSubmit() {
      if (!this.newName) {
        shake(this.$refs.newItem);
        return;
      }

      this.$store.dispatch('builderAddSectionItem', { id: this.section.id, data: {
        name: this.newName,
        page_attributes: {
          name: this.newName,
          position: 0,
        },
      } });

      this.newName = '';
    },
    changeColor(page, color) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id: page.id, data: { page: { color } } });
    },
    changeIcon(page, icon) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id: page.id, data: { page: { icon } } });
    },
    focusNew(e) {
      this.$refs.newInput.focus();
      e.stopPropagation();
    },
    navigateTo(field) {
      if (field && field.page && field.page.id) {
        return {
          name: PROJECT_METHOD,
          params: {
            project_id: this.$store.getters.projectCurrentId,
            method_id: field.page.id,
          },
        };
      }

      else if (this.$store.getters.projectMethodCurrentId) {
        return {
          name: PROJECT_METHOD,
          params: {
            project_id: this.$store.getters.projectCurrentId,
            method_id: this.$store.getters.projectMethodCurrentId,
          },
        };
      }

      return {
        name: PROJECT_METHODS,
        params: {
          project_id: this.$store.getters.projectCurrentId,
        },
      };
    },
    async onDragEnd({ index, dragData }) {
      const item = dragData[0];

      this.$store.dispatch('builderMoveSectionItem', {
        newSectionId: this.section.id,
        sectionId: this.section.id,
        id: item.id,
        newIndex: index,
      });
    },
    async onDragIn({ dragData, index, group }) {
      if (group === 'pages') {
        const item = dragData[0];

        if (item) {
          this.$store.dispatch('builderMoveSectionItem', {
            newSectionId: this.section.id,
            sectionId: item.customMethodItemId,
            id: item.id,
            newIndex: index,
          });
        }
      }
    },
    changeImage(page, image) {
      this.$store.dispatch('builderUpdateSectionItemImage', {
        sectionId: this.section.id,
        id: page.id,
        image,
      });
    },
    removeImage(page) {
      this.$store.dispatch('builderRemoveSectionItemImage', {
        sectionId: this.section.id,
        id: page.id,
      });
    },
  },
};
</script>
