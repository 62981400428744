<template>
  <div
    v-if="isProjectsFetched && workspaceCurrent"
    class="sidebar-margin"
  >
    <div class="workspace-container">
      <div class="builder-title">
        <PageAvatarPicker
          class="builder-title__avatar"
          :page="workspaceCurrent"
          :image-only="true"
          :can-edit="userCanManageTeams"
          @removeImage="removeImage"
          @addImage="changeImage"
        />
        <TitleInput
          appearance="project-title"
          :can-edit="userCanManageTeams"
          :value="workspaceCurrent.name"
          @change="onNameChange"
        />
      </div>

      <div class="workspace-section">
        <Headline>Recent Projects</Headline>
        <BaseTable
          v-if="filteredProjects && filteredProjects.length"
          :animated="true"
          appearance="projects expand"
        >
          <TableItem key="new" v-if="userCanEditProjects">
            <NewProject @create="createNew" />
          </TableItem>
          <TableItem
            v-for="project in filteredProjects"
            :key="project.id"
          >
            <Project :data="project" />
          </TableItem>
        </BaseTable>
        <div
          v-else
          class="empty-list"
        >
          <div>
            Projects will appear here.<br>
            <a
              class="link link--primary"
              @click.prevent.stop="createNew"
              v-if="userCanEditProjects"
            >Create first project</a>
          </div>
        </div>
      </div>

      <div class="workspace-section">
        <Headline>Recent Insights</Headline>
        <div
          v-if="recentInsights && recentInsights.length"
          class="insight-items-list"
        >
          <InsightItem
            v-for="insight of recentInsights"
            :key="insight.id"
            :insightId="insight.id"
            @open="showInsight(insight)"
          />
        </div>
        <div
          v-else
          class="empty-list"
        >
          <div>
            Insights will appear here.<br>
            <a
              class="link link--primary"
              href="https://app.gitbook.com/s/f6TTHtqfhSncw8j9Kg6Y/insights#create-a-new-insight"
              v-if="userCanEditProjects"
            >How to create insights?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BaseSpinner
    v-else
    :full-height="true"
  />
</template>

<script>
import InsightItem from 'views/project-insights/item';
import { UPGRADE } from 'constants/modals';
import { PROJECT_NEW } from 'constants/routes';
import { timeSinceText } from 'helpers/time';
import { mapGetters } from 'vuex';
import { analyticsLogEvent } from 'utils/analytics';

import NewProject from 'components/workspace/new-project';
import Project from 'components/workspace/project';

export default {
  name: 'WorkspaceRecent',
  components: {
    Project,
    NewProject,
    InsightItem,
  },
  data() {
    return {
      creating: false,
    }
  },
  computed: {
    ...mapGetters([
      'projects',
      'workspaceCurrent',
      'isProjectsFetched',
      'workspaceInsights',
      'userCanManageTeams',
      'projectsLimitReached',
      'userCanEditProjects',
    ]),
    filteredProjects() {
      return this.projects.filter((p) => !p.removed).slice(0,10);
    },
    recentInsights() {
      return this.workspaceInsights && this.workspaceInsights.slice(0,6);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('requireWorkspaces');
      await this.$store.dispatch('projectsFetch');
      await this.$store.dispatch('workspaceInsightsFetch');

      for(let project of this.projects) {
        this.$store.dispatch('unsetPublicToken', { id: project.id });
      }
    },
    insightBreadcrumb(card) {
      return timeSinceText(new Date(card.updatedAt));
    },
    async createNew() {
      if (this.creating) return;

      if (this.projectsLimitReached) {
        this.$store.dispatch('modalShow', {
          name: 'limit-reached',
          data: {
            kind: 'projects',
          },
        });

        return;
      }

      this.newClicked = true;

      this.creating = true;
      const name = PROJECT_NEW;

      this.$router.push({
        name,
      });

      analyticsLogEvent('project_create_recent');
    },
    onNameChange(name) {
      name = name.trim();

      this.$store.dispatch('workspaceChange', {
        id: this.workspaceCurrent.id,
        data: { name },
      });
    },
    changeImage(image) {
      this.$store.dispatch('workspaceChangeImage', {
        id: this.workspaceCurrent.id,
        image,
      });
    },
    removeImage() {
      this.$store.dispatch('workspaceRemoveImage', {
        id: this.workspaceCurrent.id,
      });
    },
    showInsight(insight) {
      this.$store.dispatch('modalShow', {
        name: 'method',
        data: {
          id: insight.id,
          projectId: insight.projectId, 
        },
      });
    },
  },
};
</script>
