const ADDRESS = 'Address';
const CITY = 'City';
const COMPANY_NAME = 'Company name';
const COUNTRY = 'Country';
const NAME = 'Name';
const POST_CODE = 'Postal code';
const TAX_NUMBER = 'TAX ID';

export const INVOICE_SETUP = {
  [ADDRESS]: 'address',
  [CITY]: 'city',
  [COMPANY_NAME]: 'companyName',
  [COUNTRY]: 'country',
  [NAME]: 'firstName',
  [POST_CODE]: 'postCode',
  [TAX_NUMBER]: 'taxNumber',
};

export const INVOICE_PLACEHOLDERS = {
  [ADDRESS]: 'e.g. 1479 Folsom Street',
  [CITY]: 'e.g. San Francisco',
  [COMPANY_NAME]: 'e.g. Google INC',
  [NAME]: 'e.g. Josh Doe',
  [POST_CODE]: 'e.g. 91104',
  [TAX_NUMBER]: 'Your EU TAX number',
};
