<template>
  <div class="sidebar-margin">
    <BaseSpinner
      v-if="!insightsFetched"
      :full-height="true"
    />
    <div
      v-else
      class="workspace-container"
    >
      <TitleInput
        appearance="project-title"
        :can-edit="false"
        value="All Insights"
      />
      <template v-if="workspaceInsights && workspaceInsights.length">
        <div class="wide-menu">
          <div class="grid grid--middle grid--between">
            <div class="grid-col" />
            <div class="grid-col">
              <div class="tag-filters">
                <div class="filter">
                  <span class="filter__name">Projects:</span>
                  <RespondersDropdown
                    :value="filterProjects"
                    @input="filterProjects = $event"
                    value-span-class="filter__value"
                    heading=""
                    list="projects"
                    :can-add="false"
                    :can-select-all="false"
                    :can-deselect-all="true"
                    :reverse-all="true"
                    value-format="text"
                  />
                </div>
                <div class="filter">
                  <span class="filter__name">Tags:</span>
                  <RespondersDropdown
                    :value="filterTags"
                    @input="filterTags = $event"
                    value-span-class="filter__value"
                    heading=""
                    list="tags"
                    :can-add="false"
                    :can-select-all="false"
                    :can-deselect-all="true"
                    :reverse-all="true"
                    value-format="text"
                  />
                </div>
                <div class="filter">
                  <span class="filter__name">Sort by:</span>
                  <DropdownMenu
                    class="filter__value"
                    :menu="[
                      {
                        text: 'Created',
                        action: () => setSortBy('created'),
                        selected: sortBy === 'created',
                      },
                      {
                        text: 'Tags',
                        action: () => setSortBy('tags'),
                        selected: sortBy === 'tags',
                      },
                      {
                        text: 'Project',
                        action: () => setSortBy('project'),
                        selected: sortBy === 'project',
                      },
                    ]"
                  >
                    {{ capitalize(sortBy) }}
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="workspace-section">
          <div
            v-if="insightsFiltered && insightsFiltered.length"
            class="insight-items-list"
          >
            <InsightItem
              v-for="insight of insightsFiltered"
              :key="insight.id"
              :insight-id="insight.id"
              :showProjectName="true"
              @open="showInsight(insight)"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="empty-info">
          <div>
            <Icon
              class="empty-info__icon"
              name="sticker-note"
            />
            <strong class="empty-info__heading">No insights found</strong>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InsightItem from 'views/project-insights/item';
import { capitalize } from 'helpers/string';
import { sortByParam } from 'helpers/sort';
import { sortAndFilterByProps } from 'helpers/filter';
import { mapGetters } from 'vuex';

export default {
  name: 'WorkspaceRecent',
  components: {
    InsightItem,
  },
  data() {
    return {
      sortBy: 'created',
      filterTags: [],
      filterProjects: [],
    };
  },
  computed: {
    ...mapGetters([
      'projects',
      'workspaceInsights',
    ]),
    insightsFiltered() {
      return sortAndFilterByProps(this.workspaceInsights, {
        sortBy: this.sortBy,
        tags: this.filterTags,
        projects: this.filterProjects,
      });
    },
    insightsFetched() {
      return !!this.workspaceInsights;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('workspaceInsightsFetch');
      await this.$store.dispatch('projectsFetch');
      await this.$store.dispatch('requireTags');
    },
    showInsight(insight) {
      this.$store.dispatch('modalShow', {
        name: 'method',
        data: {
          id: insight.id,
          projectId: insight.projectId, 
        },
      });
    },
    setSortBy(sortBy) {
      this.sortBy = sortBy;
    },
    capitalize,
  },
};
</script>
