export const load = (key, session = false) => {
  if (!key) {
    return null;
  }

  const data = (session ? sessionStorage : localStorage).getItem(key);
  if (!data) {
    return null;
  }

  try {
    const result = JSON.parse(data);
    return result;
  } catch (e) {
    return null;
  }
};

export const save = (key, data, session = false) => {
  if (!key) {
    return;
  }

  try {
    (session ? sessionStorage : localStorage).setItem(key, JSON.stringify(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
