<template>
  <transition
    mode="out-in"
    name="swipe-right"
  >
    <div
      v-if="visible"
      class="sidebar"
      :class="classNames"
    >
      <perfect-scrollbar
        ref="body"
        class="sidebar__scroll"
        @ps-scroll-y="onBodyScroll"
      >
        <div
          class="sidebar__body"
          :class="scrollClasses"
        >
          <div
            ref="content"
            class="sidebar__body-scrollable"
          >
            <HeaderLogo class="sidebar__logo" />
            <ul class="sidebar-menu">
              <li>
                <router-link
                  class="sidebar-link"
                  :class="{ 'sidebar-link--active': isRoute(WORKSPACE_RECENT) }"
                  :to="{ name: WORKSPACE_RECENT }"
                >
                  <Icon name="clock" /> Recent
                </router-link>
              </li>
              <li>
                <router-link
                  class="sidebar-link"
                  :class="{ 'sidebar-link--active': isRoute(PROJECTS_LIST) }"
                  :to="{ name: PROJECTS_LIST }"
                >
                  <Icon name="book" /> Projects
                </router-link>
              </li>
              <li>
                <router-link
                  class="sidebar-link"
                  :class="{ 'sidebar-link--active': isRoute(WORKSPACE_TAGS) }"
                  :to="{ name: WORKSPACE_TAGS }"
                >
                  <Icon name="tag-outline" /> Tags
                </router-link>
              </li>
              <li>
                <router-link
                  class="sidebar-link"
                  :class="{ 'sidebar-link--active': isRoute(WORKSPACE_INSIGHTS) }"
                  :to="{ name: WORKSPACE_INSIGHTS }"
                >
                  <Icon name="diamond-ring" /> All Insights
                </router-link>
              </li>
              <li>
                <router-link
                  class="sidebar-link"
                  :class="{ 'sidebar-link--active': isRoute(WORKSPACE_SETTINGS) }"
                  :to="{ name: WORKSPACE_SETTINGS }"
                  @click="onSettingsOpen"
                >
                  <Icon name="settings" /> Settings & team
                </router-link>
              </li>
            </ul>
            <transition name="fade">
              <div
                v-if="isProjectPhasesFetched && isProjectFetched && !isProjectForbidden"
                class="sidebar__project"
              >
                <router-link
                  :to="{ name: PROJECT_METHODS }"
                  class="sidebar__project-name"
                >
                  {{ projectName }}
                </router-link>
                <div class="sidebar-dropdown">
                  <router-link
                    :to="{ name: PROJECT_METHODS }"
                    class="sidebar-link"
                    :class="{
                      'sidebar-link--active': isProjectMethodsRoute,
                    }"
                  >
                    <span
                      class="sidebar-link__arrow-icon"
                      :class="{
                        'sidebar-link__arrow-icon--active': isMenuOpen('methods'),
                      }"
                      @click.stop.prevent="toggleMenu('methods')"
                    >
                      <Icon name="arrow-full-down" />
                    </span>
                    Pages
                  </router-link>
                  <ContentTransition :multiple="true">
                    <div
                      v-if="isMenuOpen('methods')"
                      key="methods"
                      class="sidebar-dropdown__content"
                    >
                      <ul class="sidebar-menu">
                        <transition-group
                          type="transition"
                          name="enter-list-tall"
                        >
                          <li
                            v-for="phase in projectPhasesFiltered"
                            :key="phase.id"
                            class="sidebar-methods__phase"
                          >
                            <div class="sidebar-link sidebar-link--ghost">
                              {{ phase.name }}
                              <router-link
                                v-if="canEdit"
                                :to="navigateToNewPhase(phase)"
                                @click="onNewMethodClick(phase.name)"
                              >
                                <span class="sidebar-link__action-icon">
                                  <Icon name="plus-small" />
                                </span>
                              </router-link>
                            </div>
                            <div class="sidebar-menu">
                              <Sortable
                                class="sidebar-methods__methods-draggable"
                                :disabled="!canEdit"
                                :can-drag-to="['sidebar-methods']"
                                group="sidebar-methods"
                                :data="phase.designMethods"
                                :data-phase-id="phase.id"
                                @dragstart="onMethodDragStart"
                                @dragend="onMethodDragEnd"
                                @dragin="onMethodDragEnd"
                              >
                                <div
                                  v-for="method of phase.designMethods"
                                  :key="$store.getters.idJoin(method.id)"
                                  class="sidebar-methods__method-wrapper"
                                  :data-id="method.id"
                                >
                                  <router-link
                                    class="sidebar-link"
                                    :class="{
                                      'sidebar-link--active': projectCurrentMethodId === method.id || newMethodId === method.id,
                                    }"
                                    :to="navigateToMethod(method.id)"
                                  >
                                  <div class="sortable-handler" v-if="userCanEditProjects">
                                    <Icon name="drag" />
                                  </div>
                                    <MethodIcon
                                      v-if="newMethodId !== method.id"
                                      :method="getMethod(method.id)"
                                    />
                                    <span v-if="newMethodId === method.id">New method</span>
                                    <span v-else>{{ getMethod(method.id).name }}</span>
                                  </router-link>
                                </div>
                                <div
                                  v-if="shouldDisplayNewMethod(phase) && !isCreatingMethod"
                                  :key="newMethodId"
                                >
                                  <span
                                    class="sidebar-link sidebar-link--active"
                                  >
                                    New page
                                  </span>
                                </div>
                              </Sortable>
                            </div>
                          </li>
                        </transition-group>
                      </ul>
                    </div>
                  </ContentTransition>
                </div>
                <div class="sidebar__foot">
                  <router-link
                    :to="{ name: PROJECT_HIGHLIGHTS }"
                    class="sidebar-link"
                    :class="{
                      'sidebar-link--active': isProjectHighlightsRoute,
                    }"
                  >
                    <Icon name="edit-3" /> Highlights
                  </router-link>
                  <router-link
                    :to="{ name: PROJECT_INSIGHTS }"
                    class="sidebar-link"
                    :class="{
                      'sidebar-link--active': isProjectInsightsRoute,
                    }"
                  >
                    <Icon name="diamond-ring" /> Insights
                    <InsightAnimation />
                  </router-link>
                  <router-link
                    :to="{ name: PROJECT_REPORT }"
                    class="sidebar-link"
                    :class="{
                      'sidebar-link--active': isProjectReportRoute,
                    }"
                    @click="onReportOpen"
                  >
                    <Icon name="report" /> Report
                  </router-link>
                  <router-link
                    v-if="canEdit"
                    :to="{ name: PROJECT_ARCHIVE }"
                    class="sidebar-link"
                    :class="{
                      'sidebar-link--active': isProjectArchiveRoute,
                    }"
                    @click="onArchiveOpen"
                  >
                    <Icon name="archive-alt" /> Archive
                    <ArchivingAnimation />
                  </router-link>
                  <router-link
                    v-if="canEdit && userCanManageTeams"
                    :to="{ name: PROJECT_TEAM }"
                    class="sidebar-link"
                    :class="{
                      'sidebar-link--active': isProjectTeamRoute,
                    }"
                  >
                    <Icon name="access" /> Access
                  </router-link>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </perfect-scrollbar>
      <transition name="fade">
        <div class="sidebar-limits" v-if="shouldShowLimit">
          <div class="sidebar-limits__title">{{ userPlanText }} plan</div>
          <div class="progress-bar">
            <div
              class="progress-bar__bar"
              :style="{ width: `${transcriptionLimitPercent}%` }"
            />
          </div>
          <div class="sidebar-limits__text">{{ transcriptionLimitMin }}/120 min transcription used this month</div>
          <BaseButton
            appearance="primary sm"
            @click="showUpgrade"
            v-if="isTrialUser"
          >
            Upgrade
          </BaseButton>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import HeaderLogo from 'components/header/logo';
import { PROJECT_ARCHIVE, PRICE_PLANS, PROJECT_HIGHLIGHTS, PROJECT_INSIGHTS, PROJECT_METHOD, PROJECT_METHOD_NEW, PROJECT_METHODS, PROJECT_REPORT, PROJECT_TAG, PROJECT_TAGS, PROJECT_TEAM, PROJECTS_LIST, WORKSPACE_TAGS, WORKSPACE_INSIGHTS, WORKSPACE_RECENT, WORKSPACE_SETTINGS } from 'constants/routes';
import { TAG_COLORS } from 'constants/tag-colors';
import { navigateToTag } from 'helpers/router';
import { sortByName } from 'helpers/tags';
import sample from 'lodash/sample';
import { analyticsLogEvent } from 'utils/analytics';
import { mapGetters } from 'vuex';

import ArchivingAnimation from './archiving-animation';
import InsightAnimation from './insight-animation';

export default {
  name: 'HeaderSidebar',
  components: {
    ArchivingAnimation,
    HeaderLogo,
    InsightAnimation,
  },
  data() {
    return {
      menuOpen: {
        methods: true,
        tags: false,
      },
      isScrolledTop: false,
      isScrolledBottom: false,
      transitionEnabled: true,
    };
  },
  computed: {
    ...mapGetters([
      'projectCurrent',
      'projectCurrentId',
      'projectPhases',
      'methodCurrent',
      'projectCurrentMethodId',
      'isProjectPhasesFetched',
      'userCanEditProjects',
      'userCanManageTeams',
      'tags',
      'isCardDragging',
      'newMethodId',
      'isCreatingMethod',
      'isArchiving',
      'isProjectFetched',
      'isProjectForbidden',
      'isShared',
      'isSidebarOpen',
      'transcriptionLimit',
      'userPlan',
      'isTrialUser',
    ]),
    userPlanText() {
      return this.userPlan && this.$store.getters.getPlanName(this.userPlan);
    },
    shouldShowLimit() {
      return this.userPlan;
    },
    transcriptionLimitMin() {
      return this.transcriptionLimit ? 120 - Math.floor(this.transcriptionLimit / 60) : 0;
    },
    transcriptionLimitPercent() {
      return Math.round(this.transcriptionLimitMin / 120 * 100)
    },
    projectPhasesFiltered() {
      return this.projectPhases.filter((p) => !p.archived).map((p) => ({
        ...p,
        designMethods: p.designMethods.filter((m) => !this.getMethod(m.id).archived),
      }));
    },
    classNames() {
      return {
        'sidebar--open': this.isSidebarOpen,
      };
    },
    canEdit() {
      return this.userCanEditProjects;
    },
    visible() {
      return !this.isShared && !this.$route.name.match(/(sign-in)|(workspace-onboarding)|(project-onboarding)|(password-reset)|(accept-invitation)/);
    },
    scrollClasses() {
      return {
        'sidebar__body--scrolledTop': this.isScrolledTop,
        'sidebar__body--scrolledBottom': this.isScrolledBottom,
      };
    },
    sortedTags() {
      return sortByName(this.tags);
    },
    isProjectTagsRoute() {
      return this.$store.getters.isRoute(PROJECT_TAGS);
    },
    isProjectHighlightsRoute() {
      return this.$store.getters.isRoute(PROJECT_HIGHLIGHTS);
    },
    inAddNewMethod() {
      return this.$store.getters.isRoute(PROJECT_METHOD_NEW);
    },
    isProjectMethodsRoute() {
      return this.$store.getters.isRoute(PROJECT_METHODS);
    },
    isProjectInsightsRoute() {
      return this.$store.getters.isRoute(PROJECT_INSIGHTS);
    },
    isProjectTeamRoute() {
      return this.$store.getters.isRoute(PROJECT_TEAM);
    },
    isProjectArchiveRoute() {
      return this.$store.getters.isRoute(PROJECT_ARCHIVE);
    },
    isProjectMethodRoute() {
      return this.$store.getters.isRoute(PROJECT_METHOD);
    },
    isProjectTagRoute() {
      return this.$store.getters.isRoute(PROJECT_TAG);
    },
    isProjectReportRoute() {
      return this.$store.getters.isRoute(PROJECT_REPORT);
    },
    PROJECTS_LIST() {
      return PROJECTS_LIST;
    },
    PROJECT_TAGS() {
      return PROJECT_TAGS;
    },
    PROJECT_METHODS() {
      return PROJECT_METHODS;
    },
    PROJECT_TEAM() {
      return PROJECT_TEAM;
    },
    WORKSPACE_SETTINGS() {
      return WORKSPACE_SETTINGS;
    },
    PROJECT_ARCHIVE: () => PROJECT_ARCHIVE,
    PROJECT_REPORT: () => PROJECT_REPORT,
    WORKSPACE_RECENT: () => WORKSPACE_RECENT,
    WORKSPACE_TAGS: () => WORKSPACE_TAGS,
    WORKSPACE_INSIGHTS: () => WORKSPACE_INSIGHTS,
    PROJECT_HIGHLIGHTS() {
      return PROJECT_HIGHLIGHTS;
    },
    PROJECT_INSIGHTS() {
      return PROJECT_INSIGHTS;
    },
    draggableOptions() {
      return {
        draggable: '.sidebar-methods__method-wrapper',
        disabled: !this.canEdit,
        forceFallback: true,
        animation: 150,
        ghostClass: 'sortable-ghost',
        chosenClass: 'sortable-chosen',
        dragClass: 'sortable-drag',
        touchStartThreshold: 3,
        fallbackTolerance: 2,
        direction: 'vertical',
        delay: 200,
        delayOnTouchOnly: true,
        emptyInsertThreshold: 5,
      };
    },
    transitionDisabled() {
      return !this.transitionEnabled || this.isCardDragging;
    },
    projectName() {
      if (this.projectCurrent && this.projectCurrent.name) {
        return this.projectCurrent.name;
      }
      return '';
    },
  },
  watch: {
    $route(route) {
      this.checkRoute();
    },
    visible(visible){
      if (visible) {
        document.body.classList.add('sidebar-mounted');
      } else {
        document.body.classList.remove('sidebar-mounted');
      }
    },
    projectCurrentId(currentId) {
      if (currentId) {
        this.$store.dispatch('phasesFetch');
      }
    },
  },
  mounted() {
    if (this.visible) {
      document.body.classList.add('sidebar-mounted');
    } else {
      document.body.classList.remove('sidebar-mounted');
    }
    if (this.projectCurrentId) {
      this.fetchData();
    }
    this.checkRoute();
    this.onBodyScroll();
    this.interval = window.setInterval(() => {
      this.onBodyScroll();
    }, 32);
  },
  unmounted() {
    document.body.classList.remove('sidebar-mounted');
    window.clearInterval(this.interval);
  },
  methods: {
    async fetchData() {
      await Promise.all([
        this.$store.dispatch('projectFetch'),
        this.$store.dispatch('phasesFetch'),
        this.$store.dispatch('requireTags'),
      ]);
    },
    onMethodDragStart() {
      this.$store.dispatch('uiSetCardDragging', true);
    },
    async onMethodDragEnd(data) {
      const id = data.ids[0];
      const phaseId = data.area.getAttribute('data-phase-id');

      await this.$store.dispatch('projectMethodChangeList', {
        id,
        phaseId,
        index: data.index,
      });

      this.$store.dispatch('uiSetCardDragging', false);
    },
    navigateToMethod(methodId) {
      return {
        name: PROJECT_METHOD,
        params: {
          project_id: this.projectCurrentId,
          method_id: methodId,
        },
      };
    },
    checkRoute() {
      if ((this.isProjectTagRoute || this.isProjectTagsRoute) && !this.isMenuOpen('tags')) {
        this.toggleMenu('tags');
      }
      if ((this.isProjectMethodRoute || this.isProjectMethodsRoute) && !this.isMenuOpen('methods')) {
        this.toggleMenu('methods');
      }
    },
    navigateToTag(id) {
      return {
        name: PROJECT_TAG,
        params: {
          project_id: this.projectCurrentId,
          tag_id: id,
        },
      };
    },
    toggleMenu(id) {
      if (this.menuOpen[id]) {
        this.menuOpen[id] = false;
        return;
      }

      this.menuOpen[id] = true;
    },
    isMenuOpen(id) {
      return this.menuOpen[id];
    },
    async addTag() {
      const randomColor = sample(TAG_COLORS);
      const newTag = {
        name: this.$store.getters.newTagName,
        color: randomColor,
      };
      const created = await this.$store.dispatch('tagAdd', newTag);
      navigateToTag(created.id);
    },
    navigateToNewPhase(phase) {
      return {
        name: PROJECT_METHOD_NEW,
        params: {
          project_id: this.projectCurrentId,
          phase_id: phase.id,
        },
      };
    },
    onNewMethodClick() {
      analyticsLogEvent(`sidebar_new_page`);
    },
    shouldDisplayNewMethod(phase) {
      return this.inAddNewMethod && (this.$route.params.phase_id === phase.id);
    },
    getMethod(id) {
      return this.$store.getters.projectMethodById(id) || {};
    },
    onBodyScroll() {
      let { body, content } = this.$refs;

      if (!body || !content) return;

      body = body.$el;

      const top = body.scrollTop;
      const bodyHeight = body.offsetHeight;
      const contentHeight = content.offsetHeight;
      let isBottom = false;
      let isTop = false;

      if (top === 0) {
        isTop = true;
      }

      if (top >= contentHeight - bodyHeight) {
        isBottom = true;
      }

      this.isScrolledTop = isTop;
      this.isScrolledBottom = isBottom;
    },
    onReportOpen() {
      analyticsLogEvent('Report page open');
    },
    onArchiveOpen() {
      analyticsLogEvent('Archive page open');
    },
    isRoute(route) {
      return this.$store.getters.isRoute(route);
    },
    onSettingsOpen() {
      analyticsLogEvent('open_settings');
    },
    showUpgrade() {
      this.$router.push({ name: PRICE_PLANS });
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar :global(.v-leave-active) {
  display: none !important;
}
</style>
