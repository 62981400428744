<template>
  <span
    class="base-checkbox"
    :class="classes"
    @click="onClick"
  >
    <Icon name="check-white" />
  </span>
</template>
<script>
export default {
  name: 'Checkbox',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    appearance: {
      type: String,
      default: 'default',
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    classes() {
      const appearance = 'base-checkbox--' + this.appearance.split(' ').join(' base-checkbox--');
      return [appearance, {
        'base-checkbox--checked': !!this.checked,
        'base-checkbox--locked': !this.canEdit || this.disabled,
      }];
    },
  },
  methods: {
    onClick(e) {
      if (!this.canEdit) return;
      this.$emit('input', !this.checked);
    },
  },
};
</script>
