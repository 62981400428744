<template>
  <div
    v-if="!isLoading"
    class="sidebar-margin"
  >
    <div class="workspace-container workspace-container--wide">
      <TitleInput
        appearance="project-title centered no-margin-bottom"
        :can-edit="false"
        value="Create new project"
      />
      <p class="p p--nl p--center p--no-margin tcolor--subtitle">
        Start from scratch or choose one of our predefined templates
      </p>
      <div class="new-projects__wrapper">
        <div
          class="new-project__box"
          data-aos="fade-down"
          @click="createNew('ProjectBrief')"
        >
          <img
            src="/static/img/svg/new-product-blank.svg"
            width="104"
            height="104"
          >
          <p class="project-box__name">
            Blank
          </p>
          <p class="new-project__count">
            0 Pages
          </p>
        </div>

        <div
          class="new-project__box"
          data-aos="fade-up"
          @click="createNew('NewProjectWorkshop')"
        >
          <img
            src="/static/img/svg/new-product-usability.svg"
            width="84"
            height="91"
          >
          <p class="project-box__name">
            Usability Test
          </p>
          <p class="new-project__count">
            4 Pages
          </p>

          <div class="new-project__preview">
            <BaseTooltip
              appearance="light-box"
              behaviour="click"
            >
              <div class="new-project__preview-btn">
                Preview
              </div>
              <template v-slot:content>
                <PreviewContent
                  :data="{
                    title: 'Usability Test',
                    phases: [
                      {
                        name: 'Introduction',
                        methods: [
                          {
                            type: 'NotepadMethod',
                            name: 'Research plan',
                          }
                        ]
                      },
                      {
                        name: 'Interviews',
                        methods: [
                          {
                            type: 'StakeholderInterview',
                            name: 'Interview Script',
                          }
                        ]
                      },
                      {
                        name: 'Analysis',
                        methods: [
                          {
                            type: 'ProblemValuation',
                            name: 'Problem Valuation',
                          },
                          {
                            type: 'SolutionValuation',
                            name: 'Solution Valuation',
                          }
                        ]
                      }
                    ]
                  }"
                />
              </template>
            </BaseTooltip>
          </div>
        </div>

        <div
          class="new-project__box"
          data-aos="fade-down"
          @click="createNew('KickoffWorkshop')"
        >
          <img
            src="/static/img/svg/kickoff-workshop.svg"
            width="92"
            height="75"
          >
          <p class="project-box__name">
            Discovery Phase
          </p>
          <p class="new-project__count">
            8 Pages
          </p>

          <div class="new-project__preview">
            <BaseTooltip
              appearance="light-box"
              behaviour="click"
            >
              <div class="new-project__preview-btn">
                Preview
              </div>
              <template v-slot:content>
                <PreviewContent
                  :data="{
                    title: 'Discovery Phase',
                    phases: [
                      {
                        name: 'Phase 1 - Inspiration',
                        methods: [
                          {
                            type: 'StakeholderInterview',
                            name: 'Stakeholder Interview',
                          },
                          {
                            type: 'UserInterview',
                            name: 'User Interview',
                          },
                          {
                            type: 'CompetitorAnalysis',
                            name: 'Competitor Analysis',
                          }
                        ]
                      },
                      {
                        name: 'Phase 2 - Analyze',
                        methods: [
                          {
                            type: 'PersonasMethod',
                            name: 'Personas',
                          },
                          {
                            type: 'ProblemValuation',
                            name: 'Problem Valuation',
                          },
                          {
                            type: 'SolutionValuation',
                            name: 'Solution Valuation',
                          },
                          {
                            type: 'FlowsMethod',
                            name: 'User Flows',
                          },
                        ]
                      },
                      {
                        name: 'Phase 3 - Prototype',
                        methods: [
                          {
                            type: 'InteractivePrototype',
                            name: 'Paper Prototype',
                          },
                          {
                            type: 'InteractivePrototype',
                            name: 'Interactive Prototype',
                          },
                        ]
                      }
                    ]
                  }"
                />
              </template>
            </BaseTooltip>
          </div>
        </div>
      </div>
      <a
        class="btn btn--outline btn--corners new-project__back-btn"
        @click="navigateProjects"
      >Cancel</a>
    </div>
  </div>
  <BaseSpinner v-else />
</template>

<script>
import PROJECT_NAMES from 'constants/project-names';
import { NEW_PRODUCT_RESEARCH, USABILITY_TESTING } from 'constants/project-templates';
import { METHODS_HELP, PROJECT_METHODS } from 'constants/routes';
import { navigateProjects } from 'helpers/router';
import { sample } from 'lodash';
import mapConst from 'utils/map-const';
import { mapGetters } from 'vuex';
import { analyticsLogEvent } from 'utils/analytics';

import PreviewContent from './preview-content';

export default {
  name: 'ProjectNew',
  components: {
    PreviewContent,
  },
  data: () => ({
    creating: false,
    fetching: false,
  }),
  computed: {
    ...mapConst({
      METHODS_HELP,
      NEW_PRODUCT_RESEARCH,
      USABILITY_TESTING,
    }),
    ...mapGetters([
      'isProjectsFetched',
    ]),
    isLoading() {
      return !this.isProjectsFetched || this.creating;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('projectsFetch');
    },
    async createNew(template) {
      const type = null;
      if (this.creating) return;

      this.creating = true;
      const project = await this.$store.dispatch('projectAddData', {
        name: sample(PROJECT_NAMES),
        template,
      });
      this.handleCreation(project.id);
      analyticsLogEvent(`project_create_template_${template}`);
    },
    handleCreation(id) {
      this.navigate(id);
    },
    navigate(id) {
      const name = PROJECT_METHODS;
      this.$router.push({
        name,
        params: {
          project_id: id,
        },
      });
    },
    navigateProjects() {
      navigateProjects();
    },
  },
};
</script>
