<template>
  <div @keydown="onKeyDown">
    <div
      class="builder-content"
      :class="{
        'builder-content--editable': editable,
      }"
    >
      <div
        class="builder-list-wrap"
        :class="{
          'drop-enabled': dragOverActive,
          'builder-empty': isEmpty,
          'builder-dragging': dragging,
        }"
      >
        <Sortable
          :value="sections"
          :options="draggableOptions"
          @start="onDragStart"
          @end="onDragEnd"
        >
          <div
            v-for="(section, index) in sections"
            :key="$store.getters.idJoin(section.id)"
            :data-id="section.id"
            class="drop-wrap section-wrap"
            :class="[
              `section-wrap--${ section.type }`,
              section.type !== 'paragraph' && `section-wrap--not-paragraph`,
            ]"
            :data-local="section.local"
          >
            <div>
              <BuilderSection
                ref="section"
                :survey-mode="surveyMode"
                :section="section"
                :index="index"
                :can-edit="editable"
                :data-id="section.id"
                :data-index="index"
                @split="onSplit(index, section, $event)"
                @toggle="draggingDisabled = $event"
                @selectTitle="onSelectTitle"
              />
              <div
                v-if="editable"
                :data-index="index"
                class="drop-target drop-target--top"
                @drop="onTargetDrop"
                @dragover="onTargetDragOver"
                @dragleave="onTargetDragLeave"
              />
              <div
                v-if="editable"
                :data-index="index + 1"
                class="drop-target drop-target--bottom"
                @drop="onTargetDrop"
                @dragover="onTargetDragOver"
                @dragleave="onTargetDragLeave"
              />
            </div>
          </div>
        </Sortable>
      </div>
      <div
        v-if="editable"
        class="drop-last"
        :data-index="sections.length"
        @drop="onTargetDrop"
        @dragover="onTargetDragOver"
        @dragleave="onTargetDragLeave"
      />
      <transition name="slide-up">
        <div
          v-if="shouldShowMultitagSelector"
          class="builder-multitag"
          :style="{ top: `${lastSelectedY}px` }"
          @mousedown.stop
        >
          <Responders
            :header-enabled="false"
            list="tags"
            @itemSelect="multitagSelect"
            @itemUnselect="multitagUnselect"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* global System */

import { navigateProject } from 'helpers/router';
import { byPosition } from 'helpers/sort';
import $ from 'jquery';
import { analyticsLogEvent } from 'utils/analytics';
import { mapGetters } from 'vuex';

import BuilderSection from './section';

function placeCaretAt(el, atStart = false) {
    el.focus();
    if (typeof window.getSelection != 'undefined'
            && typeof document.createRange != 'undefined') {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(atStart);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != 'undefined') {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(atStart);
        textRange.select();
    }
}

function setCaretPosition(elem, caretPos) {
    if(elem != null) {
      elem.blur();
        if(elem.createTextRange) {
            var range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if(elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            }
            else
                elem.focus();
        }
    }
}

function elementDistanceToCursor(elem, mouse) {
  var b = elem.getBoundingClientRect();
  var dx = 0;
  var dy = 0;
  var sideX = 0;
  var sideY = 0;

  if (mouse.x < b.left) {
    dx = b.left - mouse.x;
    sideX = -1;
  } else if (mouse.x > b.right) {
    dx = b.right - mouse.x;
    sideX = 1;
  } else {
    sideX = 0;
  }

  if (mouse.y < b.top) {
    dy = b.top - mouse.y;
    sideY = -1;
  } else if (mouse.y > b.bottom) {
    dy = b.bottom - mouse.y;
    sideY = 1;
  } else {
    sideY = 0;
  }

  return {
    dist: Math.floor(Math.sqrt(dx * dx + dy * dy)),
    sideY,
    sideX,
  };
}

function getCaretPosInput(oField) {
  // Initialize
  var iCaretPos = 0;

  // IE Support
  if (document.selection) {

    // Set focus on the element
    oField.focus();

    // To get cursor position, get empty selection range
    var oSel = document.selection.createRange();

    // Move selection start to 0 position
    oSel.moveStart('character', -oField.value.length);

    // The caret position is selection length
    iCaretPos = oSel.text.length;
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart == '0')
    iCaretPos = oField.selectionDirection=='backward' ? oField.selectionStart : oField.selectionEnd;

  // Return results
  return iCaretPos;
}

function getCaretPosElement(ele) {
  let _range = document.getSelection().getRangeAt(0);
  let range = _range.cloneRange();
  range.selectNodeContents(ele);
  range.setEnd(_range.endContainer, _range.endOffset);
  return range.toString().length;
}

function setCaretOnEdge(elem, type = 'end') {
  if (['INPUT', 'TEXTAREA'].includes(elem.tagName)) {
      setCaretPosition(elem, type === 'start' ? 0 : elem.value.length);
  } else if (elem.contentEditable === 'true') {
    placeCaretAt(elem, type === 'start');
  } else {
    elem.focus();
  }
}

export { setCaretOnEdge };

function getCaretPos(oField) {
  if (['INPUT', 'TEXTAREA'].includes(oField.tagName)) {
    return getCaretPosInput(oField);
  } else {
    return getCaretPosElement(oField);
  }
}

function isHidden(el) {
  return (el.offsetParent === null || !el.offsetWidth)
}

export default {
  name: 'ProjectMethodCustom',
  components: {
    BuilderSection,
  },
  props: {
    method: Object,
    surveyMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      headerText: '',
      methodId: null,
      selectedMethod: null,
      isDeleteModalVisible: false,
      newType: 'cards',
      newColor: '#abc0c8',
      newName: '',
      dragOver: false,
      draggingDisabled: false,
      dragging: false,
      transitionEnabled: true,
      startSelectSection: null,
      selecting: false,
      lastSelectedY: 0,
    };
  },
  watch: {
    method: {
      deep: true,
      handler(current) {
        this.headerText = current.name;
      },
    },
  },
  created() {
    this.headerText = this.method.name;
    this.methodId = this.method.id;
  },
  mounted() {
    document.addEventListener('mousemove', this.documentMouseMove, false);
    document.addEventListener('mouseup', this.documentMouseUp, false);
    document.addEventListener('mousedown', this.documentMouseDown, false);
    document.addEventListener('click', this.documentClick, false);
  },
  beforeUnmount() {
    document.removeEventListener('mousemove', this.documentMouseMove, false);
    document.removeEventListener('mouseup', this.documentMouseUp, false);
    document.removeEventListener('mousedown', this.documentMouseDown, false);
    document.removeEventListener('click', this.documentClick, false);
  },
  computed: {
    ...mapGetters({
      selectedSections: 'selectedSections',
      canEdit: 'userCanEditProjects',
      isFetched: 'isProjectCurrentMethodFetched',
      projectCurrentId: 'projectCurrentId',
      isShared: 'isShared',
      dragOverActive: 'dragOverActive',
      isCardDragging: 'isCardDragging',
    }),
    isEmpty() {
      return !this.sections.find((s) => !s.local && (s.type !== 'paragraph' || (s.type === 'paragraph' && s.content)));
    },
    name() {
      return this.method.name;
    },
    areButtonsVisible() {
      return this.canEdit;
    },
    editable() {
      return this.canEdit && !this.isShared && !this.surveyMode;
    },
    sections() {
      return byPosition(this.method.customMethodItems);
    },
    selectedSections() {
      return [];
    },
    draggableOptions() {
      return {
        disabled: this.draggingDisabled && !this.dragging || !this.editable,
        handle: '.builder-section__title, .builder-paragraph__drag, .addon-image',
        ghostClass: 'drag-ghost',
        fallbackClass: 'drag-static-fallback',
        animation: 150,
        direction: 'vertical',
        fallbackOnBody: false,
        forceFallback: true,
        touchStartThreshold: 3,
        fallbackTolerance: 2,
        delay: 200,
        delayOnTouchOnly: true,
        emptyInsertThreshold: 5,
        group: {
          name: 'builder-sections',
          pull: true,
        },
      };
    },
    transitionDisabled() {
      return !this.transitionEnabled || this.isCardDragging;
    },
    shouldShowMultitagSelector() {
      let canShow = false;

      this.selectedSections.forEach((section, i) => {
        let el = $(`.builder-section[data-id="${section.id}"]`)[0];
        if (el && el.__vue__) {
          let component = el.__vue__;

          if (component.$refs.addon && component.$refs.addon.taggable) {
            canShow = true;
          }
        }
      });

      return !this.selecting && this.selectedSections.length > 1 && this.lastSelectedY && canShow;
    },
  },
  methods: {
    onNameChange(name) {
      name = name.trim();
      const data = name.length === 0 ? { name: this.oldName } : { name };
      this.$store.dispatch('builderMethodChange', {
        id: this.method.id,
        data,
      });
      this.headerText = data.name;
    },
    onNameFocus() {
      this.oldName = this.headerText;
    },
    onNameBlur() {
      this.headerText = this.headerText.trim();
      if (!this.headerText) {
        this.headerText = this.oldName;
      }
    },
    onHideModal() {
      this.isDeleteModalVisible = false;
    },
    onArchiveMethod(method) {
      const { id } = method;
      const projectId = this.projectCurrentId;

      this.selectedMethod = false;
      this.$store.dispatch('projectMethodChange', { id, data: { archived: true } });

      navigateProject(projectId);
    },
    onNewTypeChange(type) {
      this.newType = type;
    },
    onNewColorChange(color) {
      this.newColor = color;
    },
    onNewNameChange(name) {
      this.newName = name;
    },
    onAddSection({ type, color, index }) {
      this.$store.dispatch('builderCreateSection', { type, color, index });
    },
    async onSplit(index, section, data) {
      data = {
        ...data,
        focus: 'start',
      };
      await this.$store.dispatch('builderCreateSection', { ...section, data, index: index + 1 });
    },
    onDragStart() {
      this.dragging = true;
      this.startSelectSection = null;
      this.transitionEnabled = false;
      this.$store.dispatch('uiSetCardDragging', true);
      this.$store.dispatch('builderSelectSections', { start: null, end: null });
      $('input, textarea, [contenteditable], .base-uploader').blur();
    },
    async onDragEnd(e) {
      const id = e.item.dataset.id;

      await this.$store.dispatch('builderMoveSection', { id, index: e.newIndex });

      this.dragging = false;
      this.transitionEnabled = true;
      this.$store.dispatch('uiSetCardDragging', false);
    },
    documentMouseDown(e) {
      if(!this.editable) return;

      let $section = $('.builder-section').filter((i, e) => !!e.offsetHeight);
      let $method = $('.method-page');
      let target = null;

      if (e.pageX > $method.offset().left) {
        $section.each((i, section) => {
          let offset = $(section).offset().top;

          if (e.pageY > offset) {
            target = section;
          }
        });
      }

      if (target) {
        let id = target.dataset.id;
        this.startSelectSection = id;
        this.selecting = true;
      } else {
        this.startSelectSection = null;
      }

      this.$store.dispatch('builderSelectSections', { start: null, end: null });

      this.prevKeyShortcut = null;
      this.moved = false;
      this.startX = e.clientX;
      this.startY = e.clientY;
    },
    documentMouseUp() {
      if(!this.editable) return;

      this.startSelectSection = null;
      this.startX = null;
      this.startY = null;
      this.selecting = false;

      if ($('.builder-section--selected').length) {
        const last = $('.builder-section--selected').filter((i, e) => !!e.offsetHeight).last();
        const parent = $('.builder-content').offset();
        this.lastSelectedY = last.offset().top + last.outerHeight() - parent.top;

        // Prevent Quill menu blink after unselect multiple items
        $('.ql-container').each((i, e) => {
          if (e.__quill) {
            e.__quill.theme.tooltip.hide(true);
          }
        });

        $('*:focus, .ql-editor').blur();

        document.activeElement &&
        document.activeElement.blur();
      } else {
         this.lastSelectedY = null;
      }
    },
    documentMouseMove(e) {
      if(!this.editable) return;

      const { clientX, clientY } = e;
      const { startSelectSection } = this;
      const $section = $('.builder-section').filter((i, e) => !!e.offsetHeight);
      let actualSelected = startSelectSection;

      if (this.startX === null) return;

      let movedDist = Math.sqrt((this.startX - e.clientX)**2 + (this.startY - e.clientY)**2);

      if (movedDist > 9) {
        this.moved = true;
      }

      if (!startSelectSection || this.dragging) {
        this.$store.dispatch('builderSelectSections', { start: null, end: null });
        return;
      }

      $section.each((index, single) => {
        let rect = single.getBoundingClientRect();

        if ((clientY > rect.top || index === 0) && (clientY <= rect.bottom || index === $section.length - 1) && rect.height && rect.width) {
          actualSelected = single.dataset.id;
        }
      })

      if (actualSelected !== startSelectSection) {
        this.$store.dispatch('builderSelectSections', { start: startSelectSection, end: actualSelected });

        // Prevent Quill menu blink after unselect multiple items
        $('.ql-container').each((i, e) => {
          if (e.__quill) {
            e.__quill.theme.tooltip.hide(true);
          }
        });

        $('*:focus, .ql-editor').blur();

        document.activeElement &&
        document.activeElement.blur();
      } else {
        this.$store.dispatch('builderSelectSections', { start: null, end: null });
      }
    },
    documentClick(e) {
      if(!this.editable) return;
      const $parent = $(e.target).closest('textarea, [contenteditable="true"], input, .base-uploader');

      if ($parent.length || this.moved) return;

      const mouse = { x: e.clientX, y: e.clientY };
      const $editable = $('.builder-content').find('textarea, [contenteditable="true"], input').filter((i, el) => {
        return !$(el).parents('.ql-tooltip').length;
      });

      let closest = null;

      $editable.each((i, elem) => {
        const dist = elementDistanceToCursor(elem, mouse);
        if (!closest || closest.dist.dist > dist.dist) {
          closest = {
            elem,
            dist,
          }
        }
      });

      if (closest) {
        if ($(closest.elem).parents('.base-card').length) {
          // Ignore cards
        } else if (['INPUT', 'TEXTAREA'].includes(closest.elem.tagName)) {
          if (closest.dist.sideY === -1 || closest.dist.sideX === -1) {
            setCaretPosition(closest.elem, 0);
          } else {
            setCaretPosition(closest.elem, closest.elem.value.length);
          }
        } else if (closest.dist.sideY === -1 || closest.dist.sideX === -1) {
          placeCaretAt(closest.elem, true);
        } else {
          placeCaretAt(closest.elem, false);
        }
      }
    },
    onKeyDown(e) {
      if(!this.editable) return;

      const { target } = e;
      const $inputs = $('.method-page').find('input, textarea, [contenteditable], .base-uploader');
      const isInput = ['INPUT', 'TEXTAREA'].includes(target.tagName);
      const contentEditable = target.contentEditable === 'true';
      let value = isInput ? target.value : target.textContent.replace(/^(<[A-z]+>)+/gim, '').replace(/(<\/[A-z]+>)+$/gim, '');
      let caretPos = getCaretPos(target);
      let nextInput, prevInput, found;
      let selectionLength = 0;
      let selection = window.getSelection();

      if (selection) {
        selectionLength = selection.toString().length;
      }

      if (value === '<br>') value = '';

      if (!contentEditable && !isInput) {
        value = '';
        caretPos = 0;
      };

      $inputs.each((index, input) => {
        if (isHidden(input)) return;

        if (input === target) {
          found = true;
        } else if (found) {
          nextInput = input;
          return false;
        } else {
          prevInput = input;
        }
      });

      if (e.key ==='a' && (e.metaKey || e.ctrlKey)) {
        let valueLength, selectionLength;

        if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
          valueLength = target.value.length;
          selectionLength = target.selectionEnd - target.selectionStart;
        } else {
          valueLength = target.innerHTML.length;
          const selection = window.getSelection();
          const range = selection.getRangeAt(0);
          const clonedSelection = range.cloneContents();
          const div = document.createElement('div');
          div.appendChild(clonedSelection);
          // Add extra 7 characters for <p></p>
          selectionLength = div.innerHTML.length + 7;
        }

        if (this.prevKeyShortcut === 'select_all' || selectionLength && valueLength <= selectionLength) {
          this.selectAll();
          e.preventDefault();
        }

        this.prevKeyShortcut = 'select_all';
      } else if (!['Meta', 'Control'].includes(e.key)) {
        this.prevKeyShortcut = null;
        this.$store.dispatch('builderSelectSections', { start: null, end: null });
      }

      if (value === undefined) return;

      if (e.key === 'Enter') {
        if (nextInput && $(target).parents('.builder-section__title').length) {
          setCaretOnEdge(nextInput, 'start');
          e.preventDefault();
          return;
        }

        if (nextInput && (
          $(target).parents('.addon-summary__item:not(.builder-new-input)').length ||
          $(target).parents('.addon-charts__item:not(.builder-new-input)').length ||
          $(target).parents('.addon-checklist__item:not(.builder-new-input)').length
        )) {
          setCaretOnEdge(nextInput, 'end');
          e.preventDefault();
          return;
        }
      }

      if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
        if (caretPos === 0) {
          if (prevInput) {
            setCaretOnEdge(prevInput, 'end');
            e.preventDefault();
            return;
          }
        }
      }

      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        if (caretPos >= value.length && !selectionLength) {
          if (nextInput) {
            setCaretOnEdge(nextInput, 'start');
            e.preventDefault();
            return;
          }
        }
      }
    },
    selectAll() {
      const sections = this.sections;
      this.$store.dispatch('builderSelectSections', { start: sections[0].id, end: sections[sections.length - 1].id });
    },
    onTargetDragOver(e) {
      if(!this.editable) return;

      const { target } = e;
      target.classList.add('drop-target--active');
      e.preventDefault();
    },
    onTargetDragLeave(e) {
      if(!this.editable) return;

      const { target } = e;
      target.classList.remove('drop-target--active');
      e.preventDefault();
    },
    async onTargetDrop(e) {
      if(!this.editable) return;

      const { target } = e;
      const index = target.dataset.index;

      target.classList.remove('drop-target--active');
      const response = await this.$store.dispatch('builderCreateSection', { type: 'image', index });
      this.$store.dispatch('builderAddSectionImage', {
        id: response.id,
        image: e.dataTransfer.files[0],
      });

      e.preventDefault();
    },
    selectLast() {
      const section = this.$refs.section[this.$refs.section.length - 1];

      if (section) {
        section.focus('end');
      }
    },
    async onTitleEnter() {
      this.$refs.title.blur();
      const data = {
        focus: 'start',
      };
      await this.$store.dispatch('builderCreateSection', { type: 'paragraph', data, index: 0 });
    },
    onTitleKeydown(e) {
      if (e.key === 'ArrowDown') {
        this.$refs.section[0].focus('start');
        e.preventDefault();
      }
    },
    onSelectTitle() {
      this.$refs.title.focus();
    },
    exportPDF() {
      this.$store.dispatch('exportPDF', { id: this.method.id });
      analyticsLogEvent('Export PDF - Notepad');
    },
    multitagSelect(tag) {
      const selectedIds = this.selectedSections.map((s) => s.id);

      this.$refs.section.forEach((section) => {
        if (selectedIds.includes(section.$attrs['data-id'])) {
          section.addTag(tag);
        }
      });
    },
    multitagUnselect(tag) {
      const selectedIds = this.selectedSections.map((s) => s.id);

      this.$refs.section.forEach((section) => {
        if (selectedIds.includes(section.$attrs['data-id'])) {
          section.removeTag(tag);
        }
      });
    },
  },
};
</script>
<style lang="scss">
  .drop-target {
    $self: &;
    width: 100%;
    position: absolute;
    left: 0;
    height: 50%;
    opacity: 0;
    display: none;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: #abc0c8;
      top: 0;
      left: 0;
      margin-top: -3px;
      border-radius: 2px;
    }

    &--top {
      top: 0;
    }

    &--bottom {
      top: 50%;

      &:before {
        top: 100%;
      }
    }

    &--active {
      opacity: 1;
    }
  }

  .drop-enabled .drop-target {
    display: block;
  }

  .drop-enabled {
    .base-uploader {
      z-index: 950;
    }
  }

  .drop-wrap {
    position: relative;
  }

  .drop-last {
    height: 340px;
    opacity: 0;
    position: relative;
    cursor: text;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: #abc0c8;
      top: 0;
      left: 0;
      margin-top: -3px;
      border-radius: 2px;
    }

    &.drop-target--active {
      opacity: 1;
    }
  }

  .builder-content {
    cursor: text;
    min-height: calc(100vh - 154px);
  }

  .builder-list-wrap {
    cursor: default;
  }

  .section-wrap--paragraph + .section-wrap--paragraph[data-local="true"] {
    display: none;
  }

  .section-wrap--paragraph[data-local="true"] + .section-wrap--paragraph {
    margin-top: -42px;
  }

  .section-wrap--not-paragraph + .section-wrap--not-paragraph {
    margin-top: 42px;
  }

  .builder-dragging {
    .section-wrap--paragraph[data-local="true"] {
      opacity: 0;
    }

  }
</style>
