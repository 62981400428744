import * as ApiService from 'services/api.service';
import { from as parseQuery } from 'utils/query-params';

class TempUserService {
  init(store, router) {
    this.$store = store;
    this.$router = router;
  }

  async handleAccessTokenUrl() {
    const accessToken = this.getAccessTokenFromUrl();
    // if has local token then merge
    if (
      this.$store.getters.hasToken &&
      this.$store.getters.token !== accessToken
    ) {
      try {
        await this.mergeTokens(accessToken);
      } catch (error) {
      }
    } else {
      this.$store.dispatch('userToken', accessToken);
    }

    return true;
  }

  getAccessTokenFromUrl() {
    // have to parse manually as router route is not yet populated
    return parseQuery(window.location.search.substr(1)).access_token;
  }

  hasAccessTokenInUrl() {
    return !!this.getAccessTokenFromUrl();
  }

  // wait until there is info if user came from public link
  waitForIsSharedLoaded() {
    return new Promise((resolve) =>
      this.$store.watch(
        (state, getters) => getters.isSharedLoading,
        (isLoading) => !isLoading && resolve()
      )
    );
  }
}

export default new TempUserService();
