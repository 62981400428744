<template>
  <div
    class="archive-item archive-item--method"
    :data-method="slug"
  >
    <span class="archive-item__body">
      <span class="archive-item__icon method-item__icon">
        <Icon :name="`method-${ slug }`" />
      </span>
      <div>
        {{ data.name }}
      </div>
      <div class="archive-item__foot">
        {{ getCounts() }}
      </div>
    </span>
  </div>
</template>

<script>
import { slugify } from 'utils/text';

export default {
  name: 'ProjectArchiveItemMethod',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({

  }),
  computed: {
    slug() {
      return slugify(this.data.type);
    },
  },
  methods: {
    getCounts() {
      return '0';
    },
  },
};
</script>
<style lang="scss" scoped>
.method-item__icon {
  width: 36px;
  height: 36px;
  font-size: 36px;
  margin-right: 23px;
}
</style>
