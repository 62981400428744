import { BLANK_ID } from 'views/page/constants';

export const sortByName = (tags) => {
  return tags.slice(0).sort((a, b) => {
    if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });
}

export const sortByItemsCount = (tags) => {
  return sortByName(tags).reverse().slice(0).sort((a, b) => {
    const aCount = a.itemsCount || a.counter || 0;
    const bCount = b.itemsCount || b.counter || 0;
    
    if(aCount < bCount) return -1;
    if(aCount > bCount) return 1;
    return 0;
  }).reverse();
}

export const sortItemsByTagsNumber = (items) => {
  return items.slice(0).sort((a, b) => {
    if(a.tags.length < b.tags.length) return -1;
    if(a.tags.length > b.tags.length) return 1;
    return 0;
  }).reverse();
}

export const sortItemsByDateCreated = (items) => {
  return items.slice(0).sort((a, b) => {
    if(a.createdAt < b.createdAt) return -1;
    if(a.createdAt > b.createdAt) return 1;
    return 0;
  }).reverse();
}

export const sortItemsByDateModified = (items) => {
  return items.slice(0).sort((a, b) => {
    if(a.updatedAt < b.updatedAt) return -1;
    if(a.updatedAt > b.updatedAt) return 1;
    return 0;
  }).reverse();
}

export const updateTagsList = (list, tags) => {
  return list.map((tag) => {
    const found = tags.find((t) => t.id === tag.id) || (tag.id === BLANK_ID && tag);
    if (!found) return null;
    return {
      ...found,
    };
  }).filter((t) => !!t);
}
