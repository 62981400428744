import { PAGE_RECORD_BY_TYPE } from 'constants/methods';
import * as ApiService from 'services/api.service';

class PagesService {
  async fetch({ type, parentId }) {
    return ApiService.get(this.getUrl(type, parentId));
  }

  add(data) {
    return this.update(data);
  }

  update({ type, content = '', parentId }) {
    if (!parentId) {
      return Promise.reject(new Error('Parent is empty'));
    }

    return ApiService.put(this.getPageUrl(type, parentId), {
      content,
    });
  }

  updatePage({ type, page = '', parentId }) {
    if (!parentId) {
      return Promise.reject(new Error('Parent is empty'));
    }

    return ApiService.put(this.getUrl(type, parentId), {
      page,
    });
  }

  remove({ type, parentId }) {
    if (!parentId) {
      return Promise.reject(new Error('Parent is empty'));
    }
    return ApiService.del(this.getUrl(type, parentId));
  }

  titleChange({ parentId, type, title }) {
    return ApiService.put(this.getUrl(type, parentId), {
      parentContent: title,
    });
  }

  getPageUrl(type, id) {
    return `api/${this.getRecordType(type)}/${id}/page`;
  }

  getUrl(type, id) {
    return `api/${this.getRecordType(type)}/${id}`;
  }

  getRecordType(type) {
    if (PAGE_RECORD_BY_TYPE.hasOwnProperty(type)) {
      return PAGE_RECORD_BY_TYPE[type];
    }

    return type;
  }

  getMethodTypeByRecord(type) {
    if (!type) return null;

    const result = Object.entries(PAGE_RECORD_BY_TYPE).find(
      ([, record]) => record === type
    );
    if (!result) return null;

    return result[0];
  }
}

export default new PagesService();
