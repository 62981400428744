import Quill from 'quill';
const Inline = Quill.import('blots/inline');

export default class Timestamp extends Inline {
  static blotName = 'tspm';
  static tagName = 'tspm';

  static create(value) {
    let node = super.create();

    node.setAttribute('data-st', value.start);
    node.setAttribute('data-et', value.end);

    return node;
  }

  static formats(node) {
    var blot = {};

    blot.start = node.dataset.st;
    blot.end = node.dataset.et;

    return blot;
  }
}