<template>
  <BaseModal
    name="tag"
    :show-close="true"
    @active="active = $event"
    @data="data = $event"
  >
    <Forbidden v-if="isProjectForbidden" />
    <div
      v-else
      :key="tagId"
    >
      <div
        v-if="isTagFetched"
        class="tag-layout__container container"
      >
        <TitleInput
          :value="name"
          class="tag-title"
          appearance="project-title"
          :can-edit="userCanEditProjects"
          :color-picker="true"
          :color="tag.color"
          :menu="[
            {
              text: 'Delete tag',
              action: removeTag,
              hidden: !userCanEditProjects,
            },
          ]"
          :maxlength="tagNameMaxLength"
          multiline
          @change="onTitleChange"
          @color-change="onColorChange"
          @keydown.enter.prevent
        />
        <BaseTextarea
          v-if="userCanEditProjects"
          ref="description"
          placeholder="Type tag description here…"
          :value="description"
          class="tag-description"
          @change="saveDescription"
        />
        <p v-else>
          {{ description }}
        </p>
        <div class="filter-menu">
          <ul class="horizontal-menu">
            <li><a :class="{ 'is-active': typeFilter === 'highlights' }" @click="typeFilter = 'highlights'">Highlights</a></li>
            <li><a :class="{ 'is-active': typeFilter === 'insights' }" @click="typeFilter = 'insights'">Insights</a></li>
          </ul>
          <div class="tag-filters">
            <div class="filter">
              <span class="filter__name">Projects:</span>
              <RespondersDropdown
                :value="filterProjects"
                @input="filterProjects = $event"
                value-span-class="filter__value"
                heading=""
                list="projects"
                :can-add="false"
                :can-select-all="false"
                :can-deselect-all="true"
                :reverse-all="true"
                value-format="text"
              />
            </div>
            <div class="filter">
              <span class="filter__name">Time Range:</span>
              <DatePicker
                class="filter__value"
                :value="timeRange"
                @change="timeRange = $event"
              >
                <span>{{ timeRange ? timeRange.text : 'All' }}</span>
              </DatePicker>
            </div>
            <div class="filter">
              <span class="filter__name">Sort by:</span>
              <DropdownMenu
                placement="bottom"
                class="filter__value"
                :menu="[
                  {
                    text: 'Date added',
                    action: () => setSortBy('date_created'),
                    active: sortBy === 'date_created',
                  },
                  {
                    text: 'Date modified',
                    action: () => setSortBy('date_modified'),
                    active: sortBy === 'date_modified',
                  },
                  {
                    text: 'Number of tags',
                    action: () => setSortBy('tags_count'),
                    active: sortBy === 'tags_count',
                  },
                ]"
              >
                {{ sortByName }}
              </DropdownMenu>
            </div>
          </div>
        </div>
        <div v-if="hasItems">
          <div v-for="item of sortedItems" :key="item.id">
            <InsightItem
              :insight-id="item.id"
              :showProjectName="true"
              @open="showInsight(item)"
              v-if="item.type === 'CustomMethod'"
            />
            <Card
              :data="item"
              :canEdit="userCanEditProjects"
              :breadcrumbs="true"
              :viewOnly="true"
              @action="onCardAction"
              @change="onCardChange"
              @tags-change="onCardTagsChange(item.id, $event)"
              v-else
            />
          </div>
        </div>
        <div
          v-else
          class="tag-empty"
        >
          <Icon
            class="tag-empty__icon"
            size="auto"
            name="sticker-note"
          />
          <div class="tag-empty__body">
            There’re no data linked to this tag.
          </div>
        </div>
      </div>
      <Spinner
        v-else
        :full-height="true"
      />
    </div>
  </BaseModal>
</template>

<script>
import moment from 'moment';
import Spinner from 'components/common/base-spinner';
import BaseTextarea from 'components/common/base-textarea';
import DatePicker from 'components/common/pickers/date';
import BaseModal from 'modals/base';
import { PROJECT_TAG } from 'constants/routes';
import { TAG_COLORS } from 'constants/tag-colors';
import { sortAndFilterByProps } from 'helpers/filter';
import { navigateToMethod, navigateToTag } from 'helpers/router';
import { sortByItemsCount } from 'helpers/tags';
import sample from 'lodash/sample';
import { mapGetters } from 'vuex';
import InsightItem from 'views/project-insights/item';

import Forbidden from 'components/forbidden';

export default {
  name: 'ProjectTag',
  components: {
    BaseTextarea,
    Spinner,
    DatePicker,
    Forbidden,
    BaseModal,
    InsightItem,
  },
  data: () => ({
    tagColor: '#000',
    timeRange: '',
    headerText: '',
    sortBy: 'date_created',
    typeFilter: 'highlights',
    filterProjects: [],
    active: false,
    data: {},
  }),
  computed: {
    ...mapGetters([
      'tags',
      'projectCurrent',
      'userCanEditProjects',
      'isProjectForbidden',
      'tagNameMaxLength',
    ]),
    tagId() {
      return this.data.id;
    },
    sortByName() {
      switch(this.sortBy) {
        case 'date_created':
          return 'Date added';
        case 'date_modified':
          return 'Date modified';
      }

      return 'Number of tags';
    },
    sortedTags() {
      return sortByItemsCount(this.tags);
    },
    sortedItems() {
      const { typeFilter } = this;
      let items;

      if (typeFilter && typeFilter === 'highlights') {
        items = this.tag.items.filter((i) => i.content || i.parentContent);
      } else if (typeFilter && typeFilter === 'insights') {
        items = this.tag.insights;
      } else {
        items = [...this.tag.insights, this.tag.items];
      }

      if (this.timeRange) {
        const { start, end } = this.timeRange;

        items = items.filter((item) => {
          return (
            (!start || item.createdAt >= moment(start).format()) &&
            (!end || item.createdAt <= moment(end).format())
          );
        });
      }

      return sortAndFilterByProps(items, {
        sortBy: this.sortBy,
        projects: this.filterProjects,
      });
    },
    hasItems() {
      return !!this.sortedItems.length;
    },
    description() {
      return this.tag.description || '';
    },
    name() {
      return this.tag.name;
    },
    tag() {
      return this.$store.getters.tagById(this.tagId);
    },
    isTagFetched() {
      return this.$store.getters.isTagFetchedById(this.tagId);
    },
  },
  watch: {
    async tagId(id) {
      if (this.active) {
        await this.$store.dispatch('tagFetch', { id });
      }
    },
    data(data) {
      if (data.filterProjectId) {
        this.filterProjects = [data.filterProjectId];
      }
    },
  },
  methods: {
    navigateTo(tagId) {
      return {
        name: PROJECT_TAG,
        params: {
          project_id: this.projectCurrent.id,
          tag_id: tagId,
        },
      };
    },
    onColorChange(color) {
      this.$store.dispatch('tagUpdate', {
        id: this.tag.id,
        data: {
          color,
        },
      });
    },
    onTitleChange(name) {
      this.$store.dispatch('tagUpdate', {
        id: this.tag.id,
        data: {
          name,
        },
      });
    },
    saveDescription(content) {
      this.$store.dispatch('tagUpdate', {
        id: this.tag.id,
        data: {
          description: content,
        },
      });
    },
    setSortBy(type) {
      this.sortBy = type;
    },
    async addTag() {
      const randomColor = sample(TAG_COLORS);
      const newTag = {
        name: this.$store.getters.newTagName,
        color: randomColor,
      };
      const created = await this.$store.dispatch('tagAdd', newTag);
      navigateToTag(created.id);
    },
    onCardChange({ cardType, content, contentTags, card: { id, parentId, designMethodId, projectId }}) {
      if (cardType === 'Sentence') {
        this.$store.dispatch('changeHighlight', {
          id,
          designMethodId,
          parentId,
          projectId,
          content,
          contentTags,
        });
      }

      this.$store.dispatch('updateTagItem', { tagId: this.tagId, id, data: { content, contentTags } });
    },
    onCardTagsChange(id, tags) {
      this.$store.dispatch('updateTagItem', { tagId: this.tagId, id, data: { tags, tagList: tags } });
    },
    async removeTag() {
      const id = this.tagId;
      await this.$store.dispatch('tagRemove', { id });
      this.$store.dispatch('modalHide');
    },
    onCardAction({ action, sentence }) {
      if (action === 'sentence-click') {
        navigateToMethod(sentence.projectId, sentence.designMethodId, {
          item: sentence.parentId,
        });
        this.$store.dispatch('modalHide');
      }
    },
    showInsight(insight) {
      this.$store.dispatch('modalShow', {
        name: 'method',
        data: {
          id: insight.id,
          projectId: insight.projectId, 
        },
      });
    },
  },
};
</script>
