<template>
  <div class="base-answer">
    <div
      class="base-answer__container"
      :class="{ active: active }"
      @click="showModal"
    >
      <template v-if="!canEditText">
        <span class="methods__list-answer--display-block">{{
          answerText
        }}</span>
      </template>
      <TextInput
        v-else
        ref="answerInput"
        v-model="answerText"
        draggable="false"
        :readonly="inputDisabled"
        type="text"
        class="base-answer__input"
        :class="selectableInputs"
        lazy
        tabindex="2"
        @blur="onInputBlur"
      />
      <div class="base-answer__indicators">
        <div v-if="hasTextInsde">
          <img
            src="/static/img/ic-article.svg"
            alt="has text inside"
          >
        </div>
        <div v-if="imagesInPageCounter">
          <img
            src="/static/img/ic-image.svg"
            alt="has images inside"
          >
          <span> {{ imagesInPageCounter }} </span>
        </div>
      </div>
      <TagsAvatars
        :tags="answer.tags"
        class="base-answer__tags-avatars"
        @click="showSelector"
      >
        <TagsSelector
          ref="selector"
          v-model="answerTags"
          :tags="tags"
          @tags-selector-toggle="tagsOpen = $event"
        />
      </TagsAvatars>
      <BaseMenu
        v-if="canEdit"
        left
        class="base-answer__menu"
        @menu-toggle="menuOpen = $event"
      >
        <li @click.stop.prevent="editPage">
          Edit page
        </li>
        <li @click.stop.prevent="remove">
          Delete page
        </li>
      </BaseMenu>
    </div>
  </div>
</template>

<script>
import TagsAvatars from 'views/page/common/tags-avatars';
import TagsSelector from 'views/page/common/tags-selector';
import { mapGetters } from 'vuex';

export default {
  name: 'BaseAnswer',
  components: {
    TagsAvatars,
    TagsSelector,
  },
  props: {
    answer: Object,
    openable: {
      default: true,
      type: Boolean,
    },
    filterTags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      menuOpen: false,
      tagsOpen: false,
      menuShownTimestamp: 0,
      inputDisabled: true,
      selfOpenable: true,
    };
  },
  watch: {
    active() {
      this.$emit('toogle', this.active);
    },
  },
  computed: {
    ...mapGetters(['tags', 'userCanEditProjects']),
    active() {
      return this.menuOpen || this.tagsOpen;
    },
    modalOpenable: {
      get() {
        if (!this.openable) {
          return false;
        }
        return this.selfOpenable;
      },
      set(value) {
        this.selfOpenable = value;
      },
    },
    answerTags: {
      get() {
        return this.answer.tags;
      },
      set(value) {
        this.$emit('tags-change', value);
      },
    },
    canEdit() {
      return this.userCanEditProjects;
    },
    isEditing() {
      return this.$store.getters.isEditing;
    },
    canEditText() {
      return this.canEdit && !this.isEditing;
    },
    answerText: {
      get() {
        return this.answer.content;
      },
      set(value) {
        if (!value.length) {
          this.remove();
          return;
        }
        this.$emit('change', { content: value });
        this.inputDisabled = true;
      },
    },
    selectableInputs() {
      return { selectable: this.inputDisabled };
    },
    hasTextInsde() {
      const MIN_LENGHT = 10;
      const page = this.answer.page;
      if (!(page && page.length > 0)) {
        return false;
      }

      return page.replace(/<[^>]*>?/gm, '').length >= MIN_LENGHT;
    },
    imagesInPageCounter() {
      const page = this.answer.page;
      if (page === null || page === undefined) {
        return false;
      }
      const imgTag = '<img';
      return page.split(imgTag).length - 1;
    },
  },
  methods: {
    editPage() {
      this.modalOpenable = false;
      this.inputDisabled = false;
      setTimeout(() => {
        this.$refs.answerInput.select();
      });
    },
    onInputBlur() {
      this.inputDisabled = true;
      this.modalOpenable = true;
    },
    showSelector(e) {
      e.stopPropagation();
      if (!this.canEdit) return;
      this.$refs.selector.show(e);
    },
    showModal() {
      if (this.modalOpenable) {
        this.$store.dispatch('openAnswerModal', this.answer);
      }
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss">
.sortable-ghost {
  .base-answer__container {
    border-bottom: 1px solid #f5f5f5;
    transition: none !important;
    background: #dae7ed !important;
    & > * {
      opacity: 0 !important;
    }
  }
}
.answer-drag-class {
  opacity: 1 !important;
  border: 0;
  .base-answer__container {
    transform: rotate(-2deg);
    border: 0;
    margin-right: 60px;
    padding-left: 15px;
    opacity: 1 !important;
    background: white;
    border-radius: 3px;
    box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.21);
    textarea {
      min-height: unset !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.base-answer {
  &:after {
    content: '';
    display: block;
    margin-right: 45px;
    margin-left: -15px;
    border-bottom: solid 1px #f2f2f2;
  }

  &.sortable-ghost:after {
    display: none;
  }

  &.answer-drag-class:after {
    display: none;
  }

  &__container {
    border-radius: 3px;
    color: #3b3b3b;
    cursor: pointer;
    font-size: 1.6rem;
    margin-left: -15px;
    padding: 16px 75px 16px 15px;
    margin-right: 45px;
    position: relative;
    transition: box-shadow 0.6s ease, visibility 0.6s ease;
    &:hover,
    &:focus,
    &:focus-within,
    &.active {
      background: $color-white;
      box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.08);

      .base-answer__tags-avatars::v-deep .tags-avatars__list--empty {
        opacity: 1;
      }

      .base-answer__menu {
        opacity: 1;
        visibility: visible;
      }

      &:hover span.empty {
        opacity: 1;
      }
    }
  }

  &__menu:hover {
    background-color: $color-grey-porcelain;
  }

  &__tags-avatars::v-deep .tags-avatars__list--empty {
    opacity: 0;
  }

  &__input {
    display: block;
    min-height: 100%;
    border: 0;
    background: transparent;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: left;
    padding: 0;
    color: #3b3b3b;
    width: 100%;
    outline: 0;
    resize: none;
    height: auto;
    cursor: pointer;

    &:focus {
      opacity: 1;
      visibility: visible;
    }
  }

  &__menu {
    width: 42px;
    height: 100%;
    border: 0;
    color: #555555;
    text-align: center;
    margin: 0 5px;
    position: absolute;
    right: -47px !important;
    top: 0;
    outline: 0;
    background: #ffffff image_url('svg/trash-2.svg') no-repeat center center;
    opacity: 0;
    transition: all 0.5s ease;
  }

  &__indicators {
    margin-top: 2px;
    display: flex;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      margin-right: 8px;
      font-size: 1.2rem;
      span {
        margin-left: 2px;
      }
    }
  }
}
.selectable {
  -webkit-user-select: none;
  user-select: none;
  &::selection {
    background-color: white;
    color: #3b3b3b;
  }
}
</style>
