import sample from 'lodash/sample';
import shuffle from 'lodash/shuffle';

const COMPETITOR_AVATAR_COLOR = [
  '#00b6a9',
  '#005bb6',
  '#007b83',
  '#00aaec',
  '#f02063',
  '#f07320',
];

export const competitorAvatarColor = () => {
  return sample(COMPETITOR_AVATAR_COLOR);
};

const competitorAvatarColorGenerator = (function*() {
  let i = 0;
  let arr = shuffle(COMPETITOR_AVATAR_COLOR);

  while (true) {
    yield arr[i];
    i += 1;
    if (i >= COMPETITOR_AVATAR_COLOR.length) {
      i = 0;
      arr = shuffle(COMPETITOR_AVATAR_COLOR);
    }
  }
})();

export const getCompetitorAvatarColor = () => {
  return competitorAvatarColorGenerator.next().value;
};

export const validateHexColor = (color) => {
  return /^#([0-9A-F]{3}){1,2}$/i.test(color);
};
