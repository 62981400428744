<template>
  <BaseTooltip
    v-if="menuVisible.length || subtree.length"
    ref="tooltip"
    :behaviour="behaviour"
    :placement="placement"
    :visible="visible"
    :title="title"
    :appearance="appearance"
    @toggle="onToggle"
  >
    <template v-slot:content>
      <ContentTransition>
        <div
          v-if="showConfirm"
          key="confirm"
          class="tooltip-prompt"
        >
          <div class="tooltip-prompt__body">
            <div class="tooltip-prompt__title">
              Are you sure?
            </div>
            <div
              v-if="showConfirm.confirmDesc"
              class="tooltip-prompt__desc"
            >
              {{ showConfirm.confirmDesc }}
            </div>
            <button
              class="btn btn--corners btn--block btn--warn"
              @click="promptAccept"
            >
              Yes
            </button>
            <button
              class="btn btn--corners btn--block btn--outline"
              @click="promptCancel"
            >
              Cancel
            </button>
          </div>
        </div>

        <div
          :key="`menu-${subtree[subtree.length-1]}`"
          ref="menu"
          class="dropdown-menu"
          :class="menuClasses"
        >
          <div
            v-if="subTitle"
            class="dropdown-menu-header"
          >
            <span
              class="dropdown-menu-header__back"
              @click="back"
            >
              <Icon name="chevron-left" />
            </span>
            {{ subTitle }}
          </div>
          <div v-if="options.search">
            <div
              class="search-input"
              :class="{
                'search-input--focus': searchFocus,
                'search-input--searching': searchedText,
              }"
            >
              <Icon
                class="search-input__icon"
                name="search-light"
              />
              <div
                class="search-input__placeholder"
                :data-placeholder="options.search"
              />
              <input
                ref="searchInput"
                v-model="searchedText"
                class="search-input__input"
                @focus="searchFocus = true"
                @blur="searchFocus = false"
                @keydown.stop
              >
            </div>
          </div>
          <span
            v-for="link of menuVisible"
            :key="link.key || link.text"
            class="dropdown-menu__item"
          >
            <span
              v-if="link === '-'"
              class="dropdown-menu__spacer"
            />
            <div
              v-else-if="link.type === 'confirmation'"
              class="dropdown-confirm"
            >
              <h2 class="dropdown-confirm__title">{{ link.title }}</h2>
              <p class="dropdown-confirm__desc">{{ link.description }}</p>
              <BaseInput
                v-model="confirmationCode"
                class="input input--new-light dropdown-confirm__input"
                :placeholder="link.placeholder"
                :class="{
                  'input--error': confirmationFailure,
                }"
                @keydown.enter="confirm(link)"
              />
              <button
                class="btn btn--block btn--warn"
                @click="confirm(link)"
              >{{ link.confirmButton }}</button>
              <button
                class="btn btn--block btn--outline"
                @click="cancel(link)"
              >Cancel</button>
            </div>
            <a
              v-else-if="link.methodItem !== undefined"
              class="dropdown-menu__link"
              :class="{
                'dropdown-menu__link--active': link.active,
                'dropdown-menu__link--disabled': link.disabled,
                'dropdown-menu__link--box-icon-small': true,
                [`dropdown-menu__link--${ link.appearance }`]: link.appearance,
                [link.className]: true,
              }"
              @click.stop="doLinkAction($event, link)"
            >
              <div class="dropdown-menu__link-content">
                <MethodItemIcon
                  class="dropdown-menu__link-box-icon"
                  :item="link.methodItem"
                />
                <div class="dropdown-menu__link-text">
                  {{ getMethodItemName(link.methodItem) }}
                </div>
              </div>
              <Icon
                v-if="link.selected"
                class="dropdown-menu__link-check"
                name="check"
              />
            </a>
            <component
              v-else-if="!link.tooltip"
              :is="link.url ? 'a' : 'div'"
              class="dropdown-menu__link"
              :class="[{
                'dropdown-menu__link--active': link.active,
                'dropdown-menu__link--disabled': link.disabled,
                'dropdown-menu__link--selected': link.selected,
                'dropdown-menu__link--sub': link.prompt || link.submenu,
                [link.className]: true,
              }, link.appearance && link.appearance.split(' ').map((c => `dropdown-menu__link--${c}`))]"
              :href="link.url"
              target="_blank"
              @click.stop="doLinkAction($event, link)"
            >
              <div class="dropdown-menu__link-content">
                <PageAvatar
                  class="dropdown-menu__link-box-icon"
                  v-if="link.icon && link.icon === 'page-avatar'"
                  :page="link.page"
                />
                <Icon
                  v-else-if="link.icon"
                  class="dropdown-menu__link-box-icon"
                  :appearance="link.appearance && link.appearance.includes('box-icon') ? 'box' : 'default'"
                  :name="link.icon"
                />
                <div class="dropdown-menu__link-body">
                  {{ link.text }}
                  <div class="dropdown-menu__link-badge" v-if="link.badge">
                    {{ link.badge }}
                  </div>
                  <div>
                    <Transition name="enter-small-list">
                      <div
                        key="desc"
                        v-if="link.desc"
                        class="dropdown-menu__link-desc"
                      >
                        <Icon :name="link.descIcon" v-if="link.descIcon" />
                        {{ link.desc }}
                      </div>
                    </Transition>
                    <Transition name="enter-small-list">
                      <div key="linkbox" class="dropdown-menu__link-box" v-if="link.linkBox">
                        <LinkBox :url="link.linkBox" />
                      </div>
                    </Transition>
                    <Transition name="enter-small-list">
                      <div key="btn" v-if="link.button">
                        <BaseButton v-bind="link.button" @click.stop />
                      </div>
                    </Transition>
                  </div>
                  <div
                    v-if="link.submenuInfo"
                    class="dropdown-menu__link-info"
                  >
                    {{ link.submenuInfo }}
                  </div>
                </div>
              </div>
              <Icon
                v-if="link.prompt || link.submenu"
                class="dropdown-menu__link-icon"
                name="arrow-right-small"
              />
              <Icon
                v-else-if="link.selected"
                class="dropdown-menu__link-check"
                name="check"
              />
            </component>
            <BaseTooltip
              v-else
              :text="link.tooltip"
              placement="top"
            >
              <a
                class="dropdown-menu__link"
                :class="[{
                  'dropdown-menu__link--active': link.active,
                  'dropdown-menu__link--disabled': link.disabled,
                  'dropdown-menu__link--sub': link.prompt || link.submenu,
                  [link.className]: true,
                }, link.appearance && link.appearance.split(' ').map((c => `dropdown-menu__link--${c}`))]"
                :href="link.url"
                target="_blank"
                @click.stop="doLinkAction($event, link)"
              >
                {{ link.text }}
                <Icon
                  v-if="link.prompt || submenu"
                  class="dropdown-menu__link-icon"
                  name="arrow-right-small"
                />
              </a>
            </BaseTooltip>
            <transition name="slide-up">
              <div
                v-if="link.prompt && activeSubmenu === link.text"
                class="dropdown-menu__sub"
                :class="{[`dropdown-menu__sub--${subMenuPosition}`]: true}"
              >
                <div class="dropdown-menu__sub-title">
                  {{ link.prompt.text }}
                </div>
                <ListMenu
                  :menu="link.prompt.menu"
                  @action="onSubmenuAction"
                />
              </div>
            </transition>
          </span>
        </div>
      </ContentTransition>
    </template>
    <BaseTooltip
      v-if="tooltip"
      :text="tooltip"
      placement="top"
    >
      <slot v-if="$slots.default" />
      <div
        v-else-if="dropdownText"
        class="dropdown-menu-btn"
      >
        {{ dropdownText }}
        <Icon name="arrow-full-down" />
      </div>
      <Icon
        v-else
        name="more-vertical"
      />
    </BaseTooltip>
    <template v-else>
      <slot v-if="$slots.default" />
      <div
        v-else-if="dropdownText"
        class="dropdown-menu-btn"
      >
        {{ dropdownText }}
        <Icon name="arrow-full-down" />
      </div>
      <Icon
        v-else
        name="more-vertical"
      />
    </template>
  </BaseTooltip>
  <div v-else>
    <slot />
  </div>
</template>

<script>
import { getMethodItemName } from 'helpers/method-items';
import { xsMax } from 'helpers/ui';

export default {
  name: 'DropdownMenu',
  props: {
    menu: Array,
    visible: {
      type: [Boolean, null],
      required: false,
      default: null,
    },
    placement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    behaviour: {
      type: String,
      required: false,
      default: 'click',
    },
    appearance: {
      type: String,
      required: false,
      default: 'light-box-menu',
    },
    dropdownText: {
      type: String,
      required: false,
    },
    title: String,
    tooltip: String,
  },
  data() {
    return {
      activeSubmenu: null,
      subMenuPosition: 'right',
      showConfirm: false,
      subtree: [],
      searchFocus: false,
      searchedText: '',
      confirmationCode: '',
      confirmationFailure: false,
    };
  },
  computed: {
    menuClasses() {
      return {
        [`dropdown-menu--${this.appearance}`]: this.appearance,
      };
    },
    hasButton() {
      return !!this.$slots.button;
    },
    options() {
      let menu = this.menu;
      let submenu = null;

      this.subtree.forEach((id) => {
        submenu = menu.find((m) => m.text === id).submenu;
        menu = menu.find((m) => m.text === id).submenu.menu;
      })
      return submenu || {};
    },
    menuVisible() {
      const { searchedText } = this;
      let menu = this.menu;

      if (this.subtree.length) {
        this.subtree.forEach((id) => {
          menu = menu.find((m) => m.text === id).submenu.menu;
        })
      }

      return menu ? menu.filter((m) => m && !m.hidden).filter((m) => !searchedText || m.text.toLowerCase().indexOf(searchedText.toLowerCase()) > -1) : [];
    },
    subTitle() {
      let menu = null;
      let title = null;

      if (this.subtree.length) {
        menu = this.menu;
        this.subtree.forEach((id) => {
          title = menu.find((m) => m.text === id).submenu.title;
          menu = menu.find((m) => m.text === id).submenu.menu;
        })
      }

      return title;
    },
    defaultMethodItemNames() {
      return defaultMethodItemNames;
    },
  },
  watch: {
    visible(visible) {
      if (!visible) {
        this.activeSubmenu = null;
        this.subtree = [];
      }
    },
    subtree() {
      this.searchedText = '';
    },
  },
  methods: {
    getMethodItemName,
    promptAccept(){
      if(!this.showConfirm) return;

      const { showConfirm } = this;

      showConfirm && showConfirm.action && showConfirm.action();
    },
    promptCancel() {
      this.showConfirm = false;
    },
    back() {
      this.subtree.pop();
    },
    doLinkAction(e, link) {
      if (link && link.prompt) {
        if (xsMax()) {
          this.$store.dispatch('promptShow', link.prompt);
        } else {
          if (this.activeSubmenu === link.text) {
            this.activeSubmenu = null;
          } else {
            this.activeSubmenu = link.text;
          }
        }

        const menu = this.$refs.menu;

        if (menu) {
          const rect = menu.getBoundingClientRect();
          const menuWidth = menu.offsetWidth;
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;

          if (windowWidth - rect.left - menuWidth < menuWidth + 20) {
            this.subMenuPosition = 'left';
          } else {
            this.subMenuPosition = 'right';
          }
        }

        return;
      }

      if (link && link.submenu) {
        this.subtree.push(link.text);
        return;
      }

      if (link && link.confirm) {
        this.showConfirm = link;
        return;
      }

      let returned;

      if (link && link.action && typeof link.action === 'string') {
        this.$emit('action', link.action);
      } else {
        returned = link && link.action && link.action();
      }

      if (returned !== false && this.visible === null && link.closeOnAction !== false) {
        this.$refs.tooltip.hide();
      }

      if (link && (link.prompt || link.submenu || link.action)) {
        e.preventDefault();
      }
    },
    onToggle(open) {
      if (!open) {
        this.activeSubmenu = null;
        this.subtree = [];
        this.showConfirm = false;
      }
      this.$emit('toggle', open);
    },
    onSubmenuAction() {
      if (this.visible === null) {
        this.$refs.tooltip.hide();
      }
    },
    open() {
      this.$refs.tooltip.open();
    },
    close() {
      this.$refs.tooltip.hide();
    },
    confirm(link) {
      if (this.confirmationCode === link.confirmationCode) {
        link && link.action && link.action(() => this.close());
      } else {
        this.confirmationFailure = true;
      }
    },
    cancel(link) {
      link && link.cancel && link.cancel(() => this.close());
    },
  },
};
</script>
