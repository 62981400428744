import { TYPES } from 'constants/methods';
import {
  COMPETITOR,
  PERSONA,
  PROJECT_METHOD,
  PROJECT_METHOD_PAGE,
  PROJECT_METHODS,
  PROJECT_REPORT,
  PROJECT_SHORT,
  PROJECT_TAG,
} from 'constants/routes';
import * as ApiService from 'services/api.service';

export const SHARABLE_ROUTES = [
  PROJECT_METHODS,
  PROJECT_METHOD,
  PROJECT_SHORT,
  PROJECT_TAG,
  PROJECT_REPORT,
  PROJECT_METHOD_PAGE,
  COMPETITOR,
  PERSONA,
];

export const PAGE_RECORD_TYPE_MAP = {
  [TYPES.STAKEHOLDER_INTERVIEW]: 'Answer',
  [TYPES.USER_INTERVIEW]: 'Answer',
  [TYPES.PROBLEM_VALUATION]: 'Problem',
  [TYPES.SOLUTION_VALUATION]: 'Solution',
  [TYPES.COMPETITION_ANALYSIS]: 'CompetitorProperty',
  [TYPES.PERSONAS]: 'Persona',
  [TYPES.FLOWS]: 'Flow',
};

export const obtainShortcut = async (recordType, id) => {
  const data = { recordType, id };
  const { shortcut } = await ApiService.post('api/shortcuts', data);

  return shortcut;
};

export const getShortcutData = (shortcut) =>
  ApiService.get(`api/shortcuts/${shortcut}`);

const init = (store) => {
  const unwatch = store.watch(
    (state, getters) => getters.isShared + getters.routeName + getters.projectCurrentId,
    () => {
      const id = store.getters.projectCurrentId;
      const route = store.getters.routeName;

      if (!id) return;
      if (!store.getters.isShared && route !== PROJECT_SHORT) return;
      if (!SHARABLE_ROUTES.includes(route)) return;

      unwatch();
      store.dispatch('shareEntrySet', { id, url: store.getters.routePath });
    }
  );
};

export default {
  init,
};
