const model = {
  id: '',
  profileImage: null,
  name: '',
  email: '',
  avatarUrl: '/static/img/avatar.jpg',
  registered: false,
  allowEmails: true,
  providers: [],
};

export const GUEST = {
  id: '',
  profileImage: null,
  name: 'Guest',
  email: 'guest@talebook.io',
  avatarUrl: '/static/img/avatar.jpg',
  registered: false,
  providers: [],
};

export const parser = (user) => {
  const result = {
    ...model,
    ...user,
  };

  return result;
};

export default model;
