/* eslint-disable no-shadow */

import ArchiveService from 'services/archive.service';
import CardsService from 'services/cards.service';
import PhasesService from 'services/phases.service';
import ProjectMethodService from 'services/project-method.service';
import TagsService from 'services/tags.service';
import { analyticsSetUserProp } from 'utils/analytics';
import { merge } from 'utils/reactive';

const state = {
  archive: {},
};

const getters = {
  archive: (state, getters) => {
    const projectId = getters.projectCurrentId;
    return state.archive[projectId] || [];
  },
  archiveItemById: (state, getters) => (id) => {
    const archive = getters.archive;
    return archive.find((a) => a.id === id);
  },
  isArchiveFetched: (state, getters) => {
    const projectId = getters.projectCurrentId;
    return !!state.archive[projectId];
  },
};

const actions = {
  async archiveFetch({ dispatch, getters }) {
    const { workspaceCurrentId } = getters;
    const projectId = getters.projectCurrentId;
    const archive = await ArchiveService.fetch(workspaceCurrentId, getters.projectCurrentId);
    dispatch('archiveSet', { projectId, archive });
  },
  archiveSet({ commit }, { projectId, archive }) {
    commit('ARCHIVE_SET', { projectId, archive });
  },
  async removeArchiveItem({ commit, getters }, { id }) {
    const { workspaceCurrentId } = getters;
    const projectId = getters.projectCurrentId;
    const item = getters.archiveItemById(id);
    commit('ARCHIVE_REMOVE', { projectId, id });

    if (!item) {
      return;
    }

    switch(item.archiveType) {
      case 'Card':
        await CardsService.remove(workspaceCurrentId, projectId, id);
        break;
      case 'DesignMethod':
        await ProjectMethodService.methodRemove(workspaceCurrentId, projectId, { id });
        break;
      case 'Tag':
        await TagsService.remove(workspaceCurrentId, projectId, id);
        break;
      case 'Section':
        await InterviewService.remove({ type: 'SECTION', data: id });
        break;
      case 'Phase':
        await PhasesService.remove(workspaceCurrentId, projectId, { id });
        break;
    }
  },
  async restoreArchiveItem({ commit, getters, dispatch }, { id }) {
    const { workspaceCurrentId } = getters;
    const projectId = getters.projectCurrentId;
    const item = getters.archiveItemById(id);
    commit('ARCHIVE_REMOVE', { projectId, id });

    switch(item.archiveType) {
      case 'Card':
        await CardsService.update(workspaceCurrentId, projectId, id, { archived: false });
        break;
      case 'DesignMethod':
        await ProjectMethodService.methodChange(workspaceCurrentId, projectId, { id, data: { archived: false } });
        await dispatch('phasesFetch');
        break;
      case 'Tag':
        await TagsService.update(workspaceCurrentId, projectId, id, { archived: false });
        await dispatch('requireTags');
        break;
      case 'Section':
        await InterviewService.change({ type: 'SECTION', id, data: { archived: false } });
        break;
      case 'Phase':
        await PhasesService.update(workspaceCurrentId, projectId, { id, archived: false });
        await dispatch('phasesFetch');
        break;
    }

    await dispatch('archiveFetch');

    analyticsSetUserProp(`Restore from archive - ${ item.archiveType }`);
  },
};

const mutations = {
  ARCHIVE_SET(state, { projectId, archive }) {
    state.archive = { ...state.archive, [projectId]: archive };
  },
  ARCHIVE_REMOVE(state, { projectId, id }) {
    const archive = state.archive[projectId];

    if (!archive) return;

    state.archive = { ...state.archive, [projectId]: archive.filter((i) => i.id !== id) };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
