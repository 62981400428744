<template>
  <div
    class="edit-finished"
    data-aos="fade-in"
  >
    <TitleInput
      ref="title"
      appearance="project-title"
      :value="project.name"
      :maxlength="$store.getters.projectNameMaxLength"
      :can-edit="canEdit"
      :view-only="true"
      :multiline="true"
      :share-button="areButtonsVisible && {
        shortcut: project.shortcut,
      }"
      @change="onNameChange"
      @keydown.enter.prevent
    />
    <div
      v-if="false"
      class="empty-projects"
    >
      <div class="text-center">
        <img
          src="/static/img/svg/empty-project.svg"
          alt
          class="empty-img"
        >
        <p class="empty-text">
          You don’t have any methods in this project.
        </p>
        <button
          type="button"
          class="btn btn--orange"
          @click="toggleEdit"
        >
          Add methods
        </button>
      </div>
    </div>
    <div v-else>
      <transition-group
        type="transition"
        name="enter-list-tall"
      >
        <div
          v-for="(phase, index) of phasesFiltered"
          :key="phase.id"
        >
          <TitleInput
            ref="title"
            appearance="phase-title"
            :value="phase.name"
            :can-edit="canEdit"
            :menu="areButtonsVisible && [
              {
                text: 'Move up',
                hidden: index === 0,
                action: () => onMovePhase({ phase, move: -1 }),
              },
              {
                text: 'Move down',
                hidden: index === phases.length - 1,
                action: () => onMovePhase({ phase, move: 1 }),
              },
              '-',
              {
                text: 'Archive',
                action: () => onPhaseRemove(phase.id),
              },

            ]"
            @change="onPhaseNameChange(phase, $event)"
          />
          <ul
            :key="`${phase.id}_list`"
            class="dragged final"
            @dragstart.capture="preventDrag($event)"
          >
            <Sortable
              :disabled="!canEdit"
              :class="phase.name"
              :data-phase-id="phase.id"
              :value="phase.designMethods"
              :options="getDraggableToOptions()"
              group="methods"
              tag="div"
              @dragstart="onStart"
              @dragend="onEnd"
              @dragin="onEnd"
            >
              <div
                v-for="method of phase.designMethods"
                :ref="`wrapper__${method.id}`"
                :key="method.id"
                class="method__wrapper"
                :data-id="method.id"
                @mousedown="addGrabCursor(method.id)"
                @mouseup="removeGrabCursor(method.id)"
              >
                <div class="sortable-handler" v-if="userCanEditProjects">
                  <Icon name="drag" />
                </div>
                <Method
                  :method="getMethod(method.id)"
                  :can-edit="canEdit"
                  :navigate-to="navigateTo(method.id)"
                  @archive="onArchive"
                />
              </div>
            </Sortable>
            <router-link
              v-if="canEdit"
              class="method-box__link"
              :to="navigateToNew(phase)"
              @click="onNewMethodClick(phase.name)"
            >
              <MethodNew />
            </router-link>
          </ul>
        </div>
      </transition-group>
      <BaseTooltip text="Add section" placement="top">
        <button
          v-if="canEdit"
          type="button"
          class="methods__add-phase"
          @click="addPhase"
        >
          <Icon name="plus-12" />
        </button>
      </BaseTooltip>
    </div>
  </div>
</template>

<script>
import EditMenu from 'components/common/edit-menu';
import PhaseEditMenu from 'components/common/phase-edit-menu';
import NotificationModal from 'components/notifications/notification-modal';
import { PROJECT_METHOD, PROJECT_METHOD_NEW } from 'constants/routes';
import { EDIT_MODE } from 'constants/tips';
import { filterActiveInputs } from 'helpers/drag';
import isMobile from 'helpers/mobile';
import { analyticsLogEvent } from 'utils/analytics';
import { mapGetters } from 'vuex';

import Method from './item';
import MethodNew from './item/new';

export default {
  name: 'ProjectMethodsView',
  components: {
    Method,
    MethodNew,
  },
  props: {
    project: {
      type: Object,
      default: undefined,
    },
    phases: {
      type: Array,
      default: undefined,
    },
  },
  EDIT_MODE,
  data() {
    return {
      isModalVisable: false,
      isPhaseModalVisable: false,
      selectedPhase: null,
      selectedMethod: null,
      transitionEnabled: true,
    };
  },
  computed: {
    ...mapGetters(['userCanEditProjects', 'isShared', 'isCardDragging']),
    phasesFiltered() {
      return this.phases.filter((p) => !p.archived).map((p) => ({
        ...p,
        designMethods: p.designMethods.map((m) => this.getMethod(m.id)).filter((m) => !m.archived),
      }));
    },
    canEdit() {
      return this.userCanEditProjects;
    },
    areButtonsVisible() {
      return this.userCanEditProjects;
    },
    hasMethods() {
      return !!this.methods.length;
    },
    titleClasses() {
      return {
        'js-shared': this.isShared,
      };
    },
    transitionDisabled() {
      return !this.transitionEnabled || this.isCardDragging;
    },
    isTipVisible() {
      return this.$store.getters.shouldShowTip(this.$options.EDIT_MODE);
    },
  },
  methods: {
    getMethod(id) {
      return this.$store.getters.projectMethodById(id) || {};
    },
    onNameChange(name) {
      this.$store.dispatch('projectChange', { currentProject: this.project, data: { name } });
    },
    onPhaseNameChange(phase, name) {
      this.$store.dispatch('phaseUpdate', {
        id: phase.id,
        name: name,
      });
    },
    preventDrag(event) {
      if (!this.canEdit) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    addGrabCursor(id) {
      const ref = `wrapper__${id}`;
      this.$refs[ref][0].classList.add('cursor');
    },
    removeGrabCursor(id) {
      const ref = `wrapper__${id}`;
      this.$refs[ref][0].classList.remove('cursor');
    },
    onStart() {
      this.$store.dispatch('uiSetCardDragging', true);
    },
    async onEnd(data) {
      const id = data.ids[0];
      const phaseId = data.area.getAttribute('data-phase-id');

      await this.$store.dispatch('projectMethodChangeList', {
        id,
        phaseId,
        index: data.index,
      });

      this.$store.dispatch('uiSetCardDragging', false);
    },
    getDraggableToOptions() {
      return {
        ...filterActiveInputs,
        draggable: '.method__wrapper',
        disabled: !this.canEdit,
        forceFallback: true,
        animation: 150,
        ghostClass: 'sortable-ghost',
        chosenClass: 'sortable-chosen',
        dragClass: 'sortable-drag',
        handle: isMobile ? '.dragged__item-title' : null,
        touchStartThreshold: 3,
        fallbackTolerance: 2,
        direction: 'vertical',
        delay: 200,
        delayOnTouchOnly: true,
        emptyInsertThreshold: 5,
      };
    },
    addPhase() {
      this.$store.dispatch('phasesAdd');
    },
    navigateTo(methodId) {
      return {
        name: PROJECT_METHOD,
        params: {
          project_id: this.project.id,
          method_id: methodId,
        },
      };
    },
    navigateToNew(phase) {
      return {
        name: PROJECT_METHOD_NEW,
        params: {
          project_id: this.project.id,
          phase_id: phase.id,
        },
      };
    },
    toggleEdit() {
      this.$store.dispatch('uiToggleEdit');
    },
    onArchive({ id }) {
      this.$store.dispatch('projectMethodChange', { id, data: { archived: true } });
    },
    onMovePhase(data) {
      this.$store.dispatch('phaseMove', data);
    },
    onPhaseRemove(id) {
      this.$store.dispatch('phaseUpdate', { id, archived: true });
    },
    onNewMethodClick() {
      analyticsLogEvent(`pages_list_new_page`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles/variables';

.method__wrapper {
  position: relative;
  &:hover {
    .methods__list-trash {
      opacity: 1;
      visibility: visible;
    }
  }
  &.cursor {
    cursor: grab;
  }
}
.methods__phase-title {
  display: flex;
  justify-content: space-between;
}

.methods__add-phase {
  height: 38px;
  border-radius: 6px;
  background-color: color(gray-bg, 100);
  border: 0;
  display: block;
  appearance: none;
  width: 100%;
  margin: 30px 0;
  transition: all 200ms;
  font-size: 12px;

  &:hover {
    background-color: color(gray-bg, 300);
  }

  &:active {
    background-color: color(gray-bg, 500);
  }
}

.dragged :global(.v-leave-active) {
  display: none !important;
}
</style>
