/* eslint-disable no-shadow */
import { byPosition } from 'helpers/sort';
import CardsService from 'services/cards.service';
import { merge } from 'utils/reactive';

const moduleState = {
};

const getters = {
};

const actions = {
  cardsReorder({ commit, getters }, { cards }) {
    if (cards && cards.length) {
      const workspace = getters.workspaceCurrentId;
      const parentId = getters.projectCurrentId;

      const newOrder = byPosition(cards).filter((c) => !c.archived).map((card, position) => ({
        id: card.id,
        parentId: card.parentId,
        position,
      }));

      cards.forEach((c) => {
        let copy = newOrder.find((i) => i.id === c.id);
        if (copy) {
          merge(c, copy);
        }
      });

      CardsService.reorder(workspace, parentId, { newOrder });
    }
  },
};

const mutations = {
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
