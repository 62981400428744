<template>
  <div class="micro-project">
    <p class="micro-project__name">
      {{ data.title }}
    </p>
    <ul class="micro-project__phases">
      <li
        v-for="phase of data.phases"
        :key="phase.name"
        class="micro-project__phase"
      >
        <span class="micro-project__phase-name">{{ phase.name }}</span>
        <ul class="micro-project__methods">
          <li
            v-for="method of phase.methods"
            :key="method.name"
            class="micro-project__method"
          >
            <MethodIcon :method="method" />
            {{ method.name }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProjectNewPreview',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
