<template>
  <img
    v-if="shouldShowUrl"
    :src="url"
    class="team__avatar"
    @error="onUrlError"
  >
  <div
    v-else-if="isEmpty"
    class="team__avatar empty"
  />
  <img
    v-else-if="shouldShowGravatar"
    :src="avatarUrl"
    class="team__avatar"
    @error="onAvatarError"
  >
  <div
    v-else
    class="team__avatar"
    :data-avatar="color"
  >
    {{ avatar }}
  </div>
</template>

<script>
import { avatar, getColor, gravatar } from 'helpers/avatar';
import { validate } from 'helpers/email';
import debounce from 'lodash/debounce';

export default {
  name: 'Avatar',
  props: {
    name: String,
    email: {
      type: String,
      required: false,
      default: '',
    },
    url: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    urlError: false,
    avatarError: false,
  }),
  computed: {
    isEmpty() {
      return (!this.name && !this.email) || (!this.name.length && !this.email.length);
    },
    isValid() {
      return this.email && this.email.length && validate(this.email);
    },
    shouldShowGravatar() {
      return !this.avatarError && this.isValid;
    },
    avatarUrl() {
      return gravatar(this.email);
    },
    avatar() {
      return avatar(this.name);
    },
    hasUrl() {
      return this.url !== null && this.url.length;
    },
    shouldShowUrl() {
      return this.hasUrl && !this.urlError;
    },
    color() {
      return getColor(this.avatar);
    },
  },
  watch: {
    email(current, old) {
      if (!current.length && old.length) {
        this.avatarError = false;
      }

      this.checkGravatar();
    },
  },
  mounted() {
    this.checkGravatar = debounce(this.checkGravatar, 250);
  },
  methods: {
    checkGravatar() {
      if (!this.isValid) return;

      this.avatarError = false;
    },
    onAvatarError() {
      this.avatarError = true;
    },
    onUrlError() {
      this.urlError = true;
    },
  },
};
</script>
