<template>
  <div
    class="method__item"
    @click.stop="onMethodItemClick(method)"
  >
    <div
      class="image__container"
      :data-method-color="slug"
      :style="{backgroundColor: method.color}"
    >
      <Icon :name="icon" />
    </div>
    <div class="method__info">
      <p class="method__name">
        {{ method.name }}
      </p>
      <p class="method__description">
        {{ methodDescription(method.name) }}
      </p>
    </div>
    <button
      type="button"
      class="arrow"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="12"
        viewBox="0 0 18 12"
      >
        <g
          fill="none"
          fill-rule="evenodd"
          stroke="#4B4B4B"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M17 6H1M7 12L1 6l6-6" />
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
import { navigateToMethod } from 'helpers/router';
import { analyticsLogEvent } from 'utils/analytics';
import { slugify } from 'utils/text';

export default {
  name: 'NewMethodItem',
  props: {
    method: {
      type: Object,
      required: false,
    },
    phaseId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      description: {
        Survey: 'Online survey without any predefined questions',
        Board: 'Kanban board without any data inside',
        Notepad: 'A blank page with the ability to add inline components',
        'Stakeholder Interview': 'Discover what is stakeholder’s vision for the product.',
        'User Interview': 'Recognize the problems and pain points of your target audience.',
        'Competitor Analysis': 'Take a closer look outside your own sandbox.',
        'Problem Valuation': 'Look back over your data and identify the problems.',
        'Solution Valuation': 'Brainstorm solutions for the most crucial problems.',
        Persona: 'Represent members of your target audience.',
        'Flows': 'Create a clear path from problem to solution.',
        'Interactive Prototype': 'Test interactive product demo.',
      },
    };
  },
  computed: {
    slug() {
      return slugify(this.method.type);
    },
    icon() {
      return this.method.icon || `method-${ this.slug }`;
    },
  },
  methods: {
    methodDescription(name) {
      if (this.description.hasOwnProperty(name)) {
        return this.description[name];
      }
      return 'Explore our template library';
    },
    onMethodItemClick(element) {
      this.$emit('adding');
      analyticsLogEvent(`create_page_template_${element.templateName || 'default'}`);
      this.$store.dispatch('projectMethodAdd', {
        data: { ...element, phaseId: this.phaseId },
      }).then(
        (result) => {
          navigateToMethod(this.$route.params.project_id, result.id);
        }
      );
    },
    imageUrl(name) {
      const data = name.toLowerCase().replace(' ', '-');
      return `/static/img/methods/${ data }.svg`;
    },
    methodName(name) {
      const data = name.toLowerCase().replace(' ', '-');
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'styles/sizes';
  .image__container {
    display: flex;
    align-self: center;
    width: 45px;
    height: 45px;
    margin-left: 16px;
    overflow: hidden;
    border-radius: 3px;
    flex-shrink: 0;

    .icon {
      font-size: 45px;
    }
  }
  .method {
    &__item {
      display: flex;
      height: 80px;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: #F8F9FA;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 35px;
      @include smMax {
        padding-left: 20px;
      }
      p {
        margin: 0px 0px;
      }
    }
    &__name {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.39;
      color: #252729;
    }
    &__description {
      font-size: 14px;
      line-height: 1.43;
      color: #646464;
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    background: none;
    outline: none;
    border: none;
    margin-left: auto;
    margin-right: 18px;
    transform: rotate(180deg);
    @include smMax {
      display: none;
    }
    svg {
      fill: black;
    }
  }
</style>
