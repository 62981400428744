<template>
  <BaseModal
    class="manage-tags-modal"
    :name="name"
    @modal-active="clearValidation"
  >
    <div class="manage-tags__modal-container">
      <button
        style="top: 38px"
        type="button"
        class="modal__close-x"
        @click="hide"
      >
        <svg
          style="width: 18px"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 18 20"
        >
          <path
            data-v-8d79be2a=""
            d="M13 0L1 12M1 0l12 12"
          />
        </svg>
      </button>
      <p class="manage-tags__modal-title">
        Manage tags
      </p>
      <div
        ref="inputContainer"
        class="manage-tags__input-container"
        :class="{ active: isInputActive }"
        @click.capture.prevent.stop="$refs.input.focus()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          :class="{ active: isInputActive }"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#AAAAB5"
            stroke-linecap="round"
            stroke-linejoin="round"
            transform="translate(1 1)"
          >
            <circle
              cx="5.833"
              cy="5.833"
              r="5.833"
            />
            <path d="M14 14L9.956 9.956" />
          </g>
        </svg>
        <div
          v-show="searchedText.length == 0"
          class="manage-tags__placeholder"
          :class="{ active: isInputActive }"
        >
          Search or add tag
        </div>
        <input
          ref="input"
          v-model="searchedText"
          type="text"
          tabindex="-1"
          :maxlength="$store.getters.tagNameMaxLength"
          @keydown.enter="onInvite"
          @focus="isInputActive = true"
          @blur="isInputActive = false"
        >
      </div>

      <ul style="padding: 0; margin: 0;">
        <li
          v-for="tag of selectedTags"
          :key="tag.id"
          class="manage-tags__list-item"
        >
          <div
            class="manage-tags__color"
            :style="{ 'background-color': tag.color }"
          />
          <div class="team__member">
            <BaseInput
              :ref="tag.id"
              :validate="value => validate(value, tag)"
              :value="tag.name"
              class="manage-tags__input"
              :class="{ 'manage-tags__input--error': errors[tag.id] }"
              :maxlength="$store.getters.tagNameMaxLength"
              @change="onTagChange(tag.id, $event)"
            />
            <transition name="fade">
              <span
                v-show="errors[tag.id]"
                class="manage-tags__error-info"
              >This tag alredy exists!</span>
            </transition>
          </div>
          <button
            style="margin: 0px"
            type="button"
            class="share__icon remove"
            @click="removeTag(tag.id)"
          />
        </li>

        <li
          v-show="showNewTag"
          class="manage-tags__list-item manage-tags__list-item--new"
          @click="onInvite"
        >
          <div
            class="team__member"
            style="justify-items: center;
    align-items: center; display: flex;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 3.1787686 3.1787677"
            >
              <g
                data-v-3a1be406=""
                transform="translate(-62.666568,-91.303473)"
              >
                <path
                  id="path1580"
                  data-v-3a1be406=""
                  d="m 64.255952,91.437649 v 2.910416 m -1.455208,-1.455208 h 2.910417"
                  style="fill: none; fill-rule: evenodd; stroke: rgb(170, 170, 170); stroke-width: 0.264583; stroke-linecap: round; stroke-linejoin: round;"
                />
              </g>
            </svg>
            <span class="manage-tags__add-new">{{ searchedText }}</span>
          </div>
        </li>
      </ul>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
import { TAGS } from 'constants/modals';
import { PROJECT_METHOD } from 'constants/routes';
import { TAG_COLORS } from 'constants/tag-colors';
import { stringIncludes } from 'helpers/filter';
import { alphabetically } from 'helpers/sort';
import leven from 'js-levenshtein';
import sample from 'lodash/sample';
import { mapGetters } from 'vuex';

export default {
  name: 'ManageTags',
  components: {
    BaseModal,
  },
  props: {},
  data() {
    return {
      errors: {},
      isInputActive: false,
      searchedText: '',
    };
  },
  computed: {
    ...mapGetters(['userCanEditProjects']),
    showNewTag() {
      if (
        this.searchedText.toString().length === 0 ||
        this.hasInTeam(this.searchedText)
      ) {
        return false;
      }
      return true;
    },
    selectedTags() {
      let tags = [...this.tags];
      if (this.searchedText.length > 0) {
        tags = tags.filter((tag) => stringIncludes(tag.name, this.searchedText));
        tags = tags.sort((a, b) =>
          leven(this.searchedText, a.name) > leven(this.searchedText, b.name)
            ? 1
            : -1
        );
      }
      return tags;
    },
    name() {
      return TAGS;
    },
    tags() {
      return [...this.$store.getters.tags].sort((a, b) =>
        alphabetically(a.name, b.name)
      );
    },
  },
  methods: {
    clearValidation() {
      this.errors = [];
    },
    validate(value, tag) {
      this.$set(this.errors, tag.id, this.isDuplicated(value, tag));
    },
    isDuplicated(name, tag) {
      return this.tags.some((t) => t.id !== tag.id && t.name === name.trim());
    },
    hasInTeam(tagName) {
      return this.tags.some(({ name }) => name === tagName.trim());
    },
    onInvite() {
      if (!this.showNewTag) return;
      this.$store.dispatch('tagAdd', {
        name: this.searchedText,
        color: sample(TAG_COLORS),
      });
      this.searchedText = '';
    },
    onTagChange(id, name) {
      if (this.hasInTeam(name)) return;
      this.$store.dispatch('tagUpdate', { id, data: { name } });
    },
    removeTag(id) {
      this.$store.dispatch('removeTagsFromAnswers', id);
      this.$store.dispatch('tagRemove', id);
    },
    hide() {
      this.$store.dispatch('modalHide');
    },
  },
};
</script>

<style lang="scss">
.manage-tags-modal .tags-modal-wrapper {
  max-width: 560px;
  padding: 30px 8px 8px;
}
</style>

<style lang="scss" scoped>
@mixin text {
  color: #646464;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.manage-tags {
  &__placeholder {
    @include text;
    position: absolute;
    opacity: 0.5;
    cursor: text;
    height: 20px;
    width: 110px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: calc(50% - 54px);
    margin-top: auto;
    margin-bottom: auto;
    transition: margin 0.2s linear;

    &.active {
      margin-left: 37px;
      pointer-events: none;
    }
  }

  &__input-container {
    position: relative;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: #F8F9FA;
    border-radius: 4px;
    height: 41px;
    align-items: center;
    padding: 5px 10px;
    svg {
      margin-right: 10px;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.1s;
    }
    svg.active {
      visibility: visible;
      opacity: 1;
      pointer-events: none;
    }
    input {
      @include text;
      flex: 1;
      border: 0;
      outline: none;
      background-color: transparent;
    }
  }

  &__modal-title {
    font-size: 20px;
    color: #000000;
    text-align: center;
    margin-top: 0px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 8px !important;
    border-radius: 4px;
    transition: all 0.3s ease;
    &:hover {
      background-color: #F8F9FA;
    }

    &--new {
      background-color: #F8F9FA;
      font-size: 14px;
      color: #646464;
    }
  }

  &__add-new {
    @include text;
    margin-left: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 13px;
  }

  &__error-info {
    position: absolute;
    left: 1px;
    top: 20px;
    color: #d0011b;
  }

  &__input--error:focus,
  &__input--error:hover,
  &__input--error {
    border-bottom: 1px dashed #d0011b !important;
  }

  &__input {
    color: #646464;

    border: 0;
    border-bottom: 1px dashed #ffffff;
    transition: border-bottom 0.25s;

    background: transparent;
    outline: 0;
    width: 96%;
    padding: 0;
    font-size: 14px;
    height: 20px;

    &:hover {
      cursor: text;
      border-bottom: 1px dashed #8e8e8e;
    }

    &:focus {
      border-bottom: 1px dashed #1d1d1d;
    }
  }
}
</style>
