export const KEY_DOWN = 'keyDown';
export const KEY_UP = 'keyUp';
export const KEY_ESC = 'keyEsc';
export const KEY_SPACE = 'keySpace';

export const RESIZE = 'resize';

export const SCROLL = 'scroll';

export const UNLOAD = 'unload';
export const VISIBILITY_HIDDEN = 'hidden';
export const VISIBILITY_VISIBLE = 'visible';

export const ESC_PRESSED = 'esc';

export const MODAL_SHOW = 'modalShow';
export const MODAL_HIDE = 'modalHide';

export const NOTIFICATION_UNDO = 'NOTIFICATION_UNDO';
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE';

export const LOGGED_IN = 'loggedIn';
export const LOGGED_OUT = 'loggedOut';
export const LOGGING_OUT = 'loggingOut';
export const USER_TOKEN_OBTAINED = 'userTokenObtained';

export const PAYMENT_METHOD_READY = 'PAYMENT_METHOD_READY';
