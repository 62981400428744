const AOS_ATTR = 'data-aos';
const AOS_ANIMATE = 'aos-animate';

// disables aos animation when value = false
export const enable = {
  update(element, { arg, value }) {
    if (!value) {
      element.removeAttribute(AOS_ATTR);
      return;
    }

    element.setAttribute(AOS_ATTR, arg);
  },
};

// removes aos-animate class when value is true
// (so that aos animation reverts)
export const remove = {
  update(element, { value }) {
    if (!value) {
      return;
    }

    element.classList.remove(AOS_ANIMATE);
  },
};

export const restart = {
  update(element, { value }) {
    if (value) {
      element.classList.remove(AOS_ANIMATE);
      return;
    }

    element.classList.add(AOS_ANIMATE);
  },
};
