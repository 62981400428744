import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { SURVEY } from 'constants/modals';
import { getScrollbarWidth } from 'helpers/ui';
import EventsService, { EVENTS } from 'services/events.service';

const CLASS_NAME = 'modal-open';
const PREVENT_ESC = [SURVEY];

const compensateElements = ['.header', '.fc-widget-normal'];

class ModalsService {
  constructor() {
    this.hide = this.hide.bind(this);

    this.initHideListeners();
  }

  init(store) {
    this.$store = store;
  }

  initHideListeners() {}

  show(name) {
    this.$store.dispatch('modalShow', name);
  }

  hide() {
    if (
      !this.$store.getters.isModalActive ||
      PREVENT_ESC.includes(this.$store.getters.modalActive)
    ) {
      return;
    }

    this.$store.dispatch('modalHide');
  }

  onShow(name) {
    if (this.$store.getters.modalStackSize > 1) {
      return;
    }

    const modal = document.querySelector('.modal-scroll');
    EventsService.emit(EVENTS.MODAL_SHOW, name);
    document.body.style.marginRight = getScrollbarWidth() + 'px';
    disableBodyScroll(modal);
    document.body.classList.add(CLASS_NAME);

    for (const selector of compensateElements) {
      const ele = document.querySelector(selector);
      if (ele) {
        ele.style.marginRight = getScrollbarWidth() + 'px';
      }
    }
  }

  onHide(name) {
    if (this.$store.getters.modalStackSize) {
      return;
    }

    clearAllBodyScrollLocks();
    EventsService.emit(EVENTS.MODAL_HIDE, name);
    document.body.style.marginRight = '';
    document.body.classList.remove(CLASS_NAME);

    for (const selector of compensateElements) {
      const ele = document.querySelector(selector);
      if (ele) {
        ele.style.marginRight = '';
      }
    }
  }

  shake() {
    const activeModal = document.querySelector(
      '.modal.js-show .modal__wrapper'
    );
    if (!activeModal) return;

    activeModal.classList.add('shake');
    window.setTimeout(() => activeModal.classList.remove('shake'), 500);
  }
}

export default new ModalsService();
