<template>
  <a
    href="https://talebook.io/"
    class="powered-by"
  >
    powered by <strong>talebook</strong>
  </a>
</template>
<script>
export default {
  name: 'PoweredBy',
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>
