<template>
  <div
    v-if="shouldRender"
    :class="classes"
  >
    <div
      class="modal-backdrop"
      @click="onClick"
    />
    <div class="modal-body">
      <div
        class="modal-scroll"
        @mouseup="onMouseUp"
        @mousedown="onMouseDown"
      >
        <div
          :class="wrapperClasses"
          @mousedown="onMouseDown"
        >
          <slot />
          <span
            v-if="showClose"
            class="modal-close"
            @click="onClick"
          >
            <Icon name="close" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalsService from 'services/modals.service';

export default {
  name: 'Modal',
  props: {
    withoutTransition: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    canBeClosed: {
      type: Boolean,
      required: false,
      default: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    better: {
      type: Boolean,
      required: false,
      default: false,
    },
    custom: {
      type: Boolean,
      required: false,
      default: false,
    },
    alwaysRender: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: null,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      animating: false,
      shouldDisplayVisibleClass: false,
      mouseupLocked: false,
    };
  },
  computed: {
    transitionName() {
      return this.withoutTransition ? null : 'modal-fade';
    },
    isActive() {
      if (this.isVisible !== null) {
        return this.isVisible;
      }
      return this.$store.getters.isModalWithNameActive(this.name);
    },
    shouldRender() {
      return this.isActive || this.animating || this.alwaysRender;
    },
    classes() {
      return {
        modal: true,
        'js-show': this.shouldDisplayVisibleClass,
      };
    },
    wrapperClasses() {
      return {
        'modal-wrapper': true,
        [`${ this.name }-modal-wrapper`]: true,
        modal__wrapper: !this.custom,
        'custom-modal__wrapper': this.custom,
        small: this.small,
        custom: this.custom,
      };
    },
  },
  watch: {
    isActive(current, old) {
      if (current) {
        this.$emit('data', this.$store.getters.modalData);
      }

      this.$emit('active', current);

      if (!current) {
        ModalsService.onHide(this.name);
        this.shouldDisplayVisibleClass = false;
        this.animating = true;
        window.setTimeout(() => { this.animating = false }, 1000);
      } else {
        this.$nextTick(() => {
          // One frame delay for smoother animation (can be removed if necessary)
          window.setTimeout(() => {
            ModalsService.onShow(this.name);
            this.shouldDisplayVisibleClass = true;
          }, 1000 / 30);
        });
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.hideUsingEscapeKey);

    this.$emit('data', this.$store.getters.modalData);
    this.$emit('active', this.isActive);

    if (this.isActive) {
      this.$nextTick(() => {
        // One frame delay for smoother animation (can be removed if necessary)
        window.setTimeout(() => {
          ModalsService.onShow(this.name);
          this.shouldDisplayVisibleClass = true;
        }, 1000 / 30)
      })
    }
  },
  unmounted() {
    if (this.shouldDisplayVisibleClass) {
      ModalsService.onHide(this.name);
    }
    document.removeEventListener('keydown', this.hideUsingEscapeKey);
  },
  methods: {
    onClick(e) {
      if (!this.canBeClosed) return;

      this.$store.dispatch('modalHide');
      this.$emit('hide');
    },
    onMouseDown(e) {
      if(e.target.classList.contains('modal-scroll')) {
        this.mouseupLocked = false;
      } else {
        this.mouseupLocked = true;
      }
    },
    onMouseUp(e) {
      if (!this.mouseupLocked && e.target.classList.contains('modal-scroll')) {
        this.onClick();
      }

      this.mouseupLocked = false;
    },
    hide() {
      this.$store.dispatch('modalHide');
      this.$emit('hide');
    },
    hideUsingEscapeKey(e) {
      if (e.keyCode === 27 && !this.$store.getters.isLightboxVisible) {
        this.hide();
      }
    },
  },
};
</script>
