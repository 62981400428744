<template>
  <div
    class="question-type-picker"
    :class="{'question-type-picker--editable': canEdit}"
  >
    <BaseTooltip
      v-if="canEdit"
      behaviour="click"
      appearance="light-box"
      :placement="placement"
      @toggle="(state) => open = state"
    >
      <div
        v-if="!$slots.default"
        class="methods__question-edit-counter question-type-picker-icon"
      >
        <div
          class="methods__question-edit-counter-body"
          :style="{ background: color }"
        >
          <transition-group name="swipe-bottom">
            <Icon
              :key="typeIcon"
              class="methods__question-edit-counter-icon"
              :name="typeIcon"
            />
          </transition-group>
        </div>
      </div>
      <slot />
      <template v-slot:content>
        <div class="question-type-picker__tooltip">
          <ContentTransition>
            <div
              v-if="needConfirm"
              key="confirm"
              class="tooltip-prompt"
            >
              <div class="tooltip-prompt__body">
                <div class="tooltip-prompt__title">
                  Are you sure?
                </div>
                <div class="tooltip-prompt__desc">
                  Changing section type will delete all section content. This operation can't be undone.
                </div>
                <button
                  class="btn btn--corners btn--block btn--warn"
                  @click="promptAccept"
                >
                  Yes, Change
                </button>
                <button
                  class="btn btn--corners btn--block btn--outline"
                  @click="promptCancel"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div
              v-else
              key="content"
            >
              <div
                v-if="!lockType"
                class="tooltip-tabs"
              >
                <button
                  v-for="t in ['Notepad', 'Survey']"
                  :key="t"
                  class="tooltip-tabs__tab"
                  :class="{
                    'tooltip-tabs__tab--active': tab === t
                  }"
                  @click="tab = t"
                >
                  <span>{{ t }}</span>
                </button>
              </div>
              <ContentTransition>
                <div
                  v-if="tab === 'Notepad' || lockType"
                  key="notepad"
                >
                  <div class="question-type-picker__section">
                    <ul class="selectable-items">
                      <li
                        v-for="listType of filterTypes"
                        :key="listType.type"
                      >
                        <div
                          class="selectable-item"
                          :class="{
                            'selectable-item--active': type === listType.type && !lockType,
                            'selectable-item--locked': lockType,
                          }"
                          @click="changeType(listType)"
                        >
                          <div
                            :style="colorStyle"
                            class="selectable-item__icon"
                          >
                            <Icon :name="listType.icon" />
                          </div>
                          {{ listType.text }}
                          <Icon name="lock" class="selectable-item__locked" v-if="locked" />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="create"
                    class="question-type-picker__section"
                  >
                    <ul class="selectable-items">
                      <li
                        v-for="listType of inlineTypes"
                        :key="listType.type"
                      >
                        <div
                          class="selectable-item"
                          :class="{ 'selectable-item--active': type === listType.type }"
                          @click="changeType(listType)"
                        >
                          <div
                            :style="colorStyle"
                            class="selectable-item__icon"
                          >
                            <Icon :name="listType.icon" />
                          </div>
                          {{ listType.text }}
                          <Icon name="lock" class="selectable-item__locked" v-if="locked" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-else
                  key="survey"
                >
                  <div class="question-type-picker__section">
                    <ul class="selectable-items">
                      <li
                        v-for="listType of surveyTypes"
                        :key="listType.type"
                      >
                        <div
                          class="selectable-item"
                          :class="{ 'selectable-item--active': type === listType.type }"
                          @click="changeType(listType)"
                        >
                          <div
                            :style="colorStyle"
                            class="selectable-item__icon"
                          >
                            <Icon :name="listType.icon" />
                          </div>
                          {{ listType.text }}
                          <Icon name="lock" class="selectable-item__locked" v-if="locked" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </ContentTransition>
              <div
                v-if="!create"
                class="question-type-picker__section question-type-picker__section--color"
              >
                <Swatches
                  appearance="squared"
                  :color="color"
                  @change="swatchChange"
                />
                <div class="question-type-picker__input-box">
                  <div
                    class="question-type-picker__active-color"
                    :style="colorStyle"
                  />
                  <div class="question-type-picker__input-wrap">
                    <BaseInput
                      class="question-type-picker__input"
                      :value="inputColor"
                      @input="inputChange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ContentTransition>
        </div>
      </template>
    </BaseTooltip>
    <div
      v-else-if="!$slots.default"
      class="question-type-picker-icon question-type-picker-icon--static"
    >
      <div
        class="methods__question-edit-counter-body"
        :style="{ background: color }"
      >
        <Icon
          :key="typeIcon"
          class="methods__question-edit-counter-icon"
          :name="typeIcon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Swatches from 'components/common/pickers/swatches';
import { QUESTION_COLORS } from 'constants/colors';
import { validateHexColor } from 'helpers/colors';
import { methodOptions } from 'services/storage.service';
import { analyticsLogEvent } from 'utils/analytics';

const TYPES = [
  {
    type: 'image',
    text: 'Image',
    icon: 'builder-addon--image',
    kind: 'inline',
  },
  {
    type: 'custom_6',
    text: 'Video',
    icon: 'builder-addon-chart',
    kind: 'inline',
  },
  {
    type: 'gallery',
    text: 'Gallery',
    icon: 'builder-addon-gallery',
  },
  {
    type: 'summary',
    text: 'Summary',
    icon: 'builder-addon-summary',
  },
  {
    type: 'charts',
    text: 'Charts',
    icon: 'builder-addon-charts',
  },
  {
    type: 'cards',
    text: 'Cards',
    icon: 'cards-25',
  },
  {
    type: 'checklist',
    text: 'Checklist',
    icon: 'builder-addon-checklist',
  },
  {
    type: 'pages',
    text: 'Pages',
    icon: 'file-25',
  },
  {
    type: 'scatter_plot',
    text: 'Pages chart',
    icon: 'builder-addon-chart',
  },
  {
    type: 'report_1',
    text: 'Report - insights',
  },
  {
    type: 'report_3',
    text: 'Report - text',
  },
  {
    type: 'report_4',
    text: 'Report - summary',
  },
  {
    type: 'report_5',
    text: 'Report - title',
  },
];

const SURVEY_TYPES = [
  {
    type: 'custom_5',
    text: 'Text',
    icon: 'builder-addon-list',
  },
  {
    type: 'custom_1',
    text: 'Scale',
    icon: 'scale-25',
  },
  {
    type: 'custom_2',
    text: 'Multiple Choice',
    icon: 'builder-addon-checklist',
  },
  {
    type: 'custom_3',
    text: 'Single Choice',
    icon: 'radio-25',
  },
  // {
  //   type: 'custom_4',
  //   text: 'Files',
  //   icon: 'file-25',
  // },
];

export default {
  name: 'QuestionTypePicker',
  components: {
    Swatches,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    actions: {
      type: [Array, null],
      default: null,
    },
    colors: {
      type: Array,
      default: () => QUESTION_COLORS,
    },
    placement: {
      type: String,
      default: 'left',
    },
    question: {
      type: Object,
    },
    create: {
      type: Boolean,
      required: false,
    },
    showChangeAlert: {
      type: Boolean,
      default: true,
    },
    lockType: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      open: false,
      needConfirm: false,
      tab: 'Notepad',
    };
  },
  computed: {
    colorStyle() {
      return { backgroundColor: this.color };
    },
    inputColor() {
      return this.color.replace(/^\#/gim, '');
    },
    typeIcon() {
      const type = [...this.types, ...this.surveyTypes].find((t) => t.type === this.type);

      return type ? type.icon : 'plus-bold';
    },
    types() {
      let list = TYPES;

      if (this.lockType) {
        list = [...TYPES, ...SURVEY_TYPES];
      }

      return list.filter((t) => t.kind !== 'inline').filter((t) => this.displayReportTypes || !t.type.match(/^report/gim));
    },
    filterTypes() {
      return this.types.filter((listType) => !this.lockType || this.type === listType.type)
    },
    inlineTypes() {
      return TYPES.filter((t) => t.kind === 'inline');
    },
    surveyTypes() {
      return SURVEY_TYPES.filter((t) => t.kind !== 'inline');
    },
  },
  watch: {
    open(open, old) {
      if (open !== old) {
        this.$emit('toggle', open);
      }
      if (!open) {
        this.needConfirm = false;
      }
      if (open) {
        if (!this.create && this.type) {
          const surveyType = !!this.surveyTypes.find((t) => t.type === this.type);
          this.tab = surveyType ? 'Survey' : 'Notepad';
        } else {
          const { projectCurrentMethodId } = this.$store.getters;
          let builderTypePickerTab = methodOptions.load(projectCurrentMethodId, 'builder-type-picker-tab');

          if (builderTypePickerTab) {
            this.tab = builderTypePickerTab;
          }
        }
      }
    },
    tab(tab) {
      const { projectCurrentMethodId } = this.$store.getters;
      methodOptions.save(projectCurrentMethodId, 'builder-type-picker-tab', tab);
      analyticsLogEvent(`page_editor_type_picker_tab_${tab}`);
    },
  },
  created() {
    this.displayReportTypes = this.$store.getters.user && this.$store.getters.user.email === 'templates@talebook.io';
  },
  mounted() {
    const { projectCurrentMethodId } = this.$store.getters;
    let builderTypePickerTab = methodOptions.load(projectCurrentMethodId, 'builder-type-picker-tab');

    if (builderTypePickerTab) {
      this.tab = builderTypePickerTab;
    }
  },
  methods: {
    swatchChange(color) {
      //this.open = false;
      this.$emit('color-change', color);
    },
    inputChange(color) {
      color = '#' + color.replace(/^\#/gim, '');
      if (!validateHexColor(color)) return;
      this.$emit('color-change', color);
    },
    changeType({ type, data }) {
      if (this.lockType) return;
      if (!this.create) {
        if (this.showChangeAlert) {
           this.needConfirm = type;
        } else {
          this.$emit('type-change', { type, data });
        }
      } else {
        this.$emit('add', { type, data });
        analyticsLogEvent(`page_editor_create_${type}`);
      }
    },
    promptAccept() {
      this.$emit('type-change', this.needConfirm);
      this.needConfirm = false;
    },
    promptCancel() {
      this.needConfirm = false;
    },
    onAddClick() {
      this.$emit('add');
    },
    close() {
      this.open = false;
    },
    show() {
      this.open = true;
    },
  },
};
</script>
