<template>
  <div />
</template>

<script>
import { navigateProjects } from 'helpers/router';

export default {
  name: 'MagicLink',
  created() {
    this.requestToken();
  },
  methods: {
    async requestToken() {
      try {
        await this.$store.dispatch('login', {
          providerName: 'magic_link',
          data: {
            token: this.$route.query.magic_link_token,
          },
        });
      } finally {
        navigateProjects();
      }
    },
  },
};
</script>
