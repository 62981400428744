<template>
  <component
    :is="componentName"
    v-bind="componentProps"
    :class="classNames"
  >
    <template v-if="value">
      <TableItem
        v-for="item of value"
        :key="item && item.id || item"
      >
        <slot v-bind="item" />
      </TableItem>
    </template>
    <slot v-else />
  </component>
</template>
<script>
import { TransitionGroup } from 'vue'

export default {
  name: 'BaseTable',
  components: {
    TransitionGroup,
  },
  props: {
    value: Array,
    animated: {
      type: Boolean,
      required: false,
      default: false,
    },
    appearance: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  computed: {
    componentName() {
      return this.animated ? 'transition-group' : 'ul';
    },
    componentProps() {
      if (this.animated) {
        return {
          tag: 'ul',
          type: 'transition',
          name: 'enter-list',
        };
      }

      return {};
    },
    classNames() {
      return ['table', ...this.appearance.split(' ')].join(' table--');
    },
  },
};
</script>
