<template>
  <div
    v-if="!hasButton"
    v-click-outside="hideMenu"
    class="base-menu base-menu__container"
    :class="{ menuActive: isMenuVisible }"
    @click="toggleMenu"
  >
    <ul
      class="base-menu__list"
      :class="menuClasses"
      @click.capture="hideMenu"
    >
      <slot />
    </ul>
  </div>
  <div
    v-else
    v-click-outside="hideMenu"
    class="base-menu base-menu--new base-menu__container"
    :class="{ menuActive: isMenuVisible }"
    @click="toggleMenu"
  >
    <slot name="button" />
    <ul
      class="base-menu__list base-menu__list--new"
      :class="menuClasses"
      @click.capture="hideMenu"
    >
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BaseMenu',
  props: {
    center: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
  },
  data() {
    return {
      isMenuVisible: false,
      menuShownTimestamp: 0,
    };
  },
  computed: {
    menuClasses() {
      return {
        'base-menu__list--left': this.left,
        'base-menu__list--center': !this.left,
        menuActive: this.isMenuVisible,
        'js-active': this.isMenuVisible,
      };
    },
    hasButton() {
      return !!this.$slots.button;
    },
  },
  methods: {
    toggleMenu(e) {
      e.stopPropagation();
      this.isMenuVisible = !this.isMenuVisible;
      this.shownTimestamp = Date.now();
      this.$emit('menu-toggle', this.isMenuVisible);
    },
    hideMenu() {
      this.isMenuVisible = false;
      this.$emit('menu-toggle', false);
    },
  },
};
</script>

<style lang="scss">
.base-menu {
  &__list {
    li {
      font-size: 1.4rem;
      line-height: 1.43;
      text-align: left;
      color: $color-grey-brownish;
      padding: 10px !important;
      transition: all 0.35s ease;
      cursor: pointer;
      margin: 0 !important;
      letter-spacing: normal;
      list-style-type: none !important;
      border-radius: 4px;

      &:hover {
        background: $color-grey-mystic;
      }

      &.is-active {
        font-weight: 600;
        color: #252729;
        background: #F8F9FA;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.base-menu {
  &__container {
    width: 42px;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    border: 0;
    background: transparent;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.8px;
    color: #555555;
    text-align: center;
    margin: 0 5px;
    right: -60px;
    top: 0;
    outline: 0;
    background: image_url('svg/more-vertical.svg') no-repeat center center;
    background-size: 5px 15px;
    transition: all 0.5s ease;
    &:hover {
      // background-color: #f7f9f9;
      cursor: pointer;
    }
  }

  &__list {
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: 0 15px 18px 0 rgba($color-black, 0.06);
    border: solid 1px rgba(237, 237, 237, 0.62);
    padding: 5px;
    margin: 0;
    position: relative;
    top: calc(50% + 20px);
    list-style: none;
    z-index: 99;
    width: 200px;
    transition: all 0.25s ease;
    opacity: 0;
    visibility: hidden;
    text-transform: initial;
    left: 50%;

    &:after {
      content: '';
      display: block;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid rgba(237, 237, 237, 0.62);
      position: absolute;
      top: -8px;
    }
    &:before {
      content: '';
      display: block;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $color-white;
      position: absolute;
      z-index: 2;
      top: -7px;
      right: 91px;
    }

    &--center {
      transform: translateX(-50%) translateY(15px);

      &:after {
        right: 91px;
      }
      &:before {
        right: 91px;
      }

      &.menuActive {
        transform: translate(-50%, 0);
      }
    }

    &--left {
      transform: translateX(-89%) translateY(15px);

      &:after {
        right: 13px;
      }
      &:before {
        right: 13px;
      }

      &.menuActive {
        transform: translate(-89%, 0);
      }
    }
  }
  .menuActive {
    opacity: 1 !important;
    visibility: visible;
  }
}

.base-menu--new {
  width: auto;
  text-transform: inherit;
  font-weight: inherit;
  vertical-align: initial;
  background: transparent;
  padding: 0;
  margin: 0;
  font-size: inherit;
  position: relative;
  right: auto;
  top: auto;
  letter-spacing: inherit;
}

.base-menu__list--new {
  position: absolute;
}
</style>
