<template>
  <div
    ref="wrap"
    class="sortable-wrap"
    :data-sortable-group="group"
    :data-sortable-editor-mode="editorMode"
  >
    <TransitionGroup name="enter-list" v-if="animateList">
      <slot />
    </TransitionGroup>
    <slot v-else/>
  </div>
</template>
<script>
import { cloneOptimizedNode, hasParent, hasParentWithClasses } from 'helpers/ui';
import { getScrollPosition } from 'utils/scroll';
import EventsService from 'services/events.service';
import { mapGetters } from 'vuex';

export default {
  name: 'Sortable',
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    handlerClass: {
      type: String,
      default: 'sortable-handler',
    },
    eventHandlerClass: {
      type: String,
      default: '',
    },
    selectAreaClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: 'default',
    },
    canDragTo: {
      type: Array,
      default: () => ([]),
    },
    canDragIn: {
      type: Function,
      default: () => true,
    },
    placeholderHeight: {
      type: Function,
      default: null,
    },
    animateList: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters(['uiHideAnswers', 'isLibraryOpen']),
    shouldAddMargins() {
      return !(this.uiHideAnswers && this.isLibraryOpen);
    },
  },
  watch: {
  },
  created() {
    this.selectedItems = [];
  },
  mounted() {
    const eventHandler = this.eventHandlerClass ? hasParentWithClasses(this.$el, [this.eventHandlerClass]) : this.$el;
    eventHandler.addEventListener('mousedown', this.mouseDown);
    eventHandler.addEventListener('touchstart', this.mouseDown);
    document.addEventListener('mousedown', this.documentMouseDown);
    EventsService.on('selection:unselect', this.deselectAll);
    this.eventHandler = eventHandler;
    this.$el._sortable = this;
  },
  unmounted() {
    this.eventHandler.removeEventListener('mousedown', this.mouseDown);
    this.eventHandler.removeEventListener('touchstart', this.mouseDown);
    document.removeEventListener('mousedown', this.documentMouseDown);
    EventsService.off('selection:unselect', this.deselectAll);
  },
  updated() {
    const items = Array.from(this.$el.children);
    this.$el.classList.remove('sortable-wrap--animated');

    this.$el._sortable = this;

    items.forEach((item, index) => {
      item.classList.remove('sortable-item--animated');
      item.style.marginTop = '';
      item.style.display = '';

      if (this.selectedItems.includes(item.dataset.id)) {
        item.classList.add('sortable-selected');
      } else {
        item.classList.remove('sortable-selected');
      }
    });
  },
  methods: {
    mouseMove(e) {
      const { clone, startTouchY, select } = this;
      let clientX = e.touches ? e.touches[0].clientX : e.clientX;
      let clientY = e.touches ? e.touches[0].clientY : e.clientY;
      this.currentY = clientY;

      if (clone) {
        this.recalcTarget(clientX, clientY);
        return;
      } else if (startTouchY && select) {
        this.recalcSelection(clientX, clientY);
      }
    },
    mouseUp(e) {
      const { wrap } = this.$refs;
      const { clone, currentArea, draggedIds, currentIndex } = this;
      let clientX = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
      let clientY = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

      if (clone) {
        const sameArea = currentArea === this.$el;
        const dragData = draggedIds.map((id) => {
          return {
            ...(this.data && this.data.find((d) => (d.id === id || d.localId === id))),
          }
        });

        const event = {
          ids: draggedIds,
          group: this.group,
          dragData,
          area: currentArea,
          index: currentIndex,
        };

        clone.classList.add('sortable-clone--end');
        this.recalcTarget(clientX, clientY, true);

        if (sameArea) {
          window.setTimeout(() => {
            this.$emit('dragend', event);
            this.clear();
          }, 300);
        } else {
          window.setTimeout(() => {
            if (currentArea) {
              this.$emit('dragcancel', event);
              this.$emit('dragout', event);
              currentArea._sortable.$emit('dragin', event);
            } else {
              this.$emit('dragend', event);
            }
            this.clear();
          }, 300);
          
        }

        this.selectedItems = [];
      } else {
        this.$emit('selectend', this.selectedItems);
      }

      document.removeEventListener('mousemove', this.mouseMove);
      document.removeEventListener('touchmove', this.mouseMove);
      document.removeEventListener('mouseup', this.mouseUp);
      document.removeEventListener('touchend', this.mouseUp);
      this.currentIndex = null;
      this.startTouchX = null;
      this.startTouchY = null;
      this.activeTarget = null;
      this.activeClone = null;
      this.multiSelectStarted = null;
      this.selectionAborted = false;
      this.startSelectItem = null;
      this.startSeletionInput = null;

      if (this.selectionBox) {
        document.body.removeChild(this.selectionBox);
        this.selectionBox = null;
      }

      this.stopScrolling();
    },

    clear() {
      const { $el, clone, dropAreas } = this;

      if (!clone) return;

      clone.parentNode.removeChild(clone);
      this.$el.classList.remove('sortable-wrap--animated');

      dropAreas.forEach(({ type, area, target, areaItems, visibleItems }) => {
        area.classList.remove('sortable-target-active');
        
        if (type === 'list') {
          area.removeChild(target);
          area.style.paddingBottom = '';

          areaItems.forEach((areaItem) => {
            areaItem.style.display = '';
            areaItem.style.height = '';
            areaItem.style.overflow = '';
            areaItem.style.opacity = '';
            areaItem.style.marginTop = '';
            areaItem.classList.remove('sortable-item');
            areaItem.classList.remove('sortable-item--animated');
            areaItem.classList.remove('sortable-selected');
            areaItem.classList.remove('sortable-dragging');
            areaItem.classList.remove('sortable-selected--first');
          });
        }
      });

      this.clone = null;
      this.dropAreas = null;
    },
    
    recalcSelection(x, y) {
      let { startTouchY, startTouchX, startScroll, multiSelectStarted, startSelectItem, startSeletionInput, selectionBox, rectSelection } = this;
      const { wrap } = this.$refs;
      if (!wrap) return;
      const items = Array.from(wrap.children);
      const scrollTop = getScrollPosition().top;
      const scrollDelta = scrollTop - startScroll;
      const topY = Math.min(startTouchY - scrollDelta, y);
      const bottomY = Math.max(startTouchY - scrollDelta, y);
      let selectedIds = [];
      let selectedEls = [];

      if (!selectionBox) {
        selectionBox = this.selectionBox = document.createElement('div');
        selectionBox.className = 'selection-box';
        document.body.appendChild(selectionBox);
      }

      let boxLeft = Math.min(startTouchX, x);
      let boxTop = Math.min(startTouchY + startScroll, y + scrollTop);
      let boxWidth = Math.abs(startTouchX - x)
      let boxHeight = Math.abs((startTouchY + startScroll) - (y + scrollTop))

      selectionBox.style.left = boxLeft + 'px';
      selectionBox.style.top = boxTop + 'px';
      selectionBox.style.width = boxWidth + 'px';
      selectionBox.style.height = boxHeight + 'px';
      selectionBox.style.display = rectSelection ? 'block' : 'none';

      if (startSeletionInput) {
        const rect = startSeletionInput.getBoundingClientRect();
        if (rect.bottom < y || rect.top > y) {
          multiSelectStarted = true;
        }
      }

      items.forEach((item, index) => {
        const rect = item.getBoundingClientRect();

        if (rect.bottom > topY && rect.top < bottomY) {
          selectedIds.push(item.dataset.id);
          selectedEls.push(item);
        }
        
        /**
         * Fix labels clicking selection
         */
        if (
          (rect.bottom > topY && rect.top < bottomY) &&
          (rect.left > x || rect.right < x) &&
          (startSelectItem && item === startSelectItem)
        ) {
          // multiSelectStarted = true;
        }

        item.classList.remove('sortable-selected');
        item.classList.remove('sortable-selected--first');
      });

      if (selectedIds.length > 1 || multiSelectStarted) {
        selectedEls.forEach((item, index) => {
          if (index === 0) {
            item.classList.add('sortable-selected--first');
          }
          item.classList.add('sortable-selected');
        });
        this.selectedItems = selectedIds;

        if (!this.selectionAborted) {
          this.abortSelection();
          this.selectionAborted = true;
        }

        this.multiSelectStarted = true;
      } else {
        this.selectedItems = [];
      }
    },
    recalcTarget(x, y, finalFrame) {
      const { wrap } = this.$refs;
      const { shouldAddMargins, targetHeight, fullHeight, draggedHeight, clone, dropAreas, prevHeight } = this;

      if (!clone) {
        return;
      }

      let height = finalFrame ? fullHeight : draggedHeight;
      let activeArea = null;
      let activeGroup = null;
      let activeIndex = 0;
      let topPosition = 0;

      dropAreas.forEach(({ type, area, group, handler, target, editorMode, areaItems, visibleItems }) => {
        const areaRect = handler.getBoundingClientRect();
        const areaActive = dropAreas.length === 1 || (
            x > areaRect.left &&
            x < areaRect.right &&
            y > areaRect.top &&
            y < areaRect.bottom
          );
        
        if (areaActive && !activeArea) {
          area.classList.add('sortable-target-active');

          if (type === 'list') {
            visibleItems.forEach((item, index) => {
              const rect = item.getBoundingClientRect();

              if (y > rect.top + rect.height / 2) {
                activeIndex = index + 1;
                topPosition += item.offsetHeight;
              }
            });

            target.style.display = '';
          }
          
          activeArea = area;
          activeGroup = group;
        } else {
          area.classList.remove('sortable-target-active');

          if (type === 'list') {
            target.style.display = 'none';
          }
        }
      });

      if (finalFrame || prevHeight !== height || this.currentArea !== activeArea || this.currentIndex !== activeIndex) {
        dropAreas.forEach(({ placeholderHeight, type, group, area, target, areaItems, editorMode, allItems, draggedItems, visibleItems }) => {
          if (type === 'list') {
            const cloneItems = Array.from(clone.children).filter((e) => !e.classList.contains('sortable-counter'));
            const firstParagraph = !cloneItems[0].classList.contains('section-wrap--not-paragraph');
            const lastParagraph = !cloneItems[cloneItems.length - 1].classList.contains('section-wrap--not-paragraph');
            const isActive = area === activeArea;
            const isClone = group !== activeGroup;
            const finalHeight = placeholderHeight ? placeholderHeight() : height;

            let prevIsParagraph = false;
            let targetFound = false;

            visibleItems = isClone ? [...allItems, null] : [...visibleItems, null];

            draggedItems.forEach((item) => {
              item.style.height = isClone ? item.originalHeight + 'px' : '0';
              item.style.opacity = isClone ? '' : '0';
            })

            area.style.paddingBottom = '';

            visibleItems.forEach((item, index) => {
              let isParagraph = !this.shouldAddMargins || (!item || !item.classList.contains('section-wrap--not-paragraph'));

              if (item) {
                if (!isParagraph && !prevIsParagraph && editorMode) {
                  item.style.marginTop = '32px';
                } else {
                  item.style.marginTop = '';
                }
              }

              if (area === activeArea) {
                if (index === activeIndex) {
                  let extraHeight = 0;

                  if (editorMode) {
                    if (
                      (!prevIsParagraph && !firstParagraph) ||
                      (!isParagraph && !item)
                    ) {
                      extraHeight += 32;
                      topPosition += 32;
                    }

                    if (!isParagraph && !lastParagraph) {
                      extraHeight += 32;
                    }
                  }
                  
                  if (!item) {
                    area.style.paddingBottom = `${finalHeight + extraHeight}px`;
                  } else {
                    item.style.marginTop = `${finalHeight + extraHeight}px`;
                  }

                  targetFound = true;
                }

                if (editorMode && !targetFound && !isParagraph && !prevIsParagraph) {
                  topPosition += 32;
                }
              }

              prevIsParagraph = isParagraph;
            });
            target.style.transform = `translateY(${topPosition}px)`;
            target.style.height = `${finalHeight}px`;

            if (finalFrame && area === activeArea) {
              const areaRect = area.getBoundingClientRect();

              Object.assign(clone.style, {
                left: `${areaRect.left}px`,
                top: `${areaRect.top + topPosition}px`,
                width: `${areaRect.width}px`,
                height: `${height}px`,
              });
            }
          }
        });

        this.prevHeight = height;
      }

      if (!finalFrame) {
        Object.assign(clone.style, {
          left: `${x - this.mouseOffsetX}px`,
          top: `${y - this.mouseOffsetY}px`,
        });
      }

      this.currentArea = activeArea;
      this.currentIndex = activeIndex;
    },
    mouseDown(e) {
      if (this.disabled) return;

      const { select, canDragTo, group } = this;
      const wrap = this.$el;

      if (!wrap) return;

      const items = Array.from(wrap.children);
      const eventHandler = hasParentWithClasses(e.target, [this.handlerClass]);
      const handlerFirstParent = eventHandler && hasParentWithClasses(eventHandler, ['sortable-wrap']);
      let handler = handlerFirstParent === wrap && eventHandler;
      const item = hasParent(e.target, items);
      const closestInput = hasParent(e.target, (e) => ['INPUT', 'TEXTAREA'].includes(e.tagName) || e.contentEditable === 'true');

      if (!this.handlerClass) {
        handler = item;
      }

      let id;
      let clientX = e.touches ? e.touches[0].clientX : e.clientX;
      let clientY = e.touches ? e.touches[0].clientY : e.clientY;

      if (select && !hasParentWithClasses(e.target, [this.selectAreaClass])) {
        EventsService.emit('selection:unselect');
        return;
      }

      document.addEventListener('mousemove', this.mouseMove);
      document.addEventListener('touchmove', this.mouseMove);
      document.addEventListener('mouseup', this.mouseUp);
      document.addEventListener('touchend', this.mouseUp);

      /**
       * Get id of touched item
       */
      if (item) {
        id = item.dataset.id;
      }

      /**
       * Toggle item selection when CMD is pressed
       */
      if (select && (e.metaKey || e.ctrlKey) && item) {
        if (this.selectedItems.find((i) => i === id)) {
          this.selectedItems = this.selectedItems.filter((i) => i !== id);
          item.classList.remove('sortable-selected');
        } else {
          this.selectedItems.push(id);
          item.classList.add('sortable-selected');
        }

        Array.from(document.querySelectorAll('.sortable-selected')).forEach((el, index) => {
          if (index === 0) { 
            el.classList.add('sortable-selected--first');
          } else {
            el.classList.remove('sortable-selected--first');
          }
        });

        this.$emit('selectend', this.selectedItems);

        this.abortSelection();
        e.preventDefault();
        return;
      }

      const scrollTop = getScrollPosition().top;

      this.currentY = clientY;
      this.startScroll = scrollTop;
      this.startScrolling();

      /**
       * User start touching outside handler, we should watch for selection
       */
      if (!handler) {
        this.startTouchX = clientX;
        this.startTouchY = clientY;
        this.rectSelection = !hasParent(e.target, (el) => {
          return (['INPUT', 'TEXTAREA'].includes(el.tagName) || el.contentEditable === 'true') || (el.classList && el.classList.contains('builder-section__content'));
        })

        EventsService.emit('selection:unselect');

        if (item) {
          this.startSeletionInput = closestInput;
          this.startSelectItem = item;
        }

        this.recalcSelection(clientX, clientY);

        e.stopPropagation();
        return;
      }

      const draggedIds = (select && this.selectedItems.length) ? this.selectedItems : [id];
      const dropAreasIds = [this.group, ...this.canDragTo];
      const dropAreasElements = Array.from(document.querySelectorAll('.sortable-wrap, .sortable-drop')).filter((e) => dropAreasIds.includes(e.dataset.sortableGroup));

      const clone = document.createElement('div');
      const dropAreas = [];
      const draggedElements = [];
      const sortableElements = [];
      
      let topBound = Infinity;
      let bottomBound = 0;
      let leftBound = Infinity;
      let rightBound = 0;
      let prevRect = null;
      let fullHeight = 0;
      let draggedHeight = 0;

      const dragData = draggedIds.map((id) => {
        return {
          ...(this.data && this.data.find((d) => (d.id === id || d.localId === id))),
        }
      });

      dropAreasElements.forEach((area) => {
        const areaRect = area.getBoundingClientRect();

        // Filter invisible drop areas
        if (!areaRect.width && !areaRect.height) {
          return;
        }

        const areaHandler = (area._sortable && area._sortable.eventHandler) || area;
        const placeholderHeight = area._sortable && area._sortable.placeholderHeight;
        const group = area._sortable && area._sortable.group;
        const canDragIn = area._sortable && area._sortable.canDragIn;

        const type = area.classList.contains('sortable-drop') ? 'drop' : 'list';
        const target = document.createElement('div');
        const areaItems = Array.from(area.children);
        const areaData = {};

        if (canDragIn) {
          if (!canDragIn({ group: this.group, dragData })) {
            return;
          }
        }
        
        // Clear classes
        if (type === 'list') {
          areaItems.forEach((item, index) => {
            item.classList.add('sortable-item');
            item.classList.remove('sortable-selected');
            item.classList.remove('sortable-selected--first');
          });

          let emptySpace = 0;
          let topMargin = 0;
          const margins = [];
          const visibleItems = [];
          const draggedItems = [];
          const allItems = [];

          for (const areaItem of areaItems) {
            const local = areaItem.dataset.local === 'true';
            const isDragged = draggedIds.includes(areaItem.dataset.id);

            if (isDragged || local) {
              const rect = areaItem.getBoundingClientRect();
              
              if (isDragged) {
                const itemClone = cloneOptimizedNode(areaItem);

                if (rect.top < topBound) {
                  topBound = rect.top;
                }

                if (rect.left < leftBound) {
                  leftBound = rect.left;
                }

                if (rect.bottom > bottomBound) {
                  bottomBound = rect.top + rect.height;
                }

                if (rect.right > rightBound) {
                  rightBound = rect.right;
                }

                if (prevRect) {
                  emptySpace += rect.top - prevRect.bottom;
                }

                fullHeight += rect.height;
                areaItem.originalHeight = rect.height;
                
                clone.appendChild(itemClone);

                itemClone.style.transform = `translateY(${emptySpace}px)`;
                itemClone.classList.remove('sortable-selected');
                itemClone.classList.add('sortable-cloned');
                areaItem.classList.add('sortable-dragging');

                window.requestAnimationFrame(() => {
                  itemClone.style.transform = 'translateY(0px)';
                });

                draggedElements.push(areaItem);
                draggedItems.push(areaItem);

                prevRect = rect;
              }

              topMargin += rect.height;

              margins.push(0);
            } else {
              margins.push(topMargin);
              visibleItems.push(areaItem);
              topMargin = 0;
            }

            sortableElements.push(areaItem);
            !local && allItems.push(areaItem);
          }

          areaItems.forEach((areaItem, index) => {
            let margin = margins[index];
            if (margin) {
              areaItem.style.marginTop = `${margin}px`;
            }
            if (areaItem.dataset.local === 'true') {
              areaItem.style.display = 'none';
            }
          });

          draggedElements.forEach((item) => {
            item.style.height = '0';
            item.style.overflow = 'hidden';
            item.style.opacity = '0';
          });

          target.className = 'sortable-target';

          Object.assign(areaData, {
            target,
            areaItems,
            visibleItems,
            draggedItems,
            allItems,
          });
        }

        Object.assign(areaData, {
          type,
          area,
          handler: areaHandler,
          placeholderHeight,
          group,
          editorMode: area.dataset.sortableEditorMode,
        });

        dropAreas.push(areaData);
      });

      draggedHeight = Math.min(320, fullHeight);

      clone.className = 'sortable-clone';

      if (draggedElements.length > 1) {
        const counter = document.createElement('div');
        counter.innerHTML = `${draggedIds.length} Rows`;
        counter.className = 'sortable-counter';
        clone.appendChild(counter);
      }

      Object.assign(clone.style, {
        left: `${leftBound}px`,
        top: `${topBound}px`,
        width: `${rightBound - leftBound}px`,
        height: `${bottomBound - topBound}px`,
      });

      this.clone = clone;
      this.dropAreas = dropAreas;
      this.draggedElements = draggedElements;
      this.sortableElements = sortableElements;
      this.mouseOffsetX = clientX - leftBound;
      this.mouseOffsetY = clientY - topBound;
      this.draggedIds = draggedIds;
      this.fullHeight = fullHeight;
      this.draggedHeight = draggedHeight;

      this.recalcTarget(clientX, clientY, true);

      dropAreas.forEach(({ type, area, target }) => {
        if (type === 'list') {
          area.appendChild(target);
        }

        area.classList.add('sortable-wrap--animated');
      })

      document.body.appendChild(clone);
      this.$emit('dragstart');
      
      requestAnimationFrame(() => {
        sortableElements.forEach((item) => {
          item.classList.add('sortable-item--animated');
        });

        clone.style.height = `${draggedHeight}px`;
        this.recalcTarget(clientX, clientY);
      });

      e.stopPropagation();
      e.preventDefault();
    },
    scrollingFrame() {
      const {currentY, selectedItems, activeClone } = this;
      const distance = window.innerHeight - currentY;

      if (selectedItems.length || activeClone) {
        if (distance < 100) {
          const speed = (1 - distance / 100) * 6;
          window.scrollBy(0, speed);
        }

        if (currentY < 100) {
          const speed = (1 - currentY / 100) * 6;
          window.scrollBy(0, -speed);
        }
      }

      if (this.scrollingEnabled) {
        requestAnimationFrame(this.scrollingFrame);
      }
    },
    startScrolling() {
      this.scrollingEnabled = true;
      requestAnimationFrame(this.scrollingFrame);
    },
    stopScrolling() {
      this.scrollingEnabled = false;
    },
    selectAll() {
      const { wrap } = this.$refs;
      const items = Array.from(wrap.children);
      const selectedItems = items.map((i) => i.dataset.id);

      items.forEach((item, index) => {
        if (index === 0) {
          item.classList.add('sortable-selected--first');
        } else {
          item.classList.remove('sortable-selected--first');
        }
        item.classList.add('sortable-selected');
      });

      this.selectedItems = selectedItems;
      this.$emit('selectend', this.selectedItems);
    },
    deselectAll() {
      const { wrap } = this.$refs;
      const items = Array.from(wrap.children);
      const selectedItems = items.map((i) => i.dataset.id);

      items.forEach((item) => {
        item.classList.remove('sortable-selected');
        item.classList.remove('sortable-selected--first');
      });

      this.selectedItems = [];
      this.$emit('selectend', this.selectedItems);
    },
    abortSelection() {
      if (window.getSelection) {
        if (window.getSelection().empty) {
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        document.selection.empty();
      }
    },
    documentMouseDown(e) {
      const outside = !hasParent(e.target, [this.eventHandler]);

      if (this.select && outside) {
        EventsService.emit('selection:unselect');
      }
    },
  },
};
</script>