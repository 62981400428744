<template>
  <ContentTransition :multiple="true" init-hidden>
    <div
      v-if="shouldDisplay"
      class="help-banner-wrap"
    >
      <div
        class="help-banner"
        :class="[`help-banner--${name}`]"
      >
        <div
          class="help-banner__close"
          @click="close"
        >
          <Icon name="close" />
        </div>
        <div class="help-banner__body">
          <template v-if="name === 'projects'">
            <div class="help-banner__title">
              Welcome!
            </div>
            <div class="help-banner__desc">
              Check out the sample project below to explore Talebook. When you’re ready, create your own.
            </div>
            <img
              class="help-banner__img"
              src="/static/img/help/projects.svg"
            >
          </template>
          <template v-if="name === 'tags'">
            <div class="help-banner__title">
              Explore tags
            </div>
            <div class="help-banner__desc">
              Compare number of tags visually to discover the themes. Enter the tag to see all linked data.
            </div>
            <a
              href=""
              class="btn btn--sm"
            >Learn more</a>
            <img
              class="help-banner__img"
              src="/static/img/help/tags.svg"
            >
          </template>
          <template v-if="name === 'highlights'">
            <div class="help-banner__title">
              Compare text fragments in one place
            </div>
            <div class="help-banner__desc">
              Analyze highlights to uncover themes. Add your findings to insights.
            </div>
            <a
              href=""
              class="btn btn--sm"
            >Learn more</a>
            <img
              class="help-banner__img"
              src="/static/img/help/highlights.svg"
            >
          </template>
          <template v-if="name === 'insights'">
            <div class="help-banner__title">
              Summarize your findings
            </div>
            <div class="help-banner__desc">
              Create insights to showcase your conclusions and share them with your team.
            </div>
            <a
              href="https://talebookapp.gitbook.io/talebook/~/changes/ho5HbHa1QYVlZZg9nv3H/insights#create-a-new-insight"
              class="btn btn--sm"
              target="_blank"
            >Learn more</a>
            <img
              class="help-banner__img"
              src="/static/img/help/insights.svg"
            >
          </template>
        </div>
      </div>
    </div>
  </ContentTransition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HelpBanner',
  props: {
    name: String,
  },
  data() {
    return {
      shouldDisplay: false,
    };
  },
  watch: {
    tutorialsData() {
      this.setShouldDisplay();
    },
  },
  async mounted() {
    await this.fetchConfig();
    this.setShouldDisplay();
  },
  computed: {
    ...mapGetters(['tutorialsData', 'isShared', 'userCanEditProjects']),
    id() {
      const name = `helpbanner${this.name}`;
      return name;
    },
  },
  methods: {
    async fetchConfig() {
      await this.$store.dispatch('fetchTutorialsConfig');
    },
    setShouldDisplay() {
      this.shouldDisplay = this.tutorialsData && !this.tutorialsData[this.id] && !this.isShared && this.userCanEditProjects;
    },
    close() {
      this.$store.dispatch('closeTutorial', this.id);
    },
  },
};
</script>


