<template>
  <span
    class="icon"
    :class="classes"
  >
    <InlineSvg :src="src" />
  </span>
</template>
<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'Icon',
  components: {
    InlineSvg,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'normal',
    },
    appearance: {
      type: String,
      required: false,
      default: 'default',
    },
    animation: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      svg: null,
    };
  },
  computed: {
    src() {
      return `/static/img/svg/${this.name}.svg`;
    },
    classes() {
      return [
        {
          'icon--normal': this.size === 'normal',
          'icon--auto': this.size === 'auto',
          [`icon--animate-${ this.animation }`]: true,
          [`icon--${ this.name }`]: true,
        },
        this.appearance.split(' ').map((a) => `icon--${a}`),
      ];
    },
  },
};
</script>
