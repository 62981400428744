/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import { validate } from 'helpers/email';
import { navigateOnboarding, navigateProjects } from 'helpers/router';
import { trimEnd } from 'lodash';
import NotificationsService from 'services/notifications.service';
import StorageService from 'services/storage.service';
import WorkspacesService from 'services/workspaces.service';
import generateId from 'utils/id';
import { merge } from 'utils/reactive';

const moduleState = {
  workspaces: null,
  workspaceCurrentId: null,
  limits: [],
};

const getters = {
  workspaces: (state) => state.workspaces,
  workspaceCurrent: (state, getters) => state.workspaces && state.workspaces.find((w) => w.id === getters.workspaceCurrentId),
  workspaceCurrentId: (state) => state.workspaceCurrentId,
  workspaceCurrentName: (state, getters) => getters.workspaceCurrent && getters.workspaceCurrent.name,
  workspaceTeam: (state, getters) => getters.workspaceCurrent && getters.workspaceCurrent.teamMembers && [
    ...getters.workspaceCurrent.teamMembers,
    ...getters.workspaceCurrent.invitations.filter((i) => !i.acceptedAt).map((i) => ({
      id: i.id || i.email,
      pending: true,
      user: i,
    })),
  ] || [],
  userRole: (state, getters) => {
    const userId = getters.user && getters.user.id;
    const member = getters.workspaceTeam.find((u) => u.userId === userId);
    return member && member.permission && member.permission.rights;
  },
  isUserOwner: (state, getters) => {
    return getters.userRole === 'owner';
  },
  isUserEditor: (state, getters) => {
    return getters.userRole === 'write';
  },
  isUserReader: (state, getters) => {
    return getters.userRole === 'read';
  },

  //limits
  insightsLimitReached: ({ limits, workspaceCurrentId }) => {
    const limit = limits[workspaceCurrentId];

    if (limit && limit.currentInsights) {
      return limit.currentInsights >= limit.maxInsights;
    }

    return false;
  },
  itemsLimitReached: ({ limits, workspaceCurrentId }) => {
    return false;
  },
  tagsLimitReached: ({ limits, workspaceCurrentId }) => {
    return false;
  },
  projectsLimitReached: ({ limits, workspaceCurrentId }) => {
    const limit = limits[workspaceCurrentId];

    if (limit && limit.currentProjects) {
      return limit.currentProjects >= limit.maxProjects;
    }

    return false;
  },
  usersLimitReached: ({ limits, workspaceCurrentId }) => {
    const limit = limits[workspaceCurrentId];

    if (limit && limit.projectIds) {
      return true;
    }

    return false;
  },
  isLockedByLimit: ({ limits, workspaceCurrentId }, getters) => (id) => {
    if (getters.isShared) {
      return false;
    }

    const limit = limits[workspaceCurrentId];

    if (limit && limit.insightIds) {
      const list = [...limit.insightIds, ...limit.projectIds];
      return !list.includes(id);
    }

    return false;
  },
  transcriptionLimit: ({ limits, workspaceCurrentId }) => {
    const limit = limits[workspaceCurrentId];
    return limit && limit.transcription;
  },
};

const actions = {
  loggedOut({ commit }) {
    commit('WORKSPACES_RESET');
  },
  async workspacesFetch({ dispatch, getters, commit }) {
    if (!getters.user) {
      await dispatch('requireUser')
    }

    if (!getters.isLoggedIn) {
      return;
    }

    const workspaces = await WorkspacesService.fetchAll();

    await commit('WORKSPACES_SET', { workspaces });

    dispatch('workspaceLimitsFetch', getters.workspaceCurrentId);

    return workspaces;
  },
  async workspaceLimitsFetch({ dispatch, getters, commit }, id) {
    if (!id) {
      id = getters.workspaceCurrentId;
    }
    
    const limits = await WorkspacesService.fetchLimits(id);
    commit('WORKSPACE_LIMITS_SET', { id, limits });
  },
  requireWorkspaces({ dispatch, getters }) {
    if (!getters.workspaces && !this.isFetching) {
      this.isFetching = dispatch('workspacesFetch').then(() => this.isFetching = false);
    }

    return this.isFetching;
  },
  async workspaceCreate({ commit, dispatch }, data) {
    await dispatch('requireWorkspaces');
    const workspace = await WorkspacesService.create(data);
    commit('WORKSPACE_ADD', { data: workspace });
    return workspace;
  },
  async workspaceChange({ commit, dispatch }, { id, data, local = false }) {
    await dispatch('requireWorkspaces');
    commit('WORKSPACE_UPDATE', { id, data });

    if (local) {
      return;
    }

    const response = await WorkspacesService.update(id, data);
    return response;
  },
  async workspaceChangeImage({ dispatch }, { id, image }) {
    dispatch('workspaceChange', {
      id,
      data: {
        image: {
          uploading: true,
          file: image,
        },
      },
      local: true, 
    });

    const response = await WorkspacesService.imageChange(
      id,
      image
    );

    dispatch('workspaceChange', {
      id,
      data: {
        image: response.image,
      },
      local: true, 
    });
  },
  async workspaceRemoveImage({ dispatch }, { id }) {
    dispatch('workspaceChange', {
      id,
      data: {
        image: null,
        removeImage: true,
      },
    });
  },
  async workspaceDelete({ commit, dispatch, getters }, { id }) {
    await dispatch('requireWorkspaces');

    const { workspaceCurrent } = getters;
    const { name } = workspaceCurrent;

    commit('WORKSPACE_DELETE', id);

    const { workspaces } = getters;
    
    if (!workspaces.length) {
      navigateOnboarding();
    } else {
      dispatch('workspaceSelect', { id: workspaces[0].id })
      navigateProjects();
    }

    NotificationsService.showInfo(`Deleted ${name} workspace`);

    const response = await WorkspacesService.delete(id);
    return response;
  },
  async workspaceUpdateMemberRights({ getters, commit }, {id, userId, rights}) {
    const { workspaceCurrentId } = getters;
    commit('WORKSPACE_TEAM_CHANGE_ROLE', {
      workspaceId: workspaceCurrentId,
      id,
      rights,
    });
    const response = await WorkspacesService.updateMemberRights(workspaceCurrentId, {id, userId, rights});
  },
  async workspaceInviteMember({ commit, getters }, {email, rights}) {
    const { workspaceCurrentId, workspaceTeam } = getters;

    email = email.trim();

    if (workspaceTeam.find((u) => u.id === email || (u.user && u.user.email === email))) {
      return { error: 'This user is already in the team' };
    }

    if (!validate(email)) {
      return { error: 'Introduce correct e-mail address' };
    }

    const response = await WorkspacesService.sendMemberInvitation(workspaceCurrentId, {email, rights});
    commit('WORKSPACE_TEAM_ADD', {
      workspaceId: workspaceCurrentId,
      invitation: {
        id: generateId(),
        email,
      },
    });

    return response;
  },
  async workspaceRemoveMember({ commit, getters }, {id, userId}) {
    const { workspaceCurrentId, workspaceCurrent } = getters;

    if (!id) {
      const member = workspaceCurrent.teamMembers.find((m) => m.userId === userId);

      if (!member) {
        return;
      }

      id = member.id;
    }

    WorkspacesService.removeMember(workspaceCurrentId, {id, userId});
    commit('WORKSPACE_TEAM_REMOVE', {
      workspaceId: workspaceCurrentId,
      id,
    });
  },
  async workspaceLeave({ commit, dispatch, getters }) {
    const { workspaceCurrentId, workspaceCurrent, user } = getters;
    const { name } = workspaceCurrent;

    await dispatch('workspaceRemoveMember', {
      userId: user.id,
    });
    
    commit('WORKSPACE_DELETE', workspaceCurrentId);

    const workspaces = getters.workspaces;

    if (!workspaces.length) {
      navigateOnboarding();
    } else {
      dispatch('workspaceSelect', { id: workspaces[0].id })
      navigateProjects();
    }

    NotificationsService.showInfo(`You leaved ${name} workspace`);
  },
  workspaceSelect({ commit, dispatch }, {id}) {
    commit('WORKSPACE_SET_CURRENT', id);
    StorageService.selectedWorkspace.save(id);
    dispatch('workspaceIdChanged', id);
  },
  workspaceIdChanged({ dispatch }) {
    dispatch('workspaceLimitsFetch');
  },
};

const mutations = {
  WORKSPACES_SET(state, { workspaces }) {
    if (state.workspaces) {
      state.workspaces = state.workspaces.filter((w) => workspaces.find((n) => n.id === w.id));
      state.workspaces.forEach((w) => {
        merge(w, workspaces.find((n) => n.id === w.id));
      });
      state.workspaces = workspaces;
    } else {
      state.workspaces = workspaces;
    }

    if (!state.workspaceCurrentId) {
      const storageId = StorageService.selectedWorkspace.load();

      if (storageId && workspaces.find((w) => w.id === storageId)) {
        state.workspaceCurrentId = storageId;
      } else if (state.workspaces[0]) {
        state.workspaceCurrentId = state.workspaces[0].id;
      }
    }
  },

  WORKSPACE_SET_CURRENT(state, id) {
    state.workspaceCurrentId = id;
  },

  WORKSPACE_UPDATE(state, { id, data }) {
    const workspace = state.workspaces.find((w) => w.id === id);
    merge(workspace, data);
  },

  WORKSPACE_ADD(state, { data }) {
    state.workspaces.push(data);
  },

  WORKSPACE_DELETE(state, id) {
    state.workspaces = state.workspaces.filter((w) => w.id !== id);
  },

  WORKSPACE_TEAM_ADD(state, { workspaceId, invitation }) {
    const workspace = state.workspaces.find((w) => w.id === workspaceId);
    workspace.invitations.push(invitation);
  },

  WORKSPACE_TEAM_REMOVE(state, { workspaceId, id }) {
    const workspace = state.workspaces.find((w) => w.id === workspaceId);
    workspace.teamMembers = workspace.teamMembers.filter((m) => m.id !== id);
  },

  WORKSPACE_TEAM_CHANGE_ROLE(state, { workspaceId, id, rights }) {
    const workspace = state.workspaces.find((w) => w.id === workspaceId);
    const member = workspace.teamMembers.find((m) => m.id === id);

    if (!member) return;

    const apiRights = {
      'W': 'write',
      'R': 'read',
    }[rights];

    if (member.permission) {
      merge(member.permission, {rights: apiRights})
    } else {
      merge(member, {permission: {rights: apiRights}})
    }
  },

  WORKSPACES_RESET(state) {
    state.workspaces = null;
    state.workspaceCurrentId = null;
  },

  [TYPES.PROJECTS_ADD](state, project) {
    const workspace = state.workspaces.find((w) => w.id === project.workspaceId);
    workspace.projects.unshift(project);
  },

  [TYPES.PROJECTS_REMOVE](state, projectId) {
    state.workspaces.forEach((w) => {
      if (w.projects.find((p) => p.id === projectId)) {
        w.projects = w.projects.filter(({ id }) => projectId !== id);
      }
    })
  },
  'WORKSPACE_LIMITS_SET'(state, { id, limits }) {
    state.limits[id] = limits;
  },
  [TYPES.PROJECT_METHOD_ADD](state, { method }) {
    const limit = state.limits[state.workspaceCurrentId];

    if (limit && limit.insightIds) {
      limit.insightIds.push(method.localId);
      limit.insightIds.push(method.id);
    }
  },
  [TYPES.UI_REGISTER_ID_JOIN](state, { join, id }) {
    const limit = state.limits[state.workspaceCurrentId];

    if (limit && limit.insightIds) {
      limit.insightIds = limit.insightIds.map((iid) => {
        return iid === id ? join : iid;
      })
    }
  },
}

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
