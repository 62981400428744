<template>
  <div class="onb-step">
    <div class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          That’s it! You’re ready to go. 
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';
import { navigateProjects } from 'helpers/router';
import { analyticsLogEvent } from 'utils/analytics';

export default {
  name: 'WorkspaceReady',
  props: {
    data: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.updateConfig();
  },
  methods: {
    getConfig() {
      return {
        progress: 5/5,
        avatar: false,
        buttons: [
          {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Begin',
            loading: this.loading,
          },
          {
            appearance: 'light',
            id: 'secondary',
            text: 'Back',
          },
        ],
      };
    },
    async buttonAction(id) {
      if (id === 'secondary') {
        this.$emit('redirect', {
          route: ROUTES.WORKSPACE_ONBOARDING_LOGO,
          data: this.data,
        })

        analyticsLogEvent('create_workspace_back_to_logo');

        return;
      }

      this.loading = true;
      this.updateConfig();
      this.$store.dispatch('userChange', { welcomeScreenShown: true });
      const workspace = await this.$store.dispatch('workspaceCreate', {
        name: this.data.organizationName,
        private: false,
      });

      if (this.data.image) {
        this.$store.dispatch('workspaceChangeImage', {
          id: workspace.id,
          image: this.data.image,
        });
      }

      this.$store.dispatch('workspaceSelect', { id: workspace.id });
      navigateProjects();

      analyticsLogEvent('create_workspace_created');
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
  },
};
</script>
