import * as TYPES from 'constants/mutations';
import { byPosition } from 'helpers/sort';
import BuilderService from 'services/method/builder.service';
import NotificationsService from 'services/notifications.service';
import ProjectMethodService from 'services/project-method.service';
import RespondersService from 'services/responders.service';
import SurveyService from 'services/survey.service';
import generateId from 'utils/id';
import { merge } from 'utils/reactive';
import { getVideoThumbnail } from 'helpers/video';

const state = () => ({
  methodSections: [], // All sections inside methods: [{ id, sections: [Section, ...] }, ...]
  selectedSections: [], // Currently selected sections IDs
  selectedSectionsFingerprint: '', // TODO: remove
  responders: [], // All respondents list | TODO: move to separate store
});

const getters = {
  allMethodSections: (state) => {
    return state.methodSections.map((s) => s.sections).flat();
  },
  // Get all method sections by methodId
  methodSections: (state) => (mid) => {
    return state.methodSections.find(({ id }) => id === mid).sections;
  },
  // Get all selected sections IDs
  selectedSections: (state) =>
    state.selectedSections,
  // Get selected sections fingerprint | TODO: remove
  selectedSectionsFingerprint: (state) =>
    state.selectedSectionsFingerprint,
  // Get section by ID
  sectionById: (state) => (id) =>
    id && state.methodSections.map((m) => m.sections).flat().find((s) => s.id === id || s.localId === id),
  // Get section index by ID
  sectionIndexById: (state, getters) => (id) => {
    const section = getters.sectionById(id);
    return getters.methodSections(section.designMethodId).findIndex((s) => s.id === id || s.localId === id);
  },
  // Get all sections inside method by type
  getSectionsWithType: (state, getters) => (mid, type) =>
    getters.methodSections(mid).filter((s) => s.type === type),
  // Get methodItem or itemField id by local id
  getIdByLocalId: (state, getters) => (id) => {
    const section = state.methodSections.map((m) => m.sections).flat().find((s) => s.localId === id);

    if (section) {
      return section.id;
    }

    const sectionItem = getters.sectionItemById(id);

    if (sectionItem) {
      return sectionItem.id;
    }
  },
  // Get section by index
  getSectionAt: (state) => (mid, index) => {
    return getters.methodSections.find(({id}) => mid === id).sections[index];
  },
  // Get methodItem parent ID by itemField ID
  sectionItemById: (state) => (id) => {
    const sections = state.methodSections.map((m) => m.sections).flat();
    for (let section of sections) {
      if (section.customItemFields) {
        let found = section.customItemFields.find((i) => i.id === id);
        if (found) {
          return found;
        }
      }
    }
  },
  // Get all respondents | TOTO: move to separate store
  responders: (state) => state.responders,
};

const actions = {
  async methodSectionUpdate(
    { getters, commit, dispatch },
    { id, data, local }
  ) {
    id = getters.idJoinRevPure(id) || id;

    const section = getters.sectionById(id);
    const designMethodId = section.designMethodId;
  
    if (local || (data.hasOwnProperty('focus') && data.focus === null)) {
      commit('METHOD_SECTION_UPDATE', { id, data });
      return;
    }

    if (section && section.customItemFields && section.customItemFields.length && data.hasOwnProperty('type') && data.type !== section.type) {
      data.customItemFields = [];
      section.customItemFields.forEach(({ id }) => {
        dispatch('send', { action: 'custom_method/destroy_field', data: { id } });
      });
    }

    if (!section || section.local) {
      commit('METHOD_SECTION_UPDATE', {
        id,
        data: { ...data, local: false, new: true, microtime: Date.now(), customItemFields: [] },
      });
      dispatch('send', { action: 'custom_method/create_item', data: { designMethodId, id, ...section, ...data } });
      commit('ADD_PARAGRAPHS_BETWEEN_ITEMS', { id: designMethodId });
      dispatch('builderUpdateSectionPositions', { id: designMethodId });

      if (data.customItemFields) {
        data.customItemFields.forEach((field) => {
          if (!field.responder) {
            dispatch('builderAddSectionItem', {
              id,
              data: field,
            });
          }
        });
      }

      window.setTimeout(() => {
        const section = getters.sectionById(id);
        if (section) {
          commit('METHOD_SECTION_UPDATE', {
            id: section.id,
            data: {
              new: false,
            },
          });
        }
      }, 1600);
    } else {
      let isNew = section.type === 'paragraph' && !section.content && data.type && data.type !== 'paragraph';

      commit('METHOD_SECTION_UPDATE', {
        id,
        data: { ...data, local: false, microtime: Date.now(), new: isNew },
      });

      if (isNew) {
        window.setTimeout(() => {
          const section = getters.sectionById(id);
          if (section) {
            commit('METHOD_SECTION_UPDATE', {
              id: section.id,
              data: {
                new: false,
              },
            });
          }
        }, 1600);
      }

      dispatch('send', { action: 'custom_method/update_item', data: { designMethodId, id, ...data } });
      commit('ADD_PARAGRAPHS_BETWEEN_ITEMS', { id: designMethodId });
    }
  },
  builderUpdateSectionOptions({ getters, commit, dispatch }, { id, options }) {
    const section = getters.sectionById(id);
    const { designMethodId } = section;

    let data = { ...section.options, ...options };

    commit('METHOD_SECTION_UPDATE', {
      id: section.id,
      data: {
        options: data,
      },
    });

    dispatch('send', { action: 'custom_method/update_item', data: { designMethodId, id, options: data } });
  },
  updateTagsIfNeeded({ dispatch, getters }, { tags, contentTags }) {
    const oldTags = getters.tags;
    let shouldUpdate = false;

    if (contentTags && contentTags.length) {
      contentTags.forEach((contentTag) => {
        contentTag.tagList.forEach((id) => {
          if (!oldTags.find((o) => o.id === id)) {
            shouldUpdate = true;
          }
        });
      });
    }

    if (tags && tags.length) {
      tags.forEach((tag) => {
        if (!oldTags.find((o) => o.id === tag.id)) {
          shouldUpdate = true;
        }
      });
    }

    if (shouldUpdate) {
      dispatch('requireTags');
    }
  },

  updateRespondersIfNeeded({ dispatch, getters }, { responder }) {
    const oldResponders = getters.responders;
    let shouldUpdate = false;

    if (responder) {
      shouldUpdate = !oldResponders.find(({id}) => id === responder.id);
    }

    if (shouldUpdate) {
      dispatch('fetchResponders');
    }
  },
  async builderMethodChange({ dispatch, getters }, { id, data }) {
    const { workspaceCurrentId, projectCurrentId } = getters;
    dispatch('send', { action: 'update_method', data: {
      id,
      ...data,
      workspaceCurrentId,
      projectCurrentId,
    } });
    dispatch('projectMethodChange', { id, data, local: true });
  },
  async builderMethodChangeImage({ dispatch, getters }, { id, image }) {
    const { workspaceCurrentId } = getters;

    const method = getters.projectMethodById(id);
    const projectId = (method && method.projectId) || getters.projectCurrentId;

    dispatch('projectMethodChange', {
      id,
      data: {
        image: {
          uploading: true,
          file: image,
        },
      },
      local: true, 
    });

    const response = await ProjectMethodService.methodImageChange(
      workspaceCurrentId,
      projectId,
      {
        id,
        image,
      }
    );

    dispatch('projectMethodChange', {
      id,
      data: {
        image: response.image,
      },
      local: true, 
    });
  },
  async builderMethodRemoveImage({ dispatch, getters }, { id }) {
    const { workspaceCurrentId } = getters;

    const method = getters.projectMethodById(id);
    const projectId = (method && method.projectId) || getters.projectCurrentId;

    dispatch('send', { action: 'update_method', data: {
      id,
      removeImage: true,
      workspaceCurrentId,
      projectCurrentId: projectId,
    } });
    dispatch('projectMethodChange', { id, data: { image: null }, local: true });
  },
  builderSelectSections({ commit }, { start, end }) {
    commit('METHOD_SECTIONS_SELECT', { start, end });
  },
  async builderMoveSection(
    { commit, getters, dispatch },
    { id, index, direction }
  ) {
    const section = getters.sectionById(id);
    const methodSections = getters.methodSections(section.designMethodId);

    if (direction) {
      direction = direction === 'up' ? 1 : -1;
      index = getters.sectionIndexById(id) + direction;
      let actual = methodSections[index];

      while (actual && actual.local) {
        index += direction;
        actual = methodSections[index];
      }

      index = Math.max(Math.min(methodSections.length - 1, index), 0);
    }

    await commit('METHOD_SECTION_MOVE', { id, index });
    await commit('ADD_PARAGRAPHS_BETWEEN_ITEMS', { id: section.designMethodId });
    await dispatch('builderUpdateSectionPositions', { id: section.designMethodId });
  },
  async builderMoveSections(
    { commit, getters, dispatch },
    { ids, index }
  ) {
    const section = getters.sectionById(ids[0]);
    await commit('METHOD_SECTIONS_MOVE', { ids, index });
    await commit('ADD_PARAGRAPHS_BETWEEN_ITEMS', { id: section.designMethodId });
    await dispatch('builderUpdateSectionPositions', { id: section.designMethodId });
  },
  async builderUpdateSectionPositions({ commit, getters, dispatch }, { id }) {
    const methodSections = getters.methodSections(id);

    const data = {
      newOrder: byPosition(
        methodSections.map((s) => ({
          id: s.id,
          position: s.position,
        }))
      ),
    };

    dispatch('send', { action: 'custom_method/reorder_item', data });
  },
  builderAddSectionItem({ commit, getters, dispatch }, { id, data, position = null }) {
    const section = getters.sectionById(id);

    if (!section) return;

    if (!data.id) {
      data.id = generateId();
    }

    const itemId = data.id;
    const payload = {
      ...data,
      customMethodItemId: section.id,
      id: itemId,
      localId: itemId,
      microtime: Date.now(),
      updatedAt: new Date().toString(),
      createdAt: new Date().toString(),
    };

    commit('METHOD_ADD_SECTION_ITEM', { data: payload, position });
    dispatch('send', { action: 'custom_method/create_field', data: payload });

    if (position !== null) {
      const items = section.customItemFields;

      window.setTimeout(() => {
        dispatch('send', { action: 'custom_method/reorder_fields', data: {
          newOrder: items.map((s) => ({
            parentId: section.id,
            id: s.id,
            position: s.position,
          })),
        } });
      }, 1000);
      
    }
  },
  async builderAddResponderChoice({ commit, dispatch, getters }, { id, responder, responseId }) {
    const section = getters.sectionById(id);

    if (!section) return;

    const responderField = section.customItemFields.find((f) => f.responder && f.responder.id === responder.id);

    if (responderField) {
      let value = JSON.parse(responderField.value);

      // Already answered
      if (value.indexOf(responseId) > -1) return;

      dispatch('builderUpdateSectionItem', {
        id: responderField.id,
        data: {
          responder,
          value: JSON.stringify([...value, responseId]),
        },
      })
    } else {
      dispatch('builderAddSectionItem', {
        id,
        data: {
          responder,
          value: JSON.stringify([responseId]),
        },
      })
    }
  },
  async builderRemoveResponderChoice({ commit, dispatch, getters }, { id, responder, responseId }) {
    const section = getters.sectionById(id);

    if (!section) return;

    const responderField = section.customItemFields.find((f) => f.responder && f.responder.id === responder.id);

    if (responderField) {
      let value = JSON.parse(responderField.value).filter((id) => id !== responseId);

      if (value.length) {
        value = JSON.stringify(value);

        dispatch('builderUpdateSectionItem', {
          id: responderField.id,
          data: {
            responder,
            value,
          },
        })
      } else {
        dispatch('builderRemoveSectionItem', {
          sectionId: id,
          id: responderField.id,
        })
      }

    }
  },
  async builderAddResponderToAnswer({ commit, dispatch, getters }, { id, responder, name }) {
    const section = getters.sectionById(id);

    if (!section) return;

    dispatch('builderAddSectionItem', {
      id,
      data: {
        responder,
        name,
      },
    })
  },
  async builderRemoveResponderFromAnswer({ commit, dispatch, getters }, { id, responder, name }) {
    const section = getters.sectionById(id);

    if (!section) return;

    const responderField = section.customItemFields.find((f) =>
      (f.responder && f.responder.id === responder.id) &&
      (f.name === name)
    );

    const responderFieldsList = section.customItemFields.filter((f) =>
      (f.name === name)
    );

    if (responderField) {
      if (responderFieldsList.length > 1 || section.type === 'custom_1') {
        dispatch('builderRemoveSectionItem', {
          sectionId: id,
          id: responderField.id,
        })
      } else {
        dispatch('builderUpdateSectionItem', {
          sectionId: id,
          id: responderField.id,
          data: {
            responder: null,
          },
        })
      }
    }
  },
  builderAddResponder({ dispatch, getters }, { item, responder }) {
    const section = getters.sectionById(item.customMethodItemId);
    if (section.type === 'custom_5') {
      dispatch('builderAddResponderToAnswer', { id: item.customMethodItemId, responder, name: item.name });
    } else {
      dispatch('builderAddResponderChoice', { id: item.customMethodItemId, responder, responseId: item.id });
    }
  },
  builderRemoveResponder({ dispatch }, { item, responder }) {
    const section = getters.sectionById(item.customMethodItemId);
    if (section.type === 'custom_5') {
      dispatch('builderRemoveResponderFromAnswer', { id: item.customMethodItemId, responder, name: item.name });
    } else {
      dispatch('builderRemoveResponderChoice', { id: item.customMethodItemId, responder, responseId: item.id });
    }
  },
  builderMoveSectionItem(
    { commit, getters, dispatch },
    { sectionId, id, newIndex, newSectionId, fieldsList = 'customItemFields' }
  ) {
    const section = getters.sectionById(sectionId);
    const newSection = getters.sectionById(newSectionId);
    const action =
      fieldsList === 'galleryImages'
        ? 'custom_method/reorder_gallery_items'
        : 'custom_method/reorder_fields';

    if (!section || !newSection) return;

    if (newSectionId === sectionId) {
      let items = byPosition(section[fieldsList] || []);
      const item = items.find((i) => i.id === id);

      items = items.filter((i) => i.id !== id);
      items.splice(newIndex, 0, item);

      items.forEach((o, i) => {
        o.position = i;
      });

      commit('METHOD_SECTION_UPDATE', {
        id: sectionId,
        data: { [fieldsList]: items },
      });

      if (items.length) {
        dispatch('send', { action, data: {
          newOrder: items.map((s) => ({
            id: s.id,
            position: s.position,
          })),
        } });
      }
    } else {
      let items = byPosition(newSection[fieldsList] || []);
      let oldItems = byPosition(section[fieldsList] || []);
      const item = oldItems.find((i) => i.id === id);

      oldItems = oldItems.filter((i) => i.id !== id);
      items.splice(newIndex, 0, item);

      oldItems.forEach((o, i) => {
        o.position = i;
        o.customMethodItemId = section.id;
      });

      items.forEach((o, i) => {
        o.position = i;
        o.customMethodItemId = newSection.id;
      });

      commit('METHOD_SECTION_UPDATE', {
        id: sectionId,
        data: { [fieldsList]: oldItems },
      });
      commit('METHOD_SECTION_UPDATE', {
        id: newSectionId,
        data: { [fieldsList]: items },
      });

      if (oldItems.length) {
        dispatch('send', { action, data: {
          newOrder: oldItems.map((s) => ({
            parentId: sectionId,
            id: s.id,
            position: s.position,
          })),
        } });
      }

      if (items.length) {
        dispatch('send', { action, data: {
          newOrder: items.map((s) => ({
            parentId: newSectionId,
            id: s.id,
            position: s.position,
          })),
        } });
      }
    }
  },
  builderUpdateSectionItem({ commit, getters, dispatch }, { id, data }) {
    const item = getters.sectionItemById(id);
    data.customMethodItemId = item.customMethodItemId;

    const payload = {
      id,
      ...data,
    };

    data.microtime = Date.now();
    commit('METHOD_SECTION_ITEM_UPDATE', { id: item.id, data });

    if (data.page && item.page) {
      const {
        workspaceCurrentId,
        projectCurrentId,
      } = getters;

      dispatch('send', { action: 'update_page', data: {
        id: item.page.id,
        workspaceCurrentId,
        projectCurrentId,
        ...data.page,
      } });
    }

    dispatch('send', { action: 'custom_method/update_field', data: payload });
  },
  async builderUpdateSectionItemImage({ commit, getters, dispatch }, { id, image }) {
    const { workspaceCurrentId, projectCurrentId } = getters;
    
    const item = getters.sectionItemById(id);
    const page = item.page;

    if (!page) {
      return;
    }

    commit('METHOD_SECTION_ITEM_UPDATE', { id: item.id, data: {
      customMethodItemId: item.customMethodItemId,
      page: {
        image: {
          uploading: true,
          file: image,
        },
      },
    } });

    const response = await ProjectMethodService.methodImageChange(
      workspaceCurrentId,
      projectCurrentId,
      {
        id: page.id,
        image,
      }
    );

    commit('METHOD_SECTION_ITEM_UPDATE', { id: item.id, data: {
      customMethodItemId: item.customMethodItemId,
      page: {
        image: response.image,
      },
    } });
  },
  async builderRemoveSectionItemImage({ commit, getters, dispatch }, { id }) {
    const { workspaceCurrentId, projectCurrentId } = getters;
    
    const item = getters.sectionItemById(id);
    const page = item.page;

    if (!page) {
      return;
    }

    commit('METHOD_SECTION_ITEM_UPDATE', { id: item.id, data: {
      customMethodItemId: item.customMethodItemId,
      page: {
        image: null,
      },
    } });

    dispatch('send', { action: 'update_method', data: {
      id: page.id,
      removeImage: true,
      workspaceCurrentId,
      projectCurrentId,
    } });

    dispatch('projectMethodChange', { id: page.id, data: { image: null }, local: true });
  },
  builderUpdateSectionRespondersItems({ commit, getters, dispatch }, { id, data }) {
    const item = getters.sectionItemById(id);
    const section = getters.sectionById(item.customMethodItemId);

    data.customMethodItemId = item.customMethodItemId;

    let fields = section.customItemFields.filter((f) => f.name === item.name);

    for (let field of fields) {
      dispatch('builderUpdateSectionItem', { id: field.id, data: {
        ...data,
        responder: field.responder,
      } });
    }
  },
  builderRemoveSectionRespondersItems({ commit, getters, dispatch }, { id }) {
    const item = getters.sectionItemById(id);
    const section = getters.sectionById(item.customMethodItemId);
    let sectionId = item.customMethodItemId;
    let fields = section.customItemFields.filter((f) => f.name === item.name);

    for (let field of fields) {
      dispatch('builderRemoveSectionItem', { id: field.id, sectionId });
    }
  },
  builderRemoveSectionItem({ commit, getters, dispatch }, { sectionId, id }) {
    const section = getters.sectionById(sectionId);

    if (!section || !section.customItemFields) return;

    commit('METHOD_SECTION_ITEM_REMOVE', { id });

    const payload = {
      id,
    };

    dispatch('send', { action: 'custom_method/destroy_field', data: payload });
  },
  builderCreateSection(
    { getters, commit, dispatch },
    { methodId, type, color = '#abc0c8', index = null, data = {}, ignoreLocalInIndex = false, new:isNew = true }
  ) {
    if (!methodId) {
      throw new Error('methodId is undefined');
    }

    const designMethodId = methodId;

    if (!data.id) {
      data.id = generateId();
    }
    
    const id = data.id;
    const section = {
      type,
      color,
      name: type,
      galleryImages: [],
      options: {},
      ...data,
      customItemFields: [],
      id,
      localId: id,
      designMethodId,
      microtime: Date.now(),
      new: isNew,
    };

    commit('METHOD_ADD_SECTION', { section, index, ignoreLocalInIndex });
    commit('ADD_PARAGRAPHS_BETWEEN_ITEMS', { id: designMethodId });

    dispatch('send', { action: 'custom_method/create_item', data: section });
    dispatch('builderUpdateSectionPositions', { id: designMethodId });

    if (data.customItemFields) {
      data.customItemFields.forEach((field) => {
        if (!field.responder) {
          dispatch('builderAddSectionItem', {
            id,
            data: field,
          });
        }
      });
    }

    window.setTimeout(() => {
      commit('METHOD_SECTION_UPDATE', {
        id,
        data: {
          new: false,
        },
      });
    }, 1600);

    return section;
  },
  builderRemoveSection({ commit, dispatch, getters }, { id }) {
    const section = getters.sectionById(id);
    commit('METHOD_REMOVE_SECTION', { id });
    commit('ADD_PARAGRAPHS_BETWEEN_ITEMS', { id: section.designMethodId });
    if (!section.local) {
      dispatch('send', { action: 'custom_method/delete_item', data: { designMethodId: section.designMethodId, id } });
    }
  },
  async builderRemoveSelectedSections({ commit, dispatch, getters }) {
    const { selectedSections } = getters;
    const section = getters.sectionById(selectedSections[0]);
    commit('METHOD_REMOVE_MULTIPLE_SECTIONS', { sections: selectedSections });
    commit('METHOD_SECTIONS_SELECT', { start: null, end: null });
    dispatch('send', { action: 'custom_method/delete_item_many', data: { ids: selectedSections } });
    commit('ADD_PARAGRAPHS_BETWEEN_ITEMS', { id: section.designMethodId });
  },
  async builderAddGallerySectionImage(
    { commit, dispatch, getters },
    { id, image, imageId = generateId() }
  ) {
    const section = getters.sectionById(id);
    const {
      workspaceCurrentId,
      projectCurrentId,
    } = getters;
    const oldImages = section.galleryImages || [];
    const exists = oldImages.find((i) => i.id === imageId);
    let newImages = [];
    let position = oldImages.length;

    if (exists) {
      await BuilderService.removeGalleryImage(
        workspaceCurrentId,
        projectCurrentId,
        section.designMethodId,
        id,
        imageId
      );
      newImages = oldImages.map((img) => {
        if (img.id === imageId) {
          position = img.position;
          return {
            ...img,
            uploading: true,
            url: null,
            file: image,
          };
        } else {
          return img;
        }
      });
    } else {
      newImages = [
        ...oldImages,
        {
          id: imageId,
          uploading: true,
          file: image,
          position,
        },
      ];
    }

    commit('METHOD_SECTION_UPDATE', {
      id,
      data: {
        galleryImages: newImages,
      },
    });

    const response = await BuilderService.addGalleryImage(
      workspaceCurrentId,
      projectCurrentId,
      section.designMethodId,
      id,
      { image, imageId, position }
    );

    newImages.forEach(async (image) => {
      if (image.id === imageId) {
        await dispatch('registerIdJoin', { join: response.id, id: imageId });
        Object.assign(image, {
          uploading: false,
          file: null,
          ...response,
        });
      }
    });

    return response;
  },
  async builderRemoveGallerySectionImage(
    { commit, dispatch, getters },
    { id, imageId }
  ) {
    const section = getters.sectionById(id);

    if (!section || !section.galleryImages) return;

    const {
      workspaceCurrentId,
      projectCurrentId,
    } = getters;

    commit('METHOD_SECTION_UPDATE', {
      id,
      data: {
        galleryImages: section.galleryImages.filter((i) => i.id !== imageId),
      },
    });

    const response = await BuilderService.removeGalleryImage(
      workspaceCurrentId,
      projectCurrentId,
      section.designMethodId,
      id,
      imageId
    );

    return response;
  },
  async builderAddSectionImage({ commit, dispatch, getters }, { id, image }) {
    const section = getters.sectionById(id);
    const {
      workspaceCurrentId,
      projectCurrentId,
    } = getters;

    commit('METHOD_SECTION_UPDATE', {
      id,
      data: {
        image: {
          uploading: true,
          file: image,
        },
      },
    });

    dispatch('send', { action: 'add_image', data: {
      workspaceCurrentId,
      projectCurrentId,
      methodId: section.designMethodId,
      id,
      image: { image },
    } });
  },
  async builderAddSectionVideo({ commit, dispatch, getters }, { id, file }) {
    const section = getters.sectionById(id);

    commit('METHOD_SECTION_UPDATE', {
      id,
      data: {
        video: {
          uploading: true,
          progress: 0,
          file,
        },
      },
    });

    const image = await getVideoThumbnail(file);

    dispatch('builderAddSectionImage', { id, image });

    const uploadAction = await dispatch('startUpload', {
      sectionId: section.id,
      methodId: section.designMethodId,
      progress: 0,
    })

    const uploadData = await dispatch('activeStorageUploadFile', { file, progress(progress) {
      commit('METHOD_SECTION_UPDATE', {
        id,
        data: {
          video: {
            uploading: true,
            progress: progress,
            file,
          },
        },
      });

      dispatch('updateUpload', {
        id: uploadAction.id,
        progress,
      })
    } });

    dispatch('methodSectionUpdate', { id, data: {
      video: uploadData.signedId,
    } });

    dispatch('endUpload', uploadAction.id);
  },
  async builderRemoveSectionImage(
    { commit, dispatch, getters },
    { id, imageId }
  ) {
    const section = getters.sectionById(id);

    if (!section || !section.image) return;

    const {
      workspaceCurrentId,
      projectCurrentId,
    } = getters;

    commit('METHOD_SECTION_UPDATE', {
      id,
      data: {
        image: null,
      },
    });

    const response = await BuilderService.removeImage(
      workspaceCurrentId,
      projectCurrentId,
      section.designMethodId,
      id
    );
  },
  async cardModalUpdate(
    { commit, dispatch, getters },
    { id, projectId, transport, data }
  ) {
    if (transport === 'none') return;
    if (!projectId) {
      projectId = getters.projectCurrentId;
    }
    dispatch('builderUpdateSectionItem', { data, id });
  },
  async projectMethodFetch({ dispatch }) {
    dispatch('fetchResponders');
  },
  async fetchResponders({ commit, dispatch, getters }) {
    if (getters.isShortcutRoute) {
      return;
    }
    
    await dispatch('requireWorkspaces');
    let { workspaceCurrentId, projectCurrentId, projectCurrentMethodId } = getters;
    if (!projectCurrentMethodId) return;

    let responders = await RespondersService.fetch(workspaceCurrentId, projectCurrentId, projectCurrentMethodId);

    commit('SET_RESPONDERS', responders);
  },
  async createResponder({ commit, getters }, { name, email, id }) {
    let { projectCurrentId, projectCurrentMethodId } = getters;
    let userId = await SurveyService.createUser({
      name,
      email,
      projectId: projectCurrentId,
      customMethodId: projectCurrentMethodId,
    });

    commit('ADD_RESPONDER', { name, email, id: userId });
    return { name, email, id: userId };
  },

  async transcribeVideo({ getters, commit, dispatch }, { id }) {
    const { workspaceCurrentId } = getters;
    const section = getters.sectionById(id);
    const method = getters.projectMethodById(section.designMethodId);
    const project = getters.projectById(method.projectId);
    const localId = generateId();

    commit('METHOD_UPDATE_REMOTE_TASK', { methodId: method.id, taskId: localId, data: {
      id: localId,
      status: 'processing',
      progress: 0,
      taskType: 'transcribe',
    } })

    const data = await ProjectMethodService.transcribe(workspaceCurrentId, project.id, method.id, { customItemId: section.id });

    commit('METHOD_UPDATE_REMOTE_TASK', { methodId: method.id, taskId: localId, data });

    dispatch('methodSectionUpdate', { id: section.id, data: {
      options: {
        transcriptionId: data.id,
      },
    } });

    return data;
  },
};

const reposition = (sections) => {
  let local = 0;
  sections.forEach((s, i) => {
    s.position = i - local;
    if (s.local) local++;
  });
};

const mutations = {
  [TYPES.PROJECT_METHOD_SET](state, { method }) {
    if (method.customMethodItems) {
      let methodSections = state.methodSections.find(({id}) => method.id === id);

      if (methodSections) {
        methodSections.sections = byPosition(method.customMethodItems);
      } else {
        state.methodSections.push({
          id: method.id,
          sections: byPosition(method.customMethodItems),
        });
      }
      
      mutations.ADD_PARAGRAPHS_BETWEEN_ITEMS(state, { id: method.id });
    }
  },
  METHOD_SECTION_UPDATE(state, { id, data }) {
    const section = state.methodSections.map((m) => m.sections).flat().find((s) => s.id === id || s.localId === id);

    if (!section) {
      // eslint-disable-next-line
      console.warn(`Can't update undefined section: ${ id }`);
      return;
    };

    if (data.id && data.id !== section.id) {
      merge(section, { id: data.id });
    }

    // Don't update by past data (only I)
    if (data.hasOwnProperty('id') && section.id !== data.id) {
      merge(section, { id: data.id});
      return;
    }

    merge(section, data);
  },
  METHOD_ADD_SECTION(state, { section, index = null, ignoreLocalInIndex = false }) {
    const sections = state.methodSections.find((m) => m.id === section.designMethodId).sections;
    let empty = 0;

    // Real API-side position is different than section index because of
    // local blank/placeholder paragraph sections
    if (index === null) {
      index = section.position;

      for (let s of sections) {
        if (s.id === section.id || s.position === section.position) {
          break;
        }
        if (s.local) {
          index++;
        }
      }
    } else {
      let actual = 0;

      for (const s of sections) {
        if (s.local) {
          empty++;
        } else {
          actual++;
        }

        if (actual >= index) {
          break;
        }
      }
    }

    if (ignoreLocalInIndex) {
      empty = 0;
    }
    
    sections.splice(index*1 + empty, 0, section);
    reposition(sections);
  },
  METHOD_REMOVE_SECTION(state, { id }) {
    state.methodSections.forEach((method) => {
      method.sections = method.sections.filter((s) => s.id !== id && s.localId !== id);
      reposition(method.sections);
    });
  },
  METHOD_REMOVE_MULTIPLE_SECTIONS(state, { sections }) {
    state.methodSections.forEach((method) => {
      method.sections = method.sections.filter((s) => !sections.includes(s.id) && !sections.includes(s.localId));
      reposition(method.sections);
    });
  },
  METHOD_SECTION_MOVE(state, { id, index }) {
    let section, method;

    state.methodSections.forEach((m) => {
      let find = m.sections.find((s) => s.id === id || s.localId === id);
      if (find) {
        section = find;
        method = m;
      }
    });

    if (!section) return;

    method.sections = method.sections.filter((s) => s.id !== id && s.localId !== id);
    method.sections.splice(index, 0, section);

    reposition(method.sections);
  },
  METHOD_SECTIONS_MOVE(state, { methodId, ids, index }) {
    let method = state.methodSections.find((m) => m.sections.find((s) => s.id === ids[0] || s.localId === ids[0]));
    const sectionsToMove = method.sections.filter((s) => ids.includes(s.id) || ids.includes(s.localId));
    const sections = method.sections.filter((s) => !ids.includes(s.id) && !ids.includes(s.localId));

    let empty = 0;
    let actual = 0;

    for (const s of sections) {
      if (s.local) {
        empty++;
      } else {
        actual++;
      }

      if (actual >= index) {
        break;
      }
    }

    sections.splice(index + empty, 0, ...sectionsToMove);

    reposition(sections);

    method.sections = sections;
  },
  METHOD_SECTIONS_SELECT(state, { start, end }) {
    const sections = byPosition(state.sections);
    const selected = [];

    if (start === end || !start || !end) {
      if (state.selectedSections.length) {
        state.selectedSections = [];
        state.selectedSectionsFingerprint = '';
      }
      return;
    }

    let selecting = false;

    for (let s of sections) {
      if (!selecting && (s.id === start || s.localId === start)) {
        selecting = 'down';
      }

      if (!selecting && (s.id === end || s.localId === end)) {
        selecting = 'up';
      }

      if (selecting) {
        selected.push(s.id);
      }

      if (
        (selecting === 'up' && (s.id === start || s.localId === start)) ||
        (selecting === 'down' && (s.id === end || s.localId === end))
      ) {
        selecting = false;
      }
    }

    state.selectedSections = selected;
    state.selectedSectionsFingerprint = selected.join(',');
  },
  METHOD_SECTIONS_SELECT_IDS(state, { ids }) {
    state.selectedSections = ids;
    state.selectedSectionsFingerprint = ids.join(',');
  },
  METHOD_SECTIONS_SELECT_ALL(state) {
    const sections = byPosition(state.sections);
    const selected = sections.map((s) => s.id);

    state.selectedSections = selected;
    state.selectedSectionsFingerprint = selected.join(',');
  },
  METHOD_SECTIONS_REORDER(state, { sections }) {
    const methodSections = state.methodSections.map((m) => m.sections).flat();
    let methodId;

    sections.forEach(([ id, position ]) => {
      let section = methodSections.find((s) => s.id === id);
      if (section) {
        methodId = section.designMethodId;
        merge(section, { position });
      }
    });

    const method = state.methodSections.find(({id}) => id === methodId);

    if (!method) return;

    method.sections = byPosition(method.sections);
  },
  METHOD_ADD_SECTION_ITEM(state, { data, position = null }) {
    const section = state.methodSections.map((m) => m.sections).flat().find((s) => s.id === data.customMethodItemId);

    if (!section) return;

    if (!section.customItemFields) {
      section.customItemFields = [];
    }

    let { customItemFields } = section;

    if (position === null) {
      customItemFields.push(data);
      customItemFields = byPosition(section.customItemFields);
    } else {
      customItemFields = byPosition(section.customItemFields);
      customItemFields.splice(position, 0, data);
    }
    
    reposition(customItemFields);

    merge(section, { customItemFields });
  },
  METHOD_SECTION_ITEM_UPDATE(state, { id, data }) {
    const section = state.methodSections.map((m) => m.sections).flat().find((s) => s.id === data.customMethodItemId);
    const item = section && section.customItemFields.find((i) => i.id === id);

    if (!item) return;

    if (data.microtime && item.microtime >= data.microtime) {
      if (data.hasOwnProperty('id') && item.id !== data.id) {
        merge(item, { id: data.id });
      }
      if (data.hasOwnProperty('page') && !item.page) {
        merge(item, { page: data.page });
      }
      return;
    }

    if (data.hasOwnProperty('page') && item.page) {
      merge(item.page, data.page);
      data = { ...data };
      delete data.page;
    }

    merge(item, data);
  },
  METHOD_SECTION_ITEMS_REORDER(state, { fields }) {
    const fieldIds = fields.map((f) => f[0]);
    const method = state.methodSections.find((m) => { 
      return m.sections.find((s) => {
        return s.customItemFields && s.customItemFields.find(({ id }) => {
          return fieldIds.includes(id);
        })
      })
    });

    if (!method) return;

    const sections = method.sections;

    sections.forEach((section) => {
      fields.forEach(([fieldId, position, parentId]) => {
        const sectionField =
          section.customItemFields &&
          section.customItemFields.find((f) => f.id === fieldId);
        if (sectionField) {
          merge(sectionField, {
            position,
          });
          if (parentId && sectionField.customMethodItemId !== parentId) {
            section.customItemFields = section.customItemFields.filter(
              (f) => f.id !== fieldId
            );
            let newSection = sections.find((s) => s.id === parentId);
            if (newSection) {
              newSection.customItemFields.push(sectionField);
            }
          }
        }
      });
    });
  },
  METHOD_SECTION_ITEM_REMOVE(state, { id }) {
    state.methodSections.map((m) => m.sections).flat().forEach((section) => {
      if (
        section.customItemFields &&
        section.customItemFields.find((f) => f.id === id || f.localId === id)
      ) {
        section.customItemFields = section.customItemFields.filter(
          (f) => f.id !== id && f.localId !== id
        );
      }
    });
  },
  METHOD_SECTION_GALLERY_ITEMS_REORDER(state, { items }) {
    const fieldIds = items.map((f) => f[0]);
    const method = state.methodSections.find((m) => { 
      return m.sections.find((s) => {
        return s.galleryImages && s.galleryImages.find(({ id }) => {
          return fieldIds.includes(id);
        })
      })
    });

    if (!method) return;

    const sections = method.sections;

    sections.forEach((section) => {
      items.forEach(([fieldId, position, parentId]) => {
        const sectionField =
          section.galleryImages &&
          section.galleryImages.find((f) => f.id === fieldId);
        if (sectionField) {
          merge(sectionField, {
            position,
          });
          if (parentId && sectionField.customMethodItemId !== parentId) {
            section.galleryImages = section.galleryImages.filter(
              (f) => f.id !== fieldId
            );
            let newSection = sections.find((s) => s.id === parentId);
            if (newSection) {
              if (newSection.galleryImages) {
                newSection.galleryImages.push(sectionField);
              } else {
                merge(newSection, { galleryImages: [sectionField] });
              }
            }
          }
        }
      });
    });
  },
  UPDATE_HIGHLIGHTS(state, { tagsList, parentId, content, contentTags }) {
    const section = state.methodSections.map((m) => m.sections).flat().find((s) => s.id === parentId);

    if (section) {
      merge(section, {
        content,
        contentTags,
      })
    }
  },
  ADD_PARAGRAPHS_BETWEEN_ITEMS(state, { id }) {
    const sections = (state.methodSections.find((m) => m.id === id) || {}).sections;

    if (!sections) return;

    const designMethodId = id;
    let prev = {};

    if (!sections.length) {
      const id = generateId();
      const section = {
        type: 'paragraph',
        local: true,
        id,
        localId: id,
        position: 0,
        options: {},
        designMethodId,
        customItemFields: [],
        microtime: Date.now(),
      };
      sections.push(section);
      return;
    }

    for (let i = sections.length - 1; i >= 0; i--) {
      let section = sections[i];
      
      if (prev.type !== 'paragraph' && section.type !== 'paragraph') {
        const id = generateId();
        const data = {
          type: 'paragraph',
          local: true,
          id,
          localId: id,
          options: {},
          designMethodId,
          customItemFields: [],
          microtime: Date.now(),
        };

        sections.splice(i + 1, 0, data);
      } else if (prev.type === 'paragraph' && section.local) {
        sections.splice(i, 1);
      } else if (prev.local && section.type === 'paragraph') {
        sections.splice(i + 1, 1);
      }

      if (i === 0 && section.type !== 'paragraph') {
        const id = generateId();
        const data = {
          type: 'paragraph',
          local: true,
          id,
          localId: id,
          designMethodId,
          customItemFields: [],
          microtime: Date.now(),
        };

        sections.splice(0, 0, data);
      }

      prev = section;
    }

    reposition(sections);
  },
  SET_RESPONDERS(state, responders) {
    state.responders = responders;
  },
  ADD_RESPONDER(state, data) {
    state.responders.push(data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
