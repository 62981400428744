<template>
  <Forbidden v-if="isProjectForbidden" />
  <div
    v-else
    class="sidebar-page"
  >
    <div class="container">
      <div
        class="archive-container method-page"
        data-aos="fade-in"
      >
        <TitleInput
          appearance="project-title"
          value="Archive"
          :can-edit="false"
        />
        <BaseSpinner
          v-if="!fetched"
          key="loader"
        />
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="archive.length"
            key="list"
          >
            <div class="tag-filters">
              <div class="filter">
                <span class="filter__name">Time Range:</span>
                <span>
                  <DatePicker
                    :value="timeRange"
                    @change="timeRange = $event"
                  >
                    <span class="dropdown-menu-btn">{{ timeRange ? timeRange.text : 'All' }} <Icon name="arrow-full-down" /></span>
                  </DatePicker>
                </span>
              </div>
              <div class="filter">
                <span class="filter__name">Type:</span>
                <BaseTooltip
                  class="filter__value"
                  behaviour="click"
                  appearance="light-box"
                >
                  <span class="dropdown-menu-btn">{{ filterType ? filterTypeName : 'All' }}<Icon name="arrow-full-down" /></span>
                  <template v-slot:content>
                    <div class="selectable-items-tooltip">
                      <span class="selectable-items-tooltip__title">Type</span>
                      <ul class="selectable-items">
                        <li
                          v-for="type of filterTypesList"
                          :key="type.name"
                        >
                          <span
                            class="selectable-item"
                            :class="{
                              'selectable-item--active': filterType === type.value,
                            }"
                            @click="selectFilterType(type.value)"
                          >
                            {{ type.name }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </BaseTooltip>
              </div>
            </div>
            <transition
              mode="out-in"
              name="fade"
            >
              <ul
                v-if="archiveList.length"
                key="list"
                class="archive-list"
              >
                <transition-group
                  type="transition"
                  name="full-list"
                >
                  <template v-for="item of archiveList" :key="item.id || item.monthText">
                    <li
                      v-if="item.monthText"
                      class="archive-period-heading"
                    >
                      {{ item.monthText }}
                    </li>
                    <li
                      class="archive-list__item"
                      v-else
                    >
                      <SideDropdownMenu
                        :enabled="canEdit"
                        :menu="[
                          {
                            text: 'Restore',
                            action: () => onRestore(item),
                          },
                          {
                            text: 'Delete permanently',
                            appearance: 'warn',
                            confirm: true,
                            action: () => onDelete(item),
                          },
                        ]"
                      >
                        <div>
                          <component
                            :is="getArchiveItemComponent(item)"
                            :data="item"
                          />
                        </div>
                      </SideDropdownMenu>
                    </li>
                  </template>
                </transition-group>
              </ul>
              <div
                v-else
                key="empty"
                class="tag-empty"
              >
                <Icon
                  class="tag-empty__icon"
                  size="auto"
                  name="sticker-note"
                />
                <div class="tag-empty__body">
                  Nothing to show, change filtering criteria.
                </div>
              </div>
            </transition>
          </div>
          <div
            v-else-if="!isWorking"
            key="empty"
            class="tag-empty"
          >
            <Icon
              class="tag-empty__icon"
              size="auto"
              name="sticker-note"
            />
            <div class="tag-empty__body">
              Nothing has been recently archived.
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Spinner from 'components/common/base-spinner';
import DatePicker from 'components/common/pickers/date';
import { PROJECT_TAG } from 'constants/routes';
import { TAG_COLORS } from 'constants/tag-colors';
import { navigateProjects, navigateToTag } from 'helpers/router';
import { sortByItemsCount } from 'helpers/tags';
import { timeMonthText } from 'helpers/time';
import sample from 'lodash/sample';
import { mapGetters } from 'vuex';

import Forbidden from 'components/forbidden';
import CardItem from './items/card';
import DefaultItem from './items/default';
import MethodItem from './items/method';
import PhaseItem from './items/phase';
import TagItem from './items/tag';

export default {
  name: 'ProjectArchive',
  components: {
    Spinner,
    DatePicker,
    Forbidden,
  },
  data: () => ({
    newInput: '',
    isDeleteModalVisible: false,
    deletingItem: null,
    timeRange: '',
    isWorking: true,
    fetched: false,
    filterType: null,
  }),
  computed: {
    ...mapGetters([
      'archive',
      'projectCurrent',
      'isArchiveFetched',
      'isProjectForbidden',
    ]),
    filterTypesList() {
      return [
        {
          name: 'All',
          value: null,
        },
        {
          name: 'Page',
          value: 'DesignMethod',
        },
        {
          name: 'Card',
          value: 'Card',
        },
        {
          name: 'Section',
          value: 'Section',
        },
        {
          name: 'Question',
          value: 'Question',
        },
        {
          name: 'Competitor',
          value: 'Competitor',
        },
        {
          name: 'Persona',
          value: 'Persona',
        },
        {
          name: 'Tag',
          value: 'Tag',
        },
      ];
    },
    filterTypeName() {
      const type = this.filterTypesList.find((t) => t.value === this.filterType)

      if (type) {
        return type.name;
      }

      return null;
    },
    sortedTags() {
      return sortByItemsCount(this.tags);
    },
    hasTags() {
      return !!this.tags.length;
    },
    newTagName() {
      if (!this.hasTags) return this.newInput;
      return this.$store.getters.newTagName;
    },
    archiveList() {
      let { archive, filterType } = this;
      let currentMonth = '';

      archive = archive.filter((item) => {
        if (filterType && item.archiveType !== filterType) {
          return false;
        }

        return true;
      });

      archive = archive.map((obj, i) => {
        const month = timeMonthText(obj.archivedAt);

        if (month !== currentMonth) {
          obj = [
            {
              monthText: month,
            },
            obj,
          ]
          currentMonth = month;
        }

        return obj;
      });

      archive = archive.flat();

      if (this.timeRange) {
        const { start, end } = this.timeRange;

        archive = archive.filter((item) => {
          return (
            (!start || item.archivedAt >= moment(start).format()) &&
            (!end || item.archivedAt <= moment(end).format())
          );
        });
      }

      return archive;
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await Promise.all([
        this.$store.dispatch('projectFetch'),
        this.$store.dispatch('archiveFetch'),
      ]);

      if (!this.projectCurrent.accessible) {
      }

      this.isWorking = false;
      this.fetched = true;
    },
    getArchiveItemComponent(item) {
      switch(item.archiveType) {
        case 'Card':
          return CardItem;
        case 'DesignMethod':
          return MethodItem;
        case 'Tag':
          return TagItem;
        case 'Phase':
          return PhaseItem;
      }
      return DefaultItem;
    },
    async onDelete(item) {
      await this.$store.dispatch('removeArchiveItem', { id: item.id });
    },
    onHideModal() {
      this.isDeleteModalVisible = false;
    },
    async onRestore(item) {
      this.isWorking = true;
      await this.$store.dispatch('restoreArchiveItem', { id: item.id });
      this.isWorking = false;
    },
    selectFilterType(type) {
      this.filterType = type;
    },
  },
};
</script>
