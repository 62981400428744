import * as TYPES from 'constants/mutations';
import BuilderService from 'services/method/builder.service';
import TagsService from 'services/tags.service';
import { merge } from 'utils/reactive';

const state = {
  highlights: [],
  projectsHighlights: {},
};

const getters = {
  highlights: (state, getters) => {
    const projectId = getters.projectCurrentId;
    return (state.projectsHighlights[projectId] || []).map((h) => getters.highlightById(h.id)).filter((h) => h);
  },
  projectHighlights: (state, getters) => (projectId) => {
    return (state.projectsHighlights[projectId] || []).map((h) => getters.highlightById(h.id)).filter((h) => h);
  },
  highlightById: (state) => (id) => {
    const highlight = state.highlights.find((h) => h.id === id);
    return highlight && { ...highlight, cardType: 'Sentence' };
  },
  isProjectHighlightsFetched: (state, getters) => (projectId) => {
    return !!state.projectsHighlights[projectId];
  },
  isHighlightsFetched: (state, getters) => {
    return getters.isProjectHighlightsFetched(getters.projectCurrentId);
  },
};

const actions = {
  async fetchHighlights({ commit, getters, dispatch }, { projectId } = {}) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;
    projectId = projectId || getters.projectCurrentId;

    if (!projectId) {
      return;
    }
    
    const response = await TagsService.fetchSentences(workspaceCurrentId, projectId);
    commit('HIGHLIGHTS_SET', { projectId, highlights: response.sentences });
  },
  requireHighlights({ state, getters, dispatch }, { projectId } = {}) {
    projectId = projectId || getters.projectCurrentId;

    if (!projectId) {
      return;
    }

    if (!this.tagsLoading) {
      this.tagsLoading = {};
    }

    if (!state.projectsHighlights[projectId] && !this.tagsLoading[projectId]) {
      this.tagsLoading[projectId] = dispatch('fetchHighlights', { projectId }).then(() => this.tagsLoading[projectId] = false);
    }

    return this.tagsLoading[projectId];
  },
  changeHighlight({ commit, getters }, { id, designMethodId, parentId, content, projectId, contentTags }) {
    projectId = projectId || getters.projectCurrentId;
    const { workspaceCurrentId } = getters;
    const highlight = getters.highlightById(id);
    designMethodId = (highlight && highlight.designMethodId) || designMethodId;
    parentId = (highlight && highlight.parentId) || parentId;
    const tagsList = getters.allTags;

    contentTags = contentTags.map((contentTag) => ({
      ...contentTag,
      tagList: contentTag.tagList.filter((tag) => {
        return tagsList.find((t) => t.id === tag);
      }),
    }));

    commit('UPDATE_HIGHLIGHTS', { tagsList, parentId, content, contentTags, id });

    BuilderService.updateItem(
      workspaceCurrentId,
      projectId,
      designMethodId,
      parentId,
      {
        content,
        contentTags,
      }
    );
  },
};

const mutations = {
  HIGHLIGHTS_SET(state, { projectId, highlights }) {
    highlights.forEach((h) => {
      state.highlights.push(h);
    });
    state.projectsHighlights = { ...state.highlights, [projectId]: highlights };
  },
  UPDATE_HIGHLIGHTS(state, { tagsList, parentId, content, contentTags }) {
    const { highlights } = state;

    highlights.forEach((hl) => {
      let found = false;

      contentTags.forEach((contentTag) => {
        if (hl.parentId === parentId && hl.endAt === contentTag.endAt && hl.startAt === contentTag.startAt) {
          merge(hl, {
            tags: contentTag.tagList.map((tagId) => ({
              ...tagsList.find((tag) => tag.id === tagId),
            })),
            parentContent: content,
          })
          found = true;
        }
      });

      if (!found && parentId === hl.parentId) {
        merge(hl, {
          tags: [],
          parentContent: content,
        })
      }
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
