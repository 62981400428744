<template>
  <span
    class="avatar"
    :data-avatar="color"
  >
    <transition name="swipe-bottom">
      <div
        v-if="mode === 'guest'"
        key="guest"
        class="avatar__content"
      >
        <Icon name="user-fill" />
      </div>
      <div
        v-else-if="shouldShowGravatar"
        :key="`gravatar-url-${ avatarUrl }`"
        class="avatar__content"
      >
        <img
          :src="avatarUrl"
        >
      </div>
      <div
        v-else-if="mode === 'email' && !this.name.length"
        key="mail-icon"
        class="avatar__content"
      >
        <Icon name="mail" />
      </div>
      <div
        v-else-if="mode === 'brand' && !this.name.length"
        key="brand-icon"
        class="avatar__content"
      >
        <Icon name="circle" />
      </div>
      <div
        v-else-if="mode === 'email' && !!this.name.length"
        :key="`mail-name-${ avatar }`"
        class="avatar__content"
      >
        {{ avatar }}
      </div>
      <div
        v-else-if="mode === 'brand' && !!this.name.length"
        :key="`brand-name-${ avatar }`"
        class="avatar__content"
      >
        {{ avatar }}
      </div>
    </transition>
    <img
      v-if="gravatarUrl"
      :src="gravatarUrl"
      style="opacity: 0;"
      @load="onAvatarLoad"
      @error="onAvatarError"
    >
  </span>
</template>

<script>
import { avatar, getColor, gravatar } from 'helpers/avatar';
import { validate } from 'helpers/email';
import debounce from 'lodash/debounce';

export default {
  name: 'OnboardingAvatar',
  props: {
    mode: {
      type: String,
      required: true,
      default: 'guest',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    avatarUrl: '',
    avatarError: false,
    gravatarUrl: '',
  }),
  computed: {
    avatar() {
      return avatar(this.name);
    },
    color() {
      if (this.shouldShowGravatar) {
        return undefined;
      }
      return ['email', 'brand'].includes(this.mode) && !!this.name.length && getColor(this.avatar);
    },
    isValid() {
      return this.name && this.name.length && validate(this.name);
    },
    shouldShowGravatar() {
      return this.avatarUrl && !this.avatarError;
    },
  },
  watch: {
    name(current) {
      this.avatarError = true;
      this.gravatarUrl = validate(this.name) && gravatar(current);
    },
  },
  mounted() {
    this.gravatarUrl = validate(this.name) && gravatar(this.name);
  },
  beforeUnmount() {
  },
  methods: {
    onAvatarLoad(e) {
      this.avatarUrl = e.target.src;
      this.avatarError = false;
    },
    onAvatarError() {
      this.avatarError = true;
    },
    onLogoLoad(e) {
      this.avatarUrl = e.target.src;
      this.avatarError = false;
    },
    onLogoError() {
      this.avatarError = true;
    },
  },
};
</script>
