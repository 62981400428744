<template>
  <div>
    <p class="modal__title">
      Change payment method
    </p>

    <p class="payment__secure">
      <span class="icon-locked-green" /> Secure connection
    </p><p /><div class="payment__form">
      <div class="payment__form-content">
        <div
          class="payment__form-row"
          :class="{ 'is-invalid': nameError }"
        >
          <div class="payment__form-col">
            <label
              class="payment__form-label"
              for="ccName"
            >
              Name on card
            </label>
            <div>
              <input
                id="ccName"
                v-model="form.firstName"
                type="text"
                placeholder="e.g. John Doe"
                @input="onInput"
              >
              <p
                v-if="nameError"
                class="payment__form-field-error"
              >
                {{ nameError }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            class="payment__form-row"
            :class="{ 'is-invalid': numberError }"
          >
            <div class="payment__form-col">
              <label
                class="payment__form-label"
                for="ccNumber"
              >Card number</label>
              <div>
                <div
                  id="ccNumber"
                  class="braintree-hf"
                />
                <p
                  v-if="numberError"
                  class="payment__form-field-error"
                >
                  {{ numberError }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="payment__form-row"
            :class="{ 'is-invalid': expirationError }"
          >
            <div class="payment__form-col">
              <label
                class="payment__form-label"
                for="ccExpiration"
              >Exp. date</label>
              <div>
                <div
                  id="ccExpiration"
                  class="braintree-hf"
                />
                <p
                  v-if="expirationError"
                  class="payment__form-field-error"
                >
                  {{ expirationError }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="payment__form-row"
            :class="{ 'is-invalid': cvvError }"
          >
            <div class="payment__form-col">
              <label
                class="payment__form-label"
                for="ccCvv"
              >CVV</label>
              <div>
                <div
                  id="ccCvv"
                  class="braintree-hf"
                />
                <p
                  v-if="cvvError"
                  class="payment__form-field-error"
                >
                  {{ cvvError }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        class="btn payment__btn modal__submit"
        :class="{ 'btn--loading': buttonProcessing }"
        :disabled="!isCorrect || buttonProcessing"
        @click="onSubmit"
      >
        {{ buttonText }}
        <BaseSpinner v-if="buttonProcessing" />
      </button>

      <p
        v-if="error"
        class="payment__form-terms error"
      >
        {{ error }}
      </p>
    </div>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import Spinner from 'components/common/base-spinner';
import COUNTIRES from 'constants/countries';
import { PRIVACY_POLICY, TERMS } from 'constants/routes';
import { checkVAT, countries } from 'jsvat';
import NotificationsService from 'services/notifications.service';
import PaymentsService from 'services/payments.service';
import { mapGetters } from 'vuex';

export default {
  name: 'ReceiptDataModal',
  components: {
    Spinner,
  },
  data: () => ({
    countryList: COUNTIRES,
    needInvoice: false,
    loading: true,
    error: false,
    buttonProcessing: false,
    firstSubmit: false,
    ccNumberError: true,
    ccExpirationError: true,
    ccCvvError: true,
    countryError: null,
    nameError: null,
    companyNameError: null,
    addressError: null,
    taxNumberError: null,
    updateCard: false,
    form: {
      companyName: '',
      firstName: '',
      taxNumber: '',
      address: '',
      country: '',
    },
  }),
  TERMS,
  PRIVACY_POLICY,
  computed: {
    ...mapGetters([
      'pricings',
      'invoice',
      'userPlan',
      'userPlanInfo',
      'paymentMethods',
    ]),

    isCorrect() {
      const form = this.form;
      return (form.firstName && !this.expirationError && !this.numberError && !this.cvvError);
    },
    plan() {
      const plan = this.$store.getters.planByKindAndPeriod(this.userPlan, 'monthly');

      return plan || {};
    },
    planId() {
      const plan = this.plan;
      return plan.id;
    },
    price() {
      return this.plan.price;
    },
    buttonText() {
      return `Update`;
    },
    numberError() {
      if (!this.firstSubmit) return null;
      return this.ccNumberError;
    },
    expirationError() {
      if (!this.firstSubmit) return null;
      return this.ccExpirationError;
    },
    cvvError() {
      if (!this.firstSubmit) return null;
      return this.ccCvvError;
    },
  },
  watch: {
    invoice(invoice) {
      this.processInvoice(invoice);
    },
  },
  async mounted() {
    this.processInvoice(this.invoice);
    await PaymentsService.loaded;
    await PaymentsService.initHostedFields({
      onValidityChange: ({ error, name, live }) => {
        if (live && !this.firstSubmit) return;
        this[`${ name }Error`] = error;
      },
      onSubmit: () => {
        this.onSubmit();
      },
    });
    await this.$store.dispatch('paymentMethodsFetch');
    this.loading = false;
  },
  methods: {
    trimString(str) {
      if (!str || str === '‏‏‎ ‎') return '';
      return str.replace(/(^\W+)|(\W+$)/gim, '');
    },
    processInvoice(invoice) {
      this.form.address = this.trimString(invoice.address);
      this.form.companyName = this.trimString(invoice.companyName);
      this.form.taxNumber = this.trimString(invoice.taxNumber);
      this.form.firstName = this.trimString(invoice.firstName);
      this.form.country = invoice.country || '';
      this.needInvoice = !!(
        this.trimString(invoice.companyName) ||
        this.trimString(invoice.address)
      );
    },
    validateCountry() {
      if (!this.firstSubmit) return null;
      if (!this.form.country) return 'Choose country from the list';
      return null;
    },
    validateName() {
      if (!this.firstSubmit) return null;
      if (!this.form.firstName) return 'Name on card can\'t be empty';
      return null;
    },
    validateCompanyName() {
      if (!this.firstSubmit) return null;
      if (!this.form.companyName) return 'Company name can\'t be empty';
      return null;
    },
    validateAddress() {
      if (!this.firstSubmit) return null;
      if (!this.form.address) return 'Adress can\'t be empty';
      return null;
    },
    validateTaxNumber() {
      if (!this.firstSubmit || !this.form.taxNumber) return null;
      const country = this.form.country || '';

      if (country) {
        const vatCheck = checkVAT(this.form.taxNumber, countries);
        if (!vatCheck.isValid || (vatCheck.country && vatCheck.country.name !== country)) {
          return `Invalid EU Tax ID for ${ country }`;
        }
      }
      return null;
    },
    async onSubmit() {
      if (this.buttonProcessing) return;

      const data = this.form;
      this.error = null;
      this.firstSubmit = true;

      PaymentsService.updatePaymentMethod({
        ...(this.pricingModalData || {}),
        receiptData: { ...data },
        planId: this.planId,
        cardholderName: this.form.firstName,
        disabled: !this.isCorrect,
        amount: parseFloat(this.price).toFixed(2),
        onValidityChange: ({ error, name }) => {
          this[`${ name }Error`] = error;
        },
        onCheckout: () => {
          this.buttonProcessing = true;
        },
        onSuccess: async (data, clear) => {
          this.$emit('completed', { ...data });
          this.$store.dispatch('subscriptionFetch');
          this.$store.dispatch('invoiceFetch');
          NotificationsService.showInfo('Your account was upgraded!');
          clear();
        },
        onError: ({ error }) => {
          this.error = error || 'Something goes wrong, please try again later';
          this.firstSubmit = false;
          this.buttonProcessing = false;
        },
      });
    },

    hide() {
      this.$store.dispatch('modalHide');
    },
    onInput(event) {
      const id = event.target.id;

      switch(id) {
        case 'ccName':
          this.nameError = this.validateName();
          break;
        case 'vat':
          this.taxNumberError = this.validateTaxNumber();
          break;
        case 'companyName':
          this.companyNameError = this.validateCompanyName();
          break;
        case 'address':
          this.addressError = this.validateAddress();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .update-card {
    cursor: pointer;
    margin-left: 20px;
    color: #53b7cb;
  }
</style>
