let $store = null;

export const init = function (s) { $store = s }

export const FANCYBOX_DEFAULTS = {
  animationEffect: 'zoom',
  selector : '.fancybox',
  infobar: false,
  buttons: [
    'download',
    'close',
  ],
  afterClose: function() {
    $store.dispatch('setLightboxVisible', false);
  },
  beforeShow: function() {
    $store.dispatch('setLightboxVisible', true);
  },

  onInit() {
    const header = document.querySelector('.header');
    const fcWidget = document.querySelector('.fc-widget-normal');

    header && header.classList.add('compensate-for-scrollbar');
    fcWidget && fcWidget.classList.add('compensate-for-scrollbar');
  },

  btnTpl: {
    arrowLeft:
      `<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}"><div><svg xmlns="http://www.w3.org/2000/svg" style="transform:scaleX(-1);" width="18" height="12" viewBox="0 0 18 12">
          <g fill="none" fill-rule="evenodd" transform="matrix(-1 0 0 1 21 -6)">
              <g stroke="#FFF" stroke-linecap="round">
                  <path style="fill:none;stroke-width:1;" d="M0.5 6L17.5 6" transform="matrix(-1 0 0 1 21 6)"/>
                  <path style="fill:none;stroke-width:1;" stroke-linejoin="round" d="M11.5 0.5L17.5 6 11.5 11.5" transform="matrix(-1 0 0 1 21 6)"/>
              </g>
          </g>
      </svg></div></button>`,

    arrowRight:
      `<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}"><div><svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12">
          <g fill="none" fill-rule="evenodd" transform="matrix(-1 0 0 1 21 -6)">
              <rect width="24" height="24" fill="#FFF" fill-opacity="0" rx="4"/>
              <g stroke="#FFF" stroke-linecap="round">
                  <path style="fill:none;stroke-width:1;" d="M0.5 6L17.5 6" transform="matrix(-1 0 0 1 21 6)"/>
                  <path style="fill:none;stroke-width:1;" stroke-linejoin="round" d="M11.5 0.5L17.5 6 11.5 11.5" transform="matrix(-1 0 0 1 21 6)"/>
              </g>
          </g>
      </svg></div></button>`,
      download:
        '<a download data-fancybox-download class="fancybox-button fancybox-button--download" title="{{DOWNLOAD}}" href="javascript:;" target="_blank">' +
        `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
            <path style="fill:none;stroke-width:1;" fill="none" fill-rule="evenodd" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" d="M21.5 10.5v8c0 .552-.448 1-1 1h-19c-.552 0-1-.448-1-1v-8M11 .5v13m-3.5-3l3.5 4 3.5-4"/>
        </svg>` +
        '</a>',
      close:
        '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
        `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12">
            <g fill="none" fill-rule="evenodd" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round">
                <path style="fill:none;stroke-width:1;" d="M12 0L0 12M0 0L12 12" transform="translate(1)"/>
            </g>
        </svg>` +
        '</button>',
  },
};
