<template>
  <div class="method-icon">
    <PageAvatar :page="method" />
  </div>
</template>

<script>
import { slugify } from 'utils/text';

export default {
  name: 'MethodIcon',
  props: {
    method: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    slug() {
      return slugify(this.method.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.method-icon {
  width: 1em;
  height: 1em;
  border-radius: 4px;
  color: #fff;
  line-height: 1;

  :global(.icon) {
    vertical-align: top;
  }
}
</style>
