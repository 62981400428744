export function getVideoThumbnail(file, time = 0.5) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.setAttribute('src', URL.createObjectURL(file));

    video.addEventListener('loadedmetadata', () => {
      setTimeout(() => {
        video.currentTime = time * video.duration;
      }, 200);
      
      video.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        ctx.canvas.toBlob(
          (blob) => {
              resolve(blob);
          },
          'image/jpeg',
          1
        );
      });
    });

    video.addEventListener('error', (error) => {
      reject('error', error);
    });

    video.load();
  });
}