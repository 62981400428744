<template>
  <BaseModal
    class="modal__login is-active"
    name="CancelSubscriptionModal"
    :show-close="true"
  >
    <div class="modal__body">
      <p class="modal__title">
        It’s sad to see you go
      </p>
      <p class="modal__desc">
        Please tell us why you decided to cancel your subscription. It’ll help us to make it better.
      </p>

      <button
        v-for="text of reasons"
        :key="text"
        class="btn btn--outline btn--block"
        style="margin-bottom: 10px"
        :class="{ 'js-active': !hasOther && text === reason }"
        @click="onReason(text)"
      >
        {{ text }}
      </button>
      <input
        v-model="other"
        class="modal__input"
        style="text-align: center; border-bottom-color: #f3f4f4"
        placeholder="Type other"
      >


      <div v-if="error !== null">
        {{ error }}
      </div>
      <button
        class="btn modal__submit"
        @click="onCancel"
      >
        Cancel {{ plan }} plan
      </button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
import { CANCEL_SUBSCRIPTION } from 'constants/modals';
import NotificationsService from 'services/notifications.service';
import SubscriptionService from 'services/subscription.service';
import { titleize } from 'utils/text';

export default {
  name: 'CancelSubscriptionModal',
  components: {
    BaseModal,
  },
  data: () => ({
    reason: '',
    other: '',
    error: null,
  }),
  computed: {
    name() {
      return CANCEL_SUBSCRIPTION;
    },
    plan() {
      const userPlan = this.$store.getters.userPlan;
      if (!userPlan) return '';

      return titleize(userPlan);
    },

    reasons() {
      return [
        'Product is too buggy',
        'It\'s too expensive',
        'Didn’t met our expectations',
      ];
    },
    hasOther() {
      return !!this.other.length;
    },
  },
  methods: {
    onReason(reason) {
      this.reason = reason;
      this.other = '';
    },

    async onCancel() {
      const workspaceId = this.$store.getters.workspaceCurrentId;
      const reason = this.other.length ? this.other : this.reason;

      try {
        await SubscriptionService.cancel({ reason, workspaceId });
        this.$store.dispatch('subscriptionCancelled');
        this.$store.dispatch('modalHide');
        NotificationsService.showInfo('Subscription cancelled');
      } catch (e) {
        this.error = e.message;
      }
    },

    hide() {
      this.$store.dispatch('modalHide');
    },
  },
};
</script>
