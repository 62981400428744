/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import { sortItemsByDateCreated } from 'helpers/tags';
import NotificationsService from 'services/notifications.service';
import ProjectService from 'services/project.service';
import ProjectsService from 'services/projects.service';
import StorageService from 'services/storage.service';
import TeamService from 'services/team.service';
import { merge } from 'utils/reactive';
import { navigateHome } from 'helpers/router';
import { sortAndFilterByProps } from 'helpers/filter';

const moduleState = {
  projects: null,
  animationShown: StorageService.projectsAnimation.load() || false,
};

const getters = {
  projects: (state, getters) => {
    const projects = (getters.workspaceCurrent && sortItemsByDateCreated(getters.workspaceCurrent.projects.map((p) => (
      state.projects.find((s) => s.id === p.id) || p
    )))) || [];

    return sortAndFilterByProps(projects, {
      sortBy: 'date_created',
    });
  },
  projectsCreatedByUser: (state, getters) => getters.projects.filter(({ name }) => !name.match(/\(example project\)$/i)),
  projectById: (state, getters) => (projectId) =>
    (state.projects && state.projects.find(({ id }) => id === projectId)) || null,
  projectCurrent: (state, getters) =>
    getters.projectById(getters.projectCurrentId),
  userHasAccessToProjectById: (state, getters) => (projectId) => {
    return !!getters.projectById(projectId);
  },
  projectsAnimationShown: (state) => state.animationShown,
  isProjectsFetched: (state) => state.projects !== null,
  isProjectFetched: (state, getters) => {
    return getters.projectCurrent && !!getters.projectCurrent.fetched;
  },
  isProjectForbidden: (state, getters) => {
    return getters.isProjectFetched && getters.projectCurrent.error;
  },
  isProjectPrivate: (state, getters) => getters.projectCurrent && !getters.projectCurrent.public,
  projectTeam: (state, getters) => {
    return getters.projectCurrent && getters.projectCurrent.teamMembers || [];
  },
};

const actions = {
  async projectsFetch({ dispatch, commit, getters }) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;
    const projects = await ProjectsService.fetchAll(workspaceCurrentId);

    commit(TYPES.PROJECTS_FETCHED, projects);

    return projects;
  },
  async projectFetch({ dispatch, getters }, id = null) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;

    let projectId = id;
    if (id === null) {
      projectId = getters.projectCurrentId;
    }

    let project;
    let error;

    project = await ProjectsService.fetch(workspaceCurrentId, projectId).catch((e) => error = e);
    project.id = projectId;

    if (!project) {
      project = { id: projectId, error: true };
    }

    // Fix updating project without error param. Cached error fix.
    if (!project.error) {
      project.error = false;
    }

    project.fetched = true;
    dispatch('projectSet', project);
    return project;
  },
  projectSet({ commit }, project) {
    commit(TYPES.PROJECT_UPDATE, project);
  },
  async projectAdd({ commit, dispatch, getters }, isIntro = false) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;
    const project = await ProjectsService.addNew(workspaceCurrentId, isIntro);
    project.workspaceId = workspaceCurrentId;
    commit(TYPES.PROJECTS_ADD, project);

    dispatch('subscriptionFetch');
    dispatch('workspaceLimitsFetch');

    return project;
  },
  async projectAddData({ commit, dispatch, getters }, data = {}) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;
    let project;

    try {
      project = await ProjectsService.addNewFromData(workspaceCurrentId, data);
    } catch(e) {
      if (e.error && e.error.toLowerCase().indexOf('limit') > -1) {
        navigateHome();
        dispatch('modalShow', {
          name: 'limit-reached',
          data: {
            kind: 'projects',
          },
        });
      }

      return;
    }

    project.workspaceId = workspaceCurrentId;
    commit(TYPES.PROJECTS_ADD, project);

    dispatch('subscriptionFetch');
    dispatch('workspaceLimitsFetch');

    return project;
  },
  async projectAddType({ commit, dispatch, getters }, template = null) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;
    const project = await ProjectsService.add(workspaceCurrentId, template);
    project.workspaceId = workspaceCurrentId;
    commit(TYPES.PROJECTS_ADD, project);

    dispatch('subscriptionFetch');
    dispatch('workspaceLimitsFetch');

    return project;
  },
  async projectDuplicate({ commit, dispatch, getters }, id) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;
    const project = await ProjectsService.duplicate(workspaceCurrentId, id);
    project.createdAt = new Date().toISOString();
    project.workspaceId = workspaceCurrentId;
    commit(TYPES.PROJECTS_ADD, project);
    dispatch('subscriptionFetch');
    dispatch('workspaceLimitsFetch');

    return project;
  },
  async projectRemove({ commit, getters, dispatch }, id) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;

    commit(TYPES.PROJECT_CHANGE, {
      id,
      data: {
        removed: true,
      },
    });

    await ProjectsService.remove(workspaceCurrentId, id);

    NotificationsService.showInfo('Project was removed');
    dispatch('subscriptionFetch');
    dispatch('workspaceLimitsFetch');

    commit(TYPES.PROJECTS_REMOVE, id);
  },
  async projectLeave({ dispatch, commit, getters }, id) {
    const { workspaceCurrentId } = getters;
    const project = getters.projectById(id);
    const userId = getters.user.id;

    commit(TYPES.PROJECT_CHANGE, {
      id,
      data: {
        removed: true,
      },
    });

    try {
      await TeamService.removeTeamMember({
        workspaceId: workspaceCurrentId,
        projectId: id,
        userId,
        memberId: project.teamMembers.find((m) => m.userId === userId).id,
      });
      dispatch('subscriptionFetch');
      dispatch('workspaceLimitsFetch');
      commit(TYPES.PROJECTS_REMOVE, id);
    } catch (e) {
      commit(TYPES.PROJECT_CHANGE, {
        id,
        data: {
          removed: false,
        },
      });

      NotificationsService.showError(
        'An error occured. You cannot leave this project!'
      );
      throw new Error(e);
    }
  },
  projectChange({ commit, getters }, { currentProject = null, data }) {
    const { workspaceCurrentId } = getters;
    const project =
      currentProject || getters.projectCurrent;
    
    data.updatedAt = new Date().toISOString();

    commit(TYPES.PROJECT_CHANGE, {
      id: project.id,
      data,
    });

    return ProjectService.updateProject(workspaceCurrentId, project.id, data);
  },
  projectsAnimationShown({ commit }) {
    commit(TYPES.PROJECTS_ANIMATION);
  },
  async setProjectImage({ commit, getters }, image) {
    const { workspaceCurrentId } = getters;
    const projectId = getters.projectCurrentId;

    commit('PROJECT_SET_IMAGE', { projectId, image: null });
    const project = await ProjectService.setImage(
      workspaceCurrentId,
      projectId,
      image
    );
    commit('PROJECT_SET_IMAGE', { projectId, image: project.url });
  },

  async removeProjectImage({ commit, getters }) {
    const { workspaceCurrentId } = getters;
    const projectId = getters.projectCurrentId;
    commit('PROJECT_SET_IMAGE', { projectId, image: null });
    const project = await ProjectService.removeImage(workspaceCurrentId, projectId);
  },

  async teamEnabledChange({ commit, getters }, { projectId, member, enabled }) {
    let data;

    if (enabled) {
      data = await TeamService.addTeamMember({
        workspaceId: getters.workspaceCurrentId,
        projectId,
        userId: member.userId,
      });
    } else {
      data = await TeamService.removeTeamMember({
        workspaceId: getters.workspaceCurrentId,
        projectId,
        userId: member.userId,
        memberId: member.id,
      });
    }

    commit(TYPES.TEAM_ENABLED_CHANGE, { projectId, userId: member.userId, enabled, data });

 //   return member;
  },
};

const mutations = {
  [TYPES.PROJECTS_FETCHED](state, projects) {
    if (!state.projects || !state.projects.length) {
      state.projects = projects;
      return;
    }

    projects.forEach((project) => {
      let found = state.projects.find(({ id }) => id === project.id);

      if (found) {
        merge(found, project);
      } else {
        state.projects = [ ...state.projects, project ];
      }
    });
  },
  [TYPES.PROJECTS_ADD](state, project) {
    state.projects.unshift(project);
  },
  [TYPES.PROJECT_UPDATE](state, project) {
    if (!state.projects) {
      state.projects = [];
    }

    const found = state.projects.find(({ id }) => id === project.id);

    if (!found) {
      state.projects = [ ...state.projects, project ];
    } else {
      merge(found, project);
    }
  },
  [TYPES.PROJECTS_REMOVE](state, projectId) {
    state.projects = state.projects.filter(({ id }) => projectId !== id);
  },
  [TYPES.PROJECT_CHANGE](state, { id, data }) {
    const project = state.projects.find((p) => p.id === id);
    merge(project, data);
  },
  [TYPES.PROJECTS_ANIMATION](state) {
    state.animationShown = true;
  },
  [TYPES.LOGGED_OUT](state) {
    state.projects = [];
  },
  PROJECT_SET_IMAGE(state, { image, projectId }) {
    if (!state.projects) return;
    const project = state.projects.find(({ id }) => id === projectId);

    if (!project) return;

    merge(project, { image });
  },
  [TYPES.TEAM_ENABLED_CHANGE](state, { projectId, userId, enabled, data }) {
    const project = state.projects.find((p) => p.id === projectId);

    if (!project) return;

    if (enabled) {
      project.teamMembers.push(data);
    } else {
      project.teamMembers = project.teamMembers.filter((m) => m.userId !== userId);
    }
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
