<template>
  <div>
    <BaseInput2
      v-if="canEdit"
      ref="nameInput"
      :classes="classes"
      class="base-subtitle"
      :remember="true"
      :cant-edit="canEdit"
      v-bind="$attrs"
    />
    <div
      v-else
      class="base-subtitle__div-title"
    >
      {{ $attrs.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSubtitle',
  props: {
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    classes() {
      return {
        'cant-edit': !this.canEdit,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-subtitle {
  height: 28px;
  font-family: Lato;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 0 0 8px;
  transition: border-bottom 0.25s;
  border-bottom: 1px dashed white;
  &:hover:not(.cant-edit) {
    border-bottom: 1px dashed #8e8e8e;
  }
  &:focus-within {
    border-bottom: 1px dashed #1d1d1d !important;
  }

  &__div-title {
    height: 28px;
    font-family: Lato;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin: 0 0 8px;
    border-bottom: 1px dashed white;
  }
}
</style>
