<template>
  <div class="modal__body">
    <div class="modal-done modal-done--success">
      <img
        src="/static/img/svg/art-checkmark.svg"
        class="modal-done__icon"
      >
      <h2 class="modal-done__title">
        {{ title }}
      </h2>
      <button
        class="btn payment__btn modal-done__btn modal__submit"
        @click="onBtnClick"
      >
        Continue to Talebook
      </button>
    </div>
  </div>
</template>

<script>
import { PROJECTS_LIST } from 'constants/routes';
import { titleize } from 'utils/text';

export default {
  name: 'PaymentSuccess',
  props: {
    receiptData: Object,
  },
  data: () => ({
  }),
  computed: {
    title() {
      return 'Payment method change successful';
    },
  },
  methods: {
    onBtnClick() {
      this.$store.dispatch('subscriptionFetch');
      this.$router.push({ name: PROJECTS_LIST });
      this.$store.dispatch('modalHide');
    },
  },
};
</script>
