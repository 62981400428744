<template>
  <div class="notifications">
    <transition-group
      name="notification"
      tag="div"
      mode="out-in"
    >
      <notification
        v-for="(data, index) of notifications"
        :key="index"
        :data="data"
        @undo="onUndo(data.id)"
        @hide="onHide(data.id)"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Notification from './item';

export default {
  name: 'Notifications',
  components: {
    Notification,
  },
  computed: {
    ...mapGetters([
      'notifications',
    ]),
  },
  methods: {
    onUndo(id) {
      this.$store.dispatch('notificationUndo', id);
    },
    onHide(id) {
      this.$store.dispatch('notificationHide', id);
    },
  },
};
</script>
