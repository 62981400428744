<template>
  <Card
    :data="data"
    :breadcrumbs="true"
    :disabled="true"
  />
</template>

<script>

export default {
  name: 'ProjectArchiveItemDefault',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
