import * as ApiService from 'services/api.service';

class LibrariesService {
  fetchAll(workspace) {
    return ApiService.get(`api/workspaces/${workspace}/custom_methods/library/pages`);
  }

  addPage(workspace, data) {
    return ApiService.post(`${this.getUrl(workspace)}/library_pages`, data);
  }

  changePage(workspace, page, data) {
    return ApiService.put(`${this.getUrl(workspace)}/library_pages/${page}`, data);
  }

  removePage(workspace, page) {
    return ApiService.del(`${this.getUrl(workspace)}/library_pages/${page}`);
  }

  addSection(workspace, data) {
    return ApiService.post(`${this.getUrl(workspace)}/library_sections`, data);
  }

  changeSection(workspace, section, data) {
    return ApiService.put(`${this.getUrl(workspace)}/library_sections/${section}`, data);
  }

  removeSection(workspace, section) {
    return ApiService.del(`${this.getUrl(workspace)}/library_sections/${section}`);
  }

  addItem(workspace, data) {
    return ApiService.post(`${this.getUrl(workspace)}/library_items`, data);
  }

  changeItem(workspace, item, data) {
    return ApiService.put(`${this.getUrl(workspace)}/library_items/${item}`, data);
  }

  removeItem(workspace, item) {
    return ApiService.del(`${this.getUrl(workspace)}/library_items/${item}`);
  }

  getUrl(workspace) {
    return `api/workspaces/${workspace}`;
  }
}

export default new LibrariesService();
