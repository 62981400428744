<template>
  <div class="addon-gallery">
    <div
      v-for="image of images"
      :key="$store.getters.idJoin(image.id)"
      :data-id="image.id"
      class="addon-gallery__item addon-gallery__item--draggable"
      :style="{
        width: (1 / columns * 100) + '%',
      }"
      @mousedown.stop
    >
      <BaseUploader
        :uploading="image.uploading"
        :file="image.file"
        :url="image.url"
        :can-edit="canEdit"
        @remove="removeImage(image.id)"
        @change="addImage($event, image.id)"
      />
    </div>
    <div
      v-if="canEdit"
      :key="newImageId"
      class="addon-gallery__item addon-gallery__item--new"
      :style="{
        width: (1 / columns * 100) + '%',
      }"
    >
      <BaseUploader
        :can-edit="canEdit"
        @change="addImage($event)"
      />
    </div>
    <div
      v-for="index in placeholders"
      :key="index"
      class="addon-gallery__item addon-gallery__item--placeholder"
      :style="{
        width: (1 / columns * 100) + '%',
      }"
    >
      <div class="addon-gallery__item-placeholder" />
    </div>
  </div>
</template>

<script>
import { byPosition } from 'helpers/sort';
import generateId from 'utils/id';

export default {
  name: 'ProjectMethodCustom',
  components: {
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      newImageId: generateId(),
    };
  },
  computed: {
    columns() {
      return this.section.options && this.section.options.columns || 2;
    },
    images() {
      return this.section.galleryImages ? byPosition(this.section.galleryImages) : [];
    },
    placeholders() {
      if ((this.images.length + 1) % this.columns === 0) {
        return 0;
      }
      
      return this.columns - ((this.images.length + 1) % this.columns);
    },
  },
  created() {
  },
  methods: {
    addImage(image, imageId) {
      if (!imageId) {
        imageId = this.newImageId;
      }
      this.$store.dispatch('builderAddGallerySectionImage', {
        id: this.section.id,
        image,
        imageId,
      });

      this.newImageId = generateId();
    },
    removeImage(imageId) {
      this.$store.dispatch('builderRemoveGallerySectionImage', {
        id: this.section.id,
        imageId,
      });
    },
    isEmpty(data) {
      return !(data.galleryImages && data.galleryImages.length);
    },
  },
};
</script>
