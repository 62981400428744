import zenscroll from 'zenscroll';

export default (offset = 0, duration = 250) => zenscroll.toY(offset, duration);

export const getScrollPosition = () => {
  let doc = document.documentElement;
  let left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
  return { top, left };
}
