<template>
  <div
    class="side-dropdown-menu"
    :class="classes"
  >
    <div class="side-dropdown-menu__content">
      <slot />
    </div>
    <DropdownMenu
      v-if="isEnabled"
      class="side-dropdown-menu__menu"
      :menu="menu"
      v-bind="dropdownAttrs"
      @toggle="menuOpen = $event"
    >
      <BaseTooltip
        v-if="tooltip"
        :text="tooltip"
        placement="top"
        style="height: 100%;"
      >
        <span class="side-dropdown-menu__btn">
          <Icon name="more-vertical" />
        </span>
      </BaseTooltip>
      <span
        v-else
        class="side-dropdown-menu__btn"
      >
        <Icon name="more-vertical" />
      </span>
    </DropdownMenu>
  </div>
</template>

<script>
import { pick } from 'lodash';

export default {
  name: 'SideDropdownMenu',
  props: {
    appearance: {
      type: String,
      default: 'default',
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    menu: [Array, Boolean],
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    classes() {
      return {
        'side-dropdown-menu--open': this.menuOpen,
        'side-dropdown-menu--empty': !this.isEnabled,
        [`side-dropdown-menu--${ this.appearance }`]: true,
      };
    },
    isEnabled() {
      return this.enabled && this.menu && this.menu.filter((m) => !m.hidden).length;
    },
    dropdownAttrs() {
      return pick(this.$attrs, [
        'onToggle',
        'dropdownText',
        'appearance',
      ])
    },
  },
  watch: {
    menuOpen(open) {
      this.$emit('toggle', open);
    },
  },
  methods: {
  },
};
</script>
