<template>
  <div class="onb-step">
    <form class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          <tempalte v-if="error">
            Something went wrong, try again
          </tempalte>
          <tempalte v-else>
            Invitation accepted
          </tempalte>
        </h2>
      </div>
      <p v-if="!error">
        Now you can sign in
      </p>
    </form>
  </div>
</template>
<script>
import { navigateSignin } from 'helpers/router';

export default {
  name: 'SignInPassword',
  computed: {
    error() {
      return this.$route.params.error;
    },
  },
  async created() {
    this.updateConfig();
  },
  methods: {
    getConfig() {
      return {
        avatar: 'email',
        avatarName: this.email,
        buttons: [
          {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Sign in',
          },
        ],
      };
    },
    buttonAction(id) {
      switch(id) {
        case 'primary':
          this.next();
          return;
      }
    },
    async next() {
      navigateSignin();
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
  },
};
</script>
