<template>
  <div class="method-item method-item--new">
    <div class="method-item__icon">
      <Icon name="plus-small" />
    </div>
    <div class="method-item__body">
      <span class="method-item__name">
        Add Page
      </span>
      <p class="method-item__count">
        Choose from template library
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewItem',
};
</script>

<style lang="scss" scoped>
  .item__new {
    display: flex;
    justify-content: center;
    background-color: #f3f6f8;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #071021;
    cursor: pointer;
    &:hover {
      background-color: #e6edf0;
    }
    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background: image_url('svg/plus_two.svg') no-repeat 0 0;
      background-size: 100% 100%;
      display: inline-block;
      margin-right: 17px;
    }
  }
</style>
