export const METHOD_ITEM_TYPES = [
  {
    type: 'image',
    name: 'Image',
    icon: 'builder-addon--image',
  },
  {
    type: 'gallery',
    name: 'Gallery',
    icon: 'builder-addon-gallery',
  },
  {
    type: 'summary',
    name: 'Summary',
    icon: 'builder-addon-summary',
  },
  {
    type: 'charts',
    name: 'Charts',
    icon: 'builder-addon-charts',
  },
  {
    type: 'cards',
    name: 'Cards',
    icon: 'cards-25',
  },
  {
    type: 'checklist',
    name: 'Checklist',
    icon: 'builder-addon-checklist',
  },
  {
    type: 'pages',
    name: 'Pages',
    icon: 'file-25',
  },
  {
    type: 'pages-chart',
    name: 'Positioning map',
    icon: 'builder-addon-chart',
  },
  {
    type: 'custom_5',
    name: 'name',
    icon: 'builder-addon-list',
  },
  {
    type: 'custom_1',
    name: 'Scale',
    icon: 'scale-25',
  },
  {
    type: 'custom_2',
    name: 'Multiple Choice',
    icon: 'builder-addon-checklist',
  },
  {
    type: 'custom_3',
    name: 'Single Choice',
    icon: 'radio-25',
  },
];
