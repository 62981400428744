<template>
  <div class="headline">
    <h2 class="headline__title">
      <slot />
    </h2>
    <slot name="aside" />
  </div>
</template>

<script>
export default {
  name: 'Headline',
  props: {
    appearance: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
      };
    },
  },
};
</script>
