import debounce from 'lodash/debounce';

const DURATION = 250;

export default {
  bind(element, binding, vnode) {
    element._inputEventHandler = debounce((event) => {
      const methodName = binding.expression;
      vnode.context[methodName](event);
    }, DURATION);

    document.body.addEventListener('input', element._inputEventHandler, false);
  },

  unbind(element) {
    document.body.removeEventListener(
      'input',
      element._inputEventHandler,
      false
    );
  },
};
