<template>
  <div class="base-search">
    <BaseInput
      class="base-search__input"
      placeholder="Search"
      v-bind="$attrs"
      :value="value"
      @input="onChange"
    />
    <Icon
      class="base-search__ico"
      name="search"
    />
  </div>
</template>
<script>
export default {
  name: 'BaseSearch',
  emits: ['input', 'change'],
  props: {
    value: String,
  },
  data() {
    return {
    };
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>
