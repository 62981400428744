import * as ApiService from 'services/api.service';

class InvitationsService {
  getDetails(workspaceId, token) {
    return ApiService.get(`api/workspaces/${workspaceId}/invitations/${token}`);
  }

  accept(workspaceId, token, data) {
    return ApiService.put(`api/workspaces/${workspaceId}/invitations/${token}`, data);
  }
}

export default new InvitationsService();
