<template>
  <div class="library-new-item">
    <div class="library-question">
      <TypePicker
        class="library-question__picker"
        :color="color"
        :type="type"
        :create="false"
        :show-change-alert="false"
        placement="right"
        @color-change="color = $event"
        @type-change="type = $event.type"
      />
      <TextInput
        ref="input"
        v-model="content"
        type="text"
        class="library-question__input"
        autocomplete="off"
        maxlength="210"
        placeholder="Add item"
        @keydown.enter="submitQuestion"
        @blur="submitQuestion"
      />
    </div>
  </div>
</template>

<script>
import TypePicker from 'views/page/method-builder/type-picker';

export default {
  name: 'LibrarySection',
  components: {
    TypePicker,
  },
  props: {
  },
  data() {
    return {
      content: '',
      color: '#abc0c8',
      type: 'cards',
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    submitQuestion() {
      if (!this.content) return;

      this.$emit('submit', {
        name: this.content,
        type: this.type,
        color: this.color,
      });

      this.content = '';
     // this.$refs.input.blur();
    },
  },
};
</script>
