<template>
  <component
    :is="component"
    v-if="type.match(/report/) && component"
    ref="addon"
    :survey-mode="surveyMode"
    :section="section"
    :can-edit="canEdit || (type.match(/report\_/) && canEditReport)"
    class="builder-section-component"
    :data-id="section.id"
    @selectTitle="$emit('selectTitle')"
  />
  <SideDropdownMenu
    v-else
    ref="base"
    class="builder-section"
    :class="classNames" 
    :menu="menu"
    appearance="top"
    @toggle="open = $event"
  >
    <div v-if="type === 'summary_result'" class="builder-section__title">
      <div
        v-if="canEdit"
        class="sortable-handler"
      >
        <Icon name="drag" />
      </div>
      <div
        class="question-type-picker"
      >
        <div
          class="methods__question-edit-counter question-type-picker-icon"
        >
          <div
            class="methods__question-edit-counter-body"
            :style="{ background: '#059FA3' }"
          >
            <Icon
              class="methods__question-edit-counter-icon"
              name="builder-addon-magic"
            />
          </div>
        </div>
      </div>
      <TitleInput
        appearance="md clear"
        value="Generated summary"
        :can-edit="false"
      />
    </div>
    <div
      v-else-if="type !== 'paragraph' && type !== 'image' && type !== 'custom_6' && type !== 'embed' && !type.match(/report/)"
      class="builder-section__title"
    >
      <div
        v-if="canEdit"
        class="sortable-handler"
      >
        <Icon name="drag" />
      </div>
      <TypePicker
        v-if="!surveyMode"
        :can-edit="canEdit"
        :color="color"
        :type="type"
        :show-change-alert="shouldShowChangeAlert"
        @type-change="onTypeChange"
        @color-change="onColorChange"
        @toggle="$emit('toggle', $event)"
      />
      <span style="display:none;">{{ name }}</span>
      <TitleInput
        ref="titleinput"
        :multiline="true"
        appearance="md clear"
        :value="name"
        :placeholder="namePlaceholder"
        :can-edit="canEdit"
        @keydown="onKeyDown"
        @input="onNameChange"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>
    <ContentTransition>
      <div
        v-if="displayContent"
        class="builder-section__content"
      >
        <component
          :is="component"
          v-if="component"
          ref="addon"
          :survey-mode="surveyMode"
          :section="section"
          :can-edit="canEdit || (type.match(/report\_/) && canEditReport)"
          class="builder-section-component"
          @selectTitle="$emit('selectTitle')"
          :data-id="section.id"
        />
        <div v-else>
          <h2>Section type not supported</h2>
          <BaseButton appearance="primary" @click="removeSection">Remove section</BaseButton>
        </div>
      </div>
    </ContentTransition>
  </SideDropdownMenu>
</template>

<script>
import { mapGetters } from 'vuex';

import { TYPE_TO_NAME_MAP, SURVEY_TYPES } from 'constants/survey';
import Cards from './addons/cards';
import Charts from './addons/charts';
import Checklist from './addons/checklist';
import Embed from './addons/embed';
import Gallery from './addons/gallery';
import Image from './addons/image';
import Pages from './addons/pages';
import PagesChart from './addons/pages-chart';
import Paragraph from './addons/paragraph';
import SummaryResult from './addons/summary-result';
import ReportInsightsFull from './addons/report-insights-full';
import ReportSummary from './addons/report-summary';
import ReportText from './addons/report-text';
import ReportTitle from './addons/report-title';
import Summary from './addons/summary';
import SurveyChoice from './addons/survey-choice';
import SurveyMultiChoice from './addons/survey-multi-choice';
import SurveyScale from './addons/survey-scale';
import SurveyText from './addons/survey-text';
import TypePicker from './type-picker';
import Video from './addons/video';

const ADDONS = {
  'cards': Cards,
  'gallery': Gallery,
  'summary': Summary,
  'paragraph': Paragraph,
  'summary_result': SummaryResult,
  'image': Image,
  'charts': Charts,
  'checklist': Checklist,
  'custom_1': SurveyScale,
  'custom_2': SurveyMultiChoice,
  'custom_3': SurveyChoice,
  'custom_4': Cards,
  'custom_5': SurveyText,
  'custom_6': Video,
  'embed': Embed,
  'pages': Pages,
  'scatter_plot': PagesChart,
  'report_2': ReportInsightsFull,
  'report_3': ReportText,
  'report_4': ReportSummary,
  'report_5': ReportTitle,
};

export default {
  name: 'ProjectMethodCustom',
  components: {
    TypePicker,
    Cards,
  },
  props: {
    section: Object,
    canEdit: Boolean,
    canEditReport: Boolean,
    index: Number,
    editor: Object,
    surveyMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      editting: false,
      name: '',
      currentType: '',
      isNew: true,
      isLocal: true,
    };
  },
  computed: {
    ...mapGetters(['selectedSections', 'uiHideAnswers', 'isLibraryOpen', 'libraryCurrent', 'itemsLimitReached']),
    displayContent() {
      return !(this.isLibraryOpen && this.uiHideAnswers);
    },
    classNames() {
      return {
        'builder-section--selected': this.open,
        [`builder-section--${ this.section.type }`]: true,
        'builder-section--survey-type': SURVEY_TYPES.includes(this.type),
        'builder-section--survey-invalid': SURVEY_TYPES.includes(this.type) && this.section.invalidAnswer,
        'methods__question-edit-counter-hover': this.type !== 'summary_result',
      };
    },
    columns() {
      return this.section.options && this.section.options.columns || 2;
    },
    color() {
      return this.section.color || '#abc0c8';
    },
    type() {
      return this.section.type;
    },
    namePlaceholder() {
      return TYPE_TO_NAME_MAP[this.section.type];
    },
    isEmpty() {
      return !this.name;
    },
    component() {
      return ADDONS[this.section.type];
    },
    menu() {
      if (!this.canEdit) return [];
      return [
        !!['gallery'].includes(this.section.type) && {
          text: 'View',
          submenuInfo: `${this.columns} columns`,
          submenu: {
            title: 'View',
            menu: [
              {
                text: '2 Columns',
                action: () => this.setColumns(2),
                selected: this.columns === 2,
              },
              {
                text: '3 Columns',
                action: () => this.setColumns(3),
                selected: this.columns === 3,
              },
              {
                text: '4 Columns',
                action: () => this.setColumns(4),
                selected: this.columns === 4,
              },
            ],
          },
        },
        !!['gallery'].includes(this.section.type) && '-',
        !['paragraph', 'image'].includes(this.section.type) && {
          text: 'Add to library',
          submenu: this.addLibraryMenu,
        },
        {
          text: 'Move up',
          action: () => this.$store.dispatch('builderMoveSection', { id: this.section.id, direction: 'down' }),
        },
        {
          text: 'Move down',
          action: () => this.$store.dispatch('builderMoveSection', { id: this.section.id, direction: 'up' }),
        },
        {
          text: 'Remove block',
          appearance: 'warn',
          action: () => this.$store.dispatch('builderRemoveSection', { id: this.section.id }),
        },
      ];
    },
    addLibraryMenu() {
      const libraryCurrent = this.libraryCurrent || [];

      return {
        title: 'Select library page',
        search: 'Search page',
        menu: libraryCurrent.map((p) => {
          return {
            text: p.name,
            submenu: {
              title: 'Select library section',
              search: 'Search section',
              menu: [
                ...(p.sections || []).map((s) => ({
                  text: s.name,
                  action: async () => {
                    const data = {
                      ...this.section,
                      position: undefined,
                      sectionId: undefined,
                    };
                    this.$store.dispatch('addLibraryItem', { pageId: p.id, sectionId: s.id, data });
                  },
                })),
              ],
            },
          }
        }),
      };
    },
    shouldAnimate() {
      return !this.section.local && this.section.new;
    },
    shouldShowChangeAlert() {
      if (!(this.component && this.component.methods.isEmpty)) {
        return true;
      }
      return !this.component.methods.isEmpty(this.section);
    },
  },
  watch: {
    section: {
      handler(next, prev) {
        if (next.focus || next.focus === 0) {
          this.focus(next.focus);
        }
        if (this.currentType !== next.type) {
          this.name = this.section.name || TYPE_TO_NAME_MAP[this.section.type] || '';
          this.currentType = next.type;
        }
        if (this.isLocal && !next.local) {
          this.isLocal = false;
        }

        if (this.name !== next.name) {
          this.name = next.name || TYPE_TO_NAME_MAP[this.section.type] || '';
        }
      },
      deep: true,
    },
    shouldAnimate(should) {
      if (should) {
        this.$el.setAttribute('data-animation', should);
      } else {
        this.$el.removeAttribute('data-animation', should);
      }
    },
  },
  created() {
    this.name = this.section.name || TYPE_TO_NAME_MAP[this.section.type] || '';
    this.currentType = this.section.type;
    this.isLocal = this.section.local;
  },
  mounted() {
    if (this.section.focus || this.section.focus === 0) {
      this.focus(this.section.focus);
    }

    if (this.shouldAnimate) {
      this.$el.setAttribute('data-animation', this.shouldAnimate);
    }

    if (this.$refs.addon) {
      this.$refs.addon.$el.__vue__ = this.$refs.addon;
    }
  },
  updated() {
    if (this.$refs.addon) {
      this.$refs.addon.$el.__vue__ = this.$refs.addon;
    }
  },
  methods: {
    setColumns(columns) {
      this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: {
        options: {
          ...this.section.options,
          columns,
        },
      } });
    },
    onTypeChange({ type, data }) {
      this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { type, ...data } });
    },
    onColorChange(color) {
      this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { color } });
    },
    focus(pos) {
      const { titleinput, addon } = this.$refs;

      if (titleinput) {
        titleinput.focus(pos);
      } else if (addon) {
        this.$nextTick(() => addon.focus(pos));
      }

      this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { focus: null } });
    },
    onNameChange(name) {
      this.name = name;
      this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { name: name.trim() } });
    },
    async onKeyDown(e) {
      if (e.key === 'Backspace') {

        if (this.isEmpty) {
          // const prevSection = this.$store.getters.getSectionAt(this.index - 1);
          // if (prevSection) {
          //   this.$store.dispatch('builderFocusSection', { id: prevSection.id });
          // }
          // this.$store.dispatch('builderRemoveSection', { id: this.section.id });
          e.preventDefault();
          return;
        }
      }
    },
    onFocus() {
      this.name = this.section.name || TYPE_TO_NAME_MAP[this.section.type] || '';
      this.editting = true;
      this.$emit('toggle', true);
    },
    onBlur() {
      this.name = this.section.name || TYPE_TO_NAME_MAP[this.section.type] || '';
      this.editting = false;
      this.$emit('toggle', false);
    },
    addTag(tag) {
      this.$refs.addon.addTag && this.$refs.addon.addTag(tag);
    },
    removeTag(tag) {
      this.$refs.addon.removeTag && this.$refs.addon.removeTag(tag);
    },
    removeSection() {
      this.$store.dispatch('builderRemoveSection', { id: this.section.id });
    },
  },
};
</script>
