<template>
  <div
    class="titleinput"
    :class="classNames"
  >
    <div class="titleinput__body">
      <span
        v-if="!canEdit && !editting"
        class="titleinput__no-edit"
      >
        {{ value }}
      </span>
      <template v-else>
        <ColorPicker
          v-if="colorPicker"
          :color="color"
          class="titleinput__color-picker"
          @change="$emit('color-change', $event)"
        />
        <template v-if="dropdownMenu">
          <DropdownMenu
            v-if="!editting"
            :visible="editting ? false : null"
            :menu="dropdownMenu"
            placement="bottom-start"
            title="Library page"
          >
            <BaseTooltip
              text="Switch page"
              placement="top"
            >
              <span class="titleinput__no-edit titleinput__select">
                {{ value }}
              </span>
            </BaseTooltip>
          </DropdownMenu>
          <BaseInput
            v-else-if="autowidth"
            ref="input"
            v-autowidth="{maxWidth: '400px', minWidth: '20px', comfortZone: 0}"
            class="titleinput__input"
            :value="value"
            :multiline="multiline"
            :maxlength="maxlength"
            :placeholder="placeholder"
            @input="onInput"
            @change="onChange"
            @blur="onBlur"
            @focus="onFocus"
            @enter="onEnter"
          />
          <BaseInput
            v-else
            ref="input"
            class="titleinput__input"
            :value="value"
            :multiline="multiline"
            :maxlength="maxlength"
            :placeholder="placeholder"
            @input="onInput"
            @change="onChange"
            @blur="onBlur"
            @focus="onFocus"
            @enter="onEnter"
          />
        </template>
        <BaseInput
          v-else-if="autowidth"
          ref="input"
          v-autowidth="{maxWidth: '400px', minWidth: '20px', comfortZone: 0}"
          class="titleinput__input"
          :value="value"
          :multiline="multiline"
          :maxlength="maxlength"
          :placeholder="placeholder"
          @input="onInput"
          @change="onChange"
          @blur="onBlur"
          @focus="onFocus"
          @keydown.enter="onEnter"
        />
        <BaseInput
          v-else
          ref="input"
          class="titleinput__input"
          :value="value"
          :multiline="multiline"
          :maxlength="maxlength"
          :placeholder="placeholder"
          @input="onInput"
          @change="onChange"
          @blur="onBlur"
          @focus="onFocus"
          @keydown.enter="onEnter"
        />
      </template>
    </div>
    <div
      v-if="showSwitch"
      class="titleinput__icons"
    >
      <div
        class="switch switch--right"
        @click="toggleAnswers"
      >
        <input
          type="checkbox"
          :checked="switchEnabled"
        >
        <label>
          {{ switchText }}
        </label>
      </div>
    </div>
    <div
      v-else-if="showIcons"
      class="titleinput__icons"
    >
      <slot />
      <ViewOnly v-if="viewOnly" />
      <span
        v-if="helpMenu && questionButton"
        class="titleinput__icon titleinput__icon--help"
      >
        <DropdownMenu
          :menu="helpMenu"
        >
          <button
            type="button"
            class="titled-icon"
          >
            <Icon name="question" />
            <span class="titled-icon__text">HELP</span>
          </button>
        </DropdownMenu>
      </span>
      <span
        v-if="shareButton"
        class="titleinput__icon titleinput__icon--share"
      >
        <ButtonShare
          v-bind="shareButton"
        />
      </span>
      <span
        v-if="editButton"
        class="titleinput__icon"
      >
        <ButtonEdit
          @click="$emit('onEditButtonClick')"
        />
      </span>
      <span
        v-if="libraryButton"
        class="titleinput__icon titleinput__icon--library"
      >
        <button
          type="button"
          class="titled-icon"
          @click="openLibrary"
        >
          <Icon name="book-open" />
          <span class="titled-icon__text">Library</span>
        </button>
      </span>
      <span
        v-if="menu && menu.filter((m) => !m.hidden).length"
        class="titleinput__icon titleinput__icon--more"
      >
        <DropdownMenu
          :tooltip="menuTooltip"
          :menu="menu"
        />
      </span>
    </div>
    <div
      v-if="closeOnEnter && editting"
      class="titleinput__enter"
    >
      <Icon name="enter" />
    </div>
  </div>
</template>

<script>
import { ColorPicker } from 'components/common/pickers';
import { mapGetters } from 'vuex';

export default {
  name: 'TitleInput',
  emits: ['change', 'input'],
  components: {
    ColorPicker,
  },
  props: {
    value: {
      type: String,
    },
    canEdit: {
      default: false,
      type: Boolean,
    },
    dropdownMenu: [Boolean, Array],
    multiline: Boolean,
    appearance: {
      type: String,
      default: 'default',
    },
    color: {
      type: String,
      default: '#000',
    },
    viewOnly: Boolean,
    colorPicker: Boolean,
    questionButton: [String, Boolean],
    tutorialName: String,
    shareButton: [Boolean, Object],
    libraryButton: Boolean,
    editButton: [Boolean, Object],
    menu: [Boolean, Array],
    menuTooltip: [String],
    maxlength: {
      type: Number,
      required: false,
    },
    autowidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    showSwitch: Boolean,
    switchText: String,
    switchEnabled: Boolean,
    closeOnEnter: Boolean,
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      editting: false,
      savedValue: '',
    };
  },
  computed: {
    ...mapGetters(['tutorialsConfig', 'projectCurrentMethod']),
    showIcons() {
      return (
        this.$slots.default ||
        this.viewOnly ||
        (this.helpMenu && this.questionButton) ||
        this.shareButton ||
        this.editButton ||
        this.libraryButton ||
        (this.menu && this.menu.filter((m) => !m.hidden).length)
      );
    },
    classNames() {
      const appearances = this.appearance.split(' ').map((a) => `titleinput--${ a }`);
      return appearances;
    },
    helpMenu() {
      const tutorialName = this.tutorialName || this.projectCurrentMethod && this.projectCurrentMethod.type && this.projectCurrentMethod.type.toLowerCase();
      const config = this.tutorialsConfig && this.tutorialsConfig.find((t) => t.id === tutorialName);

      if (!config) return null;

      const menu = [];

      if (config.steps) {
        menu.push({
          text: 'Replay introduction',
          icon: 'circle-play',
          action: () => {
            this.$store.dispatch('openTutorial', tutorialName)
          },
        });
      }

      if (config.docs) {
        if (menu.length) menu.push('-');
        for (let doc of config.docs) {
          menu.push({
            text: doc.name,
            icon: 'file-alt',
            url: doc.url,
          });
        }
      }

      if (config.sample) {
        if (menu.length) menu.push('-');
        menu.push({
          text: config.sample.name,
          icon: 'eye',
          url: config.sample.url,
        });
      }

      return menu;
    },
  },
  methods: {
    blur() {
      this.$refs.input.blur();
    },
    focus() {
      this.$refs.input.focus();
    },
    onBlur() {
      if (!this.allowEmpty && !this.value) {
        this.$emit('input', this.savedValue);
      }

      this.editting = false;
      this.$emit('blur');
    },
    onFocus() {
      if (!this.allowEmpty) {
        this.savedValue = this.value;
      }
      this.$emit('focus');
    },
    openLibrary() {
      this.$store.dispatch('toggleLibrary', true);
    },
    edit() {
      this.editting = true;
      this.$nextTick(() => {
        this.$refs.input.edit(true);
      });
    },
    toggleAnswers() {
      this.$store.dispatch('uiToggleHideAnswers');
    },
    onEnter(e) {
      if (this.closeOnEnter) {
        this.blur();
      }

      this.$emit('enter', e);
    },
    onInput(value) {
      this.$emit('input', value);
    },
    onChange(value) {
      this.$emit('change', value);
    },
    getCaretPos() {
      return this.$refs.input.getCaretPos();
    },
  },
};
</script>
