export const BASE = 'base';
export const LOGIN = 'login';
export const TESTERS = 'testers';
export const TAGS = 'tags';
export const UPGRADE = 'upgrade';
export const SURVEY = 'survey';
export const PAYMENT = 'payment';
export const PAYMENT_METHOD = 'payment-method';
export const CANCEL_SUBSCRIPTION = 'cancel-subscription';
export const ANSWER_MODAL = 'answer-modal';
