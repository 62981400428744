<template>
  <div class="builder--preloading">
    <BaseSpinner
      class="builder-list-preloading"
      :full-height="true"
    />
    <div
      v-if="isFetched"
      class="container"
    >
      <div :class="(isReport ? 'lg-wrapper' : 'small-wrapper')">
        <div class="builder-title-wrap" v-if="!(isReport && isShared)">
          <router-link
            v-if="parentName"
            :to="parentRoute"
          >
            <a class="builder-breadcrumbs">
              <Icon name="arrow-left" />
              {{ parentName }}
            </a>
          </router-link>
          <div class="builder-title">
            <PageAvatarPicker
              v-if="!isReport && editable"
              class="builder-title__avatar"
              :page="method"
              @color-change="changeColor"
              @icon-change="changeIcon"
              @removeImage="removeImage"
              @addImage="changeImage"
            />
            <PageAvatar
              v-else-if="!isReport"
              class="builder-title__avatar"
              :page="method"
            />
            <TitleInput
              ref="title"
              :value="headerText"
              appearance="project-title"
              :can-edit="!isReport && editable"
              :maxlength="methodNameMaxLength"
              :multiline="true"
              rows="1"
              :view-only="true"
              :share-button="titleShareButton"
              :menu="titleMenu"
              :library-button="!isReport && areButtonsVisible"
              :question-button="areButtonsVisible"
              :show-switch="isLibraryOpen"
              switch-text="Hide content"
              :switch-enabled="uiHideAnswers"
              tutorial-name="custommethod"
              @change="onNameChange"
              @focus="onNameFocus"
              @blur="onNameBlur"
              @enter="onTitleEnter"
              @keydown="onTitleKeydown"
            />
          </div>
        </div>
        <LimitReachedBox kind="components" v-if="itemsLimitReached" />
        <div
          class="builder-content"
          :class="{
            'builder-content--editable': editable,
            'builder-content--report': isReport,
          }"
        >
          <div
            ref="listWrap"
            class="builder-list-wrap"
            :class="{
              'drop-enabled': dragOverActive,
            }"
          >
            <Sortable
              ref="sortable"
              select-area-class="method-page"
              :disabled="!canEdit"
              :select="canEdit"
              :animateList="false"
              :can-drag-to="['library']"
              group="method"
              :data="sections"
              :editor-mode="true"
              event-handler-class="method-page"
              @dragstart="onDragStart"
              @dragend="onDragEnd"
              @dragin="onDragIn"
              @selectend="onSelectEnd"
            >
              <div
                v-for="(section, index) in sections"
                :key="section.localId || section.id"
                :data-id="section.localId || section.id"
                class="drop-wrap section-wrap"
                :class="[
                  `section-wrap--${section.type}`,
                  section.type !== 'paragraph' && !isReport && `section-wrap--not-paragraph`,
                  section.options && section.options.hidden && 'hide-print',
                ]"
                :data-local="section.local"
              >
                <BuilderSection
                  ref="section"
                  :section="section"
                  :can-edit="editable"
                  :can-edit-report="editableReportSections"
                  :data-id="section.localId || section.id"
                  @toggle="draggingDisabled = $event"
                  @selectTitle="onSelectTitle"
                />
                <div
                  v-if="editable"
                  :data-index="index"
                  class="drop-target drop-target--top"
                  @drop="onTargetDrop"
                  @dragover="onTargetDragOver"
                  @dragleave="onTargetDragLeave"
                />
                <div
                  v-if="editable"
                  :data-index="index + 1"
                  class="drop-target drop-target--bottom"
                  @drop="onTargetDrop"
                  @dragover="onTargetDragOver"
                  @dragleave="onTargetDragLeave"
                />
                <div class="report-add-section" v-if="isReport && editableReportSections">
                  <BaseButton
                    @click="createReportSection(section.position + 1)"
                  >
                    <Icon name="plus-small" />
                  </BaseButton>
                </div>
              </div>
            </Sortable>
          </div>
          <div class="page-summary" v-if="shouldShowSummary">
            <div class="page-summary__empty">
              <BaseTooltip text="Add more data to enable summary" placement="top" v-if="!summaryHasEnoughtChars && !summaryLoading">
                <BaseButton
                  appearance="light-shadow smm disabled"
                  text="Generate summary"
                  icon="builder-addon-magic"
                />
              </BaseTooltip>
              <BaseButton
                appearance="light-shadow smm"
                text="Generate summary"
                @click="generateSummary"
                icon="builder-addon-magic"
                v-else-if="!summaryLoading"
              />
              <div class="page-summary-loading" v-else>
                <BaseSpinner />
                Generating can take up to minute
              </div>
            </div>
          </div>
          <div
            class="drop-last"
            :data-index="sectionsLength"
            @drop="onTargetDrop"
            @dragover="onTargetDragOver"
            @dragleave="onTargetDragLeave"
          />
          <div
            ref="multitag"
            class="builder-multitag"
            :style="multitagStyle"
            @mousedown.stop
          >
            <Responders
              ref="tagsSelector"
              :header-enabled="false"
              list="tags"
              @itemSelect="multitagSelect"
              @itemUnselect="multitagUnselect"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PROJECT_METHOD } from 'constants/routes';
import { TYPE_TO_NAME_MAP } from 'constants/survey';
import { navigateProject } from 'helpers/router';
import { getCaretPos, getElementDistanceToCursor, hasParentWithClasses, isHidden, setCaretPos } from 'helpers/ui';
import { blink } from 'helpers/ui';
import $ from 'jquery';
import { analyticsLogEvent } from 'utils/analytics';
import { mapGetters } from 'vuex';
import zenscroll from 'zenscroll';
import EventsService from 'services/events.service';
import BuilderSection from './section';

export default {
  name: 'ProjectMethodCustom',
  components: {
    BuilderSection,
},
  data() {
    return {
      headerText: '',
      newType: 'cards',
      newColor: '#abc0c8',
      newName: '',
      draggingDisabled: false,
      dragging: false,
      selecting: false,
      lastSelectedY: null,
      shouldShowMultitagSelector: false,
      preloading: true,
      summaryPrompt: 'summarize in one paragraph and 3 insights in number list in english',
    };
  },
  computed: {
    ...mapGetters({
      method: 'projectCurrentMethod',
      canEdit: 'userCanEditProjects',
      projectCurrentId: 'projectCurrentId',
      isShared: 'isShared',
      dragOverActive: 'dragOverActive',
      methodNameMaxLength: 'methodNameMaxLength',
      selectedSectionsFingerprint: 'selectedSectionsFingerprint',
      isCardDragging: 'isCardDragging',
      isLibraryOpen: 'isLibraryOpen',
      uiHideAnswers: 'uiHideAnswers',
      itemsLimitReached: 'itemsLimitReached',
      tags: 'tags',
      insightsLimitReached: 'insightsLimitReached',
      summary: 'summary',
    }),
    pureSummary() {
      let summary = this.summary;
      return summary && summary.result ? summary.result.summary : '';
    },
    summaryText() {
      return this.pureSummary.replace(/Insights\:[\s\S]*/gim, '');
    },
    summaryInsights() {
      let insights = this.pureSummary.match(/Insights\:[\s\S]*/gim, '');

      if (!insights) {
        return [];
      }

      insights = insights[0].split("\n");
      insights.shift();

      insights = insights.map((insight) => {
        return insight.replace(/^[0-9]\.\s/gim, '').trim();
      })

      return insights.filter((i) => !!i);
    },
    summaryLoading() {
      return this.summary && this.summary.status !== 'completed';
    },
    isReport() {
      if (this.isShared) {
        return this.method && this.method.customMethodItems.find((m) => m.type.includes('report_'));
      }

      return this.method && this.$store.getters.isMethodReportById(this.method.id);
    },
    parentName() {
      const method = this.$store.getters.projectMethodById(this.method.parentMethodId);
      return method && method.name;
    },
    parentRoute() {
      const methodId = this.method.parentMethodId;
      return methodId && {
          name: PROJECT_METHOD,
          params: {
            project_id: this.$store.getters.projectCurrentId,
            method_id: methodId,
          },
        };
    },
    methodId() {
      return this.$store.getters.projectCurrentMethodId;
    },
    isFetched() {
      return this.$store.getters.isProjectMethodFetched(this.methodId);
    },
    sectionsPure() {
      return this.$store.getters.methodSections(this.methodId);
    },
    sections() {
      if (this.isReport) {
        return this.sectionsPure
          .filter((s) => s.type.match(/report\_/gim))
          .filter((s) => !this.isShared || !(s.options || {}).hidden);
      }

      return this.sectionsPure;
    },
    titleShareButton() {
      return this.areButtonsVisible && {
        methodId: this.method.id,
        hasSurveyLink: !this.isReport,
      }
    },
    sectionsLength() {
      return this.sections.length;
    },
    multitagStyle() {
      return { top: `${ this.lastSelectedY }px`, display: this.shouldShowMultitagSelector ? '' : 'none' };
    },
    titleMenu() {
      return [
        {
          text: 'Export PDF',
          action: this.exportPDF,
          hidden: this.isShared || !this.canEdit,
          className: 'export-pdf-link',
        },
        {
          text: 'Archive Page',
          action: () => this.onArchiveMethod(this.method),
          hidden: this.isShared || !this.canEdit || this.isReport,
        },
      ];
    },
    isEmpty() {
      if (!this.created) return;
      return (
        !this.sections ||
        !this.sections.find(
          (s) =>
            !s.local &&
            (s.type !== 'paragraph' || (s.type === 'paragraph' && s.content))
        )
      );
    },
    name() {
      return this.method.name;
    },
    areButtonsVisible() {
      return this.canEdit && !this.isShared;
    },
    editable() {
      return this.canEdit && !this.isShared && !this.isReport;
    },
    editableReportSections() {
      return this.canEdit && !this.isShared;
    },
    shouldShowSummary() {
      return !this.isReport && !this.isLibraryOpen && !this.isShared && this.canEdit;
    },
    summaryHasEnoughtChars() {
      return this.charsCount >= 600;
    },
    charsCount() {
      return this.sections.reduce((value, current) => {
        let length = 0;

        if (current.type === 'paragraph') {
          length = current.content ? current.content.length : 0;
        }

        if (current.type === 'custom_6' && current.customItemFields && current.customItemFields.length) {
          length = 10000;
        }

        return value + length;
      }, 0);
    },
  },
  watch: {
    '$route.query.item'() {
      this.scrollToTarget();
    },
    'method.name'(name) {
      this.headerText = this.isReport ? 'Report' : name;
    },
    selecting(selecting) {
      this.$nextTick(() => {
        this.refreshMultitagVisibility();
      });
    },
    /**
     * Peformance: don't update whole component to just add one class
     */
    dragging(dragging) {
      if (dragging) {
        this.$refs.listWrap.classList.add('builder-dragging');
      } else {
        this.$refs.listWrap.classList.remove('builder-dragging');
      }
    },
    isEmpty(empty) {
      if (empty) {
        this.$refs.listWrap.classList.add('builder-empty');
      } else {
        this.$refs.listWrap.classList.remove('builder-empty');
      }
    },
    shouldShowMultitagSelector(should) {
      if (should) {
        this.$refs.multitag.style.display = '';
        this.$refs.tagsSelector.selectTags([]);
      } else {
        this.$refs.multitag.style.display = 'none';
      }
    },
  },
  beforeUpdate() {
    this.updateStartTime = Date.now();
  },
  updated() {
    this.initialize();
  },
  async created() {
    //await this.$parent.initMethod('builder');
    this.created = true;
    this.headerText = this.isReport ? 'Report' : this.method.name;
  },
  async mounted() {
    this.$el.classList.remove('builder--preloading');
    
    //await this.$store.dispatch('projectMethodFetch');
    document.addEventListener('mousedown', this.documentMouseDown, false);
    document.addEventListener('click', this.documentClick, false);
    document.addEventListener('keydown', this.onKeyDown, false);
    document.addEventListener('copy', this.onCopy, false);
    document.addEventListener('paste', this.onPaste, false);
    this.$store.dispatch('fetchLibrary');
   // this.lazyLoad();

   this.initialize();
   EventsService.emit('editor-updated');
  },
  beforeUnmount() {
    // document.removeEventListener('mousemove', this.documentMouseMove, false);
    // document.removeEventListener('mouseup', this.documentMouseUp, false);
    document.removeEventListener('mousedown', this.documentMouseDown, false);
    document.removeEventListener('click', this.documentClick, false);
    document.removeEventListener('keydown', this.onKeyDown, false);
    document.removeEventListener('copy', this.onCopy, false);
    document.removeEventListener('paste', this.onPaste, false);
  },
  updated() {
    EventsService.emit('editor-updated');
  },
  methods: {
    scrollToTarget() {
      if (this.$route.query.item) {
        this.$nextTick(() => {
          window.setTimeout(() => {
            let section = document.querySelector(`[data-id="${ this.$route.query.item }"]`);

            if (section) {
              zenscroll.to(section, 0);
              blink(section);
            }

            window.setTimeout(() => {
              this.$el.classList.remove('builder--preloading');
            }, 100);
          }, 100);
        });
      }
    },
    initialize() {
      if (!this.isFetched || this.initialized) {
        return;
      }

      if (!this.$route.query.item) {
        this.$el.classList.remove('builder--preloading');
      } else {
        this.scrollToTarget();
      }

      this.initialized = true;
    },
    onNameChange(name) {
      name = name.trim();
      const data = name.length === 0 ? { name: this.oldName } : { name };
      this.$store.dispatch('builderMethodChange', {
        id: this.method.id,
        data,
      });
      this.headerText = data.name;
    },
    onNameFocus() {
      this.oldName = this.headerText;
    },
    onNameBlur() {
      this.headerText = this.headerText.trim();
      if (!this.headerText) {
        this.headerText = this.oldName;
      }
    },
    onArchiveMethod(method) {
      const { id } = method;
      const projectId = this.projectCurrentId;

      this.$store.dispatch('projectMethodChange', {
        id,
        data: { archived: true },
      });

      navigateProject(projectId);
    },
    onNewTypeChange(type) {
      this.newType = type;
    },
    onNewColorChange(color) {
      this.newColor = color;
    },
    onNewNameChange(name) {
      this.newName = name;
    },
    onAddSection({ type, color, index }) {
      this.$store.dispatch('builderCreateSection', { type, color, index, methodId: this.methodId });
    },

    onDragIn({ dragData, index, group }) {
      const data = dragData && dragData[0];
      if (data) {
        if (group === 'library') {
          this.$store.dispatch('builderCreateSection', {
            type: data.customMethodItem.type,
            methodId: this.methodId,
            data: {
              ...data.customMethodItem,
              name: data.name,
              color: data.color,
            },
            index,
          });
        }
        if (group === 'library-section') {
          if (data && data.items && data.items.length) {
            data.items.forEach((data, i) => {
              this.$store.dispatch('builderCreateSection', {
                type: data.customMethodItem.type,
                methodId: this.methodId,
                data: {
                  ...data.customMethodItem,
                  name: data.name,
                  color: data.color,
                },
                index: index + i,
              });
            });
          }
        }
      }
    },

    /**
     * Dragging start handler
     */
    onDragStart() {
      this.dragging = true;

      this.shouldShowMultitagSelector = false;

      document.removeEventListener('mousemove', this.documentMouseMove, false);
      document.removeEventListener('mouseup', this.documentMouseUp, false);
    },
    /**
     * Change multitag element visibility
     */
    refreshMultitagVisibility() {
      const {
        selecting,
        selectedSectionsFingerprint,
        shouldShowMultitagSelector,
      } = this;

      if (!selecting) {
        let selected = Array.from(document.querySelectorAll('.section-wrap.sortable-selected'));
        if (selected.length && selectedSectionsFingerprint) {
          const last = selected[selected.length - 1];
          const parent = document.querySelector('.builder-content').getBoundingClientRect();
          let taggable = false;

          selected.forEach((item) => {
            let el = item.querySelector('.builder-section-component');
            let component = el.__vue__;

            if (component && component.taggable) {
              taggable = true;
            }
          });

          if (taggable) {
            this.lastSelectedY = last.getBoundingClientRect().top + last.offsetHeight - parent.top;
          }

          this.shouldShowMultitagSelector = taggable;
        } else {
          this.shouldShowMultitagSelector = false;
        }
      } else {
        this.shouldShowMultitagSelector = false;
      }
    },

    /**
     * Dragging end handler
     */
    async onDragEnd({ ids, index }) {
      if (this.isReport && index > 0) {
        const target = this.sections[index];
        
        index = this.sectionsPure.filter((s) => !s.local).findIndex((section) => {
          return section.id === target.id;
        });
      }

      await this.$store.dispatch('builderMoveSections', {
        ids,
        index,
      });

      this.dragging = false;

      // Prevent focusing input just after dropping
      this.moved = true;
      window.setTimeout(() => {
        this.moved = false;
      }, 400);
    },
  
    /**
     * 
     */
    onSelectEnd(ids) {
      this.$store.commit('METHOD_SECTIONS_SELECT_IDS', {
        ids,
      });
      this.$nextTick(() => {
        this.refreshMultitagVisibility();
      });

      if (!ids.length) {
        return;
      }

      // Prevent focusing input just after dropping
      this.moved = true;
      window.setTimeout(() => {
        this.moved = false;
      }, 400);
    },

    /**
     * Document click handler:
     * 1. Find and focus nearest editable element (input, contenteditable etc.)
     */
    documentClick(e) {
      if (!this.editable || this.moved) return;

      let { target } = e;

      // Don't proceed when clicked on focusable / editable element
      if (
        ['INPUT', 'TEXTAREA'].includes(target.tagName) ||
        hasParentWithClasses(target, ['base-uploader', 'wysiwyg'])
      ) {
        return;
      }

      //
      if (!hasParentWithClasses(target, ['method-page'])) {
        return;
      }

      // Search for all focusable elements
      const editable = Array.from(document.querySelectorAll(
        '.builder-content textarea, .builder-content [contenteditable="true"], .builder-content input, .transcription-speaker'
      ));
      const mouse = { x: e.clientX, y: e.clientY };
      let closest = null;

      // Search for nearest focusable element
      editable.forEach((elem, i) => {
        const dist = getElementDistanceToCursor(elem, mouse);
        if (!closest || closest.dist.dist > dist.dist) {
          closest = {
            elem,
            dist,
          };
        }
      });

      // Focus nearest element if found
      if (closest) {
        if (hasParentWithClasses(closest.elem, ['base-card', 'transcription-speaker'])) {
          // Ignore cards
        } else if (['INPUT', 'TEXTAREA'].includes(closest.elem.tagName)) {
          if (closest.dist.sideY === -1 || closest.dist.sideX === -1) {
            setCaretPos(closest.elem, 'start');
          } else {
            setCaretPos(closest.elem, 'end');
          }
        } else if (closest.dist.sideY === -1 || closest.dist.sideX === -1) {
          setCaretPos(closest.elem, 'start');
        } else {
          setCaretPos(closest.elem, 'end');
        }
      }
    },

    /**
     * 
     */
    documentMouseDown() {
      this.prevKeyShortcut = null;
    },

    onCopy(e) {
      if (!this.editable) return;
      const { sectionsPure } = this;
      const { selectedSections } = this.$store.getters;
      const data = [];
      const textData = [];

      selectedSections.forEach((id) => {
        const section = sectionsPure.find((s) => (s.id === id) || (s.localId === id));
        if (section.local || section.type === 'image' || section.type === 'gallery' || section.type === 'pages' || section.type === 'custom_6') return;
        data.push({
          ...section,
        });
        
        if (section.type === 'paragraph') {
          const tempEl = document.createElement('div');
          tempEl.innerHTML = section.content;
          textData.push(tempEl.innerText);
        } else {
          textData.push(section.name || TYPE_TO_NAME_MAP[section.type]);

          if (section.customItemFields && section.customItemFields.length) {
            section.customItemFields.forEach((field) => {
              if (field.value !== null && field.value !== undefined) {
                textData.push(`- ${field.name} : ${field.value}`);
              } else {
                textData.push(`- ${field.name}`);
              }
            });
          }
        }
      });

      if (!data.length) {
        const selectedTranscription = document.querySelectorAll('.transcription-speaker.sortable-selected');

        if (selectedTranscription.length) {
          selectedTranscription.forEach((speaker) => {
            const speakerEl = speaker.querySelector('.transcription-speaker__name');
            const text = speaker.querySelector('.ql-editor');

            textData.push(speakerEl.dataset.name);
            textData.push(text.innerText);
          })

          try {
            (e.clipboardData || window.clipboardData).setData('text', textData.join('\n'));
          } catch(e) {
            // eslint-disable-next-line
            console.error(e);
          }

          e.preventDefault();
        }

        return;
      }

      try {
        (e.clipboardData || window.clipboardData).setData('text/_talebook-blocks', JSON.stringify(data));
        (e.clipboardData || window.clipboardData).setData('text', textData.join('\n'));
      } catch(e) {
        // eslint-disable-next-line
        console.error(e);
      }

      e.preventDefault();
    },

    onPaste(e) {
      if (!this.editable) return;
      let data = '';

      try {
        data = (e.clipboardData || window.clipboardData).getData('text/_talebook-blocks');
      } catch(e) {
        // eslint-disable-next-line
        console.error(e);
      }

      if (data) {
        const sections = JSON.parse(data);

        if (sections && sections.length) {
          const { target } = e;
          const sectionsEls = Array.from(this.$el.querySelectorAll('.section-wrap'));
          const sectionEl = hasParentWithClasses(target, ['section-wrap']);
          let index = this.sectionsPure.length;

          if (sectionEl && sectionsEls) {
            index = 0;

            for (const s of sectionsEls) {
              if (s === sectionEl) {
                break;
              }
              
              index++;
            }
          }
  
          sections.forEach((section, i) => {
            data = {
              ...section,
              // Filter contentTags with unknown tags
              contentTags: section.contentTags && section.contentTags.filter((contentTag) => {
                return contentTag.tagList.filter((tagId) => this.tags.find(({id}) => tagId === id)).length;
              }),
            };

            this.$store.dispatch('builderCreateSection', {
              type: section.type,
              data,
              index: index + i,
              methodId: this.methodId,
              ignoreLocalInIndex: true,
            });
          });

          e.preventDefault();
        }
      }
    },

    /**
     * Handle editor global key actions
     */
    onKeyDown(e) {
      if (!this.editable) return;
      const { target } = e;
      const { selectedSectionsFingerprint } = this;
      const { selectedSections } = this.$store.getters;
      const inputs = Array.from(document.querySelectorAll(
          '.method-page input, .method-page textarea, .method-page [contenteditable], .method-page .base-uploader'
        ))
        .filter((e) => !hasParentWithClasses(e, ['tags-selector']));
      const isInput = ['INPUT', 'TEXTAREA'].includes(target.tagName);
      const isContentEditable = target.contentEditable === 'true';
      const isEditable = isInput || isContentEditable;
      const isCmdKeyActive = e.metaKey || e.ctrlKey;
      let value = isInput
        ? target.value
        : target.textContent
            .replace(/^(<[A-z]+>)+/gim, '')
            .replace(/(<\/[A-z]+>)+$/gim, '');
      let caretPos = getCaretPos(target);
      let selectionLength = 0;
      let selection = window.getSelection();
      if (selection) {
        selectionLength = selection.toString().length;
      }
      if (value === '<br>') value = '';
      if (!isEditable) {
        value = '';
        caretPos = 0;
      }
      // Find input next to current target input
      let nextInput, prevInput, found;
      inputs.some((input, index) => {
        if (isHidden(input)) return;
        if (input === target) {
          found = true;
        } else if (found) {
          nextInput = input;
          return true;
        } else {
          prevInput = input;
        }
      });
      // Handle "select all" feature
      if (e.key === 'a' && isCmdKeyActive) {
        let valueLength, selectionLength;
        if (isInput) {
          valueLength = target.value.length;
          selectionLength = target.selectionEnd - target.selectionStart;
        } else {
          valueLength = target.innerHTML.length;
          const selection = window.getSelection();
          const range = selection.getRangeAt(0);
          const clonedSelection = range.cloneContents();
          const div = document.createElement('div');
          div.appendChild(clonedSelection);
          // Add extra 7 characters for <p></p>
          selectionLength = div.innerHTML.length + 7;
        }
        if (
          this.prevKeyShortcut === 'select_all' ||
          (selectionLength && valueLength <= selectionLength)
        ) {
          this.$refs.sortable.selectAll();
          e.preventDefault();
        }
        this.prevKeyShortcut = 'select_all';
      }
      // Handle remove
      else if (e.key === 'Backspace') {
        if (selectedSectionsFingerprint) {
          let firstSelected = this.$store.getters.sectionById(selectedSections[0]);
          let firstId = firstSelected.localId || firstSelected.id;
          let $inside = $(`.section-wrap[data-id="${ firstId }"]`)
            .find('input, textarea, [contenteditable], .base-uploader')
            .filter((i, e) => !$(e).parents('.tags-selector').length);
          let target = null;
          inputs.some((input, index) => {
            let found = false;
            $inside.each((i, s) => {
              if (s === input) {
                found = true;
              }
            });
            if (found) {
              return true;
            }
            if (input.offsetHeight && input.offsetWidth) {
              target = input;
            }
          });
          if (target) {
            setCaretPos(target, 'end');
          }
          this.$store.dispatch('builderRemoveSelectedSections');
          this.$nextTick(() => {
            this.refreshMultitagVisibility();
          });
          e.preventDefault();
          return;
        }
        let sectionEl = hasParentWithClasses(target, ['section-wrap']);

        if (sectionEl) {
          let id = sectionEl.dataset.id;
          let section = this.$store.getters.sectionById(id);
          if (section && section.type === 'paragraph' && caretPos === 0) {
            if (section.options && ['bullet-list', 'number-list'].includes(section.options.type)) {
              this.$store.dispatch('methodSectionUpdate', {
                id,
                data: {
                  options:{
                    ...section.options,
                    type: '',
                  },
                },
              });
              e.preventDefault();
              return;
            }

            if (prevInput) {
              let prevInputSection = hasParentWithClasses(prevInput, ['section-wrap']);
              let prevSection = prevInputSection && this.$store.getters.sectionById(prevInputSection.dataset.id);
              if (prevSection && prevSection.type === 'paragraph') {
                let emptyParagraph = /\<[a-z0-9]+\>\<br\>\<\/[a-z0-9]+\>/gim;
                let editor = prevInputSection.querySelector('.ql-editor');
                let editorHtml = editor.innerHTML.replace(emptyParagraph, '');
                let targetHtml = target.innerHTML.replace(emptyParagraph, '');

                if (editorHtml) {
                  editorHtml = editorHtml + targetHtml;
                } else {
                  editorHtml = targetHtml;
                }
                let newHtml = editorHtml.replace('</p><p>', '');
                let focusPos = editor.innerText.replace(/[\r\n]/gim, '').length;
                editor.innerHTML = newHtml;
                if (targetHtml) {
                  setCaretPos(editor, focusPos);
                } else {
                  setCaretPos(prevInput, 'end');
                }
              } else {
                setCaretPos(prevInput, 'end');
              }
            }
            this.$store.dispatch('builderRemoveSection', { id });
            e.preventDefault();
            return;
          }
          if (section && section.type === 'image') {
            this.$store.dispatch('builderRemoveSection', { id });
            setCaretPos(prevInput, 'end');
            e.preventDefault();
            return;
          }
        }
      } else if (e.key === 'Escape') {
        if (selectedSectionsFingerprint) {
          this.$store.commit('METHOD_SECTIONS_SELECT', { start: null, end: null });
          this.$nextTick(() => {
            this.refreshMultitagVisibility();
          });
          e.preventDefault();
          return;
        }
      } else if (e.key === 'c' && isCmdKeyActive) {
        // Copy
        return;
      } else if (e.key === 'v' && isCmdKeyActive) {
        // Paste
        return;
      } else if (!['Meta', 'Control'].includes(e.key)) {
        this.prevKeyShortcut = null;
        this.$store.commit('METHOD_SECTIONS_SELECT', {
          start: null,
          end: null,
        });
      }
      if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
        if (caretPos === 0) {
          if (prevInput) {
            setCaretPos(prevInput, 'end');
            e.preventDefault();
            return;
          }
        }
      }
      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        if (caretPos >= value.length && !selectionLength) {
          if (nextInput) {
            setCaretPos(nextInput, 'start');
            e.preventDefault();
            return;
          }
        }
      }
      // Next actions are for only input-type elements
      if (!isEditable) return;
      if (e.key === 'Enter') {
        if (nextInput && $(target).parents('.builder-section__title').length) {
          setCaretPos(nextInput, 'start');
          e.preventDefault();
          return;
        }
        if (
          nextInput
        ) {
          const newInput = $(target).parents('.builder-new-input, .base-card-add');

          if (newInput && value) {
            return;
          }

          if ($(target).parents('.builder-paragraph')) {
            return;
          }

          setCaretPos(nextInput, 'end');
          e.preventDefault();
          return;
        }
      }
    },

    onTargetDragOver(e) {
      if (!this.editable) return;

      const { target } = e;
      target.classList.add('drop-target--active');
      e.preventDefault();
    },
    onTargetDragLeave(e) {
      if (!this.editable) return;

      const { target } = e;
      target.classList.remove('drop-target--active');
      e.preventDefault();
    },
    async onTargetDrop(e) {
      if (!this.editable) return;

      const { target } = e;
      const file = e.dataTransfer.items[0];
      const type = file.type.slice(0, file.type.indexOf('/'));

      target.classList.remove('drop-target--active');

      if (!['image', 'video'].includes(type)) {
        e.preventDefault();
        return;
      }

      const index = target.dataset.index;

      if (type === 'image') {
        const response = await this.$store.dispatch('builderCreateSection', {
          type: 'image',
          index,
          ignoreLocalInIndex: true,
          methodId: this.methodId,
        });

        this.$store.dispatch('builderAddSectionImage', {
          id: response.id,
          image: e.dataTransfer.files[0],
        });
      }

      if (type === 'video') {
        const response = await this.$store.dispatch('builderCreateSection', {
          type: 'custom_6',
          index,
          ignoreLocalInIndex: true,
          methodId: this.methodId,
        });

        this.$store.dispatch('builderAddSectionVideo', {
          id: response.id,
          file: e.dataTransfer.files[0],
        });
      }

      e.preventDefault();
    },
    async onTitleEnter(e) {
      this.$refs.title.blur();
      const data = {
        focus: 'start',
      };
      await this.$store.dispatch('builderCreateSection', {
        type: 'paragraph',
        new: false,
        data,
        index: 0,
        methodId: this.methodId,
      });

      e.preventDefault();
    },
    onTitleKeydown(e) {
      if (e.key === 'ArrowDown') {
        this.$refs.section[0].focus('start');
        e.preventDefault();
      }
    },
    onSelectTitle() {
      this.$refs.title.focus();
    },
    exportPDF() {
      window.print();
      analyticsLogEvent('Export PDF - Notepad');
    },
    multitagSelect(tag) {
      const { selectedSections } = this.$store.getters;

      this.$refs.section.forEach((section) => {
        let s = this.$store.getters.sectionById(section.$attrs['data-id']);
        if (s && (selectedSections.includes(s.id) || selectedSections.includes(s.localId))) {
          section.addTag(tag);
        }
      });
    },
    multitagUnselect(tag) {
      const { selectedSections } = this.$store.getters;

      this.$refs.section.forEach((section) => {
        let s = this.$store.getters.sectionById(section.$attrs['data-id']);
        if (s && selectedSections.includes(s.id)) {
          section.removeTag(tag);
        }
      });
    },
    onDragClone(evt) {
      const cloneEl = evt.clone;
      cloneEl.classList.add('drag-static-clone');
      requestAnimationFrame(() => {
        cloneEl.style.display = 'block';
      });
      this.questionClonedEl = cloneEl;
      cloneEl.vueDraggableIgnoreChildren = true;
    },
    changeColor(color) {
      this.$store.dispatch('builderMethodChange', {
        id: this.method.id,
        data: { color },
      });
    },
    changeIcon(icon) {
      this.$store.dispatch('builderMethodChange', {
        id: this.method.id,
        data: { icon },
      });
    },
    changeImage(image) {
      this.$store.dispatch('builderMethodChangeImage', {
        id: this.method.id,
        image,
      });
    },
    removeImage() {
      this.$store.dispatch('builderMethodRemoveImage', {
        id: this.method.id,
      });
    },
    generateSummary() {
      this.$store.dispatch('methodGenerateSummary', { id: this.method.id, prompt: this.summaryPrompt });
    },
    async addSummaryInsight(insight) {
      if (this.insightsLimitReached) {
        this.$store.dispatch('modalShow', {
          name: 'limit-reached',
          data: {
            kind: 'insights',
          },
        });

        return;
      }
      
      const { id } = await this.$store.dispatch('projectInsightAdd', { name: insight });

      EventsService.emit('insightAddAnimation');
    },
    isInsightCreated(insight) {
      return this.$store.getters.insights.find((i) => i.name === insight);
    },
    openSummaryInsight(text) {
      const insight = this.$store.getters.insights.find((i) => i.name === text);

      if (insight) {
        this.$store.dispatch('modalShow', {
          name: 'method',
          data: {
            id: insight.id,
          },
        });
      } else {
        this.addSummaryInsight(text);
      }
    },
    async createReportSection(index) {
      const newSection = await this.$store.dispatch('builderCreateSection', { type: 'report_3', data: { name: '' }, index, methodId: this.methodId, ignoreLocalInIndex: false });
      window.setTimeout(() => {
        const sectionEl = document.querySelector(`.section-wrap[data-id="${newSection.localId}"]`);
        if (sectionEl) {
          zenscroll.to(sectionEl, 200);
        }
      }, 100);
    },
  },
};
</script>
<style lang="scss">
.drop-target {
  $self: &;
  width: 100%;
  position: absolute;
  left: 0;
  height: 50%;
  opacity: 0;
  display: none;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 6px;
    background-color: #abc0c8;
    top: 0;
    left: 0;
    margin-top: -3px;
    border-radius: 2px;
  }

  &--top {
    top: 0;
  }

  &--bottom {
    top: 50%;

    &:before {
      top: 100%;
    }
  }

  &--active {
    opacity: 1;
  }
}

.drop-enabled .drop-target {
  display: block;
}

.drop-enabled {
  .base-uploader {
    z-index: 950;
  }
}

.drop-wrap {
  position: relative;
}

.drop-last {
  height: 340px;
  opacity: 0;
  position: relative;
  cursor: text;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 6px;
    background-color: #abc0c8;
    top: 0;
    left: 0;
    margin-top: -3px;
    border-radius: 2px;
  }

  &.drop-target--active {
    opacity: 1;
  }
}

.builder-content {
  cursor: text;
  min-height: calc(100vh - 154px);
}

.builder-list-wrap {
  cursor: default;
}

.section-wrap--paragraph + .section-wrap--paragraph[data-local='true'] {
  display: none;
}

.section-wrap--paragraph[data-local='true'] + .section-wrap--paragraph {
  margin-top: -42px;
}

.section-wrap--not-paragraph + .section-wrap--not-paragraph {
  margin-top: 42px;
}

.builder-dragging {
  .section-wrap--paragraph[data-local='true'] {
    opacity: 0;
  }
}
</style>
