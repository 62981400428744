const model = {
  type: '',
  name: '',
  archived: false,
};

export const parser = (raw) => ({
  ...model,
  ...raw,
});

export default model;
