<template>
  <div class="library">
    <div
      v-if="libraryCurrent"
      class="library__scroll"
    >
      <perfect-scrollbar
        class="sidebar__scroll"
      >
        <div v-if="pages.length">
          <div class="library__header">
            <div class="library-title">
              Library
              <BaseTooltip
                text="Close"
                placement="top"
              >
                <span
                  class="library-title__close"
                  @click="onClose"
                >
                  <Icon name="close" />
                </span>
              </BaseTooltip>
            </div>
            <TitleInput
              ref="title"
              :can-edit="true"
              appearance="lg"
              :value="page.name"
              class="library-name"
              :dropdown-menu="pagesDropdownMenu"
              :close-on-enter="true"
              :menu="[
                {
                  text: 'Edit',
                  action: () => $refs.title.edit(),
                },
                {
                  text: 'Delete',
                  action: () => {
                    removePage(page.id);
                  },
                },
              ]"
              menu-tooltip="Open menu"
              @change="changePageTitle"
            />
            <BaseSearch :value="search" @change="search = $event" />
          </div>
          <div class="library__body">
            <transition
              mode="out-in"
              name="fade"
            >
              <div :key="currentPage">
                <Sortable
                  group="library-section"
                  event-handler-class="library"
                  :can-drag-to="['method']"
                  :data="sections"
                  @dragend="onDragEnd"
                >
                  <div
                    v-for="section of sections"
                    :key="$store.getters.idJoin(section.id)"
                    :data-id="section.id"
                  >
                    <LibrarySection
                      ref="section"
                      :page="page"
                      :section="section"
                      :search="search"
                    />
                  </div>
                </Sortable>
              </div>
            </transition>
            <div
              v-if="searchIsEmpty"
              class="library-no-results"
            >
              <Icon
                size="auto"
                name="sticker-note-darken"
              />
              No results
            </div>
            <div
              v-if="!search"
              class="library-section-add"
              @click="addSection"
            >
              <BaseTooltip
                placement="top"
                text="Add section"
              >
                <button class="method-section-add" />
              </BaseTooltip>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="library__header">
            <div class="library-title">
              Library
              <span
                class="library-title__close"
                @click="onClose"
              >
                <Icon name="close" />
              </span>
            </div>
          </div>
          <div class="library-empty">
            <div class="library-empty__title">
              Add library page
            </div>
            <button
              class="btn btn--sm-outline"
              @click="addPage"
            >
              Create page
            </button>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <BaseSpinner
      v-else
      :full-height="true"
    />
  </div>
</template>

<script>
import { byPosition } from 'helpers/sort';
import { mapGetters } from 'vuex';

import LibrarySection from './section';

export default {
  name: 'Library',
  components: {
    LibrarySection,
  },
  data() {
    return {
      currentPage: '',
      search: '',
    };
  },
  computed: {
    ...mapGetters([
      'isLibraryOpen',
      'libraryCurrent',
      'libraryLastAddedPage',
      'projectCurrentMethod',
    ]),
    pages() {
      if (!this.libraryCurrent) return [];
      return this.libraryCurrent;
    },
    page() {
      return this.pages.find((p) => {
        return p.id === this.currentPage;
      }) || {};
    },
    pagesDropdownMenu() {
      return [ ...this.pages.map((p) => {
        return {
          text: p.name,
          appearance: p.id === this.currentPage && 'active',
          action: () => {
            this.selectPage(p.id);
          },
        };
      }), {
        appearance: 'add',
        tooltip: 'Add page',
        action: () => {
          this.addPage();
        },
      }];
    },
    sections() {
      return byPosition(this.page.sections);
    },
    searchIsEmpty() {
      if (!this.$refs.section) return !!this.search;
      return this.search && !this.$refs.section.filter((s) => s.isVisible).length;
    },
  },
  watch: {
    isLibraryOpen(open) {
      if (open) {
        this.selectFirstPage();
        document.addEventListener('keydown', this.closeOnEsc);
      } else {
        document.removeEventListener('keydown', this.closeOnEsc);
      }
    },
    libraryCurrent() {
      this.selectFirstPage();
    },
    libraryLastAddedPage(page) {
      this.currentPage = page;
    },
    projectCurrentMethod(method) {
      this.selectFirstPage();
    },
  },
  async created() {
    await this.fetchData();
    this.selectFirstPage();
  },
  methods: {
    closeOnEsc(e) {
      if (e.key === 'Escape') {
        this.onClose();
      }
    },
    selectFirstPage() {
      this.currentPage = this.pages.length && this.pages[0].id;
    },
    async onDragEnd({ index, dragData }) {
      const section = dragData[0];

      await this.$store.dispatch('moveLibrarySection', {
        pageId: this.page.id,
        id: section.id,
        index,
      });
    },
    async fetchData() {
      await this.$store.dispatch('fetchLibrary');
    },
    onClose() {
      this.$store.dispatch('toggleLibrary', false);
    },
    selectPage(id) {
      this.currentPage = id;
    },
    async addPage() {
      const data = {
        methodType: this.projectCurrentMethod && this.projectCurrentMethod.type,
      };
      const page = await this.$store.dispatch('addLibraryPage', { data });
    },
    async removePage(id) {
      const pages = this.pages.filter((p) => p.id !== id);
      if (pages.length) {
        this.currentPage = pages[0].id;
      } else {
        this.currentPage = null;
      }

      await this.$store.dispatch('removeLibraryPage', id);
    },
    async addSection() {
      const section = await this.$store.dispatch('addLibrarySection', { pageId: this.currentPage, data: { opened: true } });
    },
    async changePageTitle(name) {
      const page = await this.$store.dispatch('changeLibraryPage', {
        id: this.currentPage,
        data: {
          name,
        },
      });
    },
  },
};
</script>
