import * as ApiService from 'services/api.service';

class BuilderService {
  init(store) {
    this.$store = store;
  }

  async updateItem(workspaceId, projectId, methodId, id, data) {
    ApiService.put(`api/workspaces/${workspaceId}/projects/${projectId}/design_methods/${methodId}/custom_method_items/${id}`, data);
  }

  async addImage(workspaceId, projectId, methodId, id, { image, imageId }) {
    const data = new FormData();
    data.append('image', image);
    data.append('image_id', imageId);
    return await ApiService.post(`/api/workspaces/${workspaceId}/projects/${projectId}/design_methods/${methodId}/custom_method_items/${id}/set_image`, data);
  }

  async removeImage(workspaceId, projectId, methodId, id) {
    return await ApiService.del(`/api/workspaces/${workspaceId}/projects/${projectId}/design_methods/${methodId}/custom_method_items/${id}/remove_image`);
  }

  async addVideo(workspaceId, projectId, methodId, id, { video, videoId }) {
    const data = new FormData();
    data.append('image', video);
    data.append('image_id', videoId);
    return await ApiService.post(`/api/workspaces/${workspaceId}/projects/${projectId}/design_methods/${methodId}/custom_method_items/${id}/set_image`, data);
  }

  async addGalleryImage(workspaceId, projectId, methodId, id, { image, imageId, position }) {
    const data = new FormData();
    data.append('image', image);
    data.append('image_id', imageId);
    data.append('position', position);
    return await ApiService.post(`/api/workspaces/${workspaceId}/projects/${projectId}/design_methods/${methodId}/custom_method_items/${id}/add_gallery_image`, data);
  }

  async removeGalleryImage(workspaceId, projectId, methodId, id, imageId) {
    const data = { image_id: imageId };
    return await ApiService.del(`/api/workspaces/${workspaceId}/projects/${projectId}/design_methods/${methodId}/custom_method_items/${id}/remove_gallery_image`, data);
  }
}

export default new BuilderService();
