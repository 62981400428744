import * as ApiService from 'services/api.service';
import uuid from 'utils/id';

const MODEL = {
  id: '__temp',
  name: '',
  color: '#91cd19',
  items: [],
};
const parse = (raw) => ({
  ...MODEL,
  ...raw,
});

class TagService {
  async fetch(workspace, projectId, id) {
    const output = await ApiService.get(`${this.getUrl(workspace, projectId)}/${id}`).then(parse);
    return output;
  }

  getUrl(workspace, projectId) {
    return `api/workspaces/${workspace}/tags`;
  }

  get model() {
    return MODEL;
  }
}

export default new TagService();
