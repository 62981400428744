import { NEW_PRODUCT_RESEARCH } from 'constants/project-templates';
import { parser as projectParser } from 'models/project';
import * as ApiService from 'services/api.service';
import Store from 'stores';

class ProjectsService {
  constructor(store) {
    this.$store = store;
  }

  async fetchAll(workspace) {
    const data = await ApiService.get(this.url(workspace));
    return this.parse(data);
  }

  async fetch(workspace, id) {
    const data = await ApiService.get(`${this.url(workspace)}/${id}`);
    return projectParser(data);
  }

  add(workspace, template = null) {
    if (template === null) {
      return this.addNew(workspace);
    }

    return this.addNewFromTemplate(workspace, template);
  }

  async addNew(workspace, isIntro = false) {
    const params = {};
    if (isIntro) {
      params.name = 'Pinocchio (codename)';
    }

    const data = await ApiService.post(this.url(workspace), params);
    return projectParser(data);
  }

  async addNewFromData(workspace, params) {
    const data = await ApiService.post(this.url(workspace), params);
    return projectParser(data);
  }

  async addNewFromTemplate(workspace, id) {
    const data = await ApiService.post(
      this.url(workspace),
      {},
      {
        template: id,
      }
    );

    return projectParser(data);
  }

  remove(workspace, id) {
    return ApiService.del(`${this.url(workspace)}/${id}`);
  }

  async duplicate(workspace, id) {
    const data = await ApiService.post(`${this.url(workspace)}/${id}/clones`);
    return projectParser(data);
  }

  parse(projects) {
    return projects.map((project) => projectParser(project));
  }

  url(workspace) {
    return `api/workspaces/${workspace}/projects`;
  }
}

export default new ProjectsService(Store);
