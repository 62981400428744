<template>
  <div class="survey__header">
    <div class="theme__container">
      <a
        class="survey__theme"
        href="https://www.talebook.io"
      >
        powered by <span class="theme__talebook">talebook</span>
      </a>
    </div>
    <p
      v-if="isMethodNameShown"
      class="project__name"
    >
      {{ currentMethod.name }}
    </p>
    <div class="survey__saving">
      <SavingIndicator />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SavingIndicator from './saving';

export default {
  name: 'HeaderSurvey',
  components: {
    SavingIndicator,
  },
  data() {
    return {
      isMethodNameShown: false,
    };
  },
  created() {
    window.addEventListener('scroll', this.checkHeightOfPage);
  },
  unmounted() {
    window.removeEventListener('scroll', this.checkHeightOfPage);
  },
  computed: {
    ...mapGetters([
      'projectMethodById',
    ]),
    currentMethod() {
      if (!this.projectMethodById(this.$route.params.method_id)) {
        return '';
      }
      return this.projectMethodById(this.$route.params.method_id);
    },
  },
  methods: {
    checkHeightOfPage() {
      const header = document.querySelector('.header');
      const sticky = header.offsetTop;
      if (window.pageYOffset > sticky + 100) {
        this.isMethodNameShown = true;
      } else {
        this.isMethodNameShown = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .survey {
    &__header {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: auto;
    }
    &__theme {
      font-size: 16px;
      line-height: 1.5;
      color: #3b3b3b;
      margin: 0;
      text-decoration: none;
    }
    &__saving {
      position: absolute;
      right: 0;
      top: 50%;
      margin: 0;
      transform: translate(0,-50%);

      .header__submenu-saved {
        position: static;
      }
    }
  }
  .theme {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 173px;
      height: 34px;
      border-radius: 3px;
      background-color: #f3f6f8;
    }
    &__talebook {
      font-weight: 600;
    }
  }
  .project__name {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    transform: translate(-50%,-50%);
    color: #252729;
    line-height: 1.39;
    font-size: 18px;
  }
  @media only screen and (max-width: 479px) {
    .survey__header {
      margin-right: 0;
    }
    .project__name {
      display: none;
    }
    .theme__container {
      justify-content: center;
    }
  }
</style>
