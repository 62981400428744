<template>
  <Forbidden v-if="isProjectForbidden" />
  <div
    v-else-if="isTagsFetched"
    class="sidebar-page"
  >
    <div class="container">
      <div
        class="insights-container"
        data-aos="fade-in"
      >
        <TitleInput
          appearance="project-title"
          value="Insights"
          :can-edit="false"
        >
          <BaseButton
            appearance="primary sm nowrap uppercase"
            :icon="insightsLimitReached && 'lock'"
            @click="createInsight"
            v-if="userCanEditProjects"
          >
            New insight
          </BaseButton>
        </TitleInput>
        <HelpBanner name="insights" />
        <div class="wide-menu">
          <div class="grid grid--middle grid--between">
            <div class="grid-col" />
            <div class="grid-col">
              <div class="tag-filters">
                <!--
                <div class="filter">
                  <span class="filter__name">Type:</span>
                  <DropdownMenu
                    class="filter__value"
                    :menu="[
                      {
                        text: 'All',
                        action: () => setSortBy('all'),
                        selected: sortBy === 'all',
                      },
                      {
                        text: 'Tags',
                        action: () => setSortBy('tags'),
                        selected: sortBy === 'tags',
                      },
                      {
                        text: 'Pages',
                        action: () => setSortBy('methods'),
                        selected: sortBy === 'methods',
                      },
                    ]"
                  >
                    {{capitalize(filterType)}}
                  </DropdownMenu>
                </div>
                -->
                <div class="filter">
                  <span class="filter__name">Sort by:</span>
                  <DropdownMenu
                    class="filter__value"
                    :menu="[
                      {
                        text: 'Created',
                        action: () => setSortBy('created'),
                        selected: sortBy === 'created',
                      },
                      {
                        text: 'Tags',
                        action: () => setSortBy('tags'),
                        selected: sortBy === 'tags',
                      },
                    ]"
                  >
                    {{ capitalize(sortBy) }}
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="insightsFiltered && insightsFiltered.length"
          class="insight-items-list"
        >
          <InsightItem
            v-for="insight of insightsFiltered"
            :key="insight.id"
            :can-edit="userCanEditProjects"
            :insight-id="insight.id"
            @open="showInsight(insight.id)"
          />
        </div>
        <div
          v-else
          class="tag-empty"
        >
          <Icon
            class="tag-empty__icon"
            size="auto"
            name="art-competitoranalysis"
          />
          <div class="tag-empty__body">
            <strong class="tag-empty__heading">No Insights…yet!</strong>
            <div>Create your first Insight.</div>
            <div class="tag-empty__btn">
              <BaseButton
                appearance="primary sm nowrap uppercase"
                :icon="insightsLimitReached && 'lock'"
                @click="createInsight"
              >
                New insight
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Spinner v-else />
</template>

<script>
import Spinner from 'components/common/base-spinner';
import { getSentencesFromEditor } from 'components/common/editor';
import { PROJECT_TAG } from 'constants/routes';
import { TAG_COLORS } from 'constants/tag-colors';
import { navigateTags, navigateToMethod, navigateToTag } from 'helpers/router';
import { sortByParam } from 'helpers/sort';
import { capitalize } from 'helpers/string';
import { timeText } from 'helpers/time';
import sample from 'lodash/sample';
import { mapGetters } from 'vuex';

import Forbidden from 'components/forbidden';
import InsightItem from './item';

export default {
  name: 'ProjectTags',
  components: {
    Spinner,
    Forbidden,
    InsightItem,
  },
  data: () => ({
    newInput: '',
    filterTags: [],
    filterMethods: [],
    timeRange: '',
    searchText: '',
    showInsightId: '',
    sortBy: 'created',
    activeTooltip: '',
    filterType: 'all',
  }),
  computed: {
    ...mapGetters([
      'tags',
      'insights',
      'projectCurrent',
      'isTagsFetched',
      'userCanEditProjects',
      'isProjectForbidden',
      'insightsLimitReached',
    ]),
    hasHighlights() {
      return !!this.insights.length;
    },
    insightsFiltered() {
      const list = this.insights.filter((i) => !i.archived).map((h) => ({
        ...h,
      }));

      return sortByParam(list, this.sortBy);
    },
  },
  async created() {
    await this.fetchData();
    this.filterTags = [...this.tags];
  },
  methods: {
    async fetchData() {
      try {
        await Promise.all([
          this.$store.dispatch('projectFetch'),
          this.$store.dispatch('requireTags'),
        ]);

        if (!this.projectCurrent.accessible) {
          
        }
      } catch (error) {
      }
    },
    navigateTo(tagId) {
      return {
        name: PROJECT_TAG,
        params: {
          project_id: this.projectCurrent && this.projectCurrent.id,
          tag_id: tagId,
        },
      };
    },
    onModalHide() {
      navigateTags(this.projectCurrent.id);
    },
    async addTag() {
      const randomColor = sample(TAG_COLORS);
      const newTag = {
        name: this.newTagName,
        color: randomColor,
      };
      const created = await this.$store.dispatch('tagAdd', newTag);
      navigateToTag(created.id);
    },
    onInputEnter() {
      this.addTag(this.newInput);
    },
    openTag(id) {
      this.showTagId = id;
    },
    hideTagModal() {
      this.showTagId = '';
    },
    timeText,
    capitalize,
    navigateToMethod(projectId, methodId) {
      navigateToMethod(projectId, methodId);
    },
    setSortBy(sortBy) {
      this.sortBy = sortBy;
    },
    onTagSelect(highlight, { id }, selected) {
      let editor = this.$refs.editor.find((e) => e.$el.dataset.highlightId === highlight.id);

      if (!editor) return;

      let quill = editor.getQuill();

      if (!quill) return;

      const offset = highlight.position.match(/__(\d+)--(\d+)$/i);
      const sentenceSelection = [offset[1] * 1, offset[2] * 1];
      let index = sentenceSelection[0];
      let length = sentenceSelection[1] - sentenceSelection[0];

      quill.setSelection(index, length);
      quill.format('tag', { tag: id, selected });

      let content = editor.getValue();
      let contentTags = getSentencesFromEditor(quill);

      this.$store.dispatch('changeHighlight', { id: highlight.id, content, contentTags });
    },
    async createInsight() {
      if (this.insightsLimitReached) {
        this.$store.dispatch('modalShow', {
          name: 'limit-reached',
          data: {
            kind: 'insights',
          },
        });

        return;
      }
      
      this.$store.dispatch('modalShow', {
        name: 'method',
        data: {
          loading: true,
        },
      });

      const { id } = await this.$store.dispatch('projectInsightAdd');

      this.$store.dispatch('modalSetData', {
        data: {
          id,
        },
      });
    },
    showInsight(id) {
      this.$store.dispatch('modalShow', {
        name: 'method',
        data: {
          id,
        },
      });
    },
  },
};
</script>
