<template>
  <div
    ref="handler"
    class="base-uploader"
    :class="{
      'base-uploader--drag-over': isDragOver,
      'base-uploader--drag-over-active': dragOverActive && canEdit,
      [`base-uploader--theme-${theme}`]: !!theme,
      'base-uploader--cant-edit': !canEdit,
    }"
    :tabindex="canEdit && 1"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
    @keydown.enter="click"
  >
    <div
      v-if="modalVisible"
      class="base-uploader__modal-container"
      @click.self="hideModal"
    >
      <div
        class="base-uploader__modal-content"
        @click.self="hideModal"
      >
        <img
          :src="imageUrl"
          class="base-uploader__modal-img"
          @mousedown.prevent
        >
        <span
          class="base-uploader__modal-x"
          @click="hideModal"
        >×</span>
      </div>
    </div>

    <div class="base-uploader__container">
      <div
        v-show="isUploading"
        class="base-uploader__badge"
      >
        <span class="base-uploader__uploading" />
        <span v-if="theme === 'default'">Uploading...</span>
      </div>
      <div
        v-if="imageUrl"
        class="fancybox base-uploader__background base-uploader__background--filed"
        :href="imageUrl"
        @click="onImageClick"
      >
        <img
          class="base-uploader__image"
          :src="imageUrl"
          @mousedown.prevent
          @click="onImageClick"
        >
        <div
          v-if="!isUploading && canEdit"
          class="base-uploader__remove hide-print"
          @click="onRemove"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <rect
                width="24"
                height="24"
                fill="#000"
                opacity=".593"
                rx="2"
              />
              <g
                stroke="#FFF"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M7 8.4h11M16.778 8.4v8.4c0 .663-.547 1.2-1.222 1.2H9.444a1.211 1.211 0 0 1-1.222-1.2V8.4m1.834 0V7.2c0-.663.547-1.2 1.222-1.2h2.444c.675 0 1.222.537 1.222 1.2v1.2M11.278 11.4V15M13.722 11.4V15"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div
        v-else
        class="base-uploader__background base-uploader__background--empty"
        :class="{ 'base-uploader__background--empty--cant-edit': !canEdit }"
        :style="{
          backgroundColor: backgroundColor,
        }"
        :data-avatar="brandColor"
        @click.self="click"
      >
        <Icon
          v-if="defaultIcon"
          :name="defaultIcon"
        />
        <template v-if="canEdit">
          <input
            ref="input"
            type="file"
            style="display: none;"
            :accept="accept"
            @change="inputOnChange"
          >
          <svg
            v-if="theme === 'default'"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            class="base-uploader__ico hide-print"
          >
            <g
              fill="none"
              fill-rule="evenodd"
              :stroke="backgroundColor ? '#fff' : '#86909A'"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="translate(1 1)"
            >
              <path
                d="M17.427 28H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v16.727"
              />
              <circle
                cx="8.5"
                cy="8.5"
                r="2.5"
              />
              <path d="M28 18.727L20.187 11 3 28M27.5 23v9M23 27.5h9" />
            </g>
          </svg>
          <svg
            v-if="theme === 'compact'"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            class="hide-print"
          >
            <g
              fill="none"
              fill-rule="evenodd"
              stroke="#86909A"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="translate(1 1)"
            >
              <path
                d="M13.693 22H1.57A1.571 1.571 0 0 1 0 20.429V1.57C0 .704.704 0 1.571 0H20.43C21.296 0 22 .704 22 1.571v13.143"
              />
              <circle
                cx="7"
                cy="7"
                r="2"
              />
              <path d="M21 13.91L15.062 8 2 21M20.5 17v7M17 20.5h7" />
            </g>
          </svg>
          <div
            v-if="theme === 'circle'"
            class="base-uploader__circle hide-print"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="#FFF"
                stroke-linecap="round"
                stroke-linejoin="round"
                transform="translate(1 1)"
              >
                <path
                  d="M13.693 22H1.57A1.571 1.571 0 0 1 0 20.429V1.57C0 .704.704 0 1.571 0H20.43C21.296 0 22 .704 22 1.571v13.143"
                />
                <circle
                  cx="7"
                  cy="7"
                  r="2"
                />
                <path d="M21 13.91L15.062 8 2 21M20.5 17v7M17 20.5h7" />
              </g>
            </svg>
          </div>
          <div
            v-if="theme === 'logo'"
            class="base-uploader__logo-symbol"
          >
            {{ brandSymbol }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import '@fancyapps/fancybox';

import { FANCYBOX_DEFAULTS } from 'constants/config';
import { avatar, getColor } from 'helpers/avatar';
import NotificationsService from 'services/notifications.service';
import { mapGetters } from 'vuex';

export default {
  name: 'BaseUploader',
  emits: ['change'],
  components: {},
  props: {
    accept: {
      type: String,
      default: 'image/x-png,image/png,image/gif,image/jpeg,image/svg,image/gif,image/svg+xml',
    },
    canEdit: {
      type: Boolean,
    },
    uploading: {
      type: Boolean,
      required: false,
    },
    url: {
      type: String,
    },
    color: {
      type: String,
      default: '',
    },
    brandName: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'default',
    },
    file: {
      default: null,
    },
    defaultIcon: String,
  },
  data() {
    return {
      myBlob: false,
      modalVisible: false,
      isDragOver: false,
    };
  },
  computed: {
    ...mapGetters(['dragOverActive', 'isCardDragging']),
    imageUrl() {
      return this.url || this.myBlob || this.blob;
    },
    isUploading() {
      return !!(!this.url && this.myBlob) || this.uploading;
    },
    backgroundColor() {
      return this.url ? '' : (this.color || '');
    },
    brandColor() {
      return this.brandName && getColor(this.brandSymbol);
    },
    brandSymbol() {
      return this.brandName && avatar(this.brandName);
    },
  },
  watch: {
    file: {
      immediate: true,
      handler: function(blob) {
        if (!blob) return;
        const fr = new FileReader();
        fr.addEventListener('load', (e) => {
          this.myBlob = e.target.result;
        });
        fr.readAsDataURL(blob);
      },
    },
  },
  mounted() {
    jQuery().fancybox(FANCYBOX_DEFAULTS);
  },
  methods: {
    onImageClick(e) {
      if (this.isCardDragging) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        return;
      };
    },
    inputOnChange() {
      const fileSize = this.$refs.input.files[0].size;
      const fileType = this.$refs.input.files[0].type;
      const accept = this.accept.split(',');
      if (fileSize > 10000000) {
        NotificationsService.showError('Maximum upload file size is 10 MB');
        this.$refs.input.value = null;
        return;
      }
      if(!accept.includes(fileType)) {
        NotificationsService.showError('Please upload PNG, GIF, JPG/JPEG, or SVG');
        this.$refs.input.value = null;
        return;
      }
      this.$emit('change', this.$refs.input.files[0]);

      const fr = new FileReader();
      fr.addEventListener('load', (e) => {
        this.myBlob = e.target.result;
      });
      fr.readAsDataURL(this.$refs.input.files[0]);

      this.$refs.input.value = null;

      this.blur();
    },
    click() {
      this.$refs.input.click();
    },
    hideByEsc(e) {
      if (e.keyCode !== 27) return;
      this.hideModal();
    },
    hideModal() {
      window.removeEventListener('keyup', this.hideByEsc);
      document.body.style.overflow = 'unset';
      this.modalVisible = false;
    },
    showModal() {
      window.addEventListener('keyup', this.hideByEsc);
      document.body.style.overflow = 'hidden';
      this.modalVisible = true;
    },
    onDragOver(e) {
      if (!this.canEdit) return;
      this.isDragOver = true;
      e.preventDefault();

    },
    onDragLeave(e) {
      this.isDragOver = false;
      e.preventDefault();
    },
    onDrop(e) {
      if (!this.canEdit) return;

      this.isDragOver = false;

      const file = e.dataTransfer.items ? e.dataTransfer.items[0].getAsFile() : e.dataTransfer.files[0];
      const fileSize = file.size;
      const fileType = file.type;
      const accept = this.accept.split(',');

      if (fileSize > 10000000) {
        NotificationsService.showError('Maximum upload file size is 10 MB');
        this.$refs.input.value = null;
        return;
      }
      if(!accept.includes(fileType)) {
        NotificationsService.showError('Please upload PNG, GIF, JPG/JPEG, or SVG');
        this.$refs.input.value = null;
        return;
      }

      this.$emit('change', file);

      const fr = new FileReader();
      fr.addEventListener('load', (e) => {
        this.myBlob = e.target.result;
      });
      fr.readAsDataURL(file);

      e.preventDefault();
    },
    onRemove(e) {
      this.myBlob = null;
      if (this.$refs.input) {
        this.$refs.input.value = null;
      }
      this.$emit('remove');
      e && e.stopPropagation();
    },
    focus() {
      if (this.$refs.handler) {
        this.$refs.handler.focus();
      }
    },
    blur() {
      if (this.$refs.handler) {
        this.$refs.handler.blur();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.base-uploader {
  $self: &;
  $root: '.base-uploader__container';
  position: relative;
  border-radius: 4px;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    right: -4px;
    bottom: -4px;
    border: solid 2px transparent;
    transition: all 200ms;
    pointer-events: none;
    border-radius: 8px;
  }

  &--drag-over-active {
    &:after {
      border-color: #abc0c8;
    }
  }

  &--drag-over,
  &:focus {
    &:after {
      border-color: #0a9fa3;
    }
  }

  &__ico {
    width: 34px;
    height: 34px;
  }

  &__container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $color-grey-aqua;
    border-radius: 4px;
    overflow: hidden;
  }
  &__background {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;

    &--filed {
      cursor: zoom-in;
    }

    &--empty {
      cursor: pointer;
      transition: all 200ms;

      #{$self}--theme-circle & {
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.2);
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          transition: all 200ms;
          border-radius: 4px;
        }
        &:hover:before {
          opacity: 1;
        }
      }
    }
  }
  &__image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  &__modal-container {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
  }
  &__modal-content {
    margin: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 120px);
    min-height: calc(100vh - 120px);
  }
  &__modal-x {
    z-index: 9999;
    top: 15px;
    right: 45px;
    color: rgb(170, 170, 170);
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    position: fixed;
  }
  &__modal-img {
    max-width: 100%;
  }
  &__remove {
    position: absolute;
    top: 6px;
    right: 6px;
    opacity: 0;
    cursor: pointer;
    transition: all 200ms;
    display: flex;
    align-items: center;
    justify-content: center;

    #{$root}:hover & {
      opacity: 1;
    }
  }

  &--theme-default {
    #{$self}__badge {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 80;
      box-sizing: border-box;
      position: absolute;
      background-color: rgba(44, 44, 44, 0.8);
      padding: 5px 10px 4px 25px;
      border-radius: 3px;
      font-family: Lato;
      font-size: 10px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.1px;
      color: #ffffff;
    }
    #{$self}__uploading {
      &:before {
        content: '';
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 2px solid #86909A;
        border-top-color: #fff;
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        animation: spin 1s linear infinite;
        background: transparent;
      }
      &:after {
        display: none;
      }
    }
    #{$self}__background--empty {
      &:hover,
      #{$self}:focus & {
        background-color: #e6edf0;
      }

      &--cant-edit {
        cursor: default;
        background-color: transparent !important;
      }
    }
  }
  &--theme-circle,
  &--theme-compact {
    #{$self}__badge {
      top: 0px;
      left: 0px;
      z-index: 80;
      box-sizing: border-box;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #{$self}__uploading {
      &:before {
        content: '';
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 2px solid #86909A;
        border-top-color: #fff;
        display: block;
        animation: spin 1s linear infinite;
        background: transparent;
      }
      &:after {
        display: none;
      }
    }

    #{$self}__background--empty {
      &:hover,
      #{$self}:focus & {
        background-color: #e6edf0;
      }

      &--cant-edit {
        cursor: default;
        background-color: transparent !important;
      }
    }
  }

  &--theme-logo,
  &--theme-simple-logo {
    height: 109px;
    width: 109px;
    margin-left: auto;
    margin-right: auto;
    border: 1px dashed #DFE2E8;

    #{$self}__container {
      position: absolute;
      left: 6px;
      top: 6px;
      right: 6px;
      bottom: 6px;
      width: auto;
      height: auto;
    }

    #{$self}__remove {
      left: 50%;
      top: 50%;
      width: 24px;
      height: 24px;
      margin: -12px 0 0 -12px;
    }

    #{$self}__background {
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 200ms;
      }

      &:hover,
      #{$self}:focus & {
        &:before {
          opacity: 0.2;
        }
      }
    }

    &#{$self}--cant-edit #{$self}__background {
      background: transparent;

      &:hover {
        background: transparent;
      }

      &:before {
        display: none;
      }
    }

    #{$self}__background--empty {
      &:hover,
      #{$self}:focus & {
        background-color: #e6edf0;
      }

      &--cant-edit {
        cursor: default;
        background-color: transparent !important;
      }
    }
  }

  &--theme-simple-logo {
    border: 0;
    height: 1em;
    width: 1em;

    #{$self}__container {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
    }
  }

  &__circle {
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    position: relative;
  }

  &__logo-symbol {
    font-size: 42px;
    font-weight: 600;
  }
}
</style>
