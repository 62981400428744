import { byPosition } from 'helpers/sort';
import * as ApiService from 'services/api.service';

class PhasesSservice {
  async fetchAll(workspace, projectId) {
    const phases = await ApiService.get(this.getUrl(workspace, projectId));
    return byPosition(phases);
  }

  async add(workspace, projectId, phaseData) {
    const result = await ApiService.post(this.getUrl(workspace, projectId), phaseData);
    return result;
  }

  async update(workspace, projectId, phaseData) {
    const result = await ApiService.put(
      this.getUrl(workspace, projectId, phaseData.id),
      phaseData
    );
    return result;
  }

  async reposition(workspace, projectId, data) {
    const result = await ApiService.put(
      `api/workspaces/${workspace}/projects/${projectId}/reorder_phases`,
      data
    );
    return result;
  }

  async remove(workspace, projectId, phaseData) {
    const { id } = phaseData;
    const result = await ApiService.del(this.getUrl(workspace, projectId, phaseData.id), {
      id,
    });
    return result;
  }

  getUrl(workspace, projectId = null, phaseId = '') {
    return `api/workspaces/${workspace}/projects/${projectId}/phases/${phaseId}`;
  }
}

export default new PhasesSservice();
