<template>
  <div class="survey-container survey-container--name">
    <div class="survey-name-logo">
      <Icon name="logotype" />
    </div>

    <p class="survey-name-desc">
      Your greatness has been invited
      <span
        v-if="invitedBy"
      >by <strong>{{ invitedBy.name }}</strong></span>
      to complete {{ methodName }} in the project <strong>{{ projectName }}</strong>.
    </p>

    <div class="survey-name">
      <p class="modal__input-title">
        {{ welcomeText }}
      </p>
      <div class="modal__input-wrapper">
        <input
          v-if="!isRegistered"
          ref="input"
          v-model="userName"
          type="text"
          class="survey-input survey-input--gray"
          placeholder="Type your name here..."
          autofocus
          @keydown.enter="submit"
        >
        <input
          v-else
          ref="input"
          type="text"
          :value="user.name"
          class="survey-input survey-input--gray"
          disabled
          @keydown.enter="submit"
        >
      </div>
    </div>

    <p
      v-if="error"
      class="modal__input-desc error"
      v-html="error"
    />

    <div class="survey-btn-set">
      <button
        type="button"
        class="btn btn--primary btn--corners btn--md btn--block"
        :disabled="!canContinue"
        @click="submit"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import { SURVEY } from 'constants/modals';
import { mapGetters } from 'vuex';

export default {
  name: 'SurveyName',
  components: {
  },
  data: () => ({
    error: null,
    userName: '',
  }),
  computed: {
    ...mapGetters({
      method: 'projectCurrentMethod',
      project: 'projectCurrent',
      user: 'user',
      isRegistered: 'isRegistered',
    }),
    welcomeText() {
      if (this.isRegistered) {
        return 'Start survey';
      }
      return 'Enter your name to start survey';
    },
    modalActive() {
      return this.$store.getters.modalActive;
    },
    name() {
      return SURVEY;
    },
    invitedBy() {
      if (!this.method) return '';

      return this.method.owner;
    },
    methodName() {
      if (!this.method) return '';

      return this.method.name;
    },
    projectName() {
      if (!this.method) return '';

      return this.method.projectName;
    },

    isValid() {
      return this.userName.length > 2;
    },
    canContinue() {
      return this.userName || this.isRegistered;
    },
  },
  methods: {
    async submit() {
      if (this.isRegistered) {
        this.$emit('done');
        return;
      }

      if (!this.isValid) {
        this.error = 'Name cannot be empty!';
        return;
      }

      const result = await this.$store.dispatch('registerSurveyResponder', {
        name: this.userName,
      });

      this.$emit('done');
    },
  },
};
</script>

<style lang="scss" scoped>
.survey {
  &__theme {
    font-size: 16px;
    line-height: 1.5;
    color: #3b3b3b;
    margin: 0;
    text-decoration: none;
  }
}
.theme {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transform: translateY(-30px);
    width: 173px;
    height: 34px;
    border-radius: 3px;
    background-color: #f3f6f8;
  }
  &__talebook {
    font-weight: 600;
  }
}
@media only screen and (min-width: 479px) {
  .theme__container {
    display: none;
  }
}
</style>
