/* eslint-disable no-shadow */
import TutorialsService from 'services/tutorials.service';
import { merge } from 'utils/reactive';

const state = {
  data: null,
  config: null,
};

const getters = {
  tutorialsData: (state) => state.data,
  tutorialsConfig: (state) => state.config,
};

const actions = {
  init({ dispatch }) {
    dispatch('fetchTutorialsConfig');
  },
  loggedOut({ commit }) {
    commit('TUTORIALS_SET_DATA', null);
  },
  loggedIn({ dispatch }) {
    dispatch('fetchTutorials');
  },
  async fetchTutorials({ commit }) {
    const data = await TutorialsService.fetch();
    commit('TUTORIALS_SET_DATA', (data || {}));
  },
  async fetchTutorialsConfig({ commit }) {
    const data = await fetch('/static/tutorials/config.json')
      .then((response) => {
        if (!response.ok) {
          return response.json().then((e) => Promise.reject(e));
        }

        return response.json();
      });
    commit('TUTORIALS_SET_CONFIG', data);
  },
  async closeTutorial({ commit, getters }, name) {
    name = name.toLowerCase();
    await commit('TUTORIALS_CLOSE', name);
    TutorialsService.save({ tutorials: getters.tutorialsData });
  },
  async openTutorial({ commit, getters }, name) {
    if (!name) {
      name = getters.projectCurrentMethod.type;
    }
    name = name.toLowerCase();
    await commit('TUTORIALS_OPEN', name);
    TutorialsService.save({ tutorials: getters.tutorialsData });
  },
};

const mutations = {
  TUTORIALS_SET_DATA(state, data) {
    state.data = data;
  },
  TUTORIALS_SET_CONFIG(state, data) {
    state.config = data;
  },
  TUTORIALS_CLOSE(state, name) {
    state.data = { ...state.data, [name]: true };
  },
  TUTORIALS_OPEN(state, name) {
    state.data = { ...state.data, [name]: false };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
