import ActionCable from 'actioncable';
import { CABLE_URL } from 'config';
import BuilderService from 'services/method/builder.service';
import NotificationsService from 'services/notifications.service';
import ProjectMethodService from 'services/project-method.service';
import { objectFrom, objectTo } from 'utils/camel-case';
import generateId from 'utils/id';

const CONNECTION_ID = generateId();
let PERFORM_QUERY = [];
let REQUESTS_TIMEOUTS = {};
let connected = false;
let perform = null;
let cable = null;
let subscription = null;
let disconnectedTimeout = null;

const state = {
};

const getters = {
};

const actions = {
  userSet({ dispatch }) {
    dispatch('initSockets');
  },
  loggedOut() {
    subscription && subscription.unsubscribe();
    cable && cable.disconnect();
    cable = null;
    subscription = null;
  },
  workspaceIdChanged({ dispatch }, id) {
    dispatch('initSockets');
  },
  init({ dispatch }) {
    dispatch('initSockets');
  },
  async initSockets({ dispatch, commit, getters }) {
    await dispatch('requireWorkspaces');
    const { isLoggedIn } = getters;

    if (isLoggedIn) {
      PERFORM_QUERY = [];
      
      const { accessToken, workspaceCurrentId } = getters;

      if (!workspaceCurrentId) {
        return;
      }

      if (!cable) {
        cable = ActionCable.createConsumer(`${CABLE_URL}?token=${accessToken}`);
      }

      if (subscription) {
        subscription.unsubscribe();
      }

      perform = (action, data) => {
        dispatch('uiToggleSave', true);

        const actionId = JSON.stringify({
          id: generateId(),
          localId: data.localId,
          microtime: Date.now(),
          cid: CONNECTION_ID,
        });

        const queryObject = {
          action,
          data,
          actionId,
        };

        PERFORM_QUERY.push(queryObject);

        if (connected) {
          tryPerform();
        }
      };

      const resolveQuery = (actionId) => {
        for (let i = PERFORM_QUERY.length - 1; i >= 0; i--) {
          if (PERFORM_QUERY[i].actionId === actionId) {
            PERFORM_QUERY.splice(i, 1);

            if (REQUESTS_TIMEOUTS[actionId]) {
              window.clearTimeout(REQUESTS_TIMEOUTS[actionId]);
              delete REQUESTS_TIMEOUTS[actionId];
            }

            if (i === 0) {
              tryPerform(true);
            }
          }
        }

        if (!PERFORM_QUERY.length) {
          dispatch('uiToggleSave', false);
        }

        return JSON.parse(actionId);
      };

      const reduceQuery = () => {
        let current = null;
        let newQuery = [];
        let created = [];
        let toIgnore = [];

        // Nothing to do here
        if (PERFORM_QUERY.length <= 1) {
          return;
        }

        for (let query of PERFORM_QUERY) {
          if (!query.send) {
            if (query.action === 'custom_method/create_item') {
              created.push(query.data.id);
            }
          }
        }

        for (let query of PERFORM_QUERY) {
          if (!query.send) {
            if (query.action === 'custom_method/delete_item' && created.includes(query.data.id)) {
              toIgnore.push(query.data.id);
            }
          }
        }

        for (let query of PERFORM_QUERY) {
          if (query.send) {
            newQuery.push(query);
          } else if (toIgnore.includes(query.data.id)) {
            // Ignore if deleted before created
          } else if (
            current &&
            current.action === query.action &&
            current.data.id === query.data.id
          ) {
            Object.assign(current.data, query.data);
            current.actionId = query.actionId;
          } else {
            current = query;
            newQuery.push(current);
          }
        }

        PERFORM_QUERY = newQuery;
      };

      const tryPerform = async (force = false) => {
        reduceQuery();

        if (PERFORM_QUERY.length === 1 || (force && PERFORM_QUERY.length)) {
          let { action, data, actionId } = PERFORM_QUERY[0];

          // Solve local ID and server ID
          if (data.id) {
            data.id = getters.getIdByLocalId(data.id) || getters.idJoinRevPure(data.id) || data.id;
          }

          if (data.customMethodItemId) {
            data.customMethodItemId = getters.getIdByLocalId(data.customMethodItemId) || getters.idJoinRevPure(data.customMethodItemId) || data.customMethodItemId;
          }

          if (data.newOrder) {
            data.newOrder.forEach((item, i) => {
              item.id = getters.getIdByLocalId(item.id) || item.id;
            });
          }

          if (data.ids) {
            data.ids = data.ids.map((id) => {
              return getters.getIdByLocalId(id) || getters.idJoinRevPure(id) || id;
            });
          }

          PERFORM_QUERY[0].send = true;

          if (action === 'add_image') {
            const response = await BuilderService.addImage.call(
              window,
              data.workspaceCurrentId,
              data.projectCurrentId,
              data.methodId,
              data.id,
              data.image
            );
            commit('METHOD_SECTION_UPDATE', {
              id: data.id,
              data: {
                image: response.image,
              },
            });
            resolveQuery(actionId);
          } else if (action === 'add_video') {
            const response = await BuilderService.addVideo.call(
              window,
              data.workspaceCurrentId,
              data.projectCurrentId,
              data.methodId,
              data.id,
              data.video
            );
            commit('METHOD_SECTION_UPDATE', {
              id: data.id,
              data: {
                video: response.video,
              },
            });
            resolveQuery(actionId);
          } else if (action === 'update_method') {
            await ProjectMethodService.methodChange(
              data.workspaceCurrentId,
              data.projectCurrentId,
              {
                id: data.id,
                data,
              }
            );
            resolveQuery(actionId);
          } else if (action === 'update_method_image') {
            
            resolveQuery(actionId);
          } else if (action === 'update_page') {
            await ProjectMethodService.methodChange(
              data.workspaceCurrentId,
              data.projectCurrentId,
              {
                id: data.id,
                data,
              }
            );
            resolveQuery(actionId);
          } else {
            if (action === 'custom_method/update_item' && data.id === data.localId) {
              resolveQuery(actionId);
              return;
            }

            subscription.perform(
              'exec',
              objectFrom({
                command: action,
                ...data,
                actionId,
              })
            );

            REQUESTS_TIMEOUTS[actionId] = setTimeout(() => {
              NotificationsService.showConnectionError(
                'Something went wrong when saving data.'
              );
            }, 5000);
          }
        }
      };

      subscription = cable.subscriptions.create(
        {
          channel: 'WorkspacesChannel',
          uuid: generateId(),
          workspace_id: workspaceCurrentId,
          token: getters.accessToken,
        },
        {
          connected: async function() {
            connected = true;
            NotificationsService.hideConnectionError();

            if (disconnectedTimeout) {
              window.clearTimeout(disconnectedTimeout);
              disconnectedTimeout = null;

              try {
                await Promise.all([
                  dispatch('projectMethodFetch'),
                  dispatch('requireTags'),
                ]);
              } catch(e) {}

              dispatch('uiToggleSave', false);

              PERFORM_QUERY = [];

              tryPerform();
            }
          },
          disconnected: function() {
            connected = false;

            if (disconnectedTimeout) {
              window.clearTimeout(disconnectedTimeout);
              disconnectedTimeout = null;
            }

            disconnectedTimeout = window.setTimeout(() => {
              NotificationsService.showConnectionError(
                'Your Internet session was interrupted. We’ll try to reconnect you.'
              );
            }, 1000);
          },
          rejected: function() {
            connected = false;

            if (disconnectedTimeout) {
              window.clearTimeout(disconnectedTimeout);
              disconnectedTimeout = null;
            }

            disconnectedTimeout = window.setTimeout(() => {
              NotificationsService.showConnectionError(
                'Your Internet session was interrupted. We’ll try to reconnect you.'
              );
            }, 1000);
          },
          received: async function({ action, data, command, code, action_id }) {
            if (!getters.sectionById) return;

            // Update remote task
            if (action === 'remote_task') {
              commit('METHOD_UPDATE_REMOTE_TASK', { taskId: data.id, data: objectTo(data) })
              return;
            }

            if (code && !(code >= 200 && code < 400)) {
              NotificationsService.showConnectionError(
                'Something went wrong when saving data.'
              );
              // eslint-disable-next-line
              console.warn(`Cable: ${data}`);
              return;
            }

            let { localId, microtime, cid } = JSON.parse(action_id) || {};

            let section = getters.sectionById(localId) || getters.sectionById(data.id);
            let sectionItem = getters.sectionItemById(localId) || getters.sectionItemById(data.id);

            if (localId && data.id && localId !== data.id) {
              dispatch('registerIdJoin', { join: data.id, id: localId });
            }

            if (command === 'custom_method/create_item' && section && data.id && data.id !== section.id) {
              commit('METHOD_SECTION_UPDATE', {
                id: section.id,
                data: { id: data.id },
              });
            }

            if (command === 'custom_method/create_field' && sectionItem && data.id && data.id !== sectionItem.id) {
              dispatch('registerIdJoin', { join: data.id, id: sectionItem.id });
              commit('METHOD_SECTION_ITEM_UPDATE', {
                id: sectionItem.id,
                data: {
                  id: data.id,
                  page: data.page,
                  customMethodItemId: sectionItem.customMethodItemId,
                },
              });
            }

            if (cid === CONNECTION_ID) {
              resolveQuery(action_id);
              return;
            }

            if ((code >= 200 && code < 400) || !code) {
              data = (data && objectTo(data)) || {};
              data.microtime = microtime;
              data.connectionId = cid;

              dispatch('updateTagsIfNeeded', data);
              dispatch('updateRespondersIfNeeded', data);

              if (command === 'custom_method/delete_item') {
                if (section) {
                  await commit('METHOD_REMOVE_SECTION', { id: section.id });
                }
              }

              if (command === 'custom_method/create_item' || command === 'create_item') {
                if (section) {
                  await commit('METHOD_SECTION_UPDATE', {
                    id: section.id,
                    data,
                  });
                } else {
                  await commit('METHOD_ADD_SECTION', { section: data });
                }
              }

              if ((command === 'custom_method/update_item' || command === 'update_item' || action === 'custom_method_items/update_item') && section) {
                await commit('METHOD_SECTION_UPDATE', { id: section.id, data });
              }

              if (command === 'custom_method/reorder_item') {
                await commit('METHOD_SECTIONS_REORDER', { sections: data });
              }

              if (command === 'custom_method/delete_item_many') {
                data.forEach(({ id }) => {
                  commit('METHOD_REMOVE_SECTION', { id });
                });
              }

              if (command === 'custom_method/create_field') {
                if (sectionItem) {
                  await commit('METHOD_SECTION_ITEM_UPDATE', {
                    id: sectionItem.id,
                    data,
                  });
                } else {
                  await commit('METHOD_ADD_SECTION_ITEM', { data });
                }
              }

              if (command === 'custom_method/update_field' && sectionItem) {
                await commit('METHOD_SECTION_ITEM_UPDATE', {
                  id: sectionItem.id,
                  data,
                });
              }

              if (command === 'custom_method/reorder_fields') {
                await commit('METHOD_SECTION_ITEMS_REORDER', { fields: data });
              }

              if (command === 'custom_method/destroy_field') {
                await commit('METHOD_SECTION_ITEM_REMOVE', { id: data.id });
              }

              if (command === 'custom_method/reorder_gallery_items') {
                await commit('METHOD_SECTION_GALLERY_ITEMS_REORDER', {
                  items: data,
                });
              }

              if (action === 'custom_methods/update_design_method') {
                await dispatch('projectMethodChange', {
                  id: data.id,
                  data,
                  local: true,
                  disableHistory: true,
                });
              }

              if (data.designMethodId) {
                commit('ADD_PARAGRAPHS_BETWEEN_ITEMS', { id: data.designMethodId });
              }
            }

            // Try sending next action
            resolveQuery(action_id);
          },
        }
      );

      this.perform = perform;
    }
  },
  reconnectSockets({ dispatch }) {
    dispatch('initSockets');
    PERFORM_QUERY = [];
    dispatch('uiToggleSave', false);
  },
  async send({}, { action, data }) {
    this.perform(action, data);
  },
};

const mutations = {
 
};

export default {
  state,
  getters,
  actions,
  mutations,
};
