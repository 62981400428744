import { enable, remove, restart } from 'directives/aos';
import detectScroll from 'directives/detect-scroll-direction';
import detectScrollTarget from 'directives/detect-scroll-target';
import dropDisable from 'directives/drop-disable';
import stoppedTyping from 'directives/stopped-typing';

export default function registerDirectives(app) {
  app.directive('aos-enable', enable);
  app.directive('aos-remove', remove);
  app.directive('aos-restart', restart);
  app.directive('scroll-direction', detectScroll);
  app.directive('scroll-target', detectScrollTarget);
  app.directive('stopped-typing', stoppedTyping);
  app.directive('drop-disable', dropDisable);
}
