import { uniqueByKey } from 'helpers/array';
import { sortBy as arrSortBy } from 'helpers/sort';
import moment from 'moment';

export function stringIncludes(a, b) {
  const string = a.toLowerCase().trim();
  const pattern = b.toLowerCase();
  return string.includes(pattern);
}

export const sortAndFilterByProps = (list, {
  ids = [],
  tags = [],
  methods = [],
  projects = [],
  createdAtRange = { start: null, end: null },
  exclude,
  sortBy,
}) => {
  let output = uniqueByKey(list, (i) => i.id);
  
  output = output.filter((h) => {
    return !tags.length || h.tags.find((t) => {
      return tags.find((st) => {
        return st.id === t.id || st === t.id || st.id === t || st === t;
      })
    });
  })

  output = output.filter((h) => {
    return !ids.length || ids.find((id) => {
      return id === h.id;
    })
  })
  
  output = output.filter((h) => {
    return !methods.length || methods.find((m) => {
      return m.id === h.designMethodId || m === h.designMethodId;
    })
  })

  output = output.filter((h) => {
    return !projects.length || projects.find((m) => {
      return m.id === h.projectId || m === h.projectId;
    })
  })

  output = output.filter((h) => {
    const createdAtDate = new Date(h.createdAt);
    let createdAt = new Date();

    createdAt.setFullYear(createdAtDate.getFullYear());
    createdAt.setMonth(createdAtDate.getMonth());
    createdAt.setDate(createdAtDate.getDate());
    
    createdAt = createdAt.getTime();
    const { start, end } = createdAtRange;
    const from = start && moment(start).toDate().getTime();
    const to = end && moment(end).add(24 * 3600 - 1, 's').toDate().getTime();

    if (from && to) {
      return createdAt <= to && createdAt >= from; 
    }

    if (from) {
      return createdAt >= from; 
    }

    if (to) {
      return createdAt <= to;
    }

    return true;
  })
  
  output = output.map((h) => ({
    ...h,
    tags: h.tags && h.tags.sort(arrSortBy((t) => t.name && t.name.toLowerCase())),
  }))
  
  if (sortBy) {
    output = output.sort(arrSortBy((a) => {
      if (sortBy === 'tags') {
        return a.tags[0] && a.tags[0].name && a.tags[0].name.toLowerCase();
      }
      else if (sortBy === 'methods') {
        return (this.$store.getters.projectMethodById(a.designMethodId) || {}).name;
      }
      else if (sortBy === 'created' || sortBy === 'date_created') {
        return a.createdAt;
      }
      else if (sortBy === 'date_modified') {
        return a.updatedAt;
      }
      else if (sortBy === 'tags_count') {
        return a.tags && a.tags.length;
      }
  
      return a.createdAt;
    }));

    if (['date_created', 'created', 'modified', 'date_modified', 'tags_count'].includes(sortBy)) {
      output.reverse();
    }
  }

  if (exclude) {
    output = output.filter((h) => !exclude.includes(h.id))
  }

  return output;
}