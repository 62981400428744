<template>
  <div class="addon-image">
    <div class="sortable-handler addon-image__drag">
      <Icon name="drag" />
    </div>
    <BaseUploader
      ref="uploader"
      :uploading="image.uploading"
      :file="image.file"
      :url="image.url"
      :can-edit="canEdit"
      @remove="removeImage"
      @change="addImage"
    />
  </div>
</template>

<script>
export default {
  name: 'ProjectMethodCustom',
  components: {
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    image() {
      if (typeof this.section.image === 'string') {
        return { url: this.section.image };
      }
      return this.section.image || {};
    },
  },
  watch: {
    section: {
      handler(next, prev) {
        if (next.focus || next.focus === 0) {
          this.focus(next.focus);
        }
      },
      deep: true,
    },
  },
  created() {
  },
  methods: {
    focus() {
      this.$refs.uploader.focus();
    },
    addImage(image) {
      this.$store.dispatch('builderAddSectionImage', {
        id: this.section.id,
        image,
      });
    },
    removeImage(imageId) {
      this.$store.dispatch('builderRemoveSection', {
        id: this.section.id,
      });
    },
  },
};
</script>