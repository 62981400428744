<template>
  <div class="archiving-animation">
    <transition :duration="2000">
      <div
        v-if="isArchiving"
        :key="id"
        class="archiving-animation__item"
      >
        <MethodIcon
          v-if="isArchiving.type === 'DesignMethod'"
          :method="isArchiving.data"
        />
        <span
          v-else-if="isArchiving.type === 'Tag'"
          class="archiving-animation__tag"
          :style="{ 'background-color': color }"
        />
        <span
          v-else-if="isArchiving.type === 'Competitor' || isArchiving.type === 'Persona'"
          class="archiving-animation__avatar"
          :style="{ 'background-color': color }"
        >
          <img
            v-if="avatar"
            :src="avatar"
          >
        </span>
        <Icon
          v-else
          :name="icon"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderArchivingAnimation',
  components: {
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters([
      'isArchiving',
    ]),
    id() {
      return this.isArchiving && (
        (this.isArchiving.data && this.isArchiving.data.id) ||
        this.isArchiving.type
      );
    },
    icon() {
      switch(this.isArchiving.type) {
        case 'Card':
          return 'archiving-card';
          break;
        case 'Question':
          return 'archiving-question';
          break;
      }

      return 'archiving-other';
    },
    color() {
      if (this.isArchiving.type === 'Tag') {
        return this.isArchiving.data.color;
      }

      if (this.isArchiving.type === 'Competitor' || this.isArchiving.type === 'Persona') {
        return !this.avatar && this.isArchiving.data.color;
      }

      return '';
    },
    avatar() {
      if (this.isArchiving.type === 'Competitor' || this.isArchiving.type === 'Persona') {
        return this.isArchiving.data.avatar;
      }

      return '';
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
@keyframes archiving-animation-item {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
    transform: translateX(0px);
  }

  100% {
    opacity: 0;
    transform: translateX(-150px) scale(0.6);
  }
}

.archiving-animation {
  &__item {
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -10px;
    width: 21px;
    height: 21px;
    animation: archiving-animation-item 1200ms 1;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
    font-size: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tag {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  &__avatar {
    width: 21px;
    height: 21px;
    border-radius: 3px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }
  }
}
</style>
