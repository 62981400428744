import * as ApiService from 'services/api.service';

class TutorialsService {
  async fetch() {
    const output = await ApiService.get('api/users/tutorials');
    return output;
  }
  async save(data) {
    const output = await ApiService.post('api/users/tutorials', data);
    return output;
  }
}

export default new TutorialsService();
