import * as METHODS from 'constants/methods/data';
import { parser } from 'models/method';
import * as ApiService from 'services/api.service';

class MethodsService {
  async fetch() {
    const data = Object.values(METHODS);
    return this.parse(data);
  }

  async addInteractivePrototypeImage(workspace, projectId, id, image, image_id) {
    const data = new FormData();
    data.append('image', image);
    data.append('image_id', image_id);

    return ApiService.post(`api/workspaces/${workspace}/projects/${projectId}/design_methods/${id}/interactive_prototype_images`, data);
  }

  parse(methods) {
    return methods.map((item) => parser(item));
  }
}

export default new MethodsService();
