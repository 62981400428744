<template>
  <span
    class="header__submenu-saved"
    :class="classes"
  >
    {{ isSaving ? 'Saving...' : 'Changes saved' }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderSaving',
  computed: {
    ...mapGetters([
      'isSaving',
    ]),
    classes() {
      return {
        saving: this.isSaving,
      };
    },
  },
};
</script>
