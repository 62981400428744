<template>
  <BaseModal
    custom
    name="notification"
    :is-visible="isVisible"
    @hide="hideModal"
  >
    <div
      class="modal__content"
      @mousedown="stopPropagation"
    >
      <button
        type="button"
        class="modal__close-x modal__exit"
        @click="hideModal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="12"
          viewBox="0 0 14 12"
        ><path d="M13 0L1 12M1 0l12 12" /></svg>
      </button>
      <header class="modal__header">
        <h2 class="modal__question">
          Are you sure?
        </h2>
      </header>
      <p class="modal__warning">
        You're about to delete <span class="modal__item-deleted">{{ this.name }}</span> {{ this.whatIsDeleted }}. This cannot be undone
      </p>
      <div class="modal__buttons-container">
        <button
          type="button"
          class="btn__delete btn"
          @click.stop="removeItem"
        >
          Yes, Delete.
        </button>
        <button
          type="button"
          class="btn__cancel btn btn--white"
          @click="hideModal"
        >
          Cancel
        </button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
export default {
  name: 'NotificationModal',
  components: {
    BaseModal,
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    phase: {
      type: Object,
      required: false,
    },
    method: {
      type: Object,
      required: false,
    },
    section: {
      type: Object,
      required: false,
    },
    question: {
      type: Object,
      required: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
    },
  },
  computed: {
    name() {
      if (this.phase) {
        return this.setMaxCharacter(this.phase.name);
      }
      if (this.section) {
        return this.setMaxCharacter(this.section.content);
      }
      if (this.method) {
        return this.setMaxCharacter(this.method.name);
      }
      if (this.question) {
        return `"${ this.setMaxCharacter(this.question.content) }"`;
      }
      if (this.item) {
        return this.setMaxCharacter(this.item.name || this.item.content || '');
      }
      return '';
    },
    whatIsDeleted() {
      if (this.phase || this.type === 'Phase') {
        return 'section';
      }
      if (this.method || this.type === 'DesignMethod') {
        return 'page';
      }
      if (this.section || this.type === 'Section') {
        return 'section';
      }
      if (this.type === 'Competitor') {
        return 'competitor';
      }
      if (this.type === 'Persona') {
        return 'persona';
      }
      if (this.type === 'Card') {
        return 'card';
      }
      // if its question
      return 'and all related answers';
    },
    data() {
      if (this.phase) {
        return this.phase;
      }
      if (this.section) {
        return this.section;
      }
      if (this.method) {
        return this.method;
      }
      if (this.question) {
        return this.question;
      }
      return this.item;
    },
  },
  created() {
  },
  unmounted() {
  },
  methods: {
    setMaxCharacter(data) {
      if (data.length > 100) {
        const shortenedName = data.slice(0, 100);
        return `${ shortenedName }...`;
      }
      return data;
    },
    removeItem() {
      this.$emit('remove', this.data.id);
      this.hideModal();
    },
    hideModal() {
      this.$emit('hideModal');
    },
    hideUsingEscapeKey(e) {
      if (e.keyCode === 27) {
        this.hideModal();
      }
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
  },
};
</script>
<style lang="scss">
.notification-modal-wrapper {
  max-width: 560px;
  background-color: #fff;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 15px 18px 0 rgba(164, 164, 164, 0.06);
}
</style>
<style lang="scss" scoped>
.modal {
  &__content {
    position: relative;

    &:before,
    &:after {
      content: '';
      display: table;
      width: 100%;
    }
  }
  &__header {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  &__question {
    margin: 0;
    font-size: 24px;
  }
  &__warning {
    font-size: 16px;
    line-height: 1.5;
    color: #3b3b3b;
    text-align: center;
    padding: 0 93px 0 93px;
  }
  &__item-deleted {
    font-weight: bold;
  }
  &__buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 35px;
  }
}
.btn {
  width: 200px;
  font-size: 13px;
  cursor: pointer;
  &__delete {
    background-color: #d0021b;
    color: #ffffff;
    margin-right: 9px;
  }
  &__cancel {
    color: #414141;
    border: solid 1px rgba(8, 54, 87, 0.15);
    margin-left: 9px;
    transition: all 0.3s ease;
    &:hover,
    &.js-active {
      border-color: #86909a;
      color: #4b5761;
    }
  }
}
</style>
