<template>
  <div
    v-if="isReader || isLockedByLimit"
    class="view-only"
  >
    <BaseTooltip
      behaviour="hover"
      appearance="light-box"
      placement="bottom"
    >
      <button
        type="button"
        class="btn btn--grey no-hover"
        @mouseenter="onHover(true)"
        @mouseleave="onHover(false)"
      >
        <span>View Only</span>
      </button>
      <template v-slot:content>
        <div class="guest-popup">
          <p class="guest-popup__title">
            <template v-if="isLockedByLimit">
              This project is inactive
            </template>
            <template v-else>
              Observer mode
            </template>
          </p>
          <p class="guest-popup__desc">
            <template v-if="isLockedByLimit">
              Free account limit of 15 projects has been reached.
            </template>
            <template v-else>
              You can only view this page. To make<br>changes ask the owner for edit access.
            </template>
          </p>
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>

<script>
export default {
  name: 'ViewOnly',
  data: () => ({
    isModalVisible: false,
  }),
  computed: {
    isReader() {
      return !this.$store.getters.userCanEditProjects;
    },
    isLockedByLimit() {
      return this.$store.getters.isLockedByLimit(this.$store.getters.projectCurrentId);
    },
  },
  methods: {
    onHover(active) {
      this.isModalVisible = active;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  white-space: nowrap;
}
</style>
