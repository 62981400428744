<template>
  <Sortable
    v-bind="$attrs"
    :data-transport="transport"
    class="sortable-cards"
  >
    <slot />
  </Sortable>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SortableAnimatedList',
  components: {
  },
  props: {
    transport: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['isCardDragging']),
    transitionDisabled() {
      return this.isCardDragging;
    },
  },
  created() {
  },
  methods: {
  },
};
</script>
