<template>
  <div class="">
    <ScaleCards
      :question="section"
      :can-edit="canEdit"
      :survey-mode="surveyMode"
      @steps-change="onStepsChange"
      @min-label-change="onMinLabelChange"
      @max-label-change="onMaxLabelChange"
      @responderSelect="onResponderAdd"
      @responderUnselect="onResponderRemove"
      @select="select"
    />
  </div>
</template>

<script>
import { byPosition } from 'helpers/sort';

export default {
  name: 'ProjectMethodCustom',
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
    surveyMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      newTags: [],
    };
  },
  computed: {
    sortableCardsActions() {
      return [
        {
          text: 'Remove',
          action: 'remove',
        },
      ];
    },
    taggable() {
      return false;
    },
  },
  methods: {
    onAnswerAction({ action, card }) {
      if (action === 'remove' || action === 'archive') {
        this.$store.dispatch('builderRemoveSectionItem', { sectionId: this.section.id, id: card.id });
      }
    },
    select(value) {
      this.$store.dispatch('surveyAddAnswer', { id: this.section.id, value });
    },
    onChange({ id, newIndex, transport }) {
      this.$store.dispatch('builderMoveSectionItem', { sectionId: this.section.id, id, newSectionId: transport, newIndex });
    },
    onAnswerChange(id, data) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data });
    },
    isEmpty(data) {
      return !(data.customItemFields && data.customItemFields.length);
    },
    onStepsChange(steps) {
      this.$store.dispatch('builderUpdateSectionOptions', { id: this.section.id, options: {
        steps,
      } });
    },
    onMinLabelChange(minLabel) {
      this.$store.dispatch('builderUpdateSectionOptions', { id: this.section.id, options: {
        minLabel,
      } });
    },
    onMaxLabelChange(maxLabel) {
      this.$store.dispatch('builderUpdateSectionOptions', { id: this.section.id, options: {
        maxLabel,
      } });
    },
    onResponderAdd({ value, responder }) {
      this.$store.dispatch('builderAddResponderToAnswer', {
        id: this.section.id,
        responder,
        name: value,
      });
    },
    onResponderRemove({ value, responder }) {
      this.$store.dispatch('builderRemoveResponderFromAnswer', {
        id: this.section.id,
        responder,
        name: value,
      });
    },
  },
};
</script>
