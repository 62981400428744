import * as ApiService from 'services/api.service';

class WorkspacesService {
  init(store) {
    this.$store = store;
  }

  async fetchAll() {
    const data = await ApiService.get(this.getUrl());
    return data;
  }

  async create(data) {
    const response = await ApiService.post(this.getUrl(), data);
    return response;
  }

  async update(id, data) {
    const response = await ApiService.put(this.getUrl(id), data);
    return response;
  }

  async imageChange(workspace, image) {
    const data = new FormData();
    data.append('image', image);
    
    const result = await ApiService.put(`api/workspaces/${workspace}/`, data);
    return result;
  }

  async delete(id) {
    const response = await ApiService.del(this.getUrl(id));
    return response;
  }

  fetchTeam(workspace) {
    return ApiService.get(`api/workspaces/${workspace}/team_members/`);
  }

  async fetchInsights(workspaceId) {
    const data = await ApiService.get(`${this.getUrl(workspaceId)}/insights`);
    return data;
  }

  async fetchLimits(workspaceId) {
    const data = await ApiService.get(`${this.getUrl(workspaceId)}/limits`);
    return data;
  }

  fetchTags(workspace) {
    return ApiService.get(`api/workspaces/${workspace}/tags/`);
  }

  getUrl(id) {
    if (id) return `api/workspaces/${id}`;
    return 'api/workspaces';
  }

  updateMemberRights(workspaceId, {id, userId, rights}) {
    return ApiService.put(`api/workspaces/${workspaceId}/permissions/${id}/`, {
      permission: {
        userId,
        rights,
      },
    });
  }

  sendMemberInvitation(workspaceId, {email, rights}) {
    return ApiService.post(`api/workspaces/${workspaceId}/invitations/`, {
      invitation: {
        email,
        rights,
      },
    });
  }

  removeMember(workspaceId, {id, userId}) {
    return ApiService.del(`api/workspaces/${workspaceId}/team_members/${id}`, {
      teamMember: {
        userId,
      },
    });
  }
}

export default new WorkspacesService();
