<template>
  <div>
    <Sortable
      v-if="shouldShowCards"
      :data-transport="dataTransport"
      :value="sortedCards"
      helper="clone"
      class="sortable-cards"
      @dragend="onEnd"
    >
      <div
        v-for="card of sortedCards"
        :key="$store.getters.idJoin(card.id)"
        class="builder-draggable-field"
        :data-id="card.id"
      >
        <div
          v-if="!draggingDisabled"
          class="builder-draggable-field__drag sortable-handler"
          :style="{left:'-15px'}"
        >
          <Icon name="drag" />
        </div>
        <Card
          :data="card"
          :actions="actions"
          :breadcrumbs="breadcrumbs"
          :can-edit="canEdit"
          :empty-remove-text="emptyRemoveText"
          :data-transport="dataTransport"
          :survey-mode="surveyMode"
          :view-only="viewOnly"
          :side-menu="sideMenu"
          :card-tabindex="cardTabindex"
          :value-param="valueParam"
          :page-param="pageParam"
          :card-mode="cardMode"
          :show-tags="showTags"
          :show-responders="showResponders"
          :project-id="projectId"
          @toggle="allowDragAnswers = !$event"
          @action="onCardAction"
          @change="onCardChange(card.id, $event)"
          @tags-change="onCardTagsChange(card.id, $event)"
          @responders-change="onCardRespondersChange(card.id, $event)"
          @responderSelect="onCardResponderSelect(card, $event, true)"
          @responderUnselect="onCardResponderSelect(card, $event, false)"
        />
      </div>
    </Sortable>
    <CardAdd
      v-if="(editable && canAdd) || canAddBySurvey"
      :placeholder="addPlaceholder"
      :new-tags="newTags"
      :card-tabindex="cardTabindex"
      :can-edit-tags="editable && canAdd"
      :survey-mode="surveyMode"
      :show-tags="showTags"
      @add="onCardAdd"
      @tags-change="onAddTagsChange"
    />
  </div>
</template>
<script>
import { byPosition } from 'helpers/sort';
import { analyticsSetUserProp } from 'utils/analytics';
import { mapGetters } from 'vuex';

export default {
  name: 'SortableCards',
  emits: ['card-add', 'change', 'card-change', 'card-action', 'card-tags-change', 'card-responders-change', 'cardResponderSelect', 'cardResponderUnselect'],
  components: {
  },
  props: {
    value: Array,
    cards: Array,
    dataTransport: String,
    actions: {
      type: [Array],
      required: false,
    },
    canSort: {
      type: Boolean,
      required: false,
      default: true,
    },
    canAdd: {
      type: Boolean,
      required: false,
      default: true,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    breadcrumbs: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    addPlaceholder: {
      type: String,
      default: 'Add answer...',
    },
    emptyRemoveText: {
      required: false,
      default: 'archive',
    },
    newTags: {
      type: Array,
      default: () => [],
    },
    cardTabindex: {
      type: String,
      default: '0',
    },
    valueParam: {
      required: false,
      default: 'content',
    },
    pageParam: {
      required: false,
      default: 'page',
    },
    cardMode: {
      type: String,
      default: 'default',
    },
    surveyMode: {
      required: false,
      default: false,
    },
    showResponders: {
      required: false,
      default: false,
    },
    showTags: {
      required: false,
      default: true,
    },
    sideMenu: {
      required: false,
      default: false,
    },
    projectId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      allowDragAnswers: true,
      transitionEnabled: false,
    };
  },
  computed: {
    ...mapGetters(['userCanEditProjects', 'isCardDragging']),
    componentData() {
      return {
        props: {
          type: 'transition',
          name: 'enter-list',
        },
      };
    },
    editable() {
      return this.userCanEditProjects && this.canEdit;
    },
    draggingDisabled() {
      return !this.editable || !this.canSort || !this.allowDragAnswers;
    },
    draggableOptions() {
      return {
        disabled: this.draggingDisabled,
        dragClass: 'base-card--dragging',
        ghostClass: 'base-card--ghost',
        animation: 150,
        direction: 'vertical',
        fallbackOnBody: true,
        forceFallback: true,
        handle: '.builder-draggable-field__drag',
        touchStartThreshold: 3,
        fallbackTolerance: 2,
        delay: 200,
        delayOnTouchOnly: true,
        emptyInsertThreshold: 5,
        helper:'clone',
        group: {
          name: 'answers',
          pull: true,
        },
      };
    },
    sortedCards() {
      if (!this.canSort) return this.cards;
      return byPosition(this.cards).filter((c) => !c.archived);
    },
    transitionDisabled() {
      return !this.transitionEnabled || this.isCardDragging;
    },
    canAddBySurvey() {
      return this.surveyMode && this.cardMode === 'text';
    },
    shouldShowCards() {
      if (this.canAddBySurvey) return false;
      return true;
    },
  },
  watch: {
    cards(cards) {
      if (cards.length) {
        this.$nextTick(() => {
          this.transitionEnabled = true;
        });
      }
    },
  },
  methods: {
    async onEnd(evt) {
      const { index, ids, area } = evt;
      const transport = area.getAttribute('data-transport');
      const id = ids[0];

      await this.$emit('change',{
        index,
        id,
        transport,
      });
    },
    onCardChange(id, data) {
      this.$emit('card-change', id, data);
    },
    onCardAction(data) {
      this.$emit('card-action', data);
    },
    onCardTagsChange(id, data) {
      this.$emit('card-tags-change', id, data);
    },
    onCardRespondersChange(id, data) {
      this.$emit('card-responders-change', id, data);
    },
    onCardAdd(content) {
      if (!content.trim().length && !this.surveyMode) {
        return;
      }
      this.transitionEnabled = true;
      this.$emit('card-add', content);

      const projectCurrentMethod = this.$store.getters.projectCurrentMethod;
      analyticsSetUserProp(`Card add - ${ projectCurrentMethod && projectCurrentMethod.type }`);
    },
    onAddTagsChange(tags) {
      this.$emit('add-tags-change', tags);
    },
    onCardResponderSelect(card, responder, selected) {
      if (selected) {
        this.$emit('cardResponderSelect', { card, responder });
      } else {
        this.$emit('cardResponderUnselect', { card, responder });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sortable-cards {
  display: block;
  counter-reset: surveyCard;
}
.sortable-cards :global(.v-leave-active) {
  display: none !important;
}
</style>
