/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import TestersService from 'services/testers.service';
import UndoService from 'services/undo.service';
import generateId from 'utils/id';
import { merge } from 'utils/reactive';

const state = {
  testers: [],
};

const getters = {
  testers: (state) => state.testers,
  testersIds: (state) => state.testers.map(({ id }) => id),
  testersNames: (state) => state.testers.map(({ name }) => name),
  testerById: (state, getters) => (testerId) =>
    getters.testers.find(({ id }) => id === testerId) || null,
};

const actions = {
  async testersFetch({ dispatch, getters }) {
    const testers = await TestersService.fetch(getters.workspaceCurrentId, getters.projectCurrentId);
    dispatch('testersSet', testers);
  },
  testersSet({ commit }, data) {
    commit(TYPES.TESTERS_SET, data);
  },
  async testerAdd({ commit, getters }, data) {
    const tester = {
      id: generateId(),
      ...data,
    };
    commit(TYPES.TESTER_ADD, tester);

    const result = await TestersService.add(getters.workspaceCurrentId, getters.projectCurrentId, data);
    commit(TYPES.TESTER_UPDATE, { tester, data: result });
    return result;
  },
  async testerUpdate({ commit, getters }, { id, data }) {
    const tester = getters.testerById(id);
    if (!tester) throw new Error('No tester found!');

    commit(TYPES.TESTER_UPDATE, { tester, data });

    const result = await TestersService.update(
      getters.workspaceCurrentId,
      getters.projectCurrentId,
      id,
      data
    );
    return result;
  },
  testerRemove({ commit, getters }, id) {
    const tester = getters.testerById(id);

    UndoService.addWithNotification({
      text: 'Tester was removed',
      onResolve: () => TestersService.remove(getters.workspaceCurrentId, getters.projectCurrentId, id),
      onCancel: () => commit(TYPES.TESTER_ADD, tester),
    });

    commit(TYPES.TESTER_REMOVE, id);
  },
};

const mutations = {
  [TYPES.TESTERS_SET](state, data) {
    state.testers = data;
  },
  [TYPES.TESTER_ADD](state, data) {
    state.testers.push(data);
  },
  [TYPES.TESTER_UPDATE](state, { tester, data }) {
    merge(tester, data);
  },
  [TYPES.TESTER_REMOVE](state, testerId) {
    state.testers = state.testers.filter(({ id }) => id !== testerId);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
