<template>
  <div class="sidebar-tags__container">
    <h1 class="sidebar-tags__header">
      Information
    </h1>
    <dl class="sidebar-dl">
      <dt>Created</dt> <dd>{{ createdAtFormated }}</dd>
      <dt>Last modified</dt> <dd>{{ updatedAtFormated }}</dd>
    </dl>
  </div>
</template>

<script>
import { timeSinceText } from 'helpers/time';
export default {
  name: 'SidebarDetails',
  props: {
    data: {
      createdAt: String,
      updatedAt: String,
    },
  },
  computed: {
    createdAtFormated() {
      return this.formatDate(this.data.createdAt);
    },
    updatedAtFormated() {
      return this.formatDate(this.data.updatedAt);
    },
  },
  methods: {
    formatDate(date) {
      return timeSinceText(new Date(date));
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-dl {
  font-size: 1.4rem;
  color: #646464;
  text-align: right;

  dt {
    float: left;
    clear: left;
    text-align: left;
  }
  dd {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sidebar-tags {
  &__header {
    margin-top: 42px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.39;
    color: #252729;
  }
}
</style>
