<template>
  <div
    v-if="hasUser"
    class="header__user"
    :class="classes"
  >
    <template v-if="isRegistered">
      <button
        v-if="isTrialUser"
        type="button"
        class="btn btn--outline btn--hide header__upgrade-btn"
        @click="showUpgrade"
      >
        Upgrade
      </button>
      <div
        v-click-outside="hideMenu"
        class="header__user-avathar-menu"
        :class="menuClasses"
        @click="showMenu"
      >
        <DropdownMenu :menu="menu">
          <Avatar
            class="header__user-image"
            :url="user.avatarUrl"
            :email="user.email"
            :name="user.name"
            @click="showLogin"
          />
        </DropdownMenu>
      </div>
    </template>
    <template v-else>
      <span
        class="header__user-register"
        @click="showLogin"
      >
        Sign up / in
      </span>
    </template>
  </div>
</template>

<script>
import { PRICE_PLANS, PROJECT_METHODS } from 'constants/routes';
import { navigateSettings, navigateSignin } from 'helpers/router';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderUser',
  data: () => ({
    isMenuVisible: false,
    isGuestHover: false,
  }),
  computed: {
    ...mapGetters([
      'isRegistered',
      'isEditing',
      'hasUser',
      'user',
      'provider',
      'guestModalShown',
      'isTrialUser',
    ]),
    classes() {
      return {
        'is-hidden': this.isEditing && this.isProjectMethodsRoute,
      };
    },
    menu() {
      return [
        {
          text: this.user && this.user.email,
          disabled: true,
        },
        '-',
        {
          text: 'Settings',
          action: () => navigateSettings(),
        },
        {
          text: 'Sign out',
          action: () => {
            this.$store.dispatch('logOut');
            navigateSignin();
          },
        },
      ];
    },
    menuClasses() {
      return {
        'js-active': this.isMenuVisible,
      };
    },
    providerClasses() {
      return {
        facebook: this.provider === 'facebook',
        google: this.provider === 'google',
        twitter: this.provider === 'twitter',
      };
    },
    isProjectMethodsRoute() {
      return this.$store.getters.isRoute(PROJECT_METHODS);
    },
    isGuestModalVisible() {
      return this.isGuestHover || !this.guestModalShown;
    },
  },
  methods: {
    navigateSettings,
    showMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    hideMenu() {
      this.isMenuVisible = false;
    },
    onGuestHover(isOver) {
      this.isGuestHover = isOver;
    },

    showLogin() {
      if (this.isRegistered) return;

      navigateSignin();
    },

    showUpgrade() {
      this.$router.push({ name: PRICE_PLANS });
    },

    logout() {
      navigateSignin();
      this.$store.dispatch('logOut');

      this.isMenuVisible = false;
    },
  },
};
</script>
