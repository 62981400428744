<template>
  <div
    v-if="show"
    class="highlights-slider"
  >
    <div class="highlights-slider__avatar">
      <img src="/static/img/sample-avatar-1.svg">
    </div>
    <div
      v-if="showNav"
      class="highlights-slider__nav"
    >
      <div
        class="highlights-slider__nav-btn"
        @click="prev"
      >
        <Icon name="chevron-left" />
      </div>
      <div
        class="highlights-slider__nav-btn"
        @click="next"
      >
        <Icon
          name="chevron-left"
          appearance="flip-x"
        />
      </div>
    </div>
    <Transition
      mode="out-in"
      name="slide-up"
    >
      <div
        v-if="current"
        :key="current.id"
        class="highlights-slider__quote"
      >
        “{{ current.text }}”
      </div>
    </Transition>
  </div>
</template>

<script>
import { truncate } from 'helpers/string';
import { mapGetters } from 'vuex';

export default {
  name: 'HighlightsSlider',
  props: {
    ids: {
      type: Array,
    },
    sentences: {
      type: Array,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['highlights']),
    list() {
      if (this.sentences) {
        return this.sentences;
      }

      return this.highlights.filter((highlight) => this.ids.includes(highlight.id)).map((h) => ({
        ...h,
        content: truncate(h.content, 100),
      }));
    },
    current() {
      return this.list[this.currentIndex];
    },
    showNav() {
      return this.list.length > 1;
    },
    show() {
      return this.list.length;
    },
  },
  methods: {
    prev() {
      this.currentIndex--;

      if (this.currentIndex < 0) {
        this.currentIndex = this.list.length - 1;
      }
    },
    next() {
      this.currentIndex++;

      if (this.currentIndex > this.list.length - 1) {
        this.currentIndex = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .highlights-slider {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__quote {
      position: absolute;
      left: 60px;
      bottom: 70px;
      right: 0;
      background-color: #fff;
      border: 1px solid #E1D6DA;
      box-shadow: 0px 24px 94px rgba(0, 0, 0, 0.05);
      padding: 18px 22px;
      font-size: 14px;
    }

    &__avatar {
      width: 87px;
      height: 87px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__nav {
      border-radius: 4px;
      overflow: hidden;
      display: flex;
    }

    &__nav-btn {
      width: 45px;
      height: 42px;
      background-color: #171717;
      flex-shrink: 0;
      flex-grow: 0;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>