import { ERROR, INFO, LOADING, SUCCESS, UNDO, WARNING } from 'constants/notifications';
import EventsService, { EVENTS } from 'services/events.service';

class NotificationsService {
  init(store) {
    this.$store = store;
  }

  show(data) {
    this.$store.dispatch('notificationShow', data);
  }

  showUndo(data) {
    this.show({
      ...data,
      type: UNDO,
    });
  }

  showInfo(text) {
    this.show({
      text,
      type: INFO,
    });
  }

  showError(text, duration) {
    this.show({
      text,
      type: ERROR,
      duration,
    });
  }

  showSuccess(text) {
    this.show({
      text,
      type: SUCCESS,
    });
  }

  showWarning(text) {
    this.show({
      text,
      type: WARNING,
    });
  }

  showLoading(text, duration) {
    this.show({
      text,
      type: LOADING,
      duration,
    });
  }

  onUndo(id) {
    EventsService.emit(EVENTS.NOTIFICATION_UNDO, id);
  }

  onHide(id) {
    EventsService.emit(EVENTS.NOTIFICATION_HIDE, id);
  }

  showConnectionError(text) {
    this.$store.dispatch('connectionNotificationShow', { text });
  }

  hideConnectionError() {
    this.$store.dispatch('connectionNotificationHide');
  }
}

export default new NotificationsService();
