export const ICONS_PACK = [
  'method-competitor-analysis',
  'method-flows-method',
  'method-interactive-prototype',
  'method-notepad-method',
  'method-personas-method',
  'method-solution-valuation',
  'method-stakeholder-interview',
  'method-user-interview',
];
