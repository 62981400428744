<template>
  <div class="onb-step">
    <form class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          <template v-if="userData && userData.status === 'pending'">
            Activate an account
          </template>
          <template v-if="isRegistered">
            Type your password
          </template>
          <template v-else>
            Set your password
          </template>
        </h2>
      </div>
      <CustomInput
        v-model="password"
        name="password"
        type="password"
        placeholder="Enter your password here"
        input-class="input input--new-light input--center"
        :validate="(value) => {
          return value.length >= 8;
        }"
        autofocus
        @keydown.enter.prevent="next"
      />
      <p v-if="!isRegistered">
        Password should be at least <strong>8 characters long</strong>
      </p>
      <p v-else>
        <a
          class="link link--dark"
          @click="resetPassword"
        >I don't remember my password</a>
      </p>
    </form>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';
import MailLoginService from 'services/mail-login.service';
import { analyticsLogEvent } from 'utils/analytics';

export default {
  name: 'SignInPassword',
  props: {
    data: Object,
  },
  data() {
    return {
      password: '',
    };
  },
  computed: {
    email() {
      return this.data.email;
    },
    userData() {
      return this.data.userData;
    },
    isRegistered() {
      return this.userData && this.userData.status === 'registered'
    },
  },
  watch: {
    password() {
      this.updateConfig();
    },
  },
  created() {
    this.updateConfig();
  },
  methods: {
    getConfig() {
      const enabled = (this.userData && this.userData.status === 'pending') || (this.password && this.password.length >= 8);

      return {
        avatar: 'email',
        avatarName: this.email,
        buttons: [
          {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Continue',
            disabled: !enabled,
            loading: this.loading,
          },
          this.isRegistered && {
            appearance: 'outline light',
            id: 'magic-link',
            text: 'Send a magic link',
            icon: 'magic-link',
          },
          {
            appearance: 'light',
            id: 'secondary',
            text: 'Back',
          },
        ],
      };
    },
    buttonAction(id) {
      switch(id) {
        case 'primary':
          this.next();
          return;
        case 'secondary':
          this.back();
          return;
        case 'magic-link':
          this.sendMagicLink();
          return;
      }
    },
    async next() {
      if (this.loading) {
        return;
      }

      if (this.userData && this.userData.status === 'pending') {
        return this.resetPassword();
      }

      this.loading = true;
      this.updateConfig();

      const body = {
        email: this.email,
        password: this.password,
      };

      let tokens;
      const { userData } = this;

      try {
        if (userData && userData.status === 'registered') {
          tokens = await MailLoginService.login(body);
          analyticsLogEvent('sign_in_login_email');
        } else if (userData && userData.status === 'pending') {
          tokens = await MailLoginService.updateUser(body);
        } else {
          tokens = await MailLoginService.createUser(body);
          analyticsLogEvent('sign_in_create_user');
        }

        await this.$store.dispatch('tokensSet', {
          providerName: 'email',
          ...tokens,
        });
      } catch (error) {
        analyticsLogEvent('sign_in_password_error');
        this.$emit('shake');
        this.loading = false;
      }

      this.updateConfig();
    },
    back() {
      this.$emit('redirect', {
        route: ROUTES.SIGN_IN_EMAIL,
        data: this.data,
      })
      analyticsLogEvent('sign_in_back_to_email');
    },
    resetPassword() {
      MailLoginService.resetPasswordRequest({ email: this.email });
      this.$store.dispatch('modalShow', {
        name: 'reset-password-request',
        data: {
          email: this.email,
        },
      });
      analyticsLogEvent('sign_in_reset_password');
    },
    sendMagicLink() {
      MailLoginService.sendMagicLink({ email: this.email });
      this.$store.dispatch('modalShow', {
        name: 'magic-link',
        data: {
          email: this.email,
        },
      });
      analyticsLogEvent('sign_in_send_magic_link');
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
  },
};
</script>
