let supported = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get() {
      supported = true;
      return null;
    },
  });
  window.addEventListener('test', null, opts);
  // eslint-disable-next-line no-empty
} catch (e) {}

const PASSIVE_SUPPORT = supported ? { passive: true } : false;
export default PASSIVE_SUPPORT;
