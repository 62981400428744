<template>
  <DropdownMenu
    :menu="menu"
    appearance="light-box-workspace-selector"
  >
    <div
      class="header-logo"
      :class="logoClasses"
    >
      <PageAvatar
        :page="workspaceCurrent"
      />
      <span class="header-logo__name">{{ workspaceCurrentName }}</span>
      <icon name="chevron-expand" class="header-logo__icon" />
    </div>
  </DropdownMenu>
</template>

<script>
import { LOGIN } from 'constants/modals';
import { PROJECT_REPORT, WORKSPACE_RECENT } from 'constants/routes';
import { navigateOnboarding, navigateProjects } from 'helpers/router';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderUser',
  components: {
  },
  data: () => ({
    isMenuVisible: false,
    isGuestHover: false,
  }),
  computed: {
    ...mapGetters([
      'workspaces',
      'workspaceCurrent',
      'workspaceCurrentName',
      'workspaceCurrentId',
      'hasUser',
      'userCanEditProjects',
      'isShared',
      'isSharedLoading',
      'shareEntry',
      'routeName',
      'projectCurrent',
      'detailsPageTitle',
      'current',
      'projectCurrentMethod',
      'isEditing',
      'isLibraryOpen',
    ]),
    logoClasses() {
      return {
        small: true,
      };
    },
    logoRoute() {
      if (this.isShared && this.shareEntry) {
        return this.shareEntry;
      }

      return { name: WORKSPACE_RECENT };
    },
    customLogo() {
      return this.isProjectReportRoute && this.isShared && this.projectCurrent && this.projectCurrent.image;
    },
    isProjectReportRoute() {
      return this.$store.getters.isRoute(PROJECT_REPORT);
    },
    menu() {
      return this.workspaces && [ ...this.workspaces.map((workspace) => ({
        appearance: 'box-icon',
        icon: 'page-avatar',
        page: workspace,
        text: workspace.name,
        selected: workspace.id === this.workspaceCurrentId,
        action: () => {
          this.$store.dispatch('workspaceSelect', { id: workspace.id })
          navigateProjects();
        },
      })), '-', {
        text: 'Create workspace',
        appearance: 'box-icon',
        icon: 'plus-12',
        action: () => {
          navigateOnboarding();
        },
      } ];
    },
  },
  methods: {
  },
};
</script>
