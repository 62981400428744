<template>
  <BaseModal
    name="limit-reached"
    :show-close="true"
    @active="active = $event"
    @data="data = $event"
  >
    <div class="limit-reached-modal">
      <Icon name="insight-fill" class="limit-reached-modal__icon" />
      <h2 class="limit-reached-modal__title">{{ title }}</h2>
      <p>{{ text }}</p>
      <button class="btn btn--md btn--primary btn--block limit-reached-modal__upgrade" @click="navigateUpgrade">Upgrade</button>
    </div>
  </BaseModal>
</template>

<script>
import { navigateUpgrade } from 'helpers/router';
import BaseModal from 'modals/base';

export default {
  name: 'LimitReachedModal',
  components: {
    BaseModal,
  },
  props: {

  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    title() {
      switch (this.data.kind) {
        case 'project':
          return 'Project is locked';
        case 'projects':
          return 'Upgrade to add more projects';
        case 'insights':
          return 'Upgrade to add unlimited insights';
      }

      return 'Limit reached';
    },
    text() {
      switch (this.data.kind) {
        case 'project':
          return 'You’ve reached the free account limit of 3 projects';
        case 'projects':
          return 'You’ve reached the free account limit of 3 projects';
        case 'insights':
          return 'You’ve reached the free account limit of 15 insights';
      }

      return 'You’ve reached the free account limit';
    },
  },
  mounted() {
  },
  methods: {
    navigateUpgrade() {
      navigateUpgrade();
      this.$store.dispatch('modalHide');
    },
  },
};
</script>
