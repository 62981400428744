<template>
  <div
    v-if="!renderContentOnly"
    ref="base"
    v-click-outside="clickOutside"
    class="tooltip"
    :class="classNames"
    @click="buttonClick"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div
      ref="tip"
      class="tooltip__tip-position"
      @click="preventEvents"
      @mousedown="preventEvents"
    >
      <transition name="slide-up">
        <div
          v-if="isVisible"
          class="tooltip__tip"
        >
          <div
            v-if="title"
            class="tooltip__tip-title tooltip-title"
          >
            {{ title }}
          </div>
          <div
            v-if="text"
            class="tooltip__tip-content"
          >
            {{ text }}
          </div>
          <slot
            v-else
            name="content"
          />
          <div
            ref="arrow"
            class="tooltip__arrow"
          />
        </div>
      </transition>
    </div>
    <div
      class="tooltip__trigger"
      @mousedown="buttonDownPrevent"
    >
      <slot />
    </div>
  </div>
  <div v-else>
    <slot name="content" />
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

let currentOpen = null;

export default {
  name: 'BaseTooltip',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    behaviour: {
      type: String,
      required: false,
      default: 'hover',
    },
    placement: {
      type: [String, Array],
      required: false,
      default: 'bottom',
    },
    text: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    appearance: {
      type: String,
      required: false,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    renderContentOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    hasContent() {
      return this.$slots.content;
    },
    classNames() {
      return {
        [`tooltip--${ this.appearance }`]: !!this.appearance,
        ['tooltip--click']: this.behaviour.includes('click'),
        ['tooltip--no-behaviour']: this.behaviour.includes('none'),
        ['tooltip--visible']: this.isVisible,
      };
    },
  },
  watch: {
    visible(newValue, prevValue) {
      if (newValue !== prevValue && (newValue === true || newValue === false)) {
        this.isVisible = newValue;
      }
    },
    isVisible(newValue, prevValue) {
      if (newValue !== prevValue) {
        this.$emit('toggle', newValue);
      }
      if (newValue && this.behaviour.includes('click')) {
        if (currentOpen && currentOpen !== this) {
          currentOpen.hide();
        }
        currentOpen = this;
      }
      this.$nextTick(() => {
        if (newValue) this.refreshPosition();
      });
    },
  },
  created() {
    document.addEventListener('keyup', this.hideUsingEscapeKey);
  },
  unmounted() {
    document.removeEventListener('keyup', this.hideUsingEscapeKey);
  },
  beforeUnmount() {
    if (!this.$refs.tip) return;
    const style = this.$refs.tip.style;
    const left = style.left;
    const right = style.right;
    const transform = style.transform;
    const position = style.position;
    this.popper && this.popper.destroy();
    style.left = left;
    style.right = right;
    style.transform = transform;
    style.position = position;
  },
  methods: {
    buttonClick(event) {
      if (!this.disabled && this.behaviour.includes('click')) {
        event.stopPropagation();
        event.preventDefault();
        this.isVisible = !this.isVisible || this.visible;
        this.$emit('toggle', this.isVisible);
      }
    },
    buttonDownPrevent(e) {
      if (!this.disabled && this.behaviour.includes('click')) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    open() {
      if (this.isVisible) return;
      this.isVisible = true;
      this.$emit('toggle', this.isVisible);
    },
    getPopperModifiers() {
      let placement = this.placement.split(/\W*\,\W*/gim);
      const modifiers = [];

      modifiers.push({
        name: 'preventOverflow',
        options: {
          mainAxis: true,
          altAxis: true,
          padding: 10,
        },
      });

      // modifiers.push({
      //   name: 'arrow',
      //   options: {
      //     element: this.$refs.arrow,
      //   },
      // });

      modifiers.push({
        name: 'offset',
        options: {
          offset: ({ popper, reference, placement }) => [10, 10],
        },
      });

      return modifiers;
    },
    refreshPosition() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }

      const base = this.$refs.base;
      const tip = this.$refs.tip;

      if (!base || !tip) return;

      let placement = this.placement.split(/\W*\,\W*/gim);
      const modifiers = this.getPopperModifiers();

      placement = placement[0];

      const popper = createPopper(base, tip, {
        placement,
        modifiers,
        positionFixed: false,
      });

      this.popper = popper;
    },
    mouseEnter() {
      if (!this.disabled && this.behaviour.includes('hover')) {
        this.isVisible = true;
      }
    },
    mouseLeave() {
      if (this.behaviour.includes('hover')) {
        this.isVisible = !!this.visible;
      }
    },
    clickOutside() {
      if (this.behaviour.includes('click')) {
        this.isVisible = !!this.visible;
      }
      if (this.behaviour.includes('closeoutside')) {
        this.isVisible = !!this.visible;
      }
    },
    hide() {
      this.isVisible = false;
    },
    hideUsingEscapeKey(e) {
      if (e.keyCode === 27) {
        this.hide();
      }
    },
    preventEvents(e) {
      if (this.behaviour.includes('click') || this.behaviour.includes('none')) {
        e.stopPropagation();
      }
    },
  },
};
</script>
