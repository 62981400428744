<template>
  <BaseModal
    name="method"
    :show-close="true"
    @active="active = $event"
    @data="data = $event"
  >
    <div
      v-if="method && fetched"
      class="method-modal-grid"
    >
      <div class="method-modal-grid__main">
        <div class="method-modal-grid__body">
          <ul class="method-modal-breadcrumbs">
            <li v-if="projectName">
              <a @click="navigateProject">
                {{ projectName }}
              </a>
            </li>
            <li><a @click="navigateProjectInsights">Insights</a></li>
          </ul>
          <LimitReachedBox kind="insights" v-if="isLockedByLimit" />
          <BaseUploader
            class="method-modal-image-uploader"
            :can-edit="userCanEditProjects && !isLockedByLimit"
            :uploading="image.uploading"
            :file="image.file"
            :url="image.url"
            @remove="removeImage"
            @change="changeImage"
          />
          <TitleInput
            appearance="xmd"
            :value="method.name"
            :can-edit="userCanEditProjects && !isLockedByLimit"
            :multiline="true"
            @change="onTitleChange"
            @keydown.enter.prevent="onTitleEnter"
          />
          <BaseTextarea
            v-if="userCanEditProjects && !isLockedByLimit"
            ref="description"
            class="method-modal-description"
            placeholder="Type description here…"
            :value="method.description"
            @change="onDescriptionChange"
          />
          <p v-else>
            {{ method.description }}
          </p>
          <div class="method-modal-section" v-if="userCanEditProjects || (insightHighlights && insightHighlights.length)">
            <h2 class="method-modal-section__title">Highlights</h2>
            <SortableCards
              :can-sort="false"
              :can-add="false"
              :can-edit="userCanEditProjects"
              :breadcrumbs="true"
              :view-only="true"
              :show-tags="true"
              :project-id="projectId"
              :cards="insightHighlights"
              :side-menu="userCanEditProjects && [
                {
                  text: 'Remove',
                  action: 'remove',
                },
              ]"
              @card-action="onCardAction"
              @card-change="onCardChange"
            />
          </div>
          <div
            v-if="userCanEditProjects"
            class="method-modal-add"
            @click="addHighlight"
          > 
            <BaseButton
              :icon="isLockedByLimit && 'lock'"
            >
              Add Highlights
            </BaseButton>
          </div>
        </div>
      </div>
      <div class="method-modal-grid__sidebar">
        <div class="method-modal-sidebar-section">
          <div class="method-modal-sidebar-section__title">
            Informations
          </div>
          <dl class="sidebar-dl">
            <dt>Created</dt> <dd>{{ createdAtFormated }}</dd>
            <dt>Last modified</dt> <dd>{{ updatedAtFormated }}</dd>
          </dl>
        </div>
        <div class="method-modal-sidebar-section">
          <div class="method-modal-sidebar-section__title">
            Tags
          </div>
          <RespondersDropdown
            :can-edit="userCanEditProjects  && !isLockedByLimit"
            list="tags"
            :project-id="projectId"
            heading=""
            value-format="list"
            :value="methodTags"
            @input="onTagsChange"
          />
        </div>
      </div>
    </div>
    <BaseSpinner v-else />
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { navigateProject, navigateProjectInsights, navigateToMethod } from 'helpers/router';
import { timeSinceText } from 'helpers/time';
import BaseModal from 'modals/base';

export default {
  name: 'MethodModal',
  components: {
    BaseModal,
  },
  data() {
    return {
      addHighlightEnabled: false,
      active: false,
      data: {},
    }
  },
  computed: {
    ...mapGetters(['userCanEditProjects']),
    isLockedByLimit() {
      return this.$store.getters.isLockedByLimit(this.data.id);
    },
    highlights() {
      return this.$store.getters.projectHighlights(this.projectId);
    },
    methodId() {
      return this.data.id;
    },
    method() {
      return this.$store.getters.projectMethodById(this.methodId);
    },
    methodTags() {
      if (!this.method) {
        return [];
      }

      return this.method.tags;
    },
    fetched() {
      return this.$store.getters.isMethodFetched(this.methodId);
    },
    image() {
      if (typeof this.method.image === 'string') {
        return { url: this.method.image };
      }
      return this.method.image || {};
    },
    insightHighlights() {
      const highlights = this.highlights;
      return (this.method.customMethodSentences || []).map(({sentenceId}) => {
        return highlights.find((h) => h.id === sentenceId);
      }).filter((h) => !!h);
    },
    createdAtFormated() {
      return this.formatDate(this.method.createdAt);
    },
    updatedAtFormated() {
      return this.formatDate(this.method.updatedAt);
    },
    loading() {
      return this.data.loading;
    },
    projectId() {
      return this.method && this.method.projectId || this.$store.getters.projectCurrentId;
    },
    projectName() {
      return this.method && this.method.projectName;
    },
  },
  watch: {
    methodId() {
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    navigateProject() {
      navigateProject(this.data.projectId || this.method.projectId)
      this.$store.dispatch('modalHide');
    },
    navigateProjectInsights() {
      navigateProjectInsights(this.data.projectId || this.method.projectId)
      this.$store.dispatch('modalHide');
    },
    async fetch() {
      if (!this.active) return;
      this.$store.dispatch('projectFetch', this.projectId);
      this.$store.dispatch('phasesFetch', this.projectId);
      await this.$store.dispatch('projectMethodFetch', { id: this.methodId, projectId: this.data.projectId });
      await this.$store.dispatch('requireTags', { projectId: this.data.projectId });
      await this.$store.dispatch('requireHighlights', { projectId: this.data.projectId });
    },
    onTagsChange(tags) {
      this.$store.dispatch('projectMethodChange', {
        id: this.methodId,
        data: {
          tagList: tags,
          tags,
        },
      })
    },
    changeImage(image) {
      this.$store.dispatch('builderMethodChangeImage', {
        id: this.method.id,
        image,
      });
    },
    removeImage() {
      this.$store.dispatch('builderMethodRemoveImage', {
        id: this.method.id,
      });
    },
    onTitleChange(name) {
      this.$store.dispatch('projectMethodChange', {
        id: this.methodId,
        data: {
          name,
        },
      })
    },
    onDescriptionChange(description) {
      this.$store.dispatch('projectMethodChange', {
        id: this.methodId,
        data: {
          description,
        },
      })
    },
    addHighlight() {
      let id = this.methodId;

      this.$store.dispatch('modalShow', {
        name: 'highlights',
        data: {
          exclude: (this.method.customMethodSentences || []).map((s) => s.sentenceId),
          projectId: this.projectId,
          onSelect: async (list) => {
            return this.$store.dispatch('projectMethodAddSentences', {
              id,
              list,
            })
          },
        },
      });
    },
    onTitleEnter() {
      this.$refs.description.focus();
    },
    onCardAction({ action, sentence }) {
      let methodId = this.methodId;

      if (action === 'sentence-click') {
        navigateToMethod(sentence.projectId, sentence.designMethodId, {
          item: sentence.parentId,
        });
        this.$store.dispatch('modalHide');
      }

      if (action === 'remove') {
        return this.$store.dispatch('projectMethodRemoveSentence', {
          methodId,
          sentenceId: sentence.id,
        })
      }
    },
    onCardChange(id, data) {
      this.$store.dispatch('changeHighlight', { id, content: data.content, projectId: this.projectId, contentTags: data.contentTags });
    },
    formatDate(date) {
      return timeSinceText(new Date(date));
    },
  },
};
</script>
