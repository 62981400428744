import { createRouter, createWebHistory } from 'vue-router';
import * as ROUTES from 'constants/routes';
import { slugify } from 'utils/text';
import MagicLink from 'views/magic-link';
import ResetPassword from 'views/reset-password';
import Onboarding from 'views/onboarding';
import Shortcut from 'views/shortcut';
import PricePlans from 'views/price-plans';
import WorkspaceProjects from 'views/workspace-projects';
import WorkspaceTags from 'views/workspace-tags';
import WorkspaceInsights from 'views/workspace-insights';
import WorkspaceRecent from 'views/workspace-recent';
import WorkspaceSettings from 'views/workspace-settings';
import ProjectArchive from 'views/project-archive';
import ProjectHighlights from 'views/project-highlights';
import ProjectInsights from 'views/project-insights';
import ProjectPages from 'views/project-pages';
import ProjectCreate from 'views/project-create';
import ProjectTags from 'views/project-tags';
import ProjectTeam from 'views/project-team';
import ProjectPageNew from 'views/create-page';
import ProjectPage from 'views/page';
import Survey from 'views/survey';

const routes = [
  {
    path: '/',
    name: ROUTES.HOME,
    redirect: { name: ROUTES.WORKSPACE_RECENT },
  },
  {
    path: '/projects',
    name: ROUTES.PROJECTS_LIST,
    component: WorkspaceProjects,
  },
  {
    path: '/recent',
    name: ROUTES.WORKSPACE_RECENT,
    component: WorkspaceRecent,
  },
  {
    path: '/tags',
    name: ROUTES.WORKSPACE_TAGS,
    component: WorkspaceTags,
  },
  {
    path: '/insights',
    name: ROUTES.WORKSPACE_INSIGHTS,
    component: WorkspaceInsights,
  },
  {
    path: '/settings',
    name: ROUTES.WORKSPACE_SETTINGS,
    component: WorkspaceSettings,
  },
  {
    path: '/projects/new',
    name: ROUTES.PROJECT_NEW,
    component: ProjectCreate,
  },
  {
    path: '/s/:shortcut',
    name: ROUTES.PROJECT_SHORT,
    component: ProjectPage,
  },
  {
    path: '/survey/:project_id/:method_id/',
    name: ROUTES.PROJECT_SURVEY,
    component: Survey,
  },
  {
    path: '/project/:project_id/pages',
    name: ROUTES.PROJECT_METHODS,
    component: ProjectPages,
  },
  {
    path: '/project/:project_id/tags',
    name: ROUTES.PROJECT_TAGS,
    component: ProjectTags,
  },
  {
    path: '/project/:project_id/highlights',
    name: ROUTES.PROJECT_HIGHLIGHTS,
    component: ProjectHighlights,
  },
  {
    path: '/project/:project_id/insights',
    name: ROUTES.PROJECT_INSIGHTS,
    component: ProjectInsights,
  },
  {
    path: '/project/:project_id/tag/:tag_id',
    name: ROUTES.PROJECT_TAG,
    component: ProjectTags,
  },
  {
    path: '/project/:project_id/report',
    name: ROUTES.PROJECT_REPORT,
    component: ProjectPage,
  },
  {
    path: '/project/:project_id/team',
    name: ROUTES.PROJECT_TEAM,
    component: ProjectTeam,
  },
  {
    path: '/project/:project_id/archive',
    name: ROUTES.PROJECT_ARCHIVE,
    component: ProjectArchive,
  },
  {
    path: '/project/:project_id/method/:method_id',
    name: ROUTES.PROJECT_METHOD,
    component: ProjectPage,
  },
  {
    path: '/project/:project_id/pages/new/:phase_id',
    name: ROUTES.PROJECT_METHOD_NEW,
    component: ProjectPageNew,
  },
  {
    path: '/sign-in',
    name: ROUTES.SIGN_IN,
    component: Onboarding,
    props: true,
    children: [
      { path: 'email', name: ROUTES.SIGN_IN_EMAIL, component: Onboarding },
      { path: 'password', name: ROUTES.SIGN_IN_PASSWORD, component: Onboarding },
    ],
  },
  {
    path: '/accept-invitation/:workspace_id/:token',
    name: ROUTES.ACCEPT_INVITATION,
    component: Onboarding,
  },
  {
    path: '/accept-invitation/ready',
    name: ROUTES.ACCEPT_INVITATION_READY,
    component: Onboarding,
  },
  {
    path: '/welcome',
    name: ROUTES.WORKSPACE_ONBOARDING,
    component: Onboarding,
    children: [
      { path: 'brand', name: ROUTES.WORKSPACE_ONBOARDING_BRAND, component: Onboarding },
      { path: 'logo', name: ROUTES.WORKSPACE_ONBOARDING_LOGO, component: Onboarding },
      { path: 'trial', name: ROUTES.WORKSPACE_ONBOARDING_TRIAL, component: Onboarding },
      { path: 'team', name: ROUTES.WORKSPACE_ONBOARDING_TEAM, component: Onboarding },
      { path: 'ready', name: ROUTES.WORKSPACE_ONBOARDING_READY, component: Onboarding },
    ],
  },
  {
    path: '/password_reset',
    name: ROUTES.PASSWORD_RESET,
    component: ResetPassword,
  },
  {
    path: '/magic_link',
    name: ROUTES.MAGIC_LINK,
    component: MagicLink,
  },
  {
    path: '/price_plans',
    name: ROUTES.PRICE_PLANS,
    component: PricePlans,
  },
  {
    path: '/:pathMatch(.*)',
    redirect: { name: ROUTES.PROJECTS_LIST },
  },
];

const router = createRouter({
  routes,
  base: '/',
  mode: 'history',
  history: createWebHistory(),
  // scrollBehavior(to, from, savedPosition) {
  //   const position = savedPosition || { top: 0 };

  //   if (!savedPosition) {
  //     if (to.hash) {
  //       position.selector = to.hash;
  //     }
  //     if (to.matched.some((m) => m.meta.scrollToTop)) {
  //       position.top = 0;
  //     }
  //   }

  //   return position;
  // },
});
// if ('scrollRestoration' in history) { history.scrollRestoration = 'manual'; }

const ROUTE_ATTRIBUTE = 'data-route';
router.afterEach(({ name }) => {
  document.body.setAttribute(ROUTE_ATTRIBUTE, slugify(name));
});

export default router;
