import ActiveStorageService from 'services/active-storage.service';

const moduleState = {
};

const getters = {
};

const actions = {
  async activeStorageUploadFile({ commit, getters }, { file, progress }) {
    const authorization = await ActiveStorageService.getStorageAuthorization(file, (e) => {
      progress(e.loaded / e.total);
    });
    return authorization;
  },
};

const mutations = {
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
