<template>
  <div class="onb-step">
    <div class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          Premium plan allows you to collaborate with your teammates. Invite them now to create projects together
        </h2>
      </div>
      <div class="onb-input">
        <CustomInput
          v-model="email1"
          name="username"
          type="email"
          placeholder="Enter your coworker email"
          input-class="input input--new-light input--center"
          :validate="validateEmail"
          autofocus
          @keydown.enter.prevent="nextStep"
        />
      </div>
      <div class="onb-input">
        <CustomInput
          v-model="email2"
          name="username"
          type="email"
          placeholder="Enter your coworker email"
          input-class="input input--new-light input--center"
          :validate="validateEmail"
          @keydown.enter.prevent="nextStep"
        />
      </div>
      <div class="onb-input">
        <CustomInput
          v-model="email3"
          name="username"
          type="email"
          placeholder="Enter your coworker email"
          input-class="input input--new-light input--center"
          :validate="validateEmail"
          @keydown.enter.prevent="nextStep"
        />
      </div>
    </div>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';
import { validate } from 'helpers/email';
import { navigateProjects } from 'helpers/router';

export default {
  name: 'WorkspaceTeam',
  props: {
    data: Object,
  },
  data() {
    return {
      email1: '',
      email2: '',
      email3: '',
      loading: false,
    }
  },
  computed: {
    isValid() {
      return (
        (!this.email1 || validate(this.email1)) &&
        (!this.email2 || validate(this.email2)) &&
        (!this.email3 || validate(this.email3))
      );
    },
  },
  watch: {
    email1() {
      this.updateConfig();
    },
    email2() {
      this.updateConfig();
    },
    email3() {
      this.updateConfig();
    },
  },
  created() {
    this.updateConfig();
  },
  methods: {
    getConfig() {
      return {
        progress: 4/5,
        avatar: false,
        buttons: [
          {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Invite',
            disabled: !this.isValid,
            loading: this.loading,
          },
          {
            appearance: 'light',
            id: 'secondary',
            text: 'Not now. Go to workspace',
            loading: this.loading,
          },
        ],
      };
    },
    buttonAction(id) {
      if (id === 'secondary') {
        this.nextStep(false);
      } else {
        this.nextStep(true);
      }
    },
    async nextStep(enableTeam) {
      if (!this.isValid) {
        this.$emit('shake');
        return;
      }

      this.loading = true;
      this.updateConfig();
      this.$store.dispatch('userChange', { welcomeScreenShown: true });
      const workspace = await this.$store.dispatch('workspaceCreate', {
        name: this.data.organizationName,
        private: false,
      });

      if (this.data.image) {
        this.$store.dispatch('workspaceChangeImage', {
          id: workspace.id,
          image: this.data.image,
        });
      }

      this.$store.dispatch('workspaceSelect', { id: workspace.id });
      navigateProjects();

      if (!enableTeam) {
        return;
      }

      const team = [this.email1, this.email2, this.email3].filter((e) => e);

      if (team.length) {
        for (const email of team) {
          this.$store.dispatch('workspaceInviteMember', {
            email: email,
            rights: 'W',
          })
        }
      }
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
    validateEmail(value) {
      return validate(value);
    },
  },
};
</script>
