<template>
  <div
    v-if="insight"
    class="report-insight"
  >
    <div class="report-insight__body">
      <div
        v-if="nth"
        class="report-subtitle"
      >
        Insight #{{ nth }}
      </div>
      <TitleInput
        appearance="report-title clear"
        :value="insight.name"
        :can-edit="false"
      />
      <div class="report-insight__desc">
        {{ insight.description }}
      </div>
      <template v-if="insight.tags && insight.tags.length">
        <div class="report-subtitle">
          Tags
        </div>
        <TagsList
          class="report-insight__tags"
          :ids="insight.tags"
          :tags="isShared && insight.tags"
        />
      </template>
    </div>
    <div class="report-insight__aside">
      <BaseUploader
        class="report-insight__uploader"
        :can-edit="canEdit"
        :uploading="image.uploading"
        :file="image.file"
        :url="image.url"
        @remove="removeImage"
        @change="changeImage"
      />
      <HighlightsSlider
        class="report-insight__quotes"
        :ids="sentencesIds"
        :sentences="sentences"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectMethodCustom',
  components: {
  },
  props: {
    id: String,
    canEdit: Boolean,
    nth: Number,
  },
  computed: {
    isShared() {
      return this.$store.getters.isShared;
    },
    insight() {
      return this.$store.getters.projectMethodById(this.id);
    },
    sentences() {
      if (this.$store.getters.isShared) {
        return this.insight.sentences;
      }

      return null;
    },
    sentencesIds() {
      return this.insight.customMethodSentences && this.insight.customMethodSentences.map(({sentenceId}) => sentenceId);
    },
    image() {
      if (typeof this.insight.image === 'string') {
        return { url: this.insight.image };
      }
      return this.insight.image || {};
    },
  },
  methods: {
    changeImage(image) {
      this.$store.dispatch('builderMethodChangeImage', {
        id: this.insight.id,
        image,
      });
    },
    removeImage() {
      this.$store.dispatch('builderMethodRemoveImage', {
        id: this.insight.id,
      });
    },
  },
};
</script>