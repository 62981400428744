<template>
  <textarea
    v-if="canEdit"
    ref="textarea"
    v-model="myValue"
    v-drop-disable
    class="base-textarea"
    rows="1"
    :spellcheck="false"
    @mouseup="mouseup"
    @focus="select"
    v-bind="attrs"
    @change="$emit('change', $event.target.value.trim())"
  />
  <div
    v-else
    class="base-textarea"
  >
    {{ myValue }}
  </div>
</template>

<script>
import autosize from 'autosize';
import omit from 'lodash/omit';

export default {
  name: 'BaseTextarea',
  emits: ['change', 'input'],
  props: {
    value: String,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    preventMouseUp: false,
  }),
  computed: {
    attrs() {
      return omit(this.$attrs, ['change', 'input']);
    },
    myValue: {
      get() {
        if (!this.value) return '';
        return this.value;
      },
      set(v) {
        autosize.update(this.$el);
        this.$emit('input', v && v.length ? v : '');
      },
    },
  },
  mounted() {
    autosize(this.$el);
  },
  updated() {
    autosize.update(this.$el);
  },
  unmounted() {
    window.setTimeout(() => {
      autosize.destroy(this.$el);
    }, 500);
  },
  methods: {
    select() {
      this.$el.select();
      this.preventMouseUp = true;
    },
    mouseup(e) {
      if (this.preventMouseUp) {
        e.preventDefault();
      }
      this.preventMouseUp = false;
    },
    focus() {
      this.$el.focus();
    },
    blur() {
      this.$el.blur();
    },
  },
};
</script>

<style scoped lang="scss">
.base-textarea {
  border: 0;
  background: transparent;
  text-align: left;
  width: 100%;
  outline: 0;
  resize: none;
  color: #3b3b3b;
  padding: 0px;

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;

  &::placeholder {
    opacity: 0.4;
    color: #3b3b3b;
  }
}
</style>
