<template>
  <div
    ref="base"
    class="addon-embed"
    tabindex="1"
    @keydown="onKeyDown"
  >
    <div class="builder-paragraph__drag">
      <Icon name="drag" />
    </div>
    <youtube-video
      v-if="type === 'youtube'"
      :video-id="videoId"
      player-width="100%"
    />
    <iframe
      v-if="type === 'figma'"
      :src="`https://www.figma.com/embed?embed_host=talebook&url=${url}`"
      allowfullscreen
      class="addon-embed__iframe"
    />
    <iframe
      v-if="type === 'loom'"
      :src="`https://www.loom.com/embed/${videoId}?hide_owner=true&hide_speed=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`"
      class="addon-embed__iframe"
    />
    <iframe
      v-if="type === 'invision'"
      :src="`https://${userId}.invisionapp.com/console/share/${videoId}`"
      class="addon-embed__iframe"
    />
    <iframe
      v-if="type === 'miro'"
      :src="`https://miro.com/app/embed/${videoId}/`"
      class="addon-embed__iframe"
    />
    <iframe
      v-if="type === 'typeform'"
      :src="`https://form.typeform.com/to/${videoId}?typeform-medium=embed-sdk&disable-tracking=true&__dangerous-disable-submissions=&typeform-embed=embed-widget`"
      class="addon-embed__iframe"
    />
    <iframe
      v-if="type === 'whimsical'"
      :src="`https://whimsical.com/embed/${videoId}`"
      class="addon-embed__iframe"
    />
  </div>
</template>

<script>
import { matchEmbedId, matchEmbedType, matchEmbedUser } from 'components/common/editor';
import { getIdFromURL } from 'vue-youtube-embed'

export default {
  name: 'ProjectMethodCustom',
  components: {
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    url() {
      return this.section.value;
    },
    type() {
      return matchEmbedType(this.section.value);
    },
    videoId() {
      if (this.type === 'youtube') {
        return getIdFromURL(this.url);
      }

      return matchEmbedId(this.url);
    },
    userId() {
      return matchEmbedUser(this.url);
    },
  },
  watch: {
    section: {
      handler(next, prev) {
        if (next.focus || next.focus === 0) {
          this.focus(next.focus);
        }
      },
      deep: true,
    },
  },
  created() {
  },
  methods: {
    focus() {
      this.$refs.base.focus();
    },
    onKeyDown(e) {
      // if (e.key === 'Backspace') {
      //   if (this.selectedSections && this.selectedSections.length) {
      //     this.$store.dispatch('builderRemoveSelectedSections');
      //     return;
      //   }

      //   this.$store.dispatch('builderRemoveSection', {
      //     id: this.section.id,
      //   });

      //   const prevSection = this.$store.getters.getSectionAt(this.index - 1);
      //   if (prevSection) {
      //     this.$store.dispatch('builderFocusSection', { id: prevSection.id });
      //   }

      //   e.stopPropagation();
      //   e.preventDefault();
      // }
    },
  },
};
</script>
