<template>
  <div class="process-notifications">
    <transition-group
      name="notification"
      tag="div"
      mode="out-in"
    >
      <Item
        v-for="data of processNotifications"
        :key="data.id"
        :data="data"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Item from './item';

export default {
  name: 'Notifications',
  components: {
    Item,
  },
  computed: {
    ...mapGetters([
      'processNotifications',
    ]),
  },
  methods: {
  },
};
</script>
