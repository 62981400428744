import * as METHODS from './data';
import { BLANK, TEMPLATES } from './phases';

export const ADD_METHODS_BY_PHASE = {
  [BLANK]: [
    METHODS.CUSTOM,
  ],
  [TEMPLATES]: [
    METHODS.STAKEHOLDER_INTERVIEW,
    METHODS.USER_INTERVIEW,
    METHODS.COMPETITION_ANALYSIS,
    METHODS.PERSONAS,
    METHODS.FLOWS,
    METHODS.INTERACTIVE_PROTOTYPE,
  ],
};
