export const HOME = 'home';
export const SETTINGS = 'settings';
export const WORKSPACE_RECENT = 'workspace-recent';
export const WORKSPACE_TAGS = 'workspace-tags';
export const WORKSPACE_INSIGHTS = 'workspace-insights';
export const WORKSPACE_SETTINGS = 'workspace-settings';

export const WORKSPACE_ONBOARDING = 'workspace-onboarding';
export const WORKSPACE_ONBOARDING_BRAND = 'workspace-onboarding-brand';
export const WORKSPACE_ONBOARDING_LOGO = 'workspace-onboarding-logo';
export const WORKSPACE_ONBOARDING_TEAM = 'workspace-onboarding-team';
export const WORKSPACE_ONBOARDING_TRIAL = 'workspace-onboarding-trial';
export const WORKSPACE_ONBOARDING_READY = 'workspace-onboarding-ready';

export const PROJECTS_LIST = 'projects-list';

export const PROJECT_NEW = 'project-new';
export const PROJECT_NEW_ANIMATION = 'project-new-animation';
export const PROJECT_METHODS = 'project-methods';
export const PROJECT_TAGS = 'project-tags';
export const PROJECT_TAG = 'project-tag';
export const PROJECT_HIGHLIGHTS = 'project-highlights';
export const PROJECT_INSIGHTS = 'project-insights';
export const PROJECT_REPORT = 'project-report';
export const PROJECT_SHORT = 'project-short';
export const PROJECT_ARCHIVE = 'project-archive';

export const PROJECT_METHOD = 'method';
export const PROJECT_METHOD_NEW = 'method-new';
export const PROJECT_METHOD_PAGE = 'method-page';
export const PROJECT_SURVEY = 'survey';

export const PROJECT_TEAM = 'project-team';

export const METHODS_HELP = 'project-methods';

export const PASSWORD_RESET = 'password-reset';
export const SIGN_IN = 'sign-in';
export const SIGN_IN_EMAIL = 'sign-in-email';
export const SIGN_IN_PASSWORD = 'sign-in-password';
export const MAGIC_LINK = 'magic-link';
export const ACCEPT_INVITATION = 'accept-invitation';
export const ACCEPT_INVITATION_READY = 'accept-invitation-ready';

export const PRICE_PLANS = 'price-plans';
export const TERMS = 'terms';
export const PRIVACY_POLICY = 'privacy-policy';

export const COMPETITOR = 'competitor';
export const PERSONA = 'persona';
