<template>
  <div
    ref="self"
    v-click-outside="delayHide"
    :class="classes"
    class="tags-selector"
  >
    <div
      class="tags-selector__background"
      :class="classes"
    >
      <div
        class="tags-selector__input-container"
        @click.stop="show"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          :class="{active: isActive}"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#AAAAB5"
            stroke-linecap="round"
            stroke-linejoin="round"
            transform="translate(1 1)"
          >
            <circle
              cx="5.833"
              cy="5.833"
              r="5.833"
            />
            <path d="M14 14L9.956 9.956" />
          </g>
        </svg>
        <div
          v-show="searchedText.length == 0"
          class="tags-selector__placeholder"
          :class="{active: isActive}"
        >
          Add {{ singleItemName }}
        </div>
        <input
          ref="input"
          v-model="searchedText"
          type="text"
          tabindex="-1"
          :maxlength="$store.getters.tagNameMaxLength"
          @keydown="handleKeydown"
        >
      </div>
      <div
        v-show="isActive"
        class="tags-selector__list-container"
      >
        <div>
          <div
            v-if="!myTags.length && !this.searchedText.length"
            class="tags-selector__no-item"
          >
            <img
              src="/static/img/svg/ic-sticker-note.svg"
              width="64"
              height="69"
              alt=""
            >
            <span>You don’t have any {{ singleItemName }}</span>
          </div>
          <div>
            <div
              ref="tagsSelectorList"
              class="tags-selector__list"
            >
              <div class="tags-selector__list-content">
                <TagListItem
                  v-for="(tag, index) in selectedTags"
                  :ref="'item-' + tag.id"
                  :key="tag.id"
                  :focused="index === tagIndex"
                  :color="tag.color"
                  :tag="tag"
                  :mode="mode"
                  class="tags-selector__list-item"
                  @click.stop="tagClicked(tag)"
                >
                  <span v-html="highlightName(truncate(tag.name, 26), searchedText)" />
                </TagListItem>
                <div
                  v-if="showNewTag"
                  class="tags-selector__list-item tags-selector__list-item--new"
                  :class="{'tags-selector__list-item--new-focused': tagIndex === selectedTags.length}"
                  @click.stop="addNewTag"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="3.1787686mm"
                    height="3.1787677mm"
                    viewBox="0 0 3.1787686 3.1787677"
                  >
                    <g transform="translate(-62.666568,-91.303473)">
                      <path
                        id="path1580"
                        style="fill:none;fill-rule:evenodd;stroke:rgba(170,170,170,1);stroke-width:0.26458332;stroke-linecap:round;stroke-linejoin:round"
                        d="m 64.255952,91.437649 v 2.910416 m -1.455208,-1.455208 h 2.910417"
                      />
                    </g>
                  </svg>
                  <div>
                    <span
                      style="word-break: break-all;"
                      v-text="searchedText"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.tags-selector__list-item .highlight {
  font-weight: bold;
  color: #171717;
}

.tags-selector .vb-invisible {
  z-index: 1;
}

.tags-selector .vb-dragger {
  right: 2px;
}

.tags-selector .vb-dragger-styler {
  right: -1px;
  width: 4px;
  opacity: 0.3;
  border-radius: 20px;
  background-color: rgb(85, 85, 85);
  height: 100%;
}
</style>

<style lang="scss" scoped>
@mixin text {
  color: #646464;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.tags-selector {
  position: relative;
  padding-top: 44px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;

  &__list-container {
    margin-top: 43px;
    padding-top: 4px;
  }

  &__background {
    z-index: 1;
    top: 0px;
    width: 100%;
    position: absolute;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0);

    &--focused {
      background-color: white;
      box-shadow: 0px 12px 23px -1px rgba(0,0,0,0.1);
    }

    &.js-active {
      z-index: 999;
    }
  }

  &__no-item {
    padding: 17px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      opacity: 0.4;
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    span {
      @include text;
      margin-top: 12px;
      color: #aaaab5;
      font-size: 12px;
    }
  }

  &__list {
    max-height: 420px;
  }

  &__list-content {
    padding: 0 3px;
  }

  &__list-item-color {
    width: 16px;
    height: 16px;
    background-color: #9f55b1;
    border-radius: 50%;
    margin: 0px 16px 0 10px;
  }

  &__list-item-name {
    flex: 1;
  }

  &__list-item {
    @include text;
    letter-spacing: 0.1px;
    min-height: 36px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    margin: 4px 0;

    &:hover {
      background-color: #F8F9FA;
    }
    &:last-child,
    &:first-child {
      margin-bottom: 0px;
    }

    &:first-child {
      margin-top: 0px;
    }

    &--selected {
      color: #252729;
      font-weight: 600;
      svg {
        width: 24px;
        margin: 2px;
        stroke-width: 1.05px;
      }
    }

    &--new {
      color: #252729;
      font-weight: 600;
      svg {
        width: 15px;
        height: 11px;
        margin: 0px 17px 0 10px;
        flex-shrink: 0;
      }
    }

    &--new-focused {
      background-color: #F8F9FA;
    }
  }

  &__placeholder {
    @include text;
    position: absolute;
    opacity: 0.5;
    cursor: pointer;

    height: 20px;
    width: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: calc(50% - 25px);
    margin-top: auto;
    margin-bottom: auto;
    transition: margin .2s linear;

    &.active {
      margin-left: 44px;
    }
  }

  &--mode-responders &__placeholder {
    width: 100px;
    margin-left: calc(50% - 50px);

    &.active {
      margin-left: 44px;
    }
  }

  &__input-container {
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;

    display: flex;
    background-color: #eeeff2;
    border-radius: 4px;
    height: 41px;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background-color: #dde0e3;
      cursor: pointer;
    }
    svg {
      margin-right: 16px;
      visibility: hidden;
      opacity: 0;
      transition: opacity .1s;
    }
    svg.active {
      visibility: visible;
      opacity: 1;
    }
    input {
      @include text;
      cursor: pointer;
      flex: 1;
      border: 0;
      outline: none;
      background-color: transparent;
    }
  }

  &__header {
    display: flex;
    margin-right: 8px;
  }

  &__tags {
    width: 173px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
</style>

<script>
import { TAG_COLORS } from 'constants/tag-colors';
import { stringIncludes } from 'helpers/filter';
import { alphabetically } from 'helpers/sort';
import { truncate } from 'helpers/string';
import leven from 'js-levenshtein';
import sample from 'lodash/sample';
import uuid from 'utils/id';

import TagListItem from './tag-list-item';

export default {
  name: 'SidebarTagsSelector',
  components: {
    TagListItem,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'tags',
    },
  },
  data: () => ({
    myTags: [],
    searchedText: '',
    isActive: false,
    newTester: '',
    tagIndex: 0,
  }),
  computed: {
    selectedTags() {
      let tags = [...this.myTags].filter((tag) => !tag.hidden);
      if (this.searchedText.length > 0) {
        tags = tags.filter((tag) => stringIncludes(tag.name, this.searchedText));
        tags = tags.sort((a, b) => (leven(this.searchedText, a.name) > leven(this.searchedText, b.name) ? 1 : -1));
      }
      return tags;
    },
    showNewTag() {
      if (this.searchedText.toString().length === 0) {
        return false;
      }
      return !this.myTags.find(
        (tag) =>
          tag.name.trim().toLowerCase() ===
          this.searchedText.toString().toLowerCase().trim()
      );
    },
    canEdit() {
      return this.$store.getters.userCanEditProjects;
    },
    testersIds() {
      const additionalIds = this.additional.map(({ id }) => id);
      return additionalIds.concat(this.$store.getters.testersIds);
    },
    classes() {
      return {
        'tags-selector__background--focused': this.isActive && (this.selectedTags.length > 0 || this.showNewTag),
        'js-active': this.isActive,
        [`tags-selector--mode-${this.mode}`]: true,
      };
    },
    singleItemName() {
      return this.mode === 'responders' ? 'respondent' : 'tag';
    },
    multipleItemsName() {
      return this.mode === 'responders' ? 'respondents' : 'tags';
    },
  },
  watch: {
    searchedText() {
      this.tagIndex = 0;
    },
    isActive(active) {
      if (active) {
        document.body.addEventListener('keydown', this.handleEscKey);
      } else {
        document.body.removeEventListener('keydown', this.handleEscKey);
      }
    },
  },
  created() {
    document.body.addEventListener('click', this.clickOutside);
  },
  unmounted() {
    document.removeEventListener('keyup', this.hideUsingEscapeKey);
    document.body.removeEventListener('click', this.clickOutside);
  },
  methods: {
    truncate,
    adjustVisibility(tag) {
      if (!tag) return;
      const cont = this.$refs.tagsSelectorList;
      const contOffsetTop = cont.offsetTop;
      const contRect = cont.getBoundingClientRect();
      const contRectTop = contRect.top;
      const contRectBottom = contRect.bottom;

      const elem = this.$refs[`item-${tag.id}`][0].$el;
      const elemOffsetTop = elem.offsetTop;
      const rect = elem.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      window.cont = cont;
      if (contRectTop > elemTop) {
        cont.scrollTop = elemOffsetTop - contOffsetTop;
      }
      if (contRectBottom < elemBottom) {
        cont.scrollTop = elemOffsetTop - cont.offsetHeight + elem.offsetHeight;
      }
    },
    handleKeydown(e) {
      if (e.code === 'ArrowDown') {
        this.tagIndex += 1;
        if (this.tagIndex >= this.selectedTags.length) {
          if (this.showNewTag) {
            this.tagIndex = this.selectedTags.length;
          } else {
            this.tagIndex = this.selectedTags.length - 1;
          }
        }
        this.adjustVisibility(this.selectedTags[this.tagIndex]);
        e.preventDefault();
      } else if (e.code === 'ArrowUp') {
        if (this.tagIndex > 0) this.tagIndex -= 1;
        this.adjustVisibility(this.selectedTags[this.tagIndex]);
        e.preventDefault();
      } else if (e.code === 'Enter') {
        if (this.tagIndex < this.selectedTags.length) {
          this.tagClicked(this.selectedTags[this.tagIndex]);
        } else if (this.tagIndex === this.selectedTags.length) {
          this.addNewTag();
        }
      }

      this.handleEscKey(e);
    },
    handleEscKey(e) {
      if (e.key === 'Escape') {
        this.delayHide();
        e.target.blur();
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    },
    clickOutside(e) {
      const self = this.$refs.self;
      if (e.target === self || self.contains(e.target)) {
        return;
      }

      this.delayHide();
    },
    async addNewTag() {
      if (!this.showNewTag) {
        return;
      }
      const randomColor = sample(TAG_COLORS);
      const id = uuid();
      const newTag = {
        id,
        name: this.searchedText,
        selected: true,
        color: randomColor,
      };
      this.myTags.unshift(newTag);
      this.searchedText = '';

      const result = await this.$store.dispatch(this.mode === 'tags' ? 'tagAdd' : 'createResponder', newTag);

      this.myTags.forEach((tag) => {
        if (tag.id === result.tempId) {
          tag.id = result.id;
        }
      });

      this.emitInput();
      this.$emit('itemAdd', result);
    },
    tagClicked(tag) {
      const selected = !tag.selected;
      this.myTags = this.myTags.map((t) => (t.id === tag.id ? { ...tag, selected: !tag.selected } : t));
      this.emitInput();
      if (selected) {
        this.$emit('itemAdd', tag);
      } else {
        this.$emit('itemRemove', tag);
      }
    },
    emitInput() {
      this.$emit('input', this.myTags.filter((myTag) => myTag.selected));
    },
    highlightName(name, pattern) {
      if (pattern.toString().length === 0) {
        return name;
      }
      return name.replace(
        new RegExp(pattern.trim().toLowerCase(), 'gi'),
        '<span class=\'highlight\'>$&</span>'
      );
    },
    prepareData() {
      this.searchedText = '';
      const valueTagIds = this.value.map((tag) => tag.id);
      const selectedTags = [];
      const otherTags = [];

      this.tags.forEach((tag) => {
        if (valueTagIds.includes(tag.id)) {
          selectedTags.push({
            ...tag,
            selected: true,
            hidden: true,
          });
        } else {
          otherTags.push({
            ...tag,
            selected: false,
          });
        }
      });

      selectedTags.sort((a, b) => alphabetically(a.name, b.name));
      otherTags.sort((a, b) => alphabetically(a.name, b.name));
      this.myTags = [...selectedTags, ...otherTags];
    },
    hideUsingEscapeKey(e) {
      e.stopPropagation();
      if (e.keyCode === 27) {
        this.hide();
      }
    },
    show() {
      this.$refs.input.focus();
      if (this.isActive) { return; }
      this.prepareData();
      this.isActive = true;
      document.addEventListener('keyup', this.hideUsingEscapeKey, true);
    },
    hide() {
      this.isActive = false;
      document.removeEventListener('keyup', this.hideUsingEscapeKey, true);
    },
    delayHide() {
      if (!this.isActive) {
        return;
      }
      this.searchedText = '';
      this.hide();
    },
  },
};
</script>
