<template>
  <BaseModal
    :name="name"
    :can-be-closed="true"
    @data="data = $event"
  >
    <button
      v-if="canBeClosed"
      type="button"
      class="modal__close-x"
      @click="hide"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
      >
        <path d="M13 0L1 12M1 0l12 12" />
      </svg>
    </button>
    <div class="modal__body">
      <img
        src="/static/img/svg/illu-magic-link.svg"
        width="165"
        height="137"
        alt=""
        class="magic-link-modal__img"
      >
      <p class="modal__title">
        Magic link is in your inbox
      </p>
      <p class="modal__desc">
        We just emailed a confirmation link to <b>{{ email }}</b>. Click the link, and you'll be signed in.
      </p>
      <a
        :href="mailboxUrl"
        class="btn btn--block btn--md btn--corners btn--outline"
        target="_blank"
      >
        Open Inbox
      </a>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
export default {
  name: 'magic-link',
  components: {
    BaseModal,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    name() {
      return 'magic-link';
    },
    canBeClosed() {
      return true;
    },
    mailboxUrl() {
      return 'http://' + (this.email || 'gmail.com').replace(/[\s\S]+\@/gim, '');
    },
    email() {
      return this.data.email;
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('modalHide');
    },
  },
};
</script>
<style lang="scss" scoped>
.magic-link-modal {
  &__img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 168px;
    height: auto;
  }
}
</style>
