<template>
  <div>
    <OnboardingHeader
      :progress="progress"
      :user="user"
      :side="projectCreator"
    />
    <div
      class="side-lay"
      :class="layClasses"
    >
      <div class="side-lay__main">
        <div
          ref="body"
          class="side-lay__body"
        >
          <div class="onb-container">
            <ContentTransition>
              <div
                v-if="avatar"
                class="onb-avatar-wrap"
              >
                <OnboardingAvatar
                  :mode="avatar"
                  :name="avatarName"
                />
              </div>
            </ContentTransition>
            <ContentTransition>
              <component
                :is="stepComponent"
                v-if="stepComponentName"
                ref="step"
                :key="stepComponentName"
                :data="stepData"
                @config="setConfig"
                @redirect="redirect"
                @shake="shake"
              />
            </ContentTransition>
            <div class="onb-buttons">
              <ContentTransition :multiple="true">
                <div
                  v-for="button of filteredButtons"
                  :key="button.id"
                >
                  <div class="onb-button-wrap">
                    <BaseButton
                      :disabled="button.disabled || button.loading"
                      :loading="button.loading"
                      v-bind="button"
                      :appearance="button.appearance + ' md block'"
                      @click="buttonAction(button.id)"
                      @keydown.enter.stop
                    >
                      {{ button.text }}
                    </BaseButton>
                  </div>
                </div>
              </ContentTransition>
            </div>
          </div>
        </div>
      </div>
      <aside class="side-lay__aside">
        <ProjectCreator
          v-if="projectCreator"
          v-bind="projectCreator"
        />
      </aside>
    </div>
  </div>
</template>

<script>
import * as ROUTES from 'constants/routes';
import { shake } from 'helpers/ui';
import { mapGetters } from 'vuex';

import OnboardingAvatar from './common/avatar';
import OnboardingHeader from './common/header';
import ProjectCreator from './common/project-creator';
import InvitationStep from './invitation/home';
import InvitationReadyStep from './invitation/ready';
import EmailStep from './steps/email';
import HomeStep from './steps/home';
import PasswordStep from './steps/password';
import WorkspaceBrandStep from './workspace-steps/brand';
import WorkspaceLogoStep from './workspace-steps/logo';
import WorkspaceTrialStep from './workspace-steps/trial';
import WorkspaceTeamStep from './workspace-steps/team';
import WorkspaceReadyStep from './workspace-steps/ready';

const COMPONENTS = {
  [ROUTES.SIGN_IN]: HomeStep,
  [ROUTES.SIGN_IN_EMAIL]: EmailStep,
  [ROUTES.SIGN_IN_PASSWORD]: PasswordStep,
  [ROUTES.WORKSPACE_ONBOARDING]: WorkspaceBrandStep,
  [ROUTES.WORKSPACE_ONBOARDING_LOGO]: WorkspaceLogoStep,
  [ROUTES.WORKSPACE_ONBOARDING_TRIAL]: WorkspaceTrialStep,
  [ROUTES.WORKSPACE_ONBOARDING_READY]: WorkspaceReadyStep,
  [ROUTES.WORKSPACE_ONBOARDING_TEAM]: WorkspaceTeamStep,
  [ROUTES.ACCEPT_INVITATION]: InvitationStep,
  [ROUTES.ACCEPT_INVITATION_READY]: InvitationReadyStep,
};

const DEFAULTS = {
  avatar: 'guest',
  avatarName: '',
  buttons: [],
  progress: null,
  projectCreator: false,
}

export default {
  name: 'Onboarding',
  components: {
    OnboardingAvatar,
    OnboardingHeader,
    ProjectCreator,
  },
  data: () => ({
    ...DEFAULTS,
    stepName: '',
    stepComponentName: '',
    stepData: {},
  }),
  computed: {
    ...mapGetters(['user', 'routeName']),
    layClasses() {
      return {
        'side-lay--open': this.projectCreator,
      };
    },
    filteredButtons() {
      return this.buttons.filter((b) => !!b);
    },
  },
  watch: {
    routeName(route) {
      const component = COMPONENTS[route];
      this.stepName = this.routeName;

      if (!component) return;

      this.setConfig(component.methods.getConfig());
      this.stepComponent = component;
      this.stepComponentName = component.name;
    },
  },
  created() {
    this.stepComponent = null;
  },
  async mounted() {
    const component = COMPONENTS[this.routeName];

    this.stepName = this.routeName;
    this.setConfig(component.methods.getConfig());
    this.stepComponent = component;
    this.stepComponentName = component.name;
  },
  methods: {
    buttonAction(id) {
      this.$refs.step.buttonAction(id);
    },
    setConfig(config = null) {
      const newConfig = {
        ...DEFAULTS,
        ...config,
      };

      for (let param in newConfig) {
        if (newConfig.hasOwnProperty(param)) {
          this[param] = newConfig[param];
        }
      }
    },
    shake() {
      shake(this.$refs.body);
    },
    redirect({ route, data = {} }) {
      this.stepData = data;
      this.$router.push({
        name: route,
      });
    },
  },
};
</script>
