<template>
  <div class="onb-step">
    <form class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          <ContentTransition>
            <div
              v-if="userData && userData.status === 'registered'"
              key="welcomeback"
            >
              Welcome back
            </div>
            <div
              v-else-if="userData"
              key="withemail"
            >
              Create account
            </div>
            <div
              v-else
              key="continue"
            >
              Continue with email
            </div>
          </ContentTransition>
        </h2>
      </div>
      <BaseInput
        v-model="email"
        name="username"
        type="email"
        placeholder="Enter your work email"
        class="input input--new-light input--center"
        autofocus
        @keydown.enter.prevent="next"
      />
    </form>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';
import { validate } from 'helpers/email';
import MailLoginService from 'services/mail-login.service';
import { analyticsLogEvent, analyticsSetUserId } from 'utils/analytics';
import GoogleLoginService from 'services/login.service/google';

export default {
  name: 'SignInEmail',
  props: {
    data: Object,
  },
  data() {
    return {
      email: this.data.email || '',
      valid: validate(this.data.email),
      userData: this.data.userData,
      googleLoginFailed: false,
    }
  },
  watch: {
    email(email) {
      const valid = email && validate(email);
      this.valid = valid;
      this.fetchUser();
      this.updateConfig();
    },
  },
  created() {
    this.updateConfig();

    GoogleLoginService.load().catch((e) => {
      let error = 'Google login load failed. Check your browser settings and try again.';

      if (e.details && e.details.match(/cookies/gim)) {
        error = 'Enable cookies to login with Google';
      }

      this.googleLoginFailed = error;

      this.updateConfig();
    });
  },
  methods: {
    getConfig() {
      let { userData } = this;
      let disabled = !(this.valid && userData);

      if (this.email && userData && validate(this.email)) {
        disabled = false;
      }

      return {
        avatar: 'email',
        avatarName: (this.valid && this.email) || '',
        buttons: [
          userData && userData.provider === 'google' && {
            appearance: 'outline light',
            id: 'google',
            text: 'Continue with Google',
            icon: 'google',
            disabled: this.googleLoginFailed,
          } || {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Continue',
            disabled,
            loading: this.loading,
            disabled: disabled,
          },
          {
            appearance: 'light',
            id: 'secondary',
            text: 'Back',
          },
        ],
      };
    },
    async fetchUser() {
      if (!this.valid) {
        this.userData = null;
        this.checkedEmail = '';
        this.updateConfig();
        return;
      }

      if (this.checkedEmail === this.email) return;

      this.loading = true;
      this.updateConfig();

      const email = this.email.trim().toLowerCase();

      try {
        const data = await MailLoginService.checkIfIsUser({ email });
        this.checkedEmail = email;
        this.userData = data || { status: 'unregistered' };
      } catch (error) {
        this.userData = null;
      }

      this.loading = false;
      this.updateConfig();
    },
    buttonAction(id) {
      switch(id) {
        case 'primary':
          this.next();
          return;
        case 'google':
          this.next();
          return;
        case 'secondary':
          this.back();
          return;
      }
    },
    next() {
      if (!this.valid || !this.userData) {
        this.$emit('shake');
        analyticsLogEvent('sign_in_email_error');
        return;
      }
      const { userData } = this;
      analyticsSetUserId(this.email);

      if (userData && userData.provider === 'google') {
        this.login('google');
        analyticsLogEvent('sign_in_email_google_login');
        return;
      }

      this.$emit('redirect', {
        route: ROUTES.SIGN_IN_PASSWORD,
        data: {
          email: this.email,
          userData: this.userData,
        },
      })
      analyticsLogEvent('sign_in_confirm_email');
    },
    back() {
      this.$emit('redirect', {
        route: ROUTES.SIGN_IN,
      })
      analyticsLogEvent('sign_in_back_to_sign_in');
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
    async login(providerName) {
      await this.$store.dispatch('login', { providerName });
      this.$emit('success');
    },
  },
};
</script>
