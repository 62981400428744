import { inputFormat as formatDate } from 'helpers/date';
import { byPosition } from 'helpers/sort';
import pick from 'lodash/pick';
import { parser as methodParser } from 'models/project-method';
import * as ApiService from 'services/api.service';
import NotificationsService from 'services/notifications.service';

class ProjectMethodService {
  init(store) {
    this.$store = store;
  }

  async fetchAll(workspace, projectId) {
    const result = await ApiService.get(this.getUrl(workspace, projectId));
    const methods = result.map(methodParser);
    return byPosition(methods);
  }

  async fetch(workspace, projectId, id, query = {}) {
    const result = await ApiService.get(
      `${this.getUrl(workspace, projectId)}/${id}`,
      query
    );

    return methodParser(result);
  }

  async fetchPublic(id) {
    const result = await ApiService.get(
      `/api/public/design_methods/${id}`
    );

    const insights = await ApiService.get(
      `/api/public/insights?token=${id}`
    )

    result.insights = insights;

    return methodParser(result);
  }

  async fetchPublicSentences(id) {
    const result = await ApiService.get(
      `/api/public/design_methods/${id}/sentences`
    );

    return result;
  }

  async fetchSurvey(workspace, projectId, id, query = {}) {
    const result = await ApiService.get(
      `api/public/custom_methods/${id}`,
      query
    );

    if (result.customMethodItems) {
      result.customMethodItems.forEach((item, i) => {
        let response = item.customItemFields.find((f) => f.responder);

        if (!response) return;

        let answers = JSON.parse(response.value);

        if (answers) {
          answers.forEach((id, i) => {
            let answer = item.customItemFields.find((f) => f.id === id);
            answer.selected = true;
          });
        } else {
          item.answer = response.name;
        }
      });
    }

    return methodParser(result);
  }

  async methodAdd(workspace, projectId, { data: method, index = -1 }) {
    this.saving = true;
    const data = pick(method, ['type', 'phaseId', 'name', 'templateName', 'kind']);
    data.dueDate = formatDate(new Date(method.timestamp));
    data.position = index;

    try {
      let result;
      if (data.type === 'CustomMethod' && data.templateName) {
        data.targetProjectId = projectId;
        data.targetPhaseId = data.phaseId;
        result = await ApiService.post(`/api/workspaces/${workspace}/custom_methods/clone_template`, data);
      } else {
        result = await ApiService.post(this.getUrl(workspace, projectId), data);
      }
      return methodParser(result);
    } finally {
      this.saving = false;
    }
  }
  // id, data, index: position
  async reorder(workspace, projectId, data) {
    this.saving = true;
    const { phaseId, index: position } = data;
    try {
      const result = await ApiService.put(
        `${this.getUrl(workspace, projectId)}/reorder`,
        data
      );
      return result;
    } finally {
      this.saving = false;
    }
  }

  async methodRemove(workspace, projectId, { id }) {
    this.saving = true;

    try {
      const result = await ApiService.del(`${this.getUrl(workspace, projectId)}/${id}`);
      return result;
    } catch (error) {
      NotificationsService.showError('Unknown error occured! Try again later!');
      throw error;
    } finally {
      this.saving = false;
    }
  }

  async methodChange(workspace, projectId, { id: methodId, data }) {
    this.saving = true;

    if (data.hasOwnProperty('timestamp')) {
      data.dueDate = formatDate(new Date(data.timestamp));
    }

    try {
      const result = await ApiService.put(
        `${this.getUrl(workspace, projectId)}/${methodId}`,
        data
      );
      return result;
    } finally {
      this.saving = false;
    }
  }

  async methodCreatePublicLink(workspace, projectId, methodId) {
    this.saving = true;

    try {
      const result = await ApiService.post(`${this.getUrl(workspace, projectId)}/${methodId}/create_public_link`);
      return result;
    } finally {
      this.saving = false;
    }
  }

  async methodDestroyPublicLink(workspace, projectId, methodId) {
    this.saving = true;

    try {
      const result = await ApiService.del(`${this.getUrl(workspace, projectId)}/${methodId}/destroy_public_link`);
      return result;
    } finally {
      this.saving = false;
    }
  }

  async joinSentenceWithMethod(workspace, data) {
    const result = await ApiService.post(
      `api/workspaces/${workspace}/custom_method_sentences`,
      data
    );
    return result;
  }

  async unjoinSentenceMethod(workspace, data) {
    const result = await ApiService.del(
      `api/workspaces/${workspace}/custom_method_sentences`,
      data
    );
    return result;
  }

  async methodImageChange(workspace, projectId, { id: methodId, image }) {
    this.saving = true;

    const data = new FormData();
    data.append('image', image);
    data.append('image', image);

    try {
      const result = await ApiService.put(
        `${this.getUrl(workspace, projectId)}/${methodId}`,
        data
      );
      return result;
    } finally {
      this.saving = false;
    }
  }

  async changeHeader({ workspace, id, parentId, newData }) {
    const body = {
      [newData.header]: newData.value,
    };
    const result = await ApiService.put(
      this.getDesignMethod({ id, parentId, workspace }),
      body
    );
    return result;
  }

  set saving(value) {
    this.$store.dispatch('uiToggleSave', value);
  }

  async generateSummary(workspace, projectId, methodId, data) {
    const result = await ApiService.post(
      `${this.getUrl(workspace, projectId)}/${methodId}/summarize`,
      data
    );
    return result;
  }

  async transcribe(workspace, projectId, methodId, data) {
    const result = await ApiService.post(
      `${this.getUrl(workspace, projectId)}/${methodId}/transcribe`,
      data
    );
    return result;
  }

  async transcriptionFetch(workspace, projectId, methodId, id) {
    const result = await ApiService.get(`${this.getUrl(workspace, projectId)}/${methodId}/remote_tasks/${id}`);
    return result;
  }

  getDesignMethod({ workspace, parentId, id }) {
    return `api/workspaces/${workspace}/projects/${parentId}/design_methods/${id}`;
  }

  getUrl(workspace, projectId = null) {
    return `api/workspaces/${workspace}/projects/${projectId}/design_methods`;
  }
}

export default new ProjectMethodService();
