<template>
  <button
    v-bind="$attrs"
    type="button"
    :class="classNames"
  >
    <BaseSpinner
      v-if="loading"
      class="btn__loader"
    />
    <ContentTransition>
      <span
        :key="text"
        class="btn__body"
      >
        <Icon
          v-if="icon"
          class="btn__icon"
          :name="icon"
        />
        <template v-if="text">{{ text }}</template>
        <slot v-else />
      </span>
    </ContentTransition>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    loading: Boolean,
    icon: String,
    text: String,
    appearance: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    classNames() {
      return [
        'btn',
        'btn--' + this.appearance.trim().split(' ').join(' btn--'),
        {
          'btn--loading': this.loading,
        },
      ]
    },
  },
};
</script>
