/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import ProjectMethodService from 'services/project-method.service';
import SurveyService from 'services/survey.service';
import { merge } from 'utils/reactive';
import { SHOULD_HAVE_FIELDS } from 'views/survey';
const SURVEY_TYPES = ['asd'];

const MULTI_CHOICE_TYPE = 'custom_2';

const state = () => ({
  surveyResponderId: null,
  isSurveyValid: null,
});

const getters = {
  surveyResponderId: (state) => state.surveyResponderId,
  hasSurveyResponder: (state, getters) => getters.surveyResponderId !== null,
  isSurveyValid: (state) => state.isSurveyValid,
};

const actions = {
  surveyResponderSet({ commit }, data) {
    commit(TYPES.SURVEY_RESPONDER_SET, data);
  },

  async projectSurveyMethodFetch({ commit, dispatch, getters }) {
    const methodId = getters.projectCurrentMethodId;
    const tagId = getters.surveyTesterId || getters.routeTesterId;
    const projectId = getters.projectCurrentId;
    const params = {};

    const method = await ProjectMethodService.fetchSurvey(
      getters.workspaceCurrentId,
      projectId,
      methodId,
      params
    ).catch((e) => e);

    method.id = methodId;
    method.fetched = true;

    if (method.guestUserToken) {
      commit(TYPES.SURVEY_RESPONDER_SET, { id: method.guestUserToken });
    }

    dispatch('projectMethodSet', { projectId, method });
    return method;
  },
  projectMethodSet({ commit }, { projectId, method }) {
    commit(TYPES.PROJECT_METHOD_SET, { projectId, method });
  },
  registerSurveyResponder({ getters }, { name }) {
    const { projectCurrentId, projectCurrentMethodId } = getters;
    SurveyService.registerUser({
      id: getters.surveyResponderId,
      name,
      projectId: projectCurrentId,
      customMethodId: projectCurrentMethodId,
    })
  },
  surveySelectItem({ commit, getters, dispatch }, { sectionId, id }) {
    const method = getters.projectCurrentMethod;
    const sections = method.customMethodItems;
    const section = sections.find((s) => s.id === sectionId);

    if (!section) return;

    commit('SURVEY_SELECT_ITEM', { section, id });

    const selected = section.customItemFields.filter((f) => f.selected);
    const value = selected.map((f) => f.id);

    dispatch('saveAnswer', { id: sectionId, data: { value: JSON.stringify(value) }});

    if (getters.isSurveyValid !== null) {
      dispatch('validateSurvey');
    }
  },
  surveyAddAnswer({ dispatch, getters, commit }, { id, value }) {
    const method = getters.projectCurrentMethod;
    const sections = method.customMethodItems;
    const section = sections.find((s) => s.id === id);

    if (!section) return;

    commit('SURVEY_SET_ITEM_ANSWER', { section, value });

    dispatch('saveAnswer', { id, data: { name: value } })

    if (getters.isSurveyValid !== null) {
      dispatch('validateSurvey');
    }
  },
  saveAnswer({ commit }, { id, data }) {
    SurveyService.saveAnswer(id, data);
  },
  validateSurvey({ getters, commit }) {
    const method = getters.projectCurrentMethod;
    const sections = method.customMethodItems;

    commit('SURVEY_VALIDATE', { sections });
  },
};

const mutations = {
  [TYPES.SURVEY_RESPONDER_SET](state, { id }) {
    state.surveyResponderId = id;
  },
  SURVEY_SELECT_ITEM(state, { section, id }) {
    const field = section.customItemFields.find((f) => f.id === id);

    if (!field) return;

    if (section.type !== MULTI_CHOICE_TYPE) {
      section.customItemFields.forEach((field, i) => {
        merge(field, { selected: false });
      });
    }

    merge(field, { selected: !field.selected });
  },
  SURVEY_SET_ITEM_ANSWER(state, { section, value }) {
    merge(section, {
      answer: value,
      customItemFields: [{
        name: value,
      }],
    })
  },
  SURVEY_VALIDATE(state, { sections }) {
    let items = sections.filter((i) => SURVEY_TYPES.includes(i.type)).filter((item) => {
      return !SHOULD_HAVE_FIELDS.includes(item.type) || item.customItemFields.length
    });
    items.forEach((i) => {
      merge(i, {
        invalidAnswer: !(i.answer || (i.customItemFields.find((f) => f.selected))),
      });
    });
    let filled = items.filter((i) => !i.invalidAnswer);

    state.isSurveyValid = filled.length === items.length;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
