<template>
  <BaseModal
    class="modal-payment"
    name="PaymentMethodModal"
    :show-close="true"
    @active="active = $event"
    @data="data = $event"
  >
    <button
      type="button"
      class="modal__close-x"
      @click="hide"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
      ><path d="M13 0L1 12M1 0l12 12" /></svg>
    </button>
    <ReceiptData
      v-if="stepName === 'receiptData'"
      :key="pricingModalData && pricingModalData.plan"
      @completed="onReceiptCompleted"
      @show-step="onShowStep"
    />
    <!-- Payment has to be always rendered in order for Braintree to work -->
    <Success
      v-else
      :receipt-data="receiptData"
    />
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
import PaymentsService from 'services/payments.service';
import { mapGetters } from 'vuex';

import ReceiptData from './receipt';
import Success from './success';

export default {
  components: {
    BaseModal,
    ReceiptData,
    Success,
  },
  data: () => ({
    data: {},
    stepName: 'receiptData',
    receiptData: null,
  }),
  computed: {
    ...mapGetters(['modalActive', 'pricingModalData']),
    modalActive() {
      return this.$store.getters.modalActive;
    },
  },
  watch: {
    modalActive() {
      this.stepName = 'receiptData';
    },
  },
  methods: {
    onReceiptCompleted(data) {
      this.receiptData = data;
      this.stepName = 'success';
    },
    onShowStep(step) {
      this.stepName = step;
    },
    hide() {
      this.$store.dispatch('modalHide');
    },
    onHide() {
      this.stepName = 'receiptData';
      this.receiptData = null;
    },
  },
};
</script>
