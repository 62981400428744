<template>
  <div class="sidebar-tags__container">
    <h1 class="sidebar-tags__header">
      {{ titleName }}
    </h1>
    <SidebarTagsSelector
      v-if="canEdit"
      :value="sortedTags"
      :tags="items"
      :mode="mode"
      @input="tags => $emit('input', tags)"
      @itemAdd="$emit('itemAdd', $event)"
      @itemRemove="$emit('itemRemove', $event)"
    />
    <div class="sidebar-tags__list-outside-selector">
      <transition-group
        name="sidebar-tags"
        duration="100"
      >
        <TagListItem
          v-for="tag in sortedTagsForList"
          :key="tag.id"
          :color="tag.color"
          :mode="mode"
          :tag="tag"
          class="sidebar-tags__tag-list-item"
          @click="removeTag(tag.id)"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { alphabetically } from 'helpers/sort';
import { mapGetters } from 'vuex';

import SidebarTagsSelector from './sidebar-tags-selector';
import TagListItem from './tag-list-item';

export default {
  name: 'SidebarTags',
  components: {
    TagListItem,
    SidebarTagsSelector,
  },
  computed: {
    ...mapGetters(['tags', 'responders']),
    sortedTags() {
      return [...this.value || []].sort((a, b) => alphabetically(a.name, b.name));
    },
    sortedTagsForList() {
      return this.sortedTags.map((tag) => ({ ...tag, selected: false }));
    },
    canEdit() {
      return this.$store.getters.userCanEditProjects;
    },
    titleName() {
      return this.mode === 'responders' ? 'Respondents' : 'Tags';
    },
    items() {
      return this.mode === 'responders' ? this.responders : this.tags;
    },
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    mode: {
      type: String,
      default: 'tags',
    },
  },
  methods: {
    removeTag(tagId) {
      if (!this.canEdit) {
        return;
      }
      this.$emit('itemRemove', this.value.find((tag) => tag.id === tagId));
      this.$emit('input', this.value.filter((tag) => tag.id !== tagId));
    },
  },
};
</script>

<style lang="scss">
.sidebar-tags__container .sidebar-tags__list-outside-selector {
  .tag-list-item__name {
    padding-right: 40px;
    position: relative;
  }
  .tag-list-item:hover {
    .tag-list-item__name:after {
      content: '';
      width: 20px;
      height: 100%;
      background: image_url('svg/check.svg') no-repeat 0 0;
      background-size: 80% 100%;
      position: absolute;
      right: 10px;
      top: 0;
      opacity: 0.7;
    }
  }
}
</style>

<style lang="scss" scoped>
.sidebar-tags-leave-active,
.sidebar-tags-enter-active {
  transition: opacity 0.3s ease;
  position: absolute;
  width: calc(100% - 8px);
}
.sidebar-tags-leave-to {
  opacity: 0;
}
.sidebar-tags-move {
  transition: 0.5s cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.sidebar-tags {
  @mixin text {
    color: #646464;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
  }

  &__container {
    position: relative;
  }

  &__selector {
    @include text;
    cursor: pointer;
    justify-content: center;
    display: flex;
    margin-top: 8px;
    margin-bottom: 4px;
    background-color: #edeff0;
    border-radius: 4px;
    height: 40px;
    align-items: center;
    padding: 5px 10px;
    color: #a5adb4;
  }

  &__header {
    margin-top: 46px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.39;
    color: #252729;
  }

  &__tag-list-item {
    margin-left: 4px;
    margin-right: 4px !important;
    margin-top: 4px;
    cursor: pointer;
    &:hover {
      background-color: #eeeff2 !important;
    }
  }
}
</style>
