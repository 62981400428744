<template>
  <li>
    <SideDropdownMenu
      appearance="outside"
      :menu="menu"
      class="workspace-project"
    >
      <router-link
        :to="navigateTo"
        v-slot="{ navigate }"
        custom
      >
        <div class="workspace-project__body" @click="onLinkClick(navigate)">
          <div class="workspace-project__icon">
            <Icon
              appearance="box"
              name="lock"
              v-if="isLockedByLimit"
            />
            <Icon
              appearance="box transparent"
              name="folder"
              v-else
            />
          </div>
          <div class="workspace-project__name">
            {{ data.name }}
          </div>
          <div class="workspace-project__meta">
            {{ data.methodsCount }} Pages
          </div>
        </div>
      </router-link>
    </SideDropdownMenu>
  </li>
</template>

<script>
import { PROJECT_METHODS, PROJECT_REPORT } from 'constants/routes';
import { mapGetters } from 'vuex';

export default {
  name: 'WorkspaceRecent',
  props: {
    data: Object,
  },
  computed: {
    ...mapGetters(['isUserOwner']),
    isLockedByLimit() {
      return this.$store.getters.isLockedByLimit(this.data.id);
    },
    navigateTo() {
      return {
        name: PROJECT_METHODS,
        params: {
          project_id: this.data.id,
        },
      };
    },
    menu() {
      return [
        {
          text: 'Duplicate',
          action: () => this.onDuplicate(),
        },
        this.isUserOwner ? {
          text: 'Delete',
          appearance: 'warn',
          confirm: true,
          action: () => this.onRemove(),
        } : {
          text: 'Leave',
          confirm: true,
          action: () => this.onLeave(),
        },
      ]
    },
  },
  methods: {
    async onRemove() {
      this.$store.dispatch('projectRemove', this.data.id);
    },
    async onLeave() {
      this.$store.dispatch('projectLeave', this.data.id);
    },
    async onDuplicate() {
      await this.$store.dispatch('projectDuplicate', this.data.id);
    },
    onLinkClick(navigate) {
      if (this.isLockedByLimit) {
        this.$store.dispatch('modalShow', {
          name: 'limit-reached',
          data: {
            kind: 'project',
          },
        });

        return;
      }

      navigate();
    },
  },
};
</script>
