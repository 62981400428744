import { PRO, STARTER, TEAM } from 'constants/user';

export const isDowngraded = (current, next) =>
  (current === TEAM && (next === PRO || next === STARTER)) ||
  (current === PRO && next === STARTER) ||
  (current === next);

export const isUpgraded = (current, next) =>
  (current === STARTER && (next === PRO || next === TEAM)) ||
  (current === PRO && next === TEAM);
