import { parser } from 'models/invoice';
import * as ApiService from 'services/api.service';

class InvoiceService {
  async getInvoice() {
    const result = await ApiService.get('api/users/customer_informations');
    return parser(result);
  }
  async change(data) {
    const result = await ApiService.put('api/users/customer_informations', data);
    return parser(result);
  }
}

export default new InvoiceService();
