import padStart from 'lodash/padStart';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const MONTHS_ABBR = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const getMonthAbbr = (month) => {
  const index = month - 1;
  if (index < 0 || index > MONTHS_ABBR.length - 1) {
    return '';
  }

  return MONTHS_ABBR[month - 1];
};

export const getMonth = (month) => MONTHS[month - 1];

const pad = (text) => padStart(text, 2, '0');
export const inputFormat = (date) =>
  `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;

export const formatDate = (date) =>
  `${pad(date.getMonth() + 1)}/${pad(date.getDate())}/${date.getFullYear()}`;
