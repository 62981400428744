<template>
  <BaseModal
    class="modal__login small-bottom"
    :name="name"
  >
    <button
      type="button"
      class="modal__close-x"
      @click="hide"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
      ><path d="M13 0L1 12M1 0l12 12" /></svg>
    </button>
    <img
      src="/static/img/svg/key-plans.svg"
      width="209"
      height="181"
      alt=""
      class="modal__magic-link"
    >
    <template v-if="!lockedByOwner">
      <p class="modal__title">
        {{ title }}
      </p>
      <p class="modal__desc big">
        {{ descriptionPrefix }}You can {{ description }} by upgrading to higher plan, starting <b>${{ lowest }}/mo.</b>
      </p>
      <router-link
        :to="{ name: PRICE_PLANS }"
        @click="hide"
      >
        <button class="btn btn--orange modal__submit small" type="button">
          See plans
        </button>
      </router-link>
    </template>
    <template v-else>
      <p class="modal__title">
        Project’s owner subscription is inactive
      </p>
      <p class="modal__desc big">
        Ask <b>{{ lockedByOwner }}</b> to unlock the account.
      </p>
      <button
        type="button"
        class="btn btn--orange modal__submit small"
        @click="hide"
      >
        Close
      </button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
import { UPGRADE } from 'constants/modals';
import { PRICE_PLANS } from 'constants/routes';

export default {
  name: 'UpgradeModal',
  components: {
    BaseModal,
  },
  props: {
    title: String,
    description: String,
    descriptionPrefix: String,
    lockedByOwner: String,
  },
  computed: {
    PRICE_PLANS() {
      return PRICE_PLANS;
    },
    name() {
      return UPGRADE;
    },
    lowest() {
      return (Math.ceil(this.$store.getters.pricingNext*100)/100).toFixed(2);
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('modalHide');
    },
  },
};
</script>
