/* global __DEV__, __STAGING__ */
import { createApp } from 'vue';

import Vuex from 'vuex';
import VueClipboard from 'vue-clipboard2';
import VueInputAutowidth from 'vue-input-autowidth'
import vClickOutside from "click-outside-vue3"
import VueYouTubeEmbed from 'vue-youtube-embed'
import { sync } from 'vuex-router-sync';
import AOS from 'aos';
import store from 'stores';
import router from './router';

import 'whatwg-fetch';
//import 'filters';
//import 'components';

import * as Config from 'constants/config';
import ModalsService from 'services/modals.service';
import NotificationsService from 'services/notifications.service';
import ProjectService from 'services/project.service';
import ProjectMethodService from 'services/project-method.service';
import ShortcutService from 'services/shortcut.service';
import StorageService from 'services/storage.service';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import TemporaryUserService from 'services/temporary-user.service';
import TitleService from 'services/title.service';
import WorkspacesService from 'services/workspaces.service';
import registerBaseComponents from './autoregister';
import registerDirectives from  'directives';

// Template exporter to create PDF .erb templates
import 'helpers/template-exporter.js';

import App from 'src/app';

VueClipboard.config.appendToBody = false;

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueClipboard);
app.use(PerfectScrollbar);
app.use(VueInputAutowidth);
app.use(vClickOutside)
//app.use(PortalVue);
//app.use(VueYouTubeEmbed, { global: true, componentId: 'youtube-video' });
app.config.productionTip = false;
app.config.devtools = __DEV__ || __STAGING__ || __RELEASE__;
app.config.debug = __DEV__ || __STAGING__ || __RELEASE__;
app.config.silent = !__DEV__;

sync(store, router);

TemporaryUserService.init(store, router);
registerBaseComponents(app);
registerDirectives(app);

if (window.Cypress) {
  window.app = app;
}

router.isReady().then(() => {
  store.dispatch('init');

  WorkspacesService.init(store);
  ProjectService.init(store);
  ProjectMethodService.init(store);

  ShortcutService.init(store);
  ModalsService.init(store);
  NotificationsService.init(store);

  StorageService.init();
  TitleService.init();

  Config.init(store);

  app.mount('#app');
});

AOS.init({
  once: true,
  duration: 300,
  easing: 'ease-in-out',
});

document.addEventListener('keydown', (e) => {
  if (e.key === 'z' && e.metaKey) {
    e.preventDefault();
  }
});