<template>
  <div class="onb-step">
    <div class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          Welcome
        </h2>
        <p class="onb-subtitle">
          Choose your way to login / sign-up
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';
import GoogleLoginService from 'services/login.service/google';
import { analyticsLogEvent } from 'utils/analytics';

export default {
  name: 'SignInHome',
  data() {
    return {
      googleLoginFailed: false,
    }
  },
  created() {
    this.$emit('config', this.getConfig());

    GoogleLoginService.load().catch((e) => {
      let error = 'Google login load failed. Check your browser settings and try again.';

      if (e.details && e.details.match(/cookies/gim)) {
        error = 'Enable cookies to login with Google';
      }

      this.googleLoginFailed = error;

      this.updateConfig();
    });
  },
  mounted() {
    document.addEventListener('keydown', this.keyDown);
  },
  unmounted() {
    document.removeEventListener('keydown', this.keyDown);
  },
  methods: {
    keyDown(e) {
      if (e.key === 'Enter') {
        this.buttonAction('primary');
      }
    },
    getConfig() {
      return {
        buttons: [
          {
            appearance: 'outline light',
            id: 'google',
            text: 'Continue with Google',
            icon: 'google',
            disabled: this.googleLoginFailed,
            loading: this.googleLoading,
          },
          {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Continue with Email',
            icon: 'mail',
          },
        ],
      };
    },
    async buttonAction(id) {
      if (id === 'primary') {
        this.$emit('redirect', {
          route: ROUTES.SIGN_IN_EMAIL,
        })
        analyticsLogEvent('sign_in_email');
      } else if (id === 'google') {
        this.googleLoading = true;
        this.updateConfig();
        await this.login('google');
        this.googleLoading = false;
        this.updateConfig();
        analyticsLogEvent('sign_in_google');
      }
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
    async login(providerName) {
      await this.$store.dispatch('login', { providerName });
      this.$emit('success');
    },
  },
};
</script>
