/*
  USER
*/
export const LOGGED_OUT = 'LOGGED_OUT';
export const USER_SET = 'USER_SET';
export const USER_CHANGE = 'USER_CHANGE';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_PROVIDER = 'USER_PROVIDER';
export const SHARE_ENTRY = 'SHARE_ENTRY';
export const FORCE_PUBLIC_VIEW_SET = 'FORCE_PUBLIC_VIEW_SET';

/*
  UI
*/
export const UI_EDIT_TOGGLE = 'UI_EDIT_TOGGLE';
export const UI_SAVE_TOGGLE = 'UI_SAVE_TOGGLE';
export const UI_HEADER_TOGGLE = 'UI_HEADER_TOGGLE';
export const UI_GUEST_MODAL = 'UI_GUEST_MODAL';
export const UI_MODAL_SHOW = 'UI_MODAL_SHOW';
export const UI_MODAL_HIDE = 'UI_MODAL_HIDE';
export const UI_TIP_SHOWN = 'UI_TIP_SHOWN';
export const UI_REGISTER_ID_JOIN = 'UI_REGISTER_ID_JOIN';
export const UI_SET_CARD_DRAGGING = 'UI_SET_CARD_DRAGGING';
export const UI_LIGHTBOX_VISIBLE = 'UI_LIGHTBOX_VISIBLE';
export const UI_SET_ARCHIVING = 'UI_SET_ARCHIVING';
export const UI_SET_DRAG_OVER = 'UI_SET_DRAG_OVER';

/*
  PROJECTS
*/
export const PROJECTS_FETCHED = 'PROJECTS_FETCHED';
export const PROJECTS_FETCHING_START = 'PROJECTS_FETCHING_START';
export const PROJECTS_FETCHING_END = 'PROJECTS_FETCHING_END';
export const PROJECT_FETCHED = 'PROJECT_FETCHED';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECTS_ADD = 'PROJECTS_ADD';
export const PROJECTS_REMOVE = 'PROJECTS_REMOVE';
export const PROJECTS_ANIMATION = 'PROJECTS_ANIMATION';

// PHASES

export const PHASES_REMOVE = 'PHASES_REMOVE';
export const PHASES_ADD = 'PHASES_ADD';
export const PHASES_SET = 'PHASES_SET';
export const PHASES_UPDATE = 'PHASES_UPDATE';

/*
  NOTIFICATIONS
*/
export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE';

/*
  METHODS
*/
export const METHODS_FETCHED = 'METHODS_FETCHED';
export const METHOD_DESCRIPTION_READ = 'METHOD_DESCRIPTION_READ';

/*
  METHOD INTERVIEW
*/
export const METHOD_SECTIONS_SET = 'METHOD_SECTIONS_SET';
export const METHOD_LIBRARY_SET = 'METHOD_LIBRARY_SET';
export const METHOD_ANSWER_TESTERS = 'METHOD_ANSWER_TESTERS';
export const METHOD_ITEM_ADD = 'METHOD_ITEM_ADD';
export const METHOD_ITEM_REMOVE = 'METHOD_ITEM_REMOVE';
export const METHOD_ITEM_CHANGE = 'METHOD_ITEM_CHANGE';
export const METHOD_ANSWER_PAGE_CHANGE = 'METHOD_ANSWER_PAGE_CHANGE';
export const METHOD_REMOVE_TAG = 'METHOD_REMOVE_TAG';

/*
  METHOD PROBLEM VALUATION
*/
export const VALUATION_SET = 'VALUATION_SET';
export const VALUATION_ITEM_ADD = 'VALUATION_ITEM_ADD';
export const VALUATION_ITEM_REMOVE = 'VALUATION_ITEM_REMOVE';
export const VALUATION_ITEM_CHANGE = 'VALUATION_ITEM_CHANGE';

/*
  METHOD SOLUTION DEFINITION
*/
export const SOLUTION_DEFINITION_SET = 'SOLUTION_DEFINITION_SET';
export const SOLUTION_DEFINITION_ITEM_ADD = 'SOLUTION_DEFINITION_ITEM_ADD';
export const SOLUTION_DEFINITION_ITEM_CHANGE =
  'SOLUTION_DEFINITION_ITEM_CHANGE';
export const SOLUTION_DEFINITION_SECTION_ADD =
  'SOLUTION_DEFINITION_SECTION_ADD';

/*
  DETAILS PAGE
*/
export const DETAILS_PAGE_TITLE = 'DETAILS_PAGE_TITLE';

/*
  TESTERS
*/
export const TESTERS_SET = 'TESTERS_SET';
export const TESTER_ADD = 'TESTER_ADD';
export const TESTER_REMOVE = 'TESTER_REMOVE';
export const TESTER_UPDATE = 'TESTER_UPDATE';

/*
  TAGS
*/
export const TAGS_SET = 'TAGS_SET';
export const TAG_ADD = 'TAG_ADD';
export const TAG_REMOVE = 'TAG_REMOVE';
export const TAG_UPDATE = 'TAG_UPDATE';
export const TAG_SET = 'TAG_SET';
export const TAG_CARD_UPDATE = 'TAG_CARD_UPDATE';

/*
  TEAM
*/
export const TEAM_FETCHED = 'TEAM_FETCHED';
export const TEAM_MEMBER_ADD = 'TEAM_MEMBER_ADD';
export const TEAM_MEMBER_CHANGE = 'TEAM_MEMBER_CHANGE';
export const TEAM_MEMBER_REMOVE = 'TEAM_MEMBER_REMOVE';
export const TEAM_OWNERSHIP = 'TEAM_OWNERSHIP';
export const TEAM_ENABLED_CHANGE = 'TEAM_ENABLED_CHANGE';
export const TEAM_CHANGE_PROJECT_PRIVATE = 'TEAM_CHANGE_PROJECT_PRIVATE';

/*
  PROJECT
*/
export const PROJECT_CHANGE = 'PROJECT_CHANGE';

/*
  PROJECT METHODS
*/
export const PROJECT_METHODS_SET = 'PROJECT_METHODS_SET';
export const PROJECT_METHODS_REMOVE = 'PROJECT_METHODS_REMOVE';
export const PROJECT_METHOD_ADD = 'PROJECT_METHOD_ADD';
export const PROJECT_METHOD_REPLACE_TEMP = 'PROJECT_METHOD_REPLACE_TEMP';
export const PROJECT_METHOD_REMOVE = 'PROJECT_METHOD_REMOVE';
export const PROJECT_METHOD_CHANGE = 'PROJECT_METHOD_CHANGE';
export const PROJECT_METHOD_SET = 'PROJECT_METHOD_SET';
export const PROJECT_METHOD_REMOVING = 'PROJECT_METHOD_REMOVING';

/*
  SURVEY
*/
export const SURVEY_RESPONDER_SET = 'SURVEY_RESPONDER_SET';

/*
  PAYMENTS
*/
export const SHOW_PAYMENT_MODAL = 'SHOW_PAYMENT_MODAL';
export const PLANS_SET = 'PLANS_SET';
export const PAYMENT_METHODS_SET = 'PAYMENT_METHODS_SET';

/*
  SUBSCRIPTIONS
*/
export const SUBSCRIPTION_STATE = 'SUBSCRIPTION_STATE';
export const SUBSCRIPTION_PLAN = 'SUBSCRIPTION_PLAN';
export const SUBSCRIPTION_LIMITATIONS = 'SUBSCRIPTION_LIMITATIONS';
export const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL';

/*
  ANSWER_MODAL
*/

export const SET_MODAL_ANSWER = 'SET_MODAL_ANSWER';
export const UPDATE_MODAL_ANSWER = 'UPDATE_MODAL_ANSWER';
