<template>
  <BaseModal
    name="highlights"
    :show-close="true"
    @active="active = $event"
  >
    <div
      v-if="highlights"
      class="highlights-modal-grid"
    >
      <div class="highlights-modal-grid__header">
        <TitleInput
          appearance="xmd centered"
          value="Select highlights"
          :can-edit="false"
        />
      </div>
      <div class="highlights-modal-grid__body">
        <HighlightsList
          :can-select="true"
          :project-id="projectId"
          :exclude="exclude"
          @changeSelection="onChangeSelection"
        />
      </div>
      <div class="highlights-modal-grid__foot">
        <div class="method-modal-foot-btn">
          <button
            class="btn btn--primary"
            :class="{'btn--loading': isAdding}"
            :disabled="!selectedList.length"
            @click="onSelect"
          >
            Add selected ({{ selectedList.length }})
            <BaseSpinner v-if="isAdding" />
          </button>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
import HighlightsList from 'views/project-highlights/list';
import { mapGetters } from 'vuex';

export default {
  name: 'MethodModal',
  components: {
    BaseModal,
    HighlightsList,
  },
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      active: false,
      selectedList: [],
      isAdding: false,
    }
  },
  computed: {
    ...mapGetters(['highlights']),
    exclude() {
      return this.$store.getters.modalData.exclude;
    },
    projectId() {
      return this.$store.getters.modalData.projectId;
    },
  },
  watch: {
    active(active) {
      if (active) {
        this.fetch();
      }
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch('fetchHighlights');
    },
    onTagsChange(tags) {
      this.$store.dispatch('projectMethodChange', {
        id: this.methodId,
        data: {
          tags,
        },
        local: true,
      })
    },
    changeImage(image) {
      this.$store.dispatch('builderMethodChangeImage', {
        id: this.method.id,
        image,
      });
    },
    removeImage() {
      this.$store.dispatch('builderMethodRemoveImage', {
        id: this.method.id,
      });
    },
    onTitleChange(name) {
      this.$store.dispatch('projectMethodChange', {
        id: this.methodId,
        data: {
          name,
        },
      })
    },
    onChangeSelection(list) {
      this.selectedList = list;
    },
    async onSelect() {
      this.isAdding = true;

      if (this.$store.getters.modalData.onSelect) {
        await this.$store.getters.modalData.onSelect(this.selectedList);
      }
      this.$store.dispatch('modalHide');

      this.isAdding = false;
    },
  },
};
</script>
