import * as ApiService from 'services/api.service';

import GoogleLogin from './google';

const MAGIC_LINK_PROVIDER = 'magic_link';
const EMAIL_PROVIDER = 'email';

class LoginService {
  async login(provider, data = {}) {
    let tokens = data;
    if (provider !== MAGIC_LINK_PROVIDER && provider !== EMAIL_PROVIDER) {
      tokens = await this.connect(provider);
    }

    const accessToken = await this.fetchToken(provider, tokens);

    return {
      provider,
      accessToken,
    };
  }

  connect(providerName) {
    const provider = this.getProvider(providerName);
    return provider.login();
  }

  async fetchToken(provider, tokens) {
    const response = await ApiService.post('api/auth/tokens', {
      provider,
      ...tokens,
    });

    return response.accessToken;
  }

  getProvider(provider) {
    if (!this[provider]) {
      throw new Error(`No provider = ${provider} found!`);
    }

    return this[provider];
  }

  get google() {
    return GoogleLogin;
  }
}

export default new LoginService();
