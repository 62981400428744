<template>
  <div class="addon-video">
    <div class="sortable-handler addon-image__drag">
      <Icon name="drag" />
    </div>
    <div ref="playerHolder">
      <div ref="playerWrap" class="addon-video__player">
        <Player
          @timeupdate="onTimeupdate"
          @playing="onPlaying"
          @stop="onStop"
          ref="player"
        >
          <video
            controls
            crossorigin
            playsinline
            :data-poster="thumbnail"
          >
            <source
              :src="videoURL"
              type="video/mp4"
            />
          </video>
        </Player>
        <div class="addon-video__player-close" @click="onClose">
          <Icon name="close" />
        </div>
      </div>
      <div ref="placeholder" style="background: #DFE2E8;"></div>
    </div>
    <div class="addon-video__uploading" v-if="['uploading'].includes(status)">
      <BaseSpinner />
      Uploading ({{ uploadingProgress }}%)
    </div>
    <div class="addon-video__body" ref="body">
      <div  class="addon-video__captions" ref="captions" v-if="fields && fields.length">
        <div class="transcription-speaker__word-highlight" ref="wordHighlight" />
        <div
          v-if="showTags"
          ref="tagsWrap"
          class="wysiwyg__tags"
        >
          <div
            class="wysiwyg__tags-group"
          >
            <div
              v-for="tag of tags"
              :key="tag.id"
              class="wysiwyg__tags-item"
              :data-tag-id="tag.id"
              :data-y="tag.y"
              @mouseenter="onTagMouseEnter(tag)"
              @mouseleave="onTagMouseLeave(tag)"
              @click="tagClicked(tag)"
            >
              <span
                class="wysiwyg__tags-item-ico"
                :style="{ background: tag.color }"
              />
              {{ tag.name }}
            </div>
          </div>
        </div>
        <div
          class="addon-video__tags-selector"
          v-show="false"
          ref="respondersDropdown"
        >
          <Responders
            ref="tagsSelector"
            list="tags"
            :autofocus="true"
            @input="onTagsInput"
          />
        </div>
        <Sortable
          ref="sortable"
          :disabled="!canEdit"
          :select="canEdit"
          :animateList="false"
          selectAreaClass="addon-video"
          group="transcription"
          :data="fields"
          @selectend="onSelectEnd"
        >
          <div class="transcription-speaker" v-for="field of fields" :data-field-id="field.id" :data-id="field.id" :key="field.id">
            <div class="transcription-speaker__name" :data-name="field.name">
              <DropdownMenu :menu="speakerMenu(field)">
                <UserAvatar mode="email" :name="field.name" />
                {{ field.name }}
              </DropdownMenu>
              <small @click="setTime(field)">{{ videoSecondTime(field, true) }}</small>
            </div>
            <div class="transcription-speaker__text" @keydown="onKeyDown">
              <Editor
                :tags-enabled="true"
                :allow-image="false"
                :value="field.value"
                :can-edit="canEdit"
                :tags-list="allTags"
                @change="onInput(field, $event)"
                @content-tags-change="onContentTagsChange(field.id, $event)"
              />
            </div>
          </div>
        </Sortable>
        <div
          ref="multitag"
          class="builder-multitag"
          :style="multitagStyle"
          @mousedown.stop
        >
          <Responders
            ref="tagsSelector"
            :header-enabled="false"
            list="tags"
            @itemSelect="multitagSelect"
            @itemUnselect="multitagUnselect"
          />
        </div>
      </div>
      <template v-else-if="['uploading', 'uploaded'].includes(status)">
        <div>
          <strong class="addon-video__title">Transcribe this file</strong>
        </div>
        <BaseButton appearance="primary" @click="startTranscription" :disabled="!canTranscribe">Begin</BaseButton>
      </template>
      <template v-else-if="['waiting', 'pending'].includes(status)">
        <div>
          <strong class="addon-video__title">Transcription in progress…</strong>
          We’ll notify you when it is ready.
        </div>
        <div class="addon-video__loading">
          <BaseSpinner class="addon-video__loader" />
        </div>
      </template>
      <template v-else-if="['failed'].includes(status)">
        <div>
          <strong class="addon-video__title">Something went wrong transcribing this video</strong>
        </div>
        <BaseButton appearance="primary" @click="startTranscription" :disabled="!canTranscribe">Try again</BaseButton>
      </template>
    </div>
  </div>
</template>

<script>
import EventsService from 'services/events.service';
import { videoSecondTime } from 'helpers/time';
import { avatar, getColor } from 'helpers/avatar';
import { getCaretPos, isHidden, setCaretPos, hasParentWithClasses } from 'helpers/ui';
import Editor from 'components/common/editor';
import { byPosition } from 'helpers/sort';

export default {
  name: 'PageItemVideo',
  components: {
    Editor,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
    showTags: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tags: [],
      shouldShowMultitagSelector: false,
    };
  },
  computed: {
    allTags() {
      return this.$store.getters.allTags;
    },
    transcription() {
      return this.$store.getters.transcriptionById(this.section.options && this.section.options.transcriptionId);
    },
    multitagStyle() {
      return { top: `${ this.lastSelectedY }px`, display: this.shouldShowMultitagSelector ? '' : 'none' };
    },
    fields() {
      return byPosition(this.section.customItemFields);
    },
    hasCaptions() {
      return this.captions;
    },
    video() {
      return this.section.video;
    },
    videoURL() {
      if (typeof this.section.video === 'string') {
        return this.section.video;
      }

      if (this.section.video) {
        return URL.createObjectURL(this.section.video.file);
      }

      return null;
    },
    thumbnail() {
      if (typeof this.section.image === 'string') {
        return this.section.image;
      }

      return this.section.image && this.section.image.blob;
    },
    activeUpload() {
      return this.$store.getters.processNotifications.find((n) => n.sectionId === this.section.id);
    },
    status() {
      if (this.transcription) {
        return this.transcription && this.transcription.status;
      }

      if (this.activeUpload) {
        return 'uploading';
      }

      if (this.video && this.video.uploading) {
        return 'uploading';
      }

      if (this.video) {
        return 'uploaded';
      }

      return 'undefined';
    },
    canTranscribe() {
      return this.status === 'uploaded' || this.status === 'failed';
    },
    uploadingProgress() {
      let progress = this.video && Math.round(this.video.progress);

      if (this.activeUpload) {
        progress = this.activeUpload.progress;
      }

      if (!progress) {
        progress = 0;
      }

      return Math.round(progress * 100);
    },
    transcriptionProgress() {
      let percent = this.transcription.progress * 100;

      if (this.transcription && this.transcription.stats && this.transcription.stats.progressPercent) {
        percent = this.transcription.stats.progressPercent;
      }

      return Math.round(percent);
    },
    pureCaptions() {
      return this.transcription && this.transcription.result && this.transcription.result.transcription;
    },
    captions() {
      if (!this.pureCaptions) {
        return null;
      }

      let list = [];

      this.pureCaptions[0].forEach(([speaker, start, end, text], index) => {
        let prev = list[list.length - 1];

        if (!prev || prev.speaker !== speaker) {
          const name = `Speaker ${speaker}`;

          list.push({
            speaker,
            name,
            start: start,
            words: [],
            text: '',
            color: getColor(avatar(name)),
          });
          prev = list[list.length - 1];
        }

        prev.words.push([start, end, text, index]);
        prev.text = prev.text + ' ' + `<timestamp data-st="${start}" data-et="${end}">${text}</timestamp>`;
      });

      return list;
    },
    speakers() {
      let list = [];

      this.fields.forEach(({ name }, index) => {
        const speaker = name;
        !list.includes(speaker) && list.push(speaker);
      });

      return list;
    },
  },
  watch: {
    section: {
      handler(next, prev) {
        if (next.focus || next.focus === 0) {
          this.focus(next.focus);
        }
      },
      deep: true,
    },
    thumbnail(thumbnail) {
      this.$refs.player.setThumbnail(thumbnail);
    },
  },
  created() {
    this.selectionStart = null;
    this.selectionEnd = null;
    window.addEventListener('scroll', this.refreshVisibility);
  },
  mounted() {
    EventsService.on('video:play', this.videoPlayHandler);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.refreshVisibility);
    EventsService.off('video:play', this.videoPlayHandler);
  },
  updated() {
  },
  methods: {
    refreshVisibility() {
      const speakers = this.$el.querySelectorAll('.transcription-speaker');

      speakers.forEach((speaker) => {
        const rect = speaker.getBoundingClientRect();

        if (rect.bottom > 0 && rect.top < window.innerHeight) {
          speaker.style.visibility = '';
        } else {
          speaker.style.visibility = 'hidden';
        }
      });

      const rect = this.$refs.playerHolder.getBoundingClientRect();

      if (rect.top < 0 || rect.bottom > window.innerHeight) {
        this.miniEnable();
      } else {
        this.miniDisable();
      }
    },
    videoPlayHandler(comp) {
      if (comp.$el !== this.$el) {
        this.onClose();
      }
    },
    onPlaying() {
      EventsService.emit('video:play', this);
      this.isPlayerOpen = true;
      this.refreshVisibility();
    },
    onStop() {
      const highlight = this.$refs.wordHighlight;
      highlight.style.display = 'none';
    },
    onClose() {
      this.isPlayerOpen = false;
      this.miniDisable();
      this.$refs.player.stop();
    },
    miniEnable() {
      if (this.miniEnabled || !this.isPlayerOpen) {
        return;
      }

      const player = this.$refs.playerWrap;
      const placeholder = this.$refs.placeholder;
      const width = player.offsetWidth;
      const height = player.offsetHeight;

      Object.assign(placeholder.style, {
        width: width + 'px',
        height: height + 'px',
        display: 'block',
      });

      Object.assign(player.style, {
        position: 'fixed',
        right: '20px',
        bottom: '20px',
        width: '400px',
        height: 'auto',
        zIndex: 99999,
      });

      player.classList.add('addon-video__player--mini');

      this.miniEnabled = true;
    },
    miniDisable() {
      if (!this.miniEnabled) {
        return;
      }

      const player = this.$refs.playerWrap;
      const placeholder = this.$refs.placeholder;

      if (placeholder) {
        placeholder.style.display = 'none';
      }
      
      if (player) {
        player.style.position = 'static';
        player.style.width = 'auto';
      }

      player.classList.remove('addon-video__player--mini');

      this.miniEnabled = false;
    },
    startTranscription() {
      this.$store.dispatch('transcribeVideo', { id: this.section.id });
    },
    setTime(field) {
      let time = this.videoSecondTime(field);
      this.$refs.player.setCurrentTime(time);
      this.$refs.player.play();
    },
    onTimeupdate(e, player) {
      const words = this.$el.querySelectorAll('tspm');
      const time = player.currentTime;
      const highlight = this.$refs.wordHighlight;
      const body = this.$refs.body;

      if (typeof time !== 'number') {
        return;
      }

      words.forEach((w) => {
        const start = parseFloat(w.dataset.st);
        const end = parseFloat(w.dataset.et);
        
        if (time >= start && time < end) {
          const rect = w.getBoundingClientRect();
          const parent = body;
          const parentRect = parent.getBoundingClientRect();
          const top = rect.top - parentRect.top;
          const left = rect.left - parentRect.left;
          const width = rect.width;
          const height = rect.height;

          Object.assign(highlight.style, {
            top: top + 'px',
            left: left + 'px',
            width: width + 'px',
            height: height + 'px',
          })
        }
      });

      highlight.style.display = '';
    },
    async regenerateFields() {
      for (const field of this.fields) {
        await this.$store.dispatch('builderRemoveSectionItem', { sectionId: this.section.id, id: field.id });
      }

      for (const caption of this.captions) {
        await this.$store.dispatch('builderAddSectionItem', { id: this.section.id, data: {
          name: caption.name,
          value: caption.text,
          color: caption.start,
          options: {
            startTime: caption.start,
          },
        } });
      }
    },
    async onInput(field, { value, before, after }) {
      let position = this.fields.findIndex((f) => f.id === field.id);

      if (before) {
        before.forEach((item, i) => {
          this.$store.dispatch('builderAddSectionItem', { id: this.section.id, data: {
            name: field.name,
            value: item.content,
          }, position });
        })
      }

      this.$store.dispatch('builderUpdateSectionItem', { id: field.id, data: {
        value,
      } });
    },
    onContentTagsChange(fieldId, sentences) {
      const tagsList = this.$store.getters.tags;
      let contentTags = (sentences || []).map((d) => ({
        tagList: d.tagList,
        text: d.content,
        editorId: d.editorId,
        startAt: d.startAt,
        endAt: d.endAt,
      }));

      contentTags = contentTags.map((contentTag) => ({
        ...contentTag,
        tagList: contentTag.tagList.filter((tag) => {
          return tagsList.find((t) => t.id === tag);
        }),
      }));

      this.$store.dispatch('builderUpdateSectionItem', { id: fieldId, data: {
        contentTags,
      } });
    },
    speakerMenu({ id, name }) {
      return [
        ...this.speakers.map((speaker) => ({
          text: speaker,
          selected: name === speaker,
          action: () => {
            this.$store.dispatch('builderUpdateSectionItem', { id, data: {
              name: speaker,
            } });
          },
        })),
      ];
    },
    onKeyDown(e) {
      const { target } = e;
      let fieldEl = hasParentWithClasses(target, ['transcription-speaker']);
      let fieldId = fieldEl.dataset.fieldId;
      const inputs = Array.from(this.$el.querySelectorAll('[contenteditable]')).filter((e) => !isHidden(e));
      let caretPos = getCaretPos(target);

      let nextInput, prevInput, found;
      inputs.some((input, index) => {
        if (input === target) {
          found = true;
        } else if (found) {
          nextInput = input;
          return true;
        } else {
          prevInput = input;
        }
      });

      if (e.key === 'Backspace') {
        if (prevInput && caretPos === 0) {
          let emptyParagraph = /\<[a-z0-9]+\>\<br\>\<\/[a-z0-9]+\>/gim;
          let prevHtml = prevInput.innerHTML.replace(emptyParagraph, '');
          let targetHtml = target.innerHTML.replace(emptyParagraph, '');

          if (prevHtml) {
            prevHtml = prevHtml + targetHtml;
          } else {
            prevHtml = targetHtml;
          }
          let newHtml = prevHtml.replace('</p><p>', '');
          let focusPos = prevInput.innerText.replace(/[\r\n]/gim, '').length;
          prevInput.innerHTML = newHtml;

          setCaretPos(prevInput, focusPos);

          this.$store.dispatch('builderRemoveSectionItem', { sectionId: this.section.id, id: fieldId });
          e.preventDefault();
        }
      }
    },
    videoSecondTime(field, format) {
      const match = field.value && field.value.match(/data\-st\=\"(\d+(\.\d+)*)\"/gim)
      let time = 0;

      if (match && match[0]) {
        time = match[0].match(/\d+(\.\d+)*/gim)[0];
      }

      if (format) {
        return videoSecondTime(time);
      }

      return parseFloat(time);
    },
    onSelectEnd(ids) {
      this.$nextTick(() => {
        this.refreshMultitagVisibility(ids);
      });
    },
    refreshMultitagVisibility(ids = []) {
      let selected = Array.from(document.querySelectorAll('.transcription-speaker.sortable-selected'));
      if (selected.length && ids.length) {
        const last = selected[selected.length - 1];
        const parent = this.$refs.body.getBoundingClientRect();

        this.lastSelectedY = last.getBoundingClientRect().top + last.offsetHeight - parent.top;
        this.shouldShowMultitagSelector = true;
      } else {
        this.shouldShowMultitagSelector = false;
      }
    },
    multitagSelect(tagId) {
      const selected = Array.from(document.querySelectorAll('.transcription-speaker.sortable-selected'));

      selected.forEach((speaker) => {
        const editor = speaker.querySelector('.wysiwyg');

        editor.__vue__ && editor.__vue__.addTag(tagId);
      })
    },
    multitagUnselect(tagId) {
      const selected = Array.from(document.querySelectorAll('.transcription-speaker.sortable-selected'));

      selected.forEach((speaker) => {
        const editor = speaker.querySelector('.wysiwyg');

        editor.__vue__ && editor.__vue__.removeTag(tagId);
      })
    },
  },
};
</script>