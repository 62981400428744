import * as ApiService from 'services/api.service';

class CardsService {
  update(workspace, projectId, id, data) {
    return ApiService.put(`${this.getUrl(workspace, projectId)}/${id}`, data);
  }

  remove(workspace, projectId, id) {
    return ApiService.del(`${this.getUrl(workspace, projectId)}/${id}`);
  }

  reorder(workspace, projectId, data) {
    return ApiService.put(`api/workspaces/${workspace}/projects/${projectId}/cards/reorder`, data);
  }

  getUrl(workspace, projectId) {
    return `api/workspaces/${workspace}/projects/${projectId}/cards`;
  }

  get model() {
    return MODEL;
  }
}

export default new CardsService();
