<template>
  <SideDropdownMenu
    :menu="menu"
    appearance="outside-middle"
  >
    <div
      class="insight-item"
      :class="{
        'insight-item--locked': isLockedByLimit,
      }"
      @click="$emit('open')"
    >
      <img
        v-if="image"
        :src="image"
        class="insight-item__image"
      >
      <Icon
        v-else
        class="insight-item__image"
        name="insight-illu"
      />
      <div class="insight-item__body">
        <span class="insight-item__date">
          {{ lastModified }}
          <span v-if="showProjectName">{{projectName}}</span>
        </span>
        <h2 class="insight-item__title">
          {{ name }}
        </h2>
        <div class="insight-item__foot">
          <!--<span class="insight-type-badge" :class="`insight-type-badge--${type}`">{{type}}</span>-->
          <span class="insight-item__tags">
            <span
              v-for="tag of tagsList"
              :key="tag.id"
              class="tag-badge"
            >
              <span
                class="tag-colour"
                :style="{background: tag.color}"
              />
              {{ tag.name }}
            </span>
            <span
              v-if="more"
              class="insight-item__more-tags"
            >{{ more }} more</span>
          </span>
        </div>
      </div>
      <div class="insight-type-badge insight-type-badge--locked" v-if="isLockedByLimit">
        <Icon name="lock" />
        Inactive
      </div>
    </div>
  </SideDropdownMenu>
</template>

<script>
import { toRaw } from 'vue';
import { timeSinceText } from 'helpers/time';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectMethodContent',
  props: {
    canEdit: Boolean,
    insightId: {
      type: String,
      required: true,
    },
    showProjectName: Boolean,
  },
  computed: {
    ...mapGetters(['allTags']),
    isLockedByLimit() {
      return this.$store.getters.isLockedByLimit(this.insightId);
    },
    insight() {
      return this.$store.getters.projectMethodById(this.insightId);
    },
    name() {
      return this.insight.name;
    },
    type() {
      return this.insight.insightType || 'neutral';
    },
    lastModified() {
      return timeSinceText(this.insight.createdAt);
    },
    image() {
      return this.insight.image;
    },
    tagsList() {
      return this.insight.tags && this.insight.tags.slice(0, 2).map((tag) => this.allTags.find((t) => t.id === (typeof tag === 'string' ? tag : tag.id))).filter((t) => !!t);
    },
    more() {
      return this.insight.tags && this.insight.tags.length > 2 && this.insight.tags.length - 2;
    },
    menu() {
      return this.canEdit && [{
        text: 'Archive',
        action: () => {
          this.removeInsight();
        },
      }]
    },
    projectName() {
      return this.insight.projectName;
    },
  },
  methods: {
    async removeInsight() {
      await this.$store.dispatch('projectMethodChange', { id: this.insight.id, data: { archived: true } });
    },
  },
};
</script>