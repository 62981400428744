import { hashMD5 } from 'utils/text';

const MAX_COLOR_INDEX = 8;
export const getColor = (avatar) =>
  avatar
    .split('')
    .reduce((total, letter, index) => total + avatar.charCodeAt(index), 0) %
  (MAX_COLOR_INDEX + 1);

export const avatar = (name) => {
  const split = name.replace(/\@.*/gim, '').split(/[\W\.\-\_]+/gim);
  // eslint-disable-next-line no-confusing-arrow
  const parts = split
    .map((part = '') => (part.length ? part[0].toUpperCase() : ''))
    .join('');

  if (!parts.length) {
    return 'T';
  }

  return parts.slice(0, 2);
};

const URL = 'https://www.gravatar.com/avatar';
export const gravatar = (email) => {
  const hash = hashMD5(email);
  return `${URL}/${hash}?d=404`;
};
