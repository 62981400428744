<template>
  <div class="color-picker">
    <BaseTooltip
      behaviour="click"
      appearance="light-box"
      @toggle="(state) => open = state"
    >
      <button
        v-if="!$slots.default"
        class="color-picker__button"
        :class="{ 'color-picker__button--open': this.open }"
        :style="colorStyle"
      />
      <slot />
      <template v-slot:content>
        <div class="color-picker__tooltip">
          <div class="color-picker__section">
            <span class="color-picker__title">Change tag color</span>
            <Swatches
              appearance="squared"
              :color="color"
              @change="swatchChange"
            />
          </div>
          <div class="color-picker__section">
            <div class="color-input">
              <div
                class="color-input__color"
                :style="colorStyle"
              />
              <div class="color-input__wrap">
                <BaseInput
                  class="color-input__input"
                  :value="inputColor"
                  @input="inputChange"
                />
              </div>
            </div>
          </div>
          <div
            v-if="actions"
            class="color-picker__section"
          >
            <ul class="color-picker__actions">
              <li
                v-for="action of actions"
                :key="action.name"
              >
                <a
                  @click="action.callback"
                >
                  {{ action.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>

<script>
import { validateHexColor } from 'helpers/colors';

import Swatches from './swatches';

export default {
  name: 'ColorPicker',
  components: {
    Swatches,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    actions: {
      type: [Array, null],
      default: null,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    colorStyle() {
      return { backgroundColor: this.color };
    },
    inputColor() {
      return this.color.replace(/^\#/gim, '');
    },
  },
  watch: {
    open(open, old) {
      if (open !== old) {
        this.$emit('toggle', open);
      }
    },
  },
  methods: {
    swatchChange(color) {
      this.open = false;
      this.$emit('change', color);
    },
    inputChange(color) {
      color = '#' + color.replace(/^\#/gim, '');
      if (!validateHexColor(color)) return;
      this.$emit('change', color);
    },
  },
};
</script>
