<template>
  <DropdownMenu
    :menu="menu"
    appearance="light-box-md"
  >
    <button
      type="button"
      class="titled-icon"
    >
      <Icon name="share" />
      <span class="titled-icon__text">Share</span>
    </button>
  </DropdownMenu>
</template>

<script>
import * as ROUTES from 'constants/routes';
import { navigateTeam } from 'helpers/router';
import NotificationsService from 'services/notifications.service';
import { PAGE_RECORD_TYPE_MAP } from 'services/shortcut.service';
import { analyticsLogEvent } from 'utils/analytics';

export default {
  name: 'ButtonShare',
  props: {
    methodId: {
      type: String,
    },
    hasSurveyLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    active: false,
    copied: false,
    publicLinkDeleting: false,
  }),
  computed: {
    methodPublicLinkTokenLoading() {
      return this.$store.getters.methodPublicLinkTokenLoading(this.methodId);
    },
    menu() {
      return [{
        key: 'share',
        icon: 'globe',
        text: this.url ? 'Public link' : 'Create public link',
        desc: this.publicLinkDeleting ? 'Removing public link...' : (!this.url && (this.methodPublicLinkTokenLoading ? 'Generating public link...' : 'Anyone with the link can view')),
        descIcon: (this.methodPublicLinkTokenLoading || this.publicLinkDeleting) && 'loader',
        linkBox: this.url,
        button: this.url && {
          text: 'Delete public link',
          appearance: "link",
          onClick: async () => {
            this.publicLinkDeleting = true;
            await this.$store.dispatch('projectMethodDestroyPublicLink', { id: this.methodId });
            this.publicLinkDeleting = false;
          },
        },
        appearance: 'box-icon box-icon-top',
        className: 'share-link--public',
        action: () => {
          if (!this.url) {
            this.$store.dispatch('projectMethodCreatePublicLink', { id: this.methodId })
              .then(() => {
                this.$copyText(this.url);
                NotificationsService.showInfo('Link copied to clipboard');
              })

            return false;
          }
        },
        closeOnAction: false,
      }, this.hasSurveyLink && {
        icon: 'edit-3',
        text: 'Invite respondent link',
        desc: 'Data input only. Can’t see data',
        action: () => {
          this.$copyText(this.respondentUrl);
          NotificationsService.showInfo('Link copied to clipboard');
        },
        appearance: 'box-icon',
      }, {
        icon: 'user-plus',
        text: 'Invite team member',
        desc: 'Edit and view',
        appearance: 'box-icon',
        action: () => this.navigateTeam(),
      }];
    },
    classes() {
      return {
        'is-active': this.active,
      };
    },
    itemId() {
      const getters = this.$store.getters;

      const ITEMS_MAP = {
        [ROUTES.PROJECT_METHODS]: getters.projectCurrent,
        [ROUTES.PROJECT_METHOD]: getters.projectCurrentMethod,
        [ROUTES.PROJECT_METHOD_PAGE]: { id: this.$route.params.parent_id },
      };

      const item = ITEMS_MAP[this.$route.name];
      return item ? item.id : null;
    },
    recordType() {
      const getters = this.$store.getters;

      const RECORD_TYPES_MAP = {
        [ROUTES.PROJECT_METHODS]: () => 'Project',
        [ROUTES.PROJECT_METHOD]: () => 'DesignMethod',
        [ROUTES.PROJECT_METHOD_PAGE]: () => {
          const { type } = getters.projectCurrentMethod;
          return PAGE_RECORD_TYPE_MAP[type];
        },
      };

      const item = RECORD_TYPES_MAP[this.$route.name];
      return item ? item() : null;
    },

    isTeamOnly() {
      return false;
    },
    // isTeamOnly: {
    //   get() {
    //     if (!this.item) return true;

    //     return !this.item.public;
    //   },
    //   set(teamOnly) {
    //     const isPublic = !teamOnly;
    //     this.changeType(isPublic);
    //   },
    // },
    respondentUrl() {
      if (!this.hasSurveyLink) return '';

      const { href } = this.$router.resolve({
        name: ROUTES.PROJECT_SURVEY,
        params: {
          project_id: this.$store.getters.projectCurrentId,
          method_id: this.$store.getters.projectCurrentMethodId,
        },
      });
      return `${ window.location.origin }${ href }`;
    },

    url() {
      if (this.publicLinkDeleting) {
        return null;
      }

      if (!this.hash || !this.hash.length) {
        return null;
      }

      const { href } = this.$router.resolve({
        name: ROUTES.PROJECT_SHORT,
        params: {
          shortcut: this.hash,
        },
      });
      return `${ window.location.origin }${ href }`;
    },
    hash() {
      return this.$store.getters.methodPublicLinkToken(this.methodId);
    },
    hasHash() {
      return this.hash !== null;
    },
  },
  methods: {
    async onClick() {
      // if (!this.shortcut && !this.fetchedShortcut) {
      //   await this.obtainShortcut();
      // }

      this.active = true;
    },
    hide() {
      this.active = false;
      this.copied = false;
    },
    onCopy(type) {
      this.copied = type;

      const projectCurrentMethod = this.$store.getters.projectCurrentMethod;
      analyticsLogEvent(`Share ${ type } link - ${ projectCurrentMethod && projectCurrentMethod.type }`);
    },

    navigateTeam() {
      navigateTeam(this.$store.getters.projectCurrentId);
    },

    // async obtainShortcut() {
    //   const recordType = this.recordType;
    //   const id = this.itemId;

    //   const shortcut = await obtainShortcut(recordType, id);
    //   this.fetchedShortcut = shortcut;

    //   return shortcut;
    // },

    // changeType(isPublic) {
    //   const ACTION_MAP = {
    //     [ROUTES.PROJECT_METHODS]: {
    //       action: 'projectChange',
    //       payload: { public: isPublic },
    //     },
    //     [ROUTES.PROJECT_METHOD]: {
    //       action: 'projectMethodChange',
    //       payload: {
    //         id: this.$store.getters.projectCurrentMethodId,
    //         data: { public: isPublic },
    //       },
    //     },
    //     // [ROUTES.PROJECT_METHOD_PAGE]: ,
    //   };

    //   const data = ACTION_MAP[this.$route.name];
    //   if (!data) return;

    //   this.$store.dispatch(data.action, data.payload);
    // },
  },
};
</script>
<style lang="scss" local>
.share-dropdown__link {
  margin: -5px;
  margin-left: -70px;
  padding: 5px;
  padding-left: 70px;

  // Tutorial highlight
  &:before {
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
}
</style>
<style lang="scss">
.share-dropdown__link.tutorial-highlighted-element {
  &:before {
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
  }
}
</style>
