import { API_URL } from 'config';
import { deserialize, serialize } from 'helpers/api';
import store from 'stores';
import { to as toQueryString } from 'utils/query-params';

const getContentType = (data = {}) => {
  if (data instanceof FormData) {
    // so that browser will decide
    return undefined;
  }

  return 'application/json';
};

export const createUrl = (path) => `${API_URL}/${path}`;
const handleError = (error) => {
  // eslint-disable-next-line no-console
  console.warn('[API] error', error);
  return Promise.reject(error.message ? { error: error.message } : error);
};

const headers = (data = {}) => {
  const result = {};
  const extra = {};

  const contentType = getContentType(data);
  if (contentType) {
    result['Content-Type'] = contentType;
  }

  const isLoggedIn = store.getters.isLoggedIn;
  const accessToken = store.getters.accessToken;
  const surveyResponderId = store.getters.surveyResponderId;

  if (accessToken) {
    result['Authorization'] = `Bearer ${accessToken}`;
  }

  else if (surveyResponderId) {
    result['Guest_user_token'] = surveyResponderId;
  }

  return result;
};

export const get = (path, query = {}) => {
  if (store.getters.shortcutToken) {
    query.shortcut = store.getters.shortcutToken;
  }

  const queryString = toQueryString(query);
  const url = `${createUrl(path)}${queryString}`;

  return window
    .fetch(url, {
      headers: headers(),
    })
    .then(async (response) => {
      if (!response.ok) {
        return response.json().then((e) => Promise.reject(e));
      }

      let guestUserToken = response.headers.get('Guest_user_token');

      let data = await response.json();

      if (guestUserToken) {
        data['guest_user_token'] = guestUserToken;
      }

      return data;
    })
    .then((response) => deserialize(response))
    .catch((error) => handleError(error));
};

export const post = (path, data, query = {}) => {
  const queryString = toQueryString(query);
  const url = `${createUrl(path)}${queryString}`;
  const body = serialize(data);

  return window
    .fetch(url, {
      method: 'POST',
      headers: headers(body),
      body,
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((e) => Promise.reject(e));
      }

      if (response.status === 204) {
        return {};
      }

      return response.json().catch(() => ({}));
    })
    .then((response) => deserialize(response))
    .catch((error) => handleError(error));
};

export const put = (path, data, query = {}) => {
  const queryString = toQueryString(query);
  const url = `${createUrl(path)}${queryString}`;
  const body = serialize(data);
  return window
    .fetch(url, {
      method: 'PUT',
      headers: headers(body),
      body,
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((e) => Promise.reject(e));
      }

      if (response.status === 204) {
        return {};
      }

      return response.json();
    })
    .then((response) => deserialize(response))
    .catch((error) => handleError(error));
};

export const del = (path, data) => {
  const url = createUrl(path);
  const body = serialize(data);

  return window
    .fetch(url, {
      method: 'DELETE',
      headers: headers(),
      body,
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((e) => Promise.reject(e));
      }

      if (response.status === 204) {
        return {};
      }

      return response.json();
    })
    .then((response) => deserialize(response))
    .catch((error) => handleError(error));
};
