<template>
  <button
    v-bind="$attrs"
    data-v-44e70fc2=""
    type="button"
    class="base-button-submit"
  />
</template>

<script>
export default {
  name: 'BaseButtonSubmit',
};
</script>

<style scoped lang="scss">
.base-button-submit {
  background: #f3f6f8 image_url('svg/enter.svg') no-repeat center center;
  width: 26px;
  height: 26px;
  border-radius: 2px;
  background-color: #f3f6f8;
  border: 0;
  z-index: 10;
  transition: all 0.4s ease;
}
</style>
