import { serialize } from 'helpers/api';
import * as ApiService from 'services/api.service';
import { createUrl } from 'services/api.service';
import uuid from 'utils/id';

class SurveyService {
  async saveAnswer(id, data) {
    const output = await ApiService.post('api/public/custom_item_fields/', {
      custom_item_field: {
        custom_method_item_id: id,
        ...data,
      },
    });
    return output;
  }
  async registerUser({ id, name, projectId, customMethodId }) {
    const output = await ApiService.put('api/public/guest_users/', {
      customMethodId,
      guest_user: {
        name,
      },
    });
    return output;
  }
  async createUser({ name, email, projectId, customMethodId }) {
    return window.fetch(createUrl('api/public/guest_users/'), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: serialize({
        customMethodId,
        guest_user: {
          name,
        },
      }),
    })
    .then(async (response) => {
      let guestUserToken = response.headers.get('Guest_user_token');
      return guestUserToken;
    });
  }
}

export default new SurveyService();
