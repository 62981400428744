export const byPosition = (items = []) =>
  [...items].sort((a, b) => a.position - b.position);

export const alphabetically = (a, b) => {
  const aUp = a.toUpperCase();
  const bUp = b.toUpperCase();

  if (aUp < bUp) {
    return -1;
  } else if (aUp > bUp) {
    return 1;
  }
  return 0;
};

export const sortBy = (valueGetter) => {
  return function (a, b) {
    const aUp = valueGetter(a)
    const bUp = valueGetter(b);

    if (aUp < bUp) {
      return -1;
    } else if (aUp > bUp) {
      return 1;
    }
    return 0;
  }
};

export const sortByParam = (list, param) => {  
  if (param === 'created') {
    return [...list].sort(sortBy((a) => a.createdAt)).reverse();
  }

  if (param === 'tags') {
    return [...list].sort(sortBy((a) => {
      const tag = a.tags[0] || '0';
      return tag;
    })).reverse();
  }

  if (param === 'project') {
    return [...list].sort(sortBy((a) => a.projectId)).reverse();
  }

  return list;
}