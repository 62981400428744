<template>
  <Forbidden v-if="isProjectForbidden" />
  <div
    v-else-if="isHighlightsFetched"
    class="sidebar-page"
  >
    <div class="container">
      <div
        class="highlights-container"
        data-aos="fade-in"
      >
        <TitleInput
          appearance="project-title"
          value="Highlights"
          :can-edit="false"
        />
        <HelpBanner name="highlights" />
        <InsightsList :project-id="projectCurrentId" />
      </div>
    </div>
  </div>
  <Spinner v-else />
</template>

<script>
import Spinner from 'components/common/base-spinner';
import { getSentencesFromEditor } from 'components/common/editor';
import { PROJECT_TAG } from 'constants/routes';
import { TAG_COLORS } from 'constants/tag-colors';
import { uniqueByKey } from 'helpers/array';
import { navigateTags, navigateToMethod, navigateToTag } from 'helpers/router';
import { sortBy } from 'helpers/sort';
import { capitalize } from 'helpers/string';
import { timeText } from 'helpers/time';
import sample from 'lodash/sample';
import { mapGetters } from 'vuex';

import Forbidden from 'components/forbidden';
import InsightsList from './list';

export default {
  name: 'ProjectTags',
  components: {
    Spinner,
    Forbidden,
    InsightsList,
  },
  data: () => ({
    newInput: '',
    filterTags: [],
    filterMethods: [],
    timeRange: '',
    searchText: '',
    showTagId: '',
    sortBy: 'created',
    activeTooltip: '',
  }),
  computed: {
    ...mapGetters([
      'tags',
      'highlights',
      'projectCurrent',
      'projectCurrentId',
      'isHighlightsFetched',
      'userCanEditProjects',
      'isProjectForbidden',
      'projectMethods',
    ]),
    hasHighlights() {
      return !!this.highlights.length;
    },
    filterHighlights() {
      const searchRegexp = new RegExp(this.searchText, 'gim');
      const highlights = uniqueByKey(this.highlights, (h) => h.id).filter((h) => {
        return h.text && (!this.searchText || h.text.toLowerCase().indexOf(this.searchText) > -1);
      }).filter((h) => {
        return h.tags.find((t) => {
          return this.filterTags.find((st) => {
            return st.id === t.id;
          })
        })
      }).filter((h) => {
        return this.filterMethods.find((m) => {
          return m.id === h.designMethodId;
        })
      }).map((h) => ({
        ...h,
        tags: h.tags.sort(sortBy((t) => t.name.toLowerCase())),
      })).sort(sortBy((a) => {
        if (this.sortBy === 'tags') {
          return a.tags[0].name.toLowerCase();
        } else if (this.sortBy === 'methods') {
          return (this.$store.getters.projectMethodById(a.designMethodId) || {}).name;
        }

        return a.createdAt;
      })).map((h) => {
        return {
          ...h,
          text: !this.searchText ? h.text : h.text.replace(searchRegexp, (f) => {
            return `<b class="search-highlight">${f}</b>`;
          }),
        }
      });

      if (this.sortBy === 'created') {
        highlights.reverse();
      }

      return highlights;
    },
  },
  async created() {
    await this.fetchData();
    this.filterTags = [...this.tags];
    this.filterMethods = [...this.projectMethods];
  },
  methods: {
    async fetchData() {
      this.$store.dispatch('fetchHighlights');
    },
    navigateTo(tagId) {
      return {
        name: PROJECT_TAG,
        params: {
          project_id: this.projectCurrent && this.projectCurrent.id,
          tag_id: tagId,
        },
      };
    },
    onModalHide() {
      navigateTags(this.projectCurrent.id);
    },
    async addTag() {
      const randomColor = sample(TAG_COLORS);
      const newTag = {
        name: this.newTagName,
        color: randomColor,
      };
      const created = await this.$store.dispatch('tagAdd', newTag);
      navigateToTag(created.id);
    },
    onInputEnter() {
      this.addTag(this.newInput);
    },
    openTag(id) {
      this.showTagId = id;
    },
    hideTagModal() {
      this.showTagId = '';
    },
    timeText,
    capitalize,
    navigateToMethod(projectId, methodId) {
      navigateToMethod(projectId, methodId);
    },
    setSortBy(sortBy) {
      this.sortBy = sortBy;
    },
    onTagSelect(highlight, { id }, selected) {
      let editor = this.$refs.editor.find((e) => e.$el.dataset.highlightId === highlight.id);

      if (!editor) return;

      let quill = editor.getQuill();

      if (!quill) return;

      const offset = highlight.position.match(/__(\d+)--(\d+)$/i);
      const sentenceSelection = [offset[1] * 1, offset[2] * 1];
      let index = sentenceSelection[0];
      let length = sentenceSelection[1] - sentenceSelection[0];

      quill.setSelection(index, length);
      quill.format('tag', { tag: id, selected });

      let content = editor.getValue();
      let contentTags = getSentencesFromEditor(quill);

      this.$store.dispatch('changeHighlight', { id: highlight.id, content, contentTags });
    },
  },
};
</script>
