<template>
  <div
    class="swatches-picker"
    :class="[`swatches-picker--${ appearance }`]"
  >
    <ul class="swatches-picker__list">
      <li
        v-for="swatchColor of colors"
        :key="swatchColor"
        class="swatches-picker__item"
      >
        <button
          class="swatches-picker__swatch"
          :class="{ 'swatches-picker__swatch--selected': swatchColor === color }"
          :style="{ backgroundColor: swatchColor }"
          @click="swatchClick(swatchColor)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { PICKER_COLORS } from 'constants/colors';

import Swatches from './swatches';

export default {
  name: 'SwatchesPicker',
  components: {
  },
  props: {
    color: String,
    appearance: {
      type: String,
      default: 'default',
    },
    colors: {
      required: false,
      default: () => PICKER_COLORS,
    },
  },
  methods: {
    swatchClick(color) {
      this.$emit('change', color);
    },
  },
};
</script>
