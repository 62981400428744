export const INSPIRATION = 'inspiration';
export const IDEATION = 'ideation';
export const BLANK = 'blank';
export const TEMPLATES = 'templates';

export const MAIN_PHASES = [INSPIRATION, IDEATION];
export const ADD_PHASES = [BLANK, TEMPLATES];

export const MAIN_PHASES_NAMES = {
  [INSPIRATION]: 'Inspiration',
  [IDEATION]: 'Ideation',
};

export const ADD_PHASES_NAMES = {
  [BLANK]: 'Blank pages',
  [TEMPLATES]: 'Templates',
};
