export const TRIAL = 'trial';
export const STARTER = 'starter';
export const PERSONAL_NEW = 'personal_2020';
export const PRO = 'pro';
export const PRO_NEW = 'pro_2020';
export const TEAM = 'team';
export const TEAM_NEW = 'team_2020';
export const UNLIMITED = 'unlimited';

export const PLANS = [STARTER, PRO, TEAM];
