<template>
  <div class="method-item">
    <MethodIcon
      :method="method"
      class="method-item__icon"
    />
    <div class="method-item__body">
      <span class="method-item__name">
        {{ name }}
      </span>
      <p class="method-item__count">
        {{ getCounts() }}
      </p>
    </div>
  </div>
</template>

<script>
import { timeText } from 'helpers/time';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectMethodContent',
  props: {
    method: {
      type: Object,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'isEditing',
    ]),
    name() {
      if (!this.method || !this.method.name) {
        return '';
      }

      return this.method.name;
    },
  },
  methods: {
    getCounts() {
      return timeText(this.method.createdAt);
    },
  },
};
</script>

<style lang="scss" scoped>
  .project-titles__wrapper {
    // width: 83%;
  }
  .project-titles__name {
    display: inline-block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    height: 33px;
    max-width: 390px;
  }
  .removed--wrap {
    flex-wrap: nowrap;
  }
  .changed--width-83 {
    // width: fit-content;
  }
</style>
