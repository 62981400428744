export const STAKEHOLDER_INTERVIEW = {
  type: 'CustomMethod',
  templateName: 'stakeholder-interview',
  name: 'Stakeholder Interview',
  icon: 'method-stakeholder-interview',
};

export const USER_INTERVIEW = {
  type: 'CustomMethod',
  templateName: 'user-interview',
  name: 'User Interview',
  icon: 'method-user-interview',
};

export const COMPETITION_ANALYSIS_OLD = {
  type: 'CompetitorAnalysis',
  name: 'Competitor Analysis',
};

export const COMPETITION_ANALYSIS = {
  type: 'CustomMethod',
  templateName: 'competitor-analysis',
  name: 'Competitor Analysis',
  icon: 'method-competitor-analysis',
  color: '#192262',
};

export const PROBLEM_VALUATION = {
  type: 'ProblemValuation',
  name: 'Problem Valuation',
};

export const SOLUTION_VALUATION = {
  type: 'SolutionValuation',
  name: 'Solution Valuation',
};

export const PERSONAS_OLD = {
  type: 'PersonasMethod',
  name: 'Personas',
};

export const PERSONAS = {
  type: 'CustomMethod',
  templateName: 'personas',
  name: 'Persona',
  icon: 'method-personas-method',
  color: '#194962',
};

export const FLOWS_OLD = {
  type: 'FlowsMethod',
  name: 'User Flows',
};

export const FLOWS = {
  type: 'CustomMethod',
  templateName: 'flows',
  name: 'Flows',
  icon: 'method-flows-method',
  color: '#194962',
};

export const SURVEY = {
  type: 'SurveyMethod',
  name: 'Survey',
};

export const BOARD = {
  type: 'BoardMethod',
  name: 'Board',
};

export const NOTEPAD = {
  type: 'NotepadMethod',
  name: 'Notepad',
};

export const CUSTOM = {
  type: 'CustomMethod',
  name: 'Notepad',
};

export const INTERACTIVE_PROTOTYPE_OLD = {
  type: 'InteractivePrototype',
  name: 'Interactive Prototype',
};

export const INTERACTIVE_PROTOTYPE = {
  type: 'CustomMethod',
  templateName: 'interactive-prototype',
  name: 'Interactive Prototype',
  icon: 'method-interactive-prototype',
  color: '#4F1962',
};
