<template>
  <div class="onb-step">
    <form class="onb-form">
      <div class="onb-login-header">
        <div class="onb-email">
          {{ email }}
        </div>
        <h2 class="onb-heading">
          <tempalte v-if="error">
            Invalid invitation link
          </tempalte>
          <template v-else-if="registered">
            Sign in to join<br> <span class="tcolor-primary">{{ workspaceName }}</span>
          </template>
          <template v-else>
            Create new account to join<br> <span class="tcolor-primary">{{ workspaceName }}</span>
          </template>
        </h2>
      </div>
      <CustomInput
        v-if="!error"
        v-model="password"
        name="password"
        type="password"
        placeholder="Enter your password here"
        input-class="input input--new-light input--center"
        :validate="(value) => {
          return value.length >= 8;
        }"
        autofocus
        @keydown.enter.prevent="next"
      />
      <p v-if="error">
        Invitation invalid or already accepted
      </p>
      <p v-else-if="!registered">
        Password should be at least <strong>8 characters long</strong>
      </p>
      <p v-else>
        <a
          class="link link--dark"
          @click="resetPassword"
        >I don't remember my password</a>
      </p>
    </form>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';
import InvitationsService from 'services/invitations.service';
import MailLoginService from 'services/mail-login.service';
import { mapGetters } from 'vuex';

export default {
  name: 'SignInPassword',
  data() {
    return {
      error: false,
      password: '',
      email: '',
      registered: false,
      workspaceName: '',
      loading: false,
    };
  },
  async created() {
    this.updateConfig();
    await this.fetch();
    this.updateConfig();
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    userData() {
      return this.$route.params.userData;
    },
    isRegistered() {
      return this.userData && this.userData.status === 'registered'
    },
  },
  watch: {
    password() {
      this.updateConfig();
    },
  },
  methods: {
    async fetch() {
      try {
        const data = await InvitationsService.getDetails(this.$route.params.workspace_id, this.$route.params.token);
        this.email = data.email;
        this.error = data.error;
        this.registered = data.registered;
        this.workspaceName = data.workspaceName;
      } catch(e) {
        this.error = true;
      }
       
    },
    getConfig() {
      const enabled = this.password && this.password.length >= 8;

      return {
        avatar: 'email',
        avatarName: this.email,
        buttons: [
          {
            appearance: 'new-primary',
            id: 'primary',
            text: this.registered ? 'Accept invitation' : 'Create account',
            disabled: !enabled,
            loading: this.loading,
          },
        ],
      };
    },
    buttonAction(id) {
      switch(id) {
        case 'primary':
          this.next();
          return;
        case 'secondary':
          this.back();
          return;
        case 'magic-link':
          this.sendMagicLink();
          return;
      }
    },
    async next() {
      this.loading = true;
      this.updateConfig();

      const response = await InvitationsService.accept(this.$route.params.workspace_id, this.$route.params.token, {
        invitation: {
          password: this.password,
          passwordConfirmation: this.password,
        },
      });

      this.loading = false;
      this.updateConfig();

      this.$router.push({
        name: ROUTES.ACCEPT_INVITATION_READY,
        params: {
          ...response,
        },
      });
    },
    resetPassword() {
      MailLoginService.resetPasswordRequest({ email: this.email });
      this.$store.dispatch('modalShow', {
        name: 'reset-password-request',
        data: {
          email: this.email,
        },
      });
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
  },
};
</script>
