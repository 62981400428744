<template>
  <div class="payment__form-row">
    <div class="payment__form-col">
      <label class="payment__form-label">{{ fieldName }}</label>
      <div>
        <Autocomplete
          v-if="param == 'country'"
          ref="autocomplete"
          class="edit-contact__autocomplete"
          :source="countryList"
          :min-length="0"
          placeholder="e.g. United States"
          :selection="inputValue"
          @select="inputValue = $event; updateField()"
        >
          <template v-slot="data">
            {{ data.value }}
          </template>
        </Autocomplete>
        <input
          v-else
          v-model="inputValue"
          type="text"
          name="name"
          class="edit-contact__input"
          :placeholder="placeholder"
          @blur="updateField"
        >
      </div>
    </div>
    <BaseSaving
      :is-saving="settingsSaving[param]"
      class="edit-contact__saving-indicator"
    />
  </div>
</template>

<script>
import Autocomplete from 'components/common/autocomplete';
import COUNTIRES from 'constants/countries';
import { mapGetters } from 'vuex';

export default {
  components: {
    Autocomplete,
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    param: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      countryList: COUNTIRES,
      textInput: '',
    };
  },
  computed: {
    ...mapGetters(['settingsSaving']),
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.textInput = newValue;
      },
    },
  },
  watch: {
    value(newValue) {
      return newValue;
    },
  },
  methods: {
    updateField() {
      if (!this.textInput.length) {
        return;
      }
      const data = {};
      data[this.param] = this.textInput;
      this.$emit('onFieldUpdate', data);
    },
  },
};
</script>

<style lang="scss">
.edit-contact {
  &__autocomplete {
    flex-grow: 1;
    input {
      border: none;
      outline: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.edit-contact {
  &__saving-indicator {
    margin-right: 28px;
    position: absolute !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &__item {
    display: flex;
    height: 55px;
    align-items: center;
    padding: 0px;
    padding-left: 100px;
    border-bottom: 1px solid #f2f2f2;
    font-size: 16px;
    line-height: 1.5;
    color: #3b3b3b;
  }
  &__data {
    display: block;
    width: 181px;
    text-align: left;
  }
  &__input {
    flex-grow: 1;
    border: none;
    outline: none;
    color: #414141;
  }
}
.button__container {
  .buttons {
    width: 90px;
    height: 30px;
    border-radius: 15px;
    outline: none;
    background: none;
    border: none;
    font-size: 11px;
    line-height: 1.36;
    letter-spacing: 0.8px;
    color: #414141;
    text-transform: uppercase;
    &:active,
    &:focus {
      border: solid 1px #68bf65;
      color: #68bf65;
    }
  }
}
</style>
