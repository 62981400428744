<template>
  <div
    class="icons-picker"
    :class="[`swatches-picker--${ appearance }`]"
  >
    <ul class="icons-picker__list">
      <li
        v-for="listIcon of icons"
        :key="listIcon"
        class="icons-picker__item"
      >
        <button
          class="icons-picker__icon"
          :class="{ 'icons-picker__icon--selected': listIcon === icon }"
          :style="{ backgroundColor: color }"
          @click="swatchClick(listIcon)"
        >
          <Icon :name="listIcon" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { ICONS_PACK } from 'constants/icons';

import Swatches from './swatches';

export default {
  name: 'SwatchesPicker',
  components: {
  },
  props: {
    color: String,
    appearance: {
      type: String,
      default: 'default',
    },
    icons: {
      required: false,
      default: () => ICONS_PACK,
    },
  },
  methods: {
    swatchClick(color) {
      this.$emit('change', color);
    },
  },
};
</script>
