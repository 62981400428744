<template>
  <div
    class="menu"
    @click="showMenu"
  >
    <ul
      v-click-outside="hideMenu"
      class="menu__list"
      :class="menuClasses"
    >
      <li
        v-if="showUp"
        @click="$emit('movePhase', {phase, move: -1});"
      >
        Move up
      </li>
      <li
        v-if="showDown"
        @click="$emit('movePhase', {phase, move: 1});"
      >
        Move down
      </li>
      <hr>
      <li @click="$emit('removePhase', phase);">
        Delete
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    showUp: {
      type: Boolean,
      default: true,
    },
    showDown: {
      type: Boolean,
      default: true,
    },
    phase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMenuVisible: false,
    };
  },
  computed: {
    menuClasses() {
      return {
        menuActive: this.isMenuVisible,
        'js-active': this.isMenuVisible,
      };
    },
  },
  methods: {
    showMenu() {
      this.isMenuVisible = true;
    },
    hideMenu() {
      this.isMenuVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  position: relative;
  background: #ffffff no-repeat center center;
  background-image: image_url('svg/more-vertical.svg');
  background-size: 4px 14px;
  width: 20px;
  transition: all .5s ease;
  cursor: pointer;
}

.menu__list {
  opacity: 0;
  li {
    list-style: none;
  }
  &.js-active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.menu__list {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 15px 18px 0 rgba(#000000, 0.06);
  border: solid 1px rgba(237, 237, 237, 0.62);
  padding: 5px 0;
  margin: 0;
  list-style: none;
  position: absolute;
  right: -100px;
  top: 40px;
  z-index: 99;
  width: 200px;
  transition: all .25s ease;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  text-transform: initial;

	&:after {
		content: '';
		display: block;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid rgba(237, 237, 237, 0.62);
		position: absolute;
		top: -8px;
		right: 100px;
	}
	&:before {
		content: '';
		display: block;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid #ffffff;
		position: absolute;
		z-index: 2;
		top: -7px;
		right: 100px;
	}
  hr {
    margin: 5px 0;
  }
	li {
    border-radius: 4px;
		font-size: 1.4rem;
	  line-height: 1.43;
	  text-align: left;
	  color: #646464;
	  padding: 10px 20px !important;
	  transition: all .35s ease;
	  cursor: pointer;
	  margin: 0 5px !important;

    &:hover {
      background: #eef3f4;
    }
  }
}

.menuActive {
  opacity: 1;
  visibility: visible;
}
</style>
