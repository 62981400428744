<template>
  <div v-if="!userLoading">
    <Header
      :editor="isEditorStep"
      :is-login-step="isLoginStep"
      :progress="editorProgress"
      :user="user"
      :side-link="headerSideLink"
    />
    <div
      class="side-lay"
      :class="layClasses"
    >
      <div class="side-lay__main">
        <div
          ref="body"
          class="side-lay__body"
        >
          <div class="onb-container">
            <div class="onb-avatar-wrap">
              <Avatar
                mode="email"
                :name="email"
              />
            </div>
            <div
              ref="wrap"
              class="content-transition"
            >
              <ContentTransition>
                <div
                  v-if="step === 'password'"
                  key="step-password"
                  class="onb-step"
                >
                  <div class="onb-login-header">
                    <h2 class="onb-heading">
                      <template>Reset your password</template>
                    </h2>
                  </div>
                  <CustomInput
                    v-model="password"
                    type="password"
                    name="password"
                    placeholder="Enter your new password here"
                    input-class="input input--light input--regular"
                    :validate="(value) => {
                      return value.length >= 8;
                    }"
                    autofocus
                    @keydown.enter="submit"
                  />
                  <p>Password should be at least <strong>8 characters long</strong></p>
                </div>
                <div
                  v-else-if="error"
                  key="step-error"
                  class="onb-step"
                >
                  <div class="onb-login-header">
                    <h2
                      class="onb-heading"
                      style="margin-left:-10px;margin-right:-10px;"
                    >
                      Your password reset request has expired
                    </h2>
                    <p class="onb-subtitle">
                      Send new email to reset your password
                    </p>
                  </div>
                  <p v-if="emailSend">
                    We’ve just sent email to <b>{{ emailSend }}</b> with link to reset your password.
                  </p>
                </div>
                <div
                  v-else
                  key="step-success"
                  class="onb-step"
                >
                  <div class="onb-login-header">
                    <h2 class="onb-heading">
                      Success!
                    </h2>
                    <p class="onb-subtitle">
                      Your password was successfully updated.
                    </p>
                  </div>
                </div>
              </ContentTransition>
              <div
                class="onb-buttons"
                style="margin-top:38px;"
              >
                <button
                  type="button"
                  class="btn btn--primary btn--md btn--corners btn--block"
                  :class="{ 'btn--loading': isLoading }"
                  :disabled="!isValid"
                  @click="submit"
                  @keydown.enter.stop
                >
                  {{ buttonText }}
                  <BaseSpinner v-if="isLoading" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BaseSpinner
    v-else
    :full-height="true"
  />
</template>

<script>
import { MIN_PASSWORD_LENGTH } from 'config';
import { validate } from 'helpers/email';
import { navigateProject, navigateProjects } from 'helpers/router';
import MailLoginService from 'services/mail-login.service';
import { mapGetters } from 'vuex';

export default {
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      error: '',
      emailSend: '',
      isLoading: false,
      step:'password',
    }
  },
  computed: {
    token() {
      return this.$route.query.reset_password_token;
    },
    isValid() {
      if (this.step === 'password') {
        return this.password.length >= 8;
      }
      if (this.step === 'status' && this.error) {
        return validate(this.email) && !this.emailSend;
      }
      return true;
    },
    buttonText() {
      if (this.step === 'password') {
        return 'Reset password';
      } else if (this.error) {
        return 'Send new link';
      }

      return 'Continue to Talebook';
    },
    email() {
      return this.$route.query.email;
    },
    projectId() {
      return this.$route.query.project_id;
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }
      if (this.step === 'password') {
        this.submitPassword();
      }
      else if (this.step === 'status' && this.error) {
        this.submitEmail();
      }
      else if (this.projectId) {
        navigateProject(this.projectId);
      } else {
        navigateProjects();
      }
    },
    async submitPassword() {
      const password = this.password;
      const resetPasswordToken = this.token;

      this.isLoading = true;

      try {
        const { accessToken } = await MailLoginService.changePassword({
          password,
          passwordConfirmation: password,
          resetPasswordToken,
        });
        await this.$store.dispatch('tokensSet', {
          provider: 'email',
          accessToken,
        });
      } catch (error) {
        this.error = error.error || 'Unknown error occured!';
      }

      this.step = 'status';
      this.isLoading = false;
    },
    async submitEmail() {
      const email = this.email;

      this.isLoading = true;

      try {
        await MailLoginService.resetPasswordRequest({ email });
      } catch (error) {
        this.error = error.error || 'Unknown error occured!';
        this.email = '';
      }

      this.step = 'status';
      this.emailSend = email;
      this.isLoading = false;
    },
  },
};
</script>
