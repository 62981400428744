<template>
  <div class="sidebar-page">
    <BaseSpinner
      v-if="loading"
      :full-height="true"
    />
    <div
      v-else
      class="container new-method__container"
      data-aos="fade-in"
    >
      <TitleInput
        appearance="project-title centered"
        :can-edit="false"
        value="Choose page type"
      />
      <NewList
        v-for="(phase) of phases"
        :key="phase"
        :title="getAddPhaseName(phase)"
        :phase="phase"
        :phase-id="$route.params.phase_id"
        @adding="loading = true"
      />
      <button
        class="button"
        @click="navigateToProject"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { ADD_PHASES, ADD_PHASES_NAMES } from 'constants/methods';
import { navigateProject, navigateProjects } from 'helpers/router';
import { mapGetters } from 'vuex';

import NewList from './new-list';

export default {
  name: 'NewMethodView',
  components: {
    NewList,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'projectCurrent',
    ]),
    phases() {
      return ADD_PHASES;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    getAddPhaseName(phase) {
      return ADD_PHASES_NAMES[phase] || '';
    },
    navigateToProject() {
      navigateProject(this.$route.params.project_id);
    },
    async fetchData() {
      await Promise.all([
        this.$store.dispatch('projectFetch'),
        this.$store.dispatch('phasesFetch'),
      ]);
      this.fetched = true;

      if (!this.projectCurrent.accessible) {
        navigateProjects();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .new-method__container {
    background-color: #ffffff;
  }
  .new-method__header {
    margin-top: 0px;
    font-size: 34px;
    letter-spacing: -0.1px;
    color: #1e1e1e;
    font-weight: normal;
    text-align: center;
    box-shadow: none;
  }
  .button {
    display: block;
    margin: auto;
    margin-top: 55px;
    width: 260px;
    height: 40px;
    background: none;
    border-radius: 100px;
    border: solid 1px rgba(8, 54, 87, 0.15);
    font-size: 14px;
    color: #414141;
    letter-spacing: 0.2px;
  }
  @media only screen and (max-width: 500px){
    .header {
      font-size: 26px;
    }
  }
</style>
