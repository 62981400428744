<template>
  <div
    class="dropdown-menu"
    :class="menuClasses"
  >
    <span
      v-for="link of menuVisible"
      :key="link.text"
      class="dropdown-menu__item"
    >
      <span
        v-if="link === '-'"
        class="dropdown-menu__spacer"
      />
      <a
        v-else
        class="dropdown-menu__link"
        :class="{
          'dropdown-menu__link--active': link.active,
          [`dropdown-menu__link--${ link.appearance }`]: link.appearance,
        }"
        @click.stop.prevent="doLinkAction(link)"
      >
        {{ link.text }}
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ListMenu',
  props: {
    menu: Array,
    visible: {
      type: [Boolean, null],
      required: false,
      default: null,
    },
    placement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    behaviour: {
      type: String,
      required: false,
      default: 'click',
    },
    appearance: {
      type: String,
      required: false,
      default: 'light-box',
    },
    title: String,
  },
  data() {
    return {
    };
  },
  computed: {
    menuClasses() {
      return {
        [`dropdown-menu--${this.appearance}`]: this.appearance,
      };
    },
    hasButton() {
      return !!this.$slots.button;
    },
    menuVisible() {
      return this.menu.filter((m) => !m.hidden);
    },
  },
  methods: {
    doLinkAction(link) {
      if (link && link.action && typeof link.action === 'string') {
        this.$emit('action', link.action);
      } else {
        link && link.action && link.action();
        this.$emit('action');
      }
    },
    onToggle(open) {
      this.$emit('toggle', open);
    },
  },
};
</script>
