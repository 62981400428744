/* global __PRODUCTION__, braintree */

import { USE_PAYPAL } from 'config/index';
import * as ApiService from 'services/api.service';
import EventsService, { EVENTS } from 'services/events.service';
import loadScript from 'utils/load-script';

const URLS = [
  'https://js.braintreegateway.com/web/3.63.0/js/client.min.js',
  'https://js.braintreegateway.com/web/3.63.0/js/three-d-secure.js',
  'https://js.braintreegateway.com/web/3.63.0/js/hosted-fields.min.js',
  'https://js.braintreegateway.com/web/3.63.0/js/vault-manager.min.js',
];

const FIELD_NAME_MAP = {
  'ccNumber': 'card number',
  'number': 'card number',
  'ccExpiration': 'card expiration date',
  'expirationDate': 'card expiration date',
  'ccCvv': 'card CVV code',
  'cvv': 'card CVV code',
};

class PaymentsService {
  constructor() {
    this.token = null;
    this.clientInstance = null;
    this.hostedFieldsInstance = null;
    this.loaded = null;
    this.vauldManager = null;
    this.paymentMethods = null;
    this.threeDSecure = null;
  }

  load() {
    return Promise.all(URLS.map((url) => loadScript(url)));
  }

  async loadClientInstance() {
    const config = {
      authorization: this.token,
      env: __PRODUCTION__ ? 'production' : 'sandbox',
    };

    if (USE_PAYPAL) {
      config.paypal = {
        flow: 'vault',
        intent: 'sale',
      };
    }

    this.clientInstance = await braintree.client.create(config);

    return this.clientInstance;
  }

  async getPaymentMethods() {
    this.vauldManager = await braintree.vaultManager.create({client: this.clientInstance});
    this.paymentMethods = await this.vauldManager.fetchPaymentMethods();
    return this.paymentMethods;
  }

  async initHostedFields({ onValidityChange, onBlur = () => false, onSubmit }) {
    const paymentMethod = this.paymentMethods && this.paymentMethods[0];

    this.hostedFieldsInstance = new Promise((resolve, reject) => braintree.hostedFields.create({
        client: this.clientInstance,
        threeDSecure: true,
        styles: {
          input: {
            'font-family': 'Lato, Helvetica, Tahoma, Arial, sans-serif',
            'font-size': '16px',
          },
          '::placeholder': {
            'color': '#d0d0d0',
          },
        },
        fields: {
          number: {
            selector: '#ccNumber',
            placeholder: paymentMethod ? '**** **** **** ' + paymentMethod.details.lastFour : '0000 0000 0000 0000',
          },
          cvv: {
            selector: '#ccCvv',
            placeholder: '***',
          },
          expirationDate: {
            selector: '#ccExpiration',
            placeholder: 'MM / YY',
            prefill: paymentMethod ? `${ paymentMethod.details.expirationMonth }/${ paymentMethod.details.expirationYear }` : '',
          },
        },
      }, function(err, hostedFieldsInstance) {
          if (err) {
            reject(err);
            return;
          }
          hostedFieldsInstance.on('empty', (event) => {
            const field = event.fields[event.emittedBy];
            onValidityChange({ error: field.isEmpty && 'Field can\'t be empty', name: field.container.id });
          });

          hostedFieldsInstance.on('notEmpty', (event) => {
            const field = event.fields[event.emittedBy];
            const err = `Invalid ${ FIELD_NAME_MAP[field.container.id] }`;
            onValidityChange({ error: null, name: field.container.id });
            onValidityChange({ error: !field.isValid && err, name: field.container.id, live: true });
          });

          hostedFieldsInstance.on('validityChange', (event) => {
            const field = event.fields[event.emittedBy];
            const err = `Invalid ${ FIELD_NAME_MAP[field.container.id] }`;
            onValidityChange({ error: !field.isValid && err, name: field.container.id, live: true });
          });

          hostedFieldsInstance.on('blur', (event) => {
            const field = event.fields[event.emittedBy];
            const err = `Invalid ${ FIELD_NAME_MAP[field.container.id] }`;
            onBlur({ name: field.container.id, live: true });
          });

          hostedFieldsInstance.on('inputSubmitRequest', (event) => {
            onSubmit(event);
          });

          resolve(hostedFieldsInstance);
        }
      )
    );

    this.threeDSecure = braintree.threeDSecure.create({
      version: 2,
      client: this.clientInstance,
    });

    return this.hostedFieldsInstance;
  }

  async start({ workspaceId, planId, type, cardholderName, useCard, updateCard, disabled, onValidityChange, onCheckout, onSuccess, onError, receiptData, amount }) {
    if (useCard) {
      onCheckout();
      try {
        const result = await this.submitCheckoutToServer({
          payment_method_nonce: useCard.nonce,
          type,
          subscription_plan_id: planId,
          workspaceId,
          ...(receiptData || {}),
        });
        onSuccess(result);
      } catch (error) {
        onError(error);
      }

      return;
    }

    if (!this.hostedFieldsInstance || !this.clientInstance) {
      throw new Error('payments service not initialized');
    }

    let hostedFieldsInstance = await this.hostedFieldsInstance;

    let formIsInvalid = false;
    const state = hostedFieldsInstance.getState();

    Object.keys(state.fields).forEach(function(field) {
      const fieldObj = state.fields[field];
      if (!fieldObj.isValid) {
        onValidityChange({ name: fieldObj.container.id, error: `Invalid ${ FIELD_NAME_MAP[field] }` });
        formIsInvalid = true;
      }
    });

    if (formIsInvalid || disabled || !amount) {
      return;
    }

    const threeDSecure = await this.threeDSecure;

    hostedFieldsInstance.tokenize({ cardholderName }).then((payload) => {
      onCheckout();

      return threeDSecure.verifyCard({
        onLookupComplete: function (data, next) {
          next();
        },
        nonce: payload.nonce,
        bin: payload.details.bin,
        amount,
        billingAddress: {},
      })
    }).then(async (payload) => {
      if (!payload.liabilityShifted) {
        return;
      }

      try {
        const result = await this.submitCheckoutToServer({
          payment_method_nonce: payload.nonce,
          type,
          subscription_plan_id: planId,
          workspaceId,
          ...(receiptData || {}),
        });
        const clear = () => hostedFieldsInstance.clear('cvv');
        onSuccess(result, clear);
      } catch (error) {
        hostedFieldsInstance.clear('cvv');
        onError(error);
      }
    }).catch((err) => {
      if (err) {
        onError(err);
      }
    });
  }

  async updatePaymentMethod({ planId, type, cardholderName, disabled, onValidityChange, onCheckout, onSuccess, onError, receiptData, amount }) {
    if (!this.hostedFieldsInstance || !this.clientInstance) {
      throw new Error('payments service not initialized');
    }

    let formIsInvalid = false;
    let hostedFieldsInstance = await this.hostedFieldsInstance;

    const state = hostedFieldsInstance.getState();

    Object.keys(state.fields).forEach(function(field) {
      const fieldObj = state.fields[field];
      if (!fieldObj.isValid) {
        onValidityChange({ name: fieldObj.container.id, error: `Invalid ${ FIELD_NAME_MAP[field] }` });
        formIsInvalid = true;
      }
    });

    if (formIsInvalid || disabled || !amount) {
      return;
    }

    const threeDSecure = await this.threeDSecure;

    hostedFieldsInstance.tokenize({ cardholderName }).then((payload) => {
      onCheckout();

      return threeDSecure.verifyCard({
        onLookupComplete: function (data, next) {
          next();
        },
        nonce: payload.nonce,
        bin: payload.details.bin,
        amount,
        billingAddress: {},
      })
    }).then(async (payload) => {
      if (!payload.liabilityShifted) {
        return;
      }

      try {
        const result = await this.submitCardUpdateToServer({
          payment_method_token: payload.nonce,
        });
        const clear = () => hostedFieldsInstance.clear('cvv');
        onSuccess(result, clear);
      } catch (error) {
        hostedFieldsInstance.clear('cvv');
        onError(error);
      }
    }).catch((err) => {
      onError(err);
    });
  }

  async fetchToken() {
    const { token } = await ApiService.get('api/payments/client_token');
    this.token = token;
    return token;
  }

  prepare() {
    this.loaded = this.load()
      .then(() => this.fetchToken())
      .then(() => this.loadClientInstance());
    return this.loaded;
  }

  submitCheckoutToServer(data) {
    return ApiService.post('api/payments/checkout', data);
  }

  submitCardUpdateToServer(data) {
    return ApiService.put('api/users/change_credit_cards', data);
  }

  submitPaymentDetailsForContact(data) {
    return ApiService.post('api/sign_for_payments', data);
  }

  async destroy() {
    let hostedFieldsInstance = await this.hostedFieldsInstance;

    this.vauldManager && this.vauldManager.teardown();
    hostedFieldsInstance && hostedFieldsInstance.teardown();
    this.clientInstance && this.clientInstance.teardown();
    this.token = null;
    this.clientInstance = null;
    this.hostedFieldsInstance = null;
    this.loaded = null;
    this.vauldManager = null;
    this.paymentMethods = null;
  }
}

export default new PaymentsService();
