<template>
  <div
    class="base-input"
    :class="{
      'base-input--invalid': !isValid || error,
      'base-input--error': displayError,
    }"
  >
    <input
      ref="input"
      v-model="myValue"
      :class="[{ 'cant-edit': !canEdit, 'error': displayError }, inputClass]"
      :readonly="!canEdit"
      v-bind="$attrs"
      :placeholder="placeholder"
      :spellcheck="false"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      @keyup="checkValidate"
      @keydown.enter="edit(false)"
      @keydown.esc="edit(false)"
      @mousedown="edit(true)"
      @touchstart="edit(true)"
      @mouseup="mouseup"
    >
    <span
      class="base-input__valid"
      :class="{
        'base-input__valid--invalid': !isValid || error,
      }"
    >
      <Icon name="check" />
    </span>
  </div>
</template>
<script>
export default {
  name: 'CustomInput',
  props: {
    inputClass: String,
    validate: {
      type: Function,
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
    value: String,
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },
    valid: {
      type: [Boolean, null],
      required: false,
      default: null,
    },
    autofocus: Boolean,
  },
  data() {
    return {
      myValue: null,
      editingName: false,
      preventMouseUp: false,
      isValid: false,
      firstEdit: false,
    };
  },
  computed: {
    displayError() {
      return this.error && this.firstEdit;
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (oldValue !== newValue && newValue !== this.myValue) {
        this.myValue = newValue;
      }
    },
    valid(valid) {
      if (valid !== null) {
        this.isValid = valid;
      }
    },
  },
  created() {
    this.myValue = this.value;
  },
  mounted() {
    this.checkValidate();

    if (this.autofocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    onInput() {
      if (this.lazy) return;
      this.$emit('input', this.myValue);
    },
    blur() {
      this.$refs.input.blur();
    },
    onChange() {
      if (this.myValue && this.myValue.trim().length > 0) {
        this.$emit('change', this.myValue);
        if (this.lazy) this.$emit('input', this.myValue);
      } else {
        this.myValue = this.value;
        this.$refs.input.value = this.value;
      }
    },
    checkValidate() {
      if (this.valid !== null) {
        this.isValid = this.valid;
      }
      if (!this.validate) return;
      this.isValid = this.validate(this.$refs.input.value);
    },
    select() {
      this.$refs.input.select();
      this.preventMouseUp = true;
    },
    mouseup(e) {
      if (this.preventMouseUp) {
        e.preventDefault();
      }
      this.preventMouseUp = false;
    },
    onBlur() {
      this.firstEdit = true;
      this.edit(false);
    },
    edit(ifEdit) {
      if (ifEdit && document.activeElement === this.$refs.input) return;
      
      if (!this.canEdit) {
        this.$refs.input.setSelectionRange(0, 0);
        this.$refs.input.blur();
        return;
      }

      this.editingName = ifEdit;
      if (ifEdit) {
        this.myValue = this.value;
      }

      this.$nextTick(() => {
        if (this.editingName) {
          this.select();
        }
      });
    },
  },
};
</script>
