/* eslint-disable no-shadow */

import InvoiceService from 'services/invoice.service';
import { merge } from 'utils/reactive';

const moduleState = {
  saving: {
    address: false,
    city: false,
    companyName: false,
    country: false,
    firstName: false,
    lastName: false,
    postCode: false,
    taxNumber: false,
    name: false,
  },
  invoice: {},
};

const getters = {
  invoice: (state) => state.invoice,
  settingsSaving: (state) => state.saving,
};

const actions = {
  async invoiceFetch({ dispatch }) {
    // const userId = getters.user;
    const invoice = await InvoiceService.getInvoice();
    dispatch('setInvoice', invoice);
  },
  async updateInvoice({ commit, dispatch }, body) {
    const key = Object.keys(body)[0];
    body = {
      ...body,
      city: '‏‏‎ ‎',
      lastName: '‏‏‎ ‎',
      postCode: 'x',
    };
    commit('changeInvoice', body);
    commit('setSavingState', { key, value: true });
    if (body.name && body.name.length) {
      dispatch('userChange', { name: body.name });
    }
    await InvoiceService.change(body);
    commit('setSavingState', { key, value: false });
  },
  setInvoice({ commit }, invoice) {
    commit('setInvoice', invoice);
  },
};

const mutations = {
  setSavingState(state, { key, value }) {
    merge(state.saving, {[key]: value});
  },
  setInvoice(state, invoice) {
    state.invoice = invoice;
  },
  changeInvoice(state, body) {
    merge(state.invoice, body);
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
