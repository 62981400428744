import { ADD_METHODS_BY_PHASE } from 'constants/methods';
import * as TYPES from 'constants/mutations';
import MethodsService from 'services/methods.service';
import StorageService from 'services/storage.service';

const moduleState = {
  methods: [],
  descriptionRead: StorageService.methodsDescriptions.load() || [],
};

const getters = {
  methods: (state) => state.methods,
  methodByType: (state) => (id) => state.methods.find(({ type }) => type === id),
  methodsByPhase: (state) => (phase) => {
    if (!ADD_METHODS_BY_PHASE.hasOwnProperty(phase)) {
      return [];
    }
    return ADD_METHODS_BY_PHASE[phase];
  },

  methodReadDescriptions: (state) => state.descriptionRead,
  isMethodDescriptionRead: (state) => ({ type }) =>
    state.descriptionRead.includes(type),
};

const actions = {
  init({ dispatch }) {
    dispatch('methodsFetch');
  },
  async methodsFetch({ dispatch }) {
    const methods = await MethodsService.fetch();
    dispatch('methodsFetched', methods);
  },
  methodsFetched({ commit }, methods) {
    commit(TYPES.METHODS_FETCHED, methods);
  },

  methodDescriptionRead({ commit }, { type }) {
    commit(TYPES.METHOD_DESCRIPTION_READ, type);
  },
};

const mutations = {
  [TYPES.METHODS_FETCHED](state, methods) {
    state.methods = methods;
  },

  [TYPES.METHOD_DESCRIPTION_READ](state, type) {
    if (state.descriptionRead.includes(type)) return;

    state.descriptionRead.push(type);
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
