<template>
  <BaseTooltip
    placement="top"
    :text="tooltip"
  >
    <div class="counted-icon">
      <div
        v-if="list.length"
        class="counted-icon__ico stacked-avatars"
      >
        <UserAvatar
          v-for="user of list"
          :key="user.name"
          mode="email"
          :name="user.name || 'Guest user'"
        />
      </div>
      <div
        v-else-if="!canEdit"
        class="counted-icon__ico stacked-avatars"
      >
        <UserAvatar mode="guest" />
      </div>
      <Icon
        v-else
        class="counted-icon__ico counted-icon__ico--plus"
        name="responder-outline"
      />
      <span
        v-if="shouldShowCount || !canEdit"
        class="counted-icon__count"
      >{{ count }}</span>
    </div>
  </BaseTooltip>
</template>

<script>

import { pluralize } from 'helpers/string';

export default {
  name: 'StackedAvatars',
  props: {
    users: Array,
    tags: Array,
    itemName: {
      type: String,
      required: false,
      default: 'respondent',
    },
    alwaysShowCounter: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
  }),
  computed: {
    list() {
      if (this.tags) {
        return this.tags ? this.tags.slice(0, 3) : [];
      }
      return this.users ? this.users.slice(0, 3) : [];
    },
    count() {
      if (this.tags) {
        return this.tags ? this.tags.length : 0;
      }
      return this.users ? this.users.length : 0;
    },
    shouldShowCount() {
      return this.count && (this.alwaysShowCounter || this.count > 3);
    },
    tooltip() {
      if (!this.count && this.canEdit) return 'Add ' + this.itemName;
      let name = this.count === 1 && this.list[0].name.length < 20 && this.list[0].name;

      return name || `${this.count} ${pluralize(this.count, this.itemName)}`;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>
