/* eslint-disable no-shadow */
import ActionCable from 'actioncable';
import { CABLE_URL } from 'config';
import { deepFindInObject } from 'helpers/array';
import { byPosition } from 'helpers/sort';
import { pluralize } from 'helpers/string';
import LibrariesService from 'services/libraries.service';
import NotificationsService from 'services/notifications.service';
import UndoService from 'services/undo.service';
import { objectFrom, objectTo } from 'utils/camel-case';
import generateId from 'utils/id';
import { merge } from 'utils/reactive';
import { TYPE_TO_NAME_MAP } from 'constants/survey';

const state = {
  open: false,
  libraries: {},
  lastAddedPage: null,
};

const parsePage = (data) => {
  data.name = data.name || 'New page';

  return Object.assign({
    name: 'New page',
  }, data);
};

const parse = (data) => {
  return data.map((page) => {
    return parsePage(page);
  });
};

const parseSection = (section) => {
  return {
    items: [],
    ...section,
  }
};

const NEW_PAGE_NAME = 'New page';

const getters = {
  isLibraryOpen: (state) => state.open,
  libraryCurrent: (state, getters) => {
    const workspaceId = getters.workspaceCurrentId;
    return state.libraries[workspaceId];
  },
  libraryGetPageById: (state, getters) => (id) => deepFindInObject(state.libraries, 'id', id),
  libraryGetSectionById: (state, getters) => (id) => deepFindInObject(state.libraries, 'id', id),
  libraryGetItemById: (state, getters) => (id) => deepFindInObject(state.libraries, 'id', id),
  libraryNewPageName: (state, getters) => {
    if (!getters.libraryCurrent) return NEW_PAGE_NAME;
    if (!getters.libraryCurrent.find(({ name }) => name === NEW_PAGE_NAME )) return NEW_PAGE_NAME;
    let counter = 0;
    const getName = () => `${ NEW_PAGE_NAME }${ counter ? ' ' + counter : '' }`;

    while (getters.libraryCurrent.find(({ name }) => name === getName())) {
      counter ++;
    }

    return getName();
  },
  libraryLastAddedPage: (state) => state.lastAddedPage,
};

const actions = {
  async initCable({ dispatch, getters, commit }) {
    if (this.cable) return;

    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;

    const { libraryCurrent, accessToken } = getters;
    const cable = ActionCable.createConsumer(`${CABLE_URL}?token=${accessToken}`);
    this.cable = cable.subscriptions.create({
      channel: 'WorkspacesChannel',
      workspace_id: workspaceCurrentId,
      uuid: generateId(),
      token: getters.accessToken,
    }, {
      connected: function() {
      },
      disconnected: function() {
      },
      rejected: function() {
      },
      received: function(response) {
        let { action, data, code, command } = response;
        const { workspaceCurrentId } = getters;
        let page, section, item;
        action = action.trim();

        if (action === 'remote_task') {
          return;
        }

        if (code && !(code >= 200 && code < 300)) {
          NotificationsService.showError(
            `Error (${ code }): ${ data }`
          );
          return;
        }

        data = objectTo(data);

        switch (command) {
          case 'library/pages/create':
            commit('LIBRARY_ADD_PAGE', { workspaceCurrentId, page: data });
            break;
          case 'library/pages/update':
            page = getters.libraryGetPageById(data.id);
            commit('LIBRARY_CHANGE_PAGE', { page, data });
            break;
          case 'library/pages/destroy':
            commit('LIBRARY_REMOVE_PAGE', { workspaceCurrentId, id: data.id });
            break;
          case 'library/sections/create':
            page = getters.libraryGetPageById(data.pageId);

            if (!page) {
              NotificationsService.showError(
                'Error: Cannot find library page'
              );
              return;
            }

            commit('LIBRARY_ADD_SECTION', { page, section: data });
            break;
          case 'library/sections/update':
            section = getters.libraryGetSectionById(data.id);
            commit('LIBRARY_CHANGE_SECTION', { section, data });
            break;
          case 'library/sections/bulk_update':
            commit('LIBRARY_SECTIONS_BULK_UPDATE', { data });
            break;
          case 'library/sections/destroy':
            page = getters.libraryGetPageById(data.pageId);
            commit('LIBRARY_REMOVE_SECTION', { page, id: data.id });
            break;
          case 'library/items/create':
            section = getters.libraryGetSectionById(data.sectionId);
            commit('LIBRARY_ADD_ITEM', { section, item: data, position: data.position });
            break;
          case 'library/items/update':
            item = getters.libraryGetItemById(data.id);
            commit('LIBRARY_CHANGE_ITEM', { item, data });
            break;
          case 'library/items/bulk_update':
            commit('LIBRARY_ITEMS_BULK_UPDATE', { data });
            break;
          case 'library/items/destroy':
            section = getters.libraryGetSectionById(data.sectionId);
            commit('LIBRARY_REMOVE_ITEM', { section, id: data.id });
            break;
        }
      },
    });
  },
  async fetchLibrary({ dispatch, getters, commit }) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;

    if (!workspaceCurrentId) return;

    const response = await LibrariesService.fetchAll(workspaceCurrentId);
    const library = parse(response);

    await commit('LIBRARY_SET', { workspaceCurrentId, library });

    dispatch('initCable');
  },
  toggleLibrary({ commit }, toggle) {
    commit('LIBRARY_TOGGLE', toggle);
  },
  async addLibraryPage({ getters, commit }, { data }) {
    const { workspaceCurrentId, libraryNewPageName, libraryCurrent } = getters;

    const page = {
      ...data,
      page: { name: libraryNewPageName },
      workspaceId: workspaceCurrentId,
      command: 'library/pages/create',
    };

    this.cable.perform('exec', objectFrom(page));

    return page;
  },
  async changeLibraryPage({ getters, commit }, { id, data }) {
    const { workspaceCurrentId } = getters;

    const payload = {
      page: { ...data },
      id,
      workspaceId: workspaceCurrentId,
      command: 'library/pages/update',
    };

    this.cable.perform('exec', objectFrom(payload));
  },
  async removeLibraryPage({ getters, commit }, id) {
    const { workspaceCurrentId } = getters;
    const page = getters.libraryGetPageById(id);

    if (!page) return;

    const payload = {
      workspaceId: workspaceCurrentId,
      command: 'library/pages/destroy',
      id,
    };

    this.cable.perform('exec', objectFrom(payload));
  },
  async addLibrarySection({ getters, commit }, { pageId, data }) {
    const { workspaceCurrentId } = getters;
    const page = getters.libraryGetPageById(pageId);

    if (!page) return;

    const section = {
      section: { name: 'New section', ...data },
      workspaceId: workspaceCurrentId,
      pageId: page.id,
      command: 'library/sections/create',
    };

    this.cable.perform('exec', objectFrom(section));

    return section;
  },
  async changeLibrarySection({ getters, commit }, { id, pageId, data }) {
    const { workspaceCurrentId } = getters;
    const section = getters.libraryGetSectionById(id);
    const page = getters.libraryGetPageById(pageId);

    if (!section) return;

    const payload = {
      section: { ...data },
      id,
      workspaceId: workspaceCurrentId,
      pageId: page.id,
      command: 'library/sections/update',
    };

    this.cable.perform('exec', objectFrom(payload));
  },
  async changeLibraryLocalSection({ getters, commit }, { id, data }) {
    const { workspaceCurrentId } = getters;
    const section = getters.libraryGetSectionById(id);

    if (!section) return;

    await commit('LIBRARY_CHANGE_SECTION', { section, data });
  },
  async moveLibrarySection({ getters, commit }, { pageId, id, index }) {
    const { workspaceCurrentId } = getters;
    const section = getters.libraryGetSectionById(id);
    const page = getters.libraryGetPageById(section.pageId);

    if (!page || !section) return;

    const sections = [
      ...byPosition(page.sections.filter((i) => i.id !== id)).map((item, i) => ({
        id: item.id,
        position: (i >= index) ? i + 1 : i,
      })),
      {
        id: section.id,
        position: index,
      },
    ];

    const payload = {
      command: 'library/sections/bulk_update',
      workspaceId: workspaceCurrentId,
      pageId,
    };

    this.cable.perform('exec', objectFrom({
      ...payload,
      sections,
    }));

    commit('LIBRARY_SECTIONS_BULK_UPDATE', { data: sections });
  },
  async removeLibrarySection({ getters, commit }, { pageId, id }) {
    const { workspaceCurrentId } = getters;
    const page = getters.libraryGetPageById(pageId);
    const section = getters.libraryGetSectionById(id);

    if (!page) return;

    const payload = {
      id,
      workspaceId: workspaceCurrentId,
      pageId: page.id,
      command: 'library/sections/destroy',
    };

    this.cable.perform('exec', objectFrom(payload));
  },
  async addLibraryItem({ getters, commit, dispatch }, { sectionId, pageId, data, showNotification = true }) {
    const { workspaceCurrentId } = getters;
    let section;
    let page;

    if (pageId) {
      page = getters.libraryGetPageById(pageId);
    }

    if (!sectionId) {
      sectionId = page.sections[0] && page.sections[0].id;
    }

    if (sectionId) {
      section = getters.libraryGetSectionById(sectionId);
    }

    if (data.position === undefined) {
      data.position = section.items.length;
    }

    const item = {
      workspaceId: workspaceCurrentId,
      sectionId: section.id,
      pageId: page.id,
      command: 'library/items/create',
      position: data.position,
      item: {
        name: data.name || TYPE_TO_NAME_MAP[data.type] || 'Name',
        color: data.color || TYPE_TO_NAME_MAP[data.color] || '#abc0c8',
        customMethodItem:{
          ...data,
          customItemFields: (data.customItemFields || []).filter((item) => !item.responder),
          name: data.name || TYPE_TO_NAME_MAP[data.type] || 'Name',
        },
        position: data.position,
      },
    };

    const positions = section.items.map((item, index) => ({
      id: item.id,
      position: index >= data.position ? index + 1 : index,
    }));

    const positionsUpdate = {
      workspaceId: workspaceCurrentId,
      sectionId: section.id,
      pageId: page.id,
      command: 'library/items/bulk_update',
      items: positions,
    };

    this.cable.perform('exec', objectFrom(item));
    this.cable.perform('exec', objectFrom(positionsUpdate));

    if (showNotification) {
      NotificationsService.showInfo(
        `Item was added to <strong>${ section.name }</strong> section.`
      );
    }

    return item;
  },
  async addLibraryMultipleItems({ getters, commit, dispatch }, { sectionId, pageId, items, position }) {
    const { workspaceCurrentId } = getters;
    let section;
    let page;

    items = items.filter((i) => !['paragraph', 'image'].includes(i.type));

    if (pageId) {
      page = getters.libraryGetPageById(pageId);
    }

    if (!sectionId) {
      sectionId = page.sections[0] && page.sections[0].id;
    }

    if (sectionId) {
      section = getters.libraryGetSectionById(sectionId);
    }

    if (position === undefined) {
      position = section.items.length;
    }

    const count = items.length;

    if (!count) return;

    const positions = section.items.map((item, index) => ({
      id: item.id,
      position: index >= position ? index + count : index,
    }));

    const positionsUpdate = {
      workspaceId: workspaceCurrentId,
      sectionId: section.id,
      pageId: page.id,
      command: 'library/items/bulk_update',
      items: positions,
    };

    items.forEach((data, i) => {
      const item = {
        workspaceId: workspaceCurrentId,
        sectionId: section.id,
        pageId: page.id,
        command: 'library/items/create',
        position: position + i,
        item: {
          name: data.name || TYPE_TO_NAME_MAP[data.type] || 'Name',
          color: data.color || '#abc0c8',
          customMethodItem: {
            ...data,
            customItemFields: (data.customItemFields || []).filter((item) => !item.responder),
            name: data.name || TYPE_TO_NAME_MAP[data.type] || 'Name',
          },
          position: position + i,
        },
      };

      this.cable.perform('exec', objectFrom(item));
    })
    
    this.cable.perform('exec', objectFrom(positionsUpdate));

    NotificationsService.showInfo(
      count === 1 ?
      `Item was added to <strong>${ section.name }</strong> section.`
      :
      `${count} ${pluralize(count, 'item')} was added to <strong>${ section.name }</strong> section.`
    );

    return items;
  },
  async changeLibraryItem({ getters, commit }, { id, sectionId, pageId, data }) {
    const { workspaceCurrentId } = getters;
    const item = getters.libraryGetItemById(id);
    let section;
    let page;

    if (sectionId && pageId) {
      section = getters.libraryGetSectionById(sectionId);
      page = getters.libraryGetPageById(pageId);
    }

    if (!item) return;

    const payload = {
      command: 'library/items/update',
      workspaceId: workspaceCurrentId,
      sectionId: section.id,
      pageId: page.id,
      id,
      item: {
        ...data,
        customMethodItem: { ...data },
      },
    };

    this.cable.perform('exec', objectFrom(payload));
  },
  async moveLibraryItem({ getters, commit }, { sectionId, pageId, id, index }) {
    const { workspaceCurrentId } = getters;
    const item = getters.libraryGetItemById(id);
    const oldSection = getters.libraryGetSectionById(item.sectionId);
    const newSection = getters.libraryGetSectionById(sectionId);

    if (!item || !oldSection || !newSection) return;

    const oldItems = [
      ...byPosition(oldSection.items.filter((i) => i.id !== id)).map((item, i) => ({
        id: item.id,
        position: (sectionId === item.sectionId && i >= index) ? i + 1 : i,
        sectionId: item.sectionId,
      })),
      {
        id: item.id,
        position: index,
        sectionId,
      },
    ];

    const newItems = [
      ...byPosition(newSection.items.filter((i) => i.id !== id)).map((item, i) => ({
        id: item.id,
        position: (sectionId === item.sectionId && i >= index) ? i + 1 : i,
        sectionId: item.sectionId,
      })),
    ];

    const payload = {
      command: 'library/items/bulk_update',
      workspaceId: workspaceCurrentId,
      pageId,
    };

    if (oldSection.id !== newSection.id) {
      this.cable.perform('exec', objectFrom({
        ...payload,
        sectionId: newSection.id,
        items: newItems,
      }));
      commit('LIBRARY_ITEMS_BULK_UPDATE', { data: newItems });
    }

    this.cable.perform('exec', objectFrom({
      ...payload,
      sectionId: oldSection.id,
      items: oldItems,
    }));

    commit('LIBRARY_ITEMS_BULK_UPDATE', { data: oldItems });
  },
  async removeLibraryItem({ getters, commit }, { sectionId, pageId, id }) {
    const { workspaceCurrentId } = getters;
    const section = getters.libraryGetSectionById(sectionId);

    if (!section) return;

    const page = getters.libraryGetPageById(pageId);

    const payload = {
      command: 'library/items/destroy',
      workspaceId: workspaceCurrentId,
      sectionId: section.id,
      pageId: page.id,
      id,
    };

    this.cable.perform('exec', objectFrom(payload));
  },
};

const mutations = {
  LIBRARY_SET(store, { workspaceCurrentId, library }) {
    merge(store.libraries, { [workspaceCurrentId]: library });
  },
  LIBRARY_TOGGLE(store, toggle) {
    store.open = toggle;
  },
  LIBRARY_ADD_PAGE(store, { workspaceCurrentId, page }) {
    const lib = store.libraries[workspaceCurrentId] || [];

    lib.push(page);
    store.lastAddedPage = page.id;
  },
  LIBRARY_CHANGE_PAGE(store, { page, data }) {
    merge(page, data);
  },
  LIBRARY_REMOVE_PAGE(store, { workspaceCurrentId, id }) {
    const lib = store.libraries[workspaceCurrentId];

    if (!lib) return;

    store.libraries[workspaceCurrentId] = lib.filter((p) => p.id !== id);
  },
  LIBRARY_ADD_SECTION(store, { page, section }) {
    page.sections.push(parseSection(section));
  },
  LIBRARY_CHANGE_SECTION(store, { section, data }) {
    merge(section, data);
  },
  LIBRARY_REMOVE_SECTION(store, { page, id }) {
    page.sections = page.sections.filter((s) => s.id !== id);
  },
  LIBRARY_ADD_ITEM(store, { section, item, position = null }) {
    if (position !== null) {
      section.items.splice(position, 0, item);
    } else {
      section.items.push(item);
    }
  },
  LIBRARY_CHANGE_ITEM(store, { item, data }) {
    merge(item, data);
  },
  LIBRARY_REMOVE_ITEM(store, { section, id }) {
    section.items = section.items.filter((i) => i.id !== id);
  },
  LIBRARY_ITEMS_BULK_UPDATE(store, { data }) {
    data.forEach((itemData) => {
      const item = deepFindInObject(store.libraries, 'id', itemData.id);

      if (itemData.sectionId && item.sectionId !== itemData.sectionId) {
        const oldSection = deepFindInObject(store.libraries, 'id', item.sectionId);
        const newSection = deepFindInObject(store.libraries, 'id', itemData.sectionId);

        oldSection.items = oldSection.items.filter((i) => i.id !== item.id);
        newSection.items.push(item);
      }

      merge(item, itemData);
    });
  },
  LIBRARY_SECTIONS_BULK_UPDATE(store, { data }) {
    data.forEach((itemData) => {
      const item = deepFindInObject(store.libraries, 'id', itemData.id);
      merge(item, itemData);
    });
  },
};

export default {
  mutations,
  actions,
  getters,
  state,
};
