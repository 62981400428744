export const TYPE_TO_NAME_MAP = {
  'cards': 'Cards',
  'gallery': 'Gallery',
  'summary': 'Summary',
  'paragraph': 'Paragraph',
  'image': 'Image',
  'charts': 'Charts',
  'checklist': 'Checklist',
  'custom_1': 'Scale',
  'custom_2': 'Multiple Choice',
  'custom_3': 'Single Choice',
  'custom_4': 'Cards',
  'custom_5': 'Text',
  'pages': 'Pages',
  'scatter_plot': 'Pages chart',
  'report_1': 'Report insights',
  'report_2': 'Report insights list',
  'report_3': 'Report text',
  'report_4': 'Report summary',
  'report_5': 'Report title',
};

export const SURVEY_TYPES = ['custom_1', 'custom_2', 'custom_3', 'custom_4', 'custom_5'];