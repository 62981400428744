export const getScrollbarWidth = () => {
  const temp = document.createElement('div');
  temp.style.visibility = 'hidden';
  temp.style.overflow = 'scroll';
  temp.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(temp);

  const inner = document.createElement('div');
  temp.appendChild(inner);

  const scrollbarWidth = (temp.offsetWidth - inner.offsetWidth);

  temp.parentNode.removeChild(temp);

  return scrollbarWidth;
}

export const xsMax = () => {
  return window.innerWidth - getScrollbarWidth() <= 767;
}

export const shake = (dom) => {
  dom.classList.add('shake');
  window.setTimeout(() => dom.classList.remove('shake'), 500);
}

export const blink = (dom) => {
  dom.classList.add('blink');
  window.setTimeout(() => dom.classList.remove('blink'), 2000);
}

export function hasParentWithClasses(element, classes) {
  let classNames = element.className && element.className.toString().split(' ');

  if (classNames) {
    for (let c of classes) {
      if (classNames.indexOf(c) >= 0) {
        return element;
      }
    }
  }

  return (
    element.parentNode && hasParentWithClasses(element.parentNode, classes)
  );
}

export function hasParent(element, parent) {
  const callable = typeof parent === 'function';
  if (parent) {
    if (callable) {
      if (parent(element)) {
        return element;
      }
    } else {
      for (let p of parent) {
        if (p === element) {
          return element;
        }
      }
    }
  }

  return (
    element.parentNode && hasParent(element.parentNode, parent)
  );
}

export function placeCaretAt(el, atStart = false) {
  el.focus();
  if (
    typeof window.getSelection != 'undefined' &&
    typeof document.createRange != 'undefined'
  ) {
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(atStart);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != 'undefined') {
    var textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(atStart);
    textRange.select();
  }
}

export function setCaretPosition(elem, caretPos) {
  if (elem != null) {
    elem.blur();
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else {
      if (elem.selectionStart) {
        elem.focus();
        elem.setSelectionRange(caretPos, caretPos);
      } else elem.focus();
    }
  }
}

export function getElementDistanceToCursor(elem, mouse) {
  var b = elem.getBoundingClientRect();
  var dx = 0;
  var dy = 0;
  var sideX = 0;
  var sideY = 0;

  if (mouse.x < b.left) {
    dx = b.left - mouse.x;
    sideX = -1;
  } else if (mouse.x > b.right) {
    dx = b.right - mouse.x;
    sideX = 1;
  } else {
    sideX = 0;
  }

  if (mouse.y < b.top) {
    dy = b.top - mouse.y;
    sideY = -1;
  } else if (mouse.y > b.bottom) {
    dy = b.bottom - mouse.y;
    sideY = 1;
  } else {
    sideY = 0;
  }

  return {
    dist: Math.floor(Math.sqrt(dx * dx + dy * dy)),
    sideY,
    sideX,
  };
}

export function getCaretPosInput(oField) {
  // Initialize
  var iCaretPos = 0;

  // IE Support
  if (document.selection) {
    // Set focus on the element
    oField.focus();

    // To get cursor position, get empty selection range
    var oSel = document.selection.createRange();

    // Move selection start to 0 position
    oSel.moveStart('character', -oField.value.length);

    // The caret position is selection length
    iCaretPos = oSel.text.length;
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart == '0')
    iCaretPos =
      oField.selectionDirection == 'backward'
        ? oField.selectionStart
        : oField.selectionEnd;

  // Return results
  return iCaretPos;
}

export function getCaretPosElement(ele) {
  let selection = document.getSelection();
  
  if (!selection.rangeCount) {
    return 0;
  }

  let _range = selection.getRangeAt(0);
  let range = _range.cloneRange();
  range.selectNodeContents(ele);
  range.setEnd(_range.endContainer, _range.endOffset);
  return range.toString().length;
}

function createRange(node, chars, range) {
    if (!range) {
        range = document.createRange()
        range.selectNode(node);
        range.setStart(node, 0);
    }

    if (chars.count === 0) {
        range.setEnd(node, chars.count);
    } else if (node && chars.count >0) {
        if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
                chars.count -= node.textContent.length;
            } else {
                 range.setEnd(node, chars.count);
                 chars.count = 0;
            }
        } else {
            for (var lp = 0; lp < node.childNodes.length; lp++) {
                range = createRange(node.childNodes[lp], chars, range);

                if (chars.count === 0) {
                   break;
                }
            }
        }
   }

   return range;
};

function setCurrentCursorPosition(node, chars) {
    if (chars >= 0) {
        const selection = window.getSelection();

        const range = createRange(node, { count: chars });

        if (range) {
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
};

export function setCaretPos(elem, type = 'end') {
  if (typeof type === 'number') {
    requestAnimationFrame(() => {
      setCurrentCursorPosition(elem, type);
    })
  } else if (['INPUT', 'TEXTAREA'].includes(elem.tagName)) {
    setCaretPosition(elem, type === 'start' ? 0 : elem.value.length);
  } else if (elem.contentEditable === 'true') {
    placeCaretAt(elem, type === 'start');
  } else {
    elem.focus();
  }
}

export function getCaretPos(oField) {
  if (['INPUT', 'TEXTAREA'].includes(oField.tagName)) {
    return getCaretPosInput(oField);
  } else {
    return getCaretPosElement(oField);
  }
}

export function isHidden(el) {
  return el.offsetParent === null || !el.offsetWidth;
}

export function getTextOffset(el, parent) {
  let currentParent = el.parentNode;
  let currentEl = el;
  let left = 0;
  let right = 0;
  let length = el.textContent.length;

  while(currentParent && currentParent !== parent) {
    let children = Array.from(currentParent.childNodes);

    for (let child of children) {
      if (child === currentEl) {
        break;
      }

      left += child.textContent.length;
    }

    currentEl = currentParent;
    currentParent = currentParent.parentNode;
  }

  right = left + length;

  return { left, right };
}

const EXCLUDE_CLASSES = [
  'wysiwyg__tags',
  'ql-clipboard',
  'ql-tooltip',
  'side-dropdown-menu__menu',
  'tooltip__tip-position',
  'drop-target',
];

export function cloneOptimizedNode(node) {
  if (node.classList) {
    for (const className of EXCLUDE_CLASSES) {
      if (node.classList.contains(className)) {
        return;
      } 
    }
  }

  const clone = node.cloneNode();
  const children = node.childNodes;

  for (const child of children) {
    const childClone = cloneOptimizedNode(child);
    if(childClone) {
      clone.appendChild(childClone);
    }
  }

  return clone;
}

export function selectText(element) {
  if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
  } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
  }
}