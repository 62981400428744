/* global gapi */

import { GOOGLE_ID } from 'config';
import loadScript from 'utils/load-script';

const SDK_URL = 'https://apis.google.com/js/api.js';

class GoogleLogin {
  constructor() {
    this._loadPromise = null;
  }

  login() {
    if (!this._loadPromise) {
      throw new Error('[google api] google api must be loaded before login');
    }
    return (
      this._login()
        .then((authResponse) => authResponse)
        // eslint-disable-next-line camelcase
        .then(({ id_token }) => ({ token: id_token }))
    );
  }

  _login() {
    const auth = gapi.auth2.getAuthInstance();
    return auth.signIn().then((user) => user.getAuthResponse(true));
  }

  load() {
    if (!this._loadPromise) {
      this._loadPromise = this.loadSdk();
    }

    return this._loadPromise;
  }

  loadSdk() {
    return loadScript(SDK_URL)
      .then(() => this.loadAuth2Client())
      .then(() => this.initClient());
  }

  loadAuth2Client() {
    return new Promise((resolve) => gapi.load('client:auth2', resolve));
  }

  initClient() {
    return gapi.auth2.init({
      client_id: GOOGLE_ID,
      ux_mode: 'popup',
    });
  }
}

export default new GoogleLogin();
