<template>
  <div
    :class="{
      ...classNames,
      'responders-dropdown-list': isListFormat,
    }"
  >
    <BaseTooltip
      behaviour="click"
      appearance="light-box"
      :render-content-only="showAsList"
      @toggle="toggleHandler"
    >
      <StackedAvatars
        v-if="!$slots.default && valueFormat === 'icon' && list === 'responders'"
        :users="value"
      />
      <TagsAvatars
        v-else-if="!$slots.default && valueFormat === 'icon' && list === 'tags'"
        :tags="value"
      />
      <span
        v-if="!$slots.default && valueFormat === 'text'"
        :class="valueSpanClass"
        :users="value"
      >
        {{ valueText }}
      </span>
      <div v-if="isListFormat">
        <div
          v-if="canEdit"
          class="tag-selector__search"
        >
          <Icon
            class="tag-selector__icon"
            name="search-light"
          />
          <div
            class="tag-selector__placeholder"
            :data-placeholder="searchPlaceholder"
          />
          <input
            ref="searchInput"
            v-model="searchedText"
            class="tag-selector__search-input"
            @focus="searchFocus = true"
            @blur="searchFocus = false"
            @keydown.stop="handleKeydown"
          />
        </div>
        <li
          v-for="item of parsedValue"
          :key="item.id"
          ref="item"
          class="tag-selector__item"
          :class="{
            'tag-selector__item--selected': true,
            [`tag-selector__item--${list}`]: true,
          }"
          @click.stop="itemClicked(item)"
        >
          <div class="tag-selector__item-body">
            <UserAvatar
              v-if="listType === 'responders'"
              class="tag-selector__ico"
              mode="email"
              :name="item.name"
            />
            <MethodIcon
              v-else-if="listType === 'methods'"
              class="tag-selector__ico"
              :method="item"
            />
            <span
              v-else-if="listType === 'tags'"
              class="tag-colour tag-selector__ico"
              :style="{background: item.color}"
            />
            <div
              class="tag-selector__item-name"
              v-html="item.html || item.name"
            />
            <Icon
              v-if="canEdit"
              class="tag-selector__item-ico"
              name="tick"
            />
          </div>
        </li>
      </div>
      <slot v-else />
      <template v-slot:content>
        <div
          class="tag-selector"
          :class="classNames"
        >
          <div class="tag-selector__body">
            <div
              v-if="parsedHeading"
              class="tag-selector__header"
            >
              <div class="tag-selector__title">
                {{ parsedHeading }}
              </div>
            </div>
            <div
              v-if="!isListFormat && canEdit"
              class="tag-selector__search"
            >
              <Icon
                class="tag-selector__icon"
                name="search-light"
              />
              <div
                class="tag-selector__placeholder"
                :data-placeholder="searchPlaceholder"
              />
              <input
                ref="searchInput"
                v-model="searchedText"
                class="tag-selector__search-input"
                @focus="searchFocus = true"
                @blur="searchFocus = false"
                @keydown.stop="handleKeydown"
              />
            </div>
            <div class="tag-selector__limit" v-if="limitReached && shouldShowNew">
              Limit of 30 tags has been reached. Unlock to add new tag
            </div>
            <perfect-scrollbar
              class="tag-selector__scroll"
            >
              <ul
                ref="itemsSelectorList"
                class="tag-selector__list"
              >
                <li
                  v-if="showSelectAll"
                  class="tag-selector__item tag-selector__item--action"
                  :class="{
                    [`tag-selector__item--${list}`]: true,
                  }"
                  @click.stop="selectAll"
                >
                  <div class="tag-selector__item-body">
                    <Icon
                      class="tag-selector__ico"
                      name="tick"
                    />
                    <div class="tag-selector__item-name">
                      Select all
                    </div>
                  </div>
                </li>
                <li
                  v-if="showDeselectAll"
                  class="tag-selector__item tag-selector__item--action"
                  :class="{
                    [`tag-selector__item--${list}`]: true,
                  }"
                  @click.stop="deselectAll"
                >
                  <div class="tag-selector__item-body">
                    <Icon
                      class="tag-selector__ico"
                      name="tick"
                    />
                    <div class="tag-selector__item-name">
                      Deselect all
                    </div>
                  </div>
                </li>
                <li
                  v-for="(item, index) of parsedList"
                  :key="item.id"
                  ref="item"
                  class="tag-selector__item"
                  :class="{
                    'tag-selector__item--selected': item.selected,
                    'tag-selector__item--active': activeIndex === index,
                    [`tag-selector__item--${list}`]: true,
                  }"
                  @click.stop="itemClicked(item)"
                >
                  <div class="tag-selector__item-body">
                    <UserAvatar
                      v-if="listType === 'responders'"
                      class="tag-selector__ico"
                      mode="email"
                      :name="item.name"
                    />
                    <MethodIcon
                      v-else-if="listType === 'methods'"
                      class="tag-selector__ico"
                      :method="item"
                    />
                    <span
                      v-else-if="listType === 'tags'"
                      class="tag-colour tag-selector__ico"
                      :style="{background: item.color}"
                    />
                    <div
                      class="tag-selector__item-name"
                      v-html="item.html || (item.itemsCount ? `${item.name} (${item.itemsCount})` : item.name)"
                    />
                    <Icon
                      class="tag-selector__item-ico"
                      name="tick"
                    />
                  </div>
                </li>
              </ul>
            </perfect-scrollbar>
            <div
              v-if="shouldShowNew"
              class="tag-selector__new"
              :class="{
                'tag-selector__new--focus': activeIndex === parsedList.length
              }"
              @click.stop="addNewItem"
            >
              <Icon name="plus-small" />
              {{ searchedText }}
            </div>
          </div>
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>
<script>
import { TAG_COLORS } from 'constants/tag-colors';
import { uniqueByKey } from 'helpers/array';
import { alphabetically } from 'helpers/sort';
import uuid from 'utils/id';
import { pluralize } from 'helpers/string';
import { mapGetters } from 'vuex';

export default {
  name: 'RespondersDropdown',
  emits: ['input', 'toggle', 'update:modelValue', 'itemSelect', 'itemUnselect'],
  props: {
    value: {
      type: Array,
      default: null,
    },
    modelValue: {
      type: Array,
      default: null,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    list: {
      type: String,
      default: 'responders',
    },
    projectId: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: '',
    },
    valueFormat: {
      type: String,
      default: 'icon',
    },
    canSelectAll: {
      type: Boolean,
      default: false,
    },
    canDeselectAll: {
      type: Boolean,
      default: false,
    },
    showAsList: {
      type: Boolean,
      default: false,
    },
    valueSpanClass: {
      type: String,
      default: '',
    },
    reverseAll: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      myList: [],
      searchFocus: false,
      searchedText: '',
      activeIndex: 0,
      originalList: null,
    };
  },
  mounted() {
    if (this.isListFormat) {
      this.prepareList();
    }
  },
  watch: {
    parsedValue() {
    //  this.setOriginalList();
    },
  },
  computed: {
    ...mapGetters(['tagsLimitReached']),
    parsedValue() {
      const value = (this.value || this.modelValue || []).map((v) => {
        if (typeof v === 'string') {
          return { id: v };
        }

        return v;
      });
      
      return (this.originalList && this.value && value.map((i) => this.originalList.find((o) => o.id === i.id)).filter((t) => !!t)) || value;
    },
    limitReached() {
      return false;
    },
    parsedHeading() {
      if (this.heading === false) return '';

      if (!this.heading) {
        return ({
          'responders': 'Respondents',
          'tags': 'Tags',
          'methods': 'Pages',
          'insights': 'Insights',
          'projects': 'Projects',
        })[this.list] || '';
      }

      return this.heading;
    },
    isListFormat() {
      return this.valueFormat === 'list';
    },
    showSelectAll() {
      return this.canSelectAll && !this.allSelected;
    },
    showDeselectAll() {
      const value = this.value || this.modelValue || [];
      return (!this.canSelectAll && this.canDeselectAll && value && value.length) || (this.canSelectAll && this.allSelected);
    },
    searchPlaceholder() {
      if (!this.canAdd) {
        return 'Search';
      }

      if (this.list === 'tags') {
        return 'Search or add tag';
      }

      return 'Search or add respondent';
    },
    valueText() {
      if (this.canSelectAll && this.allSelected && !this.reverseAll) {
        return 'All';
      }

      if (this.canDeselectAll && this.noneSelected && this.reverseAll) {
        return 'All';
      }

      const value = this.parsedValue;

      if (value.length === 1 && this.list !== 'projects') {
        const tagData = this.originalList.find((o) => o.id === value[0].id);
        return tagData && tagData.name;
      }

      const itemsName = ({
        'responders': 'responder',
        'tags': 'tag',
        'methods': 'page',
        'insights': 'insight',
        'projects': 'project',
      })[this.list];

      return `${value.length} ${pluralize(value.length, itemsName)}`;
    },
    allSelected() {
      const value = this.parsedValue;
      return this.myList && value && value.length === this.myList.length;
    },
    noneSelected() {
      const value = this.parsedValue;
      return this.myList && value && !value.length;
    },
    listType() {
      return this.list;
    },
    classNames() {
      return {
        'responders-dropdown': true,
        'tag-selector--search-focus': this.searchFocus,
        'tag-selector--searching': !!this.searchedText.length,
      };
    },
    shouldShowNew() {
      return !!(
        this.canAdd &&
        this.searchedText &&
        !this.originalList.find((i) => i.name.toLowerCase() === this.searchedText.toLowerCase())
      );
    },
    parsedList() {
      let list = this.myList;

      if (this.searchedText) {
        let newList = [];
        let searchedText = this.searchedText.toLowerCase();
        let regexp = new RegExp(searchedText, 'gim');

        list.forEach((item) => {
          if(item.name.match(regexp)) {
            newList.push({
              ...item,
              html: item.name.replace(regexp, (m) => {
                return `<span class="search-highlight">${m}</span>`;
              }),
            });
          }
        });

        list = newList;
      }

      return list;
    },
  },
  methods: {
    getOriginalList() {
      if (this.list === 'responders') {
        return this.$store.getters.responders;
      } else if (this.list === 'tags') {
        // if (this.projectId) {
        //   return this.$store.getters.tagsInProject(this.projectId);
        // } else {
          return this.$store.getters.tagsInWorkspace(this.$store.getters.workspaceCurrentId);
        // }
      } else if (this.list === 'methods') {
        if (this.projectId) {
          return this.$store.getters.methodsInProject(this.projectId);
        } else {
          return this.$store.getters.projectMethods;
        }
      } else if (this.list === 'insights') {
        return this.$store.getters.insights;
      } else if (this.list === 'projects') {
        return this.$store.getters.projects;
      }

      return this.list;
    },
    setOriginalList() {
      this.originalList = this.getOriginalList();
    },
    selectAll() {
      this.myList = this.myList.map((t) => (
        { ...t, selected: true }
      ));
      this.emitInput();
    },
    deselectAll() {
      this.myList = this.myList.map((t) => (
        { ...t, selected: false }
      ));
      this.emitInput();
    },
    prepareList() {
      const listMode = this.valueFormat === 'list';
      let selected = [];
      let unselected = [];

      this.setOriginalList();

      const value = this.parsedValue;

      this.originalList.forEach((item) => {
        if ((value && value.find((v) => v.id === item.id))) {
          selected.push(item);
        } else {
          unselected.push(item);
        }
      });

      let list = [...(listMode ? [] : selected), ...unselected];

      this.myList = list.map((item) => {
        return {
          ...item,
          selected: (item.selected === undefined ? !!value.find((v) => v.id === item.id) : item.selected),
        };
      });
    },
    toggleHandler(open) {
      this.isOpen = open;
      this.$emit('toggle', open);
      if (open) {
        this.prepareList();
        this.$nextTick(() => {
          this.autofocus && this.$refs.searchInput && this.$refs.searchInput.focus();
        });
      } else {
        this.setOriginalList();
      }
    },
    handleKeydown(e) {
      if (!this.canEdit) return;

      const listLength = this.parsedList.length;
      const min = 0;
      const max = this.shouldShowNew ? listLength : listLength - 1;

      if (e.key === 'ArrowDown') {
        this.activeIndex++;
        if (this.activeIndex >= max) {
          this.activeIndex = max;
        }
        this.adjustVisibility(this.activeIndex);
        e.preventDefault();
      } else if (e.key === 'ArrowUp') {
        this.activeIndex--;
        if (this.activeIndex < min) this.activeIndex = min;
        this.adjustVisibility(this.activeIndex);
        e.preventDefault();
      } else if (e.key === 'Enter') {
        if (this.activeIndex === listLength) {
          this.addNewItem();
        } else {
          this.itemClicked(this.parsedList[this.activeIndex]);
        }
        this.searchedText = '';
        e.preventDefault();
      }
    },
    adjustVisibility(index) {
      let item = this.parsedList[index];
      if (!item) return;
      const cont = this.$refs.itemsSelectorList;
      const contOffsetTop = cont.offsetTop;
      const contRect = cont.getBoundingClientRect();
      const contRectTop = contRect.top;
      const contRectBottom = contRect.bottom;

      const elem = this.$refs.item[index];
      const elemOffsetTop = elem.offsetTop;
      const rect = elem.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      window.cont = cont;
      if (contRectTop > elemTop) {
        cont.scrollTop = elemOffsetTop - contOffsetTop;
      }
      if (contRectBottom < elemBottom) {
        cont.scrollTop = elemOffsetTop - cont.offsetHeight + elem.offsetHeight;
      }
    },
    itemClicked(item) {
      if (!this.canEdit) return;

      const value = this.parsedValue;
      let found = this.myList.find((t) => t.id === item.id);
      let foundInValue = value.find((t) => t.id === item.id);
      let selected = found ? !found.selected : !foundInValue;

      this.myList = this.myList.map((t) =>
        t.id === item.id ? { ...t, selected } : t
      );

      if (this.isListFormat && !selected && !this.isOpen && foundInValue) {
        this.myList = [...this.myList, { ...foundInValue, selected: false }];
      }

      this.emitInput();
      this.emitSelection(item, selected);
    },
    emitSelection(item, selected) {
      if (selected) {
        this.$emit('itemSelect', item);
      } else {
        this.$emit('itemUnselect', item);
      }
    },
    emitInput() {
      let list =  this.myList.filter((t) => t.selected);
      const value = this.parsedValue;

      if (this.isListFormat) {
        let valueList = value.filter((t) => !this.myList.find((m) => m.id === t.id));
        list = [...list, ...valueList];
      }

      list = uniqueByKey(list, (i) => i.id);

      // Send only IDs!
      list = list.map((tag) => ({
        id: tag.id,
      }));

      this.$emit('input', list);
      this.$emit('update:modelValue', list);
    },
    async addNewItem() {
      if (!this.canEdit || !this.canAdd) return;
      if (!this.shouldShowNew) {
        return;
      }
      let id = uuid();
      const randomColor = TAG_COLORS[Math.floor(Math.random()*TAG_COLORS.length)];
      const newItem = {
        id,
        color: randomColor,
        name: this.searchedText,
      };
      this.myList.unshift({...newItem, selected: true});
      this.searchedText = '';
      let result;

      if (this.list === 'tags') {
        result = await this.$store.dispatch('tagAdd', newItem);
      } else if (this.list === 'responders') {
        result = await this.$store.dispatch('createResponder', newItem);
      } else {
        throw new Error(`Undefined list type "${this.list}" in RespondentsDropdown component!`);
      }

      this.myList.forEach((item) => {
        if (item.id === id) {
          item.id = result.id;
        }
      });

      newItem.id = result.id;

      this.emitInput();
      this.emitSelection(newItem, true);

      this.$refs.searchInput.focus();
    },
    clearSearch() {
      this.searchedText = '';
    },
    selectTags(list) {
      if (!this.canEdit) return;
      this.myList = [...this.originalList];
      this.myList = this.myList.map((tag) => {
        return {
          ...tag,
          selected: list.indexOf(tag.id) > -1,
        }
      });
      this.myList.sort((a, b) => {
        if (a.selected === b.selected) {
          return alphabetically(a.name, b.name);
        } else {
          return (b.selected ? 1 : 0) - (a.selected ? 1: 0);
        }
      });
    },
  },
};
</script>
