import { parser as projectParser } from 'models/project';
import * as ApiService from 'services/api.service';

class ProjectService {
  init(store) {
    this.$store = store;
  }

  fetch(workspace, id) {
    return ApiService.get(this.getUrl(workspace, id)).then(projectParser);
  }

  async updateProject(workspace, id, data) {
    this.saving = true;

    try {
      const result = await ApiService.put(this.getUrl(workspace, id), data);
      return projectParser(result);
    } finally {
      this.saving = false;
    }
  }

  async setImage(workspace, id, image) {
    const data = new FormData();
    data.append('image', image);
    return await ApiService.post(
      `${this.getUrl(workspace, id)}/images`,
      data
    );
  }

  async removeImage(workspace, id) {
    return await ApiService.del(
      `${this.getUrl(workspace, id)}/images`
    );
  }

  set saving(value) {
    this.$store.dispatch('uiToggleSave', value);
  }

  getUrl(workspace, id = null) {
    return `api/workspaces/${workspace}/projects/${id}`;
  }
}

export default new ProjectService();
