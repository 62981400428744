<template>
  <ReportWrap
    :name="title"
    v-bind="$props"
  >
    <div class="report-insight">
      <div class="report-insight__body report-insight__body--full">
        <BaseUploader
          ref="uploader"
          class="report-insight__logo"
          theme="simple-logo"
          :uploading="image.uploading"
          :file="image.file"
          :url="image.url"
          default-icon="logotype"
          :can-edit="canEdit"
          @remove="removeImage"
          @change="addImage"
        />
        <TitleInput
          appearance="report-hero-name clear"
          :value="projectName"
          :can-edit="canEdit"
          :placeholder="titlePlaceholder"
          @input="updateOption('projectName', $event)"
          @focus="onTitleFocus"
          @blur="onTitleBlur"
        />
        <TitleInput
          appearance="report-hero-title clear"
          :value="section.name"
          :allow-empty="false"
          :can-edit="canEdit"
          placeholder="Usability Test Report"
          @input="updateName($event)"
        />
        <ul class="report-foot-inputs">
          <li>
            <BaseInput
              class="input--light"
              :can-edit="canEdit"
              :value="dateName"
              placeholder="Date"
              @input="updateOption('dateText', $event)"
              @focus="dateFocus = true"
              @blur="dateFocus = false"
            />
          </li>
          <li>
            <BaseInput
              class="input--light"
              :can-edit="canEdit"
              :value="userName"
              placeholder="Author"
              @input="updateOption('authorText', $event)"
              @focus="userFocus = true"
              @blur="userFocus = false"
            />
          </li>
        </ul>
      </div>
    </div>
  </ReportWrap>
</template>

<script>
import { mapGetters } from 'vuex';
import { timeText } from 'helpers/time';
import ReportWrap from './report-wrap';

export default {
  name: 'ProjectMethodCustom',
  components: {
    ReportWrap,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      titleFocus: false,
      dateFocus: false,
      userFocus: false,
    };
  },
  computed: {
    ...mapGetters(['workspaceCurrent', 'projectCurrent']),
    image() {
      if (typeof this.section.image === 'string') {
        return { url: this.section.image };
      }
      return this.section.image || {};
    },
    titlePlaceholder() {
      return this.workspaceCurrent && this.workspaceCurrent.name;
    },
    title() {
      return this.section.options.projectName || (this.workspaceCurrent && this.workspaceCurrent.name);
    },
    projectName() {
      if (this.titleFocus) {
        return this.section.options.projectName;
      }

      return this.title;
    },
    dateName() {
      if (this.dateFocus) {
        return this.section.options.dateText;
      }

      return this.section.options.dateText || timeText(this.projectCurrent && this.projectCurrent.createdAt);
    },
    userName() {
      if (this.userFocus) {
        return this.section.options.authorText;
      }

      return this.section.options.authorText || (this.projectCurrent && this.workspaceCurrent.projects.find((p) => p.id === this.projectCurrent.id).owner.name);
    },
  },
  methods: {
    onTitleFocus() {
      this.titleFocus = true;
    },
    onTitleBlur() {
      this.titleFocus = false;
    },
    updateOption(name, value) {
      if (this.section) {
        this.$store.dispatch('builderUpdateSectionOptions', {
          id: this.section.id,
          options: {
            [name]: value,
          },
        })
      }
    },
    updateName(name) {
      if (this.section) {
        this.$store.dispatch('methodSectionUpdate', {
          id: this.section.id,
          data: {
            name,
          },
        })
      }
    },
    addImage(image) {
      this.$store.dispatch('builderAddSectionImage', {
        id: this.section.id,
        image,
      });
    },
    removeImage() {
      this.$store.dispatch('builderRemoveSectionImage', {
        id: this.section.id,
      });
    },
  },
};
</script>