import * as TYPES from 'constants/mutations';
import * as ROUTES from 'constants/routes';
import { byPosition } from 'helpers/sort';
import methodModel from 'models/project-method';
import ProjectMethodService from 'services/project-method.service';
import UndoService from 'services/undo.service';
import generateId from 'utils/id';
import { navigateHome } from 'helpers/router';
import { merge } from 'utils/reactive';

const state = {
  projectsMethods: {},
  methods: [],
  removing: null,
  pageTitle: '',
  newMethodId: generateId(),
  isCreatingMethod: false,
};

const getters = {
  projectOriginalMethods: (state, getters) => {
    const projectId = getters.projectCurrentId;
    return state.projectsMethods[projectId] || [];
  },
  projectMethods: (state, getters) => {
    return getters.projectOriginalPhases.filter((p) => p.name !== 'Insights').map((p) => (
      p.designMethods.map((m) => getters.projectMethodById(m.id))
    )).flat();
  },
  methodsInProject: (state, getters) => (projectId) => {
    const phases = getters.projectOriginalPhasesById(projectId);
    return phases.filter((p) => p.name !== 'Insights').map((p) => (
      p.designMethods.map((m) => getters.projectMethodById(m.id))
    )).flat();
  },
  projectMethodById: (state, getters) => (methodId) =>
    state.methods.find(({ id }) => id === methodId) || null,
  projectMethodByType: (state, getters) => (id) =>
    getters.projectOriginalMethods.find(({ type }) => type === id) || null,
  projectMethodsByPhaseId: (state, getters) => (phaseId) =>
    getters.projectOriginalMethods.filter((method) => method.phaseId === phaseId),

  projectCurrentMethod: (state, getters) =>
    getters.projectMethodById(getters.projectCurrentMethodId),
  projectCurrentMethodName: (state, getters) =>
    getters.projectCurrentMethod && getters.projectCurrentMethod.name,
  isProjectMethodFetched: (state, getters) => (methodId) =>
    getters.projectMethodById(methodId) && getters.projectMethodById(methodId).fetched,
  isProjectCurrentMethodFetched: (state, getters) =>
    getters.projectCurrentMethod && getters.projectCurrentMethod.fetched,
  isProjectCurrentMethodForbidden: (state, getters) => {
    return getters.isProjectCurrentMethodFetched && getters.projectCurrentMethod.error;
  },
  isMethodFetched: (state, getters) => (id) =>
    getters.projectMethodById(id) && getters.projectMethodById(id).fetched,

  insights: (state, getters) => {
    if (getters.isPublicRoute) {
      return getters.projectCurrentMethod && getters.projectCurrentMethod.insights;
    }

    const phases = getters.projectOriginalPhases;
    const insightsPhase = phases.filter((p) => p.name === 'Insights');

    if (!insightsPhase) return [];

    return insightsPhase.map((i) => i.designMethods).flat().map((m) => ({
      ...getters.projectMethodById(m.id),
    }));
  },
  
  methodPublicLinkToken: (state, getters) => (id) => {
    const token = getters.projectMethodById(id) && getters.projectMethodById(id).token;
    return token !== 'loading' && token;
  },

  methodPublicLinkTokenLoading: (state, getters) => (id) => {
    return getters.projectMethodById(id) && getters.projectMethodById(id).token === 'loading';
  },

  isMethodInsight: (state, getters) => (id) => {
    return getters.insights.find((i) => i.id === id);
  },

  projectReportId: (state, getters) => {
    const phases = getters.projectOriginalPhases;

    if (!phases) return null;

    const reportsPhase = phases.find((p) => p.name === 'Reports');

    if (!reportsPhase) return null;

    return reportsPhase.designMethods[0] && reportsPhase.designMethods[0].id;
  },

  isMethodReportById: (state, getters) => (id) => {
    const phases = getters.projectOriginalPhases;

    if (!phases) return null;

    const reportsPhase = phases.find((p) => p.name === 'Reports');

    if (!reportsPhase) return null;

    return !!reportsPhase.designMethods.find((m) => m.id === id);
  },

  detailsPageTitle: (state) => state.pageTitle,

  newMethodId: (state) => state.newMethodId,
  isCreatingMethod: (state) => state.isCreatingMethod,

  transcriptions: (state, getters) => {
    return getters.projectMethods.map((method) => ([
      ...(method.remoteTasks || []).filter((task) => task.taskType === 'transcribe').map((t) => ({
        ...t,
        methodId: method.id,
      })),
    ])).flat();
  },

  transcriptionById: (state, getters) => (id) => {
    const transcriptions = getters.projectMethods.map((method) => ([
      ...(method.remoteTasks || []).filter((task) => task.taskType === 'transcribe'),
    ])).flat();
    return transcriptions.find((t) => t.id === id);
  },

  summary: (state, getters) => {
    return (getters.projectCurrentMethod.remoteTasks || []).findLast((t) => t.taskType === 'summarize');
  },
};

const actions = {
  projectMethodsSet({ commit }, { projectId, methods }) {
    commit(TYPES.PROJECT_METHODS_REMOVE);
    commit(TYPES.PROJECT_METHODS_SET, { projectId, methods });
  },
  async projectMethodFetch({ dispatch, getters, commit }, { id = null, projectId = null, ignoreError = false } = {}) {
    await dispatch('requireTags');

    const { workspaceCurrentId } = getters;
    projectId = projectId || getters.projectCurrentId;

    if(getters.isRoute(ROUTES.PROJECT_REPORT)) {
      if (getters.isCreatingMethod) {
        return;
      }

      if (!getters.isProjectPhasesFetched) {
        await dispatch('phasesFetch', projectId);
      }

      const phases = getters.projectOriginalPhases;
      let reportsPhase = phases.find((p) => p.name === 'Reports');

      if (!reportsPhase) {
        reportsPhase = await dispatch('phasesAdd', { name: 'Reports' });
      }

      let reportMethod = reportsPhase.designMethods[0];

      if (!reportMethod) {
        reportMethod = await dispatch('projectMethodAdd', {
          data: {
            name: 'Report',
            templateName: 'report',
            phaseId: reportsPhase.id,
            type: 'CustomMethod',
            kind: 'report',
          },
        });
      }

      id = reportMethod.id;
    }

    let methodId = id;
    if (!methodId) {
      methodId = getters.projectCurrentMethodId;
    }

    if (!methodId) {
      return;
    }

    let method;

    if (getters.isShortcutRoute) {
      method = await ProjectMethodService.fetchPublic(
        methodId
      ).catch((e) => e);
      projectId = 'public';
      const tags = [];

      method.customMethodItems.forEach((item) => {
        item.tags.forEach((tag) => {
          if (!tags.find((t) => t.id === tag.id)) {
            tags.push(tag);
          }
        })

        item.customItemFields.forEach((field) => {
          field.tags.forEach((tag) => {
            if (!tags.find((t) => t.id === tag.id)) {
              tags.push(tag);
            }
          })
        })
      })

      commit(TYPES.TAGS_SET, { projectId, tags });
      dispatch('projectMethodsSet', { projectId, methods: method.insights })
    } else {
      method = await ProjectMethodService.fetch(
        workspaceCurrentId,
        projectId,
        methodId
      ).catch((e) => e);
    }

    if (method.error && ignoreError) {
      return;
    }

    method.id = methodId;
    method.fetched = true;

    await dispatch('projectMethodSet', { projectId, method });

    if (method.remoteTasks) {
      const sections = method.customMethodItems;
      const transcriptions = method.remoteTasks
        .filter((t) => {
          return t.status === 'completed' && t.taskType === 'transcribe';
        })
        .filter((t) => {
          return sections.find((s) => s.options && s.options.transcriptionId === t.id)
        })

      transcriptions.forEach((t) => {
        dispatch('transcriptionFetch', { id: t.id })
      });
    }

    return method;
  },
  projectMethodSet({ commit }, { projectId, method }) {
    commit(TYPES.PROJECT_METHOD_SET, { projectId, method });
  },

  async projectMethodAdd({ dispatch, commit, getters }, { data, index = -1 }) {
    const { workspaceCurrentId } = getters;
    const newMethodId = getters.newMethodId;
    const model = {
      ...methodModel,
      ...data,
      timestamp: Date.now(),
      id: newMethodId,
    };

    const { phaseId } = data;
    const position =
      index === -1
        ? getters.projectMethodsByPhaseId(phaseId).length
        : Math.round(index);
    model.position = position;
    model.phaseId = phaseId;
    const projectId = data.projectId || getters.projectCurrentId;
    commit('METHOD_SET_CREATING', true);
    commit(TYPES.PROJECT_METHOD_ADD, {
      method: model,
      projectId,
      index: position,
    });
    const result = await ProjectMethodService.methodAdd(workspaceCurrentId, projectId, {
      data: model,
      index: position,
    });
    await dispatch('registerIdJoin', { join: result.id, id: newMethodId });
    // commit(TYPES.PROJECT_METHOD_CHANGE, {
    //   method: model,
    //   data: result,
    // });
    // cannot just change added method as other positions might have changed
    await dispatch('phasesFetch');
    await commit('METHOD_RESET_NEW_METHOD_ID');
    setTimeout(() => commit('METHOD_SET_CREATING', false), 200);
    return result;
  },
  async projectInsightAdd({ dispatch, commit, getters }, data) {
    let phase = getters.projectOriginalPhases.find((p) => p.name === 'Insights');

    if (!phase) {
      phase = await dispatch('phasesAdd', {name: 'Insights'});
      await dispatch('phaseUpdate', { id: phase.id, name: 'Insights' });
    }

    let phaseId = phase.id;
    let method;

    try {
      method = await dispatch('projectMethodAdd', {
        data: {
          name: 'New insight',
          phaseId,
          type: 'CustomMethod',
          ...data,
          kind: 'insight',
        },
      });
      dispatch('workspaceLimitsFetch');
    } catch(e) {
      if (e.error && e.error.toLowerCase().indexOf('limit') > -1) {
        navigateHome();
        dispatch('modalShow', {
          name: 'limit-reached',
          data: {
            kind: 'projects',
          },
        });
      }

      return;
    }

    return method;
  },
  async projectMethodRemove({ dispatch, commit, getters }, { projectId = null, fetch = true, id }) {
    const { workspaceCurrentId } = getters;
    if (projectId === null) {
      projectId = getters.projectCurrentId;
    }
    const method = getters.projectMethodById(id);
    commit(TYPES.PROJECT_METHOD_CHANGE, {
      projectId,
      method,
      data: { removed: true },
    });
    try {
      await ProjectMethodService.methodRemove(workspaceCurrentId, projectId, { id });
    } catch(e) {
      commit(TYPES.PROJECT_METHOD_CHANGE, {
        projectId,
        method: getters.projectMethodById(id),
        data: { removed: false },
      });
      throw e;
    }

    dispatch('projectMethodsReorder');
    dispatch('workspaceLimitsFetch');

    fetch && await dispatch('phasesFetch');
  },

  async projectMethodsReorder({ getters }) {
    const { projectMethods, workspaceCurrentId, projectCurrentId } = getters;

    return ProjectMethodService.reorder(workspaceCurrentId, projectCurrentId, {
      newOrder: projectMethods.filter((c) => !c.archived).map((m) => ({
        id: m.id,
        position: m.position,
        parentId: m.phaseId,
      })),
    });
  },

  async projectMethodChangeList({ dispatch, getters, commit }, { id, phaseId, index }) {
    const { workspaceCurrentId } = getters;
    const method = getters.projectMethodById(id);
    const projectId = method.projectId || getters.projectCurrentId;
    const oldPhaseId = method.phaseId;

    commit(TYPES.PROJECT_METHOD_REMOVE, {
      projectId,
      id,
    });

    commit(TYPES.PROJECT_METHOD_ADD, {
      projectId,
      method: { ...method, phaseId },
      index,
    });

    dispatch('projectMethodsReorder');
  },

  async projectMethodChange({ commit, getters, dispatch }, { id, data, local = false }) {
    const { workspaceCurrentId } = getters;
    const method = getters.projectMethodById(id);
    const projectId = (method && method.projectId) || getters.projectCurrentId;

    if (!method) {
      return;
    }

    commit(TYPES.PROJECT_METHOD_CHANGE, {
      projectId,
      method,
      data,
    });

    if (local) return;

    if (data.archived) {
      const isInsight = getters.isMethodInsight(method.id);

      UndoService.addWithNotification({
        text: isInsight ? `Insight '${method.name}' was archived` : `Method '${method.name}' was archived`,
        onResolve: () => {},
        onCancel: async () => {
          try { commit('ARCHIVE_REMOVE', { projectId, id: method.id }) } catch (e) {}
          await commit(TYPES.PROJECT_METHOD_CHANGE, {
            projectId,
            method,
            data: { archived: false },
          });
          await ProjectMethodService.methodChange(workspaceCurrentId, projectId, {
            id: method.id,
            data: { archived: false },
          });
          await dispatch('phasesFetch');
          await dispatch('projectMethodsReorder');
        },
      });
      dispatch('uiToggleArchiving', { type: 'DesignMethod', data: { ...method, ...data } });
    }

    const response = await ProjectMethodService.methodChange(workspaceCurrentId, projectId, {
      id: method.id,
      data,
    });

    await dispatch('projectMethodsReorder');

    return response;
  },

  async projectMethodCreatePublicLink({ commit, getters, dispatch }, { id }) {
    const { workspaceCurrentId } = getters;
    const method = getters.projectMethodById(id);
    const projectId = method.projectId || getters.projectCurrentId;

    commit(TYPES.PROJECT_METHOD_CHANGE, {
      projectId,
      method,
      data: {
        token: 'loading',
      },
    });

    const response = await ProjectMethodService.methodCreatePublicLink(workspaceCurrentId, projectId, method.id);

    commit(TYPES.PROJECT_METHOD_CHANGE, {
      projectId,
      method,
      data: {
        token: response.token,
      },
    });

    return response;
  },

  async projectMethodDestroyPublicLink({ commit, getters, dispatch }, { id }) {
    const { workspaceCurrentId } = getters;
    const method = getters.projectMethodById(id);
    const projectId = method.projectId || getters.projectCurrentId;

    const response = await ProjectMethodService.methodDestroyPublicLink(workspaceCurrentId, projectId, method.id);

    commit(TYPES.PROJECT_METHOD_CHANGE, {
      projectId,
      method,
      data: {
        token: null,
      },
    });

    return response;
  },

  async projectMethodAddSentences({ dispatch, commit, getters }, { id, list }) {
    const { workspaceCurrentId } = getters;
    const method = getters.projectMethodById(id);
    const projectId = method.projectId || getters.projectCurrentId;
    
    for (const sentenceId of list) {
      await ProjectMethodService.joinSentenceWithMethod(workspaceCurrentId, {
        customMethodId: id,
        sentenceId,
      })
    }

    const data = {
      customMethodSentences: [...method.customMethodSentences, ...list.map((sentenceId) => ({ sentenceId }))],
    }

    commit(TYPES.PROJECT_METHOD_CHANGE, {
      projectId,
      method,
      data,
    });
  },

  async projectMethodRemoveSentence({ commit, dispatch, getters }, { methodId, sentenceId }) {
    const { workspaceCurrentId } = getters;
    const method = getters.projectMethodById(methodId);
    const projectId = method.projectId || getters.projectCurrentId;

    const data = {
      customMethodSentences: (method.customMethodSentences || []).filter((s) => s.sentenceId !== sentenceId),
    }

    commit(TYPES.PROJECT_METHOD_CHANGE, {
      projectId,
      method,
      data,
    });

    await ProjectMethodService.unjoinSentenceMethod(workspaceCurrentId, {
      customMethodId: methodId,
      sentenceId,
    })
  },

  methodInnerHeaderChange({ getters, commit }, { id, data }) {
    const { workspaceCurrentId } = getters;
    const item = getters.projectMethodById(id);
    const newData = {
      id: item.id,
      ...data,
    };
    commit('METHOD_INNER_HEADERS_CHANGE', { item, newData });
    ProjectMethodService.changeHeader({
      workspace: workspaceCurrentId,
      parentId: newData.projectId,
      id,
      newData,
    });
  },

  async methodGenerateSummary({ getters, commit }, { id, prompt }) {
    const { workspaceCurrentId } = getters;
    const method = getters.projectMethodById(id);
    const projectId = method.projectId || getters.projectCurrentId;
    const localId = generateId();

    commit('METHOD_UPDATE_REMOTE_TASK', { methodId: method.id, taskId: localId, data: {
      id: localId,
      status: 'processing',
      progress: 0,
      taskType: 'summarize',
    } })

    const data = await ProjectMethodService.generateSummary(workspaceCurrentId, projectId, method.id, { prompt });

    commit('METHOD_UPDATE_REMOTE_TASK', { methodId: method.id, taskId: localId, data })
  },

  async transcriptionFetch({ getters, commit }, { id }) {
    const { workspaceCurrentId, projectCurrentMethodId } = getters;
    const method = getters.projectMethodById(projectCurrentMethodId);
    const projectId = method.projectId || getters.projectCurrentId;

    const data = await ProjectMethodService.transcriptionFetch(workspaceCurrentId, projectId, method.id, id);

    commit('METHOD_UPDATE_REMOTE_TASK', { methodId: method.id, taskId: data.id, data })
  },
};

const mutations = {
  [TYPES.PROJECT_METHODS_SET](state, { projectId, methods }) {
    let found = state.projectsMethods[projectId];

    if (found) {
      methods.forEach((method) => {
        const prev = found.find(({ id }) => id === method.id);
        if (prev) {
          const copy = {
            ...prev,
            ...method,
          };
          merge(method, copy);
        }
      });
    }

    state.projectsMethods = { ...state.projectsMethods, [projectId]: methods };
    
    methods.forEach((method) => {
      const old = state.methods.find((m) => m.id === method.id) || {};
      state.methods = [...state.methods.filter((m) => m.id !== method.id), { ...old, ...method }];
    })
  },
  [TYPES.PROJECT_METHODS_REMOVE](state, projectId) {
    state.projectsMethods = { ...state.projectsMethods, [projectId]: null };
  },

  [TYPES.PROJECT_METHOD_SET](state, { projectId, method }) {
    let methods = state.projectsMethods[projectId] || [];
    let storeMethod = methods.find(({ id }) => id === method.id);

    methods = methods.filter(({ id }) => id !== method.id);

    if (storeMethod) {
      method = {
        ...storeMethod,
        error: null,
        ...method,
      }
    }

    methods.push(method);

    state.projectsMethods = { ...state.projectsMethods, [projectId]: methods };
    state.methods = [...state.methods.filter((m) => m.id !== method.id), method];
  },

  [TYPES.PROJECT_METHOD_ADD](state, { method, projectId, index }) {
    if (!state.projectsMethods[projectId]) {
      state.projectsMethods[projectId] = [];
    }

    let methods = byPosition(state.projectsMethods[projectId].filter((a) => !a.archived && a.phaseId === method.phaseId));

    if (typeof index === 'number') {
      methods = byPosition(methods);
      methods.splice(index, 0, method);
      methods.forEach((method, i) => {
        method.position = i;
      });
    }

    state.projectsMethods[projectId].push(method);

    state.projectsMethods = { ...state.projectsMethods };
    state.methods = [...state.methods.filter((m) => m.id !== method.id), method];
  },
  [TYPES.PROJECT_METHOD_REMOVE](state, { projectId, id }) {
    if (!state.projectsMethods[projectId]) {
      return;
    }
    const method = state.projectsMethods[projectId].find((m) => m.id === id);
    const methods = state.projectsMethods[projectId].filter((method) => method.id !== id);
    const phaseMethods = byPosition(methods.filter((a) => !a.archived && a.phaseId === method.phaseId));

    phaseMethods.forEach((item, i) => {
      item.position = i;
    });

    state.projectsMethods = { ...state.projectsMethods, [projectId]: methods };
  },
  [TYPES.PROJECT_METHOD_CHANGE](state, { method, data }) {
    const methods = state.methods.filter((m) => m.id !== method.id);
    const phaseMethods = byPosition(methods.filter((a) => !a.archived && a.phaseId === method.phaseId));

    phaseMethods.forEach((item, i) => {
      item.position = i;
    });

    merge(method, data);
    const old = state.methods.find((m) => m.id === method.id) || {};
    state.methods = [...state.methods.filter((m) => m.id !== method.id), { ...old, ...method }];
  },

  METHOD_UPDATE_REMOTE_TASK(state, { methodId, taskId, data }) {
    const remoteTasks = state.methods.map((m) => m.remoteTasks || []).flat();
    const task = remoteTasks && remoteTasks.find((t) => t.id === taskId);

    if (task) {
      merge(task, data);
    } else {
      const method = state.methods.find((m) => m.id === methodId);
      
      if (!method) {
        return;
      }
      
      if (!method.remoteTasks) {
        method.remoteTasks = [];
      }

      method.remoteTasks.push({
        id: taskId,
        ...data,
      });
    }
  },

  [TYPES.LOGGED_OUT](state) {
    state.projectsMethods = {};
  },

  METHOD_RESET_NEW_METHOD_ID(state) {
    state.newMethodId = generateId();
  },

  METHOD_SET_CREATING(state, is) {
    state.isCreatingMethod = is;
  },

  METHOD_INNER_HEADERS_CHANGE(state, { item, newData }) {
    item[`${newData.header}`] = newData.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
