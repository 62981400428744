const BOOKS = ['Where the Wild Things Are',
  'The Summer Book',
  'How I Live Now',
  'The Story of Doctor Dolittle',
  'Pippi Longstocking',
  'The Little Prince',
  'The Wizard of Oz',
  'Alice\'s in Wonderland',
  'The Adventures of Tintin',
  'The Secret Garden',
  'The Hobbit',
  'Harry Potter',
  'How to Train Your Dragon',
  'Pinocchio',
  'The Princess and the Pea',
  'Hippos Go Beserk!',
  'Winnie-the-Pooh']

export default BOOKS;