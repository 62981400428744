<template>
  <SideDropdownMenu
    :enabled="canEdit"
    :menu="[
      {
        text: 'Archive',
        action: onArchive,
      },
    ]"
  >
    <router-link
      class="method-box__link"
      :to="navigateTo"
    >
      <MethodItemContent
        :method="method"
        :slug="slug"
        :data-method="slug"
        @calendar-show="onCalendarShow"
        @remove="onRemove"
        v-bind="$attrs"
      />
    </router-link>
  </SideDropdownMenu>
</template>

<script>
import { inputFormat } from 'helpers/date';
import isMobile from 'helpers/mobile';
import { slugify } from 'utils/text';
import triggerClick from 'utils/trigger-click';
import { mapGetters } from 'vuex';

import MethodItemContent from './content';

export default {
  name: 'ProjectMethod',
  components: {
    MethodItemContent,
  },
  props: {
    method: {
      type: Object,
      required: true,
    },
    navigateTo: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data: () => ({
    isCalendarOpen: false,
  }),
  computed: {
    ...mapGetters([
      'isEditing',
    ]),
    slug() {
      return slugify(this.method.type);
    },
    name() {
      return this.method.name;
    },
    classes() {
      return {
        'last-edit': !this.isEditing && this.method.edited,
        'js-empty': this.isEmpty,
      };
    },
    isEmpty() {
      return !this.method.itemsCount;
    },
    date() {
      return new Date(this.method.timestamp);
    },
    inputFormatDate() {
      return inputFormat(this.date);
    },
  },
  methods: {
    onTimestampChange(date) {
      const timestamp = new Date(date).getTime();
      this.$emit('timestamp-changed', {
        id: this.method.id,
        timestamp,
      });
    },
    onCalendarShow() {
      if (isMobile) {
        this.$refs.dateInput.focus();
        return;
      }

      triggerClick();
      this.$refs.datepicker.showCalendar();
    },
    onCalendarVisibility(visible) {
      this.isCalendarOpen = visible;
      this.$emit('calendar-visible', visible);
    },

    onMove({ direction }) {
      this.$emit('move', {
        id: this.method.id,
        direction,
      });
    },
    onArchive() {
      this.$emit('archive', this.method);
    },
  },
};
</script>
