import EventsService, { EVENTS } from 'services/events.service';
import NotificationsService from 'services/notifications.service';
import generateId from 'utils/id';

const UNDO_DURATION = 7 * 1000;

class UndoService {
  constructor() {
    this.pending = {};

    this.initListeners();
  }

  initListeners() {
    EventsService.on(EVENTS.UNLOAD, () => this.resolveAll());
    EventsService.on(EVENTS.LOGGING_OUT, () => this.resolveAll());

    EventsService.on(EVENTS.NOTIFICATION_HIDE, (id) => this.resolve(id));
    EventsService.on(EVENTS.NOTIFICATION_UNDO, (id) => this.cancel(id));
  }

  addWithNotification({ text, ...data }) {
    const id = this.add(data);

    NotificationsService.showUndo({ text, id, duration: UNDO_DURATION });

    return id;
  }

  add({ onResolve, onCancel, onError }) {
    const id = generateId();

    this.pending[id] = {
      onResolve,
      onCancel,
      onError,
    };

    return id;
  }

  cancel(id) {
    if (!this.has(id)) return false;

    const entry = this.pending[id];
    entry.onCancel();
    this._remove(id);

    return true;
  }

  async resolve(id) {
    if (!this.has(id)) return false;

    const entry = this.pending[id];
    // if API call did not succeed then run cancel handler
    try {
      await entry.onResolve();
    } catch (error) {
      if (typeof entry.onError === 'function') {
        entry.onError(error);
      }

      entry.onCancel();
    }
    this._remove(id);

    return true;
  }

  resolveAll() {
    Object.keys(this.pending).forEach((id) => this.resolve(id));
  }

  has(id) {
    return this.pending.hasOwnProperty(id);
  }

  _remove(id) {
    delete this.pending[id];
  }
}

export default new UndoService();
