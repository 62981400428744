<template>
  <div class="archive-phase">
    <TitleInput
      appearance="phase-title"
      :value="data.name || data.content"
      :can-edit="false"
    />
    <div class="archive-phase__body">
      <div
        class="method-item"
        data-method="stakeholder-interview"
      >
        <div class="method-item__icon">
          <Icon :name="`method-stakeholder-interview`" />
        </div>
        <div class="method-item__body">
          <span class="method-item__name">
            Page
          </span>
          <p class="method-item__count">
            {{ data.name }} page
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProjectArchiveItemDefault',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
