export default {
  bind(element) {
    const handler = (event) => {
      event.preventDefault();
      return false;
    };

    element.addEventListener('dragenter', handler);
    element.addEventListener('dragover', handler);
    element.addEventListener('drop', handler);
  },
};
