<template>
<div
  class="link-box"
>
  {{ url }}
  <div class="link-box__btn" @click="onClick">
    <Icon name="copy" />
  </div>
</div>
</template>
<script>
import NotificationsService from 'services/notifications.service';

export default {
  name: 'LinkBox',
  components: {
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
  },
  computed: {
  },
  methods: {
    onClick() {
      this.$copyText(this.url);
      NotificationsService.showInfo('Link copied to clipboard');
    },
  },
};
</script>
