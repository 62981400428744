<template>
  <div class="addon-checklist">
    <SortableAnimatedList
      :transport="section.id"
      :value="section.customItemFields"
      :options="draggableOptions"
      class="sortable-cards"
      @start="onStart"
      @end="onEnd"
    >
      <div
        v-for="item of fields"
        :key="$store.getters.idJoin(item.id)"
        class="addon-checklist__drag"
        :data-id="item.id"
        @mousedown.stop
      >
        <div class="builder-draggable-field">
          <div
            v-if="canEdit"
            class="builder-draggable-field__drag"
          >
            <Icon name="drag" />
          </div>
          <SideDropdownMenu
            :menu="canEdit && [
              {
                text: 'Delete',
                action: () => $store.dispatch('builderRemoveSectionItem', { sectionId: section.id, id: item.id }),
              }
            ]"
            appearance="middle"
            @toggle="open = $event"
          >
            <div class="addon-checklist__item">
              <Checkbox
                appearance="medium"
                :checked="!!parseInt(item.value)"
                :can-edit="canEdit"
                @input="changeItemValue(item.id, $event)"
              />
              <BaseInput
                :can-edit="canEdit"
                :value="item.name"
                placeholder="Name..."
                class="addon-checklist__name base-input-2"
                multiline
                @input="changeItemName(item.id, $event)"
              />
            </div>
          </SideDropdownMenu>
        </div>
      </div>
    </SortableAnimatedList>
    <div
      v-if="canEdit"
      class="builder-new-input"
      :class="{ 'builder-new-input--active': isNewInputActive }"
    >
      <div class="enter-input">
        <Checkbox
          appearance="medium"
          :checked="newItemValue"
          @input="changeNewItemValue"
        />
        <BaseInput
          class="base-input-2"
          :value="newItemName"
          @input="newItemName = $event"
          :can-edit="canEdit"
          placeholder="Add new..."
          multiline
          @keydown.prevent.enter
          @keyup.prevent.enter="createItem"
          @focus="onNewInputFocus"
          @blur="onNewInputBlur"
        />
        <div
          v-if="newItemName"
          class="enter-input-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { byPosition } from 'helpers/sort';

export default {
  name: 'ProjectMethodCustom',
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      newItemName: '',
      newInputFocus: false,
      newItemValue: 0,
    };
  },
  computed: {
    draggableOptions() {
      return {
      //  disabled: this.draggingDisabled,
        handle: '.builder-draggable-field__drag',
        ghostClass: 'drag-ghost',
        fallbackClass: 'drag-fallback',
        animation: 150,
        direction: 'vertical',
        fallbackOnBody: true,
        forceFallback: true,
        touchStartThreshold: 3,
        fallbackTolerance: 2,
        delay: 200,
        delayOnTouchOnly: true,
        emptyInsertThreshold: 5,
        group: {
          name: 'builder-checlist',
          pull: true,
        },
      };
    },
    fields() {
      return byPosition(this.section.customItemFields);
    },
    isNewInputActive() {
      return this.newInputFocus || this.newItemName;
    },
  },
  created() {
  },
  methods: {
    onStart() {

    },
    onEnd(value) {
      var { newIndex } = value;
      const transport = value.to.getAttribute('data-transport');
      const id = value.item.dataset.id;

      this.$store.dispatch('builderMoveSectionItem', {
        sectionId: this.section.id,
        id,
        newSectionId: transport,
        newIndex,
      });
    },
    createItem() {
      if (!this.newItemName.trim()) {
        this.newItemName = '';
        return;
      }
      this.$store.dispatch('builderAddSectionItem', {
        id: this.section.id,
        data: {
          name: this.newItemName,
          value: this.newItemValue ? '1' : '',
        },
      });

      this.newItemName = '';
    },
    changeItemName(id, name) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: { name } });
    },
    changeItemValue(id, value) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: { value: value ? '1' : '' } });
    },
    onNewInputFocus() {
      this.newInputFocus = true;
    },
    onNewInputBlur() {
      this.newInputFocus = false;
    },
    changeNewItemValue(value) {
      this.newItemValue = value ? 1 : 0;
    },
    isEmpty(data) {
      return !(data.customItemFields && data.customItemFields.length);
    },
  },
};
</script>
