<template>
  <div class="container">
    <div :class="classes">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseContainer',
  props: {
    withPadding: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'small-wrapper': this.small,
        'base-container--with-padding': this.withPadding,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-container {
  &--with-padding {
    padding-bottom: 400px;
  }
}
</style>
