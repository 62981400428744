<template>
  <div class="onb-step">
    <div class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          Would you like to change the logo?
        </h2>
        <p class="onb-subtitle">
          Drag your image into the box
        </p>
      </div>
      <BaseUploader
        :brand-name="organizationName"
        class="onb-logo-uploader"
        theme="logo"
        :can-edit="true"
        @change="imageChange"
        @remove="imageRemove"
      />
    </div>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';
import { analyticsLogEvent } from 'utils/analytics';

export default {
  name: 'WorkspaceLogo',
  props: {
    data: Object,
  },
  computed: {
    organizationType() {
      return this.data.organizationType || 'product';
    },
    organizationName() {
      return this.data.organizationName;
    },
  },
  created() {
    this.updateConfig();
  },
  mounted() {
    document.addEventListener('keydown', this.keyDown);
  },
  unmounted() {
    document.removeEventListener('keydown', this.keyDown);
  },
  methods: {
    keyDown(e) {
      if (e.key === 'Enter') {
        this.buttonAction(this.organizationType);
      }
    },
    getConfig() {
      return {
        progress: 3/5,
        avatar: false,
        buttons: [
          {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Continue',
          },
          {
            appearance: 'light',
            id: 'secondary',
            text: 'Back',
          },
        ],
      };
    },
    buttonAction(id) {
      if (id === 'secondary') {
        this.$emit('redirect', {
          route: ROUTES.WORKSPACE_ONBOARDING,
          data: this.data,
        })

        analyticsLogEvent('create_workspace_back_to_name');
      } else {
        this.$emit('redirect', {
          route: ROUTES.WORKSPACE_ONBOARDING_READY,
          data: {
            ...this.data,
            image: this.image,
          },
        })
        analyticsLogEvent('create_workspace_confirm_logo');
      }
    },
    imageChange(img) {
      this.image = img;
      analyticsLogEvent('create_workspace_upload_logo');
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
  },
};
</script>
