import PASSIVE from 'constants/passive-events';
import debounce from 'lodash/debounce';

// triggers event when click outside of element occured
export default {
  bind(element, binding, vnode) {
    element.classList.add('scroll-inspector');

    element.onScrollHandler = debounce(() => {
      const target = document.querySelector('#' + binding.value.target);

      if (target && target.getBoundingClientRect().top <= 10) {
        document.querySelectorAll('.scroll-inspector').forEach((e) => e.classList.remove('is-active'));
        element.classList.add('is-active');
      } else {
        element.classList.remove('is-active');
      }
    });

    document.addEventListener('scroll', element.onScrollHandler, PASSIVE);
  },

  unbind(element) {
    element.classList.remove('scroll-inspector');
    document.removeEventListener('scroll', element.onScrollHandler, PASSIVE);
  },
};
