const model = {
  id: '',
  name: '',
  timestamp: -1,
  endsAt: '',
  shortcut: '',
  guest: false,

  lastModified: -1,
  members: [],

  methodsCount: 0,
  finishedMethodsCount: 0,

  accessible: true,
};

export const DEFAULT_NAME = 'Project name';

export const parser = (project) => {
  const result = {
    ...model,
    ...project,

    timestamp: new Date(project.endsAt).getTime(),
    lastModified: new Date(project.updatedAt).getTime(),
  };

  return result;
};

export default model;
