<template>
  <div
    v-if="isVisible"
    class="library-section"
    :data-id="section.id"
    :class="{
      'library-section--open': isOpen,
      'library-section--dragoutside': dragging && !draginside,
      'library-section--dragging': dragging,
      'library-section--search': search,
      'library-section--editting': edittingName,
    }"
  >
    <SortableDrop
      group="library"
      :disabled="isOpen"
      :can-drag-in="canDragIn"
      class="library-section__header"
      @dragin="onDragIn"
      @click="toggle"
    >
      <div class="library-section__drag sortable-handler">
        <Icon name="drag" />
      </div>
      <TitleInput
        ref="title"
        :can-edit="false"
        appearance="library-section"
        :value="section.name"
        :close-on-enter="true"
        :menu="!search && [
          {
            text: 'Edit section name',
            action: () => edit(),
          },
          {
            text: 'Delete section',
            action: () => remove(),
          },
        ]"
        menu-tooltip="Open menu"
        @change="onTitleChange"
        @blur="onTitleBlur"
      />
      <BaseTooltip
        :text="isOpen ? 'Collapse section' : 'Expand section'"
        placement="top"
      >
        <div
          v-if="!search"
          class="dropdown-counter"
          :class="{
            'dropdown-counter--active': isOpen,
          }"
        >
          {{ section.items.length }}
        </div>
      </BaseTooltip>
    </SortableDrop>
    <ContentTransition :init-hidden="!(isOpen || search)">
      <div
        v-if="isOpen || search"
        class="library-section__body"
      >
        <Sortable
          group="library"
          handler-class=""
          :can-drag-to="['method']"
          :data="items"
          event-handler-class="library-section"
          :placeholder-height="() => 44"
          :can-drag-in="canDragIn"
          @dragin="onDragIn"
          @dragend="onDragEnd"
        >
          <div
            v-for="item of items"
            :key="$store.getters.idJoin(item.id)"
            :data-id="item.id"
            :data-page-id="page.id"
            :data="item"
            @click="onItemClick(item)"
          >
            <div class="library-item">
              <div class="library-item__drag sortable-handler">
                <Icon name="drag" />
              </div>
              <Component
                :is="getItemComponent(item)"
                :data="item"
                :props="item"
                :search="search"
                @action="onAction($event, item)"
                @change="onItemChange"
              />
            </div>
          </div>
        </Sortable>
        <!-- <NewItem
          v-if="!search"
          :data="newData"
          @change="newItemChange"
          @submit="addNewItem"
        /> -->
      </div>
    </ContentTransition>
  </div>
</template>

<script>
import { byPosition } from 'helpers/sort';
import { xsMax } from 'helpers/ui';
import { mapGetters } from 'vuex';

import NewItem from './items/new.vue';
import Question from './items/question.vue';

const itemComponentsMap = {
  question: Question,
};

export default {
  name: 'LibrarySection',
  components: {
    NewItem,
  },
  props: {
    page: Object,
    section: Object,
    search: String,
  },
  data() {
    return {
      isOpen: false,
      draginside: true,
      dragging: false,
      isMobile: false,
      edittingName: false,
      isDraggingOutside: false,
      newData: {
        color: '',
        type: 'custom_5',
        content: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'isCardDragging',
    ]),
    items() {
      return (byPosition(this.section.items) || []).filter((i) => !this.search || this.shouldDisplayItem(i));
    },
    draggableOptions() {
      return {
        disabled: this.isMobile,
        ghostClass: 'drag-ghost',
        fallbackClass: 'drag-fallback',
        animation: 150,
        direction: 'vertical',
        fallbackOnBody: true,
        forceFallback: true,
        handle: null,
        touchStartThreshold: 3,
        fallbackTolerance: 2,
        delay: 200,
        delayOnTouchOnly: true,
        emptyInsertThreshold: 5,
        group: {
          name: 'builder-sections',
          pull: 'clone',
        },
      };
    },
    transitionDisabled() {
      return this.isCardDragging || this.isDraggingOutside;
    },
    isVisible() {
      return !this.search || this.section.items.filter((i) => {
        return this.shouldDisplayItem(i);
      }).length;
    },
  },
  watch: {
  },
  created() {
    this.isOpen = !!this.section.opened;
    this.$store.dispatch('changeLibraryLocalSection', { id: this.section.id, data: { opened: false } });
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = xsMax();
    },
    toggle() {
      if (this.edittingName) return;
      this.isOpen = !this.isOpen;
    },
    canDragIn({ dragData, group }) {
      if (group === 'method') {
        if (dragData) {
          return dragData.filter((i) => !['paragraph', 'image'].includes(i.type)).length;
        }

        return false;
      }

      return true;
    },
    async onDragIn({ dragData, index, group }) {
      if (group === 'method') {
        if (dragData) {
          await this.$store.dispatch('addLibraryMultipleItems', {
            sectionId: this.section.id,
            pageId: this.page.id,
            position: index,
            items: dragData,
          });
        }
      }

      if (group === 'library') {
        const item = dragData[0];

        if (item) {
          await this.$store.dispatch('moveLibraryItem', {
            sectionId: this.section.id,
            pageId: this.page.id,
            id: item.id,
            index,
          });
        }
      }
    },
    async onDragEnd({ index, dragData }) {
      const item = dragData[0];

      await this.$store.dispatch('moveLibraryItem', {
        sectionId: item.sectionId,
        pageId: this.page.id,
        id: item.id,
        index,
      });

      this.$store.dispatch('uiSetCardDragging', false);
    },
    onItemStartMove() {
      this.dragging = true;
      this.draginside = true;
      this.$store.dispatch('uiSetCardDragging', true);
    },
    onDragClone(e) {
      e.clone.classList.add('drag-source');
    },
    onDragMove(e) {
      if (e.to.classList.contains('library-section__items-list')) {
        this.draginside = true;
      } else {
        this.draginside = false;
      }
    },
    getItemComponent(item) {
      return itemComponentsMap[item.type] || Question;
    },
    shouldDisplayItem(item) {
      const component = this.getItemComponent(item);
      return component.methods.getSearchVisible(this.search, item);
    },
    newItemChange(prop, value) {
      this.newData = { ...this.newData, [prop]: value };
    },
    clearNewInput() {
      this.newData = { ...this.newData, content: '' };
    },
    async addNewItem(data = {}) {
      data.position = this.items.length;
      await this.$store.dispatch('addLibraryItem', { sectionId: this.section.id, pageId: this.page.id, data, showNotification: false });
    },
    async onItemClick(item) {
      if (this.isMobile) {
        this.$store.dispatch('promptShow', {
          text: item.name,
          menu: [
            {
              text: 'Add to method',
              action: () => {
                this.$store.dispatch('libraryCreateItem', { item });
                this.$store.dispatch('promptsHide');
              },
            },
            {
              text: 'Remove',
              action: () => {
                this.onAction({ action: 'remove-item' }, item);
                this.$store.dispatch('promptsHide');
              },
            },
          ],
        });
      }
    },
    async remove() {
      await this.$store.dispatch('removeLibrarySection', { pageId: this.page.id, id: this.section.id } );
    },
    async onTitleChange(name) {
      await this.$store.dispatch('changeLibrarySection', {
        id: this.section.id,
        pageId: this.page.id,
        data: {
          name,
        },
      } );
    },
    async onAction({ action, data }, item) {
      if (action === 'remove-item') {
        await this.$store.dispatch('removeLibraryItem', { sectionId: this.section.id, pageId: this.page.id, id: item.id } );
      }
      if (action === 'change-item') {
        await this.$store.dispatch('changeLibraryItem', { id: item.id, sectionId: this.section.id, pageId: this.page.id, data } );
      }
    },

    edit() {
      this.$refs.title.edit();
      this.edittingName = true;
    },
    onTitleBlur() {
      this.edittingName = false;
    },
  },
};
</script>
