import Vue from 'vue';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

export default function registerBaseComponents(app) {
  const requireComponent = require.context(
    // The relative path of the components folder
    './components/common',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /^([^\.]).*base-.*\.vue$/
  );

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(camelCase(componentConfig.default.name)) || upperFirst(
      camelCase(
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
      )
    );

    app.component(componentName, componentConfig.default || componentConfig);
  });
}
