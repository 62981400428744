<template>
  <div v-if="insights.length">
    <ReportWrap
      name="Insights"
      key="cover"
      :hidden="isHidden('cover')"
      :can-edit="canEdit"
      :class="{'hide-print': isHidden('cover')}"
      @change-hidden="changeHidden('cover', $event)"
      v-if="insightsCols && insightsCols.length"
    >
      <div class="report-insight">
        <div class="report-insight__body report-insight__body--full">
          <TitleInput
            appearance="report-title clear"
            value="Insights"
            :can-edit="false"
          />
          <div class="counted-column-list">
            <div
              v-for="(insightsCol, index) of insightsCols"
              :key="index"
              class="counted-column-list__col"
            >
              <div
                v-for="insight of insightsCol"
                :key="insight.id"
                class="counted-column-list__item"
              >
                {{ insight.name }}
              </div>
          </div>
        </div>
        </div>
      </div>
    </ReportWrap>
    <div v-if="!isHidden('cover')">
      <ReportWrap
        v-for="(insight, nth) in insightsFiltered"
        :key="insight.id"
        :name="insight.name"
        :class="{'hide-print': isHidden(insight.id)}"
        :hidden="isHidden(insight.id)"
        :can-edit="canEdit"
        @change-hidden="changeHidden(insight.id, $event)"
      >
        <ReportInsightDetails
          :id="insight.id"
          :nth="nth + 1"
          :can-edit="canEdit"
        />
      </ReportWrap>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ReportInsightDetails from './report-insight-details';
import ReportWrap from './report-wrap';

export default {
  name: 'ProjectMethodCustom',
  components: {
    ReportWrap,
    ReportInsightDetails,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  computed: {
    ...mapGetters(['insights', 'isShared']),
    insightsFiltered() {
      return this.insights.filter((i) => {
        const id = i.id.replace(/[\-\_]/gim, '');
        return !i.archived && (!this.isShared || !(this.section.options[id] && this.section.options[id].hidden))
      })
    },
    insightsCols() {
      const { insightsFiltered } = this;

      const insights = insightsFiltered.filter((i) => {
        const id = i.id.replace(/[\-\_]/gim, '');
        return !(this.section.options[id] && this.section.options[id].hidden);
      })

      const chunkSize = Math.ceil(this.insightsFiltered.length / 3);
      const cols = [];

      for (let i = 0; i < insights.length; i += chunkSize) {
          const chunk = insights.slice(i, i + chunkSize);
          cols.push(chunk);
      }

      return cols;
    },
  },
  methods: {
    isHidden(id) {
      id = id.replace(/[\-\_]/gim, '');
      return (this.section.options[id] && this.section.options[id].hidden);
    },
    changeHidden(id, hidden) {
      id = id.replace(/[\-\_]/gim, '');

      if (this.section) {
        this.$store.dispatch('builderUpdateSectionOptions', {
          id: this.section.id,
          options: {
            [id]: {
              hidden: hidden,
            },
          },
        })
      }
    },
  },
};
</script>