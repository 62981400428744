<template>
  <li class="table-item">
    <slot />
  </li>
</template>
<script>
export default {
  name: 'TableItem',
  props: {
  },
};
</script>
