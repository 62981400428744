<template>
  <div
    v-if="hasUser"
    class="sidebar-page"
  >
    <Upgrade
      title="Upgrade to invite team member"
      description="invite team members"
    />
    <div
      v-if="error"
      class="team__error small-wrapper"
    >
      <span
        v-if="isRegistered"
        class="g__error js-show"
      >
        An error occured! You may not have permissions needed to view this project team.
      </span>
      <button
        type="button"
        class="team__add-mobile btn"
        @click="navigateProject"
      >
        Back to project methods!
      </button>
    </div>

    <div
      v-if="isRegistered"
      class="workspace-container"
    >
      <TitleInput
        appearance="project-title"
        :can-edit="false"
        value="Access"
      />
      <div class="grid" v-if="false">
        <div class="grid-col grid-col--6">
          <div
            class="card-button"
            :class="{'card-button--checked': !isProjectPrivate}"
            @click="changePrivate(false)"
          >
            <div class="card-button__ico">
              <Icon name="logo" />
            </div>
            <div class="card-button__title">
              Workspace
            </div>
            <div class="card-button__desc">
              Share with  workspace members
            </div>
          </div>
        </div>
        <div class="grid-col grid-col--6">
          <div
            class="card-button"
            :class="{'card-button--checked': isProjectPrivate}"
            @click="changePrivate(true)"
          >
            <div class="card-button__ico">
              <UserAvatar
                mode="email"
                :name="user.name"
              />
            </div>
            <div class="card-button__title">
              Private
            </div>
            <div class="card-button__desc">
              Only visible to you
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="!isProjectPrivate">
          <headline>
            Workspace members with access
            <template v-slot:aside>
              Access
              <DropdownMenu
                appearance="light-box-md"
                dropdown-text="Custom"
                :menu="bulkMenu"
                v-if="false"
              />
            </template>
          </headline>
          <TeamList
            :can-remove="true"
            mode="enablable"
            :team="workspaceTeam"
            :members="projectTeam"
            @enable="changeEnabled"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Spinner from 'components/common/base-spinner';
import Upgrade from 'modals/upgrade';
import { UPGRADE } from 'constants/modals';
import { navigateProject } from 'helpers/router';
import { isMember, isOwner } from 'helpers/team';
import NotificationsService from 'services/notifications.service';
import TeamService from 'services/team.service';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectTeam',
  components: {
    Upgrade,
  },
  data: () => ({
    inviteMemberActive: false,
    editMember: null,
    error: false,
  }),
  computed: {
    ...mapGetters(['projectCurrent', 'projectCurrentId', 'membersLimitReached', 'user', 'isProjectPrivate', 'workspaceTeam', 'projectTeam']),
    isRegistered() {
      return this.$store.getters.isRegistered;
    },
    hasUser() {
      return this.$store.getters.hasUser;
    },
    users() {
      return this.workspaceTeam;
    },
    currentUser() {
      return this.users.find(({ id }) => id === this.$store.getters.user.id);
    },
    isCurrentUserOwner() {
      return this.currentUser && isOwner(this.currentUser.role);
    },
    founder() {
      return this.workspaceTeam.find(({ permission }) => permission && permission.rights === 'owner') || null;
    },
    founderId() {
      if (!this.founder) return null;

      return this.founder.id;
    },
    editMemberActive() {
      return this.editMember !== null;
    },
    overlayClasses() {
      return {
        'js-active': this.editMemberActive,
      };
    },
    hasPermissions() {
      return this.currentUser && (isMember(this.currentUser.role) || isOwner(this.currentUser.role));
    },
    bulkMenu() {
      return [{
        icon: 'eye',
        text: 'Enabled',
        desc: 'Can view project',
        appearance: 'box-icon',
      }, {
        icon: 'eye-disabled',
        text: 'Disabled',
        desc: 'Can’t see project',
        appearance: 'box-icon',
      }];
    },
  },
  watch: {
    isRegistered() {

    },
  },
  created() {

  },
  methods: {
    async fetchData() {
 
    },
    async onInvite(email) {
      if (this.membersLimitReached) {
        this.$store.dispatch('modalShow', UPGRADE);
        return;
      }

      await this.$store.dispatch('teamInvite', { email });

      const message = `${ email } was added to team`;
      NotificationsService.showInfo(message);
    },
    onRemove(id) {
      this.$store.dispatch('teamRemove', id);
    },
    async onTransfer(id) {
      try {
        await this.$store.dispatch('teamOwner', id);

        const message = `${ this.userEmailById(id) } is now king beyond the wall`;
        NotificationsService.showInfo(message);
      } catch (error) {
        throw error;
      }
    },
    async onRemind(id) {
      await TeamService.sendReminder(id);

      const message = `Reminder was sent to ${ this.userEmailById(id) }`;
      NotificationsService.showInfo(message);
    },
    async changeEnabled({ member, enabled }) {
      await this.$store.dispatch('teamEnabledChange', {
        projectId: this.projectCurrentId,
        member,
        enabled,
      });
    },

    changePrivate(priv) {
      this.$store.dispatch('projectChange', { data: { public: !priv } });
    },

    isFounder(user = null) {
      if (user === null) {
        return false;
      }

      const { id = null } = user;
      return id === this.founderId;
    },
    isInvited({ registered }) {
      return !registered;
    },
    isCurrentUser(id) {
      return this.currentUser.id === id;
    },
    checkIfIsInTeam(inviteEmail) {
      return this.sortedUsers.some(({ email = '' }) => email.toLowerCase() === inviteEmail.toLowerCase());
    },
    toggleInviteMember(active) {
      this.inviteMemberActive = active;
    },
    toggleEditMember(member = null) {
      this.editMember = member;
    },
    userById(userId) {
      return this.users.find(({ id }) => id === userId) || null;
    },
    userEmailById(userId) {
      const user = this.userById(userId);
      if (!user || !user.email) return '';

      return user.email;
    },


    navigateProject() {
      navigateProject(this.$store.getters.projectCurrentId);
    },
  },
};
</script>
