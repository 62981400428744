import * as ApiService from 'services/api.service';

class MailLoginService {
  async checkIfIsUser({ email }) {
    try {
      const data = await ApiService.get('api/auth/users', {
        email,
      });

      if (!data.email) {
        return null;
      }

      return {
        provider: 'email',
        ...data,
      };
    } catch (error) {
      return null;
    }
  }

  async createUser({ email, password }) {
    await ApiService.post('api/auth/users', {
      email,
      password,
      passwordConfirmation: password,
    });

    const response = await this.login({ email, password });
    return response;
  }

  async updateUser({ email, password }) {
    await ApiService.put('api/auth/users', {
      email,
      password,
      passwordConfirmation: password,
    });

    const response = await this.login({ email, password });
    return response;
  }

  async login({ email, password }) {
    const provider = 'email';

    const { accessToken } = await ApiService.post('api/auth/tokens', {
      provider,
      email,
      password,
    });

    return {
      provider,
      accessToken,
    };
  }

  sendMagicLink({ email }) {
    return ApiService.put('api/auth/magic_link', {
      email,
    });
  }

  resetPasswordRequest({ email }) {
    return ApiService.put('api/auth/password', {
      email,
    });
  }

  changePassword({ password, passwordConfirmation, resetPasswordToken, newUser = false }) {
    if (newUser) {
      return ApiService.post('api/auth/password/create_new', {
        password,
        passwordConfirmation,
        resetPasswordToken,
        provider: 'email',
      });
    }

    return ApiService.post('api/auth/password', {
      password,
      passwordConfirmation,
      resetPasswordToken,
      provider: 'email',
    });
  }
}

export default new MailLoginService();
