<template>
  <div>
    <div class="builder-section__aside">
      <div class="filter">
        <span class="filter__name">Source:</span>
        <DropdownMenu
          ref="selector"
          class="filter__value"
          :menu="sourceMenu"
        >
          <span
            v-if="sourceItem"
            class="filter__value-text"
          >{{ getMethodItemName(sourceItem) }}</span>
          <span
            v-else
            class="filter__value-text"
          >Select source</span>
        </DropdownMenu>
      </div>
    </div>
    <div
      class="avatars-chart"
      @mousedown="onMouseDown"
    >
      <div class="avatars-chart__body">
        <div class="avatars-chart__canvas">
          <div class="avatars-chart__axis" />
          <transition name="avatars-chart-plane">
            <div
              v-if="sourceItem && sourcePages.length"
              :key="sourceItem.id"
              ref="content"
              class="avatars-chart__plane"
            >
              <transition-group name="fade">
                <div
                  v-for="(page, index) of sourcePages"
                  :key="page.id"
                  class="avatars-chart__avatar"
                  :data-id="page.id"
                  :style="{
                    left: ((positions[page.id] && positions[page.id].x) || (0.5 + index * 0.02)) * 100 + '%',
                    top: ((positions[page.id] && positions[page.id].y) || (0.5 + index * 0.02)) * 100 + '%',
                    zIndex: zIndexes[page.id],
                  }"
                >
                  <BaseTooltip
                    :text="page.name"
                    placement="top"
                    :visible="page.id === dragedElementId ? true : null"
                  >
                    <PageAvatar
                      v-if="page.page"
                      :page="page.page"
                    />
                  </BaseTooltip>
                </div>
              </transition-group>
            </div>
            <div
              v-else-if="sourceItem"
              key="no-items"
              class="avatars-chart__info"
            >
              Items from&nbsp;<strong>{{ getMethodItemName(sourceItem) }}</strong>&nbsp;will appear here.
            </div>
            <div
              v-else-if="sourcesList.length"
              key="no-source"
              class="avatars-chart__info"
            >
              Please &nbsp;<strong @click="openSelector">select source</strong>
            </div>
            <div
              v-else
              key="no-sources"
              class="avatars-chart__info"
            >
              <div>
                <p>Add &nbsp;<strong>Pages</strong>&nbsp; component to select source.</p>
                <button
                  class="btn btn--primary"
                  @click="addComponent"
                >
                  Add
                </button>
              </div>
            </div>
          </transition>
        </div>
        <div class="avatars-chart__y-label">
          <BaseInput
            :value="yMin"
            @change="yMin = $event"
            class="avatars-chart__label-input avatars-chart__label-input--edge"
            placeholder="Min"
          />
          <BaseInput
            :value="yLabel"
            @change="yLabel = $event"
            class="avatars-chart__label-input avatars-chart__label-input--main"
            placeholder="Type name here..."
          />
          <BaseInput
            :value="yMax"
            @change="yMax = $event"
            class="avatars-chart__label-input avatars-chart__label-input--edge"
            placeholder="Max"
          />
        </div>
      </div>
      <div class="avatars-chart__x-label">
        <BaseInput
          :value="xMin"
          @change="xMin = $event"
          class="avatars-chart__label-input avatars-chart__label-input--edge"
          placeholder="Min"
        />
        <BaseInput
          :value="xLabel"
          @change="xLabel = $event"
          class="avatars-chart__label-input avatars-chart__label-input--main"
          placeholder="Type name here..."
        />
        <BaseInput
          :value="xMax"
          @change="xMax = $event"
          class="avatars-chart__label-input avatars-chart__label-input--edge"
          placeholder="Max"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getMethodItemName } from 'helpers/method-items';

const SIZE = 50;
const MAX_X = 1;
const MAX_Y = 1;

const gen = (function*() {
  var index = 100;
  while (true) yield index++;
})();

export default {
  name: 'ProjectMethodCustom',
  components: {
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      source: '',
      dragedElementId: '',
      zIndexes: () => [],
      positions: {},
    };
  },
  watch: {
    'section.value'(value) {
      this.positions = JSON.parse(value) || {};
    },
    'section.content'(value) {
      this.source = value;
    },
  },
  created() {
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  },
  mounted() {
    this.source = this.section.content;

    if (this.sourcesList.length && !this.source) {
      this.source = this.sourcesList[0].id;
    }

    this.positions = JSON.parse(this.section.value) || {};
  },
  unmounted() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  },
  computed: {
    ...Object.fromEntries([
      ['xMin', 'Low'],
      ['xLabel', 'Price'],
      ['xMax', 'High'],
      ['yMin', 'Low'], 
      ['yLabel', 'Quality'],
      ['yMax', 'High'],
    ].map((prop) => [prop[0], {
      get() {
        return this.section.options && this.section.options[prop[0]] || prop[1];
      },
      set(value) {
        this.$store.dispatch('methodSectionUpdate', {
          id: this.section.id,
          data: {
            options:{
              ...this.section.options,
              [prop[0]]: value,
            },
          },
        });
      },
    }])),
    pages() {
      return this.section.customItemFields;
    },
    sourceItem() {
      return this.$store.getters.sectionById(this.source);
    },
    sourcePages() {
      return this.sourceItem && this.sourceItem.customItemFields || [];
    },
    sourcesList() {
      return this.$store.getters.getSectionsWithType(this.section.designMethodId, 'pages');
    },
    sourceMenu() {
      return [...this.sourcesList.map((source) => {
        return {
          methodItem: source,
          selected: source.id === this.source,
          action: () => {
            this.source = source.id;
            this.$store.dispatch('methodSectionUpdate', {
              id: this.section.id,
              data: {
                content: source.id,
              },
            });
          },
        }
      }), {
        methodItem: null,
        action: () => {
          this.source = null;
          this.$store.dispatch('methodSectionUpdate', {
            id: this.section.id,
            data: {
              content: '',
            },
          });
        },
      }];
    },
  },
  methods: {
    getMethodItemName,
    onMouseUp() {
      if (!this.dragedElement) return;
      this.dragedElement.style.cursor = 'grab';
      document.body.style.cursor = 'unset';

      if (this.moved) {
        this.$store.dispatch('methodSectionUpdate', {
          id: this.section.id,
          data: {
            value: JSON.stringify(this.positions),
          },
        });
      }

      this.moved = false;
      this.dragedElement = null;
      this.dragedElementId = null;
    },
    onMouseDown(e) {
    //  e.preventDefault();
      if (e.button != 0 || !this.canEdit) return;
      this.dragedElement = e.target.closest('.avatars-chart__avatar');
      if (!this.dragedElement) return;
      this.dragedElement.style.cursor = 'grabbing';
      document.body.style.cursor = 'grabbing';
      const id = this.dragedElement.getAttribute('data-id');
      this.dragedElementId = id;

      this.zIndexes = Object.fromEntries(
        this.sourcePages.map((competitor) => {
          return [
            competitor.id,
            competitor.id === id
              ? gen.next().value
              : this.zIndexes[competitor.id] || 100,
          ];
        })
      );
      const draggedRect = this.dragedElement.getBoundingClientRect();
      this.curX = draggedRect.left - e.clientX + SIZE / 2;
      this.curY = draggedRect.top - e.clientY + SIZE / 2;
    },
    onMouseMove(e) {
      if (!this.dragedElement) return;
      this.moved = true;
      const rect = this.$refs.content.getBoundingClientRect();
      let x = (e.clientX - rect.left + this.curX) / this.$refs.content.offsetWidth;
      x = x < 0 ? 0 : x;
      x = x > MAX_X ? MAX_X : x;

      let y = (e.clientY - rect.top + this.curY) / this.$refs.content.offsetHeight;
      y = y < 0 ? 0 : y;
      y = y > MAX_Y ? MAX_Y : y;

      this.dragedElement.style.top = `${y * 100}%`;
      this.dragedElement.style.left = `${x * 100}%`;

      this.positions[this.dragedElementId] = {x, y};
    },
    openSelector() {
      this.$refs.selector.open();
    },
    addComponent() {
      this.$store.dispatch('builderCreateSection', {
        data: {
          type: 'pages',
        },
        index: this.section.position,
        methodId: this.section.designMethodId,
      });
    },
  },
};
</script>
