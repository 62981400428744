/* global __DEV__ */

import * as ApiService from 'services/api.service';

class SubscriptionService {
  getPlans({ workspaceId }) {
    return ApiService.get('api/subscription_plans', { workspace_id: workspaceId });
  }

  activateTrial() {
    return ApiService.post('api/trial_subscription', {});
  }

  getAvailableProjectsCount({ workspaceId }) {
    return ApiService.get('api/users/available_projects', { workspaceId });
  }

  getInformation({ workspaceId }) {
    return ApiService.get('api/users/subscription_informations', {
      workspace_id: workspaceId,
    });
  }

  async getCurrentPlan({ workspaceId }) {
    const { subscriptionPlanKind } = await ApiService.get(
      'api/users/current_subscription_plan',
      {
        workspaceId,
      }
    );
    return subscriptionPlanKind;
  }

  async isSubscriptionActive(workspaceId) {
    const { activeSubscription } = await ApiService.get(
      'api/users/active_subscription',
      { workspaceId }
    );
    return activeSubscription;
  }

  cancel({ reason, workspaceId }) {
    return ApiService.put('api/cancel_subscription', { reason, workspaceId });
  }

  changePlan({ plan, workspaceId }) {
    return ApiService.put('api/users/change_subscription_plan', {
      subscriptionPlanId: plan,
      workspaceId,
    });
  }
}

const service = new SubscriptionService();
if (__DEV__) {
  window.__SUBSCRIPTIONS = service;
}

export default service;
