import { ANSWER_MODAL } from 'constants/modals';
import * as TYPES from 'constants/mutations';
import CardsService from 'services/cards.service';
import { merge } from 'utils/reactive';

const moduleState = {
  answer: null,
};

const getters = {
  modalAnswer: (state) => state.answer,
};

const actions = {
  openCardModal({ commit, dispatch }, answer) {
    commit(TYPES.SET_MODAL_ANSWER, answer);
    dispatch('modalShow', ANSWER_MODAL);
  },
  async cardModalUpdate({ commit, dispatch, getters }, { id, projectId, data }) {
    commit(TYPES.UPDATE_MODAL_ANSWER, { ...data, updatedAt: (new Date()).toISOString() });
    if (!projectId) {
      projectId = getters.projectCurrentId;
    }
    await CardsService.update(getters.workspaceCurrentId, projectId, id, data);
  },
};

const mutations = {
  [TYPES.SET_MODAL_ANSWER](state, answer) {
    state.answer = answer;
  },
  [TYPES.UPDATE_MODAL_ANSWER](state, answer) {
    merge(state.answer, answer);
  },
};

export default {
  state: moduleState,
  mutations,
  actions,
  getters,
};
