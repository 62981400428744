/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import { TRIAL, PERSONAL_NEW } from 'constants/user';
import getFromObject from 'lodash/get';
import SubscriptionService from 'services/subscription.service';
import { analyticsSetUserProp } from 'utils/analytics';
import { merge } from 'utils/reactive';

const moduleState = {
  workspaces: [],
};

const getters = {
  workspaceSubscription: (state, getters) => {
    return state.workspaces.find((w) => w.id === getters.workspaceCurrentId);
  },
  isSubscriptionFetching: (state, getters) => !getters.workspaceSubscription,
  isSubscriptionActive: (state, getters) => getters.workspaceSubscription && getters.workspaceSubscription.isActive,

  userPlan: (state, getters) => getters.workspaceSubscription && getters.workspaceSubscription.plan,
  userPlanPeriod: (state, getters) => {
    const data = getters.workspaceSubscription;
    
    if (!data || !data.info) return null;
    const { subscriptionPlanPeriod } = data.info;

    if (subscriptionPlanPeriod === 7) {
      return null;
    }

    if (subscriptionPlanPeriod === 365) {
      return 'yearly';
    }
    return 'monthly';
  },
  isTrialUser: (state, getters) => getters.userPlan === 'inactive',

  userPlanInfo: (state, getters) => getters.workspaceSubscription && getters.workspaceSubscription.info,
  userCanCreateProject: (state, getters) =>
    getFromObject(getters.userPlanInfo, 'canCreateProject', false),
  userProjectsIsLimited: (state, getters) =>
    getFromObject(getters.userPlanInfo, 'isLimited', true),
  userProjectsLeft: (state, getters) => {
    if (!getters.userProjectsIsLimited) return Number.POSITIVE_INFINITY;

    return getFromObject(getters.userPlanInfo, 'projectsLeft', 0);
  },
  userProjectsLimit: (state, getters) => {
    if (!getters.userProjectsIsLimited) return 'unlimited';

    const limit = getFromObject(getters.userPlanInfo, 'projectsLeft', 0);
    return limit;
  },

  userSubscriptionDaysLeft: (state, getters) =>
    getFromObject(getters.userPlanInfo, 'subscriptionDaysLeft', 30),
  userSubscriptionActiveUntil: (state, getters) => {
    const data = getters.workspaceSubscription;
    return data && (data.active && data.active.activeUntil || data.info && data.info.nextSubscriptionStartAt);
  },
  userSubscriptionStatus: (state, getters) =>
    getFromObject(getters.userPlanInfo, 'braintreeStatus', null),
  isSubscriptionCanceled: (state, getters) =>
    getters.userSubscriptionStatus === 'canceled',

  hasPendingPayment: (state, getters) => getters.userPlanInfo && getters.userPlanInfo.paymentPending,
};

const actions = {
  loggedIn({ dispatch }) {
    dispatch('subscriptionFetch');
  },
  async subscriptionFetch({ commit, getters, dispatch }) {
    await dispatch('requireWorkspaces');

    const workspaceId = getters.workspaceCurrentId;
    const data = await SubscriptionService.getInformation({ workspaceId });

    if(data.subscriptionDaysLeft <= 0) {
      Object.assign(data, {
        activeSubscription: false,
        canCreateProject: false,
        isLimited: true,
        paymentPending: false,
        projectsLeft: 0,
        projectsLimit: 0,
        subscriptionDaysLeft: 0,
        subscriptionPlanKind: 'inactive',
      });
    }

    const {
      activeSubscription,
      subscriptionPlanKind,
      ...limitations
    } = data;

    commit(TYPES.SUBSCRIPTION_STATE, { workspaceId, data: activeSubscription });
    commit(TYPES.SUBSCRIPTION_PLAN, { workspaceId, data: subscriptionPlanKind});
    commit(TYPES.SUBSCRIPTION_LIMITATIONS, { workspaceId, data: limitations});

    const now = Date.now();
    const endDate = new Date(now + 3600000 * 24 * (limitations.subscriptionDaysLeft || 0)).toLocaleDateString('en-GB');
    analyticsSetUserProp('subscription_state', activeSubscription);
    analyticsSetUserProp('subscription_plan', subscriptionPlanKind);
    analyticsSetUserProp('subscription_end_date', endDate);
  },

  subscriptionCancelled({ dispatch, commit }) {
    dispatch('subscriptionFetch');
    commit(TYPES.SUBSCRIPTION_CANCEL);
    // TODO:
  },

  workspaceIdChanged({ dispatch }, id) {
    dispatch('subscriptionFetch');
  },
};

const getOrCreateWorkspace = (state, workspaceId) => {
  let workspace = state.workspaces.find((w) => w.id === workspaceId);

  if (!workspace) {
    workspace = { id: workspaceId };
    state.workspaces.push(workspace)
  }

  return workspace;
}

const mutations = {
  [TYPES.LOGGED_OUT](state) {
    state.workspaces = [];
  },

  [TYPES.SUBSCRIPTION_STATE](state, { workspaceId, data }) {
    let workspace = getOrCreateWorkspace(state, workspaceId);

    merge(workspace, {
      isActive: data,
      active: data,
    })
  },
  [TYPES.SUBSCRIPTION_PLAN](state, { workspaceId, data }) {
    let workspace = getOrCreateWorkspace(state, workspaceId);

    merge(workspace, {
      plan: data,
    })
  },
  [TYPES.SUBSCRIPTION_LIMITATIONS](state, { workspaceId, data }) {
    let workspace = getOrCreateWorkspace(state, workspaceId);

    merge(workspace, {
      info: data,
    })
  },

  [TYPES.SUBSCRIPTION_CANCEL](state) {
    state.plan = null;
    state.projectsLimitations = null;
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
