<template>
  <BaseModal
    :name="name"
    :can-be-closed="true"
    @data="data = $event"
  >
    <button
      v-if="canBeClosed"
      type="button"
      class="modal__close-x"
      @click="hide"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
      >
        <path d="M13 0L1 12M1 0l12 12" />
      </svg>
    </button>
    <div class="modal__body">
      <img
        src="/static/img/svg/illu-password.svg"
        alt=""
        class="reset-password-request-modal__img"
        width="165"
        heiht="136"
      >
      <p class="modal__title">
        Reset your password
      </p>
      <p class="modal__desc">
        We’ve just sent email to <b>{{ email }}</b> with link to reset your password.
      </p>
      <a
        :href="mailboxUrl"
        class="btn btn--block btn--md btn--corners btn--outline"
        target="_blank"
      >
        Open Inbox
      </a>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
export default {
  name: 'reset-password-request',
  components: {
    BaseModal,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    name() {
      return 'reset-password-request';
    },
    canBeClosed() {
      return true;
    },
    mailboxUrl() {
      return 'http://' + (this.email || 'gmail.com').replace(/[\s\S]+\@/gim, '');
    },
    email() {
      return this.data.email;
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('modalHide');
    },
  },
};
</script>
<style lang="scss" scoped>
.reset-password-request-modal {
  &__img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 168px;
    height: auto;
  }
}
</style>
