<template>
  <BaseModal
    class="modal__login is-active"
    :name="name"
    :can-be-closed="false"
  >
    <div class="theme__container">
      <a
        class="survey__theme"
        href="https://www.talebook.io"
      >
        powered by <span class="theme__talebook">talebook</span>
      </a>
    </div>
    <div class="modal__body">
      <img
        src="/static/img/svg/art-solutiondef.svg"
        alt=""
        class="modal__main-img"
      >

      <p class="modal__desc">
        Your greatness has been invited
        <span
          v-if="invitedBy"
        >by <b>{{ invitedBy.name }}</b> ({{ invitedBy.email }})</span>
        to complete {{ methodName }} in the project <b>{{ projectName }}</b>.
      </p>

      <p class="modal__input-title">
        Enter your name to start survey
      </p>
      <div class="modal__input-wrapper">
        <input
          ref="input"
          v-model="userName"
          type="text"
          class="modal__input"
          placeholder="Type your name here..."
          autofocus
          @keydown.enter="submit"
        >
      </div>
      <p
        v-if="error"
        class="modal__input-desc error"
        v-html="error"
      />

      <button
        type="button"
        class="btn modal__submit"
        @click="submit"
      >
        Continue
      </button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
import { SURVEY } from 'constants/modals';
import { TAG_COLORS } from 'constants/tag-colors';
import sample from 'lodash/sample';
import { mapGetters } from 'vuex';

export default {
  name: 'SurveyModal',
  components: {
    BaseModal,
  },
  data: () => ({
    error: null,
    userName: '',
  }),
  computed: {
    ...mapGetters({
      method: 'projectCurrentMethod',
      project: 'projectCurrent',
    }),

    modalActive() {
      return this.$store.getters.modalActive;
    },
    name() {
      return SURVEY;
    },
    invitedBy() {
      if (!this.project) return '';

      return this.project.owner;
    },
    methodName() {
      if (!this.method) return '';

      return this.method.name;
    },
    projectName() {
      if (!this.project) return '';

      return this.project.name;
    },

    isValid() {
      return this.userName.length > 2;
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        this.error = 'Name cannot be empty!';
        return;
      }

      const result = await this.$store.dispatch('tagAdd', {
        color: sample(TAG_COLORS),
        name: this.userName,
        createTester: true,
      });
      this.$store.dispatch('surveyTesterSet', result);
      this.$router.replace({ params: { tester_id: result.id } });
      this.$store.dispatch('projectSurveyMethodFetch');
      this.$store.dispatch('modalHide');
    },
    hide() {
      this.$store.dispatch('modalHide');
    },
  },
};
</script>

<style lang="scss" scoped>
.survey {
  &__theme {
    font-size: 16px;
    line-height: 1.5;
    color: #3b3b3b;
    margin: 0;
    text-decoration: none;
  }
}
.theme {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transform: translateY(-30px);
    width: 173px;
    height: 34px;
    border-radius: 3px;
    background-color: #f3f6f8;
  }
  &__talebook {
    font-weight: 600;
  }
}
@media only screen and (min-width: 479px) {
  .theme__container {
    display: none;
  }
}
</style>
