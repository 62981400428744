function prefix() {
  const element = document.createElement('fakeelement');
  const map = {
    animation: 'a',
    WebkitAnimation: 'webkitA',
  };

  // eslint-disable-next-line
  for (const t in map) {
    if (element.style[t] !== undefined) {
      return map[t];
    }
  }

  return map.animation;
}

export const TRANSITION_END = 'transitionend';
export const ANIMATION_END = `${prefix()}nimationend`;
export const ANIMATION_ITERATION = `${prefix()}nimationIteration`;

export const SM_MIN = 768;
export const AOS_ANIMATE_CLASS = 'aos-animate';
