import methodModel from 'models/method';
// import * as METHODS from 'constants/methods/data';

const model = {
  ...methodModel,
  id: '',
  shortcut: '',
  guest: null,

  dueDate: '',
  timestamp: 0,

  content: '',
  itemsCount: 0,

  phase: '',
};

export const parser = (method) => {
  const timestamp = method.dueDate
    ? new Date(method.dueDate).getTime()
    : Date.now();
  const result = {
    ...model,
    ...method,

    type: method.type || null,
    timestamp,
  };

  // delete result.phase;
  return result;
};

export default model;
