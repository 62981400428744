<template>
  <div class="addon-charts__item">
    <div class="addon-charts__input">
      <BaseInput
        class="base-input-2"
        :can-edit="canEdit"
        :value="item.name"
        placeholder="Name..."
        @input="changeItemName(item.id, $event)"
      />
    </div>
    <div class="addon-charts__slider">
      <RangeSlider
        v-if="canEdit"
        :value="item.value"
        @change="changeItemValue(item.id, $event)"
      />
      <div
        v-else
        class="addon-charts__slider-static"
      >
        <div :style="{width: `${item.value}%`, backgroundColor: item.color}" />
      </div>
    </div>
    <ColorPicker
      v-if="canEdit"
      :color="item.color || ''"
      class="addon-charts__more"
      :actions="[
        {
          name: 'Delete',
          callback: () => chartRemove(item.id),
        },
      ]"
      @change="colorChange(item.id, $event)"
      @toggle="open = $event"
    >
      <span class="addon-charts__more-icon">
        <Icon name="more-vertical" />
      </span>
    </ColorPicker>
  </div>
</template>

<script>
import ColorPicker from 'components/common/pickers/color';
import RangeSlider from '@vueform/slider'

export default {
  name: 'ProjectMethodCustom',
  components: {
    RangeSlider,
    ColorPicker,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      actualColor: null,
    };
  },
  computed: {
  },
  watch: {
    item: {
      deep: true,
      handler(next, prev) {
        if (next.color !== this.actualColor) {
          this.refreshColor(next.color);
        }
      },
    },
  },
  created() {
  },
  mounted() {
    this.refreshColor();
  },
  methods: {
    refreshColor(color = this.item.color) {
      const { item } = this;
      const element = document.querySelector(`div[data-id="${ item.id }"] .slider-connect`);

      if (!element) return;

      element.style.backgroundColor = color;
      this.actualColor = color;
    },
    colorChange(id, color) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: { color } });
      const element = document.querySelector(`div[data-id="${ id }"] .slider-connect`);

      if (!element) return;

      element.style.backgroundColor = color;
    },
    chartRemove(id) {
      this.$store.dispatch('builderRemoveSectionItem', { sectionId: this.section.id, id });
    },
    changeItemName(id, name) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: { name } });
    },
    changeItemValue(id, value) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: { value: value.toString() } });
    },
  },
};
</script>
