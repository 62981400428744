import { objectFrom, objectTo } from 'utils/camel-case';

export const deserialize = (data) => {
  if (!data) {
    return Promise.resolve({});
  }

  return Promise.resolve(objectTo(data));
};

export const serialize = (data = {}) => {
  if (data instanceof FormData) return data;

  const result = objectFrom(data);
  return JSON.stringify(result);
};
