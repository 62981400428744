<template>
  <div class="insight-animation">
    <transition :duration="2000">
      <div
        v-if="id"
        :key="id"
        class="insight-animation__item"
      >
        <Icon
          name="builder-addon-magic"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import EventsService from 'services/events.service';

export default {
  name: 'HeaderArchivingAnimation',
  components: {
  },
  data: () => ({
    id: 0,
  }),
  created() {
    EventsService.on('insightAddAnimation', () => {
      this.id = this.id + 1;
    })
  },
};
</script>
<style lang="scss" scoped>
@keyframes insight-animation-item {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
    transform: translateX(0px);
  }

  100% {
    opacity: 0;
    transform: translateX(-150px) scale(0.6);
  }
}

.insight-animation {
  &__item {
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -10px;
    width: 21px;
    height: 21px;
    animation: insight-animation-item 1200ms 1;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
    font-size: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
