<template>
  <SideDropdownMenu
    v-if="visible"
    tooltip="Open menu"
    class="methods__question-edit-counter-hover"
    :menu="menu"
  >
    <div class="library-question">
      <TypePicker
        class="library-question__picker"
        :color="color"
        :type="type"
        :create="false"
        :lock-type="true"
        placement="right"
        @color-change="onChange('color', $event)"
        @type-change="onChange('type', $event)"
      />
      <TitleInput
        v-if="!search"
        ref="title"
        :can-edit="false"
        appearance="library-item"
        :value="name"
        :close-on-enter="true"
        @change="onNameChange"
      />
      <span
        v-else
        v-html="name"
      />
    </div>
  </SideDropdownMenu>
</template>

<script>
import TypePicker from 'views/page/method-builder/type-picker';

export default {
  name: 'LibrarySection',
  components: {
    TypePicker,
  },
  props: {
    data: Object,
    props: Object,
    search: String,
  },
  data() {
    return {
    };
  },
  computed: {
    color() {
      return this.data.color || this.data.customMethodItem.color || '#abc0c8';
    },
    type() {
      return this.data.customMethodItem.type;
    },
    menu() {
      return [
        {
          text: 'Remove',
          action: () => this.$emit('action', {
            action: 'remove-item',
          }),
        },
        {
          text: 'Edit name',
          action: () => this.edit(),
        },
      ];
    },
    name() {
      if (this.search) {
        const regexp = this.getSearchRegexp(this.search);
        return this.props.name.replace(regexp, (match) => {
          return `<strong>${ match }</strong>`;
        });
      }

      return this.props.name;
    },
    visible() {
      if (this.search) {
        return this.getSearchVisible(this.search, this.props);
      }

      return true;
    },

  },
  watch: {
  },
  methods: {
    edit() {
      this.$refs.title.edit();
    },
    onChange(prop, value) {
      this.$emit('action', {
        action: 'change-item',
        data: {
          [prop]: value,
        },
      })
    },
    onNameChange(name) {
      this.$emit('action', {
        action: 'change-item',
        data: {
          name,
        },
      })
    }, 
    getSearchRegexp(value) {
      return new RegExp(`${ value }`, 'igm');
    },
    getSearchVisible(search, props) {
      if (!props.name) return false;

      const regexp = this.getSearchRegexp(search);
      return props.name.match(regexp);
    },
  },
};
</script>
