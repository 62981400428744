<template>
  <div class="tags-badges-list">
    <span
      v-for="tag of tagsList"
      :key="tag.id"
      class="tag-badge"
    >
      <span
        class="tag-colour"
        :style="{background: tag.color}"
      />
      {{ tag.name }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TagsList',
  props: {
    ids: {
      type: Array,
    },
    tags: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters(['allTags']),
    tagsList() {
      if (this.tags) {
        return this.tags;
      }

      return this.ids.map((id) => this.allTags.find((t) => t.id === id || t.id === id.id)).filter((t) => t);
    },
  },
};
</script>
