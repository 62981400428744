/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import { merge } from 'utils/reactive';
import model from 'models/notification';
import NotificationsService from 'services/notifications.service';
import generateId from 'utils/id';

const moduleState = {
  notifications: [],
  connectionNotification: '',
  uploadProgress: [],
};

const getters = {
  notifications: (state) => state.notifications,
  isNotificationVisible: (state) => (notificationId) =>
    state.notifications.some(({ id }) => id === notificationId),
  connectionNotification: (state) => state.connectionNotification,

  processNotifications: (state, getters) => {
    const sections = getters.allMethodSections;

    return [
      ...getters.transcriptions
        .filter((t) => {
          return ['pending', 'waiting'].includes(t.status)
        })
        .filter((t) => {
          return sections.find((s) => s.options && s.options.transcriptionId === t.id)
        })
        .map((t) => ({
          ...t,
          type: 'transcribe',
        })),
      ...state.uploadProgress.map((u) => ({
        ...u,
        type: 'upload',
      })),
    ];
  },
};

const actions = {
  notificationShow({ dispatch, commit, getters }, data = {}) {
    if (!data.text || !data.text.length) {
      throw new Error('Store.notifications: empty notification text!');
    }

    // only one notification can be shown at a time
    getters.notifications.forEach(({ id }) => dispatch('notificationHide', id));

    const notification = {
      ...model,
      id: generateId(),
      ...data,
    };

    commit(TYPES.NOTIFICATION_SHOW, notification);
    if(!notification.duration) {
      // nothing to do here
    } else if (typeof notification.duration === 'function') {
      notification.duration(() => {
        dispatch('notificationHide', notification.id);
      });
    } else {
      window.setTimeout(
        () => dispatch('notificationHide', notification.id),
        notification.duration
      );
    }
  },
  notificationHide({ commit, getters }, id) {
    if (!getters.isNotificationVisible(id)) return;

    commit(TYPES.NOTIFICATION_HIDE, id);
    NotificationsService.onHide(id);
  },
  connectionNotificationShow({ commit, getters }, { text }) {
    commit('CONNECTION_NOTIFICATION_SET', text);
  },
  connectionNotificationHide({ commit, getters }) {
    commit('CONNECTION_NOTIFICATION_SET', '');
  },
  notificationUndo({ commit }, id) {
    commit(TYPES.NOTIFICATION_HIDE, id);
    NotificationsService.onUndo(id);
  },
  startUpload({ commit }, data) {
    const uploadData = {
      id: generateId(),
      ...data,
    };

    commit('START_UPLOAD', uploadData)

    return uploadData;
  },
  updateUpload({ commit }, data) {
    commit('UPDATE_UPLOAD', data);
  },
  endUpload({ commit }, id) {
    commit('END_UPLOAD', id)
  },
};

const mutations = {
  [TYPES.NOTIFICATION_SHOW](state, notification) {
    state.notifications.push(notification);
  },
  [TYPES.NOTIFICATION_HIDE](state, notificationId) {
    state.notifications = state.notifications.filter(
      ({ id }) => id !== notificationId
    );
  },
  CONNECTION_NOTIFICATION_SET(state, notification) {
    state.connectionNotification = notification;
  },
  [TYPES.LOGGED_OUT](state) {
    state.notifications = [];
  },
  START_UPLOAD(state, data) {
    state.uploadProgress.push({
      ...data,
    });
  },
  UPDATE_UPLOAD(state, data) {
    const obj = state.uploadProgress.find((n) => n.id === data.id);

    if (obj) {
      merge(obj, data);
    }
  },
  END_UPLOAD(state, id) {
    state.uploadProgress = state.uploadProgress.filter((n) => n.id !== id);
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
