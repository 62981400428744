<template>
  <div
    ref="base"
  >
    <span
      class="builder-paragraph__counter"
      data-paragraph-counter="true"
      v-if="type === 'number-list'"
    ></span>
    <Editor
      ref="editor"
      :tags-enabled="true"
      :allow-image="false"
      :visible-on-start="visibleOnStart"
      :lazy="true"
      :value="section.content"
      :can-edit="canEdit"
      :tags-list="allTags"
      :type="type"
      :multiline="true"
      placeholder="Type here..."
      @change="onInput"
      @setType="onSetType"
      @keydown="onKeyDown"
      @focus="onFocus"
      @blur="onBlur"
      @content-tags-change="onContentTagsChange"
      @paste="onPaste"
    />

    <template v-if="canEdit && summaryInsights.length">
      <h2>Suggested insights</h2>
      <div>
        <div
          v-for="insight in summaryInsights"
          :key="insight"
          class="insight-item insight-item--compact"
          @click="openSummaryInsight(insight)"
        >
          <Icon
            class="insight-item__image"
            name="insight-illu"
          />
          <div class="insight-item__body">
            <h2 class="insight-item__title">
              {{ insight }}
            </h2>
          </div>
          <BaseButton
            class="insight-item__btn"
            appearance="outline sm"
            text="Add insight"
            v-if="!isInsightCreated(insight)"
          />
          <BaseButton
            class="insight-item__btn"
            appearance="outline success sm"
            text="Added"
            icon="check-full-circle"
            v-else
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Editor from 'components/common/editor';
import { mapGetters } from 'vuex';
import EventsService from 'services/events.service';

export default {
  name: 'ProjectMethodCustom',
  components: {
    Editor,
  },
  props: {
    section: {
      type: Object,
      default: undefined,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      newColor: '#abc0c8',
      newType: '',
      hasFocus: false,
      isOpen: false,
      visibleOnStart: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedSections',
      'allTags',
    ]),
    options() {
      return this.section.options || {};
    },
    type() {
      return this.options.type || 'default';
    },
    classNames() {
      return {
        'builder-paragraph--open': this.isOpen,
        'methods__question-edit-counter-active': this.isOpen,
        [`builder-paragraph--type-${this.options.type}`]: this.type,
      };
    },
    summaryInsights() {
      return (this.section.options && this.section.options.insights) || [];
    },
    isEmpty() {
      const content = (this.section.content || '')
        .replace('<p></p>', '')
        .replace(/^[\n\t\s]$/gim, '');

      return !content.length;
    },
    taggable() {
      return !this.isEmpty;
    },
  },
  watch: {
    section: {
      handler(section) {
        if (section.modifiedByOtherUser) {
          this.$refs.editor.setValue(section.content);
        }
      },
      deep: true,
    },
    hasFocus(focus) {
      if (focus) {
        this.$refs.base.classList.add('builder-paragraph--focus');
      } else {
        this.$refs.base.classList.remove('builder-paragraph--focus');
      }
    },
  },
  created() {
    if (this.section.focus || this.section.focus === 0) {
      this.visibleOnStart = true;
    }
  },
  mounted() {
    if (this.hasFocus) {
      this.$refs.base && this.$refs.base.classList.add('builder-paragraph--focus');
    } else {
      this.$refs.base && this.$refs.base.classList.remove('builder-paragraph--focus');
    }

    this.refreshCounters();
    EventsService.on('editor-updated', this.refreshCounters);

    this.$el.component = this;
  },
  unmounted() {
    EventsService.off('editor-updated', this.refreshCounters);
  },
  updated() {
    if (this.hasFocus) {
      this.$refs.base.classList.add('builder-paragraph--focus');
    } else {
      this.$refs.base.classList.remove('builder-paragraph--focus');
    }

    this.refreshCounters();
  },
  methods: {
    onHistoryChange(state) {
      const editor = this.$refs.editor.editor;

      if (typeof state.content === 'string') {
        this.$refs.editor.setValue(state.content);
      }

      if (editor && state.selection) {
        editor.setSelection(state.selection);
      }
    },
    onHistorySave(prev, next) {
      const editor = this.$refs.editor.editor;

      if (editor) {
        prev.selection = editor.getSelection();

        window.setTimeout(() => {
          next.selection = editor.getSelection();
        }, 33)
      }
    },
    refreshCounters() {
      const sections = document.querySelectorAll('.section-wrap');
      let counter = 0;

      sections.forEach((section) => {
        const counterEl = section.querySelector('[data-paragraph-counter]');

        if (counterEl) {
          counter++;
          counterEl.innerHTML = `${counter}.`;
        } else {
          counter = 0;
        }
      });
    }, 
    onContentTagsChange(sentences) {
      const tagsList = this.$store.getters.tags;
      let contentTags = (sentences || []).map((d) => ({
        tagList: d.tagList,
        text: d.content,
        editorId: d.editorId,
        startAt: d.startAt,
        endAt: d.endAt,
      }));

      contentTags = contentTags.map((contentTag) => ({
        ...contentTag,
        tagList: contentTag.tagList.filter((tag) => {
          return tagsList.find((t) => t.id === tag);
        }),
      }));

      this.$store.dispatch('methodSectionUpdate', {
        id: this.section.id,
        data: {
          contentTags,
        },
      });
    },
    async onInput({ value}) {
      this.$store.dispatch('methodSectionUpdate', {
        id: this.section.id,
        data: {
          content: value,
          local: false,
        },
      });
    },
    async onKeyDown(e) {
      if (e.key === 'Tab') {
        if (this.isEmpty) {
          this.$refs.picker.show();
          e.preventDefault();
          return;
        }
      }
    },
    onTypeChange({type}) {
      this.newType = type;
    },
    onSetType(type) {
      if (this.options.type === type) {
        type = '';
      }
      this.$store.dispatch('methodSectionUpdate', {
        id: this.section.id,
        data: {
          options:{
            ...this.section.options,
            type,
          },
        },
      });
    },
    onColorChange(color) {
      this.newColor = color;
    },
    focus(type = 'end') {
      this.$refs.editor.focus(type);
      this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { focus: null } });
    },
    setValue(value) {
      this.$refs.editor.setValue(value || '');
    },
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    onAdd({ type, data }) {
      if (type === 'image') {
        this.$refs.fileInput.click();
      } if (type === 'custom_6') {
        this.$refs.fileInput.click();
      } else {
        this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { color: this.newColor, name: '', type, ...data } });
      }
    },
    addTag(tag) {
      if (this.isEmpty) return;
      this.$refs.editor.addTag(tag);
    },
    removeTag(tag) {
      this.$refs.editor.removeTag(tag);
    },
    async addSummaryInsight(insight) {
      if (this.insightsLimitReached) {
        this.$store.dispatch('modalShow', {
          name: 'limit-reached',
          data: {
            kind: 'insights',
          },
        });

        return;
      }
      
      const { id } = await this.$store.dispatch('projectInsightAdd', { name: insight });

      EventsService.emit('insightAddAnimation');
    },
    isInsightCreated(insight) {
      return this.$store.getters.insights.find((i) => i.name === insight);
    },
    openSummaryInsight(text) {
      const insight = this.$store.getters.insights.find((i) => i.name === text);

      if (insight) {
        this.$store.dispatch('modalShow', {
          name: 'method',
          data: {
            id: insight.id,
          },
        });
      } else {
        this.addSummaryInsight(text);
      }
    },
  },
};
</script>
