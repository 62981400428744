<template>
  <div class="onb-step">
    <div class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          {{ title }}
        </h2>
      </div>
      <BaseInput
        v-model="organizationName"
        name="brand"
        type="text"
        placeholder="Enter your workspace name..."
        class="input input--new-light input--center"
        autofocus
        @keydown.enter.prevent="nextStep"
      />
    </div>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';
import { navigateProjects } from 'helpers/router';
import { mapGetters } from 'vuex';
import { analyticsLogEvent } from 'utils/analytics';

export default {
  name: 'WorkspaceBrand',
  props: {
    data: Object,
  },
  data() {
    return {
      organizationName: this.data.organizationName || '',
    };
  },
  computed: {
    ...mapGetters(['workspaces']),
    organizationType() {
      return this.data.organizationType || 'product';
    },
    title() {
      return 'How would you like to call your workspace?';
    },
  },
  watch: {
    organizationName() {
      this.updateConfig();
    },
    organizationType() {
      this.data.organizationType;
    },
  },
  created() {
    this.updateConfig();
  },
  methods: {
    getConfig() {
      return {
        progress: 2/5,
        avatar: 'brand',
        avatarName: this.organizationName || '',
        buttons: [
          {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Continue',
            disabled: !this.organizationName,
            loading: this.loading,
          },
          {
            appearance: 'light',
            id: 'secondary',
            text: 'Cancel',
          },
        ],
      };
    },
    async buttonAction(id) {
      if (this.loading) return;

      if (id === 'secondary') {
        if (this.workspaces && !this.workspaces.length) {
          this.loading = true;
          this.updateConfig();
          this.$store.dispatch('userChange', { welcomeScreenShown: true });
          const workspace = await this.$store.dispatch('workspaceCreate', {
            name: 'My Workspace',
            private: false,
          });

          this.$store.dispatch('workspaceSelect', { id: workspace.id });

          analyticsLogEvent('create_workspace_create_default_workspace');
        }

       navigateProjects();
       analyticsLogEvent('create_workspace_back_to_projects');
      } else {
        this.nextStep();
      }
    },
    nextStep() {
      if (!this.organizationName) {
        this.$emit('shake');
        return;
      }

      this.$emit('redirect', {
        route: ROUTES.WORKSPACE_ONBOARDING_LOGO,
        data: {
          ...this.data,
          organizationName: this.organizationName,
        },
      })

      analyticsLogEvent('create_workspace_confirm_name');
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
  },
};
</script>
