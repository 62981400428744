import md5 from 'md5';

export const slugify = (text) =>
  text ? text
    .split(/(?=[A-Z])/)
    .join(' ')
    .toLowerCase()
    .replace(/ /g, '-') : '';
export const hashMD5 = (text) => md5(text);
export const pluralify = (word, count) => `${word}${count === 1 ? '' : 's'}`;
export const titleize = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
