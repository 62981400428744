<template>
  <BaseModal
    class="payment-modal"
    name="PaymentModal"
    :show-close="true"
    @active="active = $event"
    @data="data = $event"
  >
    <ReceiptData
      v-if="stepName === 'receiptData'"
      :pricingModalData="data"
      @completed="onReceiptCompleted"
      @show-step="onShowStep"
    />
    <!-- Payment has to be always rendered in order for Braintree to work -->
    <Success
      v-else
      :receipt-data="data"
    />
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';

import ReceiptData from './receipt';
import Success from './success';

export default {
  name: 'PaymentModal',
  components: {
    BaseModal,
    ReceiptData,
    Success,
  },
  data: () => ({
    stepName: 'receiptData',
    data: null,
    active: false,
  }),
  methods: {
    onReceiptCompleted(data) {
      this.data = data;
      this.stepName = 'success';
    },
    onShowStep(step) {
      this.stepName = step;
    },
  },
};
</script>
