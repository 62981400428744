<template>
  <div
    id="app"
    @dragover="onDragEvent"
    @dragleave="onDragEvent"
    @drop="onDragEvent"
  >
    <app-header v-if="!isPublicRoute" />
    <AppPublicHeader v-else />
    <router-view v-slot="{ Component }">
      <transition
        mode="out-in"
        name="route-fade"
        @after-leave="triggerScroll"
      >
        <component :is="Component" />
      </transition>
    </router-view>
    <modals />
    <transition
      mode="out-in"
      name="swipe-left"
      @enter="onLibraryEnter"
      @leave="onLibraryLeave"
    >
      <Library v-show="isProjectMethodRoute && isLibraryOpen" />
    </transition>
    <prompts />
    <notifications />
    <ProcessNotifications />
    <ConnectionNotification />
    <EditorToolbar />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from 'components/header';
import AppPublicHeader from 'components/header-public';
import Library from 'components/library';
import Modals from 'modals';
import Notifications from 'components/notifications';
import ProcessNotifications from 'components/process-notifications';
import ConnectionNotification from 'components/notifications/connection';
import Prompts from 'components/prompts';
import { AOS_ANIMATE_CLASS } from 'constants/css';
import { PROJECT_METHOD } from 'constants/routes';
import EditorToolbar from 'components/common/editor/toolbar';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppPublicHeader,
    Modals,
    Notifications,
    Library,
    Prompts,
    ConnectionNotification,
    EditorToolbar,
    ProcessNotifications,
  },
  computed: {
    ...mapGetters([
      'projectCurrentId',
      'projectCurrentMethodId',
      'projectCurrentMethod',
      'isLibraryOpen',
      'connectionNotification',
      'isPublicRoute',
    ]),
    viewKey() {
      if (this.projectCurrentMethod) {
        return this.projectCurrentMethod.id;
      }

      if (this.projectCurrentMethodId) {
        return this.projectCurrentMethodId;
      }

      return null;
    },
    isProjectMethodRoute() {
      return this.$store.getters.isRoute(PROJECT_METHOD);
    },
  },
  created() {
    this.$store.dispatch('requireUser');
  },
  methods: {
    beforeLeave(element) {
      this.$nextTick(() => {
        const aosElements = element.querySelectorAll(`.${AOS_ANIMATE_CLASS}`);
        [...aosElements].forEach((el) => el.classList.remove(AOS_ANIMATE_CLASS));
      });
    },
    triggerScroll() {
      window.scrollTo(0, 0);
      this.$root.$emit('triggerScroll')
    },
    onDragEvent(e) {
      e.stopPropagation();
      e.preventDefault();

      if (e.type == 'dragover') {
        if (this.resetTimer) {
          clearTimeout(this.resetTimer);
        }
        this.$store.dispatch('toggleDragOver', true);
      } else if (e.type == 'dragleave') {
        this.resetTimer = window.setTimeout(() => this.$store.dispatch('toggleDragOver', false), 25);
      } else if (e.type == 'drop') {
        this.$store.dispatch('toggleDragOver', false);
      }
    },
    onLibraryEnter() {
      document.body.classList.add('is-library-open');
    },
    onLibraryLeave() {
      document.body.classList.remove('is-library-open');
      this.$store.dispatch('toggleLibrary', false);
    },
  },
};
</script>

<style lang="scss">
@import 'styles/index.scss';
</style>
