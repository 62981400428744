<template>
  <div class="modal__body">
    <div class="modal-done modal-done--success">
      <img
        src="/static/img/svg/art-checkmark.svg"
        class="modal-done__icon"
      >
      <h2 class="modal-done__title">
        {{ title }}
      </h2>
      <dl class="dl-info">
        <dt>Plan</dt>
        <dd>{{ periodType }} {{ plan }} Plan</dd>
        <template v-if="!receiptData.isDowngraded">
          <dt>Active until</dt>
          <dd>{{ untilDate }}</dd>
        </template>
        <dt>Total</dt>
        <dd>{{ price }}</dd>
      </dl>
      <div
        v-if="receiptData.isDowngraded"
        class="payment__form-terms"
      >
        You’ll be charged ${{ receiptData.originalPrice }} in the next<br> billing cycle ({{ untilDate }})
      </div>
      <button
        class="btn payment__btn modal-done__btn modal__submit"
        @click="onBtnClick"
      >
        Continue to Talebook
      </button>
    </div>
  </div>
</template>

<script>
import { PROJECTS_LIST } from 'constants/routes';
import { titleize } from 'utils/text';

export default {
  name: 'PaymentSuccess',
  props: {
    receiptData: Object,
  },
  data: () => ({
  }),
  computed: {
    plan() {
      if (!this.receiptData) return '';
      return titleize(this.receiptData.plan);
    },
    title() {
      if (!this.receiptData) return '';
      if (this.receiptData.isDowngraded || this.receiptData.isUpgraded) {
        return 'Update successful';
      }

      return 'Payment successful';
    },
    periodType() {
      if (!this.receiptData) return '';
      return titleize(this.receiptData.type);
    },
    price() {
      if (!this.receiptData) return '';
      let price = this.receiptData.price;

      if (this.receiptData.isDowngraded) return `$${ price }`;

      if (this.receiptData.type === 'yearly') {
        return `$${ price }/year`;
      }

      return `$${ price }/month`;
    },
    untilDate() {
      if (!this.receiptData) return '';
      let date = new Date(this.receiptData.activeUntil);
      return date.toLocaleDateString();
    },
  },
  methods: {
    onBtnClick() {
      this.$store.dispatch('subscriptionFetch');
      this.$router.push({ name: PROJECTS_LIST });
      this.$store.dispatch('modalHide');
    },
  },
};
</script>
