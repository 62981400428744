<template>
  <div
    ref="self"
    class="tags-selector-wrap"
  >
    <div
      class="backdrop"
      @click="onExit"
    />
    <div
      class="tags-selector"
      :class="classes"
    >
      <div
        v-if="headerEnabled"
        class="tags-selector__header"
      >
        <div class="tags-selector__tags">
          {{ type == 'filter' ? 'Show' : 'Tags' }}
        </div>
        <button
          type="button"
          class="tags-selector__button tags-selector__button--close"
          @click.stop="onExit"
        >
          <img
            width="14"
            height="12"
            src="/static/img/svg/close.svg"
            alt=""
          >
        </button>
      </div>
      <div
        v-if="canEdit"
        ref="inputContainer"
        class="tags-selector__input-container"
        :class="{ active: isInputActive }"
        @click.capture.prevent.stop="$refs.input.focus()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          :class="{ active: isInputActive }"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#AAAAB5"
            stroke-linecap="round"
            stroke-linejoin="round"
            transform="translate(1 1)"
          >
            <circle
              cx="5.833"
              cy="5.833"
              r="5.833"
            />
            <path d="M14 14L9.956 9.956" />
          </g>
        </svg>
        <div
          v-show="searchedText.length == 0"
          class="tags-selector__placeholder"
          :class="{ active: isInputActive }"
        >
          Search {{ canEdit ? 'or add tag' : '' }}
        </div>
        <input
          ref="input"
          v-model="searchedText"
          type="text"
          tabindex="-1"
          :maxlength="$store.getters.tagNameMaxLength"
          @focus="isInputActive = true"
          @blur="isInputActive = false"
          @keydown="handleKeydown"
        >
      </div>
      <div
        v-if="!myTags.length && !searchedText.length"
        class="tags-selector__no-item"
      >
        <img
          src="/static/img/svg/ic-sticker-note.svg"
          width="64"
          height="69"
          alt=""
        >
        <span v-if="canEdit">You don’t have any tags</span>
        <span v-else>No tags added</span>
      </div>
      <div
        v-if="type == 'filter' && !allSelected && !searchedText.length"
        class="tags-selector__list-item tags-selector__list-item--selector"
        :class="{
          'tags-selector__list-item--selector--selected': tagIndex == -1
        }"
        @click.stop="selectAll"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="10"
          viewBox="0 0 12 10"
        >
          <path
            fill="none"
            fill-rule="evenodd"
            stroke="#4B4B4B"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 1L3.75 9 0 5.364"
          />
        </svg>
        <div>Select All</div>
      </div>
      <div
        v-if="type == 'filter' && allSelected && !searchedText.length"
        class="tags-selector__list-item tags-selector__list-item--selector"
        :class="{
          'tags-selector__list-item--selector--selected': tagIndex == -1
        }"
        @click.stop="deselectAll"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="10"
          viewBox="0 0 12 10"
        >
          <path
            fill="none"
            fill-rule="evenodd"
            stroke="#4B4B4B"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 1L3.75 9 0 5.364"
          />
        </svg>
        <div>Deselect All</div>
      </div>
      <div v-if="myTags.length || showNewTag">
        <div
          v-if="myTags.length || searchedText.length"
          class="tags-selector__scroll"
        >
          <div
            ref="tagsSelectorList"
            class="tags-selector__list"
          >
            <TagListItem
              v-for="(tag, index) in selectedTags"
              :ref="'item-' + tag.id"
              :key="tag.id"
              :focused="index === tagIndex"
              :color="tag.color"
              selected-class="tags-selector__list-item--selected"
              :tag="tag"
              class="tags-selector__list-item"
              @click.stop="tagClicked(tag)"
            >
              <span v-html="highlightName(truncate(tag.name, 23), searchedText)" />
            </TagListItem>
          </div>
        </div>
        <div
          v-if="showNewTag"
          class="tags-selector__list-item tags-selector__list-item--new"
          :class="{
            'tags-selector__list-item--new-focused':
              tagIndex === selectedTags.length
          }"
          @click.stop="addNewTag"
        >
          <img
            width="15"
            height="11"
            src="/static/img/svg/plus-small.svg"
            alt=""
          >
          <div>
            <span
              style="word-break: break-all;"
              v-text="searchedText"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { TAGS } from 'constants/modals';
import { TAG_COLORS } from 'constants/tag-colors';
import { stringIncludes } from 'helpers/filter';
import { alphabetically } from 'helpers/sort';
import { truncate } from 'helpers/string';
import { xsMax } from 'helpers/ui';
import leven from 'js-levenshtein';
import uuid from 'utils/id';
import scrollTo, { getScrollPosition } from 'utils/scroll';
import { mapGetters } from 'vuex';

import { BLANK_ID } from '../constants';
import TagListItem from './tag-list-item';

export default {
  name: 'TagsSelector',
  components: {
    TagListItem,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: [Array, null],
      default: null,
    },
    type: {
      type: String,
      default: 'selector',
      require: false,
    },
    headerEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    myTags: [],
    searchedText: '',
    isActive: true,
    newTester: '',
    isInputActive: false,
    tagIndex: 0,
  }),
  computed: {
    ...mapGetters({
      'storeTags': 'tags',
    }),
    tagsList() {
      return this.tags || this.storeTags;
    },
    allSelected() {
      return this.value.length === this.tagsList.length;
    },
    selectedTags() {
      let tags = [...this.myTags];
      if (this.searchedText.length > 0) {
        tags = tags.filter((tag) => stringIncludes(tag.name, this.searchedText));
        tags = tags.sort((a, b) =>
          leven(this.searchedText, a.name) > leven(this.searchedText, b.name)
            ? 1
            : -1
        );
      }
      return tags;
    },
    showNewTag() {
      if (!this.canEdit) {
        return false;
      }
      if (this.searchedText.toString().length === 0) {
        return false;
      }
      return !this.myTags.find(
        (tag) =>
          tag.name.trim().toLowerCase() ===
          this.searchedText
            .toString()
            .toLowerCase()
            .trim()
      );
    },
    canEdit() {
      return this.$store.getters.userCanEditProjects;
    },
    classes() {
      return {
        'js-active': this.isActive,
        'show-new-tag': this.showNewTag,
      };
    },
  },
  watch: {
    tagsList(newTags, prevTags) {
      if (newTags !== prevTags) {
        this.prepareData();
      }
    },
    searchedText() {
      this.tagIndex = 0;
    },
  },
  created() {
    this.prepareData();
  },
  unmounted() {
    //document.body.classList.remove('modal-open');
    clearAllBodyScrollLocks();
  },
  methods: {
    truncate,
    adjustVisibility(tag) {
      if (!tag) return;
      const cont = this.$refs.tagsSelectorList;
      const contOffsetTop = cont.offsetTop;
      const contRect = cont.getBoundingClientRect();
      const contRectTop = contRect.top;
      const contRectBottom = contRect.bottom;

      const elem = this.$refs[`item-${tag.id}`][0].$el;
      const elemOffsetTop = elem.offsetTop;
      const rect = elem.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      window.cont = cont;
      if (contRectTop > elemTop) {
        cont.scrollTop = elemOffsetTop - contOffsetTop;
      }
      if (contRectBottom < elemBottom) {
        cont.scrollTop = elemOffsetTop - cont.offsetHeight + elem.offsetHeight;
      }
    },
    handleKeydown(e) {
      if (!this.canEdit) return;
      const min = this.type === 'filter' ? -1 : 0;

      if (e.code === 'ArrowDown') {
        this.tagIndex += 1;
        if (this.tagIndex >= this.selectedTags.length) {
          if (this.showNewTag) {
            this.tagIndex = this.selectedTags.length;
          } else {
            this.tagIndex = this.selectedTags.length - 1;
          }
        }
        this.adjustVisibility(this.selectedTags[this.tagIndex]);
        e.preventDefault();
        e.stopPropagation();
      } else if (e.code === 'ArrowUp') {
        if (this.tagIndex > min) this.tagIndex -= 1;
        this.adjustVisibility(this.selectedTags[this.tagIndex]);
        e.preventDefault();
        e.stopPropagation();
      } else if (e.code === 'Enter') {
        if (this.tagIndex === -1) {
          if (this.allSelected) {
            this.deselectAll();
          } else {
            this.selectAll();
          }
        } else if (this.tagIndex < this.selectedTags.length) {
          this.tagClicked(this.selectedTags[this.tagIndex]);
          this.searchedText = '';
        } else if (this.tagIndex === this.selectedTags.length) {
          this.addNewTag();
        }
        e.preventDefault();
        e.stopPropagation();
      } else if (e.key !== 'Escape') {
        e.stopPropagation();
      }
    },
    deselectAll() {
      if (!this.canEdit) return;
      this.myTags.forEach((tag) => {
        tag.selected = false;
      });
      this.emitInput();
    },
    selectAll() {
      if (!this.canEdit) return;
      this.myTags.forEach((tag) => {
        tag.selected = true;
      });
      this.emitInput();
    },
    selectTags(list) {
      if (!this.canEdit) return;
      this.myTags = [...this.tagsList];
      this.myTags.forEach((tag) => {
        tag.selected = list.indexOf(tag.id) > -1;
      });
      this.myTags.sort((a, b) => {
        if (a.selected === b.selected) {
          return alphabetically(a.name, b.name);
        } else {
          return (b.selected ? 1 : 0) - (a.selected ? 1: 0);
        }
      });
    },
    async addNewTag() {
      if (!this.canEdit) return;
      if (!this.showNewTag) {
        return;
      }
      const randomColor = TAG_COLORS[Math.floor(Math.random()*TAG_COLORS.length)];
      const id = uuid();
      const newTag = {
        id,
        name: this.searchedText,
        selected: true,
        color: randomColor,
      };
      this.myTags.unshift(newTag);
      this.searchedText = '';

      if (xsMax()) {
        this.$refs.input.blur();
      }

      const result = await this.$store.dispatch('tagAdd', newTag);

      this.myTags.forEach((tag) => {
        if (tag.id === id) {
          tag.id = result.id;
        }
      });

      newTag.id = result.id;

      this.emitInput();
      this.emitSelection(newTag, true);

      this.$refs.input.focus();
    },
    tagClicked(tag) {
      if (!this.canEdit) return;

      let selected = !this.myTags.find((t) => t.id === tag.id).selected;
      this.myTags = this.myTags.map((t) =>
        t.id === tag.id ? { ...tag, selected: !tag.selected } : t
      );

      this.emitInput();
      this.emitSelection(tag, selected);
    },
    emitSelection(tag, selected) {
      if (selected) {
        this.$emit('tagSelect', tag);
      } else {
        this.$emit('tagUnselect', tag);
      }
    },
    emitInput() {
      this.$emit('input', this.myTags.filter((myTag) => myTag.selected));
    },
    highlightName(name, pattern) {
      if (pattern.toString().length === 0) {
        return name;
      }
      return name.replace(pattern.trim().toLowerCase(),
        '<span class=\'highlight\'>$&</span>'
      );
    },
    prepareData() {
      this.searchedText = '';
      const valueTagIds = this.value.map((tag) => tag.id);
      const selectedTags = [];
      const otherTags = [];
      const blank = this.tagsList.find((tag) => tag.id === BLANK_ID);
      const withoutBlank = [...this.tagsList].filter((tag) => tag.id !== BLANK_ID);

      withoutBlank.forEach((tag) => {
        if (valueTagIds.includes(tag.id)) {
          selectedTags.push({
            ...tag,
            selected: true,
          });
        } else {
          otherTags.push({
            ...tag,
            selected: false,
          });
        }
      });

      selectedTags.sort((a, b) => alphabetically(a.name, b.name));
      otherTags.sort((a, b) => alphabetically(a.name, b.name));

      if (!this.canEdit) {
        this.myTags = [...selectedTags];
        return;
      }

      this.myTags = [...selectedTags, ...otherTags];
      if (this.type === 'filter' && blank) {
        this.myTags = [blank, ...this.myTags];
      }
    },
    triggerShow() {
      if (!xsMax()) {
        this.$refs.input.focus();
      }
      this.show({});
    },
    show(e) {
      if (e.target && e.target.closest('.tags-selector') !== null) {
        return;
      } // prevent show from itself
      this.isActive = !this.isActive;
      this.$emit('tags-selector-toggle', true);
      this.prepareData();
      this.isActive = true;
      document.addEventListener('keyup', this.hideUsingEscapeKey, true);
      this.$nextTick(() => {
        if (!xsMax()) {
          this.$refs.input.focus();
        }

        // Scroll to parent element
        const box = this.$refs.self;

        if (box && box.parentNode && xsMax()) {
          const bounding = box.parentNode.getBoundingClientRect();
          scrollTo(bounding.top + bounding.height + getScrollPosition().top - window.innerHeight + 410);
        }

        if (this.$refs.tagsSelectorList && xsMax()) {
          //document.body.classList.add('modal-open');
          disableBodyScroll(this.$refs.tagsSelectorList);
        }
      });
    },
    hide() {
      this.isActive = false;
      this.$emit('tags-selector-toggle', false);
      //document.body.classList.remove('modal-open');
      clearAllBodyScrollLocks();
      document.removeEventListener('keyup', this.hideUsingEscapeKey, true);
    },
    onExit(e) {
      this.$emit('exit');
    },
    hideUsingEscapeKey(e) {
      e.stopPropagation();
      if (e.keyCode === 27) {
        this.hide();
      }
    },
    delayHide() {
      if (!this.isActive) {
        return;
      }
      this.hide();
    },
    showManageTags() {
      this.hide();
      this.$emit('modal-show');
      this.$store.dispatch('modalShow', TAGS);
    },
    preventScroll(e) {
      e.preventDefault();
    },
    clearSearch() {
      this.searchedText = '';
    },
  },
};
</script>


<style lang="scss">
@import 'styles/sizes';
.tags-selector .highlight {
  font-weight: bold;
  color: #171717;
}

.tags-selector .vb-dragger {
  right: 2px;
}

.tags-selector .tags-selector__scroll {
  @include xsMax {
    height: 300px;
  }
}

.show-new-tag .tags-selector__scroll {
  @include xsMax {
    height: 252px;
  }
}

.tags-selector .vb-dragger-styler {
  right: -1px;
  width: 4px;
  opacity: 0.3;
  border-radius: 20px;
  background-color: rgb(85, 85, 85);
  height: 100%;
}
</style>

<style lang="scss" scoped>
@import 'styles/sizes';
@mixin text {
  color: #646464;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.6;
  display: none;

  @include xsMax {
    display: block;
  }
}

.tags-selector-wrap {
  @include xsMax {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: auto;
    z-index: 20000000020;
  }
}

.tags-selector {
  width: 260px;
  padding: 8px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  font-weight: normal;

  @include xsMax {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: auto;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 0;
    background-color: #fff;

    &.show-new-tag {
      padding-bottom: 8px;
    }
  }

  @include xsMax {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: auto;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 0;
  }

  &__no-item {
    padding: 17px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include xsMax {
      box-sizing: border-box;
      height: calc(100vh - 270px);
    }

    img {
      opacity: 0.4;
      width: 50px;
      height: 50px;
      object-fit: contain;

      @include xsMax {
        width: 100px;
        height: 100px;
      }
    }

    span {
      @include text;
      margin-top: 12px;
      color: #aaaab5;
      font-size: 12px;
    }
  }

  &__list {
    max-height: 420px;
  }

  &__list-item-color {
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    margin: 0px 16px 0 10px;
  }

  &__list-item-name {
    flex: 1;
  }

  &__list-item {
    @include text;
    letter-spacing: 0.1px;
    min-height: 36px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    margin-right: 8px;
    cursor: pointer;
    &:hover {
      background-color: #F8F9FA;
    }
    &:active {
      background-color: #F0F2F5;
    }
    &:last-child,
    &:first-child {
      margin-bottom: 0px;
    }

    &--selected {
      color: #252729;
      font-weight: 600;
      svg {
        width: 24px;
        margin: 2px;
        stroke-width: 1.05px;
      }
    }

    &--selector {
      color: #252729;
      margin-bottom: 4px;
      font-weight: 600;
      svg {
        width: 15px;
        height: 11px;
        margin: 0px 17px 0 10px;
      }
      &--selected {
        background-color: #F8F9FA;
      }
    }

    &--new {
      color: #252729;
      margin-top: 4px;
      font-weight: 600;
      img {
        width: 15px;
        height: 11px;
        margin: 0px 17px 0 10px;
        flex-shrink: 0;
      }
    }

    &--new-focused {
      background-color: #F8F9FA;
    }
  }

  &__placeholder {
    @include text;
    position: absolute;
    opacity: 0.5;
    cursor: pointer;

    height: 20px;
    width: 110px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: calc(50% - 50px);
    margin-top: auto;
    margin-bottom: auto;
    transition: margin 0.2s linear;

    &.active {
      margin-left: 44px;
      pointer-events: none;
    }
  }

  &__input-container {
    position: relative;
    display: flex;
    margin-top: 8px;
    margin-right: 8px;
    margin-bottom: 4px;
    background-color: #F8F9FA;
    border-radius: 4px;
    height: 41px;
    align-items: center;
    padding: 5px 10px;
    svg {
      margin-right: 16px;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.1s;
    }
    svg.active {
      visibility: visible;
      opacity: 1;
      pointer-events: none;
    }
    input {
      @include text;
      flex: 1;
      border: 0;
      outline: none;
      background-color: transparent;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
  }

  &__tags {
    font-size: 1.6rem;
    text-align: center;
    width: 100%;
  }

  &__button {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &:hover {
      background-color: #F8F9FA;
    }
   &:focus {
     outline: none;
   }

    &:active {
      background-color: #F0F2F5;
    }

    &--close {
      @include smMin {
        display: none;
      }
    }
  }
}
</style>
