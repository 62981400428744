<template>
  <div
    v-if="isProjectsFetched"
    class="sidebar-margin"
  >
    <div class="workspace-container">
      <TitleInput
        appearance="project-title"
        :can-edit="false"
        value="Projects"
      />
      <div class="workspace-section">
        <HelpBanner name="projects" />
        <BaseTable
          v-if="filteredProjects.length"
          :animated="true"
          appearance="projects expand"
        >
        <TransitionGroup name="enter-list">
            <TableItem
              v-if="userCanEditProjects"
              key="new"
            >
              <NewProject @create="createNew" />
            </TableItem>
            <TableItem
              v-for="project in filteredProjects"
              :key="project.id"
            >
              <Project :data="project" />
            </TableItem>
          </TransitionGroup>
        </BaseTable>
        <div
          v-else
          class="empty-info"
        >
          <div>
            <Icon
              class="empty-info__icon"
              name="sticker-note"
            />
            <strong class="empty-info__heading" v-if="userCanEditProjects">Create your first project</strong>
            <strong class="empty-info__heading" v-else>No projects found</strong>
            <button
              class="btn btn--primary btn--block btn--md"
              @click.prevent.stop="createNew"
              v-if="userCanEditProjects"
            >
              Create New Project
            </button>
            <p class="empty-info__text" v-else>You don’t have access to add new projects</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UPGRADE } from 'constants/modals';
import { PROJECT_NEW } from 'constants/routes';
import { mapGetters } from 'vuex';
import { analyticsLogEvent } from 'utils/analytics';

import NewProject from 'components/workspace/new-project';
import Project from 'components/workspace/project';

export default {
  name: 'WorkspaceProjects',
  components: {
    Project,
    NewProject,
  },
  data() {
    return {
      creating: false,
    }
  },
  computed: {
    ...mapGetters([
      'projects',
      'isProjectsFetched',
      'userCanEditProjects',
      'projectsLimitReached',
    ]),
    filteredProjects() {
      return this.projects.filter((p) => !p.removed);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('requireWorkspaces');
      await this.$store.dispatch('projectsFetch');

      for(let project of this.projects) {
        this.$store.dispatch('unsetPublicToken', { id: project.id });
      }
    },
    async createNew() {
      if (this.creating) return;

      if (this.projectsLimitReached) {
        this.$store.dispatch('modalShow', {
          name: 'limit-reached',
          data: {
            kind: 'projects',
          },
        });

        return;
      }

      this.newClicked = true;

      this.creating = true;
      const name = PROJECT_NEW;

      this.$router.push({
        name,
      });

      analyticsLogEvent('project_create_projects');
    },
  },
};
</script>
