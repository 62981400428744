<template>
  <div>
    <p class="modal__title">
      Checkout - {{ periodType }} {{ plan }} Plan
    </p>

    <p class="payment__secure">
      <span class="icon-locked-green" /> Secure transaction
    </p><p /><div class="payment__form">
      <div class="payment__form-content">
        <div
          class="payment__form-row"
          :class="{ 'is-invalid': countryError }"
        >
          <div class="payment__form-col">
            <label
              class="payment__form-label"
              for="country"
            >Country</label>
            <div>
              <Autocomplete
                ref="autocomplete"
                :source="countryList"
                :min-length="0"
                placeholder="e.g. United States"
                :selection="form.country"
                @select="onCountrySelected"
              >
                <template v-slot="data">
                  {{ data.value }}
                </template>
              </Autocomplete>
              <p
                v-if="countryError"
                class="payment__form-field-error"
              >
                {{ countryError }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="payment__form-row"
          :class="{ 'is-invalid': nameError }"
        >
          <div class="payment__form-col">
            <label
              class="payment__form-label"
              for="ccName"
            >
              {{ updateCard || !paymentMethods.length ? 'Name on card' : 'Name' }}
            </label>
            <div>
              <input
                id="ccName"
                v-model="form.firstName"
                type="text"
                placeholder="e.g. John Doe"
                @input="onInput"
              >
              <p
                v-if="nameError"
                class="payment__form-field-error"
              >
                {{ nameError }}
              </p>
            </div>
          </div>
        </div>
        <div v-show="updateCard || !paymentMethods.length">
          <div
            class="payment__form-row"
            :class="{ 'is-invalid': numberError && numberError.length }"
          >
            <div class="payment__form-col">
              <label
                class="payment__form-label"
                for="ccNumber"
              >Card number</label>
              <div>
                <div
                  id="ccNumber"
                  class="braintree-hf"
                />
                <p
                  v-if="numberError && numberError.length"
                  class="payment__form-field-error"
                >
                  {{ numberError }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="payment__form-row"
            :class="{ 'is-invalid': expirationError && expirationError.length }"
          >
            <div class="payment__form-col">
              <label
                class="payment__form-label"
                for="ccExpiration"
              >Exp. date</label>
              <div>
                <div
                  id="ccExpiration"
                  class="braintree-hf"
                />
                <p
                  v-if="expirationError && expirationError.length"
                  class="payment__form-field-error"
                >
                  {{ expirationError }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="payment__form-row"
            :class="{ 'is-invalid': cvvError && cvvError.length }"
          >
            <div class="payment__form-col">
              <label
                class="payment__form-label"
                for="ccCvv"
              >CVV</label>
              <div>
                <div
                  id="ccCvv"
                  class="braintree-hf"
                />
                <p
                  v-if="cvvError && cvvError.length"
                  class="payment__form-field-error"
                >
                  {{ cvvError }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!updateCard && paymentMethods.length">
          <div
            v-for="method of paymentMethods"
            :key="method.details.lastFour"
            class="payment__form-row"
          >
            <div class="payment__form-col">
              <label class="payment__form-label">Card number</label>
              <div>
                <div>
                  **** **** **** {{ method.details.lastFour }}
                  <a
                    class="update-card"
                    @click="onUpdateCardClick"
                  >Update</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="isEuCountry">
          <div class="payment__form-col payment__invoice-checkbox">
            <input
              id="needInvoice"
              v-model="needInvoice"
              class="checkbox"
              type="checkbox"
            >
            <label for="needInvoice">I need an business invoice</label>
          </div>

          <template v-if="needInvoice">
            <div
              class="payment__form-row"
              :class="{ 'is-invalid': companyNameError }"
            >
              <div class="payment__form-col">
                <label
                  class="payment__form-label"
                  for="companyName"
                >Company name</label>
                <div>
                  <input
                    id="companyName"
                    v-model="form.companyName"
                    type="text"
                    placeholder="e.g. Apple Co."
                    @input="onInput"
                  >
                  <p
                    v-if="companyNameError"
                    class="payment__form-field-error"
                  >
                    {{ companyNameError }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="payment__form-row"
              :class="{ 'is-invalid': addressError }"
            >
              <div class="payment__form-col">
                <label
                  class="payment__form-label"
                  for="address"
                >Address</label>
                <div>
                  <input
                    id="address"
                    v-model="form.address"
                    type="text"
                    placeholder="e.g. 12 Folsom St."
                    @input="onInput"
                  >
                  <p
                    v-if="addressError"
                    class="payment__form-field-error"
                  >
                    {{ addressError }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="payment__form-row"
              :class="{ 'is-invalid': taxNumberError }"
            >
              <div class="payment__form-col">
                <label
                  class="payment__form-label"
                  for="vat"
                >EU Tax ID (optional)</label>
                <div>
                  <input
                    id="vat"
                    v-model="form.taxNumber"
                    type="text"
                    placeholder="e.g. 1231231"
                    @input="onInput"
                  >
                  <p
                    v-if="taxNumberError"
                    class="payment__form-field-error"
                  >
                    {{ taxNumberError }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <div
        v-if="isDowngraded"
        class="payment__form-terms payment__form-terms--info"
      >
        You’ll be charged ${{ originalPrice }} in the next billing cycle ({{ nextBillingCycle }})
      </div>
      <div
        v-if="isUpgraded"
        class="payment__form-terms"
      >
        ${{ originalPrice }} - ${{ discount }} (Remaining usage) = <strong>${{ price }}</strong>
      </div>

      <button
        class="btn payment__btn modal__submit"
        :class="{ 'btn--loading': buttonProcessing }"
        :disabled="!isCorrect || buttonProcessing"
        @click="onSubmit"
      >
        {{ buttonText }}
        <BaseSpinner v-if="buttonProcessing" />
      </button>

      <p
        v-if="error"
        class="payment__form-terms error"
      >
        {{ error }}
      </p>
      <span
        v-if="isEuCountry"
        class="payment__form-terms"
      >
        Includes Tax
      </span>
      <span class="payment__form-terms">
        By clicking Continue, you agree to our
        <a
          href="https://talebook.io/terms-of-service.html"
          target="_blank"
        >Terms</a> and
        <a
          href="https://talebook.io/privacy-policy.html"
          target="_blank"
        >Privacy policy</a>
      </span>
    </div>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import Autocomplete from 'components/common/autocomplete';
import Spinner from 'components/common/base-spinner';
import COUNTIRES from 'constants/countries';
import EU_COUNTRIES, { VAT_RATE_BY_COUNTRY } from 'constants/eu_countries';
import { PRIVACY_POLICY, TERMS } from 'constants/routes';
import { PLANS } from 'constants/user';
import { isDowngraded, isUpgraded } from 'helpers/subscription';
import { checkVAT, countries } from 'jsvat';
import NotificationsService from 'services/notifications.service';
import PaymentsService from 'services/payments.service';
import SubscriptionService from 'services/subscription.service';
import { titleize } from 'utils/text';
import { mapGetters } from 'vuex';

export default {
  name: 'ReceiptDataModal',
  components: {
    Autocomplete,
    Spinner,
  },
  props: {
    pricingModalData: Object,
  },
  data: () => ({
    countryList: COUNTIRES,
    needInvoice: false,
    loading: true,
    error: false,
    buttonProcessing: false,
    firstSubmit: true,
    firstFocus: [],
    ccNumberError: true,
    ccExpirationError: true,
    ccCvvError: true,
    ccNumberFirstFocus: false,
    ccExpirationFirstFocus: false,
    ccCvvFirstFocus: false,
    countryError: null,
    nameError: null,
    companyNameError: null,
    addressError: null,
    taxNumberError: null,
    updateCard: false,
    form: {
      companyName: '',
      firstName: '',
      taxNumber: '',
      address: '',
      country: '',
    },
    isDowngraded: null,
    isUpgraded: null,
  }),
  TERMS,
  PRIVACY_POLICY,
  computed: {
    ...mapGetters([
      'pricings',
      'invoice',
      'userPlan',
      'userPlanInfo',
      'paymentMethods',
      'workspaceCurrentId',
    ]),
    isCorrect() {
      const form = this.form;
      return (!this.isInvoicePossible || form.companyName && form.address) && form.country
        && (this.useSavedCard || form.firstName && !this.ccExpirationError && !this.ccNumberError && !this.ccCvvError)
        && (!this.needInvoice || !this.isEuCountry || !form.taxNumber || !this.taxNumberError);
    },
    plan() {
      if (!this.pricingModalData) return '';

      const plan = this.$store.getters.planByKindAndPeriod(this.pricingModalData.plan, this.pricingModalData.type);

      return plan && plan.name;
    },
    periodType() {
      if (!this.pricingModalData) return '';

      return titleize(this.pricingModalData.type);
    },
    planId() {
      const plan = this.$store.getters.planByKindAndPeriod(this.pricingModalData.plan, this.pricingModalData.type);
      if (!plan) return '';

      return plan.id;
    },
    isEuCountry() {
      return EU_COUNTRIES.includes(this.form.country);
    },
    planData() {
      return this.$store.getters.planByKindAndPeriod(this.pricingModalData.plan, this.pricingModalData.type);
    },
    originalPrice() {
      if (!this.pricingModalData) return 0;
      return this.planData.price;
    },
    price() {
      if (this.isDowngraded) return 0;
      if (!this.isUpgraded) return this.originalPrice;

      const originalPrice = this.originalPrice;
      const discount = this.discount;

      return Math.round((originalPrice - discount) * 100) / 100;
    },
    buttonText() {
      if (this.isDowngraded) {
        return 'Change Plan'
      }
      if (this.isUpgraded) {
        return 'Change Plan'
      }
      return `Pay $${this.price}`;
    },
    numberError() {
      if (!this.firstSubmit || !this.ccNumberFirstFocus) return null;
      return this.ccNumberError;
    },
    expirationError() {
      if (!this.firstSubmit || !this.ccExpirationFirstFocus) return null;
      return this.ccExpirationError;
    },
    cvvError() {
      if (!this.firstSubmit || !this.ccCvvFirstFocus) return null;
      return this.ccCvvError;
    },
    useSavedCard() {
      return !this.updateCard && this.paymentMethods.length;
    },
    nextBillingCycle() {
      if (!this.userPlan) return '';
      const daysLeft = this.userPlanInfo.subscriptionDaysLeft;
      const date = new Date(Date.now() + daysLeft * 86400000);
      return date.toLocaleDateString();
    },
    discount() {
      if (!this.isUpgraded || !this.userPlan) return 0;
      if (!this.pricingModalData) return 0;
      return this.planData.discountUsd;
    },
    isInvoicePossible() {
      return this.needInvoice && this.isEuCountry;
    },
  },
  watch: {
    invoice(invoice) {
      this.processInvoice(invoice);
    },
  },
  async mounted() {
    this.isDowngraded = this.getIsDowngraded();
    this.isUpgraded = this.getIsUpgraded();

    this.processInvoice(this.invoice);
    await PaymentsService.loaded;
    await PaymentsService.initHostedFields({
      onValidityChange: ({ error, name, live }) => {
        if (live && !this.firstSubmit) return;
        this[`${ name }Error`] = error;
      },
      onBlur: ({ name }) => {
        this[`${ name }FirstFocus`] = true;
      },
      onSubmit: () => {
        this.onSubmit();
      },
    });
    await this.$store.dispatch('paymentMethodsFetch');
    this.loading = false;
  },
  methods: {
    getIsDowngraded() {
      if (!this.pricingModalData || !this.userPlan) return false;
      return isDowngraded(this.userPlan, this.pricingModalData.plan);
    },
    getIsUpgraded() {
      if (!this.pricingModalData || !this.userPlan) return false;
      return isUpgraded(this.userPlan, this.pricingModalData.plan);
    },
    trimString(str) {
      if (!str || str === '‏‏‎ ‎') return '';
      return str.replace(/(^\W+)|(\W+$)/gim, '');
    },
    processInvoice(invoice) {
      this.form.address = this.trimString(invoice.address);
      this.form.companyName = this.trimString(invoice.companyName);
      this.form.taxNumber = this.trimString(invoice.taxNumber);
      this.form.firstName = this.trimString(invoice.firstName);
      this.form.country = invoice.country || '';
      this.needInvoice = !!(
        this.trimString(invoice.companyName) ||
        this.trimString(invoice.address)
      );
    },
    validateCountry() {
      if (!this.firstSubmit) return null;
      if (!this.form.country) return 'Choose country from the list';
      return null;
    },
    validateName() {
      if (!this.firstSubmit) return null;
      if (!this.form.firstName) return 'Name on card can\'t be empty';
      return null;
    },
    validateCompanyName() {
      if (!this.firstSubmit) return null;
      if (!this.form.companyName) return 'Company name can\'t be empty';
      return null;
    },
    validateAddress() {
      if (!this.firstSubmit) return null;
      if (!this.form.address) return 'Adress can\'t be empty';
      return null;
    },
    validateTaxNumber() {
      if (!this.firstSubmit || !this.form.taxNumber) return null;
      const country = this.form.country || '';

      if (country) {
        const vatCheck = checkVAT(this.form.taxNumber, countries);
        if (!vatCheck.isValid || (vatCheck.country && vatCheck.country.name !== country)) {
          return `Invalid EU Tax ID for ${ country }`;
        }
      }
      return null;
    },
    async onSubmit() {
      if (this.buttonProcessing) return;

      const data = this.form;
      this.error = null;
      this.firstSubmit = true;

      this.taxNumberError = this.validateTaxNumber();

      const successData = {
        ...data,
        ...(this.pricingModalData || {}),
        pricings: this.pricings || {},
        isDowngraded: this.isDowngraded,
        isUpgraded: this.isUpgraded,
        originalPrice: this.originalPrice,
        price: this.price,
        nextBillingCycle: this.nextBillingCycle,
      };

      const invoiceData = {
        firstName: this.form.firstName,
        companyName: this.isInvoicePossible ? this.form.companyName : '‏‏‎ ‎',
        address: this.isInvoicePossible ? this.form.address : '‏‏‎ ‎',
        country: this.form.country,
        taxNumber: this.isInvoicePossible ? this.form.taxNumber : '‏‏‎ ‎',
      };

      if (PLANS.includes(this.userPlan)) {
        if (!this.isCorrect) return;

        this.buttonProcessing = true;

        if (this.updateCard) {
          await PaymentsService.updatePaymentMethod({
            ...(this.pricingModalData || {}),
            receiptData: { ...data },
            planId: this.planId,
            cardholderName: this.form.firstName,
            disabled: !this.isCorrect,
            amount: parseFloat(this.price).toFixed(2),
            onValidityChange: ({ error, name }) => {
              this[`${ name }Error`] = error;
            },
            onCheckout: () => {
              this.buttonProcessing = true;
            },
            onSuccess: async (data, clear) => {
              NotificationsService.showInfo('Your payment method was upgraded!');

              const output = await this.changePlan(this.planId);
              await this.$store.dispatch('updateInvoice', invoiceData);
              await this.$store.dispatch('invoiceFetch');
              await this.$store.dispatch('paymentPlansFetch');

              if (output.error) {
                this.buttonProcessing = false;
              } else {
                this.$emit('completed', { ...output, ...successData});
              }

              clear();
            },
            onError: ({ error }) => {
              this.error = error || 'Something goes wrong, please try again later';
              this.firstSubmit = false;
              this.buttonProcessing = false;
            },
          });
        } else {
          const output = await this.changePlan(this.planId);
          await this.$store.dispatch('updateInvoice', invoiceData);
          await this.$store.dispatch('invoiceFetch');
          await this.$store.dispatch('paymentPlansFetch');

          if (output.error) {
            this.buttonProcessing = false;
          } else {
            this.$emit('completed', { ...output, ...successData});
          }
        }
        return;
      }

      PaymentsService.start({
        ...(this.pricingModalData || {}),
        workspaceId: this.workspaceCurrentId,
        receiptData: { ...data },
        planId: this.planId,
        cardholderName: this.form.firstName,
        disabled: !this.isCorrect,
        useCard: this.useSavedCard && this.paymentMethods[0],
        amount: parseFloat(this.price).toFixed(2),
        onValidityChange: ({ error, name }) => {
          this[`${ name }Error`] = error;
        },
        onCheckout: () => {
          this.buttonProcessing = true;
        },
        onSuccess: async (data, clear) => {
          this.$emit('completed', { ...data, ...successData });
          this.$store.dispatch('subscriptionFetch');
          this.$store.dispatch('invoiceFetch');
          await this.$store.dispatch('paymentPlansFetch');
          NotificationsService.showInfo('Your account was upgraded!');
          clear();
        },
        onError: ({ error }) => {
          this.error = error;
          this.firstSubmit = false;
          this.buttonProcessing = false;
        },
      });
    },

    async changePlan(plan) {
      try {
        const workspaceId = this.workspaceCurrentId;
        const output = await SubscriptionService.changePlan({ plan, workspaceId });
        this.$store.dispatch('subscriptionFetch');

        return output;
      } catch (e) {
        NotificationsService.showError(e.error || 'Unknown error occured while changing subscription plan. Try later!');
        return { error: e };
      }
    },

    hide() {
      this.$store.dispatch('modalHide');
    },
    onCountrySelected(country) {
      this.form.country = country;
      this.countryError = this.validateCountry();
      this.taxNumberError = this.validateTaxNumber();
    },
    onUpdateCardClick() {
      this.updateCard = true;
    },
    onInput(event) {
      const id = event.target.id;

      switch(id) {
        case 'ccName':
          this.nameError = this.validateName();
          break;
        case 'vat':
          this.taxNumberError = this.validateTaxNumber();
          break;
        case 'companyName':
          this.companyNameError = this.validateCompanyName();
          break;
        case 'address':
          this.addressError = this.validateAddress();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .update-card {
    cursor: pointer;
    margin-left: 20px;
    color: #53b7cb;
  }
</style>
