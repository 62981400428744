/* eslint-disable no-shadow */
import * as ROUTES from 'constants/routes';

const state = {
  breadcrumbs: null,
};

const actions = {
  setBreadcrumbs({ commit }, breadcrumbs = null) {
    commit('SET_BREADCRUMBS', breadcrumbs);
  },
};

const mutations = {
  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },
};

const getters = {
  getBreadcrumbs: (state) => state.breadcrumbs,
  routePath: (state, getters, root) => root.route.path | '',
  routeName: (state, getters, root) => root.route.name || '',
  routeParams: (state, getters, root) => root.route.params || {},
  routeQuery: (state, getters, root) => root.route.query,

  isRoute: (state, getters) => (name) => getters.routeName === name,

  projectCurrentId: (state, getters) => {
    if (!getters.routeParams.hasOwnProperty('project_id')) {
      return null;
    }

    return getters.routeParams.project_id;
  },
  projectCurrentMethodId: (state, getters) => {
    if(getters.isRoute(ROUTES.PROJECT_REPORT)) {
      return getters.projectReportId;
    }

    if(getters.isRoute(ROUTES.PROJECT_SHORT)) {
      return getters.routeShortcutToken;
    }

    if (!getters.routeParams.hasOwnProperty('method_id')) {
      return null;
    }

    return getters.routeParams.method_id;
  },
  routeTesterId: (state, getters) => {
    if (!getters.routeParams.hasOwnProperty('tester_id')) {
      return null;
    }

    return getters.routeParams.tester_id;
  },
  isPublicRoute: (state, getters) => {
    return getters.isRoute(ROUTES.PROJECT_SHORT);
  },
  isShortcutRoute: (state, getters) => {
    return getters.isRoute(ROUTES.PROJECT_SHORT);
  },
  routeShortcutToken: (state, getters) => {
    if (!getters.routeParams.hasOwnProperty('shortcut')) {
      return null;
    }

    return getters.routeParams.shortcut;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
