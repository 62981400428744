<template>
  <div class="addon-summary">
    <Sortable
      :data-transport="section.id"
      :value="section.customItemFields"
      class="sortable-cards"
      @dragend="onEnd"
    >
      <div
        v-for="item of fields"
        :key="$store.getters.idJoin(item.id)"
        class="addon-summary__drag"
        :data-id="item.id"
      >
        <div class="builder-draggable-field">
          <div
            v-if="canEdit"
            class="builder-draggable-field__drag sortable-handler"
          >
            <Icon name="drag" />
          </div>
          <SideDropdownMenu
            :menu="canEdit && [
              {
                text: 'Delete',
                action: () => $store.dispatch('builderRemoveSectionItem', { sectionId: section.id, id: item.id }),
              }
            ]"
            appearance="middle"
            @toggle="open = $event"
          >
            <div class="addon-summary__item">
              <BaseInput
                :can-edit="canEdit"
                :value="item.name"
                placeholder="Name..."
                class="base-input-2 addon-summary__name"
                @input="changeItemName(item.id, $event)"
              />
              <BaseInput
                :can-edit="canEdit"
                :value="item.value"
                :multiline="true"
                placeholder="Value..."
                class="base-input-2 base-input-2--textarea addon-summary__value"
                @input="changeItemValue(item.id, $event)"
              />
            </div>
          </SideDropdownMenu>
        </div>
      </div>
    </Sortable>
    <div
      v-if="canEdit"
      class="builder-new-input hide-print"
      :class="{ 'builder-new-input--active': isNewInputActive }"
    >
      <div class="enter-input">
        <BaseInput
          class="base-input-2"
          :value="newItemName"
          @input="newItemName = $event"
          :can-edit="canEdit"
          placeholder="Add new..."
          @change="createItem"
          @keyup.enter="createItem"
          @focus="onNewInputFocus"
          @blur="onNewInputBlur"
        />
        <div
          v-if="newItemName"
          class="enter-input-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { byPosition } from 'helpers/sort';

export default {
  name: 'ProjectMethodCustom',
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      newItemName: '',
      newInputFocus: false,
    };
  },
  computed: {
    fields() {
      return byPosition(this.section.customItemFields);
    },
    isNewInputActive() {
      return this.newInputFocus || this.newItemName;
    },
  },
  methods: {
    onEnd(value) {
      const { index, area, ids } = value;
      const transport = area.getAttribute('data-transport');
      const id = ids[0];

      this.$store.dispatch('builderMoveSectionItem', {
        sectionId: this.section.id,
        id,
        newSectionId: transport,
        newIndex: index,
      });
    },
    createItem() {
      if (!this.newItemName.trim()) {
        this.newItemName = '';
        return;
      }
      this.$store.dispatch('builderAddSectionItem', {
        id: this.section.id,
        data: {
          name: this.newItemName,
          value: '',
        },
      });
      this.newItemName = '';
    },
    changeItemName(id, name) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: { name } });
    },
    changeItemValue(id, value) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: { value } });
    },
    onNewInputFocus() {
      this.newInputFocus = true;
    },
    onNewInputBlur() {
      this.newInputFocus = false;
    },
    isEmpty(data) {
      return !(data.customItemFields && data.customItemFields.length);
    },
  },
};
</script>
