<template>
  <span
    class="base-saving"
    :class="classes"
  >
    {{ isSaving ? 'Saving...' : 'Changes saved' }}
  </span>
</template>

<script>
export default {
  name: 'BaseSaving',
  props: {
    isSaving: { default: true, type: Boolean },
  },
  computed: {
    classes() {
      return {
        saving: this.isSaving,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-saving {
  font-size: 1.2rem;
  letter-spacing: 0.1px;
  color: $color-grey-tundora;
  font-weight: 400;
  position: relative;
  visibility: hidden;
  opacity: 0;
  min-width: 53px;
  transition: all 0.35s ease 1s;
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  padding-left: 26px;
  padding-top: 2px;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: image_url('svg/save.svg') no-repeat 0 0;
    background-size: 100%;
    margin-right: 4px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  &.saving {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}
</style>
