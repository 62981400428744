<template>
  <div v-bind="$attrs">
    <BaseTooltip
      v-if="canEdit"
      placement="top"
      :text="tooltip"
    >
      <div class="counted-icon">
        <div
          v-if="tags.length === 0"
          class="counted-icon__ico tags-avatars__list tags-avatars__list--empty"
        >
          <Icon name="tag-outline" />
        </div>
        <div
          v-else
          class="counted-icon__ico tags-avatars__list"
        >
          <span
            v-for="tag in tags.slice(0, MAX_AVATARS)"
            :key="tag.id"
            class="tags-avatars__list-item"
            :style="{'background-color': getTagColor(tag.id)}"
          />
        </div>
        <span
          v-if="shouldShowCount"
          class="counted-icon__count"
        > {{ tagsCount }} </span>
      </div>
    </BaseTooltip>
    <div
      v-else-if="tags.length !== 0"
      class="tags-avatars__list"
    >
      <span
        v-for="tag in tags.slice(0, MAX_AVATARS)"
        :key="tag.id"
        class="tags-avatars__list-item"
        :style="{'background-color': getTagColor(tag.id)}"
      />
      <span :class="countClasses"> {{ tagsCount }} </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const MAX_AVATARS = 5;

export default {
  name: 'TagsAvatars',
  props: {
    tags: {
      required: true,
      default: () => [],
    },
    canEdit: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  computed: {
    countClasses() {
      return [
        'tags-avatars__count',
        `tags-avatars__count--${ this.tagsCount.length }`,
      ];
    },
    tooltip() {
      if (this.tags.length === 0) {
        return 'Add tag';
      }
      const tagsNames = this.tags.map((tag) => tag.name).join(', ');
      if (tagsNames.length <= 16) {
        return tagsNames;
      }
      return `${ this.tags.length } tags`;
    },
    tagsCount() {
      if (this.tags.length <= MAX_AVATARS) return '';
      else if (this.tags.length >= 999) {
        return '999+';
      }
      return this.tags.length.toString();
    },
    MAX_AVATARS() {
      return MAX_AVATARS;
    },
    shouldShowCount() {
      return this.tags.length > 5;
    },
  },
  methods: {
    getTagColor(id) {
      const tag = this.$store.getters.tagById(id);
      if (!tag) return '';
      return tag.color;
    },
  },
};
</script>

<style lang="scss">
.tags-avatars {
  margin: 0;
  opacity: 1;
  min-width: 37px;
  min-height: 26px;
  display: inline-block;
  vertical-align: top;

  &__count {
    position: absolute;
    bottom: 5px;
    right: 4px;
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: right;
    color: #171717;
    background-color: white;
    padding-top: 3px;
    &--1 {
      width: 11px;
    }
    &--2 {
      width: 16px;
    }
    &--3 {
      width: 22px;
    }
    &--4 {
      width: 28px;
    }
    &:hover {
      background-color: #F8F9FA;
    }
  }

  &__list-item {
    width: 4px;
    height: 24px;
    border-radius: 2.5px;
    margin: 0 1px;
  }

  &__list {
    $list: &;
    padding: 7px 3px;
    width: 38px;
    height: 36px;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.1px;
    border: 1px dashed transparent;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 1;
    background-size: 100% auto;
    overflow: visible;
    border-radius: 3px;
    position: relative;
    vertical-align: top;
    transition: all 150ms;

    &:hover {
      background-color: #F8F9FA;
    }

    &:active {
      background-color: #F0F2F5;
    }

    &--empty {
      background-color: rgba(0,0,0,0);
      box-shadow: 0 0 0 transparent;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #F8F9FA;
      }

      &:active {
        background-color: #F0F2F5;
      }
    }
  }
}
</style>
