import * as ApiService from 'services/api.service';
import Store from 'stores';

class TeamService {
  constructor(store) {
    this.$store = store;
  }

  fetchAll(workspace) {
    return ApiService.get(this.getUrl(workspace));
  }

  invite(workspace, id, { email }) {
    return ApiService.post(this.getUrl(workspace, id), { email });
  }

  remove(workspace, projectId, id) {
    return ApiService.del(`${this.getUrl(workspace, projectId)}/${id}`);
  }

  transferOwnership(workspace, projectId, id) {
    return ApiService.put(`${this.getUrl(workspace, projectId)}/${id}`, {
      role: 'owner',
    });
  }

  sendReminder(id) {
    return Promise.resolve(id);
    // return ApiService.post(`${ this.url }/${ id }/remind`);
  }

  changeRole({ workspace, projectId, id, role }) {
    return ApiService.put(`${this.getUrl(workspace, projectId)}/${id}`, {
      role,
    });
  }

  addTeamMember({ workspaceId, projectId, userId }) {
    return ApiService.post(`api/workspaces/${workspaceId}/projects/${projectId}/team_members`, {
      teamMember: {
          userId,
      },
    });
  }

  removeTeamMember({ workspaceId, projectId, userId, memberId }) {
    return ApiService.del(`api/workspaces/${workspaceId}/projects/${projectId}/team_members/${memberId}`, {
      teamMember: {
          userId,
      },
    });
  }

  getUrl(workspace) {
    return `api/workspaces/${workspace}/team_members/`;
  }
}

export default new TeamService(Store);
