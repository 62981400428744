const model = {
  address: '',
  city: '',
  companyName: '',
  country: '',
  firstName: '',
  lastName: '',
  postCode: '',
  taxNumber: '',
  type: '',
};

export const parser = (invoice) => {
  const result = {
    ...model,
    ...invoice,
  };

  return result;
};

export default model;
