<template>
  <div
    class="p-creator"
    :class="classes"
  >
    <div
      ref="window"
      class="p-creator__window"
    >
      <div class="p-creator__content">
        <Icon
          class="p-creator__logotype"
          name="logotype"
        />
        <Avatar
          class="p-creator__avatar"
          :name="userName"
          mode="email"
        />
        <span class="p-creator__placeholder-dot" />
        <span class="p-creator__project-name">{{ projectName }}</span>

        <img
          class="p-creator__hand-3"
          src="/static/img/onboarding/hand-3-b.png"
        >

        <div class="p-creator__team">
          <Avatar
            :name="userName"
            mode="email"
          />
          <Avatar
            v-for="member of team"
            :key="member"
            :name="member"
            :mode="isEmailValid(member) ? 'email' : 'guest'"
          />
          <Avatar
            v-if="!isMembersFull"
            name=""
            mode="blank"
          />
        </div>

        <template v-for="phase of phases" :key="phase.id">
          <span
            :class="`p-creator__phase-name p-creator__phase-name--${ phase.type }`"
          >
            <span>{{ phase.name }}</span>
          </span>
          <div
            v-for="(method, mi) of phase.methods"
            :key="method.id"
            class="p-creator__item"
            :class="{
              [`p-creator__item--${ phase.type }`]: true,
              'p-creator__item--first': mi === 0,
            }"
            :data-enabled="isMethodEnabled(method.id)"
          >
            <span class="p-creator__item-name">{{ method.name }}</span>
            <span class="p-creator__item-icon">
              <Icon :name="method.icon" />
              <span
                v-if="mi === 0"
                class="p-creator__item-phase-name"
              >{{ phase.name.replace(/.+ \- /, '') }}</span>
            </span>
          </div>
        </template>

        <img
          class="p-creator__hand-1"
          src="/static/img/onboarding/hand-1.png"
        >
        <img
          class="p-creator__hand-2"
          src="/static/img/onboarding/hand-2.png"
        >
        <img
          class="p-creator__hand-3"
          src="/static/img/onboarding/hand-3.png"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { avatar, getColor, gravatar } from 'helpers/avatar';
import { validate } from 'helpers/email';

import Avatar from './avatar';
import PHASES from './phases';

export default {
  name: 'ProjectCreator',
  components: {
    Avatar,
  },
  props: {
    userName: String,
    projectName: String,
    selectedMethods: {
      type: Array,
      default: () => ([]),
    },
    team: Array,
    step: {
      type: String,
      default: 'name',
    },
  },
  data: () => ({
    phases: PHASES,
  }),
  computed: {
    classes() {
      const classes = {
        'p-creator--has-name': this.projectName !== 'Research Project',
      };

      classes[`p-creator--step-${ this.step }`] = true;

      return classes;
    },
    isMembersFull() {
      return this.team && this.team.length === 3;
    },
  },
  watch: {
    step(step) {
      this.$nextTick(() => {
        this.refreshPositions();
      });
    },
    selectedMethods() {
      this.$nextTick(() => {
        this.refreshPositions();
      });
    },
  },
  mounted() {
    this.refreshPositions();
  },
  beforeUnmount() {
  },
  methods: {
    refreshPositions() {
      const { step } = this;
      const margin = step === 'team' ? 1.6 : 0;

      if (step !== 'name' && step !== 'about' && step !== 'process') {
        let all = Array.prototype.slice.call(this.$refs.window.querySelectorAll('.p-creator__phase-name, .p-creator__item'));

        all = all.filter((item) => {
          return item.dataset.enabled || item.classList.contains('p-creator__phase-name');
        });

        let top = 118;

        all.forEach((item, i) => {
          let itemHeight = 29;

          if (item.classList.contains('p-creator__phase-name')) {
            itemHeight = 38;
          }

          item.style.transform = `translateY(${ top / 10 + margin }em)`;
          top += itemHeight;
        });
      } else {
        const all = this.$refs.window.querySelectorAll('.p-creator__phase-name, .p-creator__item');
        all.forEach((item) => item.style.transform = '');
      }
    },
    isMethodEnabled(methodId) {
      return this.selectedMethods.indexOf(methodId) > -1;
    },
    isEmailValid(email) {
      return validate(email);
    },
  },
};
</script>
