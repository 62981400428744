import { parser } from 'models/user';
import * as ApiService from 'services/api.service';
import LoginService from 'services/login.service';

class UserService {
  async getProfile() {
    const result = await ApiService.get('api/profile');
    return parser(result);
  }

  connect(provider) {
    return LoginService.login(provider);
  }

  async disconnect(provider) {
    const result = await ApiService.del(`api/profile/providers/${provider}`);
    return parser(result);
  }

  async change(data) {
    const result = await ApiService.put('api/profile', data);
    return parser(result);
  }
}

export default new UserService();
