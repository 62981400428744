<template>
  <div
    class="wrap base-spinner"
    :class="{ fullHeight, [appearance]: true }"
  >
    <svg
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
      viewBox="0 0 26 26"
      class="loader"
    >
      <circle
        cx="50%"
        cy="50%"
        r="12"
        class="static-circle"
      />
      <circle
        cx="50%"
        cy="50%"
        r="12"
        class="circle"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BaseSpinner',
  props: {
    fullHeight: {
      type: Boolean,
      default: false,
    },
    appearance: String,
  },
  computed: {
    classes() {
      return {
        'small-wrapper': this.small,
        'base-container--with-padding': this.withPadding,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'styles/sizes';
  $radius: 12px;
  $circuit: 3.14159265359 * $radius * 2;
  $start: (1 - 0.06) * $circuit;
  $end: (1 - 0.9) * $circuit;

  @keyframes stroke-animation {
    0% { stroke-dashoffset: $start; transform: rotate(0); }
    12.5% { stroke-dashoffset: $end; transform: rotate(0); }
    12.5001% { stroke-dashoffset: $end; transform: rotateX(180deg) rotate(72.5deg); }
    25% { stroke-dashoffset: $start; transform: rotateX(180deg) rotate(72.5deg); }

    25.0001% { stroke-dashoffset: $start; transform: rotate(270deg); }
    37.5% { stroke-dashoffset: $end; transform: rotate(270deg); }
    37.5001% { stroke-dashoffset: $end; transform: rotateX(180deg) rotate(161.5deg); }
    50% { stroke-dashoffset: $start; transform: rotateX(180deg) rotate(161.5deg); }

    50.0001% { stroke-dashoffset: $start; transform: rotate(180deg); }
    62.5% { stroke-dashoffset: $end; transform: rotate(180deg); }
    62.5001% { stroke-dashoffset: $end; transform: rotateX(180deg) rotate(251.5deg); }
    75% { stroke-dashoffset: $start; transform: rotateX(180deg) rotate(251.5deg); }

    75.0001% { stroke-dashoffset: $start; transform: rotate(90deg); }
    87.5% { stroke-dashoffset: $end; transform: rotate(90deg); }
    87.5001% { stroke-dashoffset: $end; transform: rotateX(180deg) rotate(341.5deg); }
    100% { stroke-dashoffset: $start; transform: rotateX(180deg) rotate(341.5deg); }
  }
  @keyframes rotate-animation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .loader {
    animation: rotate-animation 2s linear infinite;
    height: 26px;
    width: 26px;
  }
  .static-circle {
    fill: transparent;
    stroke: #e6edf0;
    stroke-width: 2px;
  }
  .circle {
    fill: transparent;
    stroke: #86909a;
    stroke-dasharray: 75.3982236862px;
    stroke-width: 2px;
    animation-name: stroke-animation;
    transition-property: stroke;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(.35,0,.25,1);
    animation-iteration-count: infinite;
    transform-origin: center;
  }

  :global(#app) > .wrap,
  .fullHeight {
    height: calc(100vh - 60px);
    box-sizing: border-box;
  }

  .sm {
    .loader {
      width: 20px;
      height: 20px;
    }
  }
</style>
