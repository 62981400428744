/**
 * Truncate string to chosen length
 * @param  {String} str
 * @param  {Number} length
 * @param  {String} brake on separator
 * @return {String}
 */
export function truncate(str, length, separator) {
  if (str && str.length > length) {
    const shortStr = str.substring(0, length);

    if (separator) {
      return shortStr.replace(
        new RegExp(`${separator}[^${separator}]*?$`, 'i'),
        '...'
      );
    }
    return `${shortStr.trim()}...`;
  }
  return str;
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function pluralize(num, word, plural = word + 's') {
  return [1, -1].includes(Number(num)) ? word : plural;
}
