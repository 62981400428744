const HISTORY_DELAY = 1000;
let groupEnabled = false;
let groupId = 0;

const actions = {
  projectMethodChange: {
    stack: true,
    save({ store, payload, addHistoryPoint }) {
      const { id, data } = payload;
      const item = store.getters.projectMethodById(id);

      if (!item) {
        return;
      }

      const next = { ...data };
      const prev = Object.fromEntries(Object.keys(next).map((k) => [k, item[k]]));

      if (next.tags) {
        next.tags = next.tagList = [...next.tags.map((t) => ({ id: t.id }))];
      }

      if (prev.tags) {
        prev.tags = prev.tagList = [...prev.tags.map((t) => ({ id: t.id }))];
      }

      addHistoryPoint({
        item,
        prev,
        next,
      })
    },
    undo({ store, prev, item: { id, localId } }) {
      const item = store.getters.projectMethodById(localId || id);

      if (!item) {
        return;
      }

      store.dispatch('projectMethodChange', {
        id: item.id,
        data: prev,
        disableHistory: true,
      });
    },
    redo({ store, next, item: { id, localId } }) {
      const item = store.getters.projectMethodById(localId || id);

      store.dispatch('projectMethodChange', {
        id: item.id,
        data: next,
        disableHistory: true,
      });
    },
  },

  /**
   * Action: methodSectionUpdate
   */
  methodSectionUpdate: {
    stack: true,
    save({ store, payload: { id, data }, addHistoryPoint }) {
      const item = store.getters.sectionById(id);
      const next = { ...data };
      const prev = Object.fromEntries(Object.keys(next).map((k) => [k, item[k]]));

      if (item) {
        const sectionEl = document.querySelector(`.builder-section-component[data-id="${id}"]`);
        
        if (sectionEl && sectionEl.component && sectionEl.component.onHistorySave) {
          sectionEl.component.onHistorySave(prev, next);
        }
      }

      addHistoryPoint({
        item,
        prev,
        next,
      })
    },
    undo({ store, prev, item: { id, localId, designMethodId } }) {
      const item = store.getters.sectionById(localId || id);

      if (item) {
        const sectionEl = document.querySelector(`.builder-section-component[data-id="${id}"]`);
        
        if (sectionEl && sectionEl.component && sectionEl.component.onHistoryChange) {
          sectionEl.component.onHistoryChange(prev);
        }
      }

      store.commit('METHOD_SECTION_UPDATE', {
        id,
        data: prev,
      });
      store.dispatch('send', { action: 'custom_method/update_item', data: { designMethodId, id, ...prev } });
    },
    redo({ store, next, item: { id, localId, designMethodId } }) {
      const item = store.getters.sectionById(localId || id);

      if (item) {
        const sectionEl = document.querySelector(`.builder-section-component[data-id="${id}"]`);
        
        if (sectionEl && sectionEl.component && sectionEl.component.onHistoryChange) {
          sectionEl.component.onHistoryChange(next);
        }
      }

      store.commit('METHOD_SECTION_UPDATE', {
        id,
        data: next,
      });
      store.dispatch('send', { action: 'custom_method/update_item', data: { designMethodId, id, ...next } });
    },
  },
  /**
   * Action: builderCreateSection
   */
  builderCreateSection: {
    stack: false,
    save({ store, payload, addHistoryPoint }) {
      const data = {
        ...payload,
      }

      addHistoryPoint({
        prev: data,
        next: data,
      })
    },
    undo({ store, prev }) {
      store.dispatch('builderRemoveSection', {
        id: prev.data.id,
        disableHistory: true,
      })
    },
    redo({ store, next }) {
      store.dispatch('builderCreateSection', {
        ...next,
        disableHistory: true,
      });
    },
  },
  /**
   * Action: builderRemoveSection
   */
  builderRemoveSection: {
    stack: false,
    save({ store, payload, addHistoryPoint }) {
      const itemData = store.getters.sectionById(payload.id);

      addHistoryPoint({
        prev: { ...itemData },
        next: { ...itemData },
      })
    },
    undo({ store, prev }) {
      store.dispatch('builderCreateSection', {
        methodId: prev.designMethodId,
        data: {
          ...prev,
        },
        disableHistory: true,
      })
    },
    redo({ store, next }) {
      store.dispatch('builderRemoveSection', {
        id: next.id,
        disableHistory: true,
      });
    },
  },
  /**
   * Action: builderRemoveSelectedSections
   */
  builderRemoveSelectedSections: {
    stack: false,
    save({ store, payload, addHistoryPoint }) {
      const ids = store.getters.selectedSections;
      const data = ids.map((id) => ({
        ...store.getters.sectionById(id),
      }));

      addHistoryPoint({
        prev: data,
        next: ids,
      })
    },
    undo({ store, prev }) {
      prev.forEach((section, i) => {
        const data = {
          ...section,
        };

        store.dispatch('builderCreateSection', {
          type: section.type,
          data,
          index: section.index ,
          methodId: section.designMethodId,
          disableHistory: true,
        });
      });
    },
    redo({ store, next }) {
      store.commit('METHOD_SECTIONS_SELECT_IDS', {ids: next});
      store.dispatch('builderRemoveSelectedSections', { disableHistory: true });
    },
  },
  /**
   * Action: builderRemoveSelectedSections
   */
  builderMoveSections: {
    stack: false,
    save({ store, payload: { ids, index }, addHistoryPoint }) {
      const prev = ids.map((id) => ({
        id,
        index: store.getters.sectionIndexById(id),
      }));

      const next = {
        ids,
        index,
      };

      addHistoryPoint({
        prev,
        next,
      })
    },
    undo({ store, prev }) {
      prev.forEach(({ id, index }) => {
        store.dispatch('builderMoveSection', {
          id,
          index,
          disableHistory: true,
        })
      })
    },
    redo({ store, next }) {
      store.dispatch('builderMoveSections', {
        ...next,
        disableHistory: true,
      });
    },
  }, 
  /**
   * Action: builderCreateSection
   */
  builderAddSectionItem: {
    stack: false,
    save({ store, payload: { id, data }, addHistoryPoint }) {
      addHistoryPoint({
        sectionId: id,
        prev: data,
        next: data,
      })
    },
    undo({ store, prev, sectionId }) {
      store.dispatch('builderRemoveSectionItem', {
        sectionId,
        id: prev.id,
        disableHistory: true,
      })
    },
    redo({ store, next, sectionId }) {
      store.dispatch('builderAddSectionItem', {
        id: sectionId,
        data: next,
        disableHistory: true,
      });
    },
  },
  /**
   * Action: builderCreateSection
   */
  builderRemoveSectionItem: {
    stack: false,
    save({ store, payload: { sectionId, id }, addHistoryPoint }) {
      const data = store.getters.sectionItemById(id);

      addHistoryPoint({
        sectionId: id,
        prev: { data, sectionId },
        next: { sectionId, id },
      })
    },
    undo({ store, prev }) {
      store.dispatch('builderAddSectionItem', {
        id: prev.sectionId,
        data: prev.data,
        disableHistory: true,
      });
    },
    redo({ store, next }) {
      store.dispatch('builderRemoveSectionItem', {
        ...next,
        disableHistory: true,
      });
    },
  },
}

const undoPlugin = (store) => {
  let currentType = '';
  let currentIndex = -1;
  let history = [];

  const addHistoryPoint = (data) => {
    history = history.slice(0, currentIndex + 1);
    const last = history[history.length - 1];
    const time = Date.now();
    const group = groupEnabled && groupId;

    if (actions[currentType].stack && last && (last.time + HISTORY_DELAY >= time) && last.type === currentType && last.group === group) {
      last.next = { ...last.next, ...data.next };
      last.prev = { ...data.prev, ...last.prev };
    } else {
      history.push({
        ...data,
        type: currentType,
        time,
        group,
      });
    }

    currentIndex = history.length - 1;
  }

  const undo = () => {
    let point = history[currentIndex];

    if (!point) return;

    if (point.group) {
      const mainGroup = point.group;

      while (point && point.group && mainGroup === point.group) {
        actions[point.type].undo({ ...point, store });
        currentIndex--;
        point = history[currentIndex];
      }
    } else {
      actions[point.type].undo({ ...point, store });
      currentIndex--;
    }
  }

  const redo = () => {
    let point = history[currentIndex + 1];

    if (!point) return;

    if (point.group) {
      const mainGroup = point.group;

      while (point && point.group && mainGroup === point.group) {
        actions[point.type].redo({ ...point, store });
        currentIndex++;
        point = history[currentIndex];
      }
    } else {
      actions[point.type].redo({ ...point, store });
      currentIndex++;
    }
  }

  document.addEventListener('keydown', (e) => {
    if ((e.metaKey || e.ctrlKey) && !e.shiftKey && e.key === 'z') {
      undo();
      e.preventDefault();
    }
    if ((e.metaKey || e.ctrlKey) && ((e.key === 'y') || (e.key === 'z' && e.shiftKey))) {
      redo();
      e.preventDefault();
    }
  })

  store.subscribeAction({
    before({ type, payload }) {
      if (payload && payload.disableHistory) {
        return;
      }
  
      if (actions[type] && actions[type].save) {
        currentType = type;
        actions[type].save({ store, payload, addHistoryPoint })
      }
    },
  })
}

export const history = {
  startGroup() {
    groupId++;
    groupEnabled = true;
  },
  stopGroup() {
    groupEnabled = false;
  },
};

export default undoPlugin;