// 'to' and 'from' based on: https://github.com/sindresorhus/decamelize and https://github.com/sindresorhus/camelcase

import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

const SEPARATOR = '_';

export const to = (str) => {
  if (typeof str !== 'string') {
    return str;
  }

  return str
    .replace(/^[_. ]+/, '')
    .toLowerCase()
    .replace(/[_. ]+(\w|$)/g, (m, p1) => p1.toUpperCase());
};

export const from = (str) => {
  if (typeof str !== 'string') {
    return str;
  }

  return str
    .replace(/([a-z\d])([A-Z])/g, `$1${SEPARATOR}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${SEPARATOR}$2`)
    .toLowerCase();
};

export const objectTo = (data) => {
  let result = {};
  if (isArray(data)) {
    result = [];
  }

  forEach(data, (v, key) => {
    let value = v;
    if (isPlainObject(value) || isArray(value)) {
      value = objectTo(value);
    }

    result[to(key)] = value;
  });

  return result;
};

export const objectFrom = (data) => {
  let result = {};
  if (isArray(data)) {
    result = [];
  }

  forEach(data, (v, key) => {
    let value = v;
    if (isPlainObject(value) || isArray(value)) {
      value = objectFrom(value);
    }

    result[from(key)] = value;
  });

  return result;
};
