<template>
  <div class="addon-charts">
    <SortableAnimatedList
      :transport="section.id"
      :value="section.customItemFields"
      class="sortable-cards"
      @dragend="onEnd"
    >
      <div
        v-for="item of fields"
        :key="$store.getters.idJoin(item.id)"
        class="addon-charts__drag"
        :data-id="item.id"
      >
        <div class="builder-draggable-field">
          <div
            v-if="canEdit"
            class="builder-draggable-field__drag sortable-handler"
          >
            <Icon name="drag" />
          </div>
          <ChartItem
            :can-edit="canEdit"
            :section="section"
            :item="item"
          />
        </div>
      </div>
    </SortableAnimatedList>
    <div
      v-if="canEdit"
      class="addon-charts__item builder-new-input"
      :class="{ 'builder-new-input--active': isNewInputActive }"
    >
      <div class="enter-input">
        <BaseInput
          class="base-input-2"
          :value="newItemName"
          @input="newItemName = $event"
          :can-edit="canEdit"
          placeholder="Add new..."
          @change="createItem"
          @keyup.enter="createItem"
          @focus="onNewInputFocus"
          @blur="onNewInputBlur"
        />
        <div
          v-if="newItemName"
          class="enter-input-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TAG_COLORS } from 'constants/tag-colors';
import { byPosition } from 'helpers/sort';
import sample from 'lodash/sample';

import ChartItem from './chart-item';

export default {
  name: 'ProjectMethodCustom',
  components: {
    ChartItem,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      newItemName: '',
      newInputFocus: false,
    };
  },
  computed: {
    draggableOptions() {
      return {
        disabled: !this.canEdit,
        handle: '.builder-draggable-field__drag',
        ghostClass: 'drag-ghost',
        fallbackClass: 'drag-fallback',
        animation: 150,
        direction: 'vertical',
        fallbackOnBody: true,
        forceFallback: true,
        touchStartThreshold: 3,
        fallbackTolerance: 2,
        delay: 200,
        delayOnTouchOnly: true,
        emptyInsertThreshold: 5,
        group: {
          name: 'builder-charts',
          pull: true,
        },
      };
    },
    isNewInputActive() {
      return this.newInputFocus || this.newItemName;
    },
    fields() {
      return byPosition(this.section.customItemFields);
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async onEnd(value) {
      var { index } = value;
      const transport = value.area.getAttribute('data-transport');
      const id = value.ids[0];

      this.$store.dispatch('builderMoveSectionItem', {
        sectionId: this.section.id,
        id,
        newSectionId: transport,
        newIndex: index,
      });

      this.$store.dispatch('uiSetCardDragging', false);
    },
    createItem() {
      if (!this.newItemName.trim()) {
        this.newItemName = '';
        return;
      }
      this.$store.dispatch('builderAddSectionItem', {
        id: this.section.id,
        data: {
          name: this.newItemName,
          value: Math.round(Math.random()*100).toString(),
          color: sample(TAG_COLORS),
        },
      });
      this.newItemName = '';
    },
    onNewInputFocus() {
      this.newInputFocus = true;
    },
    onNewInputBlur() {
      this.newInputFocus = false;
    },
    isEmpty(data) {
      return !(data.customItemFields && data.customItemFields.length);
    },
  },
};
</script>
