<template>
  <BaseTooltip
    v-if="canEdit"
    ref="tooltip"
    behaviour="click"
    appearance="light-box"
    placement="bottom"
    class="tags-avatars-tooltip"
    @toggle="onToggle"
  >
    <TagsAvatars
      :tags="tags"
    />
    <template v-slot:content>
      <TagsSelector
        ref="selector"
        v-model="answerTags"
        @tagSelect="$emit('tagSelect', $event)"
        @tagUnselect="$emit('tagUnselect', $event)"
        @exit="onSelectorExit"
        @modal-show="onModalShow"
      />
    </template>
  </BaseTooltip>
  <TagsAvatars
    v-else
    :tags="tags"
    :can-edit="false"
  />
</template>

<script>
import TagsAvatars from './tags-avatars';
import TagsSelector from './tags-selector';

export default {
  name: 'TagsAvatarsSelector',
  components: {
    TagsAvatars,
    TagsSelector,
  },
  props: {
    tags: {
      required: false,
      default: () => [],
    },
    canEdit: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      myTags: [],
    };
  },
  computed: {
    answerTags: {
      get() {
        return this.tags;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onSelectorExit() {
      this.$refs.tooltip && this.$refs.tooltip.hide();
    },
    onToggle(show) {
      this.$nextTick(() => {
        if (show) {
          this.$refs.selector && this.$refs.selector.triggerShow();
        } else {
          this.$refs.selector && this.$refs.selector.hide();
        }
      });

      this.$emit('toggle', show);
    },
    onModalShow() {
      this.$refs.tooltip && this.$refs.tooltip.hide();
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'styles/sizes';
.tags-avatars-tooltip :global(.tooltip__tip-position),
.tags-avatars-tooltip :global(.tooltip__tip) {
  @include xsMax {
    @include xsMax {
      transition: opacity 0.3s !important;
      will-change: opacity !important;
      transform: none !important;
    }
  }
}
</style>
