<template>
  <SideDropdownMenu
    appearance="outside-middle"
    :menu="parsedSideMenu"
  >
    <div
      class="base-card"
      :class="{
        'base-card--canedit': editPermission,
        'base-card--active': active,
        'base-card--editting': editting,
        'base-card--sentence': viewOnly,
        'base-card--disabled': disabled,
        'base-card--empty-tags': !hasTags && (!hasResponders || !showResponders),
        'base-card--option': surveyMode,
        'base-card--selected': isSelected,
      }"
    >
      <DropdownMenu
        :visible="editting"
        :menu="editMenu"
        appearance="dark"
        placement="right-start, bottom-end"
        behaviour="none"
        @action="onAction"
        @toggle="editMenuToggle"
      >
        <div class="base-card__dropdown-positioner" />
      </DropdownMenu>
      <div
        class="base-card__container"
        :tabindex="surveyMode ? 0 : -1"
        @click="onClick"
        @keydown.space.enter="onClick"
      >
        <div
          v-if="!surveyMode"
          class="base-card__body"
        >
          <ul
            v-if="shouldDisplayCallableBreadcrums"
            class="base-card__breadcrumbs"
          >
            <li>{{ callableBreadcrumbs }}</li>
          </ul>
          <ul
            v-else-if="shouldDisplayBreadcrumbs"
            class="base-card__breadcrumbs"
          >
            <BaseTooltip class="base-card__lock" placement="top" text="You don’t have access to this project" v-if="!userHasAccessToProject">
              <Icon name="lock" />
            </BaseTooltip>
            <li v-if="projectName">
              {{ projectName }}
            </li>
            <li v-if="methodTypeName">
              {{ methodTypeName }}
            </li>
            <li v-if="parentName">
              {{ parentName }}
            </li>
            <li v-if="parentContent">
              {{ parentContent }}
            </li>
          </ul>
          <span style="display:none;">{{ cardText }}</span>
          <template v-if="isSentence">
            <div
              class="base-card__sentence"
              v-html="sentenceHtml"
            />
            <Editor
              v-show="false"
              ref="editor"
              :show-tags="false"
              :value="sentenceText"
              :can-edit="editable"
              :tags-list="tags"
            />
          </template>
          <template v-else-if="viewOnly">
            <div class="base-card__sentence">
              {{ cardText }}
            </div>
          </template>
          <BaseTextarea
            v-else
            ref="input"
            :value="cardText"
            @input="cardText = $event"
            type="text"
            class="base-card__input"
            :tabindex="editable && cardTabindex"
            :can-edit="editable"
            @blur="onInputBlur"
            @focus="onInputFocus"
            @keydown.enter.prevent.stop="onInputEnter"
          />
          <div
            v-if="!isSentence"
            class="base-card__indicators"
          >
            <div v-if="hasTextInside">
              <img
                src="/static/img/ic-article.svg"
                alt="has text inside"
              >
            </div>
            <div v-if="imagesInPageCounter">
              <img
                src="/static/img/ic-image.svg"
                alt="has images inside"
              >
              <span> {{ imagesInPageCounter }} </span>
            </div>
          </div>
          <div v-if="data.cardType === 'scale_card' || cardMode === 'choice' || cardMode === 'check'">
            <Scale
              :show-percent="true"
              :max="data.scaleMax"
              :value="data.scaleValue"
              :color="data.color"
            />
          </div>
        </div>
        <div
          v-else
          class="base-card__option"
          :class="{
            'base-card__option--check': cardMode === 'check',
          }"
        >
          {{ cardText }}
        </div>
        <div
          v-if="editPermission || isSentence"
          class="base-card__foot"
        >
          <span
            v-if="editable && !viewOnly"
            class="base-card__edit-wrap"
            @click.stop="edit"
          >
            <BaseTooltip
              placement="top"
              text="Edit"
            >
              <span
                class="base-card__edit"
                @click.stop="edit"
              >
                <Icon
                  class="base-card__edit-icon"
                  name="edit"
                />
              </span>
            </BaseTooltip>
          </span>
          <span
            v-if="isSentence && viewOnly"
            class="base-card__edit-wrap"
          >
            <BaseTooltip
              placement="top"
              text="Open Source"
            >
              <span
                class="base-card__edit"
                @click="openSource"
              >
                <Icon
                  class="base-card__expand-icon"
                  name="expand"
                />
              </span>
            </BaseTooltip>
          </span>
          <RespondersDropdown
            v-if="showTags"
            class="base-card__tags"
            :can-edit="editable"
            list="tags"
            :project-id="parsedProjectId"
            :value="cardTags"
            @toggle="tagsOpen = $event"
            @input="setCardTags"
            @itemSelect="onTagSelect($event, true)"
            @itemUnselect="onTagSelect($event, false)"
          />
          <RespondersDropdown
            v-if="editable && showResponders"
            :value="cardResponders"
            :project-id="parsedProjectId"
            list="responders"
            class="base-card__tags"
            :can-edit="editable"
            @toggle="tagsOpen = $event"
            @itemSelect="onResponderSelect($event, true)"
            @itemUnselect="onResponderSelect($event, false)"
          />
        </div>
        <transition name="fade">
          <div
            v-if="isEmpty && editting"
            class="base-card__enter-archive"
          >
            Press Enter to {{ emptyRemoveText }}
            <Icon
              class="base-card__enter-archive-icon"
              name="archive"
            />
          </div>
        </transition>
      </div>
    </div>
  </SideDropdownMenu>
</template>

<script>
import chroma from 'chroma-js';
import Editor, { getSentencesFromEditor } from 'components/common/editor';
import PagesService from 'services/pages.service';
import { mapGetters } from 'vuex';

export default {
  name: 'Card',
  components: {
    Editor,
  },
  props: {
    data: Object,
    dataTransport: [String, Object, Number],
    actions: {
      type: [Array],
      required: false,
    },
    cardMode: {
      type: String,
      default: 'default',
    },
    breadcrumbs: {
      type: [Boolean, Function],
      required: false,
      default: false,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardTabindex: {
      type: String,
      default: '0',
    },
    valueParam: {
      required: false,
      default: 'content',
    },
    pageParam: {
      required: false,
      default: 'page',
    },
    emptyRemoveText: {
      required: false,
      default: 'archive',
    },
    surveyMode: {
      required: false,
      default: false,
    },
    showResponders: {
      required: false,
      default: false,
    },
    showTags: {
      required: false,
      default: true,
    },
    sideMenu: {
      required: false,
      default: false,
    },
    projectId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      tagsOpen: false,
      editting: false,
      initialText: null,
      isEmpty: false,
      sentenceText: '',
    };
  },
  computed: {
    ...mapGetters([
      'userCanEditProjects',
      'projectCurrentMethod',
      'isCardDragging',
    ]),
    userHasAccessToProject() {
      const projectId = this.projectId || this.data.projectId;
      return this.$store.getters.userHasAccessToProjectById(projectId);
    },
    parsedProjectId() {
      return this.projectId || this.data.projectId;
    },
    tags() {
      return this.$store.getters.allTags;
    },
    parsedSideMenu() {
      return this.sideMenu && this.sideMenu.map((item) => ({
        ...item,
        action: () => {
          this.$emit('action', { action: item.action, sentence: this.data });
        },
      }))
    },
    editable() {
      return this.editPermission && this.canEdit && !this.disabled;
    },
    editPermission() {
      return this.userCanEditProjects;
    },
    active() {
      return this.editting || this.tagsOpen;
    },
    recordType() {
      const type = this.type || this.projectCurrentMethod && this.projectCurrentMethod.type;
      return PagesService.getRecordType(type);
    },
    cardTags() {
      return this.data.tags || [];
    },
    cardResponders: {
      get() {
        return this.data.responders || [];
      },
      set(responders) {
        this.$emit('responders-change', responders);
        if (!this.isSentence) {
          this.$emit('action', { action: 'change', card: this.data, data: { responders } });
        }
      },
    },
    cardText: {
      get() {
        return this.data[this.valueParam];
      },
      set(value) {
        this.$emit('change', { [this.valueParam]: value });
        this.$emit('action', { action: 'change', card: this.data, data: { [this.valueParam]: value } });
      },
    },
    hasTags() {
      return !!this.cardTags.length;
    },
    hasResponders() {
      if (!this.showResponders) return true;
      return this.data.responders && this.data.responders.length;
    },
    isEditing() {
      return this.$store.getters.isEditing;
    },
    canEditText() {
      return this.canEdit && !this.isEditing && !this.disabled;
    },
    shouldDisplayBreadcrumbs() {
      return this.breadcrumbs && (this.methodTypeName || this.parentName || this.parentContent);
    },
    hasTextInside() {
      const MIN_LENGHT = 10;
      const page = this.data[this.pageParam];
      if (!(page && page.length > 0)) {
        return false;
      }

      return page.replace(/<[^>]*>?/gm, '').length >= MIN_LENGHT;
    },
    imagesInPageCounter() {
      const page = this.data[this.pageParam];
      if (page === null || page === undefined) {
        return false;
      }
      const imgTag = '<img';
      return page.split(imgTag).length - 1;
    },
    projectName() {
      const { projectId } = this.data;
      const project = this.$store.getters.projectById(projectId);

      return project && project.name;
    },
    methodTypeName() {
      if (this.data.parentMethodName) return this.data.parentMethodName;
      if (!this.data.designMethodId) return '';
      const method = this.$store.getters.projectMethodById(this.data.designMethodId);
      if (!method) return '';
      return method.name;
    },
    parentContent() {
      if (this.isSentence) return '';
      if (!this.data.parentContent) {
        if (!this.data.cardType) {
          switch(this.data.category) {
            case 'TestingSession':
              return 'Testing Sessions';
          }
        }
        return this.data.cardType !== 'CustomMethodField' && this.data.cardType;
      }
      return this.data.parentContent;
    },
    parentName() {
      if (this.isSentence) return '';
      if (this.data.parentName) return this.data.parentName;
      return '';
    },
    editMenu() {
      return this.actions || [];
    },
    shouldDisplayCallableBreadcrums() {
      return typeof this.breadcrumbs === 'function';
    },
    callableBreadcrumbs() {
      return this.breadcrumbs(this.data);
    },
    isSentence() {
      return this.data.cardType === 'Sentence';
    },
    sentenceHtml() {
      if (!this.isSentence) return '';
      const temp = document.createElement('div');
      temp.innerHTML = this.data.parentContent;
      let hasParentContent = this.data.parentContent && !!this.data.parentContent.replace('<p></p>', '').length;

      if (!hasParentContent) {
        temp.innerHTML = this.data.text;
      }

      if (!this.data.parentType || this.data.parentType === "CustomItemField") {
        temp.innerHTML = this.data.text;
        hasParentContent = false;
      }

      let text = temp.innerText.replace(/\u00A0/gim, ' ');

      let before = '...';
      let after = '...';
      const offset = this.data.editorId.match(/__(\d+)--(\d+)$/i);
      const offsetLeft = offset[1] * 1;
      let marginLeft = Math.max(0, offsetLeft - 50);
      const offsetRight = offset[2] * 1;
      let marginRight = Math.min(text.length, offsetRight + 50);

      while(text[marginLeft - 1] !== ' ' && marginLeft > 0) {
        marginLeft--;
      }

      while(text[marginRight] !== ' ' && marginRight < text.length) {
        marginRight++;
      }

      if (marginLeft === 0) {
        before = '';
      }

      if (marginRight === text.length) {
        after = '';
      }

      const left = hasParentContent ? text.slice(marginLeft, offsetLeft) : '';
      const content = hasParentContent ? text.slice(offsetLeft, offsetRight) : text;
      const right = hasParentContent ? text.slice(offsetRight, marginRight) : '';
      let colors = this.data.tags.map((tag) => {
        return this.tags.find((t) => t.id === tag.id).color;
      });

      if (!colors.length) {
        colors = ['rgba(0,0,0,0)'];
      }

      let tagColor = chroma.average(colors, 'lch');
      let backgroundColor = tagColor.alpha(0.05);
      let borderColor = tagColor.alpha(0.3);

      if (!this.showTags) {
        return `${content}`;
      }

      text = `${before}${left}<tag style="background-color:${backgroundColor};border-color:${borderColor}">${content}</tag>${right}${after}`;

      return text;
    },
    sentenceSelection() {
      const offset = this.data.editorId.match(/__(\d+)--(\d+)$/i);
      return [offset[1] * 1, offset[2] * 1];
    },
    isSurveyOption() {
      return this.surveyMode;
    },
    isSelected() {
      return this.isSurveyOption && this.data.selected;
    },
  },
  watch: {
    cardText(text) {
      if (!text) {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
    },
    active(active) {
      this.$emit('toggle', active);
    },
    editting(is) {
      this.$emit('toggle', is);
    },
  },
  created() {
    if (this.isSentence) {
      this.sentenceText = this.data.parentContent;
    }
  },
  methods: {
    setCardTags(tags) {
      this.$emit('tags-change', tags);
      if (!this.isSentence) {
        this.$emit('action', { action: 'change', card: this.data, data: { tags, tag_list: tags } });
      }
    },
    editPage() {
      this.modalOpenable = false;
      setTimeout(() => {
        this.$refs.input.select();
      });
    },
    reverseChanges() {
      this.$emit('change', { [this.valueParam]: this.initialText });
      this.$emit('action', { action: 'change', card: this.data, data: { [this.valueParam]: this.initialText } });
    },
    onInputBlur() {
      this.modalOpenable = true;
      this.editting = false;

      if (!this.data[this.valueParam]) {
        this.reverseChanges();
      }
    },
    onInputFocus() {
      this.editting = true;
      this.initialText = this.data[this.valueParam];
    },
    onInputEnter() {
      if (this.isEmpty) {
        this.reverseChanges();
        this.$emit('action', { action: 'archive', card: this.data });
      } else {
        this.$refs.input.blur();
      }
    },
    showSelector(e) {
      e.stopPropagation();
      if (!this.canEdit || this.disabled) return;
      this.$refs.selector.show(e);
    },
    showModal() {
      this.$store.dispatch('openCardModal', {
        recordType: this.recordType,
        transport: this.dataTransport,
        valueParam: this.valueParam,
        pageParam: this.pageParam,
        disableRespondents: !this.showResponders,
        disableTags: !this.showTags,
        ...this.data,
      });
    },
    edit() {
      this.$refs.input.select();
    },
    openSource(e) {
      this.$emit('action', { action: 'sentence-click', sentence: this.data });
      e.stopPropagation();
      e.stopImmediatePropagation();
      return;
    },
    onClick(e) {
      if(this.viewOnly) {
        return;
      }

      if (this.isSentence) {
        this.$emit('action', { action: 'sentence-click', sentence: this.data });
        e.stopPropagation();
        e.stopImmediatePropagation();
        return;
      }

      if (this.isSurveyOption) {
        this.$emit('action', { action: 'select', card: this.data });
        e.stopPropagation();
        e.stopImmediatePropagation();
        return;
      }

      if (this.editting || this.disabled || this.isCardDragging) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        return;
      };
      this.showModal();
    },
    onAction(action) {
      const card = this.data;
      this.$emit('action', { action, card });
    },
    editMenuToggle(open) {
      if (!open && this.editting) {
        this.$refs.input.blur();
      }
    },
    onTagSelect({ id }, selected) {
      if (this.isSentence) {
        let editor = this.$refs.editor;

        if (!editor) return;

        let quill = editor.getEditor();

        if (!quill) return;

        let index = this.sentenceSelection[0];
        let length = this.sentenceSelection[1] - this.sentenceSelection[0];

        quill.setSelection(index, length);
        quill.format('tag', { tag: id, selected });

        let tags = this.$store.getters.allTags;
        let content = editor.getValue();
        let contentTags = getSentencesFromEditor(quill, tags);

        this.$emit('change', { cardType: this.data.cardType, content, contentTags, card: this.data });
        this.$emit('action', { action: 'change', card: this.data, data: { content, contentTags } });
      }
    },
    onResponderSelect(responder, selected) {
      if (selected) {
        this.$emit('responderSelect', responder);
      } else {
        this.$emit('responderUnselect', responder);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'styles/sizes';
@import 'styles/helpers';

@keyframes card-dragging-anim {
  0% {
    transform: rotate(0deg);
    box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.08);
  }
  100% {
    transform: rotate(-2deg);
    box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.21);
  }
}

.base-card {
  $self: &;
  border-bottom: solid 1px #f2f2f2;
  margin-left: -15px;
  -webkit-user-select: none;
  user-select: none;
  position: relative;

  &--active {
    z-index: 950;
  }

  &--dragging {
    opacity: 1 !important;
    border: 0;
  }

  &--sentence {
    -webkit-user-select: auto;
    user-select: auto;
    margin-left: 0;
  }

  &--option {
    border: 0;
    border: solid 1px #F4F4F4;
    border-radius: 6px;
    margin-bottom: 8px;
    margin-left: 0;
  }

  &__dropdown-positioner {
    height: 52px;
    margin-bottom: -52px;
  }

  &__container {
    border-radius: 3px;
    color: #3b3b3b;
    cursor: pointer;
    font-size: 1.6rem;
    padding: 6px 6px 6px 15px;
    position: relative;
    transition: box-shadow 150ms;
    display: flex;
    justify-content: space-between;
    position: relative;

    #{ $self }--sentence & {
      cursor: auto;
      padding-left: 0;
    }

    #{ $self }:hover &,
    #{ $self }--active &,
    #{ $self }--dragging & {
      background: $color-white;
      box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.08);
    }
    
    #{ $self }--sentence:hover &,
    #{ $self }--option:hover & {
      background: transparent;
      box-shadow: none;
    }

    #{ $self }--option & {
      transition: background-color 150ms;

      &:focus {
        background-color: #F8F9FA;
        outline: none;
      }
    }

    #{ $self }--option:hover & {
      background-color: #F8F9FA;
    }

    #{ $self }--option:active & {
      background-color: #F0F2F5;
    }

    #{ $self }--dragging & {
      animation: card-dragging-anim 300ms;
      animation-fill-mode: both;
    }

    #{ $self }--ghost & {
      transition: none !important;
      background: #dae7ed !important;
      box-shadow: none !important;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background: #fff;
      }

      &:before {
        top: -1px;
      }

      &:after {
        bottom: -1px;
      }

      & > * {
        transition: none;
        opacity: 0 !important;
      }
    }

    #{ $self }--disabled & {
      box-shadow: none !important;
    }
  }

  &__body {
    width: 100%;

    #{ $self }--canedit & {
      width: calc(100% - 49px);
    }
  }

  &__lock {
    margin-right: 10px;
    font-size: 12px;

    :global(.tooltip__trigger) {
      line-height: 1;
    }
  }

  &__breadcrumbs {
    text-transform: uppercase;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #aaaab5;
    font-size: 1rem;
    margin-bottom: 4px;
    margin-top: 7px;
    letter-spacing: 0.86px;
    white-space: nowrap;
    display: flex;
    align-items: center;

    > li {
      display: inline-block;
      margin: 0;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-of-type {
        flex-shrink: 0;
        max-width: 30%;
      }

      &:before {
        content: '/';
        margin: 0 14px;
      }

      &:first-of-type:before {
        display: none;
      }
    }
  }

  &__input {
    display: block;
    border: 0;
    background: transparent;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: left;
    padding: 0;
    color: #3b3b3b;
    width: 100%;
    outline: 0;
    resize: none;
    height: auto;
    cursor: pointer;
    pointer-events: none;
    line-height: 1.6;
    margin: 5px 0;

    #{ $self }--editting & {
      pointer-events: auto;
      cursor: initial;
    }
  }

  &__indicators {
    margin-top: 2px;
    display: flex;

    div {
      display: flex;
      align-items: center;
      margin-right: 8px;
      min-height: 20px;
      align-items: center;
      font-size: 1.2rem;
      span {
        margin-left: 2px;
      }
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__tags {
    transition: opacity 200ms;

    #{ $self }--empty-tags & {
      opacity: 0;
    }

    #{ $self }:hover &,
    #{ $self }--active &,
    #{ $self }--dragging & {
      opacity: 1;
    }
  }

  &__edit-wrap {
    height: 100%;
    margin-left: -25px;
    opacity: 0;
    transition: all 200ms;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;

    &:after {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      width: 30px;
      pointer-events: none;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }

    #{ $self }:hover &,
    #{ $self }--active &,
    #{ $self }--dragging & {
      opacity: 1;
    }

    #{ $self }--editting &,
    #{ $self }--editting:hover & {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__edit {
    width: 36px;
    height: 36px;
    border-radius: 3px;
    font-size: 1.8rem;
    padding: 8px 9px;
    background-color: #fff;
    display: inline-block;
    vertical-align: top;
    line-height: 1px;
    transition: all 200ms;

    &:hover {
      background-color: #F8F9FA;
    }

    &:active {
      background-color: #F0F2F5;
    }

    @include xsMax {
      display: none;
    }
  }

  &__edit-icon,
  &__expand-icon {
    opacity: 0;
    transition: all 200ms;
    cursor: pointer;

    #{ $self }:hover &,
    #{ $self }--active &,
    #{ $self }--dragging & {
      opacity: 0.7;
    }

    #{ $self }--editting &,
    #{ $self }--editting:hover & {
      opacity: 0;
    }
  }

  &__expand-icon {
    width: 20px;
    height: 19px;
    vertical-align: top;
  }

  &__enter-archive {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    align-items: center;
    display: flex;
    background-color: #fff;
    border-radius: 3px;
    font-size: 12px;
    color: #858585;
    padding-right: 15px;

    &-icon {
      margin-left: 15px;
      background-color: #f3f6f8;
      width: 25px;
      height: 25px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      :global(& svg) {
        width: 18px;
        height: 15px;
      }
    }
  }

  &__sentence {
    margin: 5px 0;
  }

  &__option {
    position: relative;
    min-height: 40px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 7px 0;
    padding-left: 38px;
    font-size: 16px;
    counter-increment: surveyCard;

    &:before {
      content: counter(surveyCard, upper-alpha);
      position: absolute;
      background-color: #F8F9FA;
      width: 26px;
      height: 26px;
      left: 0;
      top: 50%;
      margin-top: -13px;
      border-radius: 3px;
      text-align: center;
      line-height: 26px;
      font-size: 12px;
      color: rgba(#414141, 0.5);
      transition: all 200ms;

      #{ $self }--selected & {
        background-color: #059fa3;
        color: #fff;
      }
    }

    &--check {
      &:before {
        content: '';
        background-repeat: no-repeat;
        background-position: center center;
        border: solid 1px #86909A;
        background-color: #fff;

        #{ $self }--selected & {
          background-color: #059fa3;
          background-image: image_url('svg/check-white.svg');
          border-color: #059fa3;
        }
      }
    }
  }
}

</style>