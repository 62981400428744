<template>
  <div
    class="method-item-icon"
    :style="colorStyle"
  >
    <Icon
      v-if="icon"
      :name="icon"
    />
  </div>
</template>

<script>

const TYPES = [
  {
    type: 'image',
    text: 'Image',
    icon: 'builder-addon--image',
    kind: 'inline',
  },
  {
    type: 'gallery',
    text: 'Gallery',
    icon: 'builder-addon-gallery',
  },
  {
    type: 'summary',
    text: 'Summary',
    icon: 'builder-addon-summary',
  },
  {
    type: 'charts',
    text: 'Charts',
    icon: 'builder-addon-charts',
  },
  {
    type: 'cards',
    text: 'Cards',
    icon: 'cards-25',
  },
  {
    type: 'checklist',
    text: 'Checklist',
    icon: 'builder-addon-checklist',
  },
  {
    type: 'pages',
    text: 'Pages',
    icon: 'file-25',
  },
  {
    type: 'pages-chart',
    text: 'Pages chart',
    icon: 'builder-addon-chart',
  },
  {
    type: 'custom_5',
    text: 'Text',
    icon: 'builder-addon-list',
  },
  {
    type: 'custom_1',
    text: 'Scale',
    icon: 'scale-25',
  },
  {
    type: 'custom_2',
    text: 'Multiple Choice',
    icon: 'builder-addon-checklist',
  },
  {
    type: 'custom_3',
    text: 'Single Choice',
    icon: 'radio-25',
  },
];

export default {
  name: 'MethodItemIcon',
  props: {
    item: { type: Object },
  },
  data() {
    return {
    };
  },
  computed: {
    colorStyle() {
      return { backgroundColor: this.color };
    },
    color() {
      return this.item && this.item.color;
    },
    icon() {
      if (!this.item) return '';
      const type = TYPES.find((t) => t.type === this.item.type);
      return type && type.icon;
    },
  },
  methods: {
  },
};
</script>
