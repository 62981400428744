/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import NotificationsService from 'services/notifications.service';
import TeamService from 'services/team.service';
import UndoService from 'services/undo.service';
import { analyticsSetUserProp } from 'utils/analytics';
import generateId from 'utils/id';
import { merge } from 'utils/reactive';

const moduleState = {
  private: false,
  members: [],
};

const getters = {
  members: (state) => state.members || [],
  memberById: (state, getters) => (userId) =>
    getters.members.find(({ id }) => id === userId) || null,
  membersLimitReached: (state, getters) => {
    const subscription = getters.userPlanInfo;
    return state.members.length >= (subscription.usersLimit || Infinity);
  },
};

const actions = {
  async teamFetch({ dispatch, getters }, id = null) {
    await dispatch('requireWorkspaces');
    await dispatch('requireUser');

    const { workspaceCurrentId } = getters;
    const response = await TeamService.fetchAll(workspaceCurrentId);
  },
  teamSet({ commit }, members) {
    commit(TYPES.TEAM_FETCHED, members);
  },

  async teamInvite({ commit, getters, dispatch }, data) {
    const { workspaceCurrentId } = getters;
    const model = {
      id: generateId(),
      ...data,
    };

    commit(TYPES.TEAM_MEMBER_ADD, model);
    try {
      const result = await TeamService.invite(workspaceCurrentId, data.projectId || getters.projectCurrentId, model);
      commit(TYPES.TEAM_MEMBER_CHANGE, {
        member: model,
        data: { role: 'member', ...result },
      });
      if (!result || !result.id) {
        await dispatch('teamFetch');
      }
      return result;
    } catch (error) {
      commit(TYPES.TEAM_MEMBER_REMOVE, model.id);
      throw error;
    }

    analyticsSetUserProp('Invite team member');
  },
  async teamOwner({ commit, getters }, id) {
    const member = await TeamService.transferOwnership(
      getters.workspaceCurrentId,
      getters.projectCurrentId,
      id
    );
    commit(TYPES.TEAM_OWNERSHIP, id);

    return member;
  },
};

const mutations = {
  [TYPES.TEAM_FETCHED](state, members) {
    state.members = members.map((member) => ({
      ...member,
    }));
  },

  [TYPES.TEAM_MEMBER_ADD](state, member) {
    state.members.push(member);
  },
  [TYPES.TEAM_MEMBER_CHANGE](state, { member, data }) {
    merge(member, data);
  },
  [TYPES.TEAM_OWNERSHIP](state, memberId) {
    state.members.forEach((member) => {
      if (member.id !== memberId) {
        member.role = 'member';
        return;
      }

      member.role = 'owner';
    });
  },

  [TYPES.LOGGED_OUT](state) {
    state.members = [];
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
