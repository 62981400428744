<template>
  <div class="sidebar-margin">
    <div class="workspace-container">
      <TitleInput
        appearance="project-title"
        :can-edit="false"
        value="Settings"
      />
      <template v-if="isUserOwner">
        <Headline>Workspace</Headline>
        <BaseTable>
          <TableItem class="table-icon-item">
            <div class="table-icon-item__icon">
              <Icon
                appearance="box green"
                name="box"
              />
            </div>
            <div class="table-icon-item__body">
              <div class="table-icon-item__title">
                Subscription Plan
              </div>
              <div class="table-icon-item__desc table-icon-item__desc--right">
                <div v-if="userPlan">
                  {{ userPlanText }} {{ userPlan !== 'trial' ? 'plan' : '' }}
                  <span
                    v-if="isCancelled && userPlan !== 'inactive'"
                    class="project-box__desc"
                  >
                    <br> (Canceled, Valid until {{ validUntil }})
                  </span>
                  <span
                    v-if="userPlan === 'trial'"
                    class="project-box__desc"
                  >
                    <br> (ends in {{ daysLeft }} day{{ daysLeft > 1 ? 's' : '' }})
                  </span>
                </div>
                <BaseSpinner v-else />
              </div>
            </div>
            <div class="table-icon-item__options">
              <button
                class="btn btn--outline"
                @click="changePlan"
              >
                Change
              </button>
            </div>
          </TableItem>
          <TableItem
            v-if="userPlan !== 'trial'"
            class="table-icon-item"
          >
            <div class="table-icon-item__icon">
              <Icon
                appearance="box blue"
                name="box"
              />
            </div>
            <div class="table-icon-item__body">
              <div class="table-icon-item__title">
                Billing Info
              </div>
              <div class="table-icon-item__desc table-icon-item__desc--right">
                {{ user && user.name }}
              </div>
            </div>
            <div class="table-icon-item__options">
              <button
                class="btn btn--outline"
                @click="showEditModal"
              >
                Change
              </button>
            </div>
          </TableItem>
          <TableItem
            v-if="userPlan !== 'trial'"
            class="table-icon-item"
          >
            <div class="table-icon-item__icon">
              <Icon
                appearance="box cyan"
                name="credit-card"
              />
            </div>
            <div class="table-icon-item__body">
              <div class="table-icon-item__title">
                Payment Method
              </div>
              <div
                v-if="paymentMethods && paymentMethods.length"
                class="table-icon-item__desc table-icon-item__desc--right"
              >
                **** **** **** {{ paymentMethods[0].details.lastFour }}
              </div>
            </div>
            <div class="table-icon-item__options">
              <button
                class="btn btn--outline"
                @click="updatePaymentMethod"
              >
                Change
              </button>
            </div>
          </TableItem>
          <TableItem
            v-if="hasInvoices"
            class="table-icon-item"
          >
            <div class="table-icon-item__icon">
              <Icon
                appearance="box violet"
                name="invoice"
              />
            </div>
            <div class="table-icon-item__body">
              <div class="table-icon-item__title">
                Invoices
              </div>
              <div class="table-icon-item__desc table-icon-item__desc--right">
                {{ invoicesCount }} Invoices
              </div>
            </div>
            <div class="table-icon-item__options">
              <button
                class="btn btn--outline"
                @click="showInvoicesModal"
              >
                Show
              </button>
            </div>
          </TableItem>
        </BaseTable>
      </template>

      <Headline>Team</Headline>
      <TeamList :team="workspaceTeam" />

      <BaseTable v-if="isUserOwner">
        <TableItem class="table-icon-item">
          <div class="table-icon-item__icon">
            <Icon
              appearance="box red"
              name="trash"
            />
          </div>
          <div class="table-icon-item__body">
            <div class="table-icon-item__title">
              Delete workspace
            </div>
          </div>
          <div class="table-icon-item__options">
            <DropdownMenu :menu="deleteMenu">
              <button class="btn btn--outline table-icon-item__btn">
                Delete
              </button>
            </DropdownMenu>
          </div>
        </TableItem>
      </BaseTable>
      <BaseTable v-else>
        <TableItem class="table-icon-item">
          <div class="table-icon-item__icon">
            <Icon
              appearance="box red"
              name="trash"
            />
          </div>
          <div class="table-icon-item__body">
            <div class="table-icon-item__title">
              Leave workspace
            </div>
          </div>
          <div class="table-icon-item__options">
            <DropdownMenu :menu="leaveMenu">
              <button class="btn btn--outline table-icon-item__btn">
                Leave
              </button>
            </DropdownMenu>
          </div>
        </TableItem>
      </BaseTable>
    </div>
  </div>
</template>

<script>
import { PRICE_PLANS } from 'constants/routes';
import { formatDate } from 'helpers/date';
import { mapGetters } from 'vuex';

export default {
  name: 'WorkspaceRecent',
  computed: {
    ...mapGetters([
      'workspaceCurrentId',
      'workspaceCurrent',
      'workspaceTeam',
      'paymentMethods',
      'userPlanPeriod',
      'userPlanInfo',
      'user',
      'hasPendingPayment',
      'isUserOwner',
    ]),
    hasInvoices() {
      return this.user && this.user.invoices.length > 0 || this.hasPendingPayment;
    },
    invoicesCount() {
      return this.user && this.user.invoices.length;
    },
    userPlan() {
      return this.$store.getters.userPlan;
    },
    isSubscriptionActive() {
      return this.$store.getters.isSubscriptionActive;
    },

    daysLeft() {
      return this.$store.getters.userSubscriptionDaysLeft;
    },

    userPlanText() {
      return this.userPlan && this.$store.getters.getPlanName(this.userPlan);
    },
    canUpgrade() {
      return true;
    },

    hasCancelled() {
      return this.$store.getters.isSubscriptionCanceled && this.userPlan !== 'trial';
    },
    validUntil() {
      if (!this.hasCancelled) return '';

      const DAY = 24 * 60 * 60 * 1000;
      const timestamp = Date.now() + (DAY * this.daysLeft);
      const date = new Date(timestamp);

      return formatDate(date);
    },
    isNext() {
      if (!this.userPlanInfo) return;

      return this.userPlanInfo.nextSubscriptionPlanKind && this.userPlanInfo.nextSubscriptionPlanKind === this.userPlan;
    },
    isCancelled() {
      return this.hasCancelled && !this.isNext;
    },
    deleteMenu() {
      return [
        {
          type: 'confirmation',
          title: 'Are you sure?',
          description: 'Workspace deletion cannot be undone. Please enter the name of the workspace to confirm.',
          placeholder: 'Workspace name',
          confirmButton: 'Delete',
          confirmationCode: this.workspaceCurrent.name,
          action: (close) => {
            this.deleteWorkspace();
            close();
          },
          cancel: (close) => {
            close();
          },
        },
      ]
    },
    leaveMenu() {
      return [
        {
          type: 'confirmation',
          title: 'Are you sure?',
          description: 'Please enter the name of the workspace to confirm.',
          placeholder: 'Workspace name',
          confirmButton: 'Leave',
          confirmationCode: this.workspaceCurrent && this.workspaceCurrent.name,
          action: (close) => {
            this.leaveWorkspace();
            close();
          },
          cancel: (close) => {
            close();
          },
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('requireUser');
    this.$store.dispatch('requireWorkspaces');
    this.$store.dispatch('invoiceFetch');
    this.$store.dispatch('paymentMethodsFetch');
  },
  methods: {
    changePlan() {
      this.$router.push({ name: PRICE_PLANS });
    },
    showEditModal() {
      this.$store.dispatch('modalShow', {
        name: 'edit-contact',
      });
    },
    updatePaymentMethod() {
      this.$store.dispatch('modalShow', {
        name: 'PaymentMethodModal',
      });
    },
    showInvoicesModal() {
      this.$store.dispatch('modalShow', {
        name: 'invoices',
      });
    },
    deleteWorkspace() {
      this.$store.dispatch('workspaceDelete', {
        id: this.workspaceCurrentId,
      });
    },
    leaveWorkspace() {
      this.$store.dispatch('workspaceLeave');
    },
  },
};
</script>
