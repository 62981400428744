<template>
  <li @click="$emit('create')">
    <div class="workspace-project workspace-project--new">
      <div class="workspace-project__body">
        <div class="workspace-project__icon">
          <Icon
            appearance="box"
            name="lock"
            v-if="projectsLimitReached"
          />
          <Icon
            appearance="box gray-500"
            name="plus_two"
            v-else
          />
        </div>
        <div class="workspace-project__name">
          Create New Project
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'WorkspaceRecent',
  props: {
    data: Object,
  },
  computed: {
    projectsLimitReached() {
      return this.$store.getters.projectsLimitReached;
    },
  },
  methods: {
  },
};
</script>
