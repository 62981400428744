/* eslint-disable no-shadow */
import * as TYPES from 'constants/mutations';
import PromptsService from 'services/prompts.service';
import generateId from 'utils/id';

const moduleState = {
  prompts: [],
};

const getters = {
  prompts: (state) => state.prompts,
  isPromptVisible: (state) => (promptId) =>
    state.prompts.some(({ id }) => id === promptId),
};

const actions = {
  promptShow({ dispatch, commit, getters }, data = {}) {
    if (!data.text || !data.text.length) {
      throw new Error('Store.prompts: empty prompt text!');
    }

    // only one notification can be shown at a time
    getters.prompts.forEach(({ id }) => dispatch('promptHide', id));

    const prompt = {
      id: generateId(),
      ...data,
    };

    commit('PROMPT_SHOW', prompt);
  },
  promptsHide({ commit, getters }, id) {

    for (const prompt of getters.prompts) {
      commit('PROMPT_HIDE', prompt.id);
      PromptsService.onHide(prompt.id);
    }
  },
};

const mutations = {
  PROMPT_SHOW(state, notification) {
    state.prompts.push(notification);
  },
  PROMPT_HIDE(state, promptId) {
    state.prompts = state.prompts.filter(
      ({ id }) => id !== promptId
    );
  },

  [TYPES.LOGGED_OUT](state) {
    state.prompts = [];
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
