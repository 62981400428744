<template>
  <div
    class="method__wrapper"
    data-aos="fade-in"
  >
    <p class="method__title">
      {{ title.toUpperCase() }}
    </p>
    <template
      v-for="method of methods"
      :key="method.type"
    >
      <MethodItem
        :method="method"
        :phase-id="phaseId"
        @adding="$emit('adding')"
      />
    </template>
  </div>
</template>

<script>
import MethodItem from './item';

export default {
  name: 'NewMethodList',
  components: {
    MethodItem,
  },
  props: {
    title: String,
    phase: String,
    phaseId: String,
  },
  computed: {
    methods() {
      return this.$store.getters.methodsByPhase(this.phase);
    },
  },
};
</script>

<style lang="scss" scoped>
  .method {
    &__wrapper {
      max-width: 642px;
      margin: auto;
    }
    &__title {
      font-size: 11px;
      font-weight: bold;
      line-height: 1.36;
      letter-spacing: 0.8px;
      color: #414141;
      padding-left: 15px;
    }
  }
</style>
