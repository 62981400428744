import forEach from 'lodash/forEach';

export default (map) => {
  const result = {};
  forEach(map, (value, key) => {
    result[key] = () => value;
  });

  return result;
};
