<template>
  <div>
    <transition name="fade">
      <div
        v-if="isWelcomeStep && shouldDisplay"
        class="tutorial-backdrop"
      />
    </transition>
    <transition name="show-bottom">
      <div
        v-if="shouldDisplay"
        class="tutorial-prompt"
      >
        <div class="tutorial-prompt__step">
          <div
            class="tutorial-step"
            :class="{ 'tutorial-step--welcome': isWelcomeStep }"
          >
            <div class="tutorial-step__img">
              <img :src="imageUrl">
            </div>
            <div class="tutorial-step__body">
              <div class="tutorial-step__text">
                <transition
                  mode="out-in"
                  name="fade"
                >
                  <div :key="currentStep">
                    <span class="tutorial-step__heading">{{ step.title }}</span>
                    {{ step.text }}
                  </div>
                </transition>
              </div>
              <div class="tutorial-step__btns">
                <div class="tutorial-dots">
                  <span
                    v-for="(step, index) of steps"
                    :key="step.title"
                    class="tutorial-dots__dot"
                    :class="{ 'tutorial-dots__dot--active': index === currentStep }"
                  />
                </div>
                <a
                  class="btn btn--md btn--light"
                  @click="prev"
                >
                  <transition
                    mode="out-in"
                    name="fade"
                  >
                    <div
                      v-if="isWelcomeStep"
                      key="skip"
                    >Skip</div>
                    <div
                      v-else
                      key="back"
                    >Back</div>
                  </transition>
                </a>
                <a
                  class="btn btn--md btn--primary"
                  @click="next"
                >
                  <transition
                    mode="out-in"
                    name="fade"
                  >
                    <span
                      v-if="isWelcomeStep"
                      key="begin"
                    >Begin</span>
                    <span
                      v-else-if="isLastStep"
                      key="next"
                    >Close</span>
                    <span key="close" v-else>Next</span>
                  </transition>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { selectText } from 'helpers/ui';
import $ from 'jquery'
import { mapGetters } from 'vuex';
import zenscroll from 'zenscroll';
import { analyticsLogEvent } from 'utils/analytics';

export default {
  name: 'Tutorial',
  props: {
    name: String,
    method: Object,
  },
  data() {
    return {
      currentStep: null,
      shouldDisplay: false,
    };
  },
  async mounted() {
    await this.fetchConfig();

    this.frame();

    window.setTimeout(() => {
      this.setShouldDisplay();
    }, 1000);
  },
  unmounted() {
    this.currentStep = null;
    this.frame();
  },
  computed: {
    ...mapGetters([
      'tutorialsData',
      'isShared',
      'userCanEditProjects',
      'tutorialsConfig',
      'sections',
    ]),
    isWelcomeStep() {
      return this.currentStep === null;
    },
    step() {
      if (this.currentStep === null) {
        return this.data;
      };

      return this.data.steps[this.currentStep];
    },
    config() {
      return this.tutorialsConfig;
    },
    steps() {
      return (this.data && this.data.steps) || [];
    },
    isLastStep() {
      return this.currentStep === this.steps.length - 1;
    },
    imageUrl() {
      return this.step && this.step.image;
    },
    methodId() {
      return this.$store.getters.projectCurrentMethodId;
    },
    sections() {
      return this.$store.getters.methodSections(this.methodId);
    },
    isReport() {
      return this.$store.getters.isMethodReportById(this.methodId);
    },
  },
  watch: {
    async currentStep(current) {
      this.reset();

      if (!this.shouldDisplay) {
        return;
      }

      const step = this.data.steps[current];
      const sections = this.sections;

      if (step && step.onShow) {
        for(let action of step.onShow) {
          let target = document.querySelector(action.target);
          if (target) {
            switch(action.action) {
              case 'click':
                $(target).trigger('click');
                break;
            }
          }
        }
      }

      if (step && step.requireItems && sections) {
        const foundItemsIds = [];

        for (const item of step.requireItems) {
          let found = sections.find((s) => {
            let status = true;

            if (item.notType) {
              status = status && !item.notType.includes(s.type);
            }

            if (item.type) {
              status = status && item.type.includes(s.type);
            }

            if (item.contentLength) {
              status = status && s.content && s.content.length >= item.contentLength;
            }

            return status;
          })

          if (!found && item.createIfNotFound) {
            found = await this.$store.dispatch('builderCreateSection', {
              data: item.createIfNotFound,
              methodId: this.methodId,
            });
          }

          foundItemsIds.push(found.localId || found.id);
        }

        this.foundItemsIds = foundItemsIds;
      }
    },
    shouldDisplay(should, prev) {
      if (!should && this.targetElement) {
        this.reset();
      }
      if (should && !prev) {
        this.currentStep = null;
        this.reset();
        this.frame();
      }
    },
    tutorialsData(data) {
      this.setShouldDisplay();
      this.reset();
    },
    name() {
      this.currentStep = null;
      this.reset();
    },
  },
  methods: {
    setShouldDisplay() {
      let found = null;

      if (this.config) {
        found = this.config.find((data) => {
          return (
            (!data.methodTitles || data.methodTitles.includes(this.method.name)) &&
            (data.type === this.method.type.toLowerCase())
          );
        })
      }

      this.data = found;
      this.tutorialId = found && found.id;
      this.shouldDisplay = !this.isReport && found && found.steps && this.tutorialsData && !this.tutorialsData[found.id] && !this.isShared && this.userCanEditProjects;
      
      if (this.shouldDisplay) {
        analyticsLogEvent('page_onboarding_show');
      }
    },
    async fetchConfig() {
      await this.$store.dispatch('fetchTutorialsConfig');
    },
    runActions(actions) {
      for(let action of actions) {
        let targetQuery = action.target;

        if (this.foundItemsIds) {
          this.foundItemsIds.forEach((id, index) => {
            targetQuery = targetQuery.replace(`{{REQUIRED_ITEM_ID_${index}}}`, id);
          });
        }

        let target = document.querySelector(targetQuery);

        if (target) {
          switch(action.action) {
            case 'click':
              $(target).trigger('click');
              break;
            case 'selectText':
              selectText(target);
          }
        }
      }
    },
    frame() {
      if (this.targetElement && !this.targetElement.classList.contains('tutorial-highlighted-element')) {
        this.targetElement.classList.add('tutorial-highlighted-element');
      }

      if (this.targetElement && !document.body.contains(this.targetElement)) {
        this.targetElement.classList.remove('tutorial-highlighted-element');
        this.targetElement = null;
      }

      if (!this.shouldDisplay) {
        this.reset();
        return;
      } else {
        requestAnimationFrame(this.frame);
      }

      if (!this.targetElement && this.currentStep !== null) {
        const step = this.data.steps[this.currentStep];
        if (step.target) {
          let targetQuery = step.target;

          if (this.foundItemsIds) {
            this.foundItemsIds.forEach((id, index) => {
              targetQuery = targetQuery.replace(`{{REQUIRED_ITEM_ID_${index}}}`, id);
            });
          }

          const target = document.querySelector(targetQuery);
          if (target) {
            target.classList.add('tutorial-highlighted-element');
            this.targetElement = target;

            if (step.onFind) {
              this.runActions(step.onFind);
            }

            if (step.scrollPosition === 'top') {
              zenscroll.to(target);
            } else if(step.scrollPosition === 'page-top') {
              zenscroll.toY(0);
            } else {
              zenscroll.center(target);
            }
          }
        }
      }
    },
    begin() {
      this.currentStep = 0;
    },
    prev() {
      if (this.currentStep === null) {
        this.$store.dispatch('closeTutorial', this.tutorialId);
        analyticsLogEvent('page_onboarding_back_close');
        return;
      } else if (!this.currentStep) {
        this.currentStep = null;
      } else {
        this.currentStep = this.currentStep - 1;
      }

      analyticsLogEvent(`page_onboarding_back_${this.currentStep}`);
    },
    next() {
      if (this.currentStep >= this.data.steps.length - 1) {
        this.$store.dispatch('closeTutorial', this.tutorialId);
        analyticsLogEvent('page_onboarding_next_close');
        return;
      } else if (this.currentStep === null) {
        this.currentStep = 0;
      } else {
        this.currentStep = this.currentStep + 1;
      }
      analyticsLogEvent(`page_onboarding_next_${this.currentStep}`);
    },
    skip() {

    },
    reset() {
      if (this.targetElement) {
        this.targetElement.classList.remove('tutorial-highlighted-element');
        this.targetElement = null;
      }
    },
  },
};
</script>
