<template>
  <div class="wy-toolbar" @mouseup="onMouseDown">
    <div class="wy-toolbar__bar" v-if="!tab">
      <div class="wy-toolbar__bar-section" v-if="showTags">
        <button class="wy-toolbar__ico wy-toolbar__ico--text" @click="openTab('tags')">
          Add tag
        </button>
      </div>
      <div class="wy-toolbar__bar-section">
        <button class="wy-toolbar__ico" :class="icoClasses('bold')" @click="formatToggle('bold')">
          <Icon name="toolbar-bold"/>
        </button>
        <button class="wy-toolbar__ico" :class="icoClasses('italic')" @click="formatToggle('italic')">
          <Icon name="toolbar-italic"/>
        </button>
        <button class="wy-toolbar__ico" :class="icoClasses('underline')" @click="formatToggle('underline')">
          <Icon name="toolbar-underline"/>
        </button>
        <span class="wy-toolbar__separator" />
        <button class="wy-toolbar__ico" :class="icoClasses('headline-1')" @click="setType('headline-1')">
          <Icon name="toolbar-headline-3"/>
        </button>
        <button class="wy-toolbar__ico" :class="icoClasses('headline-2')" @click="setType('headline-2')">
          <Icon name="toolbar-headline-4"/>
        </button>
        <span class="wy-toolbar__separator" />
        <button class="wy-toolbar__ico" :class="icoClasses('bullet-list')" @click="setType('bullet-list')">
          <Icon name="toolbar-bullet-list"/>
        </button>
        <button class="wy-toolbar__ico" :class="icoClasses('number-list')" @click="setType('number-list')">
          <Icon name="toolbar-number-list"/>
        </button>
        <span class="wy-toolbar__separator" />
        <button class="wy-toolbar__ico" :class="icoClasses('link')" @click="openTab('link')">
          <Icon name="toolbar-link"/>
        </button>
      </div>
    </div>
    <div class="wy-toolbar__bar wy-toolbar__tags" v-else-if="tab === 'edit-link'">
      <div class="wy-toolbar__bar-section">
        <div class="wy-toolbar__input">
          Enter link:
          <BaseInput
            autofocus
            :value="urlInput"
            @input="urlInput = $event"
            @enter="urlInputEnter"
            placeholder="http://talebook.io/"
          />
        </div>
        <button class="wy-toolbar__ico wy-toolbar__ico--text" @click="saveLink">
          Save
        </button>
      </div>
    </div>
    <div class="wy-toolbar__bar wy-toolbar__tags" v-else-if="tab === 'link'">
      <div class="wy-toolbar__bar-section">
        <button class="wy-toolbar__ico wy-toolbar__ico--text" @click="openLink">
          Visit URL: &nbsp;
          <strong>{{ formats.link }}</strong>
        </button>
        <button class="wy-toolbar__ico" @click="openTab('edit-link')">
          <Icon name="toolbar-edit"/>
        </button>
        <button class="wy-toolbar__ico" @click="clearLink()">
          <Icon name="toolbar-trash"/>
        </button>
      </div>
    </div>
    <div class="wy-toolbar__bar wy-toolbar__tags" v-else-if="tab === 'tags'">
      <Responders
        :value="tagsValue"
        ref="tags"
        list="tags"
        :autofocus="true"
        @input="onTagsInput"
      />
    </div>
  </div>
</template>
<script>
import Quill from 'quill';
import EventsService from 'services/events.service';
import { hasParentWithClasses } from 'helpers/ui';
import { getScrollPosition } from 'utils/scroll';

function contains(parent, descendant) {
  try {
    descendant.parentNode;
  } catch (e) {
    return false;
  }
  return parent.contains(descendant);
}

function bubbleFormats(blot, formats = {}, filter = true) {
  if (blot == null) return formats;
  if (typeof blot.formats === 'function') {
    formats = {
      ...formats,
      ...blot.formats(),
    };
    if (filter) {
      // exclude syntax highlighting from deltas and getFormat()
      delete formats['code-token'];
    }
  }
  if (
    blot.parent == null ||
    blot.parent.statics.blotName === 'scroll' ||
    blot.parent.statics.scope !== blot.statics.scope
  ) {
    return formats;
  }
  return bubbleFormats(blot.parent, formats, filter);
}

export default {
  name: 'Toolbar',
  props: {
  },
  data() {
    return {
      tab: '',
      formats: {},
      urlInput: '',
      showTags: true,
    };
  },
  mounted() {
    EventsService.on('editor:selection-change', this.reposition);
    EventsService.on('editor:open-tags', this.openTags);
    EventsService.on('editor:edit-link', this.editLink);
  },
  unmounted() {
    EventsService.off('editor:selection-change', this.reposition);
    EventsService.off('editor:open-tags', this.openTags);
    EventsService.off('editor:edit-link', this.editLink);
  },
  computed: {
    tagsValue() {
      if (!this.formats.tag) {
        return [];
      }

      return this.formats.tag.map((id) => ({ id }));
    },
    linkValue() {
      return this.formats.link;
    },
    methodId() {
      return this.$store.getters.projectCurrentMethodId;
    },
  },
  watch: {
    methodId() {
      this.$el.classList.remove('wy-toolbar--show');
    },
  },
  methods: {
    setType(type) {
      this.editorComponent.setType(type);
    },
    formatToggle(name) {
      this.format(name, !this.formats[name])
    },
    format(name, value) {
      this.editor.format(name, value);
      this.refreshFormats();
    },

    reposition(e) {
      const selection = document.getSelection();
      
      if (selection.rangeCount === 0) {
        this.$el.classList.remove('wy-toolbar--show');
        return;
      }

      const range = selection.getRangeAt(0);
      const wysiwyg = hasParentWithClasses(range.endContainer, ['wysiwyg__editor']);

      if(!wysiwyg || !hasParentWithClasses(range.startContainer, ['wysiwyg__editor'])) {
        this.$el.classList.remove('wy-toolbar--show');
        return;
      }

      const rect = range.getBoundingClientRect();
      const left = rect.x + (rect.width / 2);
      const top = rect.y + rect.height + getScrollPosition().top;

      if (range.collapsed) {
        this.$el.classList.remove('wy-toolbar--show');
      } else {
        Object.assign(this.$el.style, {
          top: `${top}px`,
          left: `${left}px`,
        })
        this.$el.classList.add('wy-toolbar--show');
        this.tab = '';
      }

      this.editor = wysiwyg.editor;
      this.editorComponent = wysiwyg.editorComponent;

      this.showTags = this.editorComponent.tagsEnabled;

      this.refreshFormats();
    },

    refreshFormats() {
      const selection =  this.editor.getSelection(true);
      const formats = this.editor.getFormat(selection);
      this.formats = formats;
    },

    selectionChange() {
      this.reposition();
    },

    onMouseDown(e) {
      e.stopPropagation();
    },
    onTagsInput(tags) {
      this.format('tag', tags.map((t) => t.id));
    },
    openTab(name) {
      if (name === 'link' && !this.formats.link) {
        name = 'edit-link';
      }

      if (name === 'edit-link') {
        this.urlInput = this.formats.link;
      }

      this.tab = name;
    },
    openTags() {
      this.openTab('tags');
    },
    editLink() {
      this.openTab('link');
    },
    icoClasses(name) {
      return {
        'is-active': !!this.formats[name],
      }
    },
    saveLink() {
      this.format('link', this.urlInput);
      this.openTab('');
    },
    clearLink() {
      this.format('link', null);
      this.openTab('');
    },
    openLink() {
      window.open(this.formats.link, '_blank');
    },
    urlInputEnter(e) {
      this.saveLink();
      e.stopPropagation();
      e.preventDefault();
    },
  },
};
</script>
