<template>
  <div
    class="notification"
    :class="classes"
  >
    <BaseSpinner v-if="isLoading" />
    <div class="notification__body">
      {{ icon }}
      <span v-html="text" />
    </div>
    <div class="notification__footer">
      <span
        v-if="isUndo"
        class="notification__action"
        @click="undo"
      >
        Undo
      </span>
      <button
        v-if="!isLoading"
        type="button"
        class="notification__close"
        @click="hide"
      />
    </div>
  </div>
</template>

<script>
import { ERROR, LOADING, SUCCESS, UNDO, WARNING } from 'constants/notifications';

export default {
  name: 'Notification',
  props: ['data'],
  computed: {
    text() {
      return this.data.text || '';
    },
    type() {
      return this.data.type || '';
    },
    icon() {
      return this.data.icon;
    },
    isUndo() {
      return this.type === UNDO;
    },
    isLoading() {
      return this.type === LOADING;
    },
    classes() {
      return {
        success: this.type === SUCCESS,
        error: this.type === ERROR,
        warning: this.type === WARNING,
        loading: this.type === LOADING,
      };
    },
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    undo() {
      this.$emit('undo');
    },
  },
};
</script>
