<template>
  <div
    class="header-wrap header-wrap--public"
  >
    <header
      class="header-public"
    >
      <PageAvatar
        class="header-public__logo"
        :page="{ projects: {} }"
      />
    </header>
  </div>
</template>

<script>

export default {
  name: 'HeaderPublic',
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  unmounted() {
  },
  methods: {
  },
};
</script>