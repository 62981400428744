<template>
  <BaseModal
    name="invoices"
    :show-close="true"
  >
    <div class="modal-container">
      <header class="modal__header">
        <h2>
          Invoices
        </h2>
      </header>
      <div
        v-for="(item, index) in invoices"
        :key="index"
        class="settings__list"
      >
        <div class="invoice__year">
          <h2>{{ item.year }}</h2>
        </div>
        <div>
          <li
            v-for="(singleInvoice, index) in item.invoices"
            :key="index"
          >
            <div class="settings__list-left invoices__left">
              {{ singleInvoice.date }}
            </div>
            <div class="settings__list-left invoices__middle">
              {{ singleInvoice.value }}
            </div>
            <div
              v-if="singleInvoice.downloadUrl"
              class="settings__list-right invoices__right"
            >
              <a
                class="invoice__download"
                :href="singleInvoice.downloadUrl"
              >Download</a>
            </div>
            <div
              v-else
              class="settings__list-right invoices__right"
            >
              <strong class="t-disabled">Pending...</strong>
            </div>
          </li>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
import { reverse } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'Invoices',
  components: {
    BaseModal,
  },
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      modalShown: true,
    };
  },
  created() {
    document.addEventListener('keyup', this.hideUsingEscapeKey);
  },
  unmounted() {
    document.removeEventListener('keyup', this.hideUsingEscapeKey);
  },
  computed: {
    ...mapGetters(['user']),
    invoices() {
      if (!this.user || !this.user.invoices) return [];

      const invoices = [ ...this.user.invoices ];

      if (this.$store.getters.hasPendingPayment) {
        invoices.unshift({
          date: (new Date()).toLocaleString('en-us', {year: 'numeric', month: 'long', day: '2-digit'}),
        });
      }

      const invoicesByYear = invoices.reduce((r, a) => {
        r[a.date.substr(a.date.length - 4)] =
          r[a.date.substr(a.date.length - 4)] || [];
        r[a.date.substr(a.date.length - 4)].push(a);
        return r;
      }, Object.create(null));

      const invoicesToArray = Object.keys(invoicesByYear).map((item) => ({
        year: item,
        invoices: invoicesByYear[item],
      }));

      const reversedInvoices = reverse(invoicesToArray);

      return reversedInvoices;
    },
  },
  methods: {
    stopPropagation(e) {
      e.stopPropagation();
    },
    hide() {
      this.$emit('hide');
    },
    hideUsingEscapeKey(e) {
      if (e.key === 'Escape') {
        this.hide();
      }
    },
  },
};
</script>
<style lang="scss">
.invoices-modal-modal-wrapper {
  max-width: 691px;
  min-height: 591px;
  background-color: #fff;
  position: relative;
  border-radius: 3px;
}
</style>
<style scoped lang="scss">
.modal {
  &__header {
    display: flex;
    justify-content: center;
    margin: 58px 0 10px 0;
    h2 {
      margin-top: 0;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  &__container {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.51);
    z-index: 999;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
}
.settings__list {
  margin-top: 0px;
  margin-bottom: 0px;
  li {
    padding: 16px 0;
    border-bottom: none;
  }
}
.invoice {
  &__year {
    margin-top: 30px;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #f2f2f2;
    h2 {
      font-size: 11px;
      line-height: 1.36;
      letter-spacing: 0.8px;
      font-weight: normal;
      color: #414141;
      opacity: 0.5;
      margin: 0px;
    }
  }
  &__left {
    margin-left: 16px;
    font-size: 14px;
    color: #3b3b3b;
    line-height: 1.5;
  }
  &__middle {
    font-size: 14px;
    color: #646464;
    position: absolute;
    transform: translateX(175px);
  }
  &__right {
    margin-right: 32px;
  }
  &__download {
    font-size: 14px;
    color: #252729;
    font-weight: bold;
    text-decoration: none;
  }
}
@media only screen and (min-width: 582px) {
  .invoices {
    &__left {
      font-size: 16px;
      color: #3b3b3b;
      line-height: 1.5;
    }
    &__middle {
      font-size: 14px;
      color: #3b3b3b;
      position: absolute;
      transform: translateX(280px);
    }
    &__download {
      font-size: 14px;
      color: #252729;
      font-weight: bold;
      text-decoration: none;
    }
  }
}
</style>
