<template>
  <BaseSpinner
    v-if="!isFetched"
    :class="{ 'sidebar-margin': !isShared && !isEditing }"
    :full-height="true"
  />
  <Forbidden
    v-else-if="isProjectCurrentMethodForbidden"
    key="forbidden"
  />
  <div
    v-else-if="component && method && (projectCurrent || isShared)"
    :key="method && method.id || methodId"
    :class="{ 'sidebar-page sidebar-page--no-margin-bottom': !isShared && !isEditing }"
  >
    <div :class="`method-page analytics__method-${ (method.type || '').toLowerCase() }-show`">
      <component
        :is="component"
        v-if="component"
        :title-menu-actions="[
          {
            text: 'Archive Page',
            action: () => onArchiveMethod(method),
            hidden: isShared || !canEdit,
          }
        ]"
      />
      <Tutorial
        :method="method"
        :name="method.type.toLowerCase()"
      />
    </div>
  </div>
</template>

<script>
import Forbidden from 'components/forbidden';
import Tutorial from 'components/tutorial';
import { navigateProject, navigateProjects, navigateToMethod } from 'helpers/router';
import { byPosition } from 'helpers/sort';
import store from 'stores';
import { analyticsLogEvent } from 'utils/analytics';
import { mapGetters } from 'vuex';

import MethodBuilder from './method-builder';

export default {
  name: 'ProjectMethod',
  components: {
    Forbidden,
    Tutorial,
  },
  data() {
    return {
      selectedMethod: null,
      isDeleteModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      projectCurrent: 'projectCurrent',
      projectCurrentId: 'projectCurrentId',
      method: 'projectCurrentMethod',
      isFetched: 'isProjectCurrentMethodFetched',
      methodId: 'projectCurrentMethodId',
      phases: 'projectPhases',
      isShared: 'isShared',
      isEditing: 'isEditing',
      canEdit: 'userCanEditProjects',
      isProjectForbidden: 'isProjectForbidden',
      isProjectCurrentMethodForbidden: 'isProjectCurrentMethodForbidden',
      isPublicRoute: 'isPublicRoute',
    }),
    component() {
      if (!this.method) return null;
      return MethodBuilder;
    },
  },
  watch: {
    methodId(id) {
      if (id) {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {
    this.destroyMethod();
  },
  methods: {
    async fetchData() {
      if (!this.$store.getters.projectCurrent && !this.isPublicRoute) {
        await this.$store.dispatch('projectFetch');
      }

      this.$store.dispatch('projectMethodFetch');

      if (!this.isPublicRoute) {
        this.$store.dispatch('fetchHighlights');
      }
    },
    async initMethod(type, StoreModule) {
      this.destroyMethod();
      
      if (StoreModule) {
        this.$store.registerModule(type, StoreModule, { preserveState: false });
        this.registeredMethodType = type;
        this.unwatchStore = this.$store.watch(
          (state, getters) => getters.projectCurrentMethodId,
          () => {
            this.$store.dispatch('methodInit');
          }
        );
      }
      
      await this.$store.dispatch('methodInit');
    },
    destroyMethod() {
      if (this.registeredMethodType) {
        this.unwatchStore();
        this.$store.unregisterModule(this.registeredMethodType);
        this.registeredMethodType = null;
      }
    },
    async onArchiveMethod(method) {
      const { id } = method;
      const projectId = this.projectCurrentId;
      const projectCurrentMethod = this.$store.getters.projectCurrentMethod;

      this.selectedMethod = false;
      await this.$store.dispatch('projectMethodChange', { id, data: { archived: true } });

      navigateProject(projectId);

      analyticsLogEvent(`Archive method - ${ projectCurrentMethod && projectCurrentMethod.type }`);
    },
  },
};
</script>
