<template>
  <div
    class="base-card-add"
    :class="{
      'base-card-add--active': active,
      'base-card-add--survey': surveyMode,
    }"
    @click="$refs.input.focus()"
  >
    <div class="base-card-add__container">
      <div class="base-card-add__body">
        <BaseInput
          ref="input"
          :value="content"
          class="base-card-add__input"
          :placeholder="placeholder"
          :tabindex="cardTabindex"
          @focus="onInputFocus"
          @blur="onInputBlur"
          @keyup.enter.prevent="submit"
          @change="submit"
          @input="content = $event"
        />
      </div>
      <div
        v-if="canEditTags && showTags"
        class="base-card-add__foot"
      >
        <TagsAvatarsSelector
          v-model="answerTags"
          class="base-card-add__tags"
          :tags="newTags"
          @toggle="tagsOpen = $event"
          @input="onTagsInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import TagsAvatarsSelector from 'views/page/common/tags-avatars-selector';
import { mapGetters } from 'vuex';

export default {
  name: 'CardAdd',
  components: {
    TagsAvatarsSelector,
  },
  emits: ['add', 'tags-change', 'toggle'],
  props: {
    placeholder: {
      type: String,
      default: 'Add card...',
    },
    newTags: {
      type: Array,
      default: () => [],
    },
    cardTabindex: {
      type: String,
      default: '0',
    },
    surveyMode: {
      type: Boolean,
      default: false,
    },
    value: String,
    canEditTags: Boolean,
    showTags: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      content: '',
      tagsOpen: false,
      focused: false,
    };
  },
  watch: {
    active() {
      this.$emit('toogle', this.active);
    },
  },
  computed: {
    ...mapGetters(['tags']),
    active() {
      return this.tagsOpen || this.focused;
    },
    answerTags: {
      get() {
        return this.newTags;
      },
      set(value) {
        this.$emit('tags-change', value);
      },
    },
  },
  mounted() {
    this.content = this.value;
    this.submit = debounce(this.submit, 500, {
      leading: true,
      trailing: false,
    });
  },
  methods: {
    submit(e) {
      if (!this.content.length && !this.surveyMode) return;
      this.$emit('add', this.content);
      if (this.surveyMode) return;
      this.content = '';
    },
    onInputFocus() {
      this.focused = true;
    },
    onInputBlur() {
      this.focused = false;
    },
    onTagsInput(tags) {
      this.$emit('tags-change', tags);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-card-add {
  $self: &;
  margin-left: -15px;
  position: relative;
  cursor: text;

  &--survey {
    margin-left: 0;
  }

  &__container {
    border-radius: 3px;
    color: #3b3b3b;
    font-size: 1.6rem;
    padding: 5px 5px 5px 14px;
    position: relative;
    transition: background-color 150ms, border 150ms;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: solid 1px transparent;
    min-height: 48px;

    #{ $self }--survey & {
      background-color: #F8F9FA;
      border-color: #F8F9FA;
      padding-top: 13px;
      padding-bottom: 13px;
    }

    #{ $self }:hover &,
    #{ $self }--active & {
      background-color: #F8F9FA;
      border-color: #F8F9FA;
    }

    #{ $self }--survey#{ $self }:hover &,
    #{ $self }--survey#{ $self }--active & {
      background-color: #F0F2F5;
      border-color: #F0F2F5;
    }

    #{ $self }--survey#{ $self }--active &,
    #{ $self }--survey#{ $self }--active:hover & {
      border-color: #DFE2E8;
      background-color: #F8F9FA;
    }
  }

  &__body {
    width: 100%;
  }

  &__input {
    display: block;
    min-height: 100%;
    border: 0;
    background: transparent;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: left;
    padding: 0;
    color: #3b3b3b;
    width: 100%;
    outline: 0;
    resize: none;
    height: auto;
    line-height: 30px;
    height: 30px;
  }

  &__foot {
    display: flex;
    align-items: center;
    cursor: default;
  }

  &__tags {
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover &__tags,
  #{ $self }--active &__tags {
    opacity: 1;
  }
}
</style>
