<template>
  <div class="archive-item">
    <span class="archive-item__body">
      <span class="archive-item__icon">
        <span
          class="tag-colour"
          :style="{ backgroundColor: data.color }"
        />
      </span>
      <div>
        {{ data.name }}
      </div>
      <div class="archive-item__foot">
        {{ data.itemsCount }}
      </div>
    </span>
  </div>
</template>

<script>

export default {
  name: 'ProjectArchiveItemTag',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
