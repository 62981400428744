<template>
  <div class="page-avatar-picker">
    <BaseTooltip
      v-if="canEdit"
      behaviour="click"
      appearance="light-box"
      @toggle="(state) => open = state"
    >
      <div
        v-if="!$slots.default"
        class="avatar-dropdown"
        :class="{'avatar-dropdown--open': open}"
      >
        <PageAvatar :page="page" />
        <Icon
          class="avatar-dropdown__icon"
          name="chevron-down"
        />
      </div>
      <slot v-else />
      <template v-slot:content>
        <div />
        <div
          v-if="!imageOnly"
          class="tooltip-tabs"
        >
          <button
            v-for="t in ['Icon', 'Image']"
            :key="t"
            class="tooltip-tabs__tab"
            :class="{
              'tooltip-tabs__tab--active': tab === t
            }"
            @click="tab = t"
          >
            <span>{{ t }}</span>
          </button>
        </div>
        <ContentTransition>
          <div
            v-if="tab === 'Icon' && !imageOnly"
            key="icon"
          >
            <div class="page-avatar-picker__section">
              <Icons
                :color="color"
                @change="iconChange"
              />
            </div>
            <div class="page-avatar-picker__section">
              <Swatches
                appearance="squared"
                :color="color"
                @change="colorChange"
              />
            </div>
            <div class="page-avatar-picker__section">
              <div class="color-input">
                <div
                  class="color-input__color"
                  :style="colorStyle"
                />
                <div class="color-input__wrap">
                  <BaseInput
                    class="color-input__input"
                    :value="inputColor"
                    @input="inputChange"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="tab === 'Image' || imageOnly"
            key="image"
          >
            <div class="page-avatar-picker__section">
              <BaseUploader
                :can-edit="true"
                class="page-avatar-picker__uploader"
                :uploading="image.uploading"
                :file="image.file"
                :url="image.url"
                @remove="$emit('removeImage', $event)"
                @change="$emit('addImage', $event)"
              />
            </div>
          </div>
        </ContentTransition>
      </template>
    </BaseTooltip>
    <PageAvatar
      v-else-if="!$slots.default"
      :page="page"
    />
    <slot v-else />
  </div>
</template>

<script>
import { validateHexColor } from 'helpers/colors';

import Icons from './icons';
import Swatches from './swatches';

export default {
  name: 'PageAvatarPicker',
  components: {
    Swatches,
    Icons,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      open: false,
      tab: 'Icon',
    };
  },
  computed: {
    color() {
      return this.page.color || '#192262';
    },
    icon() {
      return this.page.icon || 'method-notepad-method';
    },
    colorStyle() {
      return { backgroundColor: this.color };
    },
    inputColor() {
      return this.color.replace(/^\#/gim, '');
    },
    image() {
      if (typeof this.page.image === 'string') {
        return { url: this.page.image };
      }
      return this.page.image || {};
    },
  },
  watch: {
    open(open, old) {
      if (open !== old) {
        this.$emit('toggle', open);
      }
    },
  },
  methods: {
    colorChange(color) {
      this.$emit('color-change', color);
    },
    iconChange(icon) {
      this.$emit('icon-change', icon);
    },
    inputChange(color) {
      color = '#' + color.replace(/^\#/gim, '');
      if (!validateHexColor(color)) return;
      this.$emit('color-change', color);
    },
  },
};
</script>
