<template>
  <Forbidden v-if="isForbidden" />
  <div
    v-else-if="fetched"
    :class="{ 'sidebar-page': !isShared }"
  >
    <div class="container">
      <methods-view
        :project="projectCurrent"
        :phases="projectPhases"
      />
    </div>
  </div>
  <Spinner v-else />
</template>

<script>
import Spinner from 'components/common/base-spinner';
import { mapGetters } from 'vuex';

import Forbidden from 'components/forbidden';
import MethodsView from './view';

export default {
  name: 'ProjectMethods',
  components: {
    MethodsView,
    Spinner,
    Forbidden,
  },
  data: () => ({
    notAccessible: false,
  }),
  computed: {
    ...mapGetters([
      'projectCurrent',
      'projectMethods',
      'projectPhases',
      'isProjectPhasesFetched',
      'isProjectFetched',
      'isShared',
      'isProjectForbidden',
    ]),
    fetched() {
      return this.isProjectPhasesFetched && this.isProjectFetched;
    },
    isForbidden() {
      return this.isProjectForbidden || this.notAccessible;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        await Promise.all([
          this.$store.dispatch('projectFetch'),
          this.$store.dispatch('phasesFetch'),
        ]);

        if (!this.projectCurrent.accessible) {
          this.notAccessible = true;
        }
      } catch (error) {
      }
    },
  },
};
</script>
