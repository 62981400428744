import * as TYPES from 'constants/mutations';
import { TAG_COLORS } from 'constants/tag-colors';
import sample from 'lodash/sample';
import CardsService from 'services/cards.service';
import TagService from 'services/tag.service';
import TagsService from 'services/tags.service';
import WorkspacesService from 'services/workspaces.service';
import ProjectMethodService from 'services/project-method.service';
import { analyticsLogEvent } from 'utils/analytics';
import generateId from 'utils/id';
import { merge } from 'utils/reactive';

// New tag name prefix
const NEW_NAME_PREFIX = 'New tag';

const state = {
  tags: [], // All tags, indexed by tag ID
  workspaceTags: {}, // Projects with assigned tags lists
};

const getters = {
  allTags: (state, getters) => {
    return getters.tags;
  },

  //Get list of all current project tags
  tags: (state, getters) => {
    const workspaceCurrentId = getters.workspaceCurrentId;
    return (state.workspaceTags[workspaceCurrentId] || []).map((id) => getters.tagById(id)).filter((t) => t && !t.archived);
  },

  tagsInWorkspace: (state, getters) => (workspaceId) => {
    return getters.allTags;
  },

  // Get tag data by tag ID
  tagById: (state, getters) => (tagId) => {
    let found = state.tags.find((t) => t.id === tagId || t.localId === tagId);
    return found && {
      ...found,
      items: (found.items || []).map((i) => {
        const highlight = getters.highlightById(i.id);

        if (highlight) {
          return { ...i, ...highlight, cardType: 'Sentence' };
        }

        return i;
      }),
    };
  },

  // Get project tags fetch status
  isTagsFetched: (state, getters) => {
    const workspaceCurrentId = getters.workspaceCurrentId;
    return !!state.workspaceTags[workspaceCurrentId];
  },

  isProjectTagsFetched: (state, getters) => (projectId) => {
    return !!getters.isTagsFetched;
  },

  // Get tag fetch status by tag ID
  isTagFetchedById: (state) => (id) => {
    let found = state.tags.find((t) => t.id === id);
    return found && found.fetched;
  },

   // Get next free name
   newTagName: (state, getters) => {
    if (!getters.tags.find(({ name }) => name === NEW_NAME_PREFIX )) return NEW_NAME_PREFIX;
    let counter = 0;
    const getName = () => `${ NEW_NAME_PREFIX }${ counter ? ' ' + counter : '' }`;

    while (getters.tags.find(({ name }) => name === getName())) {
      counter ++;
    }

    return getName();
  },
};

const actions = {
  async fetchTags({ commit, getters, dispatch }, { projectId } = {}) {
    await dispatch('requireWorkspaces');

    const { workspaceCurrentId, routeShortcutToken } = getters;
    let tags;
    let extend = false;

    if (routeShortcutToken) {
      const sentences = await ProjectMethodService.fetchPublicSentences(routeShortcutToken);
      tags = sentences.map((sentence) => sentence.tags).flat();
      extend = true;
    } else {
      tags = await WorkspacesService.fetchTags(workspaceCurrentId);
    }

    const tagsList = Object.entries(tags).map(([_projectId, list]) => {
      return list;
    }).flat();

    commit('TAGS_LIST_UPDATE', { tags: tagsList, workspaceId: workspaceCurrentId, extend })
  },

  /**
   * Fetch all tags for current project 
   */
  requireTags({ getters, dispatch, state }, { projectId } = {}) {
    const { workspaceCurrentId } = getters;

    if (!this.tagsLoading) {
      this.tagsLoading = {};
    }

    if (!state.workspaceTags[workspaceCurrentId] && !this.tagsLoading[workspaceCurrentId]) {
      this.tagsLoading[workspaceCurrentId] = dispatch('fetchTags').then(() => this.tagsLoading[workspaceCurrentId] = false);
    }

    return this.tagsLoading[workspaceCurrentId];
  },

  /**
   * Create new tag in current project
   */
  async tagAdd({ commit, dispatch, getters }, data) {
    const { workspaceCurrentId, projectCurrentId } = getters;

    const localId = data.id || generateId();
    const color = data.color || sample(TAG_COLORS);
    const name = data.name || getters.newTagName;
    const tag = {
      ...data,
      items: [],
      itemsCount: 0,
      color,
      name,
      id: localId,
      localId,
    };

    commit(TYPES.TAG_ADD, { tag, workspaceId: workspaceCurrentId });
    const result = await TagsService.add(workspaceCurrentId, getters.projectCurrentId, tag);
    dispatch('registerIdJoin', { join: result.id, id: localId });
    commit(TYPES.TAG_UPDATE, { id: localId, data: result });

    const projectCurrentMethod = getters.projectCurrentMethod;
    analyticsLogEvent(`Tag created - ${ projectCurrentMethod && projectCurrentMethod.type }`);

    return result;
  },
  
  /**
   * Update tag props
   */
  async tagUpdate({ commit, getters, dispatch }, { id, data }) {
    const { workspaceCurrentId } = getters;
    const tag = getters.tagById(id);

    if (!tag) {
      throw new Error('No tag found!');
    }

    commit(TYPES.TAG_UPDATE, { id: tag.id, data });

    if (data.archived) {
      dispatch('uiToggleArchiving', { type: 'Tag', data: tag });
    }

    const result = await TagsService.update(tag.workspaceId, tag.projectId, tag.id, data);
    return result;
  },

  /**
   * Remove tag
   */
  async tagRemove({ commit, getters, dispatch }, { id }) {
    const tag = getters.tagById(id);

    if (!tag) {
      throw new Error('No tag found!');
    }

    commit(TYPES.TAG_REMOVE, { id: tag.id });

    const result = await TagsService.remove(tag.workspaceId, tag.projectId, tag.id);

    return result;
  },

  /**
   * Fetch tag details
   */
  async tagFetch({ commit, getters }, { id }) {
    if (!id) {
      throw new Error('Can\'t fetch Tag with undefined ID');
    }

    const tag = getters.tagById(id);
    let projectId = getters.projectCurrentId;

    if (!projectId && tag) {
      projectId = tag.projectId;
    }

    const data = await TagService.fetch(getters.workspaceCurrentId, projectId, id);
    data.fetched = true;

    commit(TYPES.TAG_UPDATE, { id, data });
  },

  async updateTagItem({ commit, getters }, { tagId, id, data }) {
    commit(TYPES.TAG_CARD_UPDATE, { tagId, id, data });
  },
};
const mutations = {
  [TYPES.TAGS_SET](state, { projectId, tags }) {
    tags.forEach((t) => {
      let tag = state.tags.find((tg) => tg.id === t.id)
      if (tag) {
        merge(tag, t);
      } else {
        state.tags.push(t);
      }
    })

    tags = tags.map((t) => t.id);

    state.projectsTags = { ...state.projectsTags, [projectId]: tags };
  },
  [TYPES.TAG_ADD](state, { workspaceId, tag }) {
    if (!state.workspaceTags[workspaceId]) {
      state.workspaceTags[workspaceId] = [];
    }

    const workspaceTags = [...state.workspaceTags[workspaceId], tag.id];

    state.tags = [...state.tags, tag];
    state.workspaceTags = { ...state.workspaceTags, [workspaceId]: workspaceTags };
  },
  [TYPES.TAG_UPDATE](state, { id, data }) {
    const tag = state.tags.find((t) => t.id === id || t.localId === id);

    if (tag) {
      merge(tag, data);
    } else {
      state.tags = [...state.tags, data];
    }
  },
  TAG_REMOVE(state, { id }) {
    const tag = state.tags.find((t) => t.id === id || t.localId === id);
    const { workspaceId } = tag;
    const tags = state.workspaceTags[workspaceId].filter((id) => id !== tag.id);

    state.tags = state.tags.filter((t) => t.id !== tag.id);
    state.workspaceTags = { ...state.workspaceTags, [workspaceId]: tags };
  },
  TAGS_LIST_UPDATE(state, { workspaceId, tags, extend = false }) {
    tags.forEach((t) => {
      let tag = state.tags.find((tg) => tg.id === t.id);

      if (tag) {
        merge(tag, t);
      } else {
        state.tags.push(t);
      }
    });

    tags = tags.map((t) => t.id);

    if (extend) {
      const oldList = state.workspaceTags[workspaceId] || [];
      oldList.forEach((id) => {
        if (!tags.contains(id)) {
          tags.push(id);
        }
      })
    }
    
    state.workspaceTags = { ...state.workspaceTags, [workspaceId]: tags };
  },
  [TYPES.TAG_CARD_UPDATE](state, { tagId, id, data }) {
    const tag = state.tags.find((t) => t.id === tagId);
    if (!tag) return;
    const items = tag.items;

    if (items && items.length) {
      const item = items.find((item) => item.id === id);
      if (item) {
        merge(item, data);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
