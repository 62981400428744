<template>
  <div class="">
    <SortableCards
      :data-transport="section.id"
      :new-tags="newTags"
      :cards="fields"
      :actions="sortableCardsActions"
      :can-edit="canEdit"
      card-mode="check"
      :survey-mode="surveyMode"
      value-param="name"
      page-param="value"
      add-placeholder="Add option..."
      empty-remove-text="remove"
      :show-tags="false"
      :show-responders="true"
      @change="onChange"
      @card-action="onAnswerAction"
      @card-change="onAnswerChange"
      @card-tags-change="onTagsChange"
      @cardResponderSelect="onResponderAdd"
      @cardResponderUnselect="onResponderRemove"
      @card-add="addAnswer"
      @add-tags-change="onNewTagsChange"
    />
  </div>
</template>

<script>
import { byPosition } from 'helpers/sort';

export default {
  name: 'ProjectMethodCustom',
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
    surveyMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      newTags: [],
    };
  },
  computed: {
    sortableCardsActions() {
      return [
        {
          text: 'Remove',
          action: 'remove',
        },
      ];
    },
    fields() {
      const fields = byPosition(this.section.customItemFields).filter((f) => !f.responder).map((f) => ({ ...f }));
      const responses = byPosition(this.section.customItemFields).filter((f) => f.responder).map((f) => ({ ...f }));

      let scaleMax = 0;

      fields.forEach((field, i) => {
        field.scaleMax = 0;
        field.scaleValue = 0;
        field.color = this.section.color;
        field.responders = [];
        field.disableTags = true;
      });

      responses.forEach((response, i) => {
        const ids = JSON.parse(response.value);
        if (ids && ids.length) {
          ids.forEach((id, i) => {
            const field = fields.find((f) => f.id === id);
            if (field) {
              field.responders.push(response.responder);
              field.scaleValue = (field.scaleValue || 0) + 1;
              scaleMax++;
            }
          });
        }
      });

      fields.forEach((field, i) => {
        field.scaleMax = scaleMax || 0;
        field.scaleValue = field.scaleValue || 0;
      });

      return fields;
    },
    taggable() {
      return !!this.fields.length;
    },
  },
  methods: {
    onAnswerAction({ action, card }) {
      if (action === 'remove' || action === 'archive') {
        this.$store.dispatch('builderRemoveSectionItem', { sectionId: this.section.id, id: card.id });
      }

      if (action === 'select') {
        this.$store.dispatch('surveySelectItem', { sectionId: this.section.id, id: card.id });
      }
    },
    addAnswer(name) {
      this.$store.dispatch('builderAddSectionItem', { id: this.section.id, data: {
        name,
        tags: this.newTags,
      } });
    },
    onChange({ id, newIndex, transport }) {
      this.$store.dispatch('builderMoveSectionItem', { sectionId: this.section.id, id, newSectionId: transport, newIndex });
    },
    onAnswerChange(id, data) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data });
    },
    onTagsChange(id, tags) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: {
        tags,
        tag_list: tags,
      } });
    },
    onRespondersChange(id, responders) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: {
        responders,
      } });
    },
    onNewTagsChange(tags) {
      this.newTags = tags;
    },
    addTag(tag) {
      this.section.customItemFields.forEach((field) => {
        const newTags = [...field.tags];

        if (!newTags.find((t) => t.id === tag.id)) {
          newTags.push(tag);
        }

        this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id: field.id, data: {
          tags: newTags,
          tag_list: newTags,
        } });
      });
    },
    removeTag(tag) {
      this.section.customItemFields.forEach((field) => {
        const newTags = field.tags.filter((t) => t.id !== tag.id);

        this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id: field.id, data: {
          tags: newTags,
          tag_list: newTags,
        } });
      });
    },
    isEmpty(data) {
      return !(data.customItemFields && data.customItemFields.length);
    },
    onResponderAdd({ card, responder }) {
      this.$store.dispatch('builderAddResponderChoice', {
        id: this.section.id,
        responder,
        responseId: card.id,
      });
    },
    onResponderRemove({ card, responder }) {
      this.$store.dispatch('builderRemoveResponderChoice', {
        id: this.section.id,
        responder,
        responseId: card.id,
      });
    },
  },
};
</script>
