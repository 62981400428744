/* eslint-disable no-shadow */

import { PAYMENT, PAYMENT_METHOD } from 'constants/modals';
import * as TYPES from 'constants/mutations';
import { PLANS } from 'constants/user';
import PaymentsService from 'services/payments.service';
import SubscriptionService from 'services/subscription.service';
import { titleize } from 'utils/text';

const moduleState = {
  currency: 'usd',
  currencySymbol: '$',
  workspacePlans: [],
  paymentMethods: [],
  current: null,
};

const getters = {
  currencySymbol: (state) => state.currencySymbol,
  pricingPlans: (state, getters) => {
    const workspace = state.workspacePlans.find((w) => w.id === getters.workspaceCurrentId);
    if (!workspace) return [];
    return workspace.plans.filter((plan) => {
      return !plan.kind.match(/\_2020/gim);
    }).map((plan) => {
      plan.name = titleize(plan.kind.replace(/\_2020/gim, ''));
      return plan;
    });
  },
  paymentMethods: (state) => state.paymentMethods,
  pricingMonthly: (state, getters) =>
    getters.pricingPlans
      .filter(({ period }) => period === 'month')
      .reduce((plans, plan) => {
        plans[plan.kind] = +(state.currency === 'eur' ? plan.priceEuro : plan.price);
        return plans;
      }, {}),
  pricingYearly: (state, getters) =>
    getters.pricingPlans
      .filter(({ period }) => period === 'year')
      .reduce((plans, plan) => {
        plans[plan.kind] = +(state.currency === 'eur' ? plan.priceEuro : plan.price) / 12;
        return plans;
      }, {}),

  pricingSavings: (state, getters) =>
    PLANS.reduce((savings, kind) => {
      savings[kind] =
        12 * (getters.pricingMonthly[kind] - getters.pricingYearly[kind]);
      return savings;
    }, {}),
  pricings: (state, getters) => ({
    monthly: getters.pricingMonthly,
    yearly: getters.pricingYearly,
  }),

  planByKindAndPeriod: (state, getters) => (kindType, periodType) => {
    const periodName = periodType === 'yearly' ? 'year' : 'month';
    return getters.pricingPlans.find(
      ({ period, kind }) => period === periodName && kind === kindType
    );
  },
  getPlanName: (state, getters) => (type) => {
    if (type === 'inactive') {
      return 'Personal';
    }
    const plan = getters.planByKindAndPeriod(type, 'yearly');
    return plan ? plan.name : titleize(type);
  },
  planKindById: (state, getters) => (id) => {
    const plan = state.plans.find((p) => {
      return p.id === id;
    });
    return plan && plan.kind;
  },
  pricingModalData: (state) => state.current,
  pricingLowest: (state, getters) => getters.pricingYearly.starter,
  userHasUnavailablePlan: (state, getters) => {
    const userPlan = getters.userPlan;
    return userPlan && userPlan !== 'trial' && !!userPlan.match(/\_2020/gim);
  },
  nextPlan: (state, getters) => {
    const userPlan = getters.userPlan;

    if (!userPlan) return 'starter';

    let nextName;
    const plans = getters.pricingPlans.filter(({ period }) => period === 'year').sort((a, b) => a.price * 1 - b.price * 1);

    if (!plans.length) {
      return 'starter';
    }

    for (const plan of plans) {
      if (nextName) {
        nextName = plan.kind;
        break;
      }
      if (plan.kind === userPlan) {
        nextName = plan.kind;
      }
    }

    return nextName || plans[0].kind;
  },
  pricingNext: (state, getters) => {
    const nextPlan = getters.nextPlan;
    return getters.pricingYearly[nextPlan];
  },
};

const actions = {
  init({ dispatch }) {
    dispatch('paymentPlansFetch');
  },
  async paymentPlansFetch({ commit, getters, dispatch }) {
    await dispatch('requireWorkspaces');
    const workspaceId = getters.workspaceCurrentId;
    
    if (!workspaceId) {
      return;
    }

    const plans = await SubscriptionService.getPlans({ workspaceId });
    commit(TYPES.PLANS_SET, { workspaceId, plans });
  },
  async paymentMethodsFetch({ commit }) {
    commit(TYPES.PAYMENT_METHODS_SET, []);
    await PaymentsService.prepare();
    const methods = await PaymentsService.getPaymentMethods();
    commit(TYPES.PAYMENT_METHODS_SET, methods);
  },

  paymentModalShow({ dispatch, commit }, data) {
    commit(TYPES.SHOW_PAYMENT_MODAL, data);
    dispatch('modalShow', PAYMENT);
  },
  paymentMethodModalShow({ dispatch, commit }, data) {
    commit(TYPES.SHOW_PAYMENT_MODAL, data);
    dispatch('modalShow', PAYMENT_METHOD);
  },
};

const mutations = {
  [TYPES.SHOW_PAYMENT_MODAL](state, data) {
    state.current = data;
  },

  [TYPES.PLANS_SET](state, { plans, workspaceId }) {
    const workspace = state.workspacePlans.find((w) => w.id === workspaceId);

    if (workspace) {
      workspace.plans = plans;
    } else {
      state.workspacePlans.push({
        id: workspaceId,
        plans,
      })
    }
  },

  [TYPES.PAYMENT_METHODS_SET](state, data) {
    state.paymentMethods = data;
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
