<template>
  <input
    v-if="!multiline"
    ref="input"
    v-model="myValue"
    :class="{ 'cant-edit': !canEdit }"
    :readonly="!canEdit"
    v-bind="$attrs"
    :placeholder="placeholder"
    :spellcheck="false"
    :maxlength="maxlength"
    @input="onInput"
    @change="onChange"
    @blur="edit(false)"
    @keyup="checkValidate"
    @keydown.enter="onEnter"
    @keydown.esc="edit(false)"
    @mousedown="edit(true)"
    @touchstart="edit(true)"
    @mouseup="mouseup"
  >
  <textarea
    v-else
    ref="input"
    v-model="myValue"
    :class="{ 'cant-edit': !canEdit }"
    :readonly="!canEdit"
    v-bind="$attrs"
    :placeholder="placeholder"
    :spellcheck="false"
    :maxlength="maxlength"
    rows="1"
    @input="onInput"
    @change="onChange"
    @blur="edit(false)"
    @keyup="checkValidate"
    @keydown.enter="onEnter"
    @keydown.esc="edit(false)"
    @mousedown="edit(true)"
    @touchstart="edit(true)"
    @mouseup="mouseup"
  />
</template>
<script>
import autosize from 'autosize';

export default {
  name: 'BaseInput',
  emits: ['input', 'change'],
  props: {
    validate: {
      type: Function,
      required: false,
    },
    multiline: Boolean,
    value: String,
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: Boolean,
    maxlength: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      myValue: null,
      editingName: false,
      preventMouseUp: false,
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (oldValue !== newValue && newValue !== this.myValue) {
        this.myValue = newValue;
      }
      if (this.multiline) {
        autosize.update(this.$refs.input);
      }
    },
  },
  created() {
    this.myValue = this.value;
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
    if(this.multiline) {
      autosize(this.$refs.input);
    }
  },
  updated() {
    if(this.multiline) {
      autosize.update(this.$refs.input);
    }
  },
  unmounted() {
    window.setTimeout(() => {
      if(this.multiline) {
        autosize.destroy(this.$refs.input);
      }
    }, 500);
  },
  methods: {
    onInput() {
      if (this.lazy) return;
      this.$emit('input', this.myValue);
    },
    blur() {
      this.$refs.input.blur();
    },
    focus() {
      this.$refs.input.focus();
    },
    onChange() {
      if (this.myValue && this.myValue.trim().length > 0) {
        this.$emit('change', this.myValue);
        if (this.lazy) this.$emit('input', this.myValue);
      } else {
        this.myValue = this.value;
        this.$refs.input.value = this.value;
      }
    },
    checkValidate() {
      if (!this.validate) return;
      this.validate(this.$refs.input.value);
    },
    select() {
      this.$refs.input.select();
      this.preventMouseUp = true;
    },
    mouseup(e) {
      if (this.preventMouseUp) {
        e.preventDefault();
      }
      this.preventMouseUp = false;
    },
    getCaretPos() {
      const oField = this.$refs.input;
      // Initialize
      var iCaretPos = 0;

      // IE Support
      if (document.selection) {

        // Set focus on the element
        oField.focus();

        // To get cursor position, get empty selection range
        var oSel = document.selection.createRange();

        // Move selection start to 0 position
        oSel.moveStart('character', -oField.value.length);

        // The caret position is selection length
        iCaretPos = oSel.text.length;
      }

      // Firefox support
      else if (oField.selectionStart || oField.selectionStart == '0')
        iCaretPos = oField.selectionDirection=='backward' ? oField.selectionStart : oField.selectionEnd;

      // Return results
      return iCaretPos;
    },
    edit(ifEdit) {
      if (ifEdit && document.activeElement === this.$refs.input) return;

      if (!this.canEdit) {
        this.$refs.input.setSelectionRange(0, 0);
        this.$refs.input.blur();
        return;
      }

      this.editingName = ifEdit;
      if (ifEdit) {
        this.myValue = this.value;
      }

      this.$nextTick(() => {
        if (this.editingName) {
          this.select();
        }
      });
    },
    onEnter(e) {
      this.edit(false);
      this.$emit('enter', e);
    },
  },
};
</script>
