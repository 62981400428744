<template>
  <Forbidden v-if="isProjectForbidden" />
  <div
    v-else-if="isTagsFetched"
    class="sidebar-page"
  >
    <div class="container">
      <div
        class="tags-container"
        data-aos="fade-in"
      >
        <div v-if="hasTags">
          <TitleInput
            appearance="project-title"
            value="Tags"
            :can-edit="false"
          />
          <HelpBanner name="tags" />
          <div class="tag-filters">
            <div class="filter">
              <span class="filter__name">Show:</span>
              <RespondersDropdown
                v-model="filterTags"
                heading=""
                list="tags"
                :can-add="false"
                :can-select-all="false"
                :can-deselect-all="true"
                :reverse-all="true"
                :project-id="projectId"
                value-format="text"
                value-span-class="filter__value"
              />
            </div>
          </div>
          <li class="tags-list__item">
            <div
              v-if="userCanEditProjects"
              class="tag-listed tag-listed--new"
              @click="addTag"
            >
              <div class="tag-listed__body">
                <span class="tag-listed__colour tag-colour tag-colour--new">
                  <Icon name="plus-12" />
                </span>
                <span class="tag-listed__name">New tag</span>
              </div>
            </div>
          </li>
          <transition-group
            tag="ul"
            class="tags-list"
            type="transition"
            name="enter-list"
          >
            <li
              v-for="tag of sortedTags"
              :key="$store.getters.idJoin(tag.id)"
              class="tags-list__item"
            >
              <SideDropdownMenu
                :menu="userCanEditProjects && [
                  {
                    text: 'Archive',
                    action: () => archiveTag(tag.id),
                  },
                ]"
              >
                <div
                  class="tag-listed"
                  @click="showTag(tag.id)"
                >
                  <div class="tag-listed__body">
                    <span
                      class="tag-listed__colour tag-colour"
                      :style="{ backgroundColor: tag.color }"
                    />
                    <span class="tag-listed__name">{{ tag.name }}</span>
                    <span class="tag-listed__count">{{ tag.itemsCount }}</span>
                  </div>
                  <div class="tag-listed__bar tag-count-bar">
                    <span
                      v-if="tag.itemsCount"
                      :style="{width: tag.itemsCountPercent * 100 + '%', backgroundColor: tag.color}"
                    >
                      {{ (tag.itemsCount / tag.itemsCountTotal) > 0.02 ? Math.round(tag.itemsCount / tag.itemsCountTotal * 100) + '%' : '' }}
                    </span>
                  </div>
                </div>
              </SideDropdownMenu>
            </li>
          </transition-group>
        </div>
        <div
          v-else
          class="tag-empty"
        >
          <Icon
            class="tag-empty__icon"
            size="auto"
            name="sticker-note"
          />
          <div class="tag-empty__body">
            <strong class="tag-empty__heading">Add your first tag</strong>
            <BaseInput
              v-model="newInput"
              class="input input--light input--center"
              placeholder="Enter tag name"
              @keydown.enter="onInputEnter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Spinner v-else />
</template>

<script>
import Spinner from 'components/common/base-spinner';
import { sortByItemsCount } from 'helpers/tags';
import { sortAndFilterByProps } from 'helpers/filter';
import { mapGetters } from 'vuex';

import Forbidden from 'components/forbidden';

export default {
  name: 'ProjectTags',
  components: {
    Spinner,
    Forbidden,
  },
  data: () => ({
    newInput: '',
    filterTags: [],
  }),
  computed: {
    ...mapGetters([
      'tags',
      'projectCurrent',
      'projectCurrentId',
      'isTagsFetched',
      'userCanEditProjects',
      'isProjectForbidden',
    ]),
    projectId() {
      return this.projectCurrentId;
    },
    tagId() {
      return this.$route.params.tag_id;
    },
    sortedTags() {
      let maxCount = 0;
      let totalCount = 0;

      let tags = sortAndFilterByProps(this.tags, {
        ids: this.filterTags.map((f) => f.id),
      })

      tags.forEach((tag) => {
        const count = tag.itemsCount || tag.counter;
        if (count > maxCount) {
          maxCount = count;
        }

        totalCount += count;
      })

      tags = tags.map((tag) => ({
        ...tag,
        itemsCount: tag.itemsCount || tag.counter,
        itemsCountPercent: (tag.itemsCount || tag.counter) / maxCount,
        itemsCountMax: maxCount,
        itemsCountTotal: totalCount,
      }));

      return sortByItemsCount(tags);
    },
    hasTags() {
      return !!this.tags.length;
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await Promise.all([
        this.$store.dispatch('projectFetch'),
        this.$store.dispatch('fetchTags'),
        this.$store.dispatch('fetchHighlights'),
      ]);
    },
    async addTag() {
      const data = {
        name: !this.hasTags && this.newInput,
      };
      const created = await this.$store.dispatch('tagAdd', data);
      this.$store.dispatch('modalShow', {
        name: 'tag',
        data: {
          id: created.id,
        },
      });
    },
    onInputEnter() {
      this.addTag();
    },
    archiveTag(id) {
      this.$store.dispatch('tagUpdate', {
        id,
        data: {
          archived: true,
        },
      });
    },
    showTag(id) {
      this.$store.dispatch('modalShow', {
        name: 'tag',
        data: {
          id,
        },
      });
    },
  },
};
</script>
