<template>
  <div class="scale-cards">
    <div class="scale-cards__header">
      <span class="scale-cards__steps">
        <span
          v-if="canEdit"
          class="scale-cards__steps-body"
        >
          <span class="scale-cards__steps-value">Steps <strong>{{ currentSteps }}</strong></span>
          <RangeSlider
            class="scale-cards__steps-slider range-slider--minimal"
            :value="steps"
            :min="4"
            :max="12"
            @input="onStepsChange"
          />
        </span>
      </span>
      <TitleInput
        appearance="tiny"
        :can-edit="canEdit"
        :value="minLabel"
        class="scale-cards__min"
        @change="onMinLabelChange"
      />
      <TitleInput
        appearance="tiny"
        :can-edit="canEdit"
        :value="maxLabel"
        class="scale-cards__max"
        @change="onMaxLabelChange"
      />
    </div>
    <div
      v-if="!surveyMode"
      class="scale-cards__grid"
    >
      <div
        v-for="(value, index) in stepsValues"
        :key="index"
        class="scale-cards__card"
      >
        <span class="scale-cards__card-value">{{ value.name }}</span>
        <Scale
          appearance="vertical"
          :max="value.scaleMax"
          :value="value.value"
          :color="question.color"
        />
        <span
          v-if="canEdit"
          class="scale-cards__card-percentage"
        >
          <RespondersDropdown
            :can-edit="editable"
            :value="value.responders"
            @toggle="tagsOpen = $event"
            @itemSelect="onResponderSelect(index + 1, $event, true)"
            @itemUnselect="onResponderSelect(index + 1, $event, false)"
          />
        </span>
        <span
          v-else
          class="scale-cards__card-percentage"
        >
          <StackedAvatars
            :users="value.responders"
            :always-show-counter="true"
            :can-edit="false"
          />
        </span>
      </div>
    </div>
    <div
      v-else
      class="scale-cards__survey"
    >
      <div
        v-for="index in steps"
        :key="index"
        class="scale-cards__survey-item"
        :class="{
          'scale-cards__survey-item--selected': isSelected(index),
        }"
        @click="$emit('select', `${index}/${steps}`)"
      >
        {{ index }}
      </div>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import RangeSlider from '@vueform/slider'
import { mapGetters } from 'vuex';

export default {
  name: 'ScaleCards',
  components: {
    RangeSlider,
  },
  props: {
    question: Object,
    dataTransport: String,
    surveyMode: Boolean,
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    cards: Array,
  },
  data() {
    return {
      currentSteps: null,
    };
  },
  computed: {
    ...mapGetters(['userCanEditProjects', 'isCardDragging', 'isShared']),
    stepsValues() {
      const fields = (this.question.customItemFields || []).filter((f) => f.responder);
      const steps = this.steps;
      const values = [];
      let scaleMax = 0;

      for (var i = 1; i < steps + 1; i++) {
        let valueFields = fields.filter((f) => {
          let v = f.name ? f.name.split('/') : null;

          if (!v) return false;

          let int = Math.round((v[0] / v[1]) * this.steps);

          if (int === i) {
            return true;
          }

          return false;
        });

        let value = valueFields.length;
        let responders = valueFields.map((f) => f.responder);

        values.push({
          responders,
          name: i,
          value,
        });

        if (scaleMax < value) {
          scaleMax = value;
        }
      }

      values.forEach((v) => {
        v.scaleMax = scaleMax;
      });

      return values;
    },
    editable() {
      return this.userCanEditProjects && this.canEdit;
    },
    steps() {
      return this.question.steps || (this.question.options && this.question.options.steps) || 10;
    },
    minLabel() {
      return this.question.minLabel || (this.question.options && this.question.options.minLabel) || 'Terrible';
    },
    maxLabel() {
      return this.question.maxLabel || (this.question.options && this.question.options.maxLabel) || 'Great';
    },
  },
  watch: {
    cards(cards) {
      if (cards.length) {
        this.$nextTick(() => {
          this.transitionEnabled = true;
        });
      }
    },
    steps(steps) {
      this.currentSteps = steps;
    },
  },
  created() {
    this.currentSteps = this.steps;
    this.submitStepsChange = debounce(this.submitStepsChange, 300);
  },
  methods: {
    onCardTagsChange(tags, card) {
      this.$emit('card-tags-change', card.id, tags);
    },
    onStepsChange(steps) {
      this.currentSteps = steps;
      if (this.steps === steps) return;
      this.submitStepsChange(steps);
    },
    onMinLabelChange(text) {
      this.$emit('min-label-change', text);
    },
    onMaxLabelChange(text) {
      this.$emit('max-label-change', text);
    },
    submitStepsChange(steps) {
      this.$emit('steps-change', steps);
    },
    isSelected(value) {
      if (this.question.customItemFields.find(({name}) => {
        let v = name ? name.split('/') : null;
        return v && v[0] == value;
      })) {
        return true;
      }
      return false;
    },
    onResponderSelect(value, responder, selected) {
      value = `${value}/${this.steps}`;
      if (selected) {
        this.$emit('responderSelect', { value, responder });
      } else {
        this.$emit('responderUnselect', { value, responder });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sortable-cards {
  display: block;
}
.sortable-cards :global(.v-leave-active) {
  display: none !important;
}
</style>
