<template>
  <div class="sidebar-page fsize-normal lheight-normal">
    <section class="section">
      <div class="container section__container container--pricing">
        <h2 class="heading heading--level-2 heading--hero heading--margin-bottom-xs">
          <span class="s1">Simple plans for everyone.</span>
        </h2>
        <p class="p p--narrow p--center p--no-margin-top p--margin-bottom-md p--lg p--light">
          Unlock your research potential.
        </p>
        <div
          v-if="userHasUnavailablePlan && !hasCancelled"
          class="light-info"
        >
          You’re currently on plan that is not available anymore <strong>{{ userPlanTitle }} Plan</strong>
          <a
            class="link link--primary"
            @click="cancel"
          >Cancel Subscription</a>
        </div>
        <!--
        <div
          class="price-period-switch"
          @click="planType = (planType === 'yearly' ? 'monthly' : 'yearly' )"
        >
          <div class="switch">
            <input
              type="checkbox"
              :checked="planType !== 'monthly'"
            >
            <label for="price-period-switch">
              Pay yearly and save <span class="highlighted">20%</span>
            </label>
          </div>
        </div>
        -->
        <div class="grid">
          <div
            v-for="plan of plans"
            :key="plan"
            class="grid-col grid-col--6 grid-col--xs-12"
          >
            <div
              class="pricing-table"
              :class="{'pricing-table--featured': plan === featuredPlan}"
            >
              <h2 class="pricing-table__name">
                {{ getPlanName(plan) }}
              </h2>

              <template v-if="plan !== 'inactive'">
                <span class="pricing-table__price"><sup>{{ currencySymbol }}</sup>{{ getPlanPrice(plan) }}</span>
                <span class="pricing-table__period">per editor/monthly</span>
              </template>
              <template v-else>
                <span class="pricing-table__price">Free</span>
                <span class="pricing-table__period">Forever</span>
              </template>

              <span class="pricing-table__users" />

              <template v-if="!isRestored(plan)">
                <p
                  v-if="isCancelled(plan)"
                  class="pricing-table__info"
                >
                  Plan canceled. It will be active until <strong>{{ activeUntil }}</strong>
                </p>
                <p
                  v-if="isNext(plan)"
                  class="pricing-table__info"
                >
                  This plan will be active in the next billing cycle, <strong>{{ userSubscriptionActiveUntil }}</strong>
                </p>
              </template>
              <template v-if="plan !== 'inactive'">
                <button
                  v-if="isCancelled(plan) && !isRestored(plan)"
                  type="button"
                  class="btn btn--primary btn--block btn--smd"
                  @click="pick(plan)"
                >
                  Restore
                </button>
                <button
                  v-else-if="isPlan(plan)"
                  type="button"
                  class="btn btn--grey btn--block btn--smd"
                  disabled
                >
                  Current plan
                </button>
                <button
                  v-else-if="isNext(plan)"
                  type="button"
                  class="btn btn--outline btn--block btn--smd"
                  @click="cancel"
                >
                  Cancel plan
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn--primary btn--block btn--smd"
                  @click="pick(plan)"
                >
                  Upgrade
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  class="btn btn--grey btn--block btn--smd"
                  v-if="isPlan(plan)"
                  disabled
                >
                  Current plan
                </button>
                <button
                  type="button"
                  class="btn btn--grey btn--block btn--smd"
                  disabled
                  v-else-if="isCancelled('pro') && !isRestored('pro')"
                >
                  Current plan
                </button>
                <button
                  type="button"
                  class="btn btn--primary btn--block btn--smd"
                  @click="cancel"
                  v-else
                >
                  Switch to Free
                </button>
              </template>
              <ul class="pricing-table__features">
                <li
                  v-for="feature of planFeatures[plan]"
                  :key="feature"
                >
                  <span class="icon icon--tick-success"><img
                    src="https://talebook.io/wp-content/themes/talebook/assets/img/icons/tick-success.svg"
                    alt="tick-success icon"
                  ></span> {{ feature }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section
      class="section section--space-bottom-smd"
      style=""
    >
      <div class="container section__container">
        <h2 class="heading heading--level-2 heading--paragraph heading--center">
          Trusted by over 500 companies
        </h2>
        <blockquote class="testimonial">
          <p class="testimonial__body">
            Talebook has revolutionized the way I’m conducting discovery phase
          </p>
          <footer class="testimonial__footer">
            <div class="testimonial__author author">
              <img
                class="author__avatar author-avatar"
                src="https://talebook.io/wp-content/themes/talebook/assets/img/testimonials/av1@2x.jpg"
                width="37"
                height="37"
                alt="user avatar"
              >
              <div>
                Brett Johnson, <cite title="Adobe"><a
                  href="https://adobe.com/"
                  class="link"
                >Adobe</a></cite>
              </div>
              <div />
            </div>
          </footer>
        </blockquote>
      </div>
    </section>

    <section
      class="section section--light"
      style=""
    >
      <div class="container section__container">
        <h2 class="heading heading--level-2 heading--hero-xs heading--margin-bottom-lg">
          Frequently asked questions
        </h2>
        <div class="grid grid--large-horizontal-gutter">
          <div class="grid-col grid-col--6 grid-col--xs-12">
            <span style="font-size: inherit;">
              <h2 class="heading heading--level-2 heading--sans heading--sm heading--no-margin-top">
                What happens to my data after the 7 days trial?
              </h2>
              <p class="p p--md p--no-margin-bottom">When you sign-up, your first 7 days are free. To continue using Talebook after your free trial, you’ll need to upgrade to a premium plan.</p>
            </span>
          </div>
          <div class="grid-col grid-col--6 grid-col--xs-12">
            <h2 class="heading heading--level-2 heading--sans heading--sm heading--no-margin-top">
              Can I switch between plans?
            </h2>
            <p class="p p--md p--no-margin-bottom">
              Absolutely. You can upgrade or downgrade anytime you like. Upgrades are available instantly. Downgrade are applied after your current billing period.
            </p>
          </div>
          <div class="grid-col grid-col--6 grid-col--xs-12">
            <h2 class="heading heading--level-2 heading--sans heading--sm heading--no-margin-top">
              How secure is my data?
            </h2>
            <p class="p p--md p--no-margin-bottom">
              We monitor our systems and code 24/7/365 with both automated tools and our experienced staff to prevent and eliminate attacks on our service and to protect our customers’ data.
            </p>
          </div>
          <div class="grid-col grid-col--6 grid-col--xs-12">
            <h2 class="heading heading--level-2 heading--sans heading--sm heading--no-margin-top">
              Do you have any discounts for non-profits or educational institutions?
            </h2>
            <p class="p p--md p--no-margin-bottom">
              Yes, we do offer discounts. Please contact us at <a
                class="link"
                href="mailto:discounts@talebook.io"
              >discounts@talebook.io</a>
            </p>
          </div>
          <div class="grid-col grid-col--6 grid-col--xs-12">
            <h2 class="heading heading--level-2 heading--sans heading--sm heading--no-margin-top">
              Is there a limit on team members?
            </h2>
            <p class="p p--md p--no-margin-bottom">
              No, all plans come with unlimited team member option. You can change the access level within the team page.
            </p>
          </div>
          <div class="grid-col grid-col--6 grid-col--xs-12">
            <h2 class="heading heading--level-2 heading--sans heading--sm heading--no-margin-top">
              Can I cancel anytime?
            </h2>
            <p class="p p--md p--no-margin-bottom">
              Yes, you can cancel your plan anytime. Your account will be active until the billing period ends.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { USE_FAKE_PAYMENTS } from 'config';
import { CANCEL_SUBSCRIPTION } from 'constants/modals';
import { SUBSCRIPTION_PLAN } from 'constants/mutations';
import { PERSONAL_NEW, PLANS, PRO, PRO_NEW, STARTER, TEAM, TEAM_NEW } from 'constants/user';
import { formatDate } from 'helpers/date';
import { isDowngraded } from 'helpers/subscription';
import NotificationsService from 'services/notifications.service';
import PaymentsService from 'services/payments.service';
import SubscriptionService from 'services/subscription.service';
import { titleize } from 'utils/text';
import { mapGetters } from 'vuex';

export default {
  name: 'PricePlans',
  data: () => ({
    planType: 'monthly',
    featuredPlan: 'pro',
  }),
  computed: {
    ...mapGetters(['currencySymbol', 'userHasUnavailablePlan', 'userPlanPeriod', 'userSubscriptionActiveUntil', 'userPlanInfo']),
    isMonthly() {
      return this.planType === 'monthly';
    },
    isYearly() {
      return this.planType === 'yearly';
    },
    plans() {
      return ['inactive', 'pro'];
    },
    pricing() {
      if (this.isMonthly) {
        return this.$store.getters.pricingMonthly;
      }

      return this.$store.getters.pricingYearly;
    },
    savings() {
      return this.$store.getters.pricingSavings;
    },

    personalPrice() {
      return this.pricing.personal_2020;
    },
    proPrice() {
      return this.pricing.pro_2020;
    },
    teamPrice() {
      return this.pricing.team_2020;
    },

    userPlan() {
      return this.$store.getters.userPlan;
    },
    userPlanTitle() {
      return titleize(this.userPlan);
    },
    hasCancelled() {
      return this.$store.getters.isSubscriptionCanceled && this.userPlan !== 'trial';
    },
    daysLeft() {
      return this.$store.getters.userSubscriptionDaysLeft;
    },
    activeUntil() {
      if (this.userSubscriptionActiveUntil) return this.userSubscriptionActiveUntil;

      const DAY = 24 * 60 * 60 * 1000;
      const timestamp = Date.now() + (DAY * this.daysLeft);
      const date = new Date(timestamp);

      return formatDate(date);
    },
    planFeatures() {
      return {
        'inactive': [ '1 editor', '10 projects', '120 transcription minutes', '150 insights', 'Unlimited components', 'Unlimited tags' ],
        'pro': [ '1200 transcription minutes', 'Unlimited projects', 'Unlimited insights', 'Unlimited components', 'Unlimited tags' ],
      };
    },
  },

  PERSONAL_NEW,
  PRO_NEW,
  TEAM_NEW,
  watch: {
    userPlanPeriod(period) {
      this.planType = period;
    },
  },
  mounted() {
    this.$store.dispatch('invoiceFetch');
    this.$store.dispatch('paymentPlansFetch');
    this.$store.dispatch('paymentMethodsFetch');

    if (this.userPlanPeriod) {
      this.planType = this.userPlanPeriod;
    }
  },
  methods: {
    pick(plan) {
      this.$store.dispatch('modalShow', {
        name: 'PaymentModal',
        data: {
          plan,
          type: this.planType,
        },
      });
    },
    cancel() {
      this.$store.dispatch('modalShow', {
        name: 'CancelSubscriptionModal',
      });
    },
    getPlanName(name) {
      return this.$store.getters.getPlanName(name);
    },
    getPlanPrice(name) {
      return (Math.ceil(this.pricing[name]*100)/100).toFixed(2);
    },
    isCancelled(plan) {
      return this.userPlan === plan && this.userPlanPeriod === this.planType && this.hasCancelled;
    },
    isPlan(plan) {
      return this.userPlan === plan && this.userPlanPeriod === this.planType;
    },
    isNext(plan) {
      if (!this.userPlanInfo) return;
      let nextPeriod = this.userPlanInfo.nextSubscriptionPlanPeriod >= 365 ? 'yearly' : 'monthly';
      return this.userPlanInfo.nextSubscriptionPlanKind && this.userPlanInfo.nextSubscriptionPlanKind === plan && nextPeriod === this.planType;
    },
    isRestored(plan) {
      return this.isNext(plan) && this.isCancelled(plan);
    },
  },
};
</script>
