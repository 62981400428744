<template>
  <div class="">
    <SortableCards
      :data-transport="section.id"
      :new-tags="newTags"
      :cards="fields"
      :actions="sortableCardsActions"
      :can-edit="canEdit"
      value-param="name"
      page-param="value"
      add-placeholder="Add card..."
      empty-remove-text="remove"
      @change="onChange"
      @card-action="onAnswerAction"
      @card-change="onAnswerChange"
      @card-tags-change="onTagsChange"
      @card-add="addAnswer"
      @add-tags-change="onNewTagsChange"
    />
  </div>
</template>

<script>
import { byPosition } from 'helpers/sort';

export default {
  name: 'ProjectMethodCustom',
  components: {
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      newTags: [],
    };
  },
  computed: {
    sortableCardsActions() {
      return [
        {
          text: 'Remove',
          action: 'remove',
        },
      ];
    },
    fields() {
      return byPosition(this.section.customItemFields);
    },
    taggable() {
      return !!this.fields.length;
    },
  },
  created() {
  },
  methods: {
    onAnswerAction({ action, card }) {
      if (action === 'remove' || action === 'archive') {
        this.$store.dispatch('builderRemoveSectionItem', { sectionId: this.section.id, id: card.id });
      }
    },
    addAnswer(name) {
      this.$store.dispatch('builderAddSectionItem', { id: this.section.id, data: {
        name,
        tags: this.newTags,
      } });
    },
    onChange({ id, index, transport }) {
      this.$store.dispatch('builderMoveSectionItem', { sectionId: this.section.id, id, newSectionId: transport, newIndex: index });
    },
    onAnswerChange(id, data) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data });
    },
    onTagsChange(id, tags) {
      this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id, data: {
        tags,
        tag_list: tags,
      } });
    },
    onNewTagsChange(tags) {
      this.newTags = tags;
    },
    addTag(tag) {
      this.section.customItemFields.forEach((field) => {
        const newTags = [...field.tags];

        if (!newTags.find((t) => t.id === tag.id)) {
          newTags.push(tag);
        }

        this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id: field.id, data: {
          tags: newTags,
          tag_list: newTags,
        } });
      });
    },
    removeTag(tag) {
      this.section.customItemFields.forEach((field) => {
        const newTags = field.tags.filter((t) => t.id !== tag.id);

        this.$store.dispatch('builderUpdateSectionItem', { sectionId: this.section.id, id: field.id, data: {
          tags: newTags,
          tag_list: newTags,
        } });
      });
    },
    isEmpty(data) {
      return !(data.customItemFields && data.customItemFields.length);
    },
  },
};
</script>
