<template>
  <transition name="fade-scale">
    <div
      v-if="connectionNotification"
      class="connection-notification"
    >
      <div class="connection-notification__box">
        <Icon
          class="connection-notification__icon"
          name="wifi-off"
        />
        <h2 class="connection-notification__heading">
          Lost Connection
        </h2>
        <p class="connection-notification__text">
          Your Internet session was interrupted. We’ll try to reconnect you.
        </p>
        <button
          class="btn btn--block btn--primary btn--md"
          :class="{
            'btn--loading': fetching,
          }"
          @click="reload"
        >
          Reconnect Now
          <BaseSpinner v-if="fetching" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import NotificationsService from 'services/notifications.service';
import { mapGetters } from 'vuex';

export default {
  name: 'Notifications',
  components: {
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapGetters([
      'connectionNotification',
    ]),
    internalError() {
      return this.connectionNotification.indexOf('went wrong') > -1;
    },
  },
  methods: {
    async reload() {
      this.fetching = true;

      try {
        await this.$store.dispatch('projectMethodFetch', {
          ignoreError: true,
        });
        await this.$store.dispatch('requireTags');
        await this.$store.dispatch('reconnectSockets');
        NotificationsService.showSuccess('Reconnected');
      } catch(e) {
        NotificationsService.showError('Failed to reconnect, try again');
      }
      
      this.fetching = false;
    },
  },
};
</script>
