import PASSIVE from 'constants/passive-events';
import debounce from 'lodash/debounce';

// triggers event when click outside of element occured
export default {
  bind(element, binding, vnode) {
    let prevScrollTop = 0;
    let prevDirection = null;
    const scrolled = (direction) => {
      if (direction === prevDirection) return;

      const methodName = binding.expression;
      vnode.context[methodName](direction);
      prevDirection = direction;
    };

    element.onScrollHandler = debounce(
      () => {
        if (window.pageYOffset < prevScrollTop) {
          scrolled('up');
        } else {
          scrolled('down');
        }

        prevScrollTop = window.pageYOffset;
      },
      50,
      { leading: true, trailing: true }
    );

    document.addEventListener('scroll', element.onScrollHandler, PASSIVE);
  },

  unbind(element) {
    document.removeEventListener('scroll', element.onScrollHandler, PASSIVE);
  },
};
