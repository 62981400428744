<template>
  <div
    style="position:relative"
    class="autocomplete"
    :class="classes"
  >
    <input
      ref="input"
      v-model="query"
      type="text"
      :placeholder="placeholder"
      :class="inputClass"
      @blur="close"
      @keydown.esc="close"
      @keydown.enter="enter"
      @keydown.down="down"
      @keydown.up="up"
      @input="change"
      @click="onClick"
    >
    <ul class="list">
      <li
        v-for="(item, index) in matches"
        :key="index"
        class="list__item"
        :class="{ highlighted: isActive(index) }"
        @mousedown="suggestionClick(index)"
        @touchstart="suggestionClick(index)"
        @mouseenter="current = index"
      >
        <slot
          v-bind="item"
        >
          {{ item.label }}
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Autocomplete',
  props: {
    source: {
      type: Array,
      required: true,
    },
    selection: {
      type: String,
      required: false,
      default: '',
    },
    minLength: {
      type: Number,
      required: false,
      default: 1,
    },
    clearOnSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: String,
    inputClass: String,
  },
  data() {
    return {
      opened: false,
      current: 0,
      query: this.selection || '',
    };
  },
  computed: {
    matches() {
      const pattern = new RegExp(this.query.replace(/\W/g, ''), 'i');
      return this.source.filter(({ label }) => label.match(pattern));
    },
    isProperLength() {
      return this.query.length >= this.minLength;
    },
    hasMatches() {
      return !!this.matches.length;
    },
    shouldOpen() {
      return this.opened
          && this.isProperLength
          && this.hasMatches;
    },
    shouldShowNew() {
      return !this.hasMatches
          && this.isProperLength
          && this.opened;
    },
    classes() {
      return {
        show: this.shouldOpen || this.shouldShowNew,
      };
    },
  },
  watch: {
    query(current) {
      this.$emit('query', current);
    },
    selection(current) {
      this.query = current || '';
    },
  },
  methods: {
    close() {
      this.opened = false;

      if (this.current === -1) {
        this.query = this.selection;
        this.$emit('select', '');
      }
    },
    enter() {
      if (!this.opened || this.current === -1) {
        this.$emit('enter');
        return;
      }

      const current = this.matches[this.current];
      this.query = current.label;
      this.opened = false;

      this.$emit('select', current.value);

      if (this.clearOnSelect) {
        this.clear();
      }
    },
    up() {
      if (this.current === 0) return;
      this.current--;
    },
    down() {
      if (this.current === this.matches.length - 1) {
        return;
      }

      this.current++;
    },
    isActive(index) {
      return index === this.current;
    },
    change() {
      if (!this.opened) {
        this.opened = true;
        this.current = 0;
      }

      if (!this.matches.length) {
        this.current = -1;
      }
    },
    suggestionClick(index) {
      this.current = index;
      this.enter();
    },
    clear() {
      this.query = '';
    },
    onClick() {
      this.opened = true;
      this.current = 0;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};

</script>
