<template>
  <div
    ref="wrap"
    class="content-transition"
    :class="{ 'content-transition--visible': visible, 'content-transition--optimized': optimized }"
  >
    <TransitionGroup
      v-if="multiple"
      :name="name"
      :duration="400"
      @before-enter="onTransitionBeforeEnter"
      @enter="onTransitionEnter"
      @after-enter="onTransitionAfterEnter"
      @before-leave="onTransitionBeforeLeave"
      @leave="onTransitionLeave"
      @after-leave="onTransitionAfterLeave"
    >
      <slot />
    </TransitionGroup>
    <transition
      v-else
      :name="name"
      :duration="200"
      mode="out-in"
      @before-enter="onTransitionBeforeEnter"
      @enter="onTransitionEnter"
      @after-enter="onTransitionAfterEnter"
      @before-leave="onTransitionBeforeLeave"
      @leave="onTransitionLeave"
      @after-leave="onTransitionAfterLeave"
    >
      <slot v-if="$slots.default" />
      <div
        v-else
        key="transition-placeholder"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ContentTransition',
  props: {
    multiple: Boolean,
    initHidden: Boolean,
    optimized: Boolean,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    name() {
      if (this.optimized) {
        return 'ct-optimized';
      }
      return 'none';
    },
  },
  mounted() {
    if (this.initHidden) {
      this.$refs.wrap.style.height = '0px';
      this.$refs.wrap.style.opacity = '0';
    }
  },
  methods: {
    onTransitionBeforeEnter(el) {
      if (!this.$refs.wrap) return;

      if (this.multiple) {
        el.classList.add('content-transition-child');
        el.style.height = '0px';
        el.style.opacity = '0';
      } else {
        const wrap = this.$refs.wrap;

        wrap.style.opacity = '0';
        wrap.style.height = wrap.offsetHeight + 'px';
      }

      this.visible = false;
    },
    onTransitionEnter(el) {
      if (!this.$refs.wrap) return;

      if (this.multiple) {
        el.style.height = '';

        const height = el.offsetHeight;

        el.style.height = '0px';
        el.style.opacity = '0';

        window.setTimeout(() => {
          el.style.height = height + 'px';
        }, 200);

        window.setTimeout(() => {
          el.style.opacity = '1';
        }, 400);
      } else {
        const wrap = this.$refs.wrap;
        wrap.style.opacity = '0';
        wrap.style.height = el.offsetHeight + 'px';
      }

      this.visible = false;
    },
    onTransitionAfterEnter(el) {
      if (!this.$refs.wrap) return;

      const wrap = this.$refs.wrap;
      wrap.style.opacity = '1';
      wrap.style.height = '';

      if (this.multiple) {
        el.style.height = '';
      }
    },
    onTransitionBeforeLeave(el) {
      if (!this.$refs.wrap) return;

      if (this.multiple) {
        el.classList.add('content-transition-child');
        el.style.opacity = '1';
        el.style.height = el.offsetHeight + 'px';
      } else {
         const wrap = this.$refs.wrap;
         wrap.style.opacity = '1';
      }
    },
    onTransitionLeave(el) {
      if (!this.$refs.wrap) return;

      if (this.multiple) {
        el.classList.add('content-transition-child');
        el.style.opacity = '0';

        window.setTimeout(() => {
          el.style.height = '0px';
        }, 200);
      } else {
        const wrap = this.$refs.wrap;

        wrap.style.opacity = '0';
        wrap.style.height = el.offsetHeight + 'px';
      }
    },
    onTransitionAfterLeave(el) {
      if (!this.$refs.wrap) return;

      if (this.multiple) {
        el.classList.add('content-transition-child');
      } else {
        const wrap = this.$refs.wrap;
        wrap.style.height = '0px';
      }
    },
  },
};
</script>
