<template>
  <div
    ref="base"
    class="builder-paragraph"
    :class="classNames"
  >
    <span
      class="builder-paragraph__counter"
      data-paragraph-counter="true"
      v-if="type === 'number-list'"
    />
    <input
      ref="fileInput"
      type="file"
      style="display:none;"
      @change="inputOnChange"
    >
    <template v-if="canEdit">
      <div
        v-if="isEmpty"
        class="sortable-handler builder-paragraph__type-drag"
      >
        <Icon name="drag" />
      </div>
      <TypePicker
        v-if="isEmpty"
        ref="picker"
        :color="newColor"
        :type="newType"
        class="builder-paragraph__picker"
        :create="true"
        @type-change="onTypeChange"
        @color-change="onColorChange"
        @add="onAdd"
        @toggle="isOpen = $event"
      />
      <template v-else>
        <div
          class="builder-paragraph__drag sortable-handler"
        >
          <Icon name="drag" />
        </div>
      </template>
    </template>
    <Editor
      ref="editor"
      :tags-enabled="true"
      :allow-image="false"
      :visible-on-start="visibleOnStart"
      :lazy="true"
      :value="section.content"
      :can-edit="canEdit"
      :tags-list="allTags"
      :type="type"
      placeholder="Type here..."
      @change="onInput"
      @setType="onSetType"
      @keydown="onKeyDown"
      @focus="onFocus"
      @blur="onBlur"
      @content-tags-change="onContentTagsChange"
      @paste="onPaste"
    />
  </div>
</template>

<script>
import Editor from 'components/common/editor';
import { matchEmbedType } from 'components/common/editor';
import NotificationsService from 'services/notifications.service';
import { mapGetters } from 'vuex';
import { history } from 'stores/plugins/history';
import EventsService from 'services/events.service';
import generateId from 'utils/id';
import TypePicker from '../type-picker';

export default {
  name: 'ProjectMethodCustom',
  components: {
    Editor,
    TypePicker,
  },
  props: {
    section: {
      type: Object,
      default: undefined,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      newColor: '#abc0c8',
      newType: '',
      hasFocus: false,
      isOpen: false,
      visibleOnStart: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedSections',
      'allTags',
    ]),
    options() {
      return this.section.options || {};
    },
    type() {
      return this.options.type || 'default';
    },
    classNames() {
      return {
        'builder-paragraph--open': this.isOpen,
        'methods__question-edit-counter-active': this.isOpen,
        [`builder-paragraph--type-${this.options.type}`]: this.type,
      };
    },
    isEmpty() {
      const content = (this.section.content || '')
        .replace('<p></p>', '')
        .replace(/^[\n\t\s]$/gim, '');

      return !content.length;
    },
    taggable() {
      return !this.isEmpty;
    },
  },
  watch: {
    section: {
      handler(section) {
        if (section.modifiedByOtherUser) {
          this.$refs.editor.setValue(section.content);
        }
      },
      deep: true,
    },
    hasFocus(focus) {
      if (focus) {
        this.$refs.base.classList.add('builder-paragraph--focus');
      } else {
        this.$refs.base.classList.remove('builder-paragraph--focus');
      }
    },
  },
  created() {
    if (this.section.focus || this.section.focus === 0) {
      this.visibleOnStart = true;
    }
  },
  mounted() {
    if (this.hasFocus) {
      this.$refs.base && this.$refs.base.classList.add('builder-paragraph--focus');
    } else {
      this.$refs.base && this.$refs.base.classList.remove('builder-paragraph--focus');
    }

    this.refreshCounters();
    EventsService.on('editor-updated', this.refreshCounters);

    this.$el.component = this;
  },
  unmounted() {
    EventsService.off('editor-updated', this.refreshCounters);
  },
  updated() {
    if (this.hasFocus) {
      this.$refs.base.classList.add('builder-paragraph--focus');
    } else {
      this.$refs.base.classList.remove('builder-paragraph--focus');
    }

    this.refreshCounters();
  },
  methods: {
    onHistoryChange(state) {
      const editor = this.$refs.editor.editor;

      if (typeof state.content === 'string') {
        this.$refs.editor.setValue(state.content);
      }

      if (editor && state.selection) {
        editor.setSelection(state.selection);
      }
    },
    onHistorySave(prev, next) {
      const editor = this.$refs.editor.editor;

      if (editor) {
        prev.selection = editor.getSelection();

        window.setTimeout(() => {
          next.selection = editor.getSelection();
        }, 33)
      }
    },
    refreshCounters() {
      const sections = document.querySelectorAll('.section-wrap');
      let counter = 0;

      sections.forEach((section) => {
        const counterEl = section.querySelector('[data-paragraph-counter]');

        if (counterEl) {
          counter++;
          counterEl.innerHTML = `${counter}.`;
        } else {
          counter = 0;
        }
      });
    }, 
    onContentTagsChange(sentences) {
      const tagsList = this.$store.getters.tags;
      let contentTags = (sentences || []).map((d) => ({
        tagList: d.tagList,
        text: d.content,
        editorId: d.editorId,
        startAt: d.startAt,
        endAt: d.endAt,
      }));

      contentTags = contentTags.map((contentTag) => ({
        ...contentTag,
        tagList: contentTag.tagList.filter((tag) => {
          return tagsList.find((t) => t.id === tag);
        }),
      }));

      this.$store.dispatch('methodSectionUpdate', {
        id: this.section.id,
        data: {
          contentTags,
        },
      });
    },
    async onInput({ value, before, after }) {
      const hasSiblings = (before && before.length) || (after && after.length);

      if (hasSiblings) {
        history.startGroup();
      }

      this.$store.dispatch('methodSectionUpdate', {
        id: this.section.id,
        data: {
          content: value,
          local: false,
        },
      });

      const getType = (p) => {
        let type = this.section.options && this.section.options.type;
        let tag = p.tagTree.at(-1);

        if (tag === 'LI') {
          return 'bullet-list';
        }
        
        if (tag === 'H1') {
          return 'headline-1';
        }
        
        if (tag.match(/^h.*/gim)) {
          return 'headline-2';
        }

        return type;
      }

      before && before.forEach((p, i) => {
        const data = {
          id: generateId(),
          content: p.content,
          position: this.section.position,
          options: {
            type: getType(p),
          },
        };

        this.$store.dispatch('builderCreateSection', {
          ...this.section,
          new: false,
          data,
          methodId: this.section.designMethodId,
        });
      })

      after && after.forEach((p, i) => {
        const data = {
          id: generateId(),
          content: p.content,
          position: this.section.position + 1 + i,
          options: {
            type: getType(p),
          },
        };

        this.$store.dispatch('builderCreateSection', {
          ...this.section,
          new: false,
          data,
          methodId: this.section.designMethodId,
        });
      })

      if (hasSiblings) {
        history.stopGroup();
      }
    },
    async onKeyDown(e) {
      if (e.key === 'Tab') {
        if (this.isEmpty) {
          this.$refs.picker.show();
          e.preventDefault();
          return;
        }
      }
    },
    onTypeChange({type}) {
      this.newType = type;
    },
    onSetType(type) {
      if (this.options.type === type) {
        type = '';
      }
      this.$store.dispatch('methodSectionUpdate', {
        id: this.section.id,
        data: {
          options:{
            ...this.section.options,
            type,
          },
        },
      });
    },
    onColorChange(color) {
      this.newColor = color;
    },
    focus(type = 'end') {
      this.$refs.editor.focus(type);
      this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { focus: null } });
    },
    setValue(value) {
      this.$refs.editor.setValue(value || '');
    },
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    onAdd({ type, data }) {
      if (type === 'image') {
        this.$refs.fileInput.click();
      } else if (type === 'custom_6') {
        this.$refs.fileInput.click();
      } else {
        this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { color: this.newColor, name: '', type, ...data } });
      }
    },
    async inputOnChange(e) {
      const input = e.target;
      const file = input.files[0];
      const fileSize = file.size;
      const fileType = file.type;
      const isVideo = fileType.match(/^video/i);

      const accept = 'image/x-png,image/png,image/gif,image/jpeg,image/svg,image/gif,image/svg+xml'.split(',');

      if (!isVideo && fileSize > 10000000) {
        NotificationsService.showError('Maximum upload file size is 10 MB');
        input.value = null;
        return;
      }

      if(!accept.includes(fileType) && !isVideo) {
        NotificationsService.showError('Please upload PNG, GIF, JPG/JPEG, or SVG');
        input.value = null;
        return;
      }

      if (isVideo) {
        this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { type: 'custom_6' } });
        this.$store.dispatch('builderAddSectionVideo', {
          id: this.section.id,
          file,
        });
      } else {
        this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { type: 'image' } });
        this.$store.dispatch('builderAddSectionImage', {
          id: this.section.id,
          image: file,
        });
      }

      input.value = null;
    },
    onVideoUploadStep(id, state, progress) {
      this.$store.dispatch('methodSectionUpdate', {
        id: this.section.id,
        data: {
          options:{
            ...this.section.options,
            transcriptionId: id,
          },
        },
      });
    },
    addTag(tag) {
      if (this.isEmpty) return;
      this.$refs.editor.addTag(tag);
    },
    removeTag(tag) {
      this.$refs.editor.removeTag(tag);
    },
    onPaste(e) {
      return;
      let quill = this.$refs.editor.getQuill();
      let clipboardData = e.clipboardData || window.clipboardData;
      let text = clipboardData.getData('Text');

      if (text && matchEmbedType(text)) {
        let remove = false;

        if (quill) {
          let range = quill.getSelection();
          let length = quill.getLength() - 1;
          let rightIndex = (range.index + range.length);
          let rightLength = length - rightIndex;

          if (rightLength) {
            quill.insertText(range.index, '\n');
          }

          if (length === 0) {
            remove = true;
          }
        }

        this.$store.dispatch('builderCreateSection', {
          type: 'embed',
          index: this.section.position + 1,
          methodId: this.section.designMethodId,
          data: {
            value: text,
            focus: 'start',
          },
        });

        if (remove) {
          this.$store.dispatch('builderRemoveSection', {
            id: this.section.id,
          });
        }

        e.stopPropagation();
        e.preventDefault();
      }
    },
  },
};
</script>
