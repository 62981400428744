<template>
  <div
    v-if="isHighlightsFetched"
    class="highlights-content"
  >
    <div class="grid grid--middle grid--between">
      <div class="grid-col">
        <div class="highlights-search">
          <BaseInput
            :value="searchText"
            @input="onSearchChange"
            placeholder="Search highlights..."
          />
          <Icon name="search-alt" />
        </div>
      </div>
      <div class="grid-col">
        <div class="filter">
          <span class="filter__name">Sort by:</span>
          <DropdownMenu
            class="filter__value"
            :menu="[
              {
                text: 'Created',
                action: () => setSortBy('created'),
                selected: sortBy === 'created',
              },
              {
                text: 'Tags',
                action: () => setSortBy('tags'),
                selected: sortBy === 'tags',
              },
              {
                text: 'Pages',
                action: () => setSortBy('methods'),
                selected: sortBy === 'methods',
              },
            ]"
          >
            {{ capitalize(sortBy) }}
          </DropdownMenu>
        </div>
      </div>
    </div>
    <div class="highlights-list">
      <div class="highlights-item highlights-item--filters">
        <div
          v-if="canSelect"
          class="highlights-item__col highlights-item__col--check"
        />
        <div class="highlights-item__col highlights-item__col--name highlights-item__col--th">
          Highlights
        </div>
        <div class="highlights-item__col highlights-item__col--tags highlights-item__col--th">
          <div class="filter">
            <span class="filter__name">Tags:</span>
            <RespondersDropdown
              v-model="filterTags"
              value-span-class="filter__value"
              heading=""
              list="tags"
              :project-id="projectId"
              :can-add="false"
              :can-select-all="false"
              :can-deselect-all="true"
              :reverse-all="true"
              value-format="text"
            />
          </div>
        </div>
        <!--
        <div class="highlights-item__col highlights-item__col--insights highlights-item__col--th" v-if="!canSelect">
          <div class="filter">
            <span class="filter__name">Insights:</span>
            <RespondersDropdown
              class="filter__value"
              heading=""
              list="insights"
              :canAdd="false"
              :canSelectAll="true"
              valueFormat="text"
              v-model="filterInsights"
            />
          </div>
        </div>
        -->
        <div class="highlights-item__col highlights-item__col--method highlights-item__col--th">
          <div class="filter">
            <span class="filter__name">Pages:</span>
            <RespondersDropdown
              v-model="filterMethods"
              value-span-class="filter__value"
              heading=""
              list="methods"
              :project-id="projectId"
              :can-add="false"
              :can-select-all="false"
              :can-deselect-all="true"
              :reverse-all="true"
              value-format="text"
            />
          </div>
        </div>
        <div class="highlights-item__col highlights-item__col--date highlights-item__col--th">
          <div class="filter">
            <span class="filter__name">Last modified:</span>
            <DatePicker
              class="filter__value"
              :value="timeRange"
              @change="timeRange = $event"
            >
              <span>{{ timeRange ? timeRange.text : 'All' }}</span>
            </DatePicker>
          </div>
        </div>
      </div>
      <ul v-if="filterHighlights" ref="highlightsList">
        <template v-if="filterHighlights.length">
          <li
            v-for="highlight of filterHighlights"
            class="highlights-list__item"
            :key="highlight.id"
          >
            <div
              class="highlights-item"
              :class="{'highlights-item--open': activeTooltip === highlight.id, 'highlights-item--selectable': canSelect, 'highlights-item--selected': selectedList.includes(highlight.id)}"
              @click="onHighlightClick(highlight.id)"
            >
              <div
                v-if="canSelect"
                class="highlights-item__col highlights-item__col--check"
              >
                <Checkbox
                  appearance="medium"
                  :checked="selectedList.includes(highlight.id)"
                />
              </div>
              <div class="highlights-item__col highlights-item__col--name">
                <div :data-text="highlight.text.replace(/\u00A0/gim, ' ')">{{highlight.text.replace(/\u00A0/gim, ' ')}}</div>
                <Editor
                  v-if="!canSelect"
                  v-show="false"
                  ref="editor"
                  :show-tags="false"
                  :value="highlight.parentContent"
                  :can-edit="true"
                  :data-highlight-id="highlight.id"
                  :tags-list="tags"
                />
                <div class="highlight-tag-edit" @click="openSource(highlight)">
                  <div class="highlight-tag-edit__btn">
                    <Icon name="expand" />
                  </div>
                </div>
              </div>
              <div class="highlights-item__col highlights-item__col--tags">
                <div class="highlights-item__col-body">
                  <div
                    v-for="tag of highlight.displayTags"
                    :key="tag.id"
                    class="tag-selector__item tag-selector__item--tags"
                    @click="!canSelect && openTag(tag.id)"
                  >
                    <div class="tag-selector__item-body tag-selector__item-body--full">
                      <span
                        class="tag-colour tag-selector__ico"
                        :style="{background: tag.color}"
                      />
                      <div class="tag-selector__item-name">
                        {{ tag.name }}
                      </div>
                    </div>
                  </div>
                  <div
                    key="more"
                    class="tag-selector__item tag-selector__item--more tag-selector__item--tags"
                    v-if="highlight.hasMoreTags"
                  >
                    <div class="tag-selector__item-body tag-selector__item-body--full">
                      <span
                        class="tag-colour tag-selector__ico"
                      />
                      <div class="tag-selector__item-name">
                        +{{highlight.moreTagsCount}} tags
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!canSelect && userCanEditProjects"
                    class="highlight-tag-edit"
                  >
                    <RespondersDropdown
                      list="tags"
                      :can-add="true"
                      :can-select-all="false"
                      :value="highlight.tags"
                      @itemSelect="onTagSelect(highlight, $event, true)"
                      @itemUnselect="onTagSelect(highlight, $event, false)"
                      @toggle="activeTooltip = $event ? highlight.id : ''"
                    >
                      <BaseTooltip
                        text="Edit"
                        placement="top"
                      >
                        <div class="highlight-tag-edit__btn">
                          <Icon name="edit" />
                        </div>
                      </BaseTooltip>
                    </RespondersDropdown>
                  </div>
                </div>
              </div>
              <!--
              <div class="highlights-item__col highlights-item__col--insights" v-if="!canSelect">
                <div class="highlights-item__col-body">
                  <template v-if="highlight.insights && highlight.insights.length">
                    <div class="tag-selector__item tag-selector__item--tags" :key="insight.id" v-for="insight of highlight.insights">
                      <div class="tag-selector__item-body tag-selector__item-body--full">
                        <span class="tag-colour tag-selector__ico" :style="{background: insight.color}" />
                        <div class="tag-selector__item-name">{{insight.name}}</div>
                      </div>
                    </div>
                  </template>
                  <div v-else>
                    Add to Insight
                  </div>
                  <div class="highlight-tag-edit">
                    <RespondersDropdown
                      heading="Insights"
                      list="insights"
                      :canAdd="true"
                      :canSelectAll="false"
                      valueFormat="text"
                      :value="highlight.insight || []"
                    >
                      <BaseTooltip text="Edit" placement="top">
                        <div class="highlight-tag-edit__btn">
                          <Icon name="edit" />
                        </div>
                      </BaseTooltip>
                    </RespondersDropdown>
                  </div>
                </div>
              </div>
              -->
              <div class="highlights-item__col highlights-item__col--method">
                <div class="highlights-item__col-body">
                  <div
                    class="tag-selector__item tag-selector__item--methods"
                    @click="!canSelect && navigateToMethod(highlight.projectId, highlight.designMethodId)"
                  >
                    <div class="tag-selector__item-body tag-selector__item-body--full">
                      <MethodIcon
                        class="tag-selector__ico"
                        :method="$store.getters.projectMethodById(highlight.designMethodId) || {}"
                      />
                      <div class="tag-selector__item-name">
                        {{ ($store.getters.projectMethodById(highlight.designMethodId) || {}).name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="highlights-item__col highlights-item__col--date">
                {{ timeText(highlight.updatedAt) }}
              </div>
            </div>
          </li>
          <div
            ref="emptySearch"
            key="emptysearch"
            class="tag-empty"
          >
            <Icon
              class="tag-empty__icon"
              size="auto"
              name="art-competitoranalysis"
            />
            <div class="tag-empty__body">
              <strong class="tag-empty__heading">No Results</strong>
              <div>Sorry, we couldn’t find the data you were looking for. Modify your keyword or add more highlights.</div>
            </div>
          </div>
        </template>
        <div
          v-else
          key="empty"
          class="tag-empty"
        >
          <Icon
            v-if="!(canSelect && hasHighlights)"
            class="tag-empty__icon"
            size="auto"
            name="art-competitoranalysis"
          />
          <div class="tag-empty__body">
            <template v-if="canSelect && hasHighlights">
              <strong class="tag-empty__heading">No highlights left</strong>
              <div>All highlights are already linked to this insight.</div>
            </template>
            <template v-else>
              <strong class="tag-empty__heading">No Highlights…yet!</strong>
              <div>Create your first highlight by tagging text in a Method.</div>
            </template>
          </div>
        </div>
      </ul>
      <Spinner v-else />
    </div>
  </div>
  <Spinner v-else />
</template>

<script>
import Spinner from 'components/common/base-spinner';
import Editor, { getSentencesFromEditor } from 'components/common/editor';
import DatePicker from 'components/common/pickers/date';
import { navigateToMethod } from 'helpers/router';
import { sortAndFilterByProps } from 'helpers/filter';
import { sortBy } from 'helpers/sort';
import { capitalize } from 'helpers/string';
import { timeText } from 'helpers/time';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectHighlights',
  components: {
    Spinner,
    DatePicker,
    Editor,
  },
  props: {
    canSelect: Boolean,
    exclude: {
      type: Array,
      default: () => ([]),
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    newInput: '',
    filterTags: [],
    filterInsights: [],
    filterMethods: [],
    timeRange: '',
    showTagId: '',
    sortBy: 'created',
    activeTooltip: '',
    selectedList: [],
    fetching: false,
    searchText: '',
  }),
  computed: {
    ...mapGetters([
      'userCanEditProjects',
      'allTags',
    ]),
    tags() {
      return this.$store.getters.allTags;
    },
    highlights() {
      return this.$store.getters.projectHighlights(this.projectId);
    },
    isHighlightsFetched() {
      return this.$store.getters.isProjectHighlightsFetched(this.projectId);
    },
    hasHighlights() {
      return !!this.highlights.length;
    },
    filterHighlights() {
      let { highlights } = this;

      highlights = highlights.filter((h) => {
        return !h.tags.length || h.tags.filter(({ id }) => this.allTags.find((t) => t.id === id)).length;
      })

      highlights = sortAndFilterByProps(highlights, {
        tags: this.filterTags,
        methods: this.filterMethods,
        createdAtRange: this.timeRange,
        sortBy: this.sortBy,
        exclude: this.exclude,
      });

      return highlights.map((h) => ({
        ...h,
        displayTags: h.tags.slice(0, 3),
        hasMoreTags: h.tags.length > 3,
        moreTagsCount: h.tags.length - 3,
      }));
    },
  },
  watch: {
  },
  async created() {
    await this.fetchData();
  },
  mounted() {
    this.onSearchChange();
  },
  updated() {
    this.onSearchChange();
  },
  methods: {
    async fetchData() {
      this.fetching = true;
      await Promise.all([
        this.$store.dispatch('requireHighlights', { projectId: this.projectId }),
      ]);
      this.fetching = false;
    },
    openTag(id) {
      this.$store.dispatch('modalShow', {
        name: 'tag',
        data: {
          id,
          filterProjectId: this.projectId,
        },
      });
    },
    timeText,
    capitalize,
    navigateToMethod(projectId, methodId) {
      navigateToMethod(projectId, methodId);
    },
    setSortBy(sortBy) {
      this.sortBy = sortBy;
    },
    onTagSelect(highlight, { id }, selected) {
      let editor = this.$refs.editor.find((e) => e.$el.dataset.highlightId === highlight.id);

      if (!editor) return;

      let quill = editor.getEditor();

      if (!quill) return;

      const offset = highlight.editorId.match(/__(\d+)--(\d+)$/i);
      const sentenceSelection = [offset[1] * 1, offset[2] * 1];
      let index = sentenceSelection[0];
      let length = sentenceSelection[1] - sentenceSelection[0];

      quill.setSelection(index, length);
      quill.format('tag', { tag: id, selected });

      let tags = this.$store.getters.allTags;
      let content = editor.getValue();
      let contentTags = getSentencesFromEditor(quill, tags);

      this.$store.dispatch('changeHighlight', { id: highlight.id, content, contentTags });
    },
    onSelect(id, selected) {
      if (selected) {
        this.selectedList.push(id);
      } else {
        this.selectedList = this.selectedList.filter((s) => s !== id);
      }
      this.$emit('changeSelection', this.selectedList);
    },
    onHighlightClick(id) {
      if (!this.canSelect) {
        return;
      }
      
      this.onSelect(id, !this.selectedList.includes(id));
    },
    onSearchChange(search = this.searchText) {
      const searchRegexp = new RegExp(search, 'gim');
      const items = this.$el.querySelectorAll('.highlights-list__item');
      let found = false;

      items.forEach((item) => {
        const content = item.querySelector('[data-text]');
        const text = content.getAttribute('data-text');
        const find = !search || text.toLowerCase().indexOf(search.toLowerCase()) > -1;
        
        if (find) {
          item.style.display = '';
          content.innerHTML = text.replace(searchRegexp, (f) => {
            return `<b class="search-highlight">${f}</b>`;
          });
          found = true;
        } else {
          item.style.display = 'none';
        }
      })

      if (this.$refs.emptySearch) {
        this.$refs.emptySearch.style.display = found ? 'none' : 'block';
      }

      this.searchText = search;
    },
    openSource(highlight) {
      navigateToMethod(highlight.projectId, highlight.designMethodId, {
        item: highlight.parentId,
      });
    },
  },
};
</script>
