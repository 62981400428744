export default (element = document.body, simulated = true) => {
  const event = document.createEvent('MouseEvents');
  event.initMouseEvent(
    'click',
    true,
    true,
    window,
    1,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );

  event.simulated = simulated;
  element.dispatchEvent(event);
};
