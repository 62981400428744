<template>
  <div class="text-center">
    <h1 class="g__title">
      Thank you for your time!
    </h1>
    <p class="method-popup__dsc small-width">
      We really appreciate your thoughts.
    </p>
    <button
      class="btn btn--orange btn--corners btn--md"
    >
      <a href="https://app.talebook.io">Create your own survey</a>
    </button>
    <button
      class="btn btn--white btn--corners btn--md"
      @click="goBackToSurvey"
    >
      Edit answers
    </button>
  </div>
</template>

<script>
export default {
  name: 'InterviewSurveyThanks',
  computed: {
    projectName() {
      const project = this.$store.getters.projectCurrent;
      if (!project) return '';

      return project.name;
    },
  },
  methods: {
    goBackToSurvey() {
      this.$emit('enablingSurvey');
    },
  },
};
</script>

<style lang="scss" scoped>
  .text-center {
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
  }
  .btn--orange {
    margin-top: 60px;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .btn--white {
    margin-top: 15px;
    border: solid 1px rgba(8, 54, 87, 0.15);
  }
  .g__title {
    padding-right: 0;
  }
</style>
