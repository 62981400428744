export const calcPosition = (newIndex, oldIndex) => {
  const direction = newIndex > oldIndex ? 1 : -1;
  return newIndex + direction * 0.1;
};

export const calcMovePosition = (id, list, direction) => {
  const item = list.find((item) => item.id === id);
  const index = list.findIndex((item) => item.id === id);
  let prev, after;

  if (direction === 'up') {
    prev = list[index - 2];
    after = list[index - 1];

    if (!prev && !after) return item.position;
    if (!prev) return after.position - 1;

    return (prev.position + after.position) / 2;
  }

  if (direction === 'down') {
    prev = list[index + 1];
    after = list[index + 2];

    if (!prev && !after) return item.position;
    if (!after) return prev.position;

    return (prev.position + after.position) / 2;
  }
};

export const filterActiveInputs = {
  filter: (e, element) => {
    const active = document.activeElement;
    const isInput = ['INPUT', 'TEXTAREA'].includes(active.tagName);
    return isInput && element.contains(active);
  },
  preventOnFilter: false,
};

export const onStart = ({ to }) => {
  if (!to || !to.children.length) return;

  const element = to.children[0];
  if (element.style.display !== 'none') return;

  element.parentNode.removeChild(element);
};
