/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import { remove } from 'lodash';
import ModalsService from 'services/modals.service';
import StorageService, { methodOptions } from 'services/storage.service';
import { merge } from 'utils/reactive';

const moduleState = {
  editing: false,
  saving: false,
  cardDragging: false,

  modalStack: [],
  modalDataStack: [],
  modalOverlay: false,

  header: true,
  guestModalShown: false,

  tipsShown: [],

  lightbox: false,

  idJoins: {},
  idJoinsRev: {},

  archiving: null,

  dragOverActive: false,

  hideAnswers: methodOptions.load('default', 'builder-hide-content-library', false),
  removedItems: [],

  sidebarOpen: false,

  ...(StorageService.ui.load() || {}),
};

const getters = {
  isEditing: (state) => state.editing,
  isSaving: (state) => state.saving,
  isHeaderVisible: (state) => state.header,
  guestModalShown: (state) => state.guestModalShown,

  modalActive: (state) => state.modalStack[state.modalStack.length - 1],
  modalStackSize: (state) => state.modalStack.length,
  isModalActive: (state, getters) => !!getters.modalActive,
  isModalOverlayActive: (state) => state.modalOverlay,
  modalData: (state) => state.modalDataStack[state.modalStack.length - 1] || {},
  isModalWithNameActive: (state, getters) => (name) => getters.modalActive === name,

  isCardDragging: (state) => !!state.cardDragging,

  tipsShown: (state) => state.tipsShown,
  shouldShowTip: (state, getters) => (id) =>
    !getters.tipsShown.includes(id) && !getters.isShared,

  isLightboxVisible: (state) => state.lightbox,

  idJoin: (state) => (id) => state.idJoins[id] || id,
  idJoinRevPure: (state) => (id) => state.idJoinsRev[id],

  isArchiving: (state) => state.archiving,

  dragOverActive: (state) => state.dragOverActive,

  uiHideAnswers: (state) => state.hideAnswers,
  removedItems: (state) => state.removedItems,

  isSidebarOpen: (state) => state.sidebarOpen,

  // App UI configuration
  methodNameMaxLength: () => 120,
  tagNameMaxLength: () => 120,
  projectNameMaxLength: () => 120,
};

const actions = {
  uiToggleEdit({ commit }, value = null) {
    commit(TYPES.UI_EDIT_TOGGLE, value);
  },
  uiToggleSave({ commit }, saving) {
    if (this.savingTimeout) {
      window.clearTimeout(this.savingTimeout);
      this.savingTimeout = false;
    }

    if (!saving) {
      this.savingTimeout = window.setTimeout(() => {
        commit(TYPES.UI_SAVE_TOGGLE, saving);
      }, 2000);
    } else {
      commit(TYPES.UI_SAVE_TOGGLE, saving);
    }
  },
  uiToggleHeader({ commit }, visible) {
    commit(TYPES.UI_HEADER_TOGGLE, visible);
  },
  uiGuestModalShown({ commit }) {
    commit(TYPES.UI_GUEST_MODAL);
  },
  uiToggleArchiving({ commit }, data) {
    commit(TYPES.UI_SET_ARCHIVING, data);
    window.setTimeout(() => commit(TYPES.UI_SET_ARCHIVING, null), 2000);
  },
  async uiSetCardDragging({ commit }, dragging) {
    await commit(TYPES.UI_SET_CARD_DRAGGING, dragging);
  },

  modalShow({ commit }, payload) {
    let name, data = {};
    if (payload.name) {
      name = payload.name;
      data = payload.data;
    } else {
      name = payload;
    }

    commit(TYPES.UI_MODAL_SHOW, { name, data });
  },
  modalSetData({ commit }, { data }) {
    commit('UI_MODAL_SET_DATA', { data });
  },
  modalHide({ commit, state }, { hideOverlay = true } = {}) {
    if (state.modal === null) {
      return;
    }

    commit(TYPES.UI_MODAL_HIDE, hideOverlay);
  },
  setLightboxVisible({ commit }, visible) {
    commit(TYPES.UI_LIGHTBOX_VISIBLE, visible);
  },
  tipShown({ commit }, id) {
    commit(TYPES.UI_TIP_SHOWN, id);
  },

  registerIdJoin({ commit }, { join, id }) {
    commit(TYPES.UI_REGISTER_ID_JOIN, { join, id });
  },

  toggleDragOver({ commit }, active) {
    commit(TYPES.UI_SET_DRAG_OVER, active);
  },

  uiToggleHideAnswers({ commit, getters }, toggle) {
    commit('UI_TOGGLE_HIDE_ANSWERS', !getters.uiHideAnswers);
  },
  uiAddRemovedItemId({ commit }, id) {
    commit('METHOD_ADD_REMOVED_ITEM_ID', id);
  },
  uiRemoveRemovedItemId({ commit }, id) {
    commit('METHOD_REMOVE_REMOVED_ITEM_ID', id);
  },
  toggleSidebar({ commit }, open) {
    commit('UI_TOGGLE_SIDEBAR', open);
  },
};

const mutations = {
  [TYPES.UI_EDIT_TOGGLE](state, value) {
    if (value !== null) {
      state.editing = value;
      return;
    }

    state.editing = !state.editing;
  },
  [TYPES.UI_SAVE_TOGGLE](state, saving) {
    state.saving = saving;
  },
  [TYPES.UI_HEADER_TOGGLE](state, visible) {
    state.header = visible;
  },
  [TYPES.UI_GUEST_MODAL](state) {
    state.guestModalShown = true;
  },

  [TYPES.UI_MODAL_SHOW](state, { name, data }) {
    state.modalStack.push(name);
    state.modalDataStack.push(data);
    state.modalOverlay = true;
  },
  'UI_MODAL_SET_DATA'(state, { name, data }) {
    merge(state.modalDataStack[state.modalDataStack.length - 1], data);
  },
  [TYPES.UI_MODAL_HIDE](state, hideOverlay) {
    state.modalStack.pop();
    state.modalDataStack.pop();
    if (hideOverlay) {
      state.modalOverlay = false;
    }
  },

  [TYPES.UI_LIGHTBOX_VISIBLE](state, visible) {
    state.lightbox = visible;
  },

  [TYPES.UI_SET_CARD_DRAGGING](state, dragging) {
    state.cardDragging = dragging;
  },

  [TYPES.UI_TIP_SHOWN](state, id) {
    if (state.tipsShown.includes(id)) return;

    state.tipsShown.push(id);
  },

  [TYPES.UI_REGISTER_ID_JOIN](state, { join, id }) {
    merge(state.idJoins, { [join]: id });
    merge(state.idJoinsRev, { [id]: join });
  },

  [TYPES.UI_SET_ARCHIVING](state, data) {
    state.archiving = data;
  },
  [TYPES.UI_SET_DRAG_OVER](state, enabled) {
    state.dragOverActive = enabled;
  },
  UI_TOGGLE_HIDE_ANSWERS(state, enabled) {
    state.hideAnswers = enabled;
    methodOptions.save('default', 'builder-hide-content-library', enabled);
  },
  METHOD_ADD_REMOVED_ITEM_ID(state, id) {
    state.removedItems.push(id);
  },
  METHOD_REMOVE_REMOVED_ITEM_ID(state, id) {
    remove(state.removedItems, (removedId) => removedId === id);
  },
  UI_TOGGLE_SIDEBAR(state, open) {
    state.sidebarOpen = open;
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
