<template>
  <ReportWrap
    :name="section.name || 'Edit section to change title'"
    v-bind="$props"
    :canRemove="true"
    :isEmpty="isEmpty"
  >
    <div class="report-insight" :class="classes">
      <div class="report-insight__body">
        <TitleInput
          appearance="report-title clear"
          :value="section.name"
          :can-edit="canEdit"
          placeholder="Start typing…"
          @input="updateName($event)"
          @focus="hasFocus = true"
          @blur="hasFocus = false"
          ref="title"
          multiline
        />
      
        <Editor
          :tags-enabled="false"
          :allow-image="false"
          :visible-on-start="true"
          :lazy="false"
          :value="section.value"
          :can-edit="canEdit"
          @change="onInput"
          v-if="hasLayout"
          multiline
        />
        <div class="report-choose-layout" :class="{ 'report-choose-layout--hidden': hasFocus }" v-else-if="canEdit">
          Or choose layout...
          <ul class="layout-list layout-list--inline">
            <li @click="selectLayout('text')">
              <Icon name="report-layout-text" />
            </li>
            <li @click="selectLayout('left')">
              <Icon name="report-layout-left" />
            </li>
            <li @click="selectLayout('right')">
              <Icon name="report-layout-right" />
            </li>
            <li @click="selectLayout('center')">
              <Icon name="report-layout-center" />
            </li>
          </ul>
        </div>
      </div>
      <div class="report-insight__aside report-insight__aside--full">
        <BaseUploader
          :uploading="image.uploading"
          :file="image.file"
          :url="image.url"
          :can-edit="canEdit"
          @remove="removeImage"
          @change="addImage"
        />
      </div>
    </div>
  </ReportWrap>
</template>

<script>
import Editor from 'components/common/editor';

import ReportWrap from './report-wrap';
import { thisExpression } from '@babel/types';

export default {
  name: 'ProjectMethodCustom',
  components: {
    ReportWrap,
    Editor,
  },
  data() {
    return {
      hasFocus: false,
    }
  },
  computed: {
    image() {
      if (typeof this.section.image === 'string') {
        return { url: this.section.image };
      }
      return this.section.image || {};
    },
    hasLayout() {
      return this.section && this.section.options && this.section.options.layout;
    },
    layout() {
      return (this.section && this.section.options && this.section.options.layout) || 'text';
    },
    classes() {
      return [
        `report-insight--layout-${this.layout}`,
      ];
    },
    isEmpty() {
      return this.section && (!this.section.name && !this.section.value);
    },
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  methods: {
    onInput({ value }) {
      this.$store.dispatch('methodSectionUpdate', { id: this.section.id, data: { value } });
    },
    updateName(name) {
      if (this.section) {
        this.$store.dispatch('methodSectionUpdate', {
          id: this.section.id,
          data: {
            name,
          },
        })

        if (!this.hasLayout) {
          this.selectLayout(this.layout);
        }
      }
    },
    selectLayout(layout) {
      if (this.section) {
        this.$store.dispatch('builderUpdateSectionOptions', {
          id: this.section.id,
          options: {
            layout,
          },
        })

        this.$refs.title.focus();
      }
    },
    addImage(image) {
      this.$store.dispatch('builderAddSectionImage', {
        id: this.section.id,
        image,
      });
    },
    removeImage(imageId) {
      this.$store.dispatch('builderRemoveSection', {
        id: this.section.id,
      });
    },
  },
};
</script>