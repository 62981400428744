/* global __DEV__, __STAGING__ */

import { UNLOAD, VISIBILITY_HIDDEN } from 'constants/events';
import * as KEYS from 'constants/storage';
import { load, save } from 'helpers/storage';
import EventsService from 'services/events.service';
import store from 'stores';

const user = {
  load: () => load(KEYS.USER),
  save: (data) => save(KEYS.USER, data),
};

const methodsDescriptions = {
  load: () => load(KEYS.METHODS_DESCRIPTIONS),
  save: (data) => save(KEYS.METHODS_DESCRIPTIONS, data),
};

const projectsAnimation = {
  load: () => load(KEYS.PROJECTS_ANIMATION),
  save: (data) => save(KEYS.PROJECTS_ANIMATION, data),
};

const ui = {
  load: () => load(KEYS.UI),
  save: (data) => save(KEYS.UI, data),
};

const publicToken = {
  load: () => load('public-token', true /*session*/),
  save: (data) => save('public-token', data, true /*session*/),
};

const selectedWorkspace = {
  load: () => load('selected-workspace'),
  save: (id) => save('selected-workspace', id),
};

const savedTags = {
  load: (id) => {
    const data = load(KEYS.SAVED_TAGS);
    if (!data) return [];
    return data[id] || [];
  },
  save: (id, tags) => {
    const data = load(KEYS.SAVED_TAGS) || {};
    save(KEYS.SAVED_TAGS, { ...data, [id]: tags });
  },
};

const savedTagsFilter = {
  load: (id) => {
    const data = load(KEYS.SAVED_TAGS_FILTER);
    if (!data) return null;
    return data[id];
  },
  save: (id, tags) => {
    const data = load(KEYS.SAVED_TAGS_FILTER) || {};
    const toSave = { ...data, [id]: tags };

    if (!tags) {
      delete toSave[id];
    }

    save(KEYS.SAVED_TAGS_FILTER, toSave);
  },
};

export const methodOptions = {
  data: null,
  init()  {
    this.data = load(KEYS.METHODS_OPTIONS) || {};
  },
  load(id, option, defaultValue = null) {
    const data = this.data || load(KEYS.METHODS_OPTIONS) || {};
    const method = data[id];
    if (!method) return defaultValue;
    return method[option] === undefined ? defaultValue : method[option];
  },
  save(id, option, value) {
    const data = this.data || load(KEYS.METHODS_OPTIONS) || {};
    const method = data[id] || {};
    const toSave = { ...method, [option]: value };

    data[id] = toSave;

    save(KEYS.METHODS_OPTIONS, data);
  },
};

methodOptions.init();

// setup save handler when app is exited
const init = () => {
  const saveHandler = () => {
    ui.save({
      guestModalShown: store.getters.guestModalShown,
      tipsShown: store.getters.tipsShown,
    });
    methodsDescriptions.save(store.getters.methodReadDescriptions);
    projectsAnimation.save(store.getters.projectsAnimationShown);
  };

  EventsService.on(UNLOAD, saveHandler);
  EventsService.on(VISIBILITY_HIDDEN, saveHandler);

  if (__DEV__ || __STAGING__) {
    window.__DISABLE_STORAGE = () => {
      EventsService.off(UNLOAD, saveHandler);
      EventsService.off(VISIBILITY_HIDDEN, saveHandler);
    };

    window.__CLEAR_STORAGE = () => {
      window.__DISABLE_STORAGE();
      localStorage.clear();
    };
  }
};

export default {
  init,

  user,
  ui,
  methodsDescriptions,
  projectsAnimation,
  savedTags,
  savedTagsFilter,
  publicToken,
  selectedWorkspace,
};
