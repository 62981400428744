<template>
  <div
    v-if="!isSurvey && !isPublicRoute"
    class="header-wrap"
  >
    <header
      v-if="!isOnboardingRoute"
      v-scroll-direction="onScrollDirection"
      class="header"
      :class="classes"
    >
      <div
        class="container-fluid"
        :class="containerClasses"
      >
        <template v-if="!isSurvey">
          <div class="header__left">
            <div
              class="header__burger"
              @click="toggleSidebar"
            >
              <Icon name="menu" />
            </div>
            <HeaderLogo class="header__logo" />
            <SavingIndicator />
          </div>
          <div
            v-if="!isShared"
            class="header__right"
          >
            <HeaderUser />
          </div>
        </template>
        <HeaderSurvey v-else />
      </div>
    </header>
    <Sidebar />
  </div>
</template>

<script>
import HeaderLogo from 'components/header/logo';
import HeaderSurvey from 'components/header/survey';
import HeaderUser from 'components/header/user';
import {
  COMPETITOR,
  PERSONA,
  PROJECT_ARCHIVE,
  PROJECT_HIGHLIGHTS,
  PROJECT_INSIGHTS,
  PROJECT_METHOD,
  PROJECT_METHOD_NEW,
  PROJECT_METHOD_PAGE,
  PROJECT_METHODS,
  PROJECT_ONBOARDING,
  PROJECT_REPORT,
  PROJECT_SHORT,
  PROJECT_SURVEY,
  PROJECT_TAG,
  PROJECT_TAGS,
  PROJECT_TEAM,
  PROJECTS_LIST,
  SETTINGS,
  SIGN_IN,
  WORKSPACE_INSIGHTS,
  WORKSPACE_ONBOARDING,
  WORKSPACE_RECENT,
} from 'constants/routes';
import { xsMax } from 'helpers/ui';
import EventsService from 'services/events.service';
import { SHARABLE_ROUTES } from 'services/shortcut.service';
import { mapGetters } from 'vuex';

import SavingIndicator from './saving';
import Sidebar from './sidebar';

export default {
  name: 'HeaderApp',
  lastScrollEvent: null,
  scrollDirection: 'down',
  components: {
    HeaderUser,
    HeaderSurvey,
    SavingIndicator,
    Sidebar,
    HeaderLogo,
  },
  data() {
    return {
      underTitle: false,
      isMobile: false,
      sidebarBreakpoint: true,
    };
  },
  computed: {
    ...mapGetters([
      'hasUser',
      'userCanEditProjects',
      'isShared',
      'isSharedLoading',
      'shareEntry',
      'routeName',
      'projectCurrent',
      'detailsPageTitle',
      'current',
      'projectCurrentMethod',
      'isEditing',
      'isLibraryOpen',
      'isSidebarOpen',
      'workspaceCurrentId',
      'isPublicRoute',
    ]),
    canEdit() {
      return this.userCanEditProjects;
    },
    shouldRenderMenu() {
      return (!this.isMobile || this.isMobile && !this.isProjectMethodRoute && !this.isCompetitorRoute && !this.isPersonaRoute) && !this.shouldRenderSidebar && !this.isLibraryOpen;
    },
    isWorkspaceRecentRoute() {
      return this.$store.getters.isRoute(WORKSPACE_RECENT);
    },
    isWorkspaceInsightsRoute() {
      return this.$store.getters.isRoute(WORKSPACE_INSIGHTS);
    },
    isSurvey() {
      return this.isSurveyRoute;
    },
    isSharableRoute() {
      return SHARABLE_ROUTES.includes(this.routeName);
    },
    isCompetitorRoute() {
      return this.$store.getters.isRoute(COMPETITOR);
    },
    isPersonaRoute() {
      return this.$store.getters.isRoute(PERSONA);
    },
    inAddNewMethod() {
      return this.$store.getters.isRoute(PROJECT_METHOD_NEW);
    },
    isProjectMethodsRoute() {
      return this.$store.getters.isRoute(PROJECT_METHODS);
    },
    isProjectMethodRoute() {
      return this.$store.getters.isRoute(PROJECT_METHOD);
    },
    isProjectsRoute() {
      return this.$store.getters.isRoute(PROJECTS_LIST);
    },
    isProjectTagsRoute() {
      return this.$store.getters.isRoute(PROJECT_TAGS);
    },
    isProjectTagRoute() {
      return this.$store.getters.isRoute(PROJECT_TAG);
    },
    isProjectHighlightsRoute() {
      return this.$store.getters.isRoute(PROJECT_HIGHLIGHTS);
    },
    isProjectInsightsRoute() {
      return this.$store.getters.isRoute(PROJECT_INSIGHTS);
    },
    isProjectArchiveRoute() {
      return this.$store.getters.isRoute(PROJECT_ARCHIVE);
    },
    isProjectReportRoute() {
      return this.$store.getters.isRoute(PROJECT_REPORT);
    },
    isProjectTeamRoute() {
      return this.$store.getters.isRoute(PROJECT_TEAM);
    },
    isSettingsRoute() {
      return this.$store.getters.isRoute(SETTINGS);
    },
    isDetailsPageRoute() {
      return this.$store.getters.isRoute(PROJECT_METHOD_PAGE);
    },
    isSurveyRoute() {
      return this.$store.getters.isRoute(PROJECT_SURVEY);
    },
    isOnboardingRoute() {
      if(!this.$store.getters.routeName) return false;

      return (
        this.$store.getters.routeName.includes(SIGN_IN) ||
        this.$store.getters.routeName.includes(WORKSPACE_ONBOARDING) ||
        this.$store.getters.routeName.includes(PROJECT_ONBOARDING)
      );
    },
    PROJECTS_LIST() {
      return PROJECTS_LIST;
    },
    isHeaderVisible() {
      return !this.isProjectMethodRoute || this.$store.getters.isHeaderVisible;
    },
    shouldRenderSidebar() {
      return this.sidebarBreakpoint && !this.isShared && !this.isEditing && (this.isProjectMethodRoute || this.isProjectMethodsRoute || this.isProjectTagsRoute || this.isProjectHighlightsRoute || this.isProjectInsightsRoute || this.isProjectTagRoute || this.isProjectTeamRoute || this.inAddNewMethod || this.isCompetitorRoute || this.isPersonaRoute || this.isProjectArchiveRoute || this.isProjectReportRoute);
    },
    classes() {
      return {
        'header--shared': this.isShared || this.isSurveyRoute,
        'header--under-title': this.underTitle,
      };
    },
    containerClasses() {
      return {
        'js-surveys': this.isSurveyRoute,
      };
    },
    title() {
      if (this.isCompetitorRoute || this.isPersonaRoute) {
        return this.$store.getters.currentSubItemName;
      }
      if (this.isDetailsPageRoute) {
        return this.detailsPageTitle;
      }

      if (this.projectCurrentMethod) {
        return this.projectCurrentMethod.name;
      }

      if (this.projectCurrent && this.projectCurrent.name) {
        return this.projectCurrent.name;
      }

      return '';
    },
  },
  watch: {
    shouldRenderMenu(should, old) {
        this.underTitle = false;
    },
  },
  mounted() {
    EventsService.on('scroll', this.onScroll);
    EventsService.on('resize', this.onResize);
    this.onResize();
  },
  unmounted() {
    EventsService.off('scroll', this.onScroll);
    EventsService.off('resize', this.onResize);
  },
  methods: {
    onScrollDirection(direction) {
      this.scrollDirection = direction;
      this.onScroll(this.lastScrollEvent);
    },
    onScroll(event) {
      const { scrollDirection } = this;
      const scrollTop = window.pageYOffset;

      const underTitle = scrollTop > 120
      const visible = scrollDirection === 'up' || scrollTop < 80;

      this.$store.dispatch('uiToggleHeader', visible);
      this.lastScrollEvent = event;
      this.underTitle = underTitle;
    },
    onResize() {
      this.isMobile = xsMax();
      this.sidebarBreakpoint = !xsMax();
    },
    toggleEditMode() {
      this.$store.dispatch('uiToggleEdit');
    },
    toggleSidebar() {
      this.$store.dispatch('toggleSidebar', !this.isSidebarOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
  .js-static {
    width: 100%;
    position: fixed;
    margin-bottom: 0;
    z-index: 99;
  }
  .js-surveys {
    margin: 0;
    width: 100%;
  }
</style>
