<template>
  <div
    class="onb-header"
    :class="{ 'onb-header--editor': side }"
  >
    <div
      class="onb-header__logo"
      @click="logoClick"
    >
      <Icon name="logotype" />
    </div>
    <transition name="fade">
      <div
        v-if="progress"
        class="onb-header__middle"
      >
        <div class="progress-bar">
          <div
            class="progress-bar__bar"
            :style="{ width: `${ progress * 100 }%` }"
          />
        </div>
      </div>
    </transition>
    <div class="onb-header__side">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="user && user.registered"
          key="avatar"
        >
          <DropdownMenu :menu="menu">
            <Avatar
              class="header__user-image"
              :url="user.avatarUrl"
              :email="user.email"
              :name="user.name"
              @click="showLogin"
            />
          </DropdownMenu>
        </div>
        <div
          v-else
          :key="sideLink"
        >
          <a
            class="link link--dark"
            @click="$emit('side-link-click')"
          >{{ sideLink }}</a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { navigateLanding, navigateProjects, navigateSignin } from 'helpers/router';
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingHeader',
  props: {
    side: Boolean,
    user: [Object, null],
    progress: Number,
    sideLink: String,
  },
  data: () => ({

  }),
  computed: {
    ...mapGetters(['isLoggedIn']),
    menu() {
      return [
        {
          text: this.user && this.user.email,
          disabled: true,
        },
        '-',
        {
          text: 'Sign out',
          action: () => {
            this.$store.dispatch('logOut');
            navigateSignin();
          },
        },
      ];
    },
  },
  watch: {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    logoClick() {
      if (this.isLoggedIn) {
        navigateProjects();
      } else {
        navigateLanding();
      }
    },
  },
};
</script>
