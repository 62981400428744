<template>
  <div
    v-if="!canEdit"
    class="base-input-2"
  >
    {{ myValue }}
  </div>
  <textarea
    v-else-if="multiline"
    ref="input"
    v-model="myValue"
    class="base-input-2 base-input-2--textarea"
    v-bind="$attrs"
    :readonly="!canEdit"
    :spellcheck="false"
    rows="1"
    :class="{ 'cant-edit': !canEdit }"
    @input="onInput"
    @change="onChange"
    @blur="onBlur"
    @keydown.enter="onChange"
    @keydown.esc="onBlur"
    @mousedown="onFocus"
    @touchstart="onFocus"
    @mouseup="mouseup"
  />
  <input
    v-else
    ref="input"
    v-model="myValue"
    class="base-input-2"
    v-bind="$attrs"
    :readonly="!canEdit"
    :spellcheck="false"
    :class="{ 'cant-edit': !canEdit }"
    @input="onInput"
    @change="onChange"
    @blur="onBlur"
    @keydown.enter="onChange"
    @keydown.esc="onBlur"
    @mousedown="onFocus"
    @touchstart="onFocus"
    @mouseup="mouseup"
  >
</template>
<script>
import autosize from 'autosize';
import debounce from 'lodash/debounce';

export default {
  name: 'BaseInput2',
  props: {
    value: String,
    multiline: {
      type: Boolean,
      default: false,
    },
    remember: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      backupValue: null,
      myValue: null,
      isEditing: false,
      preventMouseUp: false,
    };
  },
  watch: {
    value(v) {
      this.myValue = v;
      if (this.multiline) {
        autosize.update(this.$refs.input);
      }
    },
  },
  created() {
    this.myValue = this.value;
    this.backupValue = this.value;
  },
  mounted() {
    autosize(this.$refs.input);
  },
  updated() {
    autosize.update(this.$refs.input);
  },
  unmounted() {
    window.setTimeout(() => {
      autosize.destroy(this.$refs.input);
    }, 500);
  },
  methods: {
    onInput() {
      this.$emit('input', this.myValue);
    },
    onChange() {
      if (this.remember && this.myValue && this.myValue.trim().length > 0) {
        this.emitChange();
      } else {
        this.emitChange();
      }
    },
    emitChange: debounce(function() {
      this.$emit('change', this.myValue);
    }, 100),
    select() {
      this.$refs.input.select();
      this.preventMouseUp = true;
    },
    mouseup(e) {
      if (this.preventMouseUp) {
        e.preventDefault();
      }
      this.preventMouseUp = false;
    },
    onFocus() {
      if (document.activeElement === this.$refs.input) return;

      this.backupValue = this.myValue;
      this.isEditing = true;
      this.$nextTick(() => {
        if (this.isEditing) {
          this.select();
        }
      });
    },
    onBlur() {
      if (!this.remember) return;
      this.isEditing = false;
      if (this.myValue === null || !(this.myValue && this.myValue.length)) {
        this.myValue = this.backupValue;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.base-input-2 {
  border: 0;
  background: transparent;
  text-align: left;
  color: #3b3b3b;
  width: 100%;
  outline: 0;
  &::placeholder {
    opacity: 0.4;
    color: #3b3b3b;
  }

  &--textarea {
    resize: none;
    padding: 0px;
  }
}
</style>
