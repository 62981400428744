<template>
  <div
    class="base-answer-add"
    @click="$refs.input.focus()"
  >
    <div
      class="base-answer-add__container"
      :class="{ active: active }"
    >
      <BaseTextarea
        ref="input"
        v-model="content"
        class="base-answer-add__input"
        :placeholder="placeholder"
        @keydown.enter.prevent="submit"
        @change="submit"
      />
      <TagsAvatars
        :tags="answerTags"
        class="base-answer-add__tags-avatars"
        @click="showSelector"
      >
        <TagsSelector
          ref="selector"
          v-model="answerTags"
          :tags="tags"
        />
      </TagsAvatars>
    </div>
  </div>
</template>

<script>
import TagsAvatars from 'views/page/common/tags-avatars';
import TagsSelector from 'views/page/common/tags-selector';
import { mapGetters } from 'vuex';

export default {
  name: 'BaseAnswerAdd',
  components: {
    TagsAvatars,
    TagsSelector,
  },
  props: {
    placeholder: {
      type: String,
    },
    newTags: {
      type: Array,
      default: () => [],
    },
    filterTags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      content: '',
      tagsOpen: false,
    };
  },
  watch: {
    active() {
      this.$emit('toogle', this.active);
    },
  },
  computed: {
    ...mapGetters(['tags', 'userCanEditProjects']),
    active() {
      return this.tagsOpen;
    },
    answerTags: {
      get() {
        return this.newTags;
      },
      set(value) {
        this.$emit('tags-change', value);
      },
    },
  },
  methods: {
    submit() {
      if (!this.content.length) return;
      this.$emit('answer-add', this.content);
      this.content = '';
    },
    showSelector(e) {
      e.stopPropagation();
      this.$refs.selector.show(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-answer-add {
  &__container {
    border-bottom: 1px solid $color-white;
    border-radius: 3px;
    color: #3b3b3b;
    cursor: pointer;
    font-size: 1.6rem;
    margin-left: -15px;
    padding: 16px 75px 16px 15px;
    position: relative;
    margin-right: 45px;
    &:hover,
    &:focus,
    &:focus-within,
    &.active {
      background-color: #F8F9FA;

      .base-answer-add__tags-avatars::v-deep .tags-avatars__list--empty {
        opacity: 1;
      }

      .base-answer-add__menu {
        opacity: 1;
        visibility: visible;
      }

      &:hover span.empty {
        opacity: 1;
      }
    }
  }

  &__menu:hover {
    background-color: $color-grey-porcelain;
  }

  &__tags-avatars::v-deep .tags-avatars__list--empty {
    opacity: 0;
  }

  &__input {
    display: block;
    min-height: 100%;
    border: 0;
    background: transparent;
    padding: 0;
    width: 100%;
    outline: 0;
    resize: none;
    height: auto;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 2rem !important;
    text-align: left;
    color: #3b3b3b;

    &:focus {
      opacity: 1;
      visibility: visible;
    }

  }

  &__menu {
    width: 42px;
    height: 100%;
    border: 0;
    color: #555555;
    text-align: center;
    margin: 0 5px;
    position: absolute;
    right: -47px !important;
    top: 0;
    outline: 0;
    background: #ffffff image_url('svg/trash-2.svg') no-repeat center center;
    opacity: 0;
    transition: all 0.5s ease;
  }
}
</style>
