/* global __DEV__, __STAGING__ */
/* eslint-disable */

global.__DEV__  = process.env.NODE_ENV === 'development';
global.__STAGING__  = process.env.NODE_ENV === 'staging';
global.__RELEASE__  = process.env.NODE_ENV === 'release';
global.__TEST__  = process.env.NODE_ENV === 'test';
global.__PRODUCTION__  = process.env.NODE_ENV === 'production';

let PAGE_URL;
let API_URL;
let CABLE_URL;
let GOOGLE_ID;
let FACEBOOK_ID;

if (__DEV__) {
  PAGE_URL = 'http://release.talebook.io';
  API_URL = 'https://talebook-api-release.herokuapp.com';
  CABLE_URL = 'wss://talebook-api-release.herokuapp.com/cable';
  GOOGLE_ID = '498053173231-gshoca4lu8vgtr60s76u2jlqv3t6ijm6.apps.googleusercontent.com';
  FACEBOOK_ID = '1525277727521807';
}

if (__STAGING__ || __TEST__) {
  PAGE_URL = 'http://release.talebook.io';
  API_URL = 'https://talebook-api-release.herokuapp.com';
  CABLE_URL = 'wss://talebook-api-release.herokuapp.com/cable';
  GOOGLE_ID = '498053173231-gshoca4lu8vgtr60s76u2jlqv3t6ijm6.apps.googleusercontent.com';
  FACEBOOK_ID = '1525277727521807';
}

if (__RELEASE__) {
  PAGE_URL = 'http://release.talebook.io';
  API_URL = 'https://talebook-api-release.herokuapp.com';
  CABLE_URL = 'wss://talebook-api-release.herokuapp.com/cable';
  GOOGLE_ID = '498053173231-gshoca4lu8vgtr60s76u2jlqv3t6ijm6.apps.googleusercontent.com';
  FACEBOOK_ID = '1525277727521807';
}

if (__PRODUCTION__) {
  PAGE_URL = 'https://app.talebook.io';
  API_URL = 'https://talebook.herokuapp.com';
  CABLE_URL = 'wss://talebook.herokuapp.com/cable';
  GOOGLE_ID = '498053173231-vm8ci6lvhd4ckl41bsckp37l94nf2l50.apps.googleusercontent.com';
  FACEBOOK_ID = '389731878135935';
}

const TWITTER_ID = 'Du4zFs5Q1KHs6n2BQjYJV6dst';

export const MIN_PASSWORD_LENGTH = 8;
export const USE_FAKE_PAYMENTS = false;
export const USE_PAYPAL = false;

export {
  API_URL,
  GOOGLE_ID,
  FACEBOOK_ID,
  TWITTER_ID,
  PAGE_URL,
  CABLE_URL,
};