export default [
  {
    name: 'Phase 1 - Discover',
    type: 'discover',
    methods: [
      {
        id: 'StakeholderInterview',
        name: 'Stakeholder Interview',
        icon: 'method-stakeholder-interview',
        description: 'Gain a deeper understanding of client\'s expectations. Start with predefined questions to ask your stakeholder.',
      },
      {
        id: 'UserInterview',
        name: 'User Interview',
        icon: 'method-user-interview',
        description: 'Recognize the problems and pain points of your target audience while talking to them.',
      },
      {
        id: 'CompetitorAnalysis',
        name: 'Competitor Analysis',
        icon: 'method-competitor-analysis',
        description: 'Analyze your competitors and evaluate their strengths, weaknesses and distinguishing features.',
      },
    ],
  },
  {
    name: 'Phase 2 - Analyze',
    type: 'analyze',
    methods: [
      {
        id: 'ProblemValuation',
        name: 'Problem Valuation',
        icon: 'method-problem-valuation',
        description: 'Examine your data and evaluate all the problems you discovered so far from your interviews.',
      },
      {
        id: 'PersonasMethod',
        name: 'Personas',
        icon: 'method-personas-method',
        description: 'Streamline your research data and represent the specific considerations for fundamentally different groups of people.',
      },
      {
        id: 'SolutionValuation',
        name: 'Solution Valuation',
        icon: 'method-solution-valuation',
        description: 'Tackle the most crucial problems by brainstorming different solutions. Come up with as many solutions as possible.',
      },
      {
        id: 'FlowsMethod',
        name: 'Flows',
        icon: 'method-flows-method',
        description: 'Connect the dots between the identified problems and their solutions. List down 5-8 steps showing a clear path from the problem to its corresponding solution.',
      },
    ],
  },
  {
    name: 'Phase 3 - Prototype',
    type: 'prototype',
    methods: [
      {
        id: 'PaperPrototype',
        name: 'Paper Prototype',
        icon: 'method-interactive-prototype',
        description: 'Start with simple prototypes created on paper. It\'s a simple and cheap way to test your product without much effort.',
      },
      {
        id: 'InteractivePrototype',
        name: 'Interactive Prototype',
        icon: 'method-interactive-prototype',
        description: 'Use a hi-fidelity prototype to gain more precise feedback and take your prototype to the next level.',
      },
    ],
  },
];
