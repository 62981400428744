<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="prompt"
    >
      <div
        class="prompt__bg"
        @click="hide"
      />
      <div
        v-if="visible"
        class="prompt__body"
      >
        <div class="prompt__header">
          {{ data.text }}
          <button
            type="button"
            class="prompt__close"
            @click="hide"
          >
            <Icon name="close" />
          </button>
        </div>
        <div class="prompt__content">
          <ListMenu :menu="data.menu" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ListMenu from 'components/common/base-list-menu';
import { mapGetters } from 'vuex';

export default {
  name: 'Prompts',
  components: {
    ListMenu,
  },
  computed: {
    ...mapGetters([
      'prompts',
    ]),
    visible() {
      return this.prompts.length;
    },
    data() {
      return this.prompts[0];
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('promptsHide');
    },
  },
};
</script>
