<template>
  <div
    ref="wrap"
    class="sortable-drop"
    :data-sortable-group="!disabled && group"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'SortableDrop',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: 'default',
    },
    canDragIn: {
      type: Function,
      default: () => true,
    },
  },
  data: () => ({
  }),
  mounted() {
    this.$el._sortable = this;
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>