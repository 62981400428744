<template>
  <div class="limit-reached-box">
    <div class="limit-reached-box__body">
      <h2 class="limit-reached-box__title">This insight is inactive</h2>
      Free account limit of 15 insights has been reached.
    </div>
    <button class="btn btn--light limit-reached-box__upgrade" @click="navigateUpgrade">Upgrade</button>
  </div>
</template>

<script>
import { navigateUpgrade } from 'helpers/router';

export default {
  name: 'LimitReachedBox',
  props: {
  },
  computed: {

  },
  methods: {
    navigateUpgrade,
  },
};
</script>
