import * as ROUTES from 'constants/routes';
import { truncate } from 'helpers/string';
import { timeText } from 'helpers/time';
import store from 'stores';

const talebook = 'Talebook';
const HANDLERS = {
  [ROUTES.PROJECTS_LIST]: () => 'Projects',
  [ROUTES.WORKSPACE_RECENT]: () => 'Recent',
  [ROUTES.WORKSPACE_INSIGHTS]: () => 'Insights',
  [ROUTES.WORKSPACE_SETTINGS]: () => 'Settings',
  [ROUTES.SIGN_IN]: () => 'Sign in',
  [ROUTES.WORKSPACE_ONBOARDING]: () => 'Create first workspace',
  [ROUTES.PROJECT_ONBOARDING]: () => 'Create first project',
  'modal-method': () => {
    const modalData = store.getters.modalData;
    const method = store.getters.projectMethodById(modalData.id);
    return method ? `${method.name}` : '';
  },
  [ROUTES.PROJECTS_LIST]: () => 'My projects',
  [ROUTES.PROJECT_METHODS]: () => {
    const project = store.getters.projectCurrent;
    const isEditing = store.getters.isEditing;
    if (!project) return null;

    return `${isEditing ? 'Edit ' : ''} ${project.name}`;
  },
  [ROUTES.PROJECT_METHOD]: () => {
    const method = store.getters.projectCurrentMethod;
    if (!method) return null;

    return method.name || 'Access denied';
  },
  [ROUTES.PROJECT_REPORT]: () => {
    const method = store.getters.projectCurrentMethod;
    const workspace = store.getters.workspaceCurrent;
    const projectCurrent = store.getters.projectCurrent;

    if (!method || !workspace || !projectCurrent) return null;

    const heroItem = method.customMethodItems && method.customMethodItems.find((i) => {
      return i.type === 'report_5';
    });

    if (!heroItem) {
      return null;
    }

    const title = heroItem.name || 'Usability Test Report';
    const date = (heroItem.options && heroItem.options.dateText) || timeText(projectCurrent && projectCurrent.createdAt);

    return `${title} - ${workspace.name} - ${date} - report.pdf`;
  },
  [ROUTES.PROJECT_TEAM]: () => 'Access',
  [ROUTES.SETTINGS]: () => 'Settings',
  [ROUTES.PROJECT_TAGS]: () => 'Tags',
  [ROUTES.PROJECT_HIGHLIGHTS]: () => 'Highlights',
  [ROUTES.PROJECT_INSIGHTS]: () => 'Insights',
  [ROUTES.COMPETITOR]: () => {
    const method = store.getters.projectCurrentMethod;
    if (!method || !method.competitors) return null;
    const competitor = method.competitors.find(
      (competitor) => competitor.id === store.getters.routeParams.competitor_id
    );
    const competitorName = truncate(competitor.content, 28);
    const methodName = truncate(method.name, 28);
    return competitorName ? `${competitorName} - ${methodName}` : 'Access denied';
  },
  [ROUTES.PERSONA]: () => {
    const method = store.getters.projectCurrentMethod;
    if (!method || !method.personas) return null;
    const persona = method.personas.find(
      (persona) => persona.id === store.getters.routeParams.persona_id
    );
    const personaName = truncate(persona.name, 28);
    const methodName = truncate(method.name, 28);
    return personaName ? `${personaName} - ${methodName}` : 'Access denied';
  },
  [ROUTES.PROJECT_TAG]: () => {
    const tags = store.getters.tags;
    const tagId = store.getters.tagCurrentId;
    const tag = tags.find((t) => t.id === tagId);
    if (!tag || !tag.name) return null;
    const tagName = truncate(tag.name, 28);
    return tagName;
  },
};

const onChange = () => {
  const route = store.getters.routeName;
  const modal = store.getters.modalActive;
  let title = null;
  if (HANDLERS.hasOwnProperty(`modal-${modal}`)) {
    title = HANDLERS[`modal-${modal}`]();
  } else if (HANDLERS.hasOwnProperty(route)) {
    title = HANDLERS[route]();
  }

  if (!title) {
    title = talebook;
  } else {
    title = `${title} - ${talebook}`;
  }

  document.title = title;
};

const init = () => {
  const CHANGE_TRIGGERS = [
    'routeName',
    'isEditing',
    'projectCurrent',
    'projectCurrentMethod',
    'projectCurrentMethodName',
    'tagCurrentId',
    'tag',
    'modalActive',
  ];

  CHANGE_TRIGGERS.forEach((trigger) =>
    store.watch((state, getters) => getters[trigger], onChange)
  );

  onChange();
};

export default {
  init,
};
