export const from = (query) => {
  const result = {};
  query.split('&').forEach((string) => {
    const [key, value] = string.split('=');
    result[key.toLowerCase()] = decodeURIComponent(value) || true;
  });

  return result;
};

export const to = (data = {}) => {
  if (!Object.keys(data).length) return '';

  const string = Object.keys(data).reduce(
    (result, key) => `${key}=${encodeURIComponent(data[key])}`,
    ''
  );

  return `?${string}`;
};
