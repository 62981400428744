/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import { byPosition } from 'helpers/sort';
import { parser } from 'models/project-method';
import PhaseService from 'services/phases.service';
import UndoService from 'services/undo.service';

const state = {
  phases: {},
};

const getters = {
  projectOriginalPhases: (state, getters) => {
    const projectId = getters.projectCurrentId;
    return (state.phases[projectId] || []).map((phase) => ({
      ...phase,
      designMethods: (phase.designMethods || []).map((method) => getters.projectMethodById(method.id)),
    }));
  },
  projectOriginalPhasesById: (state, getters) => (projectId) => {
    return (state.phases[projectId] || []).map((phase) => ({
      ...phase,
      designMethods: (phase.designMethods || []).map((method) => getters.projectMethodById(method.id)),
    }));
  },
  projectPhases: (state, getters) => {
    return getters.projectOriginalPhases.filter((p) => !['Insights', 'Reports'].includes(p.name));
  },
  projectPhaseById: (state, getters) => (id) => getters.projectPhases.find((phase) => phase.id === id),
  isProjectPhasesFetched: (state, getters) => {
    const projectId = getters.projectCurrentId;
    return !!state.phases[projectId];
  },
};

const actions = {
  async phasesFetch({ commit, getters }, id = null) {
    const { workspaceCurrentId } = getters;

    let projectId = id;
    if (id === null) {
      projectId = getters.projectCurrentId;
    }

    const phases = await PhaseService.fetchAll(workspaceCurrentId, projectId);
    phases.forEach((phase) => {
      phase.designMethods = byPosition((phase.designMethods || []).map(parser));
    });
    const methods = phases.map((phase) => (phase.designMethods || [])).flat();

    commit(TYPES.PROJECT_METHODS_SET, { projectId, methods });
    commit(TYPES.PHASES_SET, { projectId, phases });

    return phases;
  },
  async phasesAdd({ commit, getters }, { name, id = null } = {}) {
    const { workspaceCurrentId } = getters;

    let projectId = id;
    if (id === null) {
      projectId = getters.projectCurrentId;
    }

    const phasesLength = getters.projectPhases.length;

    const phase = await PhaseService.add(workspaceCurrentId, projectId, {
      name,
      position: phasesLength + 1,
    });

    commit(TYPES.PHASES_ADD, { projectId, phase });

    return phase;
  },

  async phaseUpdate({ dispatch, getters, commit }, data) {
    const { workspaceCurrentId } = getters;
    const projectId = getters.projectCurrentId;
    const originalPhase = getters.projectPhaseById(data.id);
    const phase = { ...originalPhase, ...data };

    if (data.archived) {
      UndoService.addWithNotification({
        text: `Phase '${phase.name}' was archived`,
        onResolve: () => {},
        onCancel: async () => {
          try { commit('ARCHIVE_REMOVE', { projectId, id: phase.id }) } catch (e) {}
          await commit(TYPES.PHASES_UPDATE, {
            projectId,
            phase: { ...phase, archived: false },
          });
          await PhaseService.update(workspaceCurrentId, projectId, {
            ...data,
            archived: false,
          });
          await dispatch('phasesFetch');
        },
      });
      dispatch('uiToggleArchiving', { type: 'Phase', data: { ...phase } });
    }

    commit(TYPES.PHASES_UPDATE, { projectId, phase });
    await PhaseService.update(workspaceCurrentId, projectId, data);
    await dispatch('phasesFetch');
  },

  async phaseMove({ dispatch, getters, commit }, data) {
    const { phase, move } = data;
    const { workspaceCurrentId } = getters;
    const projectId = getters.projectCurrentId;
    const index = getters.projectPhases.findIndex((p) => p.id === phase.id);
    const newIndex = index + move;
    let phases = [...getters.projectPhases];

    if (newIndex < 0 || newIndex >= phases.length) {
      return;
    }

    commit('PHASE_MOVE', {
      projectId,
      id: phase.id,
      index: newIndex,
    });

    phases = [...getters.projectPhases];

    const newOrder = phases.map((p) => ({
      id: p.id,
      position: p.position,
    }))

    await PhaseService.reposition(workspaceCurrentId, projectId, { newOrder });

    return;
  },

  async phaseRemove({ dispatch, commit, getters }, data) {
    const { workspaceCurrentId } = getters;
    const { id } = data;
    const projectId = getters.projectCurrentId;
    const phases = getters.projectPhases.filter((phase) => phase.id !== id);
    commit(TYPES.PHASES_SET, { projectId, phases });

    await PhaseService.remove(workspaceCurrentId, projectId, data);
    await dispatch('phasesFetch');
  },
};

const mutations = {
  [TYPES.PHASES_SET](state, { projectId, phases }) {
    state.phases = { ...state.phases, [projectId]: phases };
  },
  [TYPES.PHASES_UPDATE](state, { projectId, phase }) {
    const phases = state.phases[projectId] || [];
    state.phases = { ...state.phases, [projectId]: phases.map((p) => (p.id === phase.id ? phase : p)) };
  },
  [TYPES.PHASES_ADD](state, { projectId, phase }) {
    const phases = state.phases[projectId] || [];
    state.phases = { ...state.phases, [projectId]: [...phases, phase] };
  },
  [TYPES.PROJECT_METHOD_REMOVE](state, { projectId, id }) {
    const phases = state.phases[projectId];
    if (!phases) return;
    state.phases[projectId] = phases.map((phase) => {
      return { ...phase, designMethods: phase.designMethods.filter((method) => {
        return method.id !== id;
      }) };
    });
    state.phases = { ...state.phases };
  },
  [TYPES.PROJECT_METHOD_ADD](state, { method, projectId, index }) {
    const phases = state.phases[projectId];
    const { phaseId } = method;
    const phase = phases.find((p) => p.id === phaseId);

    if (phase) {
      phase.designMethods.splice(index, 0, method);
      phase.designMethods.forEach((method, i) => {
        method.position = i;
      });
    }

    state.phases = { ...state.phases };
  },
  [TYPES.PROJECT_METHOD_CHANGE](state, { method, data, projectId }) {
    const phases = state.phases[projectId];
    if (!phases || !method) return;
    const { phaseId } = method;
    const phase = phases.find((p) => p.id === phaseId);

    if (phase) {
      if (method.position !== data.position && typeof data.position === 'number') {
        phase.designMethods.forEach((method, i) => {
          method.position = i;
        });
        phase.designMethods = phase.designMethods.filter((m) => {
          return m.id !== method.id;
        });
        phase.designMethods.splice(data.position, 0, method);
        phase.designMethods.forEach((method, i) => {
          method.position = i;
        });
      }
    }

    state.phases = { ...state.phases };
  },
  PHASE_MOVE(state, { projectId, id, index }) {
    let phases = state.phases[projectId];
    const phase = phases.find((p) => p.id === id);
    if (!phases || !phase) return;


    phases = phases.filter((p) => p.id !== id);

    phases.splice(index, 0, phase);
    phases.forEach((p, i) => p.position = i);

    state.phases[projectId] = phases;
  },
};

export default {
  mutations,
  actions,
  getters,
  state,
};
