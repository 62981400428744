<template>
  <div class="sidebar-page">
    <div class="side-lay">
      <div class="side-lay__main">
        <div class="side-lay__body">
          <div class="onb-container">
            <div class="onb-avatar-wrap">
              <Icon
                v-if="noInternet"
                name="wifi-off"
                style="width:60px;height:60px;color: #E5411D;"
              />
              <Icon
                v-else
                name="illu-forbidden"
                style="width:209px;height:181px;"
              />
            </div>
            <h2 class="onb-heading">
              <template v-if="noInternet">
                Check your internet connection and try again
              </template>
              <template v-else-if="!isUserInvitedToProject">
                You don’t have permission to<br> access this {{ itemName }}
              </template>
              <template v-else>
                Log in as <strong>{{ isUserInvitedToProject }}</strong> to access this project
              </template>
            </h2>
            <div
              class="onb-buttons"
              style="margin-top:40px;"
            >
              <button
                v-if="noInternet"
                class="btn btn--md btn--new-primary btn--block"
                :class="{
                  'btn--loading': fetching,
                }"
                :disabled="fetching"
                @click="fetchMethod"
              >
                Try again
                <BaseSpinner v-if="fetching" />
              </button>
              <button
                v-else-if="isLoggedIn"
                class="btn btn--md btn--new-primary btn--block"
                @click="navigateProjects"
              >
                Back to project list
              </button>
              <button
                v-else
                class="btn btn--md btn--new-primary btn--block"
                @click="navigateSignin"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navigateProjects, navigateSignin } from 'helpers/router';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectForbidden',
  components: {
  },
  data() {
    return {
      fetching: false,
      selectedMethod: null,
      isDeleteModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'routeName', 'isUserInvitedToProject', 'isProjectCurrentMethodForbidden']),
    itemName() {
      if (this.routeName === 'survey') {
        return 'survey';
      }
      return 'project';
    },
    noInternet() {
      return this.isProjectCurrentMethodForbidden === 'Failed to fetch';
    },
  },
  methods: {
    navigateProjects: () => navigateProjects(),
    navigateSignin: () => navigateSignin(),
    async fetchMethod() {
      if (this.fetching) return;
      this.fetching = true;
      
      try {
        await Promise.all([
          this.$store.dispatch('projectMethodFetch'),
          this.$store.dispatch('requireTags'),
        ]);
      } catch(e) {}
      window.setTimeout(() => {
        this.fetching = false;
      }, 5000);
    },
  },
};
</script>
