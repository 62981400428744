<template>
  <div class="onb-step">
    <div class="onb-form">
      <div class="onb-login-header">
        <h2 class="onb-heading">
          We are giving you free access to our premium features for the next 7 days.
        </h2>
        <p class="onb-subtitle">
          After the free trial your account will automatically downgrade to Free plan
        </p>
      </div>

      <div
        class="pricing-table pricing-table--featured"
      >
        <h2 class="pricing-table__name">
          Professional
        </h2>

        <ul class="pricing-table__features">
          <li
            v-for="feature of [
              'Unlimited collaborators',
              'Unlimited projects',
              'Unlimited insights',
              'Unlimited components',
              'Unlimited tags',
              'Project access managements',
              'Global insights and tags',
            ]"
            :key="feature"
          >
            <span class="icon icon--tick-success"><img
              src="https://talebook.io/wp-content/themes/talebook/assets/img/icons/tick-success.svg"
              alt="tick-success icon"
            ></span> {{ feature }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import * as ROUTES from 'constants/routes';

export default {
  name: 'WorkspaceTrial',
  props: {
    data: Object,
  },
  created() {
    this.updateConfig();
  },
  methods: {
    getConfig() {
      return {
        progress: 4/5,
        avatar: false,
        buttons: [
          {
            appearance: 'new-primary',
            id: 'primary',
            text: 'Continue',
            disabled: false,
          },
          {
            appearance: 'light',
            id: 'secondary',
            text: 'Back',
          },
        ],
      };
    },
    buttonAction(id) {
      if (id === 'secondary') {
        this.$emit('redirect', {
          route: ROUTES.WORKSPACE_ONBOARDING_READY,
          data: this.data,
        });
      } else {
        this.$emit('redirect', {
          route: ROUTES.WORKSPACE_ONBOARDING_TEAM,
          data: this.data,
        });
      }
    },
    updateConfig() {
      this.$emit('config', this.getConfig());
    },
  },
};
</script>
