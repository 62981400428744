<template>
  <div v-if="user">
    <BaseTable
      v-slot="member"
      :animated="true"
      :value="team"
    >
      <SideDropdownMenu
        appearance="outside"
        :menu="getMenu(member)"
      >
        <div class="table-icon-item">
          <UserAvatar
            class="table-icon-item__icon"
            mode="email"
            :name="member.user.email"
          />
          <div class="table-icon-item__body">
            <span class="table-icon-item__title">{{ member.user.name }} <span v-if="member.user.email === user.email">(You)</span></span>
            <span class="table-icon-item__desc">
              {{ member.user.email }}
              <span v-if="mode === 'enablable'">
                ({{roleName(member)}})
              </span>
            </span>
          </div>
          <div
            v-if="mode !== 'enablable'"
            class="table-icon-item__options"
          >
            <template v-if="!member.pending">
              <DropdownMenu
                v-if="userCanManageTeams && member.permission.rights !== 'owner'"
                appearance="light-box-md"
                :dropdown-text="userRoleName(member.permission.rights)"
                :menu="roleMenu(member)"
              />
              <span
                v-else
                class="table-icon-item__dropdown-text"
              >{{ userRoleName(member.permission && member.permission.rights) }}</span>
            </template>
            <span
              v-else
              class="table-icon-item__dropdown-text"
            >Pending...</span>
          </div>
          <div
            v-if="mode === 'enablable'"
            class="table-icon-item__options"
          >
            <strong>{{ members.find(m => m.userId === member.userId) ? 'Enabled' : 'Disabled' }}</strong>
            <Checkbox
              v-if="userCanManageTeams && member.permission && member.permission.rights !== 'owner'"
              appearance="switch switch--xs"
              :checked="members.find(m => m.userId === member.userId)"
              @input="onEnable(member, $event)"
            />
          </div>
        </div>
      </SideDropdownMenu>
    </BaseTable>
    <div
      v-if="canRemove && userCanManageTeams"
      class="table-icon-item"
    >
      <UserAvatar
        class="table-icon-item__icon"
        mode="guest"
      />
      <div class="table-icon-item__body">
        <div class="input-with-dropdown">
          <BaseInput
            :value="inviteEmail"
            @input="inviteEmail = $event"
            class="input input--new-light input-with-dropdown__input"
            placeholder="Enter email address e.g. josh@gmail.com"
            @keydown="error = false"
            @keydown.enter="invite"
            @focus="onNewInputFocus"
          />
          <DropdownMenu
            v-if="userCanManageTeams"
            class="input-with-dropdown__dropdown"
            appearance="light-box-md"
            :dropdown-text="userRoleName(inviteRights)"
            :menu="roleMenu()"
          />
        </div>
      </div>
      <div class="table-icon-item__options">
        <BaseButton
          appearance="primary"
          :disabled="!inviteEmailValid"
          @click="invite"
        >
          Invite
        </BaseButton>
      </div>
    </div>
    <div
      v-if="error"
      class="table-icon-item-error"
    >
      {{ error }}
    </div>
  </div>
</template>
<script>
import { validate } from 'helpers/email';
import { userRoleName } from 'helpers/names';
import { navigateUpgrade } from 'helpers/router';
import { mapGetters } from 'vuex';
import { analyticsLogEvent } from 'utils/analytics';

export default {
  name: 'TeamList',
  props: {
    team: {
      type: Array,
      required: true,
    },
    members: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    mode: {
      type: String,
      required: false,
      default: 'editable',
    },
    canRemove: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      error: false,
      inviteEmail: '',
      inviteRights: 'read',
    };
  },
  computed: {
    ...mapGetters(['isUserOwner', 'isUserEditor', 'isUserReader', 'user', 'userCanManageTeams', 'usersLimitReached']),
    inviteEmailValid() {
      return this.inviteEmail;
    },
  },
  created() {

  },
  methods: {
    roleName(member) {
      if (member && member.pending) {
        return 'Pending...';
      }
      return userRoleName(member.permission && member.permission.rights);
    },
    roleMenu(member) {
      let isNew = false;

      if (!member) {
        member = {
          permission: {
            rights: this.inviteRights === 'write' ? 'write' : 'read',
          },
        }
        isNew = true;
      }

      return [{
        icon: 'eye',
        text: 'Viewer',
        desc: 'Can preview projects',
        appearance: 'box-icon',
        selected: member.permission && member.permission.rights === 'read',
        action: () => {
          if (isNew) {
            this.inviteRights = 'read';
            analyticsLogEvent('team_set_new_read');
            return;
          }

          this.$store.dispatch('workspaceUpdateMemberRights', {
            id: member.id,
            userId: member.userId,
            rights: 'R',
          });
        },
      }, {
        icon: 'edit',
        text: 'Editor',
        badge: this.usersLimitReached && 'Team plan',
        desc: 'Can edit and add projects',
        appearance: 'box-icon',
        selected: member.permission && member.permission.rights === 'write',
        action: () => {
          if (this.usersLimitReached) {
            navigateUpgrade();
            return;
          }

          if (isNew) {
            this.inviteRights = 'write';
            analyticsLogEvent('team_set_new_write');
            return;
          }

          this.$store.dispatch('workspaceUpdateMemberRights', {
            id: member.id,
            userId: member.userId,
            rights: 'W',
          });
        },
      }];
    },
    onEnable(member, enabled) {
      this.$emit('enable', { member, enabled });
    },
    getMenu(member) {
      if (!this.canRemove || member.pending || member.permission.rights === 'owner' || this.mode === 'enablable' || !this.userCanManageTeams) return;

      return [{
        text: 'Remove',
        appearance: 'warn',
        confirm: true,
        action: () => {
          this.$store.dispatch('workspaceRemoveMember', {
            id: member.id,
            userId: member.user.id,
          });
          this.$emit('removeMember', member);
        },
      }];
    },
    async invite() {
      if (!this.inviteEmailValid) return;

      const email = this.inviteEmail;
      const rights = this.inviteRights === 'write' ? 'W' : 'R';

      this.inviteEmail = '';

      const response = await this.$store.dispatch('workspaceInviteMember', {
        email,
        rights,
      });

      if (response.error) {
        this.error = response.error;
      } else {
        this.error = false;
      }

      analyticsLogEvent('team_invite');
    },
    onNewInputFocus() {
      analyticsLogEvent('team_new_input_focus');
    },
    userRoleName,
  },
};
</script>
