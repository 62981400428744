import * as TYPES from 'constants/mutations';
import WorkspacesService from 'services/workspaces.service';
import { sortAndFilterByProps } from 'helpers/filter';

const state = {
  workspacesInsights: {},
};

const getters = {
  workspaceInsights: (state, getters) => {
    const { workspaceCurrentId } = getters;
    return sortAndFilterByProps(state.workspacesInsights[workspaceCurrentId] || [], {
      sortBy: 'date_modified',
    });
  },
};

const actions = {
  async workspaceInsightsFetch({ commit, getters, dispatch }) {
    await dispatch('requireWorkspaces');
    const { workspaceCurrentId } = getters;
    const insights = await WorkspacesService.fetchInsights(workspaceCurrentId);
    
    commit('WORKSPACE_INSIGHTS_SET', { workspaceId: workspaceCurrentId, insights });

    insights.forEach((method) => {
      commit(TYPES.PROJECT_METHOD_SET, { projectId: method.projectId, method })
    })
  },
};

const mutations = {
  WORKSPACE_INSIGHTS_SET(state, { workspaceId, insights }) {
    state.workspacesInsights = { ...state.workspacesInsights, [workspaceId]: insights };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
