import EventsService, { EVENTS } from 'services/events.service';

class PromptsService {
  init(store) {
    this.$store = store;
  }

  show(data) {
    this.$store.dispatch('promptShow', data);
  }

  onHide(id) {
    EventsService.emit('promptHide', id);
  }
}

export default new PromptsService();
