<template>
  <BaseModal
    name="edit-contact"
    :show-close="true"
  >
    <div
      v-if="user"
      class="modal-container"
    >
      <header class="modal__header">
        <h2 class="header__info">
          Edit contact info
        </h2>
        <p class="header__email">
          {{ user.email }}
        </p>
      </header>

      <div class="payment__form">
        <div class="payment__form-content">
          <EditContactField
            v-for="(fieldName, index) in editFormOne"
            :key="index"
            :field-name="fieldName"
            :value="invoiceValue(fieldName)"
            :param="invoiceParam(fieldName)"
            @onFieldUpdate="updateField"
          />
          <template v-if="isEuCountry">
            <div class="payment__form-col payment__invoice-checkbox">
              <input
                id="needInvoice"
                v-model="companySelected"
                class="checkbox"
                type="checkbox"
              >
              <label for="needInvoice">I need an business invoice</label>
            </div>
          </template>
          <div v-if="allowEditDetailedSettings">
            <EditContactField
              v-for="(fieldName, index) in selectedForm"
              :key="index + 1"
              :field-name="fieldName"
              :placeholder="invoicesPlaceholders[fieldName]"
              :value="invoiceValue(fieldName)"
              :param="invoiceParam(fieldName)"
              @onFieldUpdate="updateField"
            />
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from 'modals/base';
import EU_COUNTRIES from 'constants/eu_countries';
import { mapGetters } from 'vuex';

import { INVOICE_PLACEHOLDERS, INVOICE_SETUP } from '@/constants/invoices';

import EditContactField from './edit-contact-field';

export default {
  name: 'EditContact',
  components: {
    EditContactField,
    BaseModal,
  },
  data() {
    return {
      modalShown: true,
      companySelected: null,
      editFormOne: ['Account name'],
      eu: {
        company: [
          'Name',
          'Country',
          'Company name',
          'Address',
          'TAX ID',
        ],
        private: ['Name', 'Country'],
      },
      outsideEu: {
        company: ['Name', 'Country'],
        private: ['Name', 'Country'],
      },
    };
  },
  created() {
    document.addEventListener('keyup', this.hideUsingEscapeKey);
  },
  mounted() {
    const company = !!(
      this.trimString(this.invoice.companyName) ||
      this.trimString(this.invoice.address)
    );

    this.companySelected = company;
    this.privateSelected = !company;
  },
  unmounted() {
    document.removeEventListener('keyup', this.hideUsingEscapeKey);
  },
  computed: {
    ...mapGetters(['invoice', 'user']),
    allowEditDetailedSettings() {
      return this.user.subscriptionKind != 'trial';
    },
    invoicesPlaceholders() {
      return INVOICE_PLACEHOLDERS;
    },
    invoicesValues() {
      return INVOICE_SETUP;
    },
    isEuCountry() {
      return EU_COUNTRIES.includes(this.invoice.country);
    },
    selectedForm() {
      if (
        this.invoice &&
        this.invoice.country &&
        this.invoice.country.length &&
        !EU_COUNTRIES.includes(this.invoice.country)
      ) {
        return this.companySelected
          ? this.outsideEu.company
          : this.outsideEu.private;
      }
      return this.companySelected ? this.eu.company : this.eu.private;
    },
  },
  watch: {
    companySelected(selected, old) {
      if (old === null) return;
      this.updateField({ type: selected ? 'company' : 'private' });
    },
  },
  methods: {
    updateField(data) {
      let body = {
        // id: this.user.id,
        ...data,
      };

      if (!this.companySelected && this.allowEditDetailedSettings) {
        body = {
          ...body,
          companyName: '‏‏‎ ‎',
          address: '‏‏‎ ‎',
          taxNumber: '‏‏‎ ‎',
        };
      }
      this.$store.dispatch('updateInvoice', body);
    },
    trimString(str) {
      if (!str || str === '‏‏‎ ‎') return '';
      return str.replace(/(^\W+)|(\W+$)/gim, '');
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    hide() {
      this.$emit('hide');
    },
    hideUsingEscapeKey(e) {
      if (e.key === 'Escape') {
        this.hide();
      }
    },
    onCompanySelection() {
      this.privateSelected = false;
      this.companySelected = true;
    },
    onPrivateSelection() {
      this.companySelected = false;
      this.privateSelected = true;
    },
    invoiceValue(field) {
      if (field === 'Account name') return this.user.name;
      return this.trimString(this.invoice[this.invoicesValues[field]]);
    },
    invoiceParam(field) {
      if (field === 'Account name') return 'name';
      return this.invoicesValues[field];
    },
  },
};
</script>

<style lang="scss">
.edit-contact-modal-modal-wrapper {
  max-width: 691px;
  min-height: 591px;
  background-color: #fff;
  position: relative;
  border-radius: 3px;
}
</style>

<style scoped lang="scss">
.modal {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 58px 0 0 0;
  }
  &__container {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.51);
    z-index: 999;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
}
.header {
  &__info {
    margin: 0;
    margin-top: 0;
    font-size: 2.4rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &__email {
    font-size: 1.4rem;
    color: #646464;
    line-height: 1.43;
    margin-top: 0.3rem;
  }
}
.edit-contact {
  &__list {
    padding: 0;
    margin: 48px 0 0 0;
    font-size: 1.6rem;
  }
  &__item {
    display: flex;
    height: 55px;
    align-items: center;
    padding: 0px;
    padding-left: 100px;
    border-bottom: 1px solid #f2f2f2;
    font-size: 16px;
    line-height: 1.5;
    color: #3b3b3b;
  }
  &__data {
    display: block;
    width: 181px;
    text-align: left;
  }
  &__input {
    border: none;
    outline: none;
  }
}
.button__container {
  .buttons {
    width: 90px;
    height: 30px;
    border-radius: 15px;
    outline: none;
    background: none;
    border: none;
    font-size: 11px;
    line-height: 1.36;
    letter-spacing: 0.8px;
    color: #414141;
    text-transform: uppercase;
    opacity: 0.5;
    &:active,
    &:focus {
      border: solid 1px #68bf65;
      color: #68bf65;
      opacity: 1;
    }
    &.js-selected {
      border: solid 1px #68bf65;
      color: #68bf65;
      opacity: 1;
    }
  }
}
</style>
