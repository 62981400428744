<template>
  <Forbidden v-if="isProjectCurrentMethodForbidden" />
  <div
    v-else
    class="survey-screen"
  >
    <div
      class="survey-header"
      :class="{
        'survey-header--show': step === 'survey',
      }"
    >
      <div class="survey-header__side">
        <Icon name="logotype" />
      </div>
      <transition name="fade">
        <div class="small-wrapper">
          <div
            v-if="step === 'survey'"
            class="g-progressbar g-progressbar--text"
          >
            <div
              class="g-progressbar__bar"
              :style="{ width: progress + '%' }"
            />
            <span class="g-progressbar__text">{{ progressText }}</span>
          </div>
        </div>
      </transition>
      <div class="survey-header__side">
        <span class="survey-saving">
          <template v-if="isSaving">
            Saving...
          </template>
          <template v-else>
            Saved
          </template>
        </span>
      </div>
    </div>

    <transition
      mode="out-in"
      name="fade"
    >
      <div
        v-if="step === 'name'"
        key="name"
        class="survey-step"
      >
        <div class="survey-step__body">
          <SurveyName @done="onNameStepDone" />
        </div>
      </div>

      <div
        v-else-if="step === 'survey'"
        class="container survey-content"
      >
        <div class="small-wrapper">
          <TitleInput
            appearance="project-title"
            :can-edit="false"
            :value="method.name"
          />
          <MethodEditor
            :survey-mode="true"
            :method="methodFiltered"
          />
          <div class="survey-continue">
            <button
              class="btn btn--primary btn--md"
              @click="onContinueClick"
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      <div
        v-else-if="step === 'thanks'"
        key="thanks"
        class="survey-step"
      >
        <div class="survey-step__body">
          <Thanks @enablingSurvey="enablingSurvey" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Forbidden from 'components/forbidden';
import PASSIVE from 'constants/passive-events';
import StoreModule from 'stores/modules/survey';
import MethodEditor from 'views/page/method-builder/editor';
import { SURVEY_TYPES } from 'constants/survey';
import { mapGetters } from 'vuex';
import zenscroll from 'zenscroll';

import SurveyName from './name';
import Thanks from './thank-you';

export const SHOULD_HAVE_FIELDS = [
  'custom_2',
  'custom_3',
];

export default {
  name: 'InterviewSurvey',
  components: {
    Thanks,
    SurveyName,
    Forbidden,
    MethodEditor,
  },
  data: () => ({
    email: '',
    step: 'name',
    isSticky: false,
  }),
  computed: {
    ...mapGetters({
      method: 'projectCurrentMethod',
      project: 'projectCurrent',
      responderId: 'surveyResponderId',
      isSaving: 'isSaving',
      isProjectForbidden: 'isProjectForbidden',
      isProjectCurrentMethodForbidden: 'isProjectCurrentMethodForbidden',
      isSurveyValid: 'isSurveyValid',
    }),
    invitedBy() {
      if (!this.project) return '';

      return this.project.owner;
    },
    name() {
      if (!this.method) return '';
      return this.method.name;
    },
    barClasses() {
      return {
        'is-sticky': this.isSticky,
      };
    },
    items() {
      return (this.method && this.method.customMethodItems.filter((item) => {
        return !SHOULD_HAVE_FIELDS.includes(item.type) || item.customItemFields.length
      })) || [];
    },
    methodFiltered() {
      return {
        ...this.method,
        customMethodItems: this.items,
      }
    },
    progress() {
      let items = this.items.filter((i) => SURVEY_TYPES.includes(i.type));
      let filled = items.filter((i) => {

        return i.answer || (i.customItemFields.find((f) => f.selected));
      });

      return filled.length / items.length * 100;
    },
    progressText() {
      let items = this.items.filter((i) => SURVEY_TYPES.includes(i.type));
      let filled = items.filter((i) => {

        return i.answer || (i.customItemFields.find((f) => f.selected));
      });

      return `${filled.length} of ${items.length}`;
    },
  },
  created() {
    this.$store.registerModule('survey', StoreModule, { preserveState: false });
  },
  async mounted() {
    const shortcut = this.$route.query.token;
    await this.$store.dispatch('setSurveyToken', shortcut);
    await this.fetchData();

    this.handleScroll = this.handleScroll.bind(this);
    document.addEventListener('scroll', this.handleScroll, PASSIVE);
  },
  beforeUnmount() {
    this.$store.unregisterModule('survey');

    document.removeEventListener('scroll', this.handleScroll, PASSIVE);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('projectSurveyMethodFetch');
    },

    onNameStepDone() {
      this.step = 'survey';
    },

    async onContinueClick(force) {
      if (this.step === 'survey') {
        await this.$store.dispatch('validateSurvey');

        if (this.isSurveyValid) {
          this.step = 'thanks';
          return;
        }

        this.$nextTick(this.scrollToInvalidQuestion);
      }
    },

    scrollToInvalidQuestion() {
      const question = document.querySelector('.builder-section--survey-invalid');

      if (question) {
        zenscroll.setup(null, 62)
        zenscroll.to(question);
      }
    },

    handleScroll() {
      this.isSticky = window.scrollY >= 60;
    },

    enablingSurvey() {
      this.step = 'survey';
    },
  },
};
</script>

<style lang="scss" scoped>
.public__question {
  display: inline;
  position: relative;
  font-size: 1.8rem;
  font-weight: 600;
  color: #252729;
}
.method {
  &__introduction {
    justify-content: center;
  }
}
</style>
