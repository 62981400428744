import * as ApiService from 'services/api.service';

const MODEL = {
};

const parse = (raw) => ({
  ...MODEL,
  ...raw,
  type: raw.recordType,
});

class ArchiveService {
  fetch(workspace, projectId) {
    return ApiService.get(this.getUrl(workspace, projectId)).then((items) => items.map(parse));
  }

  getUrl(workspace, projectId) {
    return `api/workspaces/${workspace}/projects/${projectId}/archives`;
  }

}

export default new ArchiveService();
