import * as ROUTES from 'constants/routes';
import Router from 'src/router';

const navigate = (name, params = {}, query = {}) => {
  Router.push({ name, params, query }).catch((err) => {});
}

export const navigateLanding = () => window.location = 'https://talebook.io';
export const navigateHome = () => navigate(ROUTES.HOME);
export const navigateSettings = () => navigate(ROUTES.WORKSPACE_SETTINGS);
export const navigateProjects = () => navigate(ROUTES.PROJECTS_LIST);
export const navigateOnboarding = () => navigate(ROUTES.WORKSPACE_ONBOARDING);
export const navigateUpgrade = () => navigate(ROUTES.PRICE_PLANS);
export const navigateSignin = () => {
  if ([ROUTES.SIGN_IN, ROUTES.SIGN_IN_EMAIL].includes(Router.currentRoute.name)) return;
  navigate(ROUTES.SIGN_IN);
}
export const navigateProject = (projectId) =>
  navigate(ROUTES.PROJECT_METHODS, {
    project_id: projectId,
  });
export const navigateTeam = (projectId) =>
  navigate(ROUTES.PROJECT_TEAM, {
    project_id: projectId,
  });
export const navigateTags = (projectId) =>
  navigate(ROUTES.PROJECT_TAGS, {
    project_id: projectId,
  });
export const navigateToTag = (tagId) =>
  navigate(ROUTES.PROJECT_TAG, {
    tag_id: tagId,
  });
export const navigateProjectInsights = (projectId) =>
  navigate(ROUTES.PROJECT_INSIGHTS, {
    project_id: projectId,
  });
export const navigateToMethod = (projectId, methodId, query) =>
  navigate(ROUTES.PROJECT_METHOD, {
    project_id: projectId,
    method_id: methodId,
  }, query);

export const navigateToDetailsPage = (
  projectId,
  recordType,
  parentId,
  methodName
) =>
  navigate(ROUTES.PROJECT_METHOD_PAGE, {
    project_id: projectId,
    record_type: recordType,
    parent_id: parentId,
    method_name: methodName,
  });
