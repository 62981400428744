/* eslint-disable no-shadow */

import * as TYPES from 'constants/mutations';
import {
  ACCEPT_INVITATION,
  COMPETITOR,
  MAGIC_LINK,
  PASSWORD_RESET,
  PERSONA,
  PRICE_PLANS,
  PRIVACY_POLICY,
  PROJECT_METHOD,
  PROJECT_METHOD_PAGE,
  PROJECT_METHODS,
  PROJECT_REPORT,
  PROJECT_SHORT,
  PROJECT_SURVEY,
  SIGN_IN,
  TERMS,
} from 'constants/routes';
import { navigateOnboarding, navigateProjects, navigateSignin } from 'helpers/router';
import { isReader } from 'helpers/team';
import { GUEST } from 'models/user';
import EventsService, { EVENTS } from 'services/events.service';
import LoginService from 'services/login.service';
import NotificationsService from 'services/notifications.service';
import PaymentsService from 'services/payments.service';
import StorageService from 'services/storage.service';
import TemporaryUserService from 'services/temporary-user.service';
import UserService from 'services/user.service';
import { analyticsLogEvent, analyticsSetUserId } from 'utils/analytics';
import { merge } from 'utils/reactive';

const moduleState = {
  user: null,
  provider: null,
  token: null,

  // share entry url
  forcedPublicView: false,
  surveyToken: false,
  publicTokens: {},

  ...(StorageService.user.load() || {}),
  ...(StorageService.publicToken.load() || {}),
};

const SHAREABLE_ROUTES = [
  PROJECT_SHORT,
  PROJECT_METHODS,
  PROJECT_METHOD,
  PROJECT_METHOD_PAGE,
  PROJECT_SURVEY,
  COMPETITOR,
  PERSONA,
  PROJECT_REPORT,
];

const PUBLIC_ROUTES = [SIGN_IN, TERMS, PRIVACY_POLICY, PRICE_PLANS, PASSWORD_RESET, MAGIC_LINK, ACCEPT_INVITATION];

const getters = {
  user: (state) => state.user,
  provider: (state) => state.provider,
  hasUser: (state) => state.user !== null && !!state.user.id.length,
  isLoggedIn: (state, getters) =>
    state.provider !== null && getters.isRegistered,
  isRegistered: (state, getters) => getters.user && getters.user.registered,

  isGuest: (state, getters) => {
    const method = getters.projectCurrentMethod;
    if (method !== null) return method.guest;

    const project = getters.projectCurrent;
    if (project !== null) return project.guest;

    return null;
  },
  isReader: (state, getters) =>
    getters.projectCurrent && !getters.isShared && isReader(getters.projectCurrent.role),
  userCanEditProjects: (state, getters) =>
    !(state.forcedPublicView || getters.shortcutToken) && (getters.isUserOwner || getters.isUserEditor),
  userCanManageTeams: (state, getters) =>
    getters.isUserOwner,
  isShared: (state, getters) =>
    state.forcedPublicView || getters.shortcutToken || getters.isProjectForbidden || getters.isPublicRoute || getters.hasUser && getters.isGuest && (getters.isUserOwner || getters.isUserEditor),
  shortcutToken: (state, getters) => {
    const { publicTokens } = state;
    const projectId = getters.projectCurrentId;

    return publicTokens[projectId] && publicTokens[projectId].token || state.surveyToken;
  },
  isSharedLoading: (state, getters) => {
    if (!getters.routeName) return true;

    // if on route that cannot be shared then shared state is not loading
    if (
      SHAREABLE_ROUTES.includes(getters.routeName) ||
      PUBLIC_ROUTES.includes(getters.routeName)
    ) {
      return false;
    }

    return false;
  },

  token: (state) => state.token,
  hasToken: (state) => state.token !== null,
  accessToken: (state, getters) => {
    const token = getters.token;
    if (!token) {
      return null;
    }

    return token;
  },

  shareEntry: (state, getters) => {
    const { publicTokens } = state;
    const projectId = getters.projectCurrentId;

    return publicTokens[projectId] && publicTokens[projectId].url;
  },

  isUserInvitedToProject: (state, getters) => {
    const { publicTokens } = state;
    const projectId = getters.projectCurrentId;

    return publicTokens[projectId] && publicTokens[projectId].invited;
  },
};

const actions = {
  init({ dispatch, getters }) {
    if (!getters.hasToken) return;

    dispatch('requireUser');
  },

  async login({ dispatch }, { providerName, data = {} }) {
    const { accessToken, provider, error } = await LoginService.login(
      providerName,
      data
    ).catch((e) => e);

    if (error || !accessToken) {
      let errorDisplay = 'Can\'t sign in, try again';

      if (error && (error.includes('already signed') || error.includes('same user'))) {
        errorDisplay = 'You’re already signed in';
      }

      NotificationsService.showInfo(errorDisplay);
      return;
    }

    dispatch('tokensSet', { provider, accessToken });

    return accessToken;
  },
  tokensSet({ dispatch }, { provider, accessToken }) {
    dispatch('userProvider', provider);
    dispatch('userToken', accessToken);

    StorageService.user.save({
      token: accessToken,
      provider: provider,
    });
  },
  async loggedIn({ dispatch, getters }, user) {
    dispatch('userSet', user);

    if (!getters.isRegistered) {
      EventsService.emit(EVENTS.LOGGED_IN, user);
    }

    await dispatch('requireWorkspaces');

    if (!getters.workspaces.length) {
      if (
        SHAREABLE_ROUTES.includes(getters.routeName)
      ) return;
      navigateOnboarding();
      analyticsLogEvent('signed_in_redirect_to_onboarding');
      return;
    } else if(getters.routeName.includes('sign-in')) {
      navigateProjects();
      analyticsLogEvent('signed_in_redirect_to_projects');
    }
  },
  loggedOut() {},

  async logOut({ dispatch, commit }) {
    EventsService.emit(EVENTS.LOGGING_OUT);

    commit(TYPES.LOGGED_OUT);
    EventsService.emit(EVENTS.LOGGED_OUT);

    await PaymentsService.destroy();

    StorageService.user.save({
      token: null,
      provider: null,
    });

    this.userLoading = false;

    analyticsLogEvent('log_out');
    dispatch('loggedOut');
  },

  userSet({ commit }, user) {
    commit(TYPES.USER_SET, user);
    analyticsSetUserId(user.email);
    if (typeof Rollbar !== 'undefined') {
      Rollbar.configure({
        payload: {
          person: {
            id: user.id,
            username: user.email,
            email: user.email,
          },
        },
      });
    }
  },
  userToken({ dispatch, commit }, token) {
    commit(TYPES.USER_TOKEN, token);
    return dispatch('requireUser');
  },
  userProvider({ commit }, provider) {
    commit(TYPES.USER_PROVIDER, provider);
  },

  requireUser({ dispatch, getters }) {
    if (!getters.user && !this.userLoading) {
      this.userLoading = dispatch('userFetch').then(() => this.userLoading = false);
    }

    return this.userLoading;
  },

  async userFetch({ dispatch, getters }) {
    let profile;

    if (!getters.accessToken) {
      profile = {};
    } else {
      profile = await UserService.getProfile().catch((e) => ({}));
    }

    let action = null;

    if (getters.isRegistered) {
      action = 'userSet';
    } else if (profile.registered) {
      action = 'loggedIn';
    }

    if (
      !profile.registered &&
      !getters.isShared &&
      !PUBLIC_ROUTES.includes(getters.routeName) &&
      !SHAREABLE_ROUTES.includes(getters.routeName)
    ) {
      // show login modal if user not registered (so guest or temp user)
      // and not coming from shared link
      if (getters.isSharedLoading) {
        TemporaryUserService.waitForIsSharedLoaded().then(() => {
          if (
            PUBLIC_ROUTES.includes(getters.routeName) || SHAREABLE_ROUTES.includes(getters.routeName)
          ) return;
          navigateSignin();
        });
      } else {
        if (
          PUBLIC_ROUTES.includes(getters.routeName) || SHAREABLE_ROUTES.includes(getters.routeName)
        ) return;
        navigateSignin();
      }
    }

    action && dispatch(action, profile);
    return profile;
  },
  async userChange({ dispatch, commit }, data) {
    commit(TYPES.USER_CHANGE, data);

    const user = await UserService.change(data);
    dispatch('userSet', user);
  },

  shareEntrySet({ commit }, { id, url }) {
    commit(TYPES.SHARE_ENTRY, { id, url });
  },

  forcePublicView({ commit }, forced) {
    commit(TYPES.FORCE_PUBLIC_VIEW_SET, forced);
  },

  setSurveyToken({ commit }, token) {
    commit('SET_SURVEY_TOKEN', token);
  },

  setPublicToken({ commit }, { id, token }) {
    commit('SET_PUBLIC_TOKEN', { id, token });
  },

  setProjectInvited({ commit }, { id, invited }) {
    commit('SET_PUBLIC_INVITED', { id, invited });
  },

  async unsetPublicToken({ commit }, { id }) {
    await commit('UNSET_PUBLIC_TOKEN', { id });
  },
};

const mutations = {
  [TYPES.USER_SET](state, user) {
    state.user = user;
  },
  [TYPES.LOGGED_OUT](state) {
    state.user = null;
    state.token = null;
    state.provider = null;
  },

  [TYPES.USER_TOKEN](state, token) {
    state.token = token;
  },
  [TYPES.USER_PROVIDER](state, provider) {
    state.provider = provider;
  },

  [TYPES.USER_CHANGE](state, data) {
    merge(state.user, data);
  },

  [TYPES.SHARE_ENTRY](state, { id, url }) {
    let token = state.publicTokens[id];

    if (!token) {
      merge(state.publicTokens, { [id]: { token } });
    } else {
      token = state.publicTokens[id];

      if (!token.url || url === null) {
        merge(token, { url });
      }
    }

    StorageService.publicToken.save({ publicTokens: state.publicTokens });
  },
  [TYPES.FORCE_PUBLIC_VIEW_SET](state, forced) {
    state.forcedPublicView = forced;
  },
  SET_SURVEY_TOKEN(state, token) {
    state.surveyToken = token;
  },
  SET_PUBLIC_TOKEN(state, { id, token }) {
    let entry = state.publicTokens[id];

    if (!entry) {
      merge(state.publicTokens, { [id]: { token } });
    }

    entry = state.publicTokens[id];

    merge(entry, { token });

    StorageService.publicToken.save({ publicTokens: state.publicTokens });
  },
  UNSET_PUBLIC_TOKEN(state, { id }) {
    delete state.publicTokens[id];
    StorageService.publicToken.save({ publicTokens: state.publicTokens });
  },
  SET_PUBLIC_INVITED(state, { id, invited }) {
    let entry = state.publicTokens[id];

    if (!entry) {
      merge(state.publicTokens, { [id]: { invited } });
    } else {
      entry = state.publicTokens[id];
      merge(entry, { invited });
    }

    StorageService.publicToken.save({ publicTokens: state.publicTokens });
  },
};

export default {
  state: moduleState,
  getters,
  actions,
  mutations,
};
