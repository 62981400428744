<template>
  <div>
    <div ref="player" style="z-index: 99999">
      <slot />
    </div>
  </div>
</template>
<script>
import Plyr from 'plyr'

export default {
  name: 'Player',
  emits: ['timeupdate', 'playing', 'pause', 'ended'],
  props: {
    options: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      player: {},
    }
  },
  mounted() {
    const player = new Plyr(this.$refs.player.children[0], this.options);

    player.on('timeupdate', (e) => {
      this.$emit('timeupdate', e, player);
    });

    player.on('playing', (e) => {
      this.onPlaying(e);
    });

    player.on('pause', (e) => {
      this.onStop(e);
    })

    this.player = player;

    document.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    try {
      this.player.destroy()
      document.removeEventListener('scroll', this.onScroll);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
  methods: {
    setCurrentTime(time) {
      this.player.currentTime = time;
    },
    setThumbnail(thumbnail) {
      this.player.poster = thumbnail;
    },
    onPlaying() {
      this.$emit('playing');
    },
    onStop() {
      this.$emit('stop');
    },
    stop() {
      this.player.pause();
    },
    play() {
      this.player.play();
    },
  },
}
</script>