export const deepFindInObject = (theObject, findProp, findValue) => {
  let result = null;
  if(theObject instanceof Array) {
    for(let i = 0; i < theObject.length; i++) {
      result = deepFindInObject(theObject[i], findProp, findValue);
      if (result) {
        break;
      }
    }
  }
  else {
    for(let prop in theObject) {
      if(prop == findProp) {
        if(theObject[prop] === findValue) {
          return theObject;
        }
      }
      if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
        result = deepFindInObject(theObject[prop], findProp, findValue);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
}

export function uniqueByKey(a, key) {
  let seen = {};
  return a.filter(function(item) {
    let k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  })
}