<template>
  <div
    v-if="canEdit || !isHiddenInPublic"
    class="report-block"
  >
    <div
      v-if="canEdit && section"
      class="sortable-handler report-block__sortable-handler"
    >
      <Icon name="drag" />
    </div>
    <Transition name="slide-up">
      <div
        v-if="isHidden"
        class="report-block__hidden-name"
      >
        {{ name }}
      </div>
    </Transition>
    <ContentTransition :multiple="true">
      <div
        v-if="!isHidden"
        class="report-block__body"
      >
        <slot />
      </div>
    </ContentTransition>
    <div
      v-if="canEdit"
      class="report-block__tooltip"
    >
      <div
        class="report-tooltip"
        :class="{ 'report-tooltip--light': empty }"
      >
        <BaseTooltip
          appearance="light-box"
          placement="bottom"
          behaviour="click"
          v-if="canRemove"
        >
          <div class="report-tooltip__section report-tooltip__button">
            <Icon name="layout" />
          </div>
          <template v-slot:content>
            <ul class="layout-list">
              <li @click="selectLayout('text')" :class="{'is-active': layout === 'text'}">
                <Icon name="report-layout-text" />
              </li>
              <li @click="selectLayout('left')" :class="{'is-active': layout === 'left'}">
                <Icon name="report-layout-left" />
              </li>
              <li @click="selectLayout('right')" :class="{'is-active': layout === 'right'}">
                <Icon name="report-layout-right" />
              </li>
              <li @click="selectLayout('center')" :class="{'is-active': layout === 'center'}">
                <Icon name="report-layout-center" />
              </li>
            </ul>
          </template>
        </BaseTooltip>
        <router-link
          v-if="navigateTo"
          class="report-tooltip__section report-tooltip__button"
          :to="navigateTo"
        >
          <Icon name="expand" />
        </router-link>
        <div class="report-tooltip__section">
          <span class="report-tooltip__label">Show</span>
          <Checkbox
            appearance="switch switch--sm switch--green"
            :checked="!isHidden"
            @click="onChange"
          />
        </div>
        <div class="report-tooltip__section report-tooltip__button" @click="removeItem" v-if="canRemove">
          <Icon name="trash-2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    hidden: Boolean,
    section: {
      type: Object,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    canEdit: Boolean,
    canRemove: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    isHidden() {
      if (this.section) {
        return this.section.options && this.section.options.hidden;
      }

      return this.hidden;
    },
    isHiddenInPublic() {
      return this.isHidden || this.isEmpty;
    },
    layout() {
      return (this.section && this.section.options && this.section.options.layout) || '';
    },
  },
  methods: {
    onChange() {
      if (this.section) {
        this.$store.dispatch('builderUpdateSectionOptions', {
          id: this.section.id,
          options: {
            hidden: !this.isHidden,
          },
        })
      }

      this.$emit('change-hidden', !this.isHidden);
    },
    removeItem() {
      this.$store.dispatch('builderRemoveSection', { id: this.section.id });
    },
    selectLayout(layout) {
      if (this.section) {
        this.$store.dispatch('builderUpdateSectionOptions', {
          id: this.section.id,
          options: {
            layout,
          },
        })
      }
    },
  },
};
</script>