<template>
  <div
    class="process-notification"
  >
    <MethodIcon
      :method="method"
    />
    <template v-if="type === 'upload'">
      Uploading {{ progressText }}
    </template>

    <template v-if="type === 'transcribe'">
      Transcribing
    </template>

    <div @click="navigate" class="process-notification__link">
      <Icon name="expand" />
    </div>
  </div>
</template>
<script>
import { navigateToMethod } from 'helpers/router';

export default {
  name: 'Notification',
  props: ['data'],
  computed: {
    type() {
      return this.data.type;
    },
    method() {
      return this.$store.getters.projectMethodById(this.data.methodId);
    },
    progress() {
      return this.data.progress || ((this.data.stats && this.data.stats.progressPercent) / 100) || 0;
    },
    progressText() {
      return (typeof this.progress === 'number') ? ` (${Math.round(this.progress * 100)}%)` : '';
    },
  },
  methods: {
    navigate() {
      navigateToMethod(this.method.projectId, this.data.methodId, {
        item: this.data.options && this.data.options.customItemId || this.data.sectionId,
      });
    },
  },
};
</script>
