import generateId from 'utils/id';
import Quill from 'quill';

const Inline = Quill.import('blots/inline');

export default class Tag extends Inline {
  static blotName = 'tag';
  static tagName = 'tag';

  static create(value) {
    const node = super.create(value);

    if (value.tag) {
      value = [value.tag];
    }
    
    node.setAttribute('data-tags', `${value.join(' ')}`);
    node.setAttribute('data-sentence-id', generateId());

    return node;
  }

  static formats(domNode) {
    const tags = domNode.getAttribute('data-tags');
    return tags ? tags.split(' ') : null;
  }

  format(name, value) {
    if (!value || (value && value.tag === false)) {
      super.format(name, false);
    }
    else if (name !== this.statics.blotName) {
      super.format(name, value);
    }
    else {
      if (value.tag) {
        const { tag, selected } = value;
        const attr = this.domNode.getAttribute('data-tags') || '';
        let tags = attr.split(' ');
        let changed = false;

        if (selected) {
          if (!tags.includes(tag)) {
            tags.push(tag);
            changed = true;
          }
        } else {
          if (tags.includes(tag)) {
            tags = tags.filter((t) => t !== tag);
            changed = true;
          }
        }

        if (changed) {
          this.domNode.setAttribute('data-tags', tags.join(' '));
        //  this.domNode.setAttribute('data-sentence-id', generateId());
        }
      } else {
        this.domNode.setAttribute('data-tags', value.join(' '));
      //  this.domNode.setAttribute('data-sentence-id', generateId());
      }
    }
  }
}