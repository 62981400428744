<template>
  <div>
    <button
      type="button"
      class="btn btn--orange hidden-mobile"
      v-bind="$attrs"
    >
      <span>Edit</span>
    </button>
    <button
      type="button"
      class="titled-icon show-mobile"
      v-bind="$attrs"
    >
      <Icon name="edit" />
      <span class="titled-icon__text">Edit</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonEdit',
};
</script>
