<template>
  <div
    v-if="!isEditing"
    class="base-multiline-input__div"
    @click="onFocus"
    @touchstart="onFocus"
  >
    {{ this.truncatedValue }}
  </div>
  <textarea
    v-else
    ref="input"
    v-model="myValue"
    class="base-multiline-input"
    v-bind="$attrs"
    :readonly="!canEdit"
    :spellcheck="false"
    :class="{ 'cant-edit': !canEdit }"
    @input="onInput"
    @change="onChange"
    @blur="onBlur"
    @keydown.enter="$refs.input.blur()"
    @keydown.esc="onBlur"
    @mouseup="mouseup"
  />
</template>
<script>
import autosize from 'autosize';
import { truncate } from 'helpers/string';
import debounce from 'lodash/debounce';

export default {
  name: 'BaseMultilineInput',
  props: {
    value: String,
    truncate: {
      type: Number,
    },
    remember: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      backupValue: null,
      myValue: null,
      isEditing: false,
      preventMouseUp: false,
    };
  },
  computed: {
    truncatedValue() {
      if (this.truncate && this.truncate > 0) return truncate(this.myValue, this.truncate);
      return this.myValue;
    },
  },
  watch: {
    value(v) {
      this.myValue = v;
      if (this.multiline) {
        autosize.update(this.$refs.input);
      }
    },
  },
  created() {
    this.myValue = this.value;
    this.backupValue = this.value;
  },
  mounted() {
    autosize(this.$refs.input);
  },
  updated() {
    autosize.update(this.$refs.input);
  },
  unmounted() {
    window.setTimeout(() => {
      autosize.destroy(this.$refs.input);
    }, 500);
  },
  methods: {
    onInput() {
      this.$emit('input', this.myValue);
    },
    onChange() {
      if (this.myValue && this.myValue.trim().length > 0) {
        this.emitChange();
      }
    },
    emitChange: debounce(function() {
      this.$emit('change', this.myValue);
    }, 100),
    select() {
      this.$refs.input.select();
      this.preventMouseUp = true;
    },
    mouseup(e) {
      if (this.preventMouseUp) {
        e.preventDefault();
      }
      this.preventMouseUp = false;
    },
    onFocus() {
      this.backupValue = this.myValue;
      this.isEditing = true;
      this.$nextTick(() => {
        if (this.isEditing) {
          autosize(this.$refs.input);
          this.select();
        }
      });
    },
    onBlur() {
      if (!this.remember) return;
      this.isEditing = false;
      if (this.myValue === null || !this.myValue.length) {
        this.myValue = this.backupValue;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.base-multiline-input {
  border: 0;
  background: transparent;
  text-align: left;
  width: 100%;
  outline: 0;
  resize: none;
  padding: 0px;
  &::placeholder {
    opacity: 0.4;
    color: #3b3b3b;
  }
}

.base-multiline-input__div {
  &:hover {
    cursor: text;
  }
}
</style>
