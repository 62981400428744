/* global __PRODUCTION__ */

export const analyticsLogEvent = (event) => {
  if (!__PRODUCTION__) {
    // eslint-disable-next-line
    console.log(`[AMPLITUDE:LOG] - ${ event }`);
    return;
  }

  amplitude.getInstance().logEvent(event);
};

export const analyticsSetUserId = (id) => {
  window.fcWidget && window.fcWidget.user && window.fcWidget.user.setProperties({
    email: id,
  });

  if (!__PRODUCTION__) {
    // eslint-disable-next-line
    console.log(`[AMPLITUDE:SET-USER-ID] - ${ id }`);
    return;
  }

  amplitude.getInstance().setUserId(id);
};

export const analyticsSetUserProp = (prop, value) => {
  window.fcWidget && window.fcWidget.user && window.fcWidget.user.setProperties({
    [prop]: value,
  });

  if (!__PRODUCTION__) {
    // eslint-disable-next-line
    console.log(`[AMPLITUDE:SET-USER-PROP] - ${ prop }: ${ value }`);
    return;
  }

  const identify = new amplitude.Identify().set(prop, value);
  amplitude.getInstance().identify(identify);
};
