if (__STAGING__) {
  class TemplateExport {
    static defaults = {
      target: '#app',
      header: `<html>
      <head>
      <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css?family=IBM+Plex+Serif:400,500&amp;display=swap" rel="stylesheet">
      <style>
      {{styles}}
      </style>
      </head>
      <body>`,
      footer: `</body>
      </html>`,
      erbContent: {
        '.titleinput__no-edit': 'persona.object.name',
        '.competitor-analysis-competitor__description .base-textarea': 'persona.object.profile',
        '.competitor-analysis-competitor-summary-input__property-name': 'field.name',
        '.persona-fields-wrap .competitor-analysis-competitor-summary-input__input': 'field.value',
        '.base-card__input': 'card.content',
      },
      erbLoops: {
        '.persona-fields-wrap .competitor-analysis-competitor-summary-input__container': {
          array: 'persona.object.field_values_list',
          variable: 'field',
        },
        '.persona-charts-wrap .competitor-analysis-competitor-summary-input__container': {
          array: 'persona.object.chart_values_list',
          variable: 'field',
        },
        '.persona-values-cards-wrap .base-card': {
          array: 'persona.object.values',
          variable: 'card',
        },
        '.persona-goals-cards-wrap .base-card': {
          array: 'persona.object.goals',
          variable: 'card',
        },
        '.persona-problems-cards-wrap .base-card': {
          array: 'persona.object.problems',
          variable: 'card',
        },
      },
      erbStyles: {
        '.base-card': {
          'padding': '6px 0',
        },
        '.persona-charts-wrap .range-slider-fill': {
          'background-color': '<%= field.color %>',
          'width': '<%= field.value %>%',
        },
        '.container': {
          'margin-left': '130px',
          'width': 'auto',
          'padding': '50px',
        },
        '.small-wrapper': {
          'margin': '0px',
          'width': 'auto',
          'padding-right': '0px',
        },
      },
      erbClass: {
        '.base-card': 'base-card',
      },
      erbCustomStyles: `
        .base-card:last-child {
          border-bottom: 0 !important;
        }
      `,
      erbAtts: {
        '.competitor-avatar__img img': {
          'src': '<%= persona.avatar %>',
        },
      },
      cssProps: [
        'width',
        'height',

        'font-family',
        'font-size',
        'font-stretch',
        'font-style',
        'font-weight',

        'line-height',
        'letter-spacing',

        'background-color',
        'background-image',
        'background-size',
        'background-repeat',

        'border-top',
        'border-right',
        'border-bottom',
        'border-left',

        'display',
        'top',
        'right',
        'bottom',
        'left',
        'padding',
        'margin',
        'color',
        'position',
        'float',
        'clear',
        'opacity',
        'box-sizing',
        'border-radius',
        'box-shadow',
      ],
      htmlAtts: [
        'src',
      ],
    }

    usedLoops = {}

    constructor(options) {
      this.options = { ...TemplateExport.defaults, ...options };
      this.process();
    }

    process() {
      const { target } = this.options;
      const targetEl = document.querySelector(target);

      const template =
        this.options.header.replace('{{styles}}', this.options.erbCustomStyles) +
        this.createErbTemplate(this.processChild([targetEl])) +
        this.options.footer;

      const blob = new Blob([template], {type : 'text/html'}); ;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'template.html';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }

    matchSelector(node, selector) {
      const elements = [ ...document.querySelectorAll(selector) ];
      return !!elements.find((n) => n === node);
    }

    hasErbContent(node) {
      const { erbContent } = this.options;

      for (let selector in erbContent) {
        if (this.matchSelector(node, selector)) {
          return erbContent[selector];
        }
      }

      return false;
    }

    hasErbLoop(node) {
      const { erbLoops } = this.options;

      for (let selector in erbLoops) {
        if (this.matchSelector(node, selector)) {
          this.usedLoops[selector] = true;
          return erbLoops[selector];
        }
      }

      return false;
    }

    hasErbStyles(node) {
      const { erbStyles } = this.options;

      for (let selector in erbStyles) {
        if (this.matchSelector(node, selector)) {
          return erbStyles[selector];
        }
      }

      return false;
    }

    hasErbAtts(node) {
      const { erbAtts } = this.options;

      for (let selector in erbAtts) {
        if (this.matchSelector(node, selector)) {
          return erbAtts[selector];
        }
      }

      return false;
    }

    hasErbClass(node) {
      const { erbClass } = this.options;

      for (let selector in erbClass) {
        if (this.matchSelector(node, selector)) {
          return erbClass[selector];
        }
      }

      return false;
    }

    isLoopNextChild(node) {
      const { erbLoops } = this.options;

      for (let selector in erbLoops) {
        if (this.matchSelector(node, selector) && this.usedLoops[selector]) {
          return true;
        }
      }

      return false;
    }

    shouldHaveHeight(node) {
      if (node.tagName === 'INPUT' || node.tagName === 'TEXTAREA') {
        return false;
      }

      const prevHeightCss = node.style.height;
      const prevHeight = node.offsetHeight;

      node.style.height = 'auto';

      const nextHeight = node.offsetHeight;
      node.style.height = prevHeightCss;

      if (nextHeight !== prevHeight) {
        return true;
      }

      return false;
    }

    processChild(nodes) {
      const data = [];
      const { cssProps, htmlAtts } = this.options;

      nodes.forEach((node) => {
        if (this.isLoopNextChild(node)) {
          return;
        }

        const nodeType = node.nodeType;
        const nodeTag = (node.tagName || '').toLowerCase();
        const computedStyle = nodeType === Node.ELEMENT_NODE && getComputedStyle(node);
        const style = [];
        const atts = [];
        const erbContent = this.hasErbContent(node);
        const erbLoop = this.hasErbLoop(node);
        const erbStyles = this.hasErbStyles(node);
        const erbAtts = this.hasErbAtts(node);
        const erbClass = this.hasErbClass(node);
        const nodeData = {
          type: nodeType,
          tag: nodeTag,
          html: node.nodeValue,
          style,
          atts,
          className: erbClass || '',
        };

        if (nodeTag === 'input' || nodeTag === 'textarea') {
          nodeData.tag = 'div';
          nodeData.html = node.value;
        }

        if (erbContent) {
          nodeData.html = `<%= ${ erbContent } %>`;
        }

        if (erbLoop) {
          nodeData.loop = erbLoop;
        }

        if (computedStyle) {
          if (
            computedStyle.getPropertyValue('opacity') === '0' ||
            computedStyle.getPropertyValue('display') === 'none'
          ) {
            return;
          }

          cssProps.forEach((prop) => {
            let value = computedStyle.getPropertyValue(prop);
            if (prop === 'display' && value === 'flex') {
              value = '-webkit-box';
            }

            if (prop === 'height' && !this.shouldHaveHeight(node)) {
              return;
            }

            (!erbStyles || !erbStyles[prop]) && style.push([prop, value]);
          });

          if (erbStyles) {
            for(let prop in erbStyles) {
              let value = erbStyles[prop];
              style.push([prop, value]);
            }
          }
        }

        if (nodeTag) {
          htmlAtts.forEach((attr) => {
            (!erbAtts || !erbAtts[attr]) && node.getAttribute(attr) && atts.push([attr, node.getAttribute(attr)]);
          });

          if (erbAtts) {
            for(let attr in erbAtts) {
              let value = erbAtts[attr];
              atts.push([attr, value]);
            }
          }
        }

        if (node.childNodes) {
          nodeData.child = this.processChild(node.childNodes);
        }

        data.push(nodeData);
      });

      return data;
    }

    createErbTemplate(nodes) {
      if (!nodes) return '';

      return nodes.reduce((template, node) => {
        if (!node.tag) {
          return template + (node.html || '');
        }

        const loop = node.loop;

        const content = node.html || this.createErbTemplate(node.child) || '';
        const style = this.createStyle(node.style);
        const atts = this.createAtts(node.atts);
        const className = node.className ? `class="${ node.className }"` : '';

        if (loop) {
          template += `<% ${ loop.array }.each do |${ loop.variable }| %>`;
        }
        template += `<${ node.tag } ${ className } style='${ style }' ${ atts }>${ content }</${ node.tag }>`;
        if (loop) {
          template += '<%end %>';
        }

        return template;
      }, '');
    }

    createStyle(styles) {
      return styles.reduce((style, [prop, value]) => {
        return style += `${ prop }: ${ value };`;
      }, '');
    }

    createAtts(atts) {
      return atts.reduce((attr, [prop, value]) => {
        return attr += `${ prop }="${ value }" `;
      }, '');
    }
  }

  window.TemplateExport = TemplateExport;
}
