/* global __DEV__ */
import { createStore } from 'vuex';

import answerModal from './modules/answer-modal';
import archive from './modules/archive';
import builder from './modules/builder';
import cards from './modules/cards';
import highlights from './modules/highlights';
import insights from './modules/insights';
import invoice from './modules/invoice';
import library from './modules/library';
import methods from './modules/methods';
import methodsDefinitions from './modules/methods-definition';
// import createLogger from 'vuex/dist/logger';
import notifications from './modules/notifications';
import payments from './modules/payments';
import phases from './modules/phases';
import projects from './modules/projects';
import prompts from './modules/prompts';
import router from './modules/route';
import sockets from './modules/sockets';
import subscription from './modules/subscription';
import tags from './modules/tags';
import team from './modules/team';
import testers from './modules/testers';
import tutorials from './modules/tutorials';
import ui from './modules/ui';
import user from './modules/user';
import workspaces from './modules/workspaces';
import activeStorage from './modules/active-storage';
import historyPlugin from './plugins/history';

const store = createStore({
  modules: {
    notifications,
    prompts,
    methodsDefinitions,
    workspaces,
    projects,
    payments,
    phases,
    methods,
    sockets,
    cards,
    router,
    subscription,
    ui,
    user,
    testers,
    tags,
    highlights,
    archive,
    team,
    invoice,
    answerModal,
    library,
    tutorials,
    builder,
    insights,
    activeStorage,
  },
  plugins: [historyPlugin],

  strict: __DEV__,
});

export default store;
