<template>
  <div :class="{'tag-list-item': true, 'tag-list-item--selected': tag.selected, [selectedClass]: tag.selected, 'tag-list-item--focused': focused}">
    <div
      v-if="shouldShowColor"
      class="tag-list-item__color"
      :style="{'background-color': color}"
    />
    <UserAvatar
      v-if="shouldShowAvatar"
      class="tag-list-item__avatar"
      mode="email"
      :name="tag.name"
    />
    <div class="tag-list-item__name">
      <slot>{{ tag.name }}</slot>
    </div>
    <svg
      v-if="tag.selected"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
    >
      <path
        fill="none"
        fill-rule="evenodd"
        stroke="#4B4B4B"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 1L3.75 9 0 5.364"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'TagListItem',
  props: {
    color: {
      default: 'rgba(0, 0, 0, 0.1)',
    },
    tag: {
      required: true,
      type: Object,
    },
    focused: {
      required: false,
      type: Boolean,
      default: false,
    },
    selectedClass: {
      required: false,
      type: String,
    },
    mode: {
      type: String,
      default: 'tags',
    },
  },
  computed: {
    shouldShowColor() {
      return this.mode === 'tags';
    },
    shouldShowAvatar() {
      return this.mode === 'responders';
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin text {
  color: #646464;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.tag-list-item {
  @include text;
  letter-spacing: 0.1px;
  min-height: 36px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  margin-right: 8px;

  &:hover {
    background-color: #F8F9FA;
  }
  &:last-child,
  &:first-child {
    margin-bottom: 0px;
  }

  &--selected {
    color: #252729;
    font-weight: 600;
    svg {
      width: 24px;
      margin: 2px;
      stroke-width: 1.05px;
    }
  }

  &--focused {
    background-color: #F8F9FA;
  }

  &--new {
    color: #252729;
    font-weight: 600;
    svg {
      width: 15px;
      height: 11px;
      margin: 0px 17px 0 10px;
    }
  }

  &__color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0px 16px 0 10px;
  }

  &__avatar {
    font-size: 22px;
    margin: 0px 16px 0 10px;
  }

  &__name {
    flex: 1;
    word-break: break-all;
  }
}
</style>
