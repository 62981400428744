<template>
  <div class="page-avatar">
    <div
      v-if="!imageUrl"
      class="page-avatar__icon"
      :style="{ 'background-color': color }"
    >
      <Icon
        v-if="icon"
        :key="icon"
        :name="icon"
      />
      <span
        v-else
        class="page-avatar__empty-icon"
      />
    </div>
    <div
      v-else
      class="page-avatar__img"
    >
      <template v-if="isUploading">
        <img
          :src="imageUrl"
        >
        <div class="page-avatar__badge">
          <span class="page-avatar__uploading" />
        </div>
      </template>
      <template v-else>
        <img :src="imageUrl">
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageAvatar',
  props: {
    canEdit: { type: Boolean },
    page: { type: Object },
  },
  data() {
    return {
    };
  },
  computed: {
    color() {
      if (this.page && this.page.projects) {
        return '';
      }

      return this.page && (this.page.color || '#192262') || '';
    },
    icon() {
      if (this.page && this.page.projects) {
        return 'logotype';
      }

      return this.page && (this.page.icon || 'method-notepad-method') || '';
    },
    imageUrl() {
      if (this.isUploading) {
        return null;
      }

      return this.page && this.page.image;
    },
    isUploading() {
      return this.page && this.page.image && this.page.image.uploading;
    },
  },
  methods: {
  },
};
</script>
