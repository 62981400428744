<template>
  <div class="modals">
    <transition name="modal" :duration="400">
      <ManageTags v-if="getModalState('ManageTags')" />
    </transition>
    <transition name="modal" :duration="400">
      <SurveyInvitation v-if="getModalState('SurveyModal')" />
    </transition>
    <transition name="modal" :duration="400">
      <Payments v-if="getModalState('PaymentModal')" />
    </transition>
    <transition name="modal" :duration="400">
      <PaymentMethod v-if="getModalState('PaymentMethodModal')" />
    </transition>
    <transition name="modal" :duration="400">
      <CancelSubscription v-if="getModalState('CancelSubscriptionModal')" />
    </transition>
    <transition name="modal" :duration="400">
      <AnswerModal v-if="getModalState('AnswerModal')" />
    </transition>
    <transition name="modal" :duration="400">
      <MethodModal v-if="getModalState('method')" />
    </transition>
    <transition name="modal" :duration="400">
      <HighlightsModal v-if="getModalState('highlights')" />
    </transition>
    <transition name="modal" :duration="400">
      <TagModal v-if="getModalState('tag')" />
    </transition>
    <transition name="modal" :duration="400">
      <EditContact v-if="getModalState('edit-contact')" />
    </transition>
    <transition name="modal" :duration="400">
      <LimitReached v-if="getModalState('limit-reached')" />
    </transition>
    <transition name="modal" :duration="400">
      <Invoices v-if="getModalState('invoices')" />
    </transition>
    <transition name="modal" :duration="400">
      <ResetPasswordRequest v-if="getModalState('reset-password-request')" />
    </transition>
    <transition name="modal" :duration="400">
      <MagicLink v-if="getModalState('magic-link')" />
    </transition>
  </div>
</template>

<script>
import AnswerModal from './answer-modal';
import CancelSubscription from './cancel-subscription';
import EditContact from './edit-contact';
import HighlightsModal from './highlights';
import Invoices from './invoices';
import ManageTags from './manage-tags';
import MethodModal from './method';
import Payments from './payment';
import PaymentMethod from './payment-method';
import SurveyInvitation from './survey';
import TagModal from './tag';
import ResetPasswordRequest from './reset-password-request';
import MagicLink from './magic-link';
import LimitReached from './limit-reached';

export default {
  name: 'Modals',
  components: {
    ManageTags,
    SurveyInvitation,
    Payments,
    CancelSubscription,
    AnswerModal,
    PaymentMethod,
    MethodModal,
    HighlightsModal,
    TagModal,
    EditContact,
    Invoices,
    ResetPasswordRequest,
    MagicLink,
    LimitReached,
},
  methods: {
    getModalState(name) {
      return this.$store.getters.isModalWithNameActive(name);
    },
  },
};
</script>
