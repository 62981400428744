import { TYPES } from './types';

// used by PagesService
export const PAGE_RECORD_BY_TYPE = {
  [TYPES.STAKEHOLDER_INTERVIEW]: 'answers',
  [TYPES.USER_INTERVIEW]: 'answers',
  [TYPES.PROBLEM_VALUATION]: 'problems',
  [TYPES.SOLUTION_VALUATION]: 'solutions',
  [TYPES.COMPETITION_ANALYSIS]: 'competitor_properties',
  [TYPES.PERSONAS]: 'problems',
  [TYPES.INTERACTIVE_PROTOTYPE]: 'testing_sessions',
};
